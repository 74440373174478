import axios from "axios";
import { toastr } from "react-redux-toastr";

import { history } from "../../utils/history";
// import { logoutUser, LOGOUT_USER } from "../modules/auth";
// import { showLoading, hideLoading, resetLoading } from "react-redux-loading-bar";
import {SET_AUTH_REDIRECT_URL, SET_AUTHENTICATED} from "../modules/auth";
import { RESET_USER_INFO } from "../modules/user";
import {useLocation} from "react-router-dom";
///////
axios.defaults.withCredentials = true;

export const API = "api/API";
const API_START = "api/API_START";
const API_END = "api/API_END";
const API_ERROR = "api/API_ERROR";
const ACCESS_DENIED = "api/ACCESS_DENIED";
const ON_FAILURE = "api/ON_FAILURE";
export const DYNAMO = 'sdk/DYNAMO'
export const NEW_DISPATCH = 'redux/DISPATCH'

export const SQL = 'sdk/SQL'

let url = process.env.REACT_APP_API_ENDPOINT;

// interceptor
const axiosApiInstance = axios.create();

// Response interceptor for API calls >> code from internet
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  // debugger
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    if (window.location.pathname !== "/login") {
      refreshToken()
    }
    // const access_token = "await refreshAccessToken()";
    // const access_token = await refreshAccessToken();
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

var firstErrorMsgTime = new Date();
const minimumTimeBetweenTwoMsg = 30;

// axiosApiInstance.interceptors.response.use(
//   success => {
//       if (success.headers && success.headers.feedbackmessage) {
//           // reduxStore.dispatch({ type: SET_SYSTEM_INFO_MESSAGE, payload: success.headers.feedbackmessage })
//       }
//       return success
//   },
//   error => {
//       const { response, config } = error;
//       if (response && response.status === 401 && config && !config.retryRequest && config.url !== 'logout' && !config.skipAuth) {
//           return refreshToken()
//               .then(() => {
//                   config.retryRequest = true;
//                   return axiosApiInstance(config);
//               });
//       }

//       //TODO:SE: test timeout error handling
//       if (response === undefined && !config.suppresTimeoutError) {
//           var nextErrorMsgTime = new Date();
//           var timeDifference = (nextErrorMsgTime.getTime() - firstErrorMsgTime.getTime()) / 1000;
//           if (timeDifference > minimumTimeBetweenTwoMsg) {
//               // reduxStore.dispatch({ type: SET_SYSTEM_ERROR_MESSAGE, payload: { localizationCode: ERRORCODE.TIMEOUT } });
//               firstErrorMsgTime = new Date();
//           }
//           return Promise.reject(response);
//       }

//       switch (response.status) {

//           // Unauthorized
//           case 401: {
//               if (response && response.data && response.data.result) {
//                   response.data.errorData = { localizationCode: response.data.result };
//               }

//               if (!config.skipAuth) {
//                   // logout();
//               }
//               break;
//           }

//           // Method not allowed or ValidationError
//           case 405: {
//               //case1: Validation error
//               if (response && response.data) {
//                   // response.data = { /*...response.data,*/ errorData: getErrorMessage(response.data) }

//               //case2: Method not allowed (ex: POST/GET confuse)
//               } else {
//                   // response.data = { errorData: { localizationCode: ERRORCODE.METHOD_NOT_ALLOWED } }
//                   // reduxStore.dispatch({ type: SET_SYSTEM_ERROR_MESSAGE, payload: response.data.errorData })
//               }
//               break;
//           }

//           case 404: // Not found
//               // response.data = { errorData: ERRORCODE.NOT_FOUND_404 }
//               break;

//           case 403: // Forbidden (ForbiddenException)
//           case 500: // Internal Server Error (ServiceException)
//               // response.data = { /*...response.data,*/ errorData: getErrorMessage(response.data) }
//               // reduxStore.dispatch({ type: SET_SYSTEM_ERROR_MESSAGE, payload: response.data.errorData })
//               break;

//           case 400: // Bad Request (BusinessException)
//           default: // All unhandled error codes
//               // response.data = { /*...response.data,*/ errorData: getErrorMessage(response.data) }
//               break;
//       }

//       return Promise.reject(response);
//   }
// );

/** Refresh token */
export function setRefreshToken(rfToken) {
  localStorage.setItem("refreshToken", rfToken)
}

export function getRefreshToken() {
  const token = localStorage.getItem("refreshToken");
  if (token  !== "undefined" && token !== "null"){
      return token
  }
  return null
}

export function refreshToken() {
  const rfToken = getRefreshToken();
  const url = process.env.REACT_APP_API_ENDPOINT;

  // debugger
  if (!rfToken)
      return Promise.reject();
  return axiosApiInstance.post(`${url}/public/refresh-secure`, null, {
      params: {
          rftok: rfToken
      },
      skipAuth: true
  }).then((data) => {
      setTimeout(() => {
      }, 0); // check if still needed
      return Promise.resolve(data);
  });
}


/** login */
export function login(rfToken) {
  setRefreshToken(rfToken);
}

/** logout */
// export function logout() {
//   return api.post("logout", null, {
//       params: {
//           rftok: getRefreshToken()
//       }
//   }).finally(() => {
//       if (Router.pathname !== '/login'){
//           Router.push('/login')
//       }
//       reduxStore.dispatch({type: RESET_USER_SESSION})
//       setRefreshToken(null);
//   });
// }

// interceptor
export const apiStart = label => ({
  type: API_START,
  payload: label
});

export const apiEnd = label => ({
  type: API_END,
  payload: label
});

export const apiError = error => ({
  type: API_ERROR,
  payload: error
});

export const accessDenied = error => ({
  type: ACCESS_DENIED,
  payload: error
});

export const onFailure = error => ({
  type: ON_FAILURE,
  payload: error
});

// export const logOutUser = () => ({
//   type: LOGOUT_USER
// });

export const apiAction = ({
  endPoint = "",
  method = "GET",
  data = null,
  responseType,
  // headers = null,
  onSuccess = () => { },
  onFailure = () => { },
  extraHeaders = null,
  label = "",
  baseUrl = null,
  qs = false,
  isToastOnError = true
}) => ({
  type: API,
  payload: {
    endPoint,
    method,
    data,
    responseType,
    onSuccess,
    onFailure,
    extraHeaders,
    label,
    baseUrl,
    qs,
    isToastOnError
  }
});

export const dynamoAction = ({
  method = "QUERY",
  onSuccess = () => { },
  onFailure = () => { },
  parameters = null,
}) => ({
  type: DYNAMO,
  payload: {
    method,
    parameters,
    onSuccess,
    onFailure,
  }
});

export const sqlAction = ({
  onSuccess = () => { },
  onFailure = () => { },
  parameters = null,
}) => ({
  type: SQL,
  payload: {
    parameters,
    onSuccess,
    onFailure,
  }
});

export const reDispatch = (innerFunction) => ({
  type: NEW_DISPATCH,
  payload: innerFunction
});

const apiMiddleware = ({ getState, dispatch }) => next => action => {
  // console.log("API ACTION", action);
  next(action);

  if (action.type !== API && action.type !== DYNAMO && action.type != SQL && action.type!== NEW_DISPATCH) {
    return;
  }
  if (action.type === NEW_DISPATCH) {
    const {next, onSuccess} = action.payload

    //console.log("test")
    dispatch(action.payload( dispatch))
  }

  if (action.type == API) {
    const {
      endPoint,
      method,
      data,
      accessToken,
      extraHeaders,
      responseType,
      onSuccess,
      onFailure: onFailureCustom,
      label,
      baseUrl,
      qs,
        isToastOnError
    } = action.payload;
    // GET & DELETE  use params vs POST which may require data
    const dataOrParams = (["GET", "DELETE"].includes(method) || qs) ? "params" : "data";

    let updatedHeaders = {
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Credentials':true
    }
    axiosApiInstance.defaults.headers.common["Content-Type"] = "application/json";
    // headers && headers.map(header => axiosApiInstance.defaults.headers.common[header.name] = header.value)
    // add token to req
    const isToken = localStorage.getItem("user");
    // console.log({extraHeaders})
    // extraHeaders && extraHeaders.forEach(header =>
    //   axiosApiInstance.defaults.headers.common[header.name] = header.value
    //   )
    let token = null;
    // console.log("isToken", isToken);
    if (isToken) {
      token = JSON.parse(isToken).IdToken;
    }
    // console.log(token);

    if (token) updatedHeaders["Authorization"] = token;
    // if (token) axiosApiInstance.defaults.headers.common["Authorization"] = token;

    if (label) {
      dispatch(apiStart(label));
    }
    //   Progress.show();
    // dispatch(showLoading());
    //   dispatch(show())

    url = process.env.REACT_APP_API_ENDPOINT;
    url = baseUrl ? `${baseUrl}${endPoint}` : `${url}${endPoint}`;

    // console.log("getState", getState());
    // return function(dispatch) {
    return axiosApiInstance
      .request({
        url,
        method,
        // mode: 'no-cors',
        headers: updatedHeaders,
        responseType,
        [dataOrParams]: data,
        // withCredentials: true
      })
      .then(({ data }) => {
        // console.log(headers)
        // dispatch(hideLoading());
        dispatch(onSuccess(data, dispatch)) })
      .catch(error => {
        // dispatch(resetLoading())

          if (isToastOnError) {
              if (error.response && error.response.data && typeof error.response.data === "string") {
                  toastr.error(String(error.response.data))
              } else if (error.response.status !== 401) {
                toastr.error("API integration error...")
              }
          }
  
            if (error.response) {
          if (onFailure?.type) dispatch(onFailure());//error.response.status
          if (onFailureCustom?.type) {
            //onFailureCustom(error);
            dispatch(onFailureCustom(error, dispatch));
          }
          if (error.response.status === 401) {
          // if (error.response.status === 401 || error.response.status === 403) {
            // dispatch(logoutUser());
            if (window.location.pathname.includes("admin")) {
              dispatch({type: SET_AUTH_REDIRECT_URL, payload: `${window.location.pathname}${window.location.search}` });
            }
            dispatch({type: SET_AUTHENTICATED, payload:false}) //temp usage before rele implementation
            dispatch({type: RESET_USER_INFO})
            history.push("/login");
            if(error.response.data.result !== "PW_INVALID" && error.response.data.result !== "LOCKED") {
              toastr.warning('', 'Your session has expired. Please log in again.')
            }
          }
        }

        dispatch(apiError(error));
        if (onFailure) dispatch(onFailure(error));
        if (onFailureCustom) {
          //onFailureCustom(error);
          dispatch(onFailureCustom(error, dispatch));
        }

        if (error.response && error.response.status === 404) {
          dispatch(accessDenied(window.location.pathname));
        }
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  }
};

export default apiMiddleware;
