import React, {useState} from 'react'

import {useRouteMatch} from "react-router-dom";
import {SvgIcon} from "@synergic/core"

import MagicSideBarLink from "./MagicSideBarLink";

import {MenuItem} from "./menu";

interface MagicSidebarItemProps {
    item: MenuItem
}

const MagicSidebarItem = (props: MagicSidebarItemProps) => {
    const {item} = props;

    const match = useRouteMatch(item.actionLink);

    const [isOpened, setIsOpened] = useState(false);

    const hasChild = item.children?.length > 0;

    const renderParentContent = () => {
        return <>
            <div className="menu-parent__content">
                <div className="icon-ct">
                    <SvgIcon name={item.icon} mr={0} h={6} w={6} stroke={'white'}/>
                </div>
                <label className={'etype-down-1'}>{item.label}</label>
            </div>
            <div className="menu-parent__icon">
                <SvgIcon name={item.children.length ? "expand1" : "right"} mr={0} h={8} w={8} stroke={'white'}/>
            </div>
        </>
    }

    const renderParent = () => {
        const className = `menu-parent ${match ? 'active' : ''}`;
        if (hasChild) {
            return <div className={className} onClick={() => setIsOpened((prev: boolean) => !prev)}>
                {renderParentContent()}
            </div>
        }

        return <MagicSideBarLink className={className} to={item.actionLink}>
            {renderParentContent()}
        </MagicSideBarLink>
    }

    const renderChild = (item: MenuItem, index: any) => {
        return <MagicSideBarLink key={index} className="menu-child" to={item.actionLink}>
            <div className="icon-ct">
                <SvgIcon name={item.icon} mr={0} h={6} w={6} stroke={'white'}/>
            </div>
            <label className={'etype-down-1 type--light'}>{item.label}</label>
        </MagicSideBarLink>

    }

    if (!hasChild) {
        return <div className="menu-item">
            {renderParent()}
        </div>
    }

    return <div className={`menu-item ${isOpened && 'menu-item--opened'}`}>
        {renderParent()}
        <div className="menu-children">
            {item.children.map((item: MenuItem, index) => renderChild(item, index))}
        </div>
    </div>

}


export default MagicSidebarItem;