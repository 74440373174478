import { useSelector } from "store/store"
import { mapRole } from "./lookupFunctions"

export const useUserInfo = () => {
    const user = useSelector(state => state.user)
    const roleName = mapRole(user?.groupId)
    const groupsId = useSelector(state => state?.user?.groupsId)
    let companyType = useSelector(state => state.company?.type) ?? 'GUEST'
    
    if (roleName === 'GUEST') companyType = 'GUEST'
    return {
        ...user,
        isPublic: roleName === 'GUEST',
        companyType,
        adminAccess : groupsId?.includes?.(user?.groupId),
    }
}