import * as yup from "yup";
import moment from "moment";

import {formatDateWTime} from "utils/dateUtils";
import {groupBy} from "../../../utils/array";

import {rolePermissions} from "../constants/roleConstants";


export const getStatusClass = (status: string) => {
    switch (status) {
        case "BaseEntityStatus.ACTIVE":
            return "active-status-box"
        case "BaseEntityStatus.SUSPENDED":
            return "suspended-status-box"
        case "BaseEntityStatus.DELETED":
            return "deleted-status-box"
        default:
            return "Statusul nu este setat"
    }
}

export const getStatusText = (status: string) => {
    switch (status) {
        case "BaseEntityStatus.ACTIVE":
            return "Activ"
        case "BaseEntityStatus.SUSPENDED":
            return "Suspendat"
        case "BaseEntityStatus.DELETED":
            return "Șters"
        default:
            return "Statusul nu este setat"
    }
}

export function getPermissionLabel(code: any, dictionarySet: any) {
    let result = "Undefined";

    if (!dictionarySet || !code) return null;

    dictionarySet.map((item: any) => {
        if (item.code === code) result = item.label;
    })
    return result;
}

export const roleModalSchema = yup.object().shape({
    shortName: yup.string().required().min(4),
});

export const parseApiGroupData = (data: any) => {
    const groupedByKey = groupBy(data.permissionList, 'mainKey');
    const permissionMappedList = Object.assign(
        {},
        ...Object.keys(groupedByKey).map((key: any) => ({[key]: mapRoleToArray(key, groupedByKey[key])}))
    );
    return ({
        ...data,
        ...permissionMappedList,
        permissionKeys: Object.keys(groupedByKey),
        modifyTime: data.modifyTime ? formatDateWTime(data.modifyTime) : "-",
        createTime: data.createTime ? formatDateWTime(data.createTime) : "-",
    })
}

export const parseForApiGroupData = (data: any) => ({
    code: data.code,
    description: data.description,
    id: data.id,
    name: data.name,
    status: data.status,
    technicalName: data.technicalName,
    type: data.type,
    userCount: data.userCount,
    userDirectory: data.userDirectory,
    permissionList: data.permissionList.map((item: any) => {
        const roleName = item.subKey.split("_").pop();
        return ({
            ...item,
            exist: Array.isArray(data[item.mainKey]) && data[item.mainKey].includes(roleName)
        })
    })
})

const mapRoleToArray = (key: string, role: any[]) => {
    const permissions: string[] = [];
    role.forEach((item) => {
        if (item.exist) {
            permissions.push(item.subKey.split("_").pop());
        }
    })
    return permissions.length > 0 ? permissions : "0";
}

export const parseForApiNewGroupData = (data: any) => {
    const groupPermissions: Array<any> = []
    let idx = 0

    Object.keys(data).map((key: any) => {
        if (rolePermissions.includes(key) && (data[key] || data[key] === null)) {
            groupPermissions.push(
                {
                    "category": `${[key]?.toLocaleString() === "GROUP" || [key]?.toLocaleString() === "USER" ? "SETUP" : "SALES"}`,
                    "idx": idx++,
                    "mainKey": [key]?.toLocaleString(),
                    "subKey": `${[key]?.toLocaleString()}_READ`,
                    "exist": data[key] !== null ? data[key]?.includes("READ") : false,
                },
                {
                    "category": `${[key]?.toLocaleString() === "GROUP" || [key]?.toLocaleString() === "USER" ? "SETUP" : "SALES"}`,
                    "idx": idx++,
                    "mainKey": [key]?.toLocaleString(),
                    "subKey": `${[key]?.toLocaleString()}_WRITE`,
                    "exist": data[key] !== null ? data[key]?.includes("WRITE") : false,
                },
                {
                    "category": `${[key]?.toLocaleString() === "GROUP" || [key]?.toLocaleString() === "USER" ? "SETUP" : "SALES"}`,
                    "idx": idx++,
                    "mainKey": [key]?.toLocaleString(),
                    "subKey": `${[key]?.toLocaleString()}_DELETE`,
                    "exist": data[key] !== null ? data[key]?.includes("DELETE") : false,
                }
            )
        }
    })

    return ({
        id: null,
        name: data?.name,
        code: data?.code,
        technicalName: data?.technicalName,
        status: "BaseEntityStatus.ACTIVE",
        userDirectory: "LDAP",
        createTime: moment(new Date())?.toISOString(),
        modifyTime: moment(new Date())?.toISOString(),
        createUserName: data?.currentUser,
        modifyUserName: data?.currentUser,
        type: "INTERNAL",
        userCount: 0,
        description: null,
        permissionList: groupPermissions
    })
}