const groupBy = (xs: any[], key: string) =>
    xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

const sortBy = (items: any[], key: string) => {
    return items.sort((a: any, b: any) => a[key] - b[key])
}

const isNotLastItem = (index: number, array: unknown[]) => index < array.length - 1;
const isLastItem = (index: number, array: unknown[]) => index === array.length - 1;

export { groupBy, isNotLastItem, isLastItem, sortBy };
