import { PageStatus } from "@synergic/core"

interface ErrorPage {
    type: "page-not-found" | "page-was-moved" | "browser-not-compatible" | "outdated-browser" | "bad-resolution" | "access-required" | "no-server-connection" | "no-search-result";
    title: string;
    className?: string;
}

export const ErrorPage = (props: ErrorPage) => {
    const { className, type, title } = props

    return (
        <div className={`flex justify-center items-center h-full ${className}`}>
            <PageStatus type={type} title={title} isCompact={false} />
        </div>
    )
}