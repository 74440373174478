import React, {useContext} from "react";

import {useState} from "react";
import {isIOS} from 'react-device-detect';

import MagicSidebar from "./components/MagicSidebar/MagicSidebar";
import {OverlayContext} from "contexts/OverlayContext";

import 'style/index.scss';
import './index.scss';

import MagicNavbar from "./components/MagicNavbar/MagicNavbar";

export default function MagicLayout({children}: any) {
    const [showLeftMenu, setShowLeftMenu] = useState<boolean>(false);
    const {visibleOverlay} = useContext(OverlayContext);

    return (
        <div className={`app theme-default ${isIOS ? 'ios' : ''}`}>
            <MagicNavbar onPressHamburger={() => setShowLeftMenu(prev => !prev)}/>

            <MagicSidebar isOpen={showLeftMenu}/>

            <div className={`pageContent page-flex-column ${showLeftMenu ? 'thin' : ''}`}>
                <main className="">{children}</main>
            </div>
            {visibleOverlay && <div className="page-overlay"/>}
        </div>
    );
}
