export function numberFormatter(number, delimiter = " ") {
    if (number)
        return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
    else
        return number;
}

export function numberFormatterV2(x, productCardRelated) {
    x = x?.toString()
    if (x?.includes(",") && x?.includes(".")) {
      x = x?.replace(/\./g, "")
      x = x?.replace(/\,/g, ".")
    }

    if(productCardRelated) {
      x = x?.replace(/.(?=\d{3})/g, '')
    }

    x = (Math.round(x * 100) / 100)?.toFixed(2)
    return x?.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")?.replace(/\,/g, ".")?.replace(/\.([^\.]*)$/, "," + "$1")
}

export function convertFile2Base64(file) {
    if (!file) return Promise.reject();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = error => reject(error);
    });
  }

export function jsonCheck (input) {
    return typeof input === 'string'
        ? JSON.parse(input)
        : input
}

export const searchItems = (searchString, dataViewItems,) => {
    // if (!searchString) return dataViewItems
    // if (searchString === '') return dataViewItems
    return dataViewItems?.filter((obj) => trimmAndLowerString(JSON.stringify(obj)).includes(searchString));
  };
const trimmAndLowerString = (rawString) => rawString ? rawString.replace(/\s+/g, '').toLowerCase() : "";

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') return string
    return string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase();
  }

export const getInitialsFromName = title => String(title)?.split(' ')?.slice(0, 2).map(i => i[0]?.toUpperCase()).join('')

export const getInitialsFromCompanyName = title =>
  String(title)?.split(' ')?.slice(0, 3).map(i => !(["srl","srl.", "sa", "sa.", "srl-d"].includes(i.toLocaleLowerCase())) ? i[0].toUpperCase() : "")


export const sharedStart = (array) => {
    let A = array.concat().sort(),
        a1 = A[0], a2 = A[A.length - 1], L = a1.length, i = 0;
    while(i<L && a1.charAt(i)=== a2.charAt(i)) i++;
    return a1.substring(0, i);
}