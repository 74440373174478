import { FILE_TYPE, PERMISSION } from "staticData/general";
import { apiAction, reDispatch } from "store/middleware/api";
import { SET_API_RESPONSE } from "store/modules/auth";
import { setCrtFormItem, setImportedDataFromExcel, addCrtListItems, setPageModalData } from "store/modules/page";
import { formatDateWTime } from "utils/dateUtils";
import store from '../store';
import { setDictionary } from "store/modules/backData";
import { parseApiGroupData } from "modules/users/utils/roleUtils";
import { parseApiLeadData } from "../../modules/lead/utils/leadUtils";
import { parseApiDealData } from "../../modules/deals/utils/dealUtils";

export const loadItem = (type = 'product', id, reduxSave = true, combine, moreSteps, quantity, originalQuantity) => {
  //this branch will be used for cases where we need multiple API calls to fetch data
  if (type === 'productDetails') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //workaround to clear redux state. Because there is no API call this should be executed before getting the API responses below
    dispatch(loadItem('product/goods', id, true, true))
    dispatch(loadItem('product/goods/info', id, true, true))
    dispatch(loadItem('stock/by-product', id, true, true))
    dispatch(loadItem('product-document/by-product', id + "?expired=false", true, true))
    return ({ type: "a" })
  })
  if (type === 'discountVolumeDetails') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //workaround to clear redux state. Because there is no API call this should be executed before getting the API responses below
    dispatch(loadItem('product/goods/discount/volume', id, true, true))
    dispatch(loadItem('product/goods/discount/volume/clients', id, true, true))
    return ({ type: "a" })
  })
  if (type === 'discountGridDetails') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //workaround to clear redux state. Because there is no API call this should be executed before getting the API responses below
    dispatch(loadItem('product/goods/discount/grid', id, true, true))
    dispatch(loadItem('product/goods/discount/grid/clients', id, true, true))
    dispatch(loadItem('product/goods/discount/grid/products', id, true, true))
    return ({ type: "a" })
  })
  if (type === 'badgeDetails') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //workaround to clear redux state. Because there is no API call this should be executed before getting the API responses below
    dispatch(loadItem('goods-badge', id, true, true))
    dispatch(loadItem('goods-badge/products', id, true, true))
    dispatch(loadItem('goods-badge/clients', id, true, true))
    return ({ type: "a" })
  })
  if (type === 'shopProductDetails') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //workaround to clear redux state. Because there is no API call this should be executed before getting the API responses below
    dispatch(loadItem('product/goods/shop', id, true, true))
    dispatch(loadItem('product-rating/by-product', id, true, true))
    dispatch(loadItem('product-question/by-product', id, true, true))
    dispatch(loadItem('product/goods/my-client-purchase-statistics', id, true, true))
    dispatch(loadItem('product-document/by-product', id + "?expired=false", true, true))
    return ({ type: "a" })
  })
  if (type === 'companyForm') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //workaround to clear redux state. Because there is no API call this should be executed before getting the API responses below
    dispatch(loadItem('organization/webshop-client', id, true, true))
    dispatch(loadItem('organization/webshop-client/info', id, true, true))
    dispatch(loadItem('organization/webshop-client/limit', id, true, true))
    dispatch(loadItem('organization/webshop-client/financial', id, true, true))
    return ({ type: "a" })
  })
  if (type === 'defineYourTypeName') return reDispatch(dispatch => {
    dispatch(setCrtFormItem()) //clear redux state
    dispatch(loadItem('product/goods', id, true, true)) //add your api calls
    dispatch(loadItem('product/goods/info', id, true, true)) //add your api calls
    //keep in mind that data processing still happens below when we come back in this function with the actual api path prefix.
    return ({ type: "a" })
  })
  if (type === 'addDeliveryAddress') return reDispatch(dispatch => {
    dispatch(setCrtFormItem())
    dispatch(loadItem('address', id, true, true))
    dispatch(loadItem('organization/workplace/alloptions', '', true, true))
    dispatch(loadItem('location-delivery/by-address', id, true, true))
    return ({ type: "a" })
  })

  if (type === 'locationDelivery') return reDispatch(dispatch => {
    dispatch(setCrtFormItem())
    dispatch(loadItem('location-delivery', id, true, true))
    return ({ type: "a" })
  })

  if (type === 'productDocumentDetailForm') return reDispatch(dispatch => {
    dispatch(setCrtFormItem())
    dispatch(loadItem('product-document', id, true, true))
    dispatch(loadItem('product-document/products', id, true, true))
    return ({ type: "a" })
  })

  if (type === 'shopDashboard') return reDispatch(dispatch => {
    dispatch(setCrtFormItem());
    // dispatch(loadItem('my-company', id, true, true))
    // dispatch(loadItem('basket/statistics', id, true, true))
    // dispatch(loadItem('invoice/statistics', id, true, true))

    // I told 6 months ago that it is redundant
    dispatch(loadItem('basket/my-statistics', "", true, true))
    dispatch(loadItem('organization/webshop-client', id, true, true))
    dispatch(loadItem('organization/webshop-client/limit', id, true, true))
    dispatch(loadItem('organization/webshop-client/financial', id, true, true))
    dispatch(loadItem('organization/webshop-client/info', id, true, true))
    //dispatch(loadItem('client-invoice/my-statistics', "", true, true))
    return ({ type: "a" })
  })

  return apiAction({
    endPoint: `/${type}` + (id ? `/${id}` : ''),
    onSuccess: (data, dispatch) => {
      // console.log("API data", type, ': ', data);
      let parsedData
      dispatch({ type: SET_API_RESPONSE, payload: data, })
      //TODO: We need this for the id to be saved
      if (data && type !== 'location-delivery/by-address' && type !== 'product/goods/shop/code') data.id = id;
      if (moreSteps) moreSteps(data, dispatch, quantity)
      if (reduxSave) {
        if (type === 'user/my-user') dispatch(setCrtFormItem(parseApiMyProfile(data)))
        if (type === 'user') dispatch(setCrtFormItem(parseApiUserData(data)))
        if (type === 'group') dispatch(setCrtFormItem(parseApiGroupData(data)))
        if (type === 'organization/lead') dispatch(setCrtFormItem(parseApiLeadData(data)))
        if (type === 'deal') dispatch(setCrtFormItem(parseApiDealData(data)))
        if (type === 'product/goods') dispatch(setCrtFormItem(parseApiCompanoProduct(data), combine))
        if (type === 'product/goods/shop' && quantity === -1) dispatch(setDictionary({ data }))
        if (type === 'product/goods/shop') dispatch(setCrtFormItem(data, combine))
        if (type === 'stock/by-product') dispatch(setCrtFormItem(parseApiProductStockList(data), combine))
        if (type === 'basket') dispatch(setCrtFormItem(data, combine)) //search specific order
        if (type === 'order') dispatch(setCrtFormItem(data, combine)) //search specific order
        if (type === 'product/goods/info') dispatch(setCrtFormItem(parseApiCompanoProductInfo(data), combine))
        if (type === 'organization/webshop-client') dispatch(setCrtFormItem(parseWebShopClient(data), combine))
        if (type === 'organization/webshop-client/info') dispatch(setCrtFormItem(data, combine))
        if (type === 'organization/webshop-client/limit') dispatch(setCrtFormItem(data, combine))
        if (type === 'organization/webshop-client/financial') dispatch(setCrtFormItem(data, combine))
        if (type === 'product-question') dispatch(setCrtFormItem(parseApiPorductQuestion(data)))
        if (type === 'product/goods/discount/grid') dispatch(setCrtFormItem(parseApiDiscountGrid(data), combine))
        if (type === 'product/goods/discount/grid/clients') dispatch(setCrtFormItem(parseAssociatedCompanies(data), combine))
        if (type === 'product/goods/discount/grid/products') dispatch(setCrtFormItem(parseAssociatedProducts(data), combine))
        if (type === 'product/goods/discount/package') dispatch(setCrtFormItem(parseApiDiscountPackage(data)))
        if (type === 'product/goods/discount/fix') dispatch(setCrtFormItem(parseApiDiscountFix(data)))
        if (type === 'product/goods/discount/volume') dispatch(setCrtFormItem(parseApiDiscountVolume(data), combine))
        if (type === 'product/goods/discount/volume/clients') dispatch(setCrtFormItem(parseAssociatedCompanies(data), combine))
        if (type === 'goods-badge') dispatch(setCrtFormItem(parseApiProductGoodsBadge(data), combine))
        if (type === 'goods-badge/products') dispatch(setCrtFormItem(parseAssociatedProducts(data), combine))
        if (type === 'goods-badge/clients') dispatch(setCrtFormItem(parseAssociatedCompanies(data), combine))
        if (type === 'product-rating') dispatch(setCrtFormItem(parseApiProductRating(data)))
        if (type === 'product-document') dispatch(setCrtFormItem(parseApiDocument(data), combine))
        if (type === 'product-rating/by-product') dispatch(setCrtFormItem(parseApiRatingByProduct(data), combine))
        if (type === 'product-question/by-product') dispatch(setCrtFormItem(parseApiQAByProduct(data), combine))
        if (type === 'organization/webshop-client/limit') dispatch(setCrtFormItem({ limit: data }, combine))
        if (type === 'organization/webshop-client/financial') dispatch(setCrtFormItem({ financial: data }, combine))
        if (type === 'clientInvoice/search') dispatch(setCrtFormItem(data))
        if (type === 'client-invoice') dispatch(setCrtFormItem(data))
        if (type === 'client-invoice/my') dispatch(setCrtFormItem(data))
        if (type === 'uniprest/user/my-user') dispatch(setCrtFormItem(data, combine))
        if (type === 'location-delivery') dispatch(setCrtFormItem(parseLocationDelivery(data), combine))
        if (type === 'organization/workplace') dispatch(setCrtFormItem(parseOrganizationWorkplace(data), combine))
        if (type === 'address') dispatch(setCrtFormItem(parseDeliveryAddressData(data), combine))
        if (type === 'location') dispatch(setCrtFormItem(parseLocationData(data), combine))
        if (type === 'basket/statistics-by-client') dispatch(setCrtFormItem(data))
        if (type === 'basket/my-statistics') dispatch(setCrtFormItem(data, combine))
        if (type === 'organization/my-organization') dispatch(setCrtFormItem(data, combine))
        if (type === 'client-invoice/my-statistics') dispatch(setCrtFormItem(data, combine))
        if (type === 'location-delivery/by-address') dispatch(setCrtFormItem(parseLocationDelivery(data), combine))
        if (type === 'organization/workplace/alloptions') dispatch(setCrtFormItem(parseWorkplaceOptions(data), combine))
        if (type === 'product-document/by-product') dispatch(setCrtFormItem(parseProductDocumentData(data), combine))
        if (type === 'address/by-organization') dispatch(setCrtFormItem(parseAddressByOrganization(data), combine))
        if (type === 'product/goods/my-client-purchase-statistics') dispatch(setCrtFormItem(data, combine))
        if (type === 'product-document/products') dispatch(setCrtFormItem(relatedProductsList(data), combine))
        if (type === 'location-delivery/by-county') dispatch(setCrtFormItem(parseLocationDelivery(data), combine))
        if (type === 'location-delivery/by-location') dispatch(setCrtFormItem(parseLocationDelivery(data), combine)) //
        if (type === 'organization/webshop-client/my-options') dispatch(setDictionary(parseOrgData(data), combine))
        if (type === 'sales-activity/notify') dispatch(setCrtFormItem(parseApiMyProfile(data)))
        if (type === 'sales-activity/note') dispatch(setCrtFormItem(parseApiMyProfile(data)))
        if (type === 'sales-activity/meeting') dispatch(setCrtFormItem(parseApiMyProfile(data)))
        if (type === 'sales-activity/interface') dispatch(setCrtFormItem(parseApiMyProfile(data)))
        if (type === 'sales-deal') dispatch(setCrtFormItem(parseApiMyProfile(data)))
        
        if (type === 'basket/delivery-date-option-list') {
          if (quantity?.priority === 0) {
            dispatch(setCrtFormItem(parseCentralDeliveryDateOptions(data, quantity), combine))
          } else {
            dispatch(setCrtFormItem(parseLocalDeliveryDateOptions(data, quantity), combine))
          }
        }
        if (type === 'dictionary/type/USER_POSITION') dispatch(setCrtFormItem(parsePositionData(data), combine))
        // if (type === 'product/goods/shop/code' && parseFloat(originalQuantity) >= 0 && /^[+-]?\d+(\.\d+)?$/.test(originalQuantity?.replace(',', '.'))) {
        //   dispatch(setImportedDataFromExcel(parseImportedDataFromExcel(data, originalQuantity)))
        // } else {
        //   console.log({type});
        //   // if (!['sales-activity/notify', 'sales-activity/notify', 'sales-activity/notify', 'sales-activity/notify'].includes(type)) {
        //     dispatch(setImportedDataFromExcel({ name: `CANTITATEA ESTE INCORECTA LA PRODUSUL: ${id}`, id: 'incorrect' + Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1) }))
        //   // }
        // }
      }
      return { type: 'a' }
    },
    onFailure: () => {
      console.log("error occured custom, type: ", type);
      // if (type === 'product/goods/shop/code') {
      //   if (parseFloat(originalQuantity) >= 0 && /^[+-]?\d+(\.\d+)?$/.test(originalQuantity?.replace(',', '.'))) {
      //     store.dispatch(setImportedDataFromExcel({ name: `CODUL ESTE INCORECT LA PRODUSUL: ${id}`, id: 'incorrect' + Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1) }))
      //   } else {
      //     store.dispatch(setImportedDataFromExcel({ name: `CODUL SI CANTITATEA ESTE INCORECTA LA PRODUSUL: ${id}`, id: 'incorrect' + Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1) }))
      //   }
      // }
      return {
        type: "a"
      };
    }
  });
}

export const parseOrgData = (data) => {
  return {
    permission: data
  }
}

export const loadAccessories = (data, dispatch, quantity) => {
  //console.log("ACCESSORRRRRR LIST ", data?.relationList, quantity)
  //dispatch(addCrtListItems(data))
  //dispatch(setPageModalData({...data, quantity}))
  data?.relationList?.filter(i => i?.relationType === "ACCESSORIES_OBLIGATORY")?.map(i =>
    dispatch(fetchAccessory(i?.relatedProductId, quantity))
  )
  return {
    type: "a"
  }
}

const fetchAccessory = (id, quantity) =>
  apiAction({
    endPoint: '/product/goods/shop' + (id ? `/${id}` : ''),
    onSuccess: (data, dispatch) => {
      const payload = { items: [{ ...data, quantity }] }
      //console.log("DATA ", data)
      dispatch(addCrtListItems(payload))
      return {
        type: "a"
      }
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a"
      }
    }
  })

const parseLocationData = data => ({
  locationId: data?.id,
  county: data?.county,
  country: data?.country,
  name: ''
})

const parsePositionData = (data) => {
  return { positionPublic: data, position: data }
}

const parseLocalDeliveryDateOptions = (data) => {
  return { localDeliveryDateOptions: data }
}

const parseCentralDeliveryDateOptions = (data) => {
  return { centralDeliveryDateOptions: data }
}

const parseImportedDataFromExcel = (data, q) => {
  q = q?.replace(',', '.')
  //const totalStock = data?.centralStockQuantity + data?.localStockQuantity
  const sellQuantityIncrement = data?.sellQuantityIncrement ? data?.sellQuantityIncrement : 1
  let roundUp = Math.ceil(parseFloat(q) / sellQuantityIncrement) * sellQuantityIncrement

  // if(roundUp > totalStock) {
  //   roundUp = totalStock?.toFixed(2)
  // }

  return { ...data, quantity: roundUp }
}

const parseDeliveryAddressData = data => ({
  ...data,
  requestStatus: data?.status,
  map:
  {
    latitude: data?.latitude ? data?.latitude : 25.857022,
    longitude: data?.longitude ? data?.longitude : 46.146793,
    zoom: 15,
  },
})

const parseAddressByOrganization = data => {
  const finalData = data?.map(item => {
    return { ...item, name: item?.name ? item.name : item?.displayAddress }
  })

  const lof = finalData;

  return { lof };
}

const parseWorkplaceOptions = data => {

  const workplaceOptions = data?.map((obj, i) => {
    return { ...obj, id: obj.value, value: i };
  });

  return { workplaceOptions: workplaceOptions }
}

const parseApiMyProfile = data => {
  return { ...data }
}

const parseOrganizationWorkplace = data => ({
  ...data,
  relationManagers: data?.contactList ?? ["LoadItemsManager1", "LoadItemsManager2"],
  phoneNumebrs: [data?.contactInfo?.phone ?? "42424242", "0745424242"], //data?contactInfo?.mobil ??
  addressShortName: data?.addressList?.[0]?.displayAddress ?? "Str Parse-ului nr.42",
  street: data?.addressList?.[0]?.street ?? "Str Parse-ului",
  number: data?.addressList?.[0]?.number ?? "nr.42",
  city: data?.name ?? "Parse city",
  county: data?.addressList?.[0]?.county ?? "PS",
  country: data?.addressList?.[0]?.country ?? "PS",
  postalcode: data?.addressList?.[0]?.zipCode ?? "--",
  schedule: data?.operatingHourList?.[0] ?? "--",//?? strange -
  storeEmail: data?.contactInfo?.email ?? "parsedemail@nodataintst.ru",
  map:
  {
    latitude: data?.addressList?.[0]?.latitude ?? 27.604444,
    longitude: data?.addressList?.[0]?.longitude ?? 47.147778,
    zoom: 15,
  },

  // data from UAT - for now this is available in postman
  // "id": "VrXRlL",
  // "type": "WORKPLACE",
  // "name": "Iasi",
  // "code": "232",
  // "alias": null,
  // "status": "OrganizationStatus.ACTIVE",
  // "addressList": [
  //     {
  //         "id": "qZN7ld",
  //         "type": "HEADQUARTER",
  //         "status": "ACTIVE",
  //         "name": null,
  //         "modifyDate": "2021-09-27T11:12:08.789+0000",
  //         "country": "RO",
  //         "county": "IS",
  //         "locationId": "lL14Wm",
  //         "street": "Cal. Chisinaului",
  //         "number": "Nr. 132",
  //         "zipCode": null,
  //         "region": null,
  //         "displayAddress": "Cal. Chisinau, Nr. 132",
  //         "extern": false,
  //         "longitude": 27.604444,
  //         "latitude": 47.147778,
  //         "contactInfo": null
  //     }
  // ],
  // "bankAccountList": [],

  // "contactInfo": {
  //     "email": "iasi@uniprest.ro",
  //     "mobil": null,
  //     "phone": "0365-882.077"
  // },
  // "description": null,
  // "presentationList": [],
  // "attachmentList": [],
  // "limitList": [],
  // "operatingHourList": [],
  // "hasAccess": false
})

const parseLocationDelivery = data => {

  if (data?.ruleList.length === 0) {
    return {
      ...data,
      beSideGeneratedId: data?.id,
      localWarehouseEnabled: false,
      centralWarehouseEnabled: false,
    }
  }

  let local, central

  // local related - start
  const localWarehouseEnabled =
    (data?.ruleList.length === 1 && !data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')) ||
      data?.ruleList?.length > 1
      ? true
      : false

  let localFirst, localMainInfo;
  if (data?.ruleList?.length === 1) {
    localFirst =
      !data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')
        ? data?.ruleList?.[0]?.deliveryTypeList
        : null
    localMainInfo =
      !data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')
        ? data?.ruleList?.[0]
        : null
  }

  if (data?.ruleList?.length === 2) {
    localFirst =
      !data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')
        ? data?.ruleList?.[0]?.deliveryTypeList
        : !data?.ruleList?.[1]?.workplaceName?.toLowerCase()?.includes('central')
          ? data?.ruleList?.[1]?.deliveryTypeList
          : null
    localMainInfo =
      !data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')
        ? data?.ruleList?.[0]
        : !data?.ruleList?.[1]?.workplaceName?.toLowerCase()?.includes('central')
          ? data?.ruleList?.[1]
          : null
  }

  if (localFirst !== null) {
    const localSecond = localFirst?.map((elem) => ({
      [elem.deliveryMethodType]: { ...elem, available: elem?.cost === null && elem?.deliveryTimeType === null ? false : true }
    }))

    local = Object.assign({}, ...localSecond, localMainInfo)
  }
  // local related - end

  // central related - start
  let centralWarehouseEnabled =
    (data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central') && data?.ruleList?.[0]?.deliveryTypeList.length) ||
      (data?.ruleList?.[1]?.workplaceName?.toLowerCase()?.includes('central') && data?.ruleList?.[1]?.deliveryTypeList.length)
      ? true
      : false

  const centralFirst = centralWarehouseEnabled
    ? data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')
      ? data?.ruleList?.[0]?.deliveryTypeList
      : data?.ruleList?.[1]?.deliveryTypeList
    : null

  const centralMainInfo = centralWarehouseEnabled
    ? data?.ruleList?.[0]?.workplaceName?.toLowerCase()?.includes('central')
      ? data?.ruleList?.[0]
      : data?.ruleList?.[1]
    : null

  if (centralWarehouseEnabled) {
    const centralSecond = centralFirst?.map((elem) => ({
      [elem.deliveryMethodType]: { ...elem, available: elem?.cost === null && elem?.deliveryTimeType === null ? false : true }
    }))

    central = Object.assign({}, ...centralSecond, centralMainInfo)

    centralFirst.forEach(element => {
      centralWarehouseEnabled = element?.deliveryTimeType !== null || element?.cost !== null
    });

    if (centralFirst.filter(e => e?.deliveryTimeType !== null || e?.cost !== null).length > 0) {
      centralWarehouseEnabled = true;
    }
    else {
      centralWarehouseEnabled = false;
    }
  }

  return {
    ...data,
    localWarehouseEnabled,
    centralWarehouseEnabled,
    local,
    localMainInfo,
    central,
    centralMainInfo,
    workplaceId: local?.workplaceId ? local?.workplaceId : central?.workplaceId,
    beSideGeneratedId: data?.id,
  }
}

const parseWebShopClient = data => ({
  ...data,
  // hqAddress: data?.addressList?.find(item => item?.type === 'HEADQUARTER')?.displayAddress,
  hqAddress: data?.addressList?.filter(element => (element?.id === data?.defaultAddressId))?.[0]?.displayAddress,
  classify: [data?.classify],
  userName: data?.user?.name,
  "paymentMethod.invoice": data?.paymentMethod?.invoice,
  "paymentMethod.bankTransfer": data?.paymentMethod?.bankTransfer,
  "paymentMethod.creditLimit": data?.paymentMethod?.creditLimit,
  "paymentMethod.bankCard": data?.paymentMethod?.bankCard,
  email: data?.contactInfo?.email,
  phone: data?.contactInfo?.phone,
  activeAddress: data?.addressList?.filter(element => (element?.id === data?.defaultAddressId))?.[0]?.displayAddress,
  addressListProcessed: data?.addressList?.map(item => ({
    ...item,
    // country: [item.country]
    map: {
      latitude: item?.latitude ?? 46,
      longitude: item?.longitude ?? 25,
      zoom: 5,
    }
  }))

})
const parseApiUserData = data => {
  const groupIdsExternal = data?.type === "EXTERNAL" ? data?.groupIds[0] : undefined
  const groupIdsInternal = data?.type === "INTERNAL" ? data?.groupIds[0] : undefined
  return {
    ...data,
    department: data?.departmentList ?? [],
    groupIdsExternal,
    groupIdsInternal,
    // firstName : data.name,
    // status: data.status?.split('.')?.[1],
    // department: data.department?.split(',')
  }
}

const relatedProductsList = data => ({
  relatedProducts: data
})

const parseApiRatingByProduct = data => {
  data = data.map((item) => {
    return {
      ...item,
      answerList: item.answerList.map((answer) => {
        return ({
          ...answer,
          answerTime: answer.answerTime ? formatDateWTime(answer.answerTime) : "-",
        })
      })
    }
  })

  return ({
    ratings: data,
  })
}

const parseApiQAByProduct = data => {
  data = data.map((item) => {
    return {
      ...item,
      answerList: item.answerList.map((answer) => {
        return ({
          ...answer,
          answerTime: answer.answerTime ? formatDateWTime(answer.answerTime) : "-",
        })
      })
    }
  })

  return ({
    questions: data,
  })
}

const parseApiCompanoProduct = data => ({
  ...data,
  sortByRanking: true,
  sortByAccessCount: false,
  volumePickerList: typeof data?.volumePickerList === 'string' ? data.volumePickerList.split(',') : data.volumePickerList,
  imageList: data?.attachmentList?.filter(item => item.type === FILE_TYPE.IMAGE).map((item) => {
    return {
      ...item,
      url: item?.reference,
    }
  }),
  videoList: data?.attachmentList?.filter(item => item.type === FILE_TYPE.VIDEO).map((item) => {
    return {
      ...item,
      url: item?.reference,
    }
  }),
})

const parseApiCompanoProductInfo = data => ({
  ...data,
})

const parseApiDiscountGrid = data => ({
  ...data,
  productGroup: `Clasa de produs ${data.productGroup}`,
  clientCount: `${data.clientCount} Companii`,
  productCount: `${data.productCount} Produse`,
})

const parseAssociatedCompanies = data => ({
  associatedCompanies: [...data]
})
const parseAssociatedProducts = data => ({
  associatedProducts: [...data]
})

const parseApiDiscountPackage = data => ({
  ...data,
  // productCategory: data.productCategoryList,
  // productSupplier: data.productSupplierList,
  // brandName: data.productBrandList,

  // workplaceList: data.workplaceList,

  //Hint: we need itemId for drop-down options
  products: data.goodsList?.length > 0 ? data.goodsList.map((item) => { return { ...item, id: item.goodsId } }) : null,
  clientList: data.clientList?.length > 0 ? data.clientList.map((item) => { return { id: item } }) : null,

  //TODO: hardcode
  companyApplicability:
    data?.workplaceList?.length > 0 || data?.clientClassifyList?.length > 0 ? "category"
      : data.clientList.length > 0 ? "individual" : "general",
  productApplicability:
    //data.productBrandList.length > 0 || data.productSupplierList.length > 0 || data.productCategoryList.length > 0 ? "category" :
    data.goodsList.length > 0 ? "individual" : "general",
})
const parseApiDiscountFix = data => ({
  ...data,
})
const parseApiDiscountVolume = data => ({
  ...data,

  //Hint: we need itemId for drop-down options
  clientList: data.clientList?.length > 0 ? data.clientList.map((item) => { return { id: item } }) : null,

  discountList: data?.quantityList, /*data.quantityList.map(item => {
    return { discount: item.discount, quantity: item.quantity }
  }) : null,*/

  product: {
    id: data.productId,
    name: data.productName,
    code: data.code,
    mainImage: data.mainImage,
  },
  //TODO: hardcode
  companyApplicability:
    data?.workplaceList?.length > 0 || data?.clientClassifyList?.length > 0 ? "category"
      : data.clientList.length > 0 ? "individual" : "general",
  // productApplicability:
  //   data.productBrandList.length > 0 || data.productSupplierList.length > 0 || data.productCategoryList.length > 0 ? "category"
  //     : data.productList.length > 0 ? "individual" : "general",
})

const parseApiProductRating = data => ({
  answers: data.answerList.map((item) => {
    return {
      id: item.id,
      author: item.userName,
      createdBy: item.organizationName,
      date: formatDateWTime(item.answerTime),
      value: item.answer,
      attachmentList: item.attachmentList ?? [],
    }
  }),
  ...data,
})

const parseApiProductGoodsBadge = data => ({
  ...data,
  productCategory: data.productCategoryList,
  productSupplier: data.productSupplierList,
  brandName: data.productBrandList,
  // workplaceList: data.workplaceList,

  //Hint: we need itemId for drop-down options
  products: data.productList?.length > 0 ? data.productList.map((item) => { return { id: item } }) : null,
  clientList: data.clientList?.length > 0 ? data.clientList.map((item) => { return { id: item } }) : null,

  //TODO: hardcode
  companyApplicability:
    data?.workplaceList?.length > 0 || data?.clientClassifyList?.length > 0 ? "category"
      : data.clientList.length > 0 ? "individual" : "general",
  productApplicability:
    data.productBrandList.length > 0 || data.productSupplierList.length > 0 || data.productCategoryList.length > 0 ? "category"
      : data.productList.length > 0 ? "individual" : "general",
})

const parseApiPorductQuestion = data => ({
  answers: data.answerList.filter(a => a.type === "ANSWER").map((item) => {
    return {
      id: item.id,
      author: item.userName,
      createdBy: item.organizationName,
      date: formatDateWTime(item.answerTime),
      value: item.answer,
      attachmentList: item.attachmentList ?? [],
    }
  }),
  comments: data.answerList.filter(a => a.type === "COMMENT").map((item) => {
    return {
      id: item.id,
      author: item.userName,
      createdBy: item.organizationName,
      date: formatDateWTime(item.answerTime),
      value: item.answer,
      //attachmentList: item.attachmentList ?? [],
    }
  }),
  ...data,
})

const parseApiProductStockList = data => ({
  stockData: [...data],
})

const parseApiDocument = data => ({
  ...data,
  products: data?.productIdList?.length > 0 ? data.productIdList.map(product => ({ id: product })) : null
})

const parseProductDocumentData = data => ({
  documentList: data
})