import { lazy } from "react";

export const activitiesRoutes = [
    {
        exact: true,
        path: "/admin/activity/list",
        component: lazy(() => import("../views/activitiesList")),
    },
    {
        exact: true,
        path: "/admin/activity/form",
        component: lazy(() => import("../views/form/form")),
    },
];