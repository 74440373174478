export const writeGroupPermission = "GROUP_WRITE"

export const permissionOptions = [
    { label: "FARA PERMISIUNE", value: "0" },
    { label: "DREPT DE CITIRE", value: "READ" },
    { label: "DREPT DE MODIFICARE", value: "WRITE" },
    { label: "DREPT DE STERGERE", value: "DELETE" },
];

export const mockValues = {
    name: "Manager de ofertare",
    code: "RFPM",
    lastUpdate: "21 Mai 2021 - 12:20",
    createDate: "21 Mai 2021 - 12:20",
    createdBy: "Ionut Popescu",
    updatedBy: "Andrei Popescu",
    asociati: "14.421",
    phonePassword: true,
    emailMagicLink: true,
    roles: [
        { id: 1, shortName: "Nume scurt Companie", permissionCount: 14, role: "Tip Rol A" },
        { id: 2, shortName: "Nume scurt Companie", permissionCount: 5, role: "Ofertant" },
        { id: 3, shortName: "Uniprest Unlimited", permissionCount: 9, role: "Ofertant" },
    ],
};

export const rolePermissions = [
    'GROUP', 'USER', 'ACTIVITY', 'DEAL', 'FORM_MODEL', 'LEAD', 'MICROSITE', 'PIPELINE', 'REPORT'
]