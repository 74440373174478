import {Redirect} from 'react-router'
import {useUserInfo} from 'utils/useUserInfo'
import Config from "config/config";
import store from "../../store/store";
import {SET_AUTH_REDIRECT_URL} from "../../store/modules/auth";

function AuthGuard(props) {
    // const {noGuest} = props
    const {groupId, companyType, adminAccess} = useUserInfo()
    const pathName = `${window.location.pathname}${window.location.search}`

    if (pathName.includes('/admin')) {
        if (!adminAccess) {
            store.dispatch({type: SET_AUTH_REDIRECT_URL, payload: pathName});
            return <Redirect to={Config.unauthenticatedMainUrl}/>
        } else {
            store.dispatch({type: SET_AUTH_REDIRECT_URL, payload: ""});
        }
    }

    return props.children
}

export default AuthGuard
