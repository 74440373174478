/* eslint-disable react-hooks/exhaustive-deps */
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// import getFieldTranslation from 'utils/formTranslation';
// import { useHistory } from "react-router-dom";
import { useSelector } from "store/store";
import { history } from "utils/history"
import { useController } from "react-hook-form";

import { selectFormOptions } from "store/selectors/form/formOptions.selector";
import { selectFromLabels } from "store/selectors/form/formLabels.selecto";
import { useDispatch } from "react-redux";
import { setAllFieldErrors, setPageEdit, setPageModal } from "store/modules/page";
import BigModal from "components/_modals/BigModal";

// YUP locale is initialized in the App.js component with the language from REDUX
// filed names are coming from the form selector. The used selector takes into account the language from REDUX user
// form name is used to track down options and field names in the correspondent selctor.

export const cancelEdit = () => {
  history.push(
    window.location.pathname.replace("/edit", "") + window.location.search
  );
}

export default function useMagicForm(props) {
  const dispatch = useDispatch()

  const {
    schema,
    defaultValues = {},
    formName = "general",
    edit,
    onSubmit,
    onError = (errors, e) => {
      console.log("errors", errors)
      dispatch(setAllFieldErrors(errors))
    },
    showFieldNames,
    location,
  } = props;

  let isEdit = useSelector(state => state.page?.isEdit)
  const crtFormItem = useSelector((state) => state?.page?.crtFormItem);

  isEdit = edit || isEdit
  const url = window.location.pathname.split("/")
  // const isEdit = edit ?? (url[url.length-1] === "edit" || url[url.length-1] === "add");
  // const history = useHistory();
  const { allOptions } = useSelector((state) => selectFormOptions(state));
  const { formTX } = useSelector((state) => selectFromLabels(state)); //TODO: typo?
  const pageFiledErrors = useSelector((state) => state.page?.fieldErrors); //usually got from API
  // useEffect(() => {
  //   reset(defaultValues)
  // }, [defaultValues])
  useEffect(() => {
    if (location?.state?.isEdit) dispatch(setPageEdit(location?.state?.isEdit))
  }, [location?.state?.isEdit])

  const {
    handleSubmit,
    getValues,
    unregister,
    register,
    formState,
    control,
    errors,
    reset,
    formState: { isDirty, isValid },
    watch,
  } = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
    mode: "onChange",
    defaultValues,
  });

  const setEdit = () => {
    dispatch(setPageEdit(true))
    // history.push(window.location.pathname + "/edit" + window.location.search);
  };
  const cancelEdit = () => {
    dispatch(setPageEdit(false))
    reset(crtFormItem)
    // history.push(window.location.pathname + "/edit" + window.location.search);
  };

  const getFieldProps = (fieldName, options = null, checkbox = false) => {
    let fieldProps = {
      name: fieldName,
      readOnly: isEdit !== undefined ? !isEdit : false,
      error: pageFiledErrors?.[fieldName]?.message ?? errors?.[fieldName]?.message,
      label: showFieldNames ? fieldName : getFieldTranslation(formTX, fieldName, "label", formName),
      placeholder: getFieldTranslation(
        formTX,
        fieldName,
        "placeholder",
        formName
      ),
    };
    return {
      ...fieldProps,
      control,
      options: getFormOptions(allOptions, formName, fieldName),
      // unregister,
    };
  };

  const submitForm = handleSubmit(onSubmit, onError);
  return {
    getFieldProps,
    getProps: getFieldProps,
    handleSubmit,
    setEdit,
    cancelEdit,
    reset,
    getValues,
    disableSubmit: !(isDirty && isValid),
    isDirty,
    isValid,
    formState,
    isEdit,
    submitForm,
    watch,
    control,
    register
  };
}

const getFormOptions = (allOptions, formName, fieldName) => {
  return (
    allOptions?.[formName]?.[fieldName] ??
    allOptions?.general?.[fieldName] ??
    []
  );
  // if (result[0] === undefined) console.warn(`No options for CustomSelect with fieldName: ${fieldName} in form name: ${formName}. Check options selector, form name and field name. Make sure they match to the thing in the selector`);
};

const getFieldTranslation = (formTX, fieldName, inputPart, formName) => {
  return (
    formTX?.[formName]?.[fieldName]?.[inputPart] ??
    formTX.general?.[fieldName]?.[inputPart] ??
    (inputPart === "label" ? fieldName : null)
  );
};

// Simple List Item
// used to capture a list of IDs froma custom list, with optional modal
// child component props:
// selectedItems /  - filtered array of options based on selected IDs
// removeItem(id) - removes the selected item from values
// disabled - true if the field is diabled
// addItems([id]) - used to add items to the selected valus. It takes in a list of IDs. it also checks for duplicates when setting them

// options - Full list of items that can be used for modal and local list reprezentation. It required an ID field used to identify each entry. Usually it's not usefull
// value - [id] >> array of IDs. The ids of the selected options. Usually it's not usefull
// onChange([id]) - used to directly set the selected values. Usually it's not usefull

export const SimpleListInput = (props) => {
  const { name, control, options, disabled } = props;
  const {
    field: {value, onChange, onBlur},
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: [],
  });

        const selectedItems = options.filter((item) =>
          value?.includes(item.id)
        );
        const unselectedItems = options.filter(
          (item) => !value?.includes(item.id)
        );
        const addItems = (items) =>
          onChange(
            [...value, ...items].filter((v, i, a) => a.indexOf(v) === i)
          );
        const removeItem = (id) => onChange(value.filter((i) => i !== id));
        return React.cloneElement(props.children, {
          addItems,
          selectedItems,
          unselectedItems,
          removeItem,
          disabled,
          onChange,
          value,
          options,
        });

};

// SubFormInput
// used to keep an array of objects. Each array item can be individually deleted, you can also add to the array. The form of the object you add is controlled by the add modal that you create
// child component props:
// value - [{field1, field2}] >> array of objects. each object keeps the values for the set of fields
// removeItem(index) - removes the the item in the array at the specified index
// upsertItem({filed1, field2}, withReset) >> adds to the array of fields or updates the current openIndex in the array
// appendItem({filed1}) >> adds element to the array of fields
// openIndex >> null / number >> it controlls what object in the array is reprezented in the add / edit modal
// setOpenIndex >> used to set the above
// disabled - true if the field is diabled

// we recomand this syntax to open the add modal:
// <AddItemModal {...{addItem, setOpenIndex, defaultValues:value[openIndex]}}/>

export const SubFormInput = (props) => {console.log("SUBFORM ", props)
  const { name, control, options, disabled, getProps } = props;
  const [openIndex, setOpenIndex] = useState(null);
  const dispatch = useDispatch()

  const {
    field: {value, onChange, onBlur},
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  const addAction = () => {
    setOpenIndex(value?.length ?? 1)
    dispatch(setPageModal(name))
  }
  const removeItem = (index) =>
    onChange(value.filter((v, i) => i !== index));
    const upsertItem = (data, withReset = true, _openIndex = openIndex) => {
      onChange(
        _openIndex >= value?.length
          ? [...value, data]
          : value.map((item, index) => (_openIndex === index ? data : item))
      );
      if (withReset) setOpenIndex(null);
    }
    const appendItem = data =>  {
        onChange( value ? [...value, data] : [data])
    }

    const changeItem = (editItem, editIndex) => onChange(
      value.map((item, index) =>
        index === editIndex ? editItem : item
      )
    )
    const simpleModalComponent = props => <BigModal open={true} grid closeAction={() => setOpenIndex(null)} //props supplied from this file
      disableSubmit={props.disableSubmit} saveAction={props.submitForm} //props req fom the comp where it will be mounted
      >
        {props.formContent}
    </BigModal>

    return React.cloneElement(props.children, {
      value,
      name,
      addAction,
      upsertItem,
      appendItem,
      setOpenIndex,
      openIndex,
      removeItem,
      editItem : setOpenIndex,
      disabled,
      onChange, changeItem,
      simpleModalComponent,
    });

};

export const SubFormInputModal = (props) => {
  const {schema, grid, formContent, upsertItem, value, openIndex, setOpenIndex, title} = props
  const onSubmit = (data, e) => upsertItem(data)
  const defaultValues = value?.[openIndex]
  const { submitForm, disableSubmit, getProps, reset, getValues,
  } = useMagicForm({ defaultValues, onSubmit, schema, isEdit: true })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])
  if(openIndex === null) return null

  return <BigModal open={true} {...{title, grid, disableSubmit}}
    closeAction={() => setOpenIndex(null)}
    saveAction={submitForm}
  >
    {formContent({getProps, getValues})}
</BigModal>
}


export const WhateverFormModal = (props) => {
  const { name, control, schema, title, grid, formContent} = props;
  const {
    field: {value, onChange},
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: {},
  });
  const defaultValues = value
  const onSubmit = (data, e) => onChange(data)

  const magicFormResult = useMagicForm({ defaultValues, onSubmit, schema, edit: true })

  const { submitForm, disableSubmit, reset,} = magicFormResult
  useEffect(() => {
    reset(value)
  }, [value])

  return <BigModal {...{title, name, grid, disableSubmit, submitForm}}  >
    {formContent(magicFormResult)}
</BigModal>
};

export const CustomFormField = (props) => {
  const { name, control} = props;
  const {
    field: {value, onChange},
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: {},
  });

  return React.cloneElement(props.children, {
    ...props,
    value, onChange
  });
};
