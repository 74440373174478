import * as yup from "yup"
import moment from "moment";

import {getDataAsync} from "store/justActions/miscApiInteractions";

export const schema = yup.object().shape({
    // firstName: yup.string().required().min(4)
})

export const getLeadSvgProps = (type: string) => {
    switch (type) {
        case 'NOTIFY':
            return {bgColor: 'cyan-20', stroke: '#00539A', name: 'notifications-1'}
        case 'NOTE':
            return {bgColor: 'magenta-20', stroke: '#D12771', name: 'document-10'}
        case 'MEETING':
            return {bgColor: 'blue-20', stroke: '#0043CE', name: 'upload-1'}
        case 'INTERFACE':
            return {bgColor: 'gray-20', stroke: '#6F6F6F', name: 'elements-5'}
        case 'EMAIL':
            return {bgColor: 'teal-20', stroke: '#007D79', name: 'message-2'}
        case 'TASK':
            return {bgColor: 'purple-20', stroke: '#8A3FFC', name: 'task-1'}
        default:
            return {bgColor: 'blue-20', stroke: '#161616', name: 'elements-5'}
    }
}

export const getLeadStatusSvg = (type: string) => {
    switch (type) {
        case 'ONGOING':
            return 'check1'
        case 'INACTIVE':
            return 'check2'
        case 'COMPLETED':
            return 'check3'
        default:
            return 'check4'
    }
}

export const parseApiLeadData = (data: any) => {
    const {dataManagement, subscribe} = data?.agreementInfo ?? {}
    return {
        ...data,
        email: data?.contactInfo?.email ?? '-',
        phone: data?.contactInfo?.phone ?? '-',
        idnp: data?.registrationNr ?? '-',
        birthDate: data?.personalInfo?.birthDate?.replaceAll('-', '.'),
        maritalType: data?.personalInfo?.maritalStatus ?? '-',
        childCount: data?.personalInfo?.childCount,
        employer: data?.personalInfo?.employer,
        fieldOfActivity: data?.personalInfo?.fieldOfActivity ?? '-',
        profession: data?.personalInfo?.profession ?? '-',
        dataManagement: dataManagement && moment(dataManagement)?.format("DD.MM.YY HH:MM"),
        subscribe: subscribe && moment(subscribe)?.format("DD.MM.YY HH:MM"),
        communicationChannelList: data?.agreementInfo?.communicationChannelList,
        addressList: data?.addressList,
        education: data?.personalInfo?.education ?? '-',
        residenceType: data?.personalInfo?.residenceType ?? '-',
        employed: data?.personalInfo?.employed === true ? "Da" : data?.personalInfo?.employed === false ? "Nu" : '-',
        employerName: data?.personalInfo?.employerName ?? '-',
        contractType: data?.personalInfo?.contractType ?? '-',
        workSeniority: data?.personalInfo?.workSeniority ? moment(data?.personalInfo?.workSeniority)?.format("DD.MM.YYYY") : '-',
    }
}


export const parseForApiLeadActivity = ({data, itemId}: any) => {
    return {
        name: data?.activityName,
        id: null,
        status: "COMPLETED",
        salesDealId: null,
        leadId: itemId,
        dueDate: null,
        info: data?.noteDescription
    }
}

export const getAddressLocation = (locationId: string, setItemLocation: Function) => {
    Promise.all([getDataAsync(`/location/${locationId}`, "GET", null)]).then((data) => {
        setItemLocation(data?.[0]?.name)
    })
}