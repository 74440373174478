import React from 'react';
import {
    Link,
    useRouteMatch,
    LinkProps,
} from "react-router-dom";

const MagicSideBarLink = ({ children, to, ...props }: LinkProps) => {
    let match = useRouteMatch(to as string);

    return (
        <div className={`${match ? 'active' : '' }`}>
            <Link
                to={to}
                {...props}
            >
                {children}
            </Link>
        </div>
    );
}

export default MagicSideBarLink;