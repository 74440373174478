import {MenuItem} from "../components/MagicSidebar/menu";
import {sharedStart} from "../../../utils/stringUtils";

const mapMenuItems = (menuItems: MenuItem[]) => {
    return menuItems.map((item: MenuItem) => ({
        ...item,
        actionLink: item.children.length ? findCommonActionInsideChild(item) : item.actionLink
    }));
}

const findCommonActionInsideChild = (menuItem: MenuItem) => {
    const actionLinks = menuItem.children.map((item: MenuItem) => item.actionLink);
    return sharedStart(actionLinks);
}

export {mapMenuItems}
