import React, {createContext} from "react";

export const OverlayContext = createContext<any>(null);

const OverlayContextProvider = ({children}: any) => {
    const [visibleOverlay, setVisibleOverlay] = React.useState(false);

    const modalContext = React.useMemo(
        () => ({
            visibleOverlay,
            setVisibleOverlay,
        }),
        [visibleOverlay]
    );


    return <OverlayContext.Provider value={modalContext}>{children}</OverlayContext.Provider>;
};

export default OverlayContextProvider;