import store from "store/store"
import {apiAction, getRefreshToken, setRefreshToken} from "../middleware/api"
import {SET_AUTHENTICATED} from "store/modules/auth"
import {toastr} from "react-redux-toastr"
import {RESET_USER_INFO, setUserGroupsId, setUserInfo, setUserPermissions} from "store/modules/user"
import {history} from "utils/history"
import {getDataAsync} from "utils/getDataAsync"
import {AxiosError} from "axios";
import {getGroups} from "./miscApiInteractions";

const dispatch = store.dispatch

export const logIn = (loginName: string, password: string) => getDataAsync<any>('/public/login', {
    method: 'POST',
    data: {loginName, password, client: 'front'}
}).then(data => {
    setRefreshToken(data?.refreshToken)
    dispatch(setUserInfo({groupId: data?.role?.groupId, ...data?.role?.user}))
    dispatch(setUserPermissions(data?.role?.permissions))
    Promise.all([getGroups()]).then((response) => dispatch(setUserGroupsId(
        response?.[0]?.items?.map((item: any) => (item?.id))
    )))
    return [data, null]
}).catch((err: AxiosError) => [null, err]) as Promise<[unknown, null] | [null, AxiosError]>


export const logout = () => {
    return apiAction({
    endPoint: "/logout",
    method: "POST",
    extraHeaders: { rftok: getRefreshToken() } as any,
    // @ts-ignore
    onSuccess: (data, dispatch) => {
      dispatch(setUserInfo({ groupId: undefined }));
      history.push('/login')
      return {
        type: "a"
      }
    },
    // @ts-ignore
    onFailure: (error, dispatch) => {
      return {
        type: "a"
      }
    }
  })
}

// @ts-ignore
export const resetPassword = (nPass, nPassAgain, passwKey, setSuccsessReset, setenterNewPasswordCard) => {
  return apiAction({
    endPoint: '/public/password-reset',
    method: "POST",
      // @ts-ignore
    data: {
      newPassword: nPass,
      newPasswordAgain: nPassAgain,
      key: passwKey
    },
      // @ts-ignore
    onSuccess: (data, dispatch) => {
      setSuccsessReset(true);
      setenterNewPasswordCard(false)
      return {
        type: "a"
      }
    },
      // @ts-ignore
    onFailure: (error, dispatch) => {
      if (nPass !== nPassAgain) {
          // @ts-ignore
        toastr.error("Parolele nu se potrivesc!")
      }
      else {
          // @ts-ignore
        toastr.error("A aparut o eroare la resetarea parolei!")
      }
      return {
        type: "a"
      }
    }
  })
}

// @ts-ignore
export const forgotPassword = (identity, setResetPasswordCard, setSentResetPasswordMessae, setLoginCard) => {
  return apiAction({
    endPoint: "/public/password-forgot",
    method: "POST",
      // @ts-ignore
    data: {
      emailAddress: identity,
      client: "front"
    },
      // @ts-ignore
    onSuccess: (data, dispatch) => {
      setResetPasswordCard(false)
      setSentResetPasswordMessae(true)
      setLoginCard(false)
      return {
        type: "a"
      }
    },
      // @ts-ignore
    onFailure: (error, dispatch) => {
        // @ts-ignore
      toastr.error("Utilizatorul nu exista!")
      return {
        type: "a"
      }
    }
  })
}

export const refreshJavaToken = () => {
    // @ts-ignore
  const rftok = store.getState().auth?.userData?.refreshToken
  return apiAction({
    endPoint: "/public/refresh",
    method: "POST",
      // @ts-ignore
    data: {
      rftok,
    },
    qs: true,
      // @ts-ignore
    onSuccess: (data, dispatch) => {
      console.log("refresh data", data);
      return {
        type: "a",
        // payload: data
      };
    },
      // @ts-ignore
    onFailure: (error, dispatch) => {
      // When refresh token fails, set auth false, go to login
      dispatch({ type: SET_AUTHENTICATED, payload: false }) //temp usage before rele implementation
      dispatch({ type: RESET_USER_INFO })
      history.push("/login");

      return {
        type: "a"
      };
    }
  });
}

export const removeJavaToken = () => {
  return apiAction({
    endPoint: "/logout",
    method: "POST",
      // @ts-ignore
    onSuccess: (data) => {
      return {
        type: "a"
      }
    },
      // @ts-ignore
    onFailure: (error, dispatch) => {
      return {
        type: "a"
      }
    }
  })
}