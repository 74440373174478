const SET_TABLE_SEARCH_STRING = "table/SET_SEARCH_STRING"
const SET_TABLE_ROWS = "table/SET_TABLE_ROWS"
const RESET_TABLE = "table/RESET_TABLE"
const ADD_TABLE_ROWS = "table/ADD_TABLE_ROWS"
const SET_SELECTED_FILTERS = "table/SET_SELECTED_FILTERS"


const initialState = {
    rows: [],
    selectedFilters: [],
    searchString: '',
    lastRowHint: undefined,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_TABLE:
            return {
                initialState,
            };
        case SET_TABLE_SEARCH_STRING:
            return {
                ...state,
                searchString: action.payload,
            };
        case SET_TABLE_ROWS:
            return {
                ...state,
                rows: action.payload.rows,
            };
        case ADD_TABLE_ROWS:
            return {
                ...state,
                rows: [...state.rows, ...action.payload.rows],
                lastRowHint: action.payload.lastRowHint,
            };
        case SET_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload,
            };
        default:
            return state;
    }
}



export const resetTable = () => {
    return {
        type: RESET_TABLE,
    };
}

export const setTableSearchString = (string) => {
    return {
        payload: string,
        type: SET_TABLE_SEARCH_STRING,
    };
}

export const setTableRows = (rows) => {
    return {
        payload: {rows},
        type: SET_TABLE_ROWS,
    };
}

export const addTableRows = (rows) => {
    return {
        payload: {rows},
        type: SET_TABLE_ROWS,
    };
}