import {apiAction, dynamoAction, reDispatch} from 'store/middleware/api';
import {getSKfromType} from 'utils/geenrateIds';
import {getUrlParam} from 'utils/history';
import store from '../store'
import {setApiResponse} from './auth';
import {setDataItems} from './dataView';
import {SET_DICTIONARY} from './backData';
import {ADD_ITEMS, SET_ITEMS} from './dataView';
import {clearLoadingIndicator, setLoadingIndicator, SET_CRT_FORM_ITEM} from './page';
import moment from "moment";
import {toastr} from "react-redux-toastr";


//TODO: move to a normal place :)
// *** Search operators
// EQUAL("=="),
// NOT_EQUAL("!="),
// LESS("<"),
// LESS_OR_EQUAL("<="),
// GREATER(">"),
// GREATER_OR_EQUAL(">="),
// BETWEEN("between"),
// NOT_BETWEEN("!between"),
// LIKE("~"),
// NOT_LIKE("!~"),
// STARTS_WITH("starts_with"),
// ENDS_WITH("ends_with"),
// EMPTY("empty"),
// NOT_EMPTY("!empty"),
// SUBQUERY_IN("subquery_in"),
// NOT_SUBQUERY_IN("!subquery_in"),
// IN("in"),
// NOT_IN("!in"),

// OR("or"),
// AND("and");

const productSearchData = {
    "firstRow": 1000,
    "pageSize": 10,
    "sortField": null,
    "sortAsc": false,
    "filters": [],
    "filterText": "",
    "searchTerm": null,
    "jsonLogicText": null,
    "jsonLogic": {
        "operation": "OR",
        "children": [],
        "field": null,
        "value": null
    }
}

// selectedFilters: {
//   'FLT-SMaaaa00': [ '2', '3']
// }


export const filterKeys = {
    "FLT-SMaaaa00": 'brandName',
    "FLT-SMaaaa11": 'brandName',
    "FLT-GeneralStatus01": 'status',
    "FLT-ActivitiesStatus": 'status',
    "FLT-DatePickerFilter": 'date',
    "FLT-FormsStatus": 'status',
    "FLT-DealActivityType": 'status',
    "FLT-ReportBranch": 'branchCode',
    "FLT-ReportDealStatus": 'dealStatus',
    "FLT-ReportDealStartDatePickerFilter": 'dealStartDate',
    "FLT-ReportModifyDatePickerFilter": 'lastModifiedDate',
    "FLT-CompanyStatus": 'status',
    "FLT-OrderStatus": 'status',
    "FLT-PersPosition": 'position',
    "FLT-b2bProductStatus": 'status',
    "FLT-RoleStatus": 'status',
    "FLT-Applicability": 'userDirectory',
    "FLT-Source": "source",
    "FLT-Source-Client": "source",
    "FLT-InvStatus": "status",
    'FLT-CLIENT': 'clientId',
    'FLT-UserType': 'type',
    'FLT-CLIENT-ID': 'organizationId',
    'FLT-Agent': 'agent',
    'FLT-Ranking': 'ranking',
}

//Hint: this is a workaround solution, until API will have only one type of search (at least as request inputs)
export const createClassicFilters = () => {
    let state = store.getState();
    let result = []
    const selectedFilters = state?.dataView?.selectedFilters
    const dateFilters = state?.dataView?.dateFilters
    
    dateFilters !== undefined && dateFilters.map((item, index) => {
        if (item?.startDate instanceof Date && item?.endDate instanceof Date) {
            result.push(
                {"beginParenthesis": "(", "field": item?.field, "relation": ">", "value": item?.startDate?.toISOString(), "operator": "AND"},
                dateFilters.length > 1 && index < dateFilters.length -1
                    ? {"field": item?.field, "relation": "<", "value": item?.endDate?.toISOString(), "endParenthesis": ")", "operator": "AND"}
                    : {"field": item?.field, "relation": "<", "value": item?.endDate?.toISOString(), "endParenthesis": ")"}
            )
        }
    })
    //next section is used by the brandName >> version 1 for general multiselect...
    for (const filterId in selectedFilters) {
        const filterValues = selectedFilters?.[filterId]

        if (filterValues?.length > 0 && ['FLT-SMaaaa11', 'FLT-SMaaaa00', 'FLT-GeneralStatus01', "FLT-ActivitiesStatus", "FLT-DatePickerFilter", "FLT-FormsStatus", "FLT-DealActivityType", "FLT-ReportBranch", "FLT-ReportDealStatus", "FLT-ReportDealStartDatePickerFilter", "FLT-ReportModifyDatePickerFilter", "FLT-CompanyStatus", "FLT-InvStatus", 'FLT-RoleStatus', 'FLT-Applicability', "FLT-Source", "FLT-Source-Client", 'FLT-PersPosition', 'FLT-OrderStatus', 'FLT-b2bProductStatus', 'FLT-UserType', 'FLT-Agent'].includes(filterId)) {
            result.push({
                "beginParenthesis": "(",
                "field": filterKeys?.[filterId],
                "relation": "in",
                "value": filterValues?.join(','),
                "operator": "AND",
                "endParenthesis": ")",
            })
        }
        if (filterValues?.length > 0 && ['FLT-CLIENT-ID', 'FLT-CLIENT'].includes(filterId)) {
            result.push({
                "beginParenthesis": "(",
                //"field": "organizationId",
                "field": filterKeys?.[filterId],
                //"relation": "in", - left here 'cause I bet it will be changed to multi select LOL
                "relation": "=",
                //"value": "AZQQaZ",
                "value": filterValues?.join(''),
                //"operator": "AND",
                "hashed": "true",
                "endParenthesis": ")",
            })
        }
    }
    return result
}

const processGuestSort = () => {
    let state = store.getState();
    const selectedFilters = state?.dataView?.selectedFilters
    const guestSortFilter = selectedFilters?.["FLT-sort00p1"]?.[0]
    const guestOrderCreateDateFilter = selectedFilters?.["FLT-sortOderDate"]?.[0]
    let [sortField, sortAsc] = guestSortFilter ? guestSortFilter?.split('|') : guestOrderCreateDateFilter ? guestOrderCreateDateFilter?.split('|') : [null, null]
    sortAsc = Boolean(sortAsc === "true")
    return [sortField, sortAsc]
}
export const createFilters = () => {
    let state = store.getState();
    let result = []
    const selectedFilters = state?.dataView?.selectedFilters
    //next section is used by the brandName >> version 1 for general multiselect...
    for (const filterId in selectedFilters) {
        const filterValues = selectedFilters?.[filterId]
        if (filterValues?.length > 0 && (filterId === 'FLT-SMaaaa11' || filterId === 'FLT-SMaaaa00')) {
            result.push(
                filterValues?.includes("Fara Brand")
                    ?
                    {
                        "operation": "OR",
                        "children": [
                            {
                                "operation": "EMPTY",
                                "children": [
                                    {
                                        "operation": null,
                                        "children": null,
                                        "field": "brandName",
                                        "value": "Fara Brand"
                                    }
                                ],
                                "field": null,
                                "value": null
                            },
                            {
                                "operation": "IN",
                                "children": [
                                    {
                                        "operation": null,
                                        "children": null,
                                        "field": filterKeys?.[filterId],
                                        "value": filterValues?.join(','),
                                    }
                                ],
                                "field": null,
                                "value": null
                            },
                        ],
                        "field": null,
                        "value": null
                    }
                    :
                    {
                        "operation": "IN",
                        "children": [
                            {
                                "operation": null,
                                "children": null,
                                "field": filterKeys?.[filterId],
                                "value": filterValues?.join(','),
                            }
                        ],
                        "field": null,
                        "value": null
                    }
            )
        }

        if (filterValues?.length > 0 && ['FLT-GeneralStatus01', 'FLT-ActivitiesStatus', 'FLT-DatePickerFilter', 'FLT-FormsStatus', 'FLT-DealActivityType', 'FLT-ReportBranch', 'FLT-ReportDealStatus', 'FLT-ReportDealStartDatePickerFilter', 'FLT-ReportModifyDatePickerFilter', 'FLT-RoleStatus', 'FLT-Applicability'].includes(filterId)) {
            result.push({ //temp disable untill we get the logic working fine
                "operation": "IN",
                "children": null,
                "field": filterKeys?.[filterId],
                "value": filterValues?.join(','),
            })
        }
        if (filterValues?.length > 0 && filterId === 'FLT-Ranking') {
            result.push(
                {
                    "operation": "OR",
                    "children": [
                        {
                            "operation": "EQUAL",
                            "children": [
                                {
                                    "operation": null,
                                    "children": null,
                                    "field": filterKeys?.[filterId],
                                    "value": filterValues?.[0]
                                }
                            ],
                            "field": null,
                            "value": null
                        }
                    ],
                    "field": null,
                    "value": null
                }
            )
        }

        if (filterValues?.length > 0 && ['FLT-b2bProductStatus'].includes(filterId)) {//'FLT-CLIENT'
            result.push({ //temp disable untill we get the logic working fine
                "operation": "IN",
                "children": [
                    {
                        "operation": null,
                        "children": null,
                        "field": filterKeys?.[filterId],
                        "value": filterValues?.join(',')
                    }
                ],
                "field": null,
                "value": null
            })
        }
    }

    //next section looks for category filter.
    //work in progress
    // const treeCode = selectedFilters?.["FLT-PCATEG01"] ?? selectedFilters?.["FLT-PCATEG01"] ?? selectedFilters?.["FLT-PCATEG00"]
    // const treeCode = undefined
    const treeCode = selectedFilters?.["FLT-CATMEGA1"]?.[0] ?? selectedFilters?.["FLT-CATEG001"]?.[0]
    //console.log("createFilters", result, treeCode);
    if (treeCode) result.push({
        "operation": "STARTS_WITH",
        "children": [
            {
                "operation": null,
                "children": null,
                "field": "categoryTreeCode",
                "value": treeCode,
            }
        ],
        "field": null,
        "value": null
    })
    return result
}

export const searchStuff = ({
                                type = "product",
                                sortAsc = true,
                                sortField,
                                firstRow = 0,
                                pageSize = 25,
                                searchTerm,
                                reload,
                                target,
                                dictionary,
                                rangeDate = [],
                                filterFields = [],
                                countResult = true
                            }) => {
    
    let state = store.getState();
    const nextAction = target !== "menuSearch" && reload ? SET_ITEMS : ADD_ITEMS
    let [guestSortField, guestSortAsc] = processGuestSort()
    
    if (type === "location") {
        guestSortAsc = sortAsc;
        guestSortField = sortField
    }
    if (type === "organization/lead") {
        guestSortAsc = 'false';
        guestSortField = 'date'
    }
    let itemId
    if (['stock'].includes(type)) { // exception to add some Id in the search url
        itemId = getUrlParam('id')
        if (!itemId) return {type: 'a'}
    }
    if (type === 'organization/lead/activities') itemId = getUrlParam('id')
    if (type === 'pipeline/sales-deal') itemId = (window.location.pathname).split('/')[3]
    if (type === 'sales-deal') itemId = getUrlParam('id')
    
    if (type === 'otpm/report/loans' || type === 'otpm/report/callme') {
        itemId = getUrlParam('id')
        guestSortAsc = false;
        guestSortField = 'lastModifiedDate'
    }
    if (type === 'sales-activity/history') {
        guestSortField = 'date'
        guestSortAsc = false
    }
    if (type === 'otpm/group') {
      guestSortField = 'name'
      guestSortAsc = true
    }
    
    return apiAction({
        endPoint: `/${type}/search` + (itemId ? `/${itemId}` : ``),
        method: "POST",
        data: {
            ...productSearchData,
            sortAsc: state?.dataView?.sort?.[1] ?? guestSortAsc ?? sortAsc,
            sortField: state?.dataView?.sort?.[0] ?? guestSortField ?? sortField,
            firstRow: reload
                ? 0
                : type === 'sales-activity/history' ? firstRow
                : state?.dataView?.lastItemHint
                    ? state?.dataView?.lastItemHint
                    : 0,
            pageSize,
            countResult: countResult,
            searchTerm: searchTerm ?? state.dataView?.searchString,
            filters:
                type === 'organization/lead' && rangeDate.length > 0
                    ? [
                        {"beginParenthesis": "(", "field": guestSortField, "relation": ">", "value": rangeDate?.[0]?.toISOString(), "operator": "AND"},
                        {"field": guestSortField, "relation": "<", "value": rangeDate?.[1]?.toISOString(), "endParenthesis": ")"}
                    ]
                    : type === 'sales-activity/history' ? filterFields
                    : (type === 'otpm/report/loans' || type === 'otpm/report/callme') && target === "menuSearch" ? [] : createClassicFilters(), //menu search has no filters
            jsonLogic: {
                "operation": "AND",
                "children": target === "menuSearch" ? [] : createFilters(),
                "field": null,
                "value": null
            }
        },
        onSuccess: (data, dispatch) => {
            dispatch(setApiResponse(data))
            const newLastItemHint = Number(reload ? 0 : state?.dataView?.lastItemHint ?? 0) + data?.items.length;
            if (dictionary) {
                let payload = {}
                payload[dictionary] = data?.items
                return {
                    type: SET_DICTIONARY,
                    payload
                }
            } else
                return {
                    type: nextAction,
                    payload: {
                        items: data?.items,
                        lastItemHint: data?.items.length === pageSize && newLastItemHint,
                        count: data?.count,
                    },
                };
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }
    });
}

export const loadExcelProducts = (sourceId) => reDispatch(dispatch => {
        dispatch(setLoadingIndicator(sourceId, true))
        //dispatch(clearLoadingIndicator(sourceId))

        const state = store?.getState()
        return apiAction({
            endPoint: '/product/goods/shop/export',
            method: "POST",
            responseType: 'blob',
            data: {
                format: "XLSX",
                language: "RO",
                entityCode: "productShop",
                queryCriteria: {
                    "firstRow": 0,
                    "pageSize": 0,//state?.dataView?.items?.length ?? 10,//10,
                    "filters": [],//createClassicFilters(),
                    "searchTerm": state?.dataView?.searchString ?? null,//null,
                    "jsonLogic": {//null
                        "operation": "AND",
                        "children": createFilters(),
                        "field": null,
                        "value": null
                    }
                },
                columns: [
                    "code",
                    //"status",
                    "name",
                    "externalCode",
                    "ranking",
                    "brandName",
                    "priceListNet",
                    "priceListGross",
                    "currency",
                    "unitOfMeasure",
                    "tagList", //: [],
                ]
            },
            onSuccess: (data) => {//console.log(headers, data)
                dispatch(clearLoadingIndicator(sourceId))
                const downloadLink = window.document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
                downloadLink.download = `Lista_produse_B2B_${moment(Date.now()).format("DD.MM.YYYY-HH:MM:SS")}.xlsx`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                return {
                    type: "a",
                };
            },
            onFailure: (data) => {
                dispatch(clearLoadingIndicator(sourceId))
                console.log("error occured custom");
                toastr.error("Momentan produsele nu au putut fi descarcate, incearca mai tarziu")
                return {
                    type: "a",
                };
            },
        });
    }
)

export const downloadShoppingCart = (type, id) => {
    return apiAction({
        // endPoint: `/basket/report-export-${type}/${id ? id : ""}`,
        endPoint: `/basket/export/${id ? id : ""}?format=${computedParam(type)}`,
        method: "GET",
        responseType: 'blob',
        onSuccess: (data, dispatch) => {
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([data], computedType(type)));
            downloadLink.download = `Cos_cumparaturi_B2B_${moment(Date.now()).format("DD.MM.YYYY-HH:MM:SS")}.${type === "excel" ? "xlsx" : "pdf"}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return {
                type: "a",
            };
        },
        onFailure: (d, dispatch) => {
            console.log("error occured custom");
            toastr.error("Momentan cosul de cumparaturi nu a putut fi descarcat, incearca mai tarziu")
            return {
                type: "a",
            };
        },
    });
}
const computedType = (type) => {
    switch (type) {
        case "pdf":
            return {type: 'application/pdf'};
        case "excel":
            return {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"};
        default:
            return {type: 'application/pdf'};
    }
}
const computedParam = (type) => {
    switch (type) {
        case "pdf":
            return "PDF";
        case "excel":
            return "XLSX";
        default:
            return "PDF";
    }
}
export const loadDldOrders = (type, id, code) => {
    return apiAction({
        // endPoint: `/order/report-export-${type}/${id ? id : ""}`,//Vlea3l
        endPoint: `/order/export/${id ? id : ""}?format=${computedParam(type)}`,
        method: "GET",
        responseType: 'blob',
        onSuccess: (data, dispatch) => {
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([data], computedType(type)));
            downloadLink.download = `Order_${code}_${moment(Date.now()).format("DD.MM.YYYY-HH:MM:SS")}.${type === "excel" ? "xlsx" : "pdf"}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return {
                type: "a",
            };
        },
        onFailure: (d, dispatch) => {
            console.log("error occured custom");
            toastr.error("Momentan comanda nu a putut fi descarcata, incearca mai tarziu")
            return {
                type: "a",
            };
        },
    });
}

export const loadDynamoItemsBySK = ({SK, reload = true, dictionary}) =>
    dynamoAction({
        method: "QUERY",
        parameters: {
            TableName: "TempDevStack-MainTable-74SE74RNUI3D",
            KeyConditionExpression: "#pk = :pkvalue",
            IndexName: "SK-PK",
            ExpressionAttributeNames: {
                "#pk": "SK"
            },
            ExpressionAttributeValues: {
                ":pkvalue": {S: SK}
            }
        },
        onSuccess: (data, dispatch) => {
            dispatch(setApiResponse(data))

            if (dictionary) {
                let payload = {}
                payload[dictionary] = data?.items
                return {
                    type: SET_DICTIONARY,
                    payload
                }
            } else
                return {
                    type: reload ? SET_ITEMS : ADD_ITEMS,
                    payload: {
                        items: data?.items,
                        lastItemHint: data?.lastEvalKey
                    }
                }
        },
        onFailure: (err) => {
            // showMagicToaster({title: err})
            console.log(err)
            return {
                type: "a"
            }
        }
    })


export const loadDynamoFormItemByPK = ({PK, reload = true, dictionary, moreSteps}) =>
    dynamoAction({
        method: "QUERY",
        parameters: {
            TableName: "TempDevStack-MainTable-74SE74RNUI3D",
            KeyConditionExpression: "#pk = :pkvalue",
            // IndexName: "SK-PK",
            ExpressionAttributeNames: {
                "#pk": "PK"
            },
            ExpressionAttributeValues: {
                ":pkvalue": {S: PK}
            }
        },
        onSuccess: (data, dispatch) => {
            dispatch(setApiResponse(data))
            const firstItem = data?.items?.[0]
            //this could be unnecessary in the future, 'cuz the form will automaticly send an empty value for thmbnl img and subttl
            if ((firstItem?.SK === "GUEST_ARTICLE_V2" || firstItem?.SK === "JOB_RESPONSE") && !firstItem?.values?.content?.find(v => v.type === ("subtitle"))) {
                firstItem.values.content.splice(1, 0, {type: "subtitle", id: "152", text: ""});
            }
            if (moreSteps) moreSteps(firstItem, dispatch)
            if (dictionary) {
                let payload = {}
                payload[dictionary] = data?.items
                return {
                    type: SET_DICTIONARY,
                    payload
                }
            } else
                return {
                    type: SET_CRT_FORM_ITEM,
                    payload: {
                        data: {
                            ...(firstItem?.values ?? {}),
                            PK: firstItem?.PK, //used to identify and opened Dynamo item
                            SK: firstItem?.SK

                        }
                    }
                }
        },
        onFailure: (err) => {
            // showMagicToaster({title: err})
            console.log(err)
            return {
                type: "a"
            }
        }
    })


export const loadDynamoFormItemByPK_v2 = ({
                                              input,
                                              reload = true,
                                              dictionary,
                                              moreSteps,
                                              setDvListProducts,
                                              combine = false
                                          }) => {
    const PK = input.split?.('|')?.[0]
    const SK = input.split?.('|')?.[1] ?? getSKfromType(PK.split?.('-')?.[0])

    return dynamoAction({
        method: "QUERY",
        parameters: {
            TableName: "TempDevStack-MainTable-74SE74RNUI3D",
            KeyConditionExpression: "PK = :pkvalue and SK = :skvalue",
            // IndexName: "SK-PK",
            // ExpressionAttributeNames: {
            //   "#pk": "PK",
            //   "#sk": "SK",
            // },
            ExpressionAttributeValues: {
                ":pkvalue": {S: PK},
                ":skvalue": {S: SK},
            }
        },
        onSuccess: (data, dispatch) => {
            dispatch(setApiResponse(data))
            const firstItem = data?.items?.[0]
            if (moreSteps) moreSteps(firstItem, dispatch)
            // debugger
            if (setDvListProducts) dispatch(setDataItems(data?.items?.[0]?.products))
            if (dictionary) {
                let payload = {}
                payload[dictionary] = data?.items
                return {
                    type: SET_DICTIONARY,
                    payload
                }
            } else
                return {
                    type: SET_CRT_FORM_ITEM,
                    payload: {
                        data: firstItem,
                        combine: combine
                    }
                }
        },
        onFailure: (err) => {
            // showMagicToaster({title: err})
            console.log(err)
            return {
                type: "a"
            }
        }
    })
}
