import React from 'react';
import ReactDOM from 'react-dom/client';
import ReduxToastr from 'react-redux-toastr'

import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

import App from './App';

import ConfirmActionModal from "./components/_modals/ConfirmActionModal";
import reportWebVitals from './reportWebVitals';
import store, {persistor} from "./store/store";

import './index.css';
import '@synergic/core/style/index.css';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
                <ReduxToastr
                    timeOut={100000}
                    newestOnTop={false}
                    preventDuplicates
                    position="bottom-left"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    closeOnToastrClick={false}/>
                <ConfirmActionModal/>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
