import React from 'react';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ThemeContext from "./theme/themeProvider";
import OverlayContextProvider from "./contexts/OverlayContext";
import routes, { renderRoutes } from './routes/routes';

import { theme } from "./theme/theme";

import { history } from './utils/history';

import './App.css';
import useWindowSize from 'utils/useWindowsSize';
import { ErrorPage } from 'routes/ErrorPage';


function App() {
    const size = useWindowSize()

    if (size.width && size.width < 900) {
        return <ErrorPage type='bad-resolution' title='Min width 900px. Desktop only.' className='h-screen' />;
    }

    return (
        <ThemeContext.Provider value={theme}>
            <OverlayContextProvider>
                <Router history={history}>
                    {renderRoutes(routes)}
                </Router>
            </OverlayContextProvider>
        </ThemeContext.Provider>
    );
}

export default App;
