export const permissionGroups = [
    {
        code: "ACTIVITY",
        label: "Activity",
    },
    {
        code: "DEAL",
        label: "Deal",
    },
    {
        code: "FORM_MODEL",
        label: "Form model",
    },
    {
        code: "FORM_MODEL",
        label: "Form model",
    },
    {
        code: "GROUP",
        label: "Group",
    },
    {
        code: "LEAD",
        label: "Lead",
    },
    {
        code: "MICROSITE",
        label: "Microsite",
    },
    {
        code: "PIPELINE",
        label: "Pipeline",
    },
    {
        code: "REPORT",
        label: "Report",
    },
    {
        code: "ROLE",
        label: "Role",
    },
    {
        code: "USER",
        label: "User",
    },
    {
        code: "LOCATION",
        label: "Location"
    },
];