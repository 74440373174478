import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";

import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import AuthGuard from "./guard/AuthGuard";
import MagicLayout from "../layouts/magic/MagicLayout";
import NotAuthGuard from "./guard/NotAuthGuard";

import { pipelineRoutes } from "../modules/pipeline/routes/pipeline.routes";
import { userRoutes } from "../modules/users/routes/users.routes";
import { leadRoutes } from "../modules/lead/routes/lead.routes";
import { micrositeRoutes } from "../modules/microsite/routes/microsite.routes";
import { formsRoutes } from "../modules/forms/routes/forms.routes";
import { dealRoutes } from "../modules/deals/routes/deal.routes";
import { activitiesRoutes } from "../modules/activities/routes/activities.routes";
import { reportsRoutes } from "../modules/reports/routes/repots.routes";
import { ErrorPage } from "./ErrorPage";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            <Redirect exact={true} from="/" to="/login" />
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                            </Guard>
                        )}
                    />
                );
            })}
            <Route path='*'>
                <ErrorPage type='page-not-found' title='404 Error. Page not found' />
            </Route>
        </Switch>
    </Suspense>
);

let routes = [
    {
        path: "/login",
        component: lazy(() => import("../modules/auth/views/signIn")),
        guard: NotAuthGuard
    },
    {
        exact: true,
        path: "/safariNotSupported",
        component: lazy(() => import("views/MaintenancePage/MaintenancePage")),
    },
    {
        path: "/admin",
        layout: MagicLayout,
        routes: [
            {
                path: '/admin/user',
                routes: userRoutes
            },
            {
                path: '/admin/lead',
                routes: leadRoutes
            },
            {
                path: '/admin/pipeline',
                routes: pipelineRoutes
            },
            {
                path: '/admin/microsite',
                routes: micrositeRoutes
            },
            {
                path: '/admin/forms',
                routes: formsRoutes
            }
            ,
            {
                path: '/admin/deal',
                routes: dealRoutes
            },
            {
                path: '/admin/activity',
                routes: activitiesRoutes
            },
            {
                path: '/admin/report',
                routes: reportsRoutes
            }

        ],
        guard: AuthGuard,
    }
];

export default routes;
