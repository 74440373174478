import { createSelector } from "reselect"

const language = (state) => state.user.language

let defaultLang = undefined
const allLanguages = ["RO", "EN", "HU"]

const testForm = {
  "one.firstName": {
    label: "Primul nume",
    placeholder: "pune nume aici...."
  },
  test1: {
    label: "Selecteaza",
    placeholder: "scrie aici"
  },
  lastName: {
    label: "Prenume specific",
    placeholder: "scrie aici"
  },
  ddOne: {
    label: { RO: "Selecteaza Unul", EN: "Select" },
    placeholder: "Selecteaza"
  }
}
const companyForm = {
  name: {
    label: "Nume scurt",
    placeholder: "Nume companie ",
  },
  legalName: {
    label: "Nume entitate juridica",
    placeholder: "Numele companiei",
  },
  vatNr: {
    label: "CUI",
    placeholder: "RO 1234568",
  },
  fiscalNr: {
    label: "ONRC",
    placeholder: "J40/21/12.03.2021",
  },
  hqAddress: {
    label: "Sediul social",
    placeholder: "Adresa sediului",
  },
  administrator: {
    label: "",
    placeholder: "Andrei Popescu",
  },
  email: {
    label: "Email Global",//"Unde iti putem scrie?",
    placeholder: "Email",
  },
  phone: {
    label: "Telefon",
    placeholder: "0742111222",
  },
  "paymentMethod.invoice": {
    label: "Factura la termen (din limita de credit)",
    //placeholder: "factura"
  },
  "paymentMethod.bankTransfer": {
    label: "Plata prin transfer bancar",
    //placeholder: "transfer"
  },
  "paymentMethod.creditLimit": {
    label: "Plata cu card bancar",
    //placeholder: "card"
  },
  "paymentMethod.bankCard": {
    label: "Bilet la ordin / CEC la livrare marfa",
    //placeholder: "card"
  },

}
const productPackForm = {
  pachetName: {
    label: "Numele pachet",
    placeholder: "Nume pachet simplu ",
  },
  stocMax: {
    label: "Stoc (nr. maxim de achizitii)",
    placeholder: "1500",
  },
  stocActual: {
    label: "Stoc Actual",
    placeholder: "1120",
  },
  sales: {
    label: "Numar de vanzari",
    placeholder: "380",
  },
  income: {
    label: "Venituri generate",
    placeholder: "2.802,44",
  },
  expirationDate: {
    label: "Data expirare",
    placeholder: "31 August 21 23:59 Change this to datepicker",
  },
  progress: {
    label: "31 days remaining",
    // placeholder: "",
  },
  pachetPeriod: {
    label: "Perioda pachet",
    placeholder: "30",
  },
  productPrice: {
    label: "Pretul produselor in pachet",
    placeholder: "11.040,22 - 12.144,11",
  },
  averageDiscount: {
    label: "Discount mediu calculat",
    placeholder: "2",
  },
  personalizedApplication: {
    label: "Aplicabilitate personalizata",
    // placeholder: "2",
  },
  status: {
    label: "Status",
  },
}

const general = {
  firstName: {
    label: { RO: "Cum te numesti?", EN: "sdsd" },
    placeholder: "Nume",
  },
  lastName: {
    label: { RO: "Cum te numesti?", EN: "sdsd" },
    placeholder: "Prenume",
  },
  cui: {
    label: "CUI-ul companiei pe care o reprezinti",
    placeholder: "3226447",
  },
  email: {
    label: "Unde iti putem scrie?",
    placeholder: "Email",
  },
  "contactInfo.email": {
    label: "Email",
    placeholder: "ex@mail.com",
  },
  "contactInfo.phone": {
    label: "Telefon",
    placeholder: "+407xxxxxxxx",
  },
  telefon: {
    label: "Unde te putem suna?",
    placeholder: "0742111222",
  },
  numeScurtAdresa: {
    label: "Adresa*",
    placeholder: "Nume scurt adresa",
  },
  adresa: {
    label: "Adresa*",
    placeholder: "Scrie adresa",
  },
  numarLocation: {
    label: "Numar*",
    placeholder: "Scrie numarul",
  },
  localitateLocation: {
    label: "Localitate*",
    placeholder: "Scrie localitate",
  },
  judetLocation: {
    label: "Judet / Regiune*",
    placeholder: "Auto-fill in",
  },
  taraLocation: {
    label: "Tara*",
    placeholder: "Auto-fill in",
  },
  codPostalLocation: {
    label: "Cod Postal*",
    placeholder: "I am a placeholder",
  },
  longitudinelLocation: {
    label: "Longitudine**",
    placeholder: "Auto-fill in",
  },
  latitudineLocation: {
    label: "Latitudine*",
    placeholder: "Auto-fill in",
  },

  //**** Optiuni de logare ****
  emailPassword: {
    label: "Email si Parola",
    // placeholder: "scrie aici"
  },
  emailMagicLink: {
    label: "Email si Magic Link",
    // placeholder: "scrie aici"
  },
  phonePassword: {
    label: "Telefon si Parola",
    // placeholder: "scrie aici"
  },
  smsOtp: {
    label: "Telefon si One-time password via SMS",
    // placeholder: "scrie aici"
  },
}

const allFormsData = {
  general,
  companyForm,
  productPackForm,
  testForm,
  secInfoForm: {
    comenziInfo: {
      label: "Informatii despre comenzi",
      //placeholder: "scrie aici"
    },
    facturiInfo: {
      label: "Informatii despre facturi",
      // placeholder: "scrie aici"
    },
    utilizatoriInfo: {
      label: "Informatii despre utilizatori",
      // placeholder: "scrie aici"
    },
    notificariComerciale: {
      label: "Notificari comerciale",
      // placeholder: "scrie aici"
    },
    notificariMarketing: {
      label: "Materiale si notificari in scop de marketing",
      // placeholder: "scrie aici"
    },

    //**** Durata sesiunii ****
    dupa30zile: {
      label: "Expira dupa 30 de zile (cookies)",
      // placeholder: "scrie aici"
    },
    dupa14zile: {
      label: "Expira dupa 14 de zile (cookies)",
      // placeholder: "scrie aici"
    },
    in24ore: {
      label: "Expira in 24 de ore (cookies)",
      // placeholder: "scrie aici"
    },
    dupa30minute: {
      label: "Expira dupa 30 de miute de inactivitate",
      // placeholder: "scrie aici"
    },
    inchiziAplicatia: {
      label: "Expira de fiecare data cand inchizi aplicatia",
      // placeholder: "scrie aici"
    },
  },

  productDocumentForm: {
    docName: {
      label: { RO: "Numele document", EN: "Document name" },
      placeholder: 'Certificat de garantie - ADAPTOR 15-1/2" EXT',
    },
    docDescription: {
      label: { RO: "Descriere document", EN: "Document description" },
      placeholder:
        "Nulla placerat in ligula at suscipit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur ut ultricies sem. Maecenas metus sapien, pulvinar at mollis eu, porta a sem. Nulla faucibus finibus lorem vitae fringilla. Donec egestas vulputate blandit. Vivamus vestibulum suscipit arcu vitae luctus. Vivamus feugiat dapibus fringilla. Donec tempus, leo eu bibendum mollis, leo metus mattis ex, sit amet tincidunt felis lacus sed metus. Quisque pretium dignissim ex, et aliquet tellus cursus sit amet. Nullam ac leo nunc. Vivamus varius, libero nec rhoncus imperdiet, sapien diam cursus tellus, sed euismod lorem nibh gravida odio. Suspendisse potenti. Duis tempor lectus nec sapien condimentum vulputate. Donec fermentum lectus at urna suscipit, vel tincidunt ligula vulputate.Ut ultrices mollis lobortis. Donec pulvinar tincidunt interdum. Curabitur iaculis id turpis vestibulum molestie. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus fringilla congue nisl, at porta quam vehicula a. Suspendisse ultrices vel felis non suscipit. Aenean hendrerit interdum nunc, eget iaculis dolor ultricies sed. Quisque ut lobortis tortor. Vivamus lobortis suscipit metus eget pharetra.",
    },
    docUpload: {
      label: { RO: "Adauga document nou", EN: "Add new document" },
      placeholder: "",
      value: [],
    },
    published: {
      label: { RO: "Publicat de", EN: "Published by" },
      value: "",
    },
    publishedDate: {
      label: { RO: "Data postarii", EN: "Posted date" },
      value: "",
    },
    expireDate: {
      label: { RO: "Data expirarii", EN: "Epired date" },
      value: "",
    },
    docType: {
      label: { RO: "Tip Document", EN: "Document type" },
      value: "",
    },
    docType2: {
      label: { RO: "Tip Document", EN: "Document type" },
      value: "",
    },
    docStatus: {
      label: { RO: "Status", EN: "Status" },
      value: "",
    },
  },

  accountRequestForm: {
    position: {
      // TODO: dynamic company name
      label: "Functia in Companie cu nume extrem de lung SRL",
    },
    email: {
      label: "Adresa de email",
      placeholder: "exemplu@email.com",
    },
    phone: {
      label: "Numar de telefon",
      placeholder: "+(40) 744 123 457",
    },
    status: {
      label: "Schimba statusul solicitarii",
    },
    acquisitionVolum: {
      label: "Volum de achizitie",
    },
  },

  uniUserForm: {
    firstName: {
      label: "Nume",
      placeholder: "Nume",
    },
    lastName: {
      label: "Prenume",
      placeholder: "Prenume",
    },
    position: {
      label: "Functia Uniprest",
      placeholder: "selecteaza...",
    },
    positionPublic: {
      label: "Functia publica",
      placeholder: "selecteaza...",
    },
    email: {
      label: "Adresa de email",
      placeholder: "E-mail",
    },
    phoneNr: {
      label: "Numar de telefon",
      placeholder: "Numar de telefon",
    },
    workstation: {
      placeholder: "Punctul de lucru",
      label: "Punctul de lucru"
    },
    city: {
      label: "Localitatea",
      placeholder: "Localitatea",
    },
    county: {
      label: "Judetul",
      placeholder: "Judetul",
    },
    status: {
      label: "Pozitia la firma",
      placeholder: "Pozitia la firma",
    },
    nrOfCompanies: {
      label: "Nr Companii",
      placeholder: "Numar companii",// for now we do not need it :)
    },
    connectedUser: {
      label: "Userul asociat",
      placeholder: "",// for now we do not need it :)
    },
    isExistingUser: {
      label: "User existent",
      placeholder: "",// for now we do not need it :)
    },
    availableUsers: {
      label: "Utilizatori disponibili",
      placeholder: "",// for now we do not need it :)
    },
    companies: {
      label: "",
      placeholder: "Nici o companie asociata",
    }
  },

  grilaDiscountForm: {
    productGroup: {
      label: "Grila de discount",
      //placeholder: "",
    },
    discountProcentual: {
      label: "Discount procentual",
      //placeholder: "",
    },
    discount: {
      label: "Discount fata de Pret de Lista",
    },
    status: {
      label: "Status",
      //placeholder: "",
    },
    customApply: {
      label: "Aplicabilitate Personalizata",
      //placeholder: "",
    },
    clientCount: {
      label: "Companii afectate",
      //placeholder: "",
    },
    productCount: {
      label: "Produse afectate",
      //placeholder: "",
    },
  },

  productForm: {
    //Detalii generale
    companoId: {
      label: "ID Compano",
    },
    companoName: {
      label: "Nume in Compano",
    },
    b2bName: {
      label: "Nume in B2B (max. 70 caractere)",
    },
    urbanSearchTags: {
      label: "Vocabular Urban Search",
    },
    supplier: {
      label: "Furnizor",
    },
    responsible: {
      label: "Responsabil",
    },

    //Detalii produs
    productDetails: {
      label: "Detalii Produs in B2B (max. 250 caractere)",
    },

    //Documentatie technica
    showInfo: {
      label: "Afiseaza informatia",
    },

    //Prices
    decimalDisplay: {
      label: "Nr. decimale",
    },
  },

  adreseDeLivrareForm: {
    modificat: {
      label: "MODIFICATA PE 21 DEC 2021",
      // placeholder: "scrie aici"
    },
    title: {
      label: "Depozit central ",
      // placeholder: "scrie aici"
    },
    adresa: {
      label: "Adresa",
      placeholder: "Str. Ceahlaului 22, Sector 2, Bucuresti",
    },
    localitate: {
      label: "Localitate",
      placeholder: "Bucuresti",
    },

    judet: {
      label: "Sector / Judet",
      placeholder: "Sector 2",
    },
    codpostal: {
      label: "Cod postal",
      placeholder: "775147",
    },
    detalii: {
      label: "Detalii livrare",
      placeholder:
        "Cladirea a doua, pe stanga. Intrebati la portar pentru Nume Firma",
    },
  },

  utilizatoriForm: {
    email: {
      label: "Email",
      placeholder: "exemplu@email.com",
    },
    telefon: {
      label: "Telefon",
      placeholder: "+40 ...",
    },
    comenzi: {
      label: "Informatii despre comenzi",
      //placeholder: "scrie"
    },
    facturi: {
      label: "Informatii despre facturi",
      //placeholder: "scrie"
    },
  },
}

// const getFieldTranslation = (formTX, fieldName, inputPart, formName) => {
//   return formTX.forms?.[formName]?.[fieldName]?.[inputPart]
//     ?? formTX.forms?.general?.[fieldName]?.[inputPart]
//     ?? (inputPart === "label" ? fieldName : "null");
// };

export const selectFromLabels = createSelector(language, (language) => {
  for (const form in allFormsData)
    for (const field in allFormsData[form])
      for (const label in allFormsData[form][field]) {
        let item = allFormsData[form][field][label]
        if (typeof item === "object")
          if (allLanguages.includes(Object.keys(item)[0])) {
            // console.log(item)
            allFormsData[form][field][label] =
              item?.[language] ?? item?.[defaultLang]
          }
      }

  return {
    formTX: allFormsData,
  }
})
