const getFieldById = (filterId: string) => {
    switch(filterId) {
        case "FLT-ReportBranch":
            return "branchCode"
        case "FLT-ReportDealStatus":
            return "dealStatus"
        default:
            return undefined
    }
}

export const buildFilterById = (filterId: string, filterValue: Array<string>) => {
    if (filterId && filterValue) {
        return {
            "beginParenthesis": "(",
            "field": getFieldById(filterId),
            "relation": "in",
            "value": filterValue.toString(),
            "operator": "AND",
            "endParenthesis": ")"
        }
    }
}

export const getColumnsForXls = (activeColumns: Array<any>) => {
    let columns: Array<string> = []
    activeColumns && activeColumns?.map((item: any) => {
        if (item?.colId) columns.push(item?.colId)
    })
    return columns
}

export const getReportTypeByPipeline = (pipelineType: any) => {
    if (pipelineType === "Loans" || pipelineType === "Real Estate Loans") {
        return "otpm/report/loans"
    } else if(pipelineType === "Cards" || pipelineType === "Deposits" || pipelineType === "Insurances") {
        return "otpm/report/callme"
    } else {
        return ""
    }
}

export const organizeReportStages = (columns: Array<any>) => {
    const organizedColumns: Array<any> = []
    organizedColumns.push("stageName")

    columns && columns?.map((item: any, index: number) => {
        if (!item?.includes("stageDuration")) {
            organizedColumns.push(item)
        } else {
            organizedColumns.push(item)
            organizedColumns.push(`stageDate${item?.split("stageDuration")?.[1]}`)
        }
    })
    return organizedColumns
}

export const  padTo2Digits = (num: any) => {
    return num > 0 ? num.toString().padStart(2) : null;
}

export const convertMsToHours = (milliseconds: any) => {
    if (milliseconds) {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24)

        seconds = seconds % 60;
        minutes = seconds >= 30 ? minutes + 1 : minutes;
        minutes = minutes % 60;
        hours = hours % 24;

        return `
            ${padTo2Digits(days) ? `${padTo2Digits(days)}d` : ''}
            ${padTo2Digits(hours) ? `${padTo2Digits(hours)}h` : ''} 
            ${padTo2Digits(minutes) ? `${padTo2Digits(minutes)}m` : ''}`;
    } else {
        return null
    }
}

export const isLoans = (itemType: string) => {
    return itemType?.includes("loans")
}