import React, {useState} from 'react';
import {useHistory} from "react-router";

import {SvgIcon} from "@synergic/core";

import {useUserInfo} from "utils/useUserInfo";

import MobiasLogo from 'assets/MobiasLogo.svg';

interface MagicNavbarProps {
    onPressHamburger: () => void
}

const MagicNavbar = (props: MagicNavbarProps) => {
    const {onPressHamburger} = props;

    const history = useHistory()

    const [showHamburger, setShowHamburger] = useState<boolean>(false);

    const {isPublic, name, id} = useUserInfo()

    const clickOnHamburger = () => {
        setShowHamburger(e => !e);
        onPressHamburger();
    }

    return <header className="topMenu navbar">
        <div className="left">
            <button type="button" name="hamburger" className={`hamburger ${showHamburger ? 'active' : ''}`}
                    onClick={clickOnHamburger}>
                <span/>
            </button>
        </div>
        <img onContextMenu={(e) => e.preventDefault()} className="logo" alt="logo" src={MobiasLogo}/>

        <nav className="right">
            {!isPublic && <div className="logged-in-button" onClick={() => history.push(`/admin/user/client/form?id=${id}`)}>
                <SvgIcon name="user" className="" h={6} w={6} mr={2} stroke={'#fff'}/>
                <div>{name}</div>
            </div>}
        </nav>
    </header>
}

export default MagicNavbar;
