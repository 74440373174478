export const countryListAllIsoData = [
	{"id": "AF", "label": "Afghanistan", "number": "004"},
	{"id": "AL", "label": "Albania", "number": "008"},
	{"id": "DZ", "label": "Algeria", "number": "012"},
	{"id": "AS", "label": "American Samoa", "number": "016"},
	{"id": "AD", "label": "Andorra", "number": "020"},
	{"id": "AO", "label": "Angola", "number": "024"},
	{"id": "AI", "label": "Anguilla", "number": "660"},
	{"id": "AQ", "label": "Antarctica", "number": "010"},
	{"id": "AG", "label": "Antigua and Barbuda", "number": "028"},
	{"id": "AR", "label": "Argentina", "number": "032"},
	{"id": "AM", "label": "Armenia", "number": "051"},
	{"id": "AW", "label": "Aruba", "number": "533"},
	{"id": "AU", "label": "Australia", "number": "036"},
	{"id": "AT", "label": "Austria", "number": "040"},
	{"id": "AZ", "label": "Azerbaijan", "number": "031"},
	{"id": "BS", "label": "Bahamas (the)", "number": "044"},
	{"id": "BH", "label": "Bahrain", "number": "048"},
	{"id": "BD", "label": "Bangladesh", "number": "050"},
	{"id": "BB", "label": "Barbados", "number": "052"},
	{"id": "BY", "label": "Belarus", "number": "112"},
	{"id": "BE", "label": "Belgium", "number": "056"},
	{"id": "BZ", "label": "Belize", "number": "084"},
	{"id": "BJ", "label": "Benin", "number": "204"},
	{"id": "BM", "label": "Bermuda", "number": "060"},
	{"id": "BT", "label": "Bhutan", "number": "064"},
	{"id": "BO", "label": "Bolivia (Plurinational State of)", "number": "068"},
	{"id": "BQ", "label": "Bonaire, Sint Eustatius and Saba", "number": "535"},
	{"id": "BA", "label": "Bosnia and Herzegovina", "number": "070"},
	{"id": "BW", "label": "Botswana", "number": "072"},
	{"id": "BV", "label": "Bouvet Island", "number": "074"},
	{"id": "BR", "label": "Brazil", "number": "076"},
	{"id": "IO", "label": "British Indian Ocean Territory (the)", "number": "086"},
	{"id": "BN", "label": "Brunei Darussalam", "number": "096"},
	{"id": "BG", "label": "Bulgaria", "number": "100"},
	{"id": "BF", "label": "Burkina Faso", "number": "854"},
	{"id": "BI", "label": "Burundi", "number": "108"},
	{"id": "CV", "label": "Cabo Verde", "number": "132"},
	{"id": "KH", "label": "Cambodia", "number": "116"},
	{"id": "CM", "label": "Cameroon", "number": "120"},
	{"id": "CA", "label": "Canada", "number": "124"},
	{"id": "KY", "label": "Cayman Islands (the)", "number": "136"},
	{"id": "CF", "label": "Central African Republic (the)", "number": "140"},
	{"id": "TD", "label": "Chad", "number": "148"},
	{"id": "CL", "label": "Chile", "number": "152"},
	{"id": "CN", "label": "China", "number": "156"},
	{"id": "CX", "label": "Christmas Island", "number": "162"},
	{"id": "CC", "label": "Cocos (Keeling) Islands (the)", "number": "166"},
	{"id": "CO", "label": "Colombia", "number": "170"},
	{"id": "KM", "label": "Comoros (the)", "number": "174"},
	{"id": "CD", "label": "Congo (the Democratic Republic of the)", "number": "180"},
	{"id": "CG", "label": "Congo (the)", "number": "178"},
	{"id": "CK", "label": "Cook Islands (the)", "number": "184"},
	{"id": "CR", "label": "Costa Rica", "number": "188"},
	{"id": "HR", "label": "Croatia", "number": "191"},
	{"id": "CU", "label": "Cuba", "number": "192"},
	{"id": "CW", "label": "Curaçao", "number": "531"},
	{"id": "CY", "label": "Cyprus", "number": "196"},
	{"id": "CZ", "label": "Czechia", "number": "203"},
	{"id": "CI", "label": "Côte d'Ivoire", "number": "384"},
	{"id": "DK", "label": "Denmark", "number": "208"},
	{"id": "DJ", "label": "Djibouti", "number": "262"},
	{"id": "DM", "label": "Dominica", "number": "212"},
	{"id": "DO", "label": "Dominican Republic (the)", "number": "214"},
	{"id": "EC", "label": "Ecuador", "number": "218"},
	{"id": "EG", "label": "Egypt", "number": "818"},
	{"id": "SV", "label": "El Salvador", "number": "222"},
	{"id": "GQ", "label": "Equatorial Guinea", "number": "226"},
	{"id": "ER", "label": "Eritrea", "number": "232"},
	{"id": "EE", "label": "Estonia", "number": "233"},
	{"id": "SZ", "label": "Eswatini", "number": "748"},
	{"id": "ET", "label": "Ethiopia", "number": "231"},
	{"id": "FK", "label": "Falkland Islands (the) [Malvinas]", "number": "238"},
	{"id": "FO", "label": "Faroe Islands (the)", "number": "234"},
	{"id": "FJ", "label": "Fiji", "number": "242"},
	{"id": "FI", "label": "Finland", "number": "246"},
	{"id": "FR", "label": "France", "number": "250"},
	{"id": "GF", "label": "French Guiana", "number": "254"},
	{"id": "PF", "label": "French Polynesia", "number": "258"},
	{"id": "TF", "label": "French Southern Territories (the)", "number": "260"},
	{"id": "GA", "label": "Gabon", "number": "266"},
	{"id": "GM", "label": "Gambia (the)", "number": "270"},
	{"id": "GE", "label": "Georgia", "number": "268"},
	{"id": "DE", "label": "Germany", "number": "276"},
	{"id": "GH", "label": "Ghana", "number": "288"},
	{"id": "GI", "label": "Gibraltar", "number": "292"},
	{"id": "GR", "label": "Greece", "number": "300"},
	{"id": "GL", "label": "Greenland", "number": "304"},
	{"id": "GD", "label": "Grenada", "number": "308"},
	{"id": "GP", "label": "Guadeloupe", "number": "312"},
	{"id": "GU", "label": "Guam", "number": "316"},
	{"id": "GT", "label": "Guatemala", "number": "320"},
	{"id": "GG", "label": "Guernsey", "number": "831"},
	{"id": "GN", "label": "Guinea", "number": "324"},
	{"id": "GW", "label": "Guinea-Bissau", "number": "624"},
	{"id": "GY", "label": "Guyana", "number": "328"},
	{"id": "HT", "label": "Haiti", "number": "332"},
	{"id": "HM", "label": "Heard Island and McDonald Islands", "number": "334"},
	{"id": "VA", "label": "Holy See (the)", "number": "336"},
	{"id": "HN", "label": "Honduras", "number": "340"},
	{"id": "HK", "label": "Hong Kong", "number": "344"},
	{"id": "HU", "label": "Hungary", "number": "348"},
	{"id": "IS", "label": "Iceland", "number": "352"},
	{"id": "IN", "label": "India", "number": "356"},
	{"id": "id2", "label": "Indonesia", "number": "360"},
	{"id": "IR", "label": "Iran (Islamic Republic of)", "number": "364"},
	{"id": "IQ", "label": "Iraq", "number": "368"},
	{"id": "IE", "label": "Ireland", "number": "372"},
	{"id": "IM", "label": "Isle of Man", "number": "833"},
	{"id": "IL", "label": "Israel", "number": "376"},
	{"id": "IT", "label": "Italy", "number": "380"},
	{"id": "JM", "label": "Jamaica", "number": "388"},
	{"id": "JP", "label": "Japan", "number": "392"},
	{"id": "JE", "label": "Jersey", "number": "832"},
	{"id": "JO", "label": "Jordan", "number": "400"},
	{"id": "KZ", "label": "Kazakhstan", "number": "398"},
	{"id": "KE", "label": "Kenya", "number": "404"},
	{"id": "KI", "label": "Kiribati", "number": "296"},
	{"id": "KP", "label": "Korea (the Democratic People's Republic of)", "number": "408"},
	{"id": "KR", "label": "Korea (the Republic of)", "number": "410"},
	{"id": "KW", "label": "Kuwait", "number": "414"},
	{"id": "KG", "label": "Kyrgyzstan", "number": "417"},
	{"id": "LA", "label": "Lao People's Democratic Republic (the)", "number": "418"},
	{"id": "LV", "label": "Latvia", "number": "428"},
	{"id": "LB", "label": "Lebanon", "number": "422"},
	{"id": "LS", "label": "Lesotho", "number": "426"},
	{"id": "LR", "label": "Liberia", "number": "430"},
	{"id": "LY", "label": "Libya", "number": "434"},
	{"id": "LI", "label": "Liechtenstein", "number": "438"},
	{"id": "LT", "label": "Lithuania", "number": "440"},
	{"id": "LU", "label": "Luxembourg", "number": "442"},
	{"id": "MO", "label": "Macao", "number": "446"},
	{"id": "MG", "label": "Madagascar", "number": "450"},
	{"id": "MW", "label": "Malawi", "number": "454"},
	{"id": "MY", "label": "Malaysia", "number": "458"},
	{"id": "MV", "label": "Maldives", "number": "462"},
	{"id": "ML", "label": "Mali", "number": "466"},
	{"id": "MT", "label": "Malta", "number": "470"},
	{"id": "MH", "label": "Marshall Islands (the)", "number": "584"},
	{"id": "MQ", "label": "Martinique", "number": "474"},
	{"id": "MR", "label": "Mauritania", "number": "478"},
	{"id": "MU", "label": "Mauritius", "number": "480"},
	{"id": "YT", "label": "Mayotte", "number": "175"},
	{"id": "MX", "label": "Mexico", "number": "484"},
	{"id": "FM", "label": "Micronesia (Federated States of)", "number": "583"},
	{"id": "MD", "label": "Moldova (the Republic of)", "number": "498"},
	{"id": "MC", "label": "Monaco", "number": "492"},
	{"id": "MN", "label": "Mongolia", "number": "496"},
	{"id": "ME", "label": "Montenegro", "number": "499"},
	{"id": "MS", "label": "Montserrat", "number": "500"},
	{"id": "MA", "label": "Morocco", "number": "504"},
	{"id": "MZ", "label": "Mozambique", "number": "508"},
	{"id": "MM", "label": "Myanmar", "number": "104"},
	{"id": "NA", "label": "Namibia", "number": "516"},
	{"id": "NR", "label": "Nauru", "number": "520"},
	{"id": "NP", "label": "Nepal", "number": "524"},
	{"id": "NL", "label": "Netherlands (the)", "number": "528"},
	{"id": "NC", "label": "New Caledonia", "number": "540"},
	{"id": "NZ", "label": "New Zealand", "number": "554"},
	{"id": "NI", "label": "Nicaragua", "number": "558"},
	{"id": "NE", "label": "Niger (the)", "number": "562"},
	{"id": "NG", "label": "Nigeria", "number": "566"},
	{"id": "NU", "label": "Niue", "number": "570"},
	{"id": "NF", "label": "Norfolk Island", "number": "574"},
	{"id": "MP", "label": "Northern Mariana Islands (the)", "number": "580"},
	{"id": "NO", "label": "Norway", "number": "578"},
	{"id": "OM", "label": "Oman", "number": "512"},
	{"id": "PK", "label": "Pakistan", "number": "586"},
	{"id": "PW", "label": "Palau", "number": "585"},
	{"id": "PS", "label": "Palestine, State of", "number": "275"},
	{"id": "PA", "label": "Panama", "number": "591"},
	{"id": "PG", "label": "Papua New Guinea", "number": "598"},
	{"id": "PY", "label": "Paraguay", "number": "600"},
	{"id": "PE", "label": "Peru", "number": "604"},
	{"id": "PH", "label": "Philippines (the)", "number": "608"},
	{"id": "PN", "label": "Pitcairn", "number": "612"},
	{"id": "PL", "label": "Poland", "number": "616"},
	{"id": "PT", "label": "Portugal", "number": "620"},
	{"id": "PR", "label": "Puerto Rico", "number": "630"},
	{"id": "QA", "label": "Qatar", "number": "634"},
	{"id": "MK", "label": "Republic of North Macedonia", "number": "807"},
	{"id": "RO", "label": "Romania", "number": "642"},
	{"id": "RU", "label": "Russian Federation (the)", "number": "643"},
	{"id": "RW", "label": "Rwanda", "number": "646"},
	{"id": "RE", "label": "Réunion", "number": "638"},
	{"id": "BL", "label": "Saint Barthélemy", "number": "652"},
	{"id": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
	{"id": "KN", "label": "Saint Kitts and Nevis", "number": "659"},
	{"id": "LC", "label": "Saint Lucia", "number": "662"},
	{"id": "MF", "label": "Saint Martin (French part)", "number": "663"},
	{"id": "PM", "label": "Saint Pierre and Miquelon", "number": "666"},
	{"id": "VC", "label": "Saint Vincent and the Grenadines", "number": "670"},
	{"id": "WS", "label": "Samoa", "number": "882"},
	{"id": "SM", "label": "San Marino", "number": "674"},
	{"id": "ST", "label": "Sao Tome and Principe", "number": "678"},
	{"id": "SA", "label": "Saudi Arabia", "number": "682"},
	{"id": "SN", "label": "Senegal", "number": "686"},
	{"id": "RS", "label": "Serbia", "number": "688"},
	{"id": "SC", "label": "Seychelles", "number": "690"},
	{"id": "SL", "label": "Sierra Leone", "number": "694"},
	{"id": "SG", "label": "Singapore", "number": "702"},
	{"id": "SX", "label": "Sint Maarten (Dutch part)", "number": "534"},
	{"id": "SK", "label": "Slovakia", "number": "703"},
	{"id": "SI", "label": "Slovenia", "number": "705"},
	{"id": "SB", "label": "Solomon Islands", "number": "090"},
	{"id": "SO", "label": "Somalia", "number": "706"},
	{"id": "ZA", "label": "South Africa", "number": "710"},
	{"id": "GS", "label": "South Georgia and the South Sandwich Islands", "number": "239"},
	{"id": "SS", "label": "South Sudan", "number": "728"},
	{"id": "ES", "label": "Spain", "number": "724"},
	{"id": "LK", "label": "Sri Lanka", "number": "144"},
	{"id": "SD", "label": "Sudan (the)", "number": "729"},
	{"id": "SR", "label": "Suriname", "number": "740"},
	{"id": "SJ", "label": "Svalbard and Jan Mayen", "number": "744"},
	{"id": "SE", "label": "Sweden", "number": "752"},
	{"id": "CH", "label": "Switzerland", "number": "756"},
	{"id": "SY", "label": "Syrian Arab Republic", "number": "760"},
	{"id": "TW", "label": "Taiwan", "number": "158"},
	{"id": "TJ", "label": "Tajikistan", "number": "762"},
	{"id": "TZ", "label": "Tanzania, United Republic of", "number": "834"},
	{"id": "TH", "label": "Thailand", "number": "764"},
	{"id": "TL", "label": "Timor-Leste", "number": "626"},
	{"id": "TG", "label": "Togo", "number": "768"},
	{"id": "TK", "label": "Tokelau", "number": "772"},
	{"id": "TO", "label": "Tonga", "number": "776"},
	{"id": "TT", "label": "Trinidad and Tobago", "number": "780"},
	{"id": "TN", "label": "Tunisia", "number": "788"},
	{"id": "TR", "label": "Turkey", "number": "792"},
	{"id": "TM", "label": "Turkmenistan", "number": "795"},
	{"id": "TC", "label": "Turks and Caicos Islands (the)", "number": "796"},
	{"id": "TV", "label": "Tuvalu", "number": "798"},
	{"id": "UG", "label": "Uganda", "number": "800"},
	{"id": "UA", "label": "Ukraine", "number": "804"},
	{"id": "AE", "label": "United Arab Emirates (the)", "number": "784"},
	{"id": "GB", "label": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826"},
	{"id": "UM", "label": "United States Minor Outlying Islands (the)", "number": "581"},
	{"id": "US", "label": "United States of America (the)", "number": "840"},
	{"id": "UY", "label": "Uruguay", "number": "858"},
	{"id": "UZ", "label": "Uzbekistan", "number": "860"},
	{"id": "VU", "label": "Vanuatu", "number": "548"},
	{"id": "VE", "label": "Venezuela (Bolivarian Republic of)", "number": "862"},
	{"id": "VN", "label": "Viet Nam", "number": "704"},
	{"id": "VG", "label": "Virgin Islands (British)", "number": "092"},
	{"id": "VI", "label": "Virgin Islands (U.S.)", "number": "850"},
	{"id": "WF", "label": "Wallis and Futuna", "number": "876"},
	{"id": "EH", "label": "Western Sahara", "number": "732"},
	{"id": "YE", "label": "Yemen", "number": "887"},
	{"id": "ZM", "label": "Zambia", "number": "894"},
	{"id": "ZW", "label": "Zimbabwe", "number": "716"},
	{"id": "AX", "label": "Åland Islands", "number": "248"}
];

export const countyList =
[
    {
        "id2": "XlxM7m",
        "type": "COUNTY",
        "code": "AB",
		"id": "AB",
        "value": "Alba",
		"label": "Alba",
        "info": null
    },
    {
        "id2": "LZMbGl",
        "type": "COUNTY",
        "code": "AR",
		"id": "AR",
		"label": "Arad",
        "value": "Arad",
        "info": null
    },
    {
        "id2": "PrEP6m",
        "type": "COUNTY",
        "code": "AG",
		"id": "AG",
		"label": "Argeș",
        "value": "Argeș",
        "info": null
    },
    {
        "id2": "wlpMxm",
        "type": "COUNTY",
        "code": "BC",
		"id": "BC",
		"label": "Bacău",
        "value": "Bacău",
        "info": null
    },
    {
        "id2": "olWbAZ",
        "type": "COUNTY",
        "code": "BH",
		"id": "BH",
		"label": "Bihor",
        "value": "Bihor",
        "info": null
    },
    {
        "id2": "NmkMbZ",
        "type": "COUNTY",
        "code": "BN",
		"id": "BN",
		"label": "Bistrița-Năsăud",
        "value": "Bistrița-Năsăud",
        "info": null
    },
    {
        "id2": "XlDjWr",
        "type": "COUNTY",
        "code": "BT",
		"id": "BT",
		"label": "Botoșani",
        "value": "Botoșani",
        "info": null
    },
    {
        "id2": "LmnMBl",
        "type": "COUNTY",
        "code": "BR",
		"id": "BR",
		"label": "Brăila",
        "value": "Brăila",
        "info": null
    },
    {
        "id2": "5l7VXZ",
        "type": "COUNTY",
        "code": "BV",
		"id": "BV",
		"label": "Brașov",
        "value": "Brașov",
        "info": null
    },
    {
        "id2": "VleMJl",
        "type": "COUNTY",
        "code": "B",
		"id": "B",
		"label": "București",
        "value": "București",
        "info": null
    },
    {
        "id2": "WmGb9l",
        "type": "COUNTY",
        "code": "BZ",
		"id": "BZ",
		"label": "Buzău",
        "value": "Buzău",
        "info": null
    },
    {
        "id2": "arBaDm",
        "type": "COUNTY",
        "code": "CL",
		"id": "CL",
		"label": "Călărași",
        "value": "Călărași",
        "info": null
    },
    {
        "id2": "7rqMvr",
        "type": "COUNTY",
        "code": "CS",
		"id":"CS",
		"label": "Caraș-Severin",
        "value": "Caraș-Severin",
        "info": null
    },
    {
        "id2": "krwMjl",
        "type": "COUNTY",
        "code": "CJ",
		"id": "CJ",
		"label": "Cluj",
        "value": "Cluj",
        "info": null
    },
    {
        "id2": "AZQWnm",
        "type": "COUNTY",
        "code": "CT",
		"id": "CT",
		"label": "Constanța",
        "value": "Constanța",
        "info": null
    },
    {
        "id2": "Jr3OYr",
        "type": "COUNTY",
        "code": "CV",
		"id": "CV",
		"label": "Covasna",
        "value": "Covasna",
        "info": null
    },
    {
        "id2": "9m13YZ",
        "type": "COUNTY",
        "code": "DB",
		"id": "DB",
		"label": "Dâmbovița",
        "value": "Dâmbovița",
        "info": null
    },
    {
        "id2": "4lOboZ",
        "type": "COUNTY",
        "code": "DJ",
		"id": "DJ",
		"label": "Dolj",
        "value": "Dolj",
        "info": null
    },
    {
        "id2": "grKbQm",
        "type": "COUNTY",
        "code": "GL",
		"id": "GL",
		"label": "Galați",
        "value": "Galați",
        "info": null
    },
    {
        "id2": "3r21pm",
        "type": "COUNTY",
        "code": "GR",
		"id": "GR",
		"label": "Giurgiu",
        "value": "Giurgiu",
        "info": null
    },
    {
        "id2": "yZyMLZ",
        "type": "COUNTY",
        "code": "GJ",
		"id": "GJ",
		"label": "Gorj",
        "value": "Gorj",
        "info": null
    },
    {
        "id2": "JlJbAl",
        "type": "COUNTY",
        "code": "HR",
		"id": "HR",
		"label": "Harghita",
        "value": "Harghita",
        "info": null
    },
    {
        "id2": "3ZVeRr",
        "type": "COUNTY",
        "code": "HD",
		"id": "HD",
		"label": "Hunedoara",
        "value": "Hunedoara",
        "info": null
    },
    {
        "id2": "JmAooZ",
        "type": "COUNTY",
        "code": "IL",
		"id": "IL",
		"label": "Ialomița",
        "value": "Ialomița",
        "info": null
    },
    {
        "id2": "qZNbLZ",
        "type": "COUNTY",
        "code": "IS",
		"id": "IS",
		"label": "Iași",
        "value": "Iași",
        "info": null
    },
    {
        "id2": "0lYbwm",
        "type": "COUNTY",
        "code": "IF",
		"id": "IF",
		"label": "Ilfov",
        "value": "Ilfov",
        "info": null
    },
    {
        "id2": "2l5Kpr",
        "type": "COUNTY",
        "code": "MM",
		"id": "MM",
		"label": "Maramureș",
        "value": "Maramureș",
        "info": null
    },
    {
        "id2": "Wr8JVl",
        "type": "COUNTY",
        "code": "MH",
		"id": "MH",
		"label": "Mehedinți",
        "value": "Mehedinți",
        "info": null
    },
    {
        "id2": "Xl0QPm",
        "type": "COUNTY",
        "code": "MS",
		"id": "MS",
		"label": "Mureș",
        "value": "Mureș",
        "info": null
    },
    {
        "id2": "yZRbyZ",
        "type": "COUNTY",
        "code": "NT",
		"id": "NT",
		"label": "Neamț",
        "value": "Neamț",
        "info": null
    },
    {
        "id2": "krdMzZ",
        "type": "COUNTY",
        "code": "OT",
		"id": "OT",
		"label": "Olt",
        "value": "Olt",
        "info": null
    },
    {
        "id2": "OroMwl",
        "type": "COUNTY",
        "code": "PH",
		"id": "PH",
		"label": "Prahova",
        "value": "Prahova",
        "info": null
    },
    {
        "id2": "AZvMKr",
        "type": "COUNTY",
        "code": "SJ",
		"id": "SJ",
		"label": "Sălaj",
        "value": "Sălaj",
        "info": null
    },
    {
        "id2": "nljM1Z",
        "type": "COUNTY",
        "code": "SM",
		"id": "SM",
		"label": "Satu Mare",
        "value": "Satu Mare",
        "info": null
    },
    {
        "id2": "BmabDr",
        "type": "COUNTY",
        "code": "SB",
		"id": "SB",
		"label": "Sibiu",
        "value": "Sibiu",
        "info": null
    },
    {
        "id2": "WZPnvm",
        "type": "COUNTY",
        "code": "SV",
		"id": "SV",
		"label": "Suceava",
        "value": "Suceava",
        "info": null
    },
    {
        "id2": "wmgMGZ",
        "type": "COUNTY",
        "code": "TR",
		"id": "TR",
		"label": "Teleorman",
        "value": "Teleorman",
        "info": null
    },
    {
        "id2": "Rr9Y3r",
        "type": "COUNTY",
        "code": "TM",
		"id": "TM",
		"label": "Timiș",
        "value": "Timiș",
        "info": null
    },
    {
        "id2": "Wr62Xr",
        "type": "COUNTY",
        "code": "TC",//!!!!!!!!!!!!!!! this is how it is in api
		"id": "TL",
		"label": "Tulcea",
        "value": "Tulcea",
        "info": null
    },
    {
        "id2": "QmbpaZ",
        "type": "COUNTY",
        "code": "VL",
		"id": "VL",
		"label": "Vâlcea",
        "value": "Vâlcea",
        "info": null
    },
    {
        "id2": "EZzKEl",
        "type": "COUNTY",
        "code": "VS",
		"id": "VS",
		"label": "Vaslui",
        "value": "Vaslui",
        "info": null
    },
    {
        "id2": "ElLLPl",
        "type": "COUNTY",
        "code": "VN",
		"id": "VN",
		"label": "Vrancea",
        "value": "Vrancea",
        "info": null
    }
]

export const FILE_TYPE = {
	IMAGE: 		"IMAGE",
	OTHER: 		"OTHER",
	AVATAR: 	"AVATAR",
    VIDEO: 		"VIDEO",
    DOCUMENT: 	"DOCUMENT"
}

export const FILE_ACTION = {
	ADD: "ADD",
	REMOVE: "REMOVE",
}

export const PERMISSION = {
	READ: "read",
	WRITE: "write",
	DELETE: "delete",
}

export const GENERAL_STATUS = {
	ACTIVE: {
	  title: "Activ",
	  backgroundColor: "all-good"
	},
	INACTIVE:{
	  title: "Inactiv",
	  backgroundColor: "button-inactive"
	}
  }

export const allLocation = [
  { id: "Zye3oZ", label: "Abram - Jud. BH" },
  { id: "rKj70m", label: "Abramut - Jud. BH" },
  { id: "ElLnr7", label: "Abrud - Jud. AB" },
  { id: "r6OE5Z", label: "Abrud - Jud. CT" },
  { id: "EZzNZ1", label: "Abrud Sat - Jud. AB" },
  { id: "lx71PZ", label: "Abucea - Jud. HD" },
  { id: "lOAyGr", label: "Abud - Jud. MS" },
  { id: "rq0ogm", label: "Abus - Jud. MS" },
  { id: "lDqwjm", label: "Acas - Jud. SM" },
  { id: "r6GeWZ", label: "Acatari - Jud. MS" },
  { id: "XlDPWl", label: "Achimetesti - Jud. AB" },
  { id: "rd7Pam", label: "Aciliu - Jud. SB" },
  { id: "Z49M1l", label: "Aciua - Jud. SM" },
  { id: "Xl0D6l", label: "Aciuta - Jud. AR" },
  { id: "JmA4ql", label: "Acmariu - Jud. AB" },
  { id: "ZQQdgZ", label: "Acris - Jud. BV" },
  { id: "rwE1xl", label: "Adalin - Jud. SJ" },
  { id: "lpEnom", label: "Adam - Jud. GL" },
  { id: "ZVL1Om", label: "Adamclisi - Jud. CT" },
  { id: "r9yxXr", label: "Adamus - Jud. MS" },
  { id: "rqb3gZ", label: "Adanca - Jud. DB" },
  { id: "leoQJr", label: "Adancata - Jud. DJ" },
  { id: "mA4Xql", label: "Adancata - Jud. IL" },
  { id: "ZMGPzm", label: "Adancata - Jud. SV" },
  { id: "ZNy4Yr", label: "Adaseni - Jud. BT" },
  { id: "XlDNJr", label: "Adea - Jud. AR" },
  { id: "m18Wgl", label: "Adjud - Jud. VN" },
  { id: "l7wzdl", label: "Adjudeni - Jud. NT" },
  { id: "lO7dJl", label: "Adjudu Vechi - Jud. VN" },
  { id: "l7veLZ", label: "Adoni - Jud. BH" },
  { id: "mbxQBZ", label: "Adrian - Jud. MS" },
  { id: "mAGKKZ", label: "Adrian - Jud. SM" },
  { id: "rq0RPm", label: "Adrianu Mare - Jud. MS" },
  { id: "ZQV2vl", label: "Adrianu Mic - Jud. MS" },
  { id: "qZNqer", label: "Adunati - Jud. AG" },
  { id: "lpA4Pm", label: "Adunati - Jud. PH" },
  { id: "l7Qz5r", label: "Adunatii Copaceni - Jud. GR" },
  { id: "l57A3r", label: "Adunatii Teiului - Jud. MH" },
  { id: "lL79YZ", label: "Ady Endre - Jud. SM" },
  { id: "lDKbWZ", label: "Afanata - Jud. VL" },
  { id: "3r28pl", label: "Afrimesti - Jud. AG" },
  { id: "lpEGPm", label: "Afumati - Jud. DJ" },
  { id: "rq0Jgm", label: "Afumati - Jud. IF" },
  { id: "lWDBdm", label: "Afumati - Jud. OT" },
  { id: "r6Oa5Z", label: "Agadici - Jud. CS" },
  { id: "lWBWEZ", label: "Agafton - Jud. BT" },
  { id: "ZPavEl", label: "Agapia - Jud. NT" },
  { id: "l71G3r", label: "Agarbiciu - Jud. CJ" },
  { id: "rqpqQm", label: "Agarbiciu - Jud. SB" },
  { id: "rXOERm", label: "Agarcia - Jud. NT" },
  { id: "lJQ6AZ", label: "Agas - Jud. BC" },
  { id: "lOWeJl", label: "Agaua - Jud. BR" },
  { id: "ZQaE5Z", label: "Aghires - Jud. SJ" },
  { id: "r97G3l", label: "Aghiresu - Jud. CJ" },
  { id: "lOW4Gl", label: "Aghiresu Fabrici - Jud. CJ" },
  { id: "lO6WNZ", label: "Agigea - Jud. CT" },
  { id: "ma6Vbr", label: "Agighiol - Jud. TL" },
  { id: "mGWBBm", label: "Agnita - Jud. SB" },
  { id: "mnPaNZ", label: "Agries - Jud. BN" },
  { id: "lDAkJm", label: "Agriesel - Jud. BN" },
  { id: "mnznNm", label: "Agrij - Jud. SJ" },
  { id: "rodwqZ", label: "Agris - Jud. CJ" },
  { id: "rERY5Z", label: "Agris - Jud. SM" },
  { id: "mbxkdZ", label: "Agristeu - Jud. MS" },
  { id: "Zv2q2Z", label: "Agrisu De Jos - Jud. BN" },
  { id: "ljG0Nm", label: "Agrisu De Sus - Jud. BN" },
  { id: "yZRVAZ", label: "Agrisu Mare - Jud. AR" },
  { id: "AZv92r", label: "Agrisu Mic - Jud. AR" },
  { id: "m1DBal", label: "Aita Mare - Jud. CV" },
  { id: "lDP22l", label: "Aita Medie - Jud. CV" },
  { id: "r3GQer", label: "Aita Seaca - Jud. CV" },
  { id: "mgAOGZ", label: "Aiton - Jud. CJ" },
  { id: "9m18l7", label: "Aiud - Jud. AB" },
  { id: "4lOKlq", label: "Aiudul De Sus - Jud. AB" },
  { id: "ZQaKaZ", label: "Alamor - Jud. SB" },
  { id: "lOw3ol", label: "Alba - Jud. BT" },
  { id: "mA9D8m", label: "Alba - Jud. TL" },
  { id: "krwylx", label: "Alba Iulia - Jud. AB" },
  { id: "Xl000l", label: "Albac - Jud. AB" },
  { id: "NmkAOZ", label: "Albele - Jud. BC" },
  { id: "majxjr", label: "Albeni - Jud. GJ" },
  { id: "rEa3Qr", label: "Albeni - Jud. TR" },
  { id: "krdAqZ", label: "Albesti - Jud. AG" },
  { id: "lYD2EZ", label: "Albesti - Jud. BH" },
  { id: "rXd8Dr", label: "Albesti - Jud. BT" },
  { id: "rwpzyZ", label: "Albesti - Jud. BZ" },
  { id: "lJPWzr", label: "Albesti - Jud. CT" },
  { id: "ZRxNvm", label: "Albesti - Jud. DJ" },
  { id: "lx7OpZ", label: "Albesti - Jud. IL" },
  { id: "rXqEnZ", label: "Albesti - Jud. IS" },
  { id: "lj3aBm", label: "Albesti - Jud. MS" },
  { id: "lDqD1m", label: "Albesti - Jud. OT" },
  { id: "rBRJJr", label: "Albesti - Jud. TR" },
  { id: "mnp2NZ", label: "Albesti - Jud. VS" },
  { id: "r9DMXm", label: "Albesti Muru - Jud. PH" },
  { id: "lWDzYm", label: "Albesti Paleologu - Jud. PH" },
  { id: "l7vx5Z", label: "Albestii Bistritei - Jud. BN" },
  { id: "0lYqOl", label: "Albestii Pamanteni - Jud. AG" },
  { id: "JmA3WZ", label: "Albestii Ungureni - Jud. AG" },
  { id: "rwERjl", label: "Albi - Jud. SB" },
  { id: "ljNNbl", label: "Albina - Jud. BR" },
  { id: "rwV3ym", label: "Albina - Jud. TM" },
  { id: "lLJR6r", label: "Albina - Jud. VS" },
  { id: "l7y5Ar", label: "Albinari - Jud. PH" },
  { id: "mG5JnZ", label: "Albis - Jud. BH" },
  { id: "r6qG3m", label: "Albis - Jud. CV" },
  { id: "mk14oZ", label: "Albita - Jud. VS" },
  { id: "yZRPKm", label: "Albota - Jud. AG" },
  { id: "5l7NwZ", label: "Albotele - Jud. AG" },
  { id: "ZzDp8m", label: "Albulesti - Jud. MH" },
  { id: "lj5nwZ", label: "Aldea - Jud. HR" },
  { id: "r97Dal", label: "Aldeni - Jud. BZ" },
  { id: "qZNzVZ", label: "Aldesti - Jud. AR" },
  { id: "rwy1Vl", label: "Aldesti - Jud. GL" },
  { id: "rqxPvm", label: "Aldesti - Jud. VL" },
  { id: "Xl0xNl", label: "Alecus - Jud. AB" },
  { id: "ZyeDLZ", label: "Alesd - Jud. BH" },
  { id: "r6ye9m", label: "Aleus - Jud. SJ" },
  { id: "lJN9Yl", label: "Alexandria - Jud. TR" },
  { id: "lOD7Nm", label: "Alexandrita - Jud. HR" },
  { id: "rwDvoZ", label: "Alexandru Cel Bun - Jud. IS" },
  { id: "l71y5r", label: "Alexandru Odobescu - Jud. BZ" },
  { id: "lYy3Vl", label: "Alexandru Odobescu - Jud. CL" },
  { id: "mk5goZ", label: "Alexandru Vlahuta - Jud. VN" },
  { id: "lDapJm", label: "Alexandru Vlahuta - Jud. VS" },
  { id: "lYKxXr", label: "Alexeni - Jud. GJ" },
  { id: "ZNAL6r", label: "Alexeni - Jud. IL" },
  { id: "ZVwpxl", label: "Alexeni - Jud. IS" },
  { id: "lezkgm", label: "Alexesti - Jud. IS" },
  { id: "leLPYZ", label: "Aliceni - Jud. BZ" },
  { id: "lJ9XYZ", label: "Aliceni - Jud. SM" },
  { id: "lYxyRZ", label: "Aliman - Jud. CT" },
  { id: "leGaOr", label: "Alimanesti - Jud. OT" },
  { id: "Zvo55l", label: "Alimpesti - Jud. GJ" },
  { id: "lxE4om", label: "Alios - Jud. TM" },
  { id: "mAGD6Z", label: "Aliza - Jud. SM" },
  { id: "Z49JQl", label: "Alma - Jud. SB" },
  { id: "ZPqykl", label: "Alma Vii - Jud. SB" },
  { id: "lWewYr", label: "Almaj - Jud. DJ" },
  { id: "rKPMRZ", label: "Almajel - Jud. DJ" },
  { id: "rXO1pm", label: "Almajel - Jud. MH" },
  { id: "mAOA8Z", label: "Almalau - Jud. CT" },
  { id: "AZvvJZ", label: "Almas - Jud. AR" },
  { id: "r8BEel", label: "Almas - Jud. NT" },
  { id: "l0NgJr", label: "Almas Saliste - Jud. HD" },
  { id: "ZRwXel", label: "Almasel - Jud. HD" },
  { id: "lDq9Jm", label: "Almasu - Jud. SJ" },
  { id: "2l54pZ", label: "Almasu De Mijloc - Jud. AB" },
  { id: "yZRWql", label: "Almasu Mare - Jud. AB" },
  { id: "l7v6YZ", label: "Almasu Mare - Jud. BH" },
  { id: "l0pdol", label: "Almasu Mic - Jud. BH" },
  { id: "majzJr", label: "Almasu Mic - Jud. HD" },
  { id: "r664gr", label: "Almasu Mic De Munte - Jud. HD" },
  { id: "rwD4xZ", label: "Almasu Sec - Jud. HD" },
  { id: "Z4YWxl", label: "Alparea - Jud. BH" },
  { id: "ZMGB5m", label: "Altina - Jud. SB" },
  { id: "ZVy9Kr", label: "Altringen - Jud. TM" },
  { id: "lx74PZ", label: "Alun - Jud. HD" },
  { id: "lDPqGl", label: "Alungeni - Jud. CV" },
  { id: "WZP1Ml", label: "Alunis - Jud. AG" },
  { id: "arB2Jr", label: "Alunis - Jud. AR" },
  { id: "lOWYLl", label: "Alunis - Jud. BZ" },
  { id: "mb9Bal", label: "Alunis - Jud. CJ" },
  { id: "lWekRr", label: "Alunis - Jud. DB" },
  { id: "lxb5nr", label: "Alunis - Jud. GJ" },
  { id: "mnQbxl", label: "Alunis - Jud. HR" },
  { id: "mgaR9Z", label: "Alunis - Jud. MS" },
  { id: "mkzPnl", label: "Alunis - Jud. PH" },
  { id: "ZMGkym", label: "Alunis - Jud. SJ" },
  { id: "arBaDm", label: "Alunisu - Jud. AG" },
  { id: "l54bNZ", label: "Alunisu - Jud. CJ" },
  { id: "mkjpOl", label: "Alunisu - Jud. DB" },
  { id: "m1qLqm", label: "Alunisu - Jud. IF" },
  { id: "rERdgZ", label: "Alunisu - Jud. OT" },
  { id: "ZMYbjZ", label: "Alunisul - Jud. BN" },
  { id: "max79l", label: "Alunisul - Jud. MH" },
  { id: "ljonqm", label: "Alunu - Jud. VL" },
  { id: "leLz6Z", label: "Amara - Jud. BZ" },
  { id: "ZQGoam", label: "Amara - Jud. IL" },
  { id: "mbO4Yr", label: "Amara Noua - Jud. IL" },
  { id: "lO6ooZ", label: "Amarasti - Jud. DJ" },
  { id: "ZPG9br", label: "Amarasti - Jud. VL" },
  { id: "mkjonl", label: "Amarastii De Jos - Jud. DJ" },
  { id: "mnxDGZ", label: "Amarastii De Sus - Jud. DJ" },
  { id: "lLavPm", label: "Amaru - Jud. BZ" },
  { id: "rd71Gm", label: "Amati - Jud. SM" },
  { id: "rob5Nm", label: "Ambud - Jud. SM" },
  { id: "robwqm", label: "Amnas - Jud. SB" },
  { id: "qZNaer", label: "Ampoita - Jud. AB" },
  { id: "r8YYqZ", label: "Amzacea - Jud. CT" },
  { id: "rqzVvZ", label: "Andici - Jud. CJ" },
  { id: "rE2LMZ", label: "Andrasesti - Jud. IL" },
  { id: "lYKJJr", label: "Andreeni - Jud. HR" },
  { id: "ZNAA6r", label: "Andreesti - Jud. GJ" },
  { id: "2l5g9r", label: "Andrei Saguna - Jud. AR" },
  { id: "rB5egm", label: "Andreiasu De Jos - Jud. VN" },
  { id: "lJ6dYl", label: "Andreiasu De Sus - Jud. VN" },
  { id: "mbNOYm", label: "Andreiesti - Jud. VL" },
  { id: "rwPOaZ", label: "Andreneasa - Jud. MS" },
  { id: "mGWQ6m", label: "Andrid - Jud. SM" },
  { id: "r8OxMm", label: "Andrieseni - Jud. IS" },
  { id: "nlj34m", label: "Anghelesti - Jud. AB" },
  { id: "lxbkwr", label: "Anghelesti - Jud. GR" },
  { id: "lL6yAr", label: "Anghelesti - Jud. VN" },
  { id: "mG1PdZ", label: "Anghelus - Jud. CV" },
  { id: "9m1N8r", label: "Anghinesti - Jud. AG" },
  { id: "Zy6pKl", label: "Angofa - Jud. MS" },
  { id: "r6bg9r", label: "Anies - Jud. BN" },
  { id: "r8DWer", label: "Anina - Jud. CS" },
  { id: "l549EZ", label: "Anini - Jud. BZ" },
  { id: "lO69KZ", label: "Aninisu Din Deal - Jud. GJ" },
  { id: "ZyNPDl", label: "Aninisu Din Vale - Jud. GJ" },
  { id: "krdQ7Z", label: "Aninoasa - Jud. AG" },
  { id: "ZzgzDl", label: "Aninoasa - Jud. CV" },
  { id: "mAOW4Z", label: "Aninoasa - Jud. DB" },
  { id: "lj534Z", label: "Aninoasa - Jud. GJ" },
  { id: "r92odZ", label: "Aninoasa - Jud. HD" },
  { id: "lDKAWZ", label: "Aninoasa - Jud. VL" },
  { id: "lDPK1l", label: "Aninosani - Jud. DB" },
  { id: "mk872r", label: "Ant - Jud. BH" },
  { id: "r8DzPr", label: "Antas - Jud. CJ" },
  { id: "l59Bvl", label: "Antoceni - Jud. SV" },
  { id: "lj464r", label: "Antofiloaia - Jud. PH" },
  { id: "lOwwLl", label: "Antohesti - Jud. BC" },
  { id: "r300VZ", label: "Antonesti - Jud. TR" },
  { id: "rwEBXl", label: "Apa - Jud. SM" },
  { id: "ElLjbr", label: "Apa Asau - Jud. BC" },
  { id: "maj1Dr", label: "Apa Neagra - Jud. GJ" },
  { id: "rod4nZ", label: "Apadia - Jud. CS" },
  { id: "lLaAPm", label: "Apahida - Jud. CJ" },
  { id: "r3Baqm", label: "Apalina - Jud. MS" },
  { id: "rdjWGl", label: "Apata - Jud. BV" },
  { id: "nljWBm", label: "Apateu - Jud. AR" },
  { id: "ma19bm", label: "Apateu - Jud. BH" },
  { id: "lDqNGm", label: "Apateu - Jud. SM" },
  { id: "ZP2KKm", label: "Apatiu - Jud. BN" },
  { id: "lDPynl", label: "Apele Vii - Jud. DJ" },
  { id: "mbxAqZ", label: "Apold - Jud. MS" },
  { id: "rERJ6Z", label: "Apoldu De Jos - Jud. SB" },
  { id: "ZNwqYl", label: "Apoldu De Sus - Jud. SB" },
  { id: "rKDGRZ", label: "Apos - Jud. SB" },
  { id: "mnz4Gm", label: "Apostolache - Jud. PH" },
  { id: "mkwNqm", label: "Aprozi - Jud. CL" },
  { id: "rqbp9Z", label: "Araci - Jud. CV" },
  { id: "BZ4azl", label: "Arad - Jud. AR" },
  { id: "lYKBJr", label: "Arama - Jud. IS" },
  { id: "lWRWEZ", label: "Aramesti - Jud. NT" },
  { id: "Zy6n2l", label: "Aramoaia - Jud. NT" },
  { id: "krd1aZ", label: "Araneag - Jud. AR" },
  { id: "lJNJWl", label: "Aranghel - Jud. VL" },
  { id: "m1q5dm", label: "Aranies - Jud. HD" },
  { id: "lDD21l", label: "Arbanasi - Jud. BZ" },
  { id: "rBRjVr", label: "Arbore - Jud. SV" },
  { id: "ZzEqNr", label: "Arborea - Jud. BT" },
  { id: "r82PXl", label: "Arcalia - Jud. BN" },
  { id: "ZP7aAr", label: "Arcani - Jud. GJ" },
  { id: "ZMRWpl", label: "Arcanu - Jud. BZ" },
  { id: "rER7gZ", label: "Arcesti - Jud. OT" },
  { id: "ZMGRym", label: "Arcesti Cot - Jud. OT" },
  { id: "rwDzXZ", label: "Archia - Jud. HD" },
  { id: "rd7MRm", label: "Archid - Jud. SJ" },
  { id: "WZPXDZ", label: "Archis - Jud. AR" },
  { id: "mnEMXl", label: "Archita - Jud. MS" },
  { id: "lOwkLl", label: "Archiud - Jud. BN" },
  { id: "mG1xWZ", label: "Arcus - Jud. CV" },
  { id: "lJQA3Z", label: "Ardan - Jud. BN" },
  { id: "lDak2m", label: "Ardealu - Jud. TL" },
  { id: "rXOpnm", label: "Ardeluta - Jud. NT" },
  { id: "lYDPwZ", label: "Ardeoani - Jud. BC" },
  { id: "ZNWNYl", label: "Ardeova - Jud. CJ" },
  { id: "r92ANZ", label: "Ardeu - Jud. HD" },
  { id: "l5853Z", label: "Ardud - Jud. SM" },
  { id: "l0wJgZ", label: "Ardud Vii - Jud. SM" },
  { id: "lxAKom", label: "Ardusat - Jud. MM" },
  { id: "mbx9KZ", label: "Arduzel - Jud. MM" },
  { id: "OroNxm", label: "Arefu - Jud. AG" },
  { id: "lDKGKZ", label: "Argea - Jud. VN" },
  { id: "rqOzBl", label: "Argel - Jud. SV" },
  { id: "4lOkKl", label: "Argesani - Jud. AG" },
  { id: "7rq6dZ", label: "Argeselu - Jud. AG" },
  { id: "lDq1Wm", label: "Argestru - Jud. SV" },
  { id: "l7QPdr", label: "Argetoaia - Jud. DJ" },
  { id: "lWveBZ", label: "Arghira - Jud. SV" },
  { id: "Zy284l", label: "Arghisu - Jud. CJ" },
  { id: "maxXjl", label: "Arginesti - Jud. MH" },
  { id: "lDqRnm", label: "Aricestii Rahtivani - Jud. PH" },
  { id: "l7y0dr", label: "Aricestii Zeletin - Jud. PH" },
  { id: "mA88Kl", label: "Aricioaia - Jud. VL" },
  { id: "mnJPam", label: "Ariciu - Jud. BR" },
  { id: "krdXRr", label: "Arieseni - Jud. AB" },
  { id: "lWR1yZ", label: "Ariesu De Camp - Jud. MM" },
  { id: "ZzDDNm", label: "Ariesu De Padure - Jud. MM" },
  { id: "rKjaem", label: "Arini - Jud. BC" },
  { id: "lWBEBZ", label: "Arini - Jud. BV" },
  { id: "rE6RxZ", label: "Arinis - Jud. MM" },
  { id: "lOB7nZ", label: "Arionestii Noi - Jud. PH" },
  { id: "ZyY50Z", label: "Arionestii Vechi - Jud. PH" },
  { id: "ZQqagZ", label: "Ariusd - Jud. CV" },
  { id: "roz25m", label: "Arjoci - Jud. GJ" },
  { id: "ZzwV3l", label: "Armaseni - Jud. HR" },
  { id: "lj8aqr", label: "Armaseni - Jud. VS" },
  { id: "rXqanZ", label: "Armasenii Noi - Jud. HR" },
  { id: "r8OLem", label: "Armasesti - Jud. IL" },
  { id: "rBRwbr", label: "Armasesti - Jud. VL" },
  { id: "m1bKdr", label: "Armasoaia - Jud. VS" },
  { id: "r3y80l", label: "Armeni - Jud. SB" },
  { id: "mGgMDm", label: "Armeni - Jud. VN" },
  { id: "r8DyEr", label: "Armenis - Jud. CS" },
  { id: "l5QzAl", label: "Aroneanu - Jud. IS" },
  { id: "PrE26Z", label: "Aronesti - Jud. AB" },
  { id: "ZQGG4m", label: "Arpadia - Jud. GJ" },
  { id: "ZQQP4Z", label: "Arpasel - Jud. BH" },
  { id: "ZMGdGm", label: "Arpasu De Jos - Jud. SB" },
  { id: "lDqgYm", label: "Arpasu De Sus - Jud. SB" },
  { id: "lx85pl", label: "Arsa - Jud. CT" },
  { id: "r36Gzm", label: "Arsanca - Jud. VL" },
  { id: "r3Q3Yl", label: "Arseni - Jud. GJ" },
  { id: "lxxqPl", label: "Arsita - Jud. BN" },
  { id: "lWRXRZ", label: "Arsita - Jud. MS" },
  { id: "lYPVQZ", label: "Arsita - Jud. VN" },
  { id: "mk1vqZ", label: "Arsita - Jud. VS" },
  { id: "l79z3r", label: "Arsura - Jud. VS" },
  { id: "lOWPwl", label: "Arsuri - Jud. CS" },
  { id: "rwDyjZ", label: "Arsuri - Jud. GJ" },
  { id: "lJxQAr", label: "Artanu - Jud. GJ" },
  { id: "r2YPgm", label: "Artari - Jud. CL" },
  { id: "rwVnVm", label: "Artari - Jud. SV" },
  { id: "ElL5ym", label: "Arti - Jud. AB" },
  { id: "leLw3Z", label: "Aruncuta - Jud. CJ" },
  { id: "ZQaBdZ", label: "Arva - Jud. PH" },
  { id: "mndoaZ", label: "Arva - Jud. VN" },
  { id: "l0wpNZ", label: "Arvateasca - Jud. OT" },
  { id: "ZyG5qm", label: "Arvateni - Jud. HR" },
  { id: "lJ2ezl", label: "Arvatesti - Jud. MH" },
  { id: "mAA8om", label: "Asau - Jud. BC" },
  { id: "rXL49r", label: "Aschileu Mare - Jud. CJ" },
  { id: "Z4wO8r", label: "Aschileu Mic - Jud. CJ" },
  { id: "wlpxPl", label: "Asinip - Jud. AB" },
  { id: "Zv5Ewr", label: "Aspra - Jud. MM" },
  { id: "lOwqOl", label: "Astileu - Jud. BH" },
  { id: "rwPpaZ", label: "Asuaju De Jos - Jud. MM" },
  { id: "ZMVGpl", label: "Asuaju De Sus - Jud. MM" },
  { id: "rKDgkZ", label: "Atea - Jud. SM" },
  { id: "ropgLZ", label: "Ateas - Jud. BH" },
  { id: "leG3Jr", label: "Atel - Jud. SB" },
  { id: "l7J9dZ", label: "Atia - Jud. HR" },
  { id: "ZQG8gm", label: "Atid - Jud. HR" },
  { id: "lLVLGr", label: "Atintis - Jud. MS" },
  { id: "lxEnMm", label: "Atmagea - Jud. TL" },
  { id: "Zv2axZ", label: "Augustin - Jud. BV" },
  { id: "ljGwBm", label: "Aurel Vlaicu - Jud. BT" },
  { id: "l7JjLZ", label: "Aurel Vlaicu - Jud. HD" },
  { id: "rK2Mkm", label: "Aurel Vlaicu - Jud. MS" },
  { id: "ZQwGdm", label: "Auresti - Jud. VL" },
  { id: "l57k0r", label: "Aurora - Jud. MH" },
  { id: "r2VAgr", label: "Auseu - Jud. BH" },
  { id: "rXq87Z", label: "Avantu - Jud. IS" },
  { id: "r2w2vZ", label: "Averesti - Jud. NT" },
  { id: "Zvz3PZ", label: "Averesti - Jud. VS" },
  { id: "LmnxBZ", label: "Avram Iancu - Jud. AB" },
  { id: "LZM15Z", label: "Avram Iancu - Jud. AR" },
  { id: "lWB2oZ", label: "Avram Iancu - Jud. BH" },
  { id: "rdjEql", label: "Avram Iancu - Jud. BT" },
  { id: "Z4Y5jl", label: "Avrameni - Jud. BT" },
  { id: "5l7QXr", label: "Avramesti - Jud. AB" },
  { id: "r3QRql", label: "Avramesti - Jud. HR" },
  { id: "Z4DX1Z", label: "Avramesti - Jud. MS" },
  { id: "Zy5W2Z", label: "Avramesti - Jud. VL" },
  { id: "ZVyAJr", label: "Avramesti - Jud. VS" },
  { id: "rqpkQm", label: "Avrig - Jud. SB" },
  { id: "lpA8xm", label: "Axente Sever - Jud. SB" },
  { id: "l5QL0l", label: "Axintele - Jud. IL" },
  { id: "mb5NKl", label: "Azuga - Jud. PH" },
  { id: "XlDanm", label: "Baba - Jud. AB" },
  { id: "r2wQeZ", label: "Baba - Jud. MM" },
  { id: "mGWdpm", label: "Baba Ana - Jud. PH" },
  { id: "ZR31Nl", label: "Baba Novac - Jud. SM" },
  { id: "rqORGl", label: "Babadag - Jud. TL" },
  { id: "lOYxLr", label: "Babaita - Jud. TR" },
  { id: "BmawNZ", label: "Babana - Jud. AG" },
  { id: "krwJvm", label: "Babaroaga - Jud. AG" },
  { id: "ZMG1Qm", label: "Babasesti - Jud. SM" },
  { id: "l54pRZ", label: "Babdiu - Jud. CJ" },
  { id: "rd59qZ", label: "Babeni - Jud. BZ" },
  { id: "l7yA3r", label: "Babeni - Jud. SJ" },
  { id: "mbKMqr", label: "Babeni - Jud. VL" },
  { id: "mnpqqZ", label: "Babeni Oltetu - Jud. VL" },
  { id: "ZNwgQl", label: "Babesti - Jud. SM" },
  { id: "ZRj9eZ", label: "Babiceni - Jud. BT" },
  { id: "l7yy5r", label: "Babiciu - Jud. OT" },
  { id: "mAGkaZ", label: "Babiu - Jud. SJ" },
  { id: "lWv3qZ", label: "Babsa - Jud. TM" },
  { id: "l58q3Z", label: "Babta - Jud. SM" },
  { id: "lj8pdr", label: "Babuesti - Jud. VL" },
  { id: "lj8Mqr", label: "Babusa - Jud. VS" },
  { id: "mbKEdr", label: "Babuta - Jud. VS" },
  { id: "l54EpZ", label: "Babutiu - Jud. CJ" },
  { id: "mGx96r", label: "Bacaia - Jud. HD" },
  { id: "qZNnyZ", label: "Bacainti - Jud. AB" },
  { id: "rBR5dr", label: "Bacalesti - Jud. TR" },
  { id: "rwVRam", label: "Bacani - Jud. VS" },
  { id: "rqOkPl", label: "Bacaoani - Jud. VS" },
  { id: "4lO5Or", label: "Bacau - Jud. BC" },
  { id: "3ZVzoZ", label: "Bacau De Mijloc - Jud. AR" },
  { id: "ZNAj1r", label: "Bacea - Jud. HD" },
  { id: "ZQavvZ", label: "Bacea - Jud. OT" },
  { id: "mboxor", label: "Bacel - Jud. CV" },
  { id: "lOWAAl", label: "Baceni - Jud. BZ" },
  { id: "5l7kdl", label: "Bacesti - Jud. AG" },
  { id: "l52oEm", label: "Bacesti - Jud. DB" },
  { id: "lj554Z", label: "Bacesti - Jud. GJ" },
  { id: "ma6bEr", label: "Bacesti - Jud. VS" },
  { id: "lezW3m", label: "Bacia - Jud. HD" },
  { id: "5l7a5r", label: "Bacioiu - Jud. BC" },
  { id: "ZzodEl", label: "Baciu - Jud. CJ" },
  { id: "ZzVVWZ", label: "Baciu - Jud. TR" },
  { id: "r3BWem", label: "Bacles - Jud. MH" },
  { id: "ZVK1Jl", label: "Baclesti - Jud. BC" },
  { id: "leJVdm", label: "Bacova - Jud. TM" },
  { id: "ZvgnVl", label: "Bacu - Jud. GR" },
  { id: "lpbBpr", label: "Bacu - Jud. IS" },
  { id: "lOBELZ", label: "Badacin - Jud. SJ" },
  { id: "WmG19Z", label: "Badai - Jud. AB" },
  { id: "rqPqvm", label: "Badarai - Jud. BT" },
  { id: "rwV2xm", label: "Badeana - Jud. VS" },
  { id: "XlDnGr", label: "Badeni - Jud. AG" },
  { id: "lpzAbr", label: "Badeni - Jud. BZ" },
  { id: "rK3JRr", label: "Badeni - Jud. CJ" },
  { id: "l52dEm", label: "Badeni - Jud. DB" },
  { id: "ZP79Er", label: "Badeni - Jud. HR" },
  { id: "rwD2XZ", label: "Badeni - Jud. IS" },
  { id: "mgoXDm", label: "Badeni - Jud. VL" },
  { id: "JlJ3zm", label: "Badesti - Jud. AG" },
  { id: "l08LPl", label: "Badesti - Jud. CJ" },
  { id: "l52x9m", label: "Badesti - Jud. GJ" },
  { id: "ma5Qjm", label: "Badeuti - Jud. SV" },
  { id: "WmGpdr", label: "Badicea - Jud. AG" },
  { id: "Rr94qm", label: "Badila - Jud. AG" },
  { id: "mAbw4r", label: "Badila - Jud. BZ" },
  { id: "EZzYDr", label: "Badislava - Jud. AG" },
  { id: "Zv5pxr", label: "Baditesti - Jud. MH" },
  { id: "r2V3er", label: "Badiuti - Jud. BT" },
  { id: "ZzzKbZ", label: "Badon - Jud. SJ" },
  { id: "rqb70Z", label: "Badosi - Jud. DJ" },
  { id: "lDa6Gm", label: "Badragi - Jud. SV" },
  { id: "ZVyqor", label: "Baduleasa - Jud. TR" },
  { id: "WmG7dm", label: "Badulesti - Jud. AG" },
  { id: "lYx1VZ", label: "Badulesti - Jud. DB" },
  { id: "mgABqZ", label: "Baesti - Jud. BZ" },
  { id: "lJWBem", label: "Bagaciu - Jud. CJ" },
  { id: "rE6LBZ", label: "Bagaciu - Jud. MS" },
  { id: "rK34er", label: "Bagara - Jud. CJ" },
  { id: "olWMYr", label: "Bagau - Jud. AB" },
  { id: "r6y3pm", label: "Bagesti - Jud. OT" },
  { id: "r92BOZ", label: "Bahluiu - Jud. IS" },
  { id: "r9q2Xr", label: "Bahna - Jud. BC" },
  { id: "lWRxYZ", label: "Bahna - Jud. MH" },
  { id: "lp5X2m", label: "Bahna - Jud. NT" },
  { id: "mk1jwZ", label: "Bahna Arin - Jud. SV" },
  { id: "m1G68Z", label: "Bahna Mare - Jud. NT" },
  { id: "mbK2vr", label: "Bahnari - Jud. VS" },
  { id: "rXdqgr", label: "Bahnaseni - Jud. BC" },
  { id: "rXOQgm", label: "Bahnea - Jud. MS" },
  { id: "maNBJr", label: "Bahnele - Jud. VN" },
  { id: "mkE4QZ", label: "Bahnisoara - Jud. NT" },
  { id: "2l5A2l", label: "Bai - Jud. AB" },
  { id: "ElL3GZ", label: "Baia - Jud. AR" },
  { id: "rqO01l", label: "Baia - Jud. SV" },
  { id: "mgo5km", label: "Baia - Jud. TL" },
  { id: "ZMV6Gl", label: "Baia De Arama - Jud. MH" },
  { id: "XlxoZp", label: "Baia De Aries - Jud. AB" },
  { id: "lx7noZ", label: "Baia De Cris - Jud. HD" },
  { id: "r66G5r", label: "Baia De Fier - Jud. GJ" },
  { id: "roE23l", label: "Baia Mare - Jud. MM" },
  { id: "mgaYEZ", label: "Baia Noua - Jud. MH" },
  { id: "ZzDEBm", label: "Baia Sprie - Jud. MM" },
  { id: "rdN72l", label: "Baiasa - Jud. VL" },
  { id: "mgJa1l", label: "Baiasu - Jud. VL" },
  { id: "leG4or", label: "Baica - Jud. SJ" },
  { id: "mk84Qr", label: "Baiceni - Jud. BT" },
  { id: "mGxp6r", label: "Baiceni - Jud. IS" },
  { id: "lL766Z", label: "Baicoi - Jud. PH" },
  { id: "AZvaDr", label: "Baiculesti - Jud. AG" },
  { id: "ZzwnBl", label: "Baiesti - Jud. HD" },
  { id: "2l5DvZ", label: "Baila - Jud. AG" },
  { id: "lpzbYr", label: "Baile - Jud. BZ" },
  { id: "ZMVYjl", label: "Baile Borsa - Jud. MM" },
  { id: "mkapwZ", label: "Baile Chirui - Jud. HR" },
  { id: "mnpXaZ", label: "Baile Dranceni - Jud. VS" },
  { id: "mgP15r", label: "Baile Felix - Jud. BH" },
  { id: "l089Bl", label: "Baile Herculane - Jud. CS" },
  { id: "r3QyAl", label: "Baile Homorod - Jud. HR" },
  { id: "mnQzGl", label: "Baile Tusnad - Jud. HR" },
  { id: "mG1MBZ", label: "Bailesti - Jud. DJ" },
  { id: "Zyee8Z", label: "Baimac - Jud. BC" },
  { id: "m1bnRr", label: "Bainet - Jud. SV" },
  { id: "lxxk7l", label: "Baisa - Jud. BT" },
  { id: "r3yXAl", label: "Baisesti - Jud. SV" },
  { id: "rXL6Lr", label: "Baisoara - Jud. CJ" },
  { id: "rwnNQm", label: "Baita - Jud. BH" },
  { id: "mGw6Dm", label: "Baita - Jud. CJ" },
  { id: "lpbW2r", label: "Baita - Jud. HD" },
  { id: "Zy6e8l", label: "Baita - Jud. MM" },
  { id: "lxAqPm", label: "Baita - Jud. MS" },
  { id: "lexG3Z", label: "Baita De Sub Codru - Jud. MM" },
  { id: "rByxam", label: "Baita Plai - Jud. BH" },
  { id: "lp5A2m", label: "Baiut - Jud. MM" },
  { id: "XlDkbZ", label: "Bajanesti - Jud. AG" },
  { id: "lW1kEr", label: "Bajani - Jud. BZ" },
  { id: "roYbLl", label: "Bajenari - Jud. VL" },
  { id: "7rqDvm", label: "Bajesti - Jud. AG" },
  { id: "lWBqqZ", label: "Bajura - Jud. BT" },
  { id: "rq0LBm", label: "Bala - Jud. MH" },
  { id: "ZMVL5l", label: "Bala - Jud. MS" },
  { id: "l57Opr", label: "Bala De Sus - Jud. MH" },
  { id: "lOYJwr", label: "Balabancea - Jud. TL" },
  { id: "l0XONl", label: "Balabanesti - Jud. GL" },
  { id: "wlpMxm", label: "Balabani - Jud. AG" },
  { id: "rwE6Ql", label: "Balaceana - Jud. SV" },
  { id: "ZNA5Vr", label: "Balaceanca - Jud. IF" },
  { id: "mkwaOm", label: "Balaceanu - Jud. BZ" },
  { id: "rqbdQZ", label: "Balacesti - Jud. GJ" },
  { id: "m1bR3r", label: "Balaci - Jud. TR" },
  { id: "ZQVYDl", label: "Balacita - Jud. MH" },
  { id: "ZM9LzZ", label: "Balaciu - Jud. IL" },
  { id: "lL67Xr", label: "Balaciu - Jud. VL" },
  { id: "lea2ol", label: "Balaia - Jud. BC" },
  { id: "r82GDl", label: "Balaia - Jud. BH" },
  { id: "rBWGbZ", label: "Balaia - Jud. BZ" },
  { id: "l7JydZ", label: "Balan - Jud. HR" },
  { id: "mGWL0m", label: "Balan - Jud. SJ" },
  { id: "r6bO6r", label: "Balaneasa - Jud. BC" },
  { id: "r33Q4r", label: "Balanesti - Jud. BC" },
  { id: "l08K6l", label: "Balanesti - Jud. BZ" },
  { id: "m1Dbal", label: "Balanesti - Jud. DB" },
  { id: "r92yMZ", label: "Balanesti - Jud. GJ" },
  { id: "Zy6BDl", label: "Balanesti - Jud. NT" },
  { id: "rBnyVr", label: "Balanesti - Jud. OT" },
  { id: "ZvOG9l", label: "Balanesti - Jud. VN" },
  { id: "mA4Oql", label: "Balani - Jud. GJ" },
  { id: "ZNG1Vl", label: "Balanu - Jud. GR" },
  { id: "rwyWKl", label: "Balasan - Jud. DJ" },
  { id: "ZRxyKm", label: "Balasesti - Jud. GL" },
  { id: "rBbYqr", label: "Balata - Jud. HD" },
  { id: "lex5YZ", label: "Balauseri - Jud. MS" },
  { id: "ZVK2xl", label: "Balc - Jud. BH" },
  { id: "9m12dr", label: "Balca - Jud. BC" },
  { id: "QmbNQm", label: "Balcaciu - Jud. AB" },
  { id: "ZNyDLr", label: "Balcani - Jud. BC" },
  { id: "ZQyVJZ", label: "Balcauti - Jud. SV" },
  { id: "mGw80m", label: "Balcesti - Jud. CJ" },
  { id: "ZM7P5m", label: "Balcesti - Jud. GJ" },
  { id: "rqOVQl", label: "Balcesti - Jud. VL" },
  { id: "lODQJm", label: "Balciu - Jud. IS" },
  { id: "ZQVngl", label: "Balda - Jud. MS" },
  { id: "lpEy6m", label: "Baldana - Jud. DB" },
  { id: "l5QAAl", label: "Baldovin - Jud. HD" },
  { id: "lpzzWr", label: "Baldovinesti - Jud. BR" },
  { id: "mGWWDm", label: "Baldovinesti - Jud. OT" },
  { id: "m1b8qr", label: "Baldovinesti - Jud. TR" },
  { id: "rwEgXl", label: "Baleasa - Jud. OT" },
  { id: "lxxe0l", label: "Baleni - Jud. BH" },
  { id: "rdVn7l", label: "Baleni - Jud. GL" },
  { id: "maoNNZ", label: "Baleni Romani - Jud. DB" },
  { id: "ZvgODl", label: "Baleni Sarbi - Jud. DB" },
  { id: "LZM9GZ", label: "Balesti - Jud. AB" },
  { id: "ZRwKjl", label: "Balesti - Jud. GJ" },
  { id: "rqx7om", label: "Balesti - Jud. VN" },
  { id: "rKx4kZ", label: "Balesti - Jud. VS" },
  { id: "VlezJm", label: "Balesti Catun - Jud. AB" },
  { id: "m1nBdr", label: "Balhacu - Jud. BZ" },
  { id: "nlj2bl", label: "Balilesti - Jud. AG" },
  { id: "rwVgvm", label: "Balinesti - Jud. SV" },
  { id: "l59q0l", label: "Balint - Jud. TM" },
  { id: "rBOL5Z", label: "Balintesti - Jud. GL" },
  { id: "mG5LwZ", label: "Balinti - Jud. BT" },
  { id: "BmaAEm", label: "Balmosesti - Jud. AB" },
  { id: "lOwPJl", label: "Balnaca - Jud. BH" },
  { id: "ZyeOKZ", label: "Balnaca Grosi - Jud. BH" },
  { id: "lezQYm", label: "Balomir - Jud. HD" },
  { id: "lxzE0r", label: "Balomireasa - Jud. VL" },
  { id: "JmAR6r", label: "Balomiru De Camp - Jud. AB" },
  { id: "Zvoo5l", label: "Balosani - Jud. GJ" },
  { id: "lLJ9Pr", label: "Balosesti - Jud. TM" },
  { id: "lYn7Rm", label: "Balota - Jud. MH" },
  { id: "lYPGJZ", label: "Balota - Jud. VL" },
  { id: "lWe8or", label: "Balota De Jos - Jud. DJ" },
  { id: "mkjY2l", label: "Balota De Sus - Jud. DJ" },
  { id: "Wr8wKm", label: "Baloteasca - Jud. AG" },
  { id: "ZMYGpZ", label: "Balotesti - Jud. BC" },
  { id: "ZQVM5l", label: "Balotesti - Jud. IF" },
  { id: "m1G4jZ", label: "Balotesti - Jud. MH" },
  { id: "ZQG4dm", label: "Bals - Jud. IS" },
  { id: "mGPRWr", label: "Bals - Jud. OT" },
  { id: "rE2XxZ", label: "Balsa - Jud. HD" },
  { id: "lWMeor", label: "Balsoara - Jud. VL" },
  { id: "lDPRbl", label: "Balta - Jud. DJ" },
  { id: "rdo5zm", label: "Balta - Jud. GJ" },
  { id: "ZzDBEm", label: "Balta - Jud. MH" },
  { id: "ZzojEl", label: "Balta Alba - Jud. BZ" },
  { id: "ZVKEWl", label: "Balta Arsa - Jud. BT" },
  { id: "rBn4Qr", label: "Balta Doamnei - Jud. PH" },
  { id: "lj5pkZ", label: "Balta Neagra - Jud. IF" },
  { id: "mGgd0m", label: "Balta Ratei - Jud. VN" },
  { id: "mA98am", label: "Balta Sarata - Jud. TR" },
  { id: "ZPe8kl", label: "Balta Tocila - Jud. BZ" },
  { id: "r6qg5m", label: "Balta Verde - Jud. DJ" },
  { id: "rwPxvZ", label: "Balta Verde - Jud. MH" },
  { id: "lOWAGl", label: "Baltagari - Jud. BZ" },
  { id: "ZMRvzl", label: "Baltagesti - Jud. CT" },
  { id: "mA1RKm", label: "Baltanele - Jud. MH" },
  { id: "Zzo8Nl", label: "Baltareti - Jud. BZ" },
  { id: "ZPBLbm", label: "Baltareti - Jud. GL" },
  { id: "WZPEAl", label: "Baltata - Jud. AG" },
  { id: "mAAGWm", label: "Baltata - Jud. BC" },
  { id: "lejjgm", label: "Baltateni - Jud. VL" },
  { id: "l0KQoZ", label: "Baltateni - Jud. VS" },
  { id: "r6GM7Z", label: "Baltatesti - Jud. NT" },
  { id: "l0N60r", label: "Baltati - Jud. IS" },
  { id: "rK2vkm", label: "Baltati - Jud. OT" },
  { id: "r99Lar", label: "Baltati - Jud. VS" },
  { id: "BZ451l", label: "Balteni - Jud. AG" },
  { id: "r3kynr", label: "Balteni - Jud. BZ" },
  { id: "ZvgD9l", label: "Balteni - Jud. DB" },
  { id: "lL1VWm", label: "Balteni - Jud. GJ" },
  { id: "mbOaBr", label: "Balteni - Jud. IF" },
  { id: "l5Q6Yl", label: "Balteni - Jud. IS" },
  { id: "r9ypqr", label: "Balteni - Jud. OT" },
  { id: "r2MJdm", label: "Balteni - Jud. VL" },
  { id: "rBRpgr", label: "Balteni - Jud. VS" },
  { id: "mbKAor", label: "Balteni Deal - Jud. VS" },
  { id: "lOYXwr", label: "Baltenii De Jos - Jud. TL" },
  { id: "ZyWv2m", label: "Baltenii De Sus - Jud. TL" },
  { id: "rqp4dm", label: "Baltesti - Jud. PH" },
  { id: "rozdwm", label: "Baltisoara - Jud. GJ" },
  { id: "rqpnvm", label: "Baltita - Jud. PH" },
  { id: "leaodl", label: "Balusa - Jud. BC" },
  { id: "lxxVpl", label: "Baluseni - Jud. BT" },
  { id: "ZzE5nr", label: "Balusenii Noi - Jud. BT" },
  { id: "rqE6dr", label: "Balusesti - Jud. IS" },
  { id: "rq0qGm", label: "Balusesti - Jud. NT" },
  { id: "rq0gdm", label: "Baluta - Jud. MH" },
  { id: "l0kBPZ", label: "Balutoaia - Jud. VL" },
  { id: "m1GdRZ", label: "Balvanesti - Jud. MH" },
  { id: "ZQV9nl", label: "Balvanestii De Jos - Jud. MH" },
  { id: "Z490zl", label: "Ban - Jud. SJ" },
  { id: "EZzJYZ", label: "Banaresti - Jud. AG" },
  { id: "qZNPVm", label: "Banca - Jud. BC" },
  { id: "mGap0Z", label: "Banca - Jud. VS" },
  { id: "lOYWNr", label: "Bancesti - Jud. SV" },
  { id: "lJNB3l", label: "Bancesti - Jud. VS" },
  { id: "Z4310r", label: "Bancu - Jud. HR" },
  { id: "Z4D6QZ", label: "Band - Jud. MS" },
  { id: "lYyLQl", label: "Bandoiu - Jud. BR" },
  { id: "m1np6r", label: "Baneasa - Jud. CT" },
  { id: "ronQxm", label: "Baneasa - Jud. GL" },
  { id: "mG1pDZ", label: "Baneasa - Jud. GR" },
  { id: "mnEnBl", label: "Baneasa - Jud. NT" },
  { id: "ma6X2r", label: "Baneasa - Jud. TR" },
  { id: "4lOqLr", label: "Banesti - Jud. AR" },
  { id: "maoOJZ", label: "Banesti - Jud. DB" },
  { id: "ZyNa4l", label: "Banesti - Jud. GR" },
  { id: "ZQandZ", label: "Banesti - Jud. PH" },
  { id: "lJNj9l", label: "Banesti - Jud. SV" },
  { id: "lejz1m", label: "Banesti - Jud. VL" },
  { id: "l543YZ", label: "Bania - Jud. CS" },
  { id: "BmaPEl", label: "Banicesti - Jud. AG" },
  { id: "rwEJal", label: "Banisor - Jud. SJ" },
  { id: "r3kR0r", label: "Banita - Jud. BZ" },
  { id: "rXqXRZ", label: "Banita - Jud. HD" },
  { id: "r27zeZ", label: "Baniu - Jud. GJ" },
  { id: "l0K5BZ", label: "Banloc - Jud. TM" },
  { id: "l79K5r", label: "Banov - Jud. TR" },
  { id: "ZVw0Jl", label: "Banpotoc - Jud. HD" },
  { id: "Xl0PRl", label: "Bantau - Jud. AG" },
  { id: "mk5j2Z", label: "Bantesti - Jud. VL" },
  { id: "lYKNRr", label: "Banu - Jud. IS" },
  { id: "lD6Jjr", label: "Bara - Jud. MS" },
  { id: "r3yJ4l", label: "Bara - Jud. PH" },
  { id: "ZR6gjZ", label: "Bara - Jud. TM" },
  { id: "arBbr0", label: "Barabant - Jud. AB" },
  { id: "ZzgBKl", label: "Baraceni - Jud. DB" },
  { id: "rBW3aZ", label: "Baraganu - Jud. CT" },
  { id: "lOWWOl", label: "Baraganul - Jud. BR" },
  { id: "lpzPbr", label: "Barai - Jud. CJ" },
  { id: "ZQVNdl", label: "Baraiacu - Jud. MH" },
  { id: "rwEbyl", label: "Baraitaru - Jud. PH" },
  { id: "ZyeALZ", label: "Baranca - Jud. BT" },
  { id: "krw23m", label: "Baranesti - Jud. AG" },
  { id: "l0XdJl", label: "Baranu - Jud. DJ" },
  { id: "mboovr", label: "Baraolt - Jud. CV" },
  { id: "krwpjZ", label: "Barasti - Jud. AB" },
  { id: "yZyykZ", label: "Barasti - Jud. AG" },
  { id: "rK3xer", label: "Barasti - Jud. BZ" },
  { id: "rwEExl", label: "Barasti - Jud. OT" },
  { id: "ZV3xAl", label: "Barasti - Jud. SV" },
  { id: "mbxeoZ", label: "Barastii De Cepturi - Jud. OT" },
  { id: "mgaGAZ", label: "Barastii De Vede - Jud. OT" },
  { id: "lpbNMr", label: "Barastii Hategului - Jud. HD" },
  { id: "rqEvPr", label: "Barastii Iliei - Jud. HD" },
  { id: "AZQeoZ", label: "Baratca - Jud. AR" },
  { id: "lOAjKr", label: "Baratca - Jud. NT" },
  { id: "lD252r", label: "Baratcos - Jud. HR" },
  { id: "rBR2Dr", label: "Barateaz - Jud. TM" },
  { id: "rqPzom", label: "Barati - Jud. BC" },
  { id: "m1Bqdr", label: "Barbalai - Jud. OT" },
  { id: "Wr6z5r", label: "Barbalani - Jud. AG" },
  { id: "wmgqAZ", label: "Barbalatesti - Jud. AG" },
  { id: "maN59r", label: "Barbarigeni - Jud. VL" },
  { id: "ZVwGRl", label: "Barbatescu - Jud. IL" },
  { id: "Wr6K5Z", label: "Barbatesti - Jud. AG" },
  { id: "mgLaEr", label: "Barbatesti - Jud. GJ" },
  { id: "mGx8pr", label: "Barbatesti - Jud. IS" },
  { id: "r65N3m", label: "Barbatesti - Jud. VL" },
  { id: "LZMx8r", label: "Barbesti - Jud. AB" },
  { id: "rqPbom", label: "Barboasa - Jud. BC" },
  { id: "m1D0Yl", label: "Barboi - Jud. DJ" },
  { id: "lJ2LWl", label: "Barbosi - Jud. MS" },
  { id: "ZQy6JZ", label: "Barbosi - Jud. VS" },
  { id: "mb9gvl", label: "Barbosu - Jud. CS" },
  { id: "m1D8al", label: "Barbuceanu - Jud. DB" },
  { id: "rK6DPm", label: "Barbuceni - Jud. VL" },
  { id: "rdoAzm", label: "Barbulesti - Jud. IL" },
  { id: "lj96BZ", label: "Barbuletu - Jud. DB" },
  { id: "rK3K9r", label: "Barbuncesti - Jud. BZ" },
  { id: "ZQGYvm", label: "Barbura - Jud. HD" },
  { id: "rwyOvl", label: "Barca - Jud. DJ" },
  { id: "Z4938l", label: "Barca - Jud. OT" },
  { id: "m1qkYm", label: "Barcaciu - Jud. GJ" },
  { id: "lxxAnl", label: "Barcana - Jud. BC" },
  { id: "rdNx2l", label: "Barcanele - Jud. VL" },
  { id: "l0NqBr", label: "Barcanesti - Jud. IL" },
  { id: "m1GyYZ", label: "Barcanesti - Jud. NT" },
  { id: "mGWPDm", label: "Barcanesti - Jud. OT" },
  { id: "mgB91l", label: "Barcanesti - Jud. PH" },
  { id: "lJ6Nzl", label: "Barcanesti - Jud. VL" },
  { id: "rqbp1Z", label: "Barcani - Jud. CV" },
  { id: "l52J2m", label: "Barcea - Jud. GL" },
  { id: "lODvAm", label: "Barcea Mare - Jud. HD" },
  { id: "rBbGVr", label: "Barcea Mica - Jud. HD" },
  { id: "lDAO2m", label: "Barcut - Jud. BV" },
  { id: "lj3gLm", label: "Barda - Jud. MH" },
  { id: "AZQwdm", label: "Bardesti - Jud. AB" },
  { id: "rK2kEm", label: "Bardesti - Jud. MS" },
  { id: "rXOygm", label: "Bargau - Jud. MM" },
  { id: "r3Bg0m", label: "Bargauani - Jud. NT" },
  { id: "mnzABm", label: "Barghis - Jud. SB" },
  { id: "r2w6QZ", label: "Barjoveni - Jud. NT" },
  { id: "Wr6Mpm", label: "Barla - Jud. AG" },
  { id: "ZMY2RZ", label: "Barla - Jud. BN" },
  { id: "lxE30m", label: "Barlad - Jud. VS" },
  { id: "r2Mkgm", label: "Barlalesti - Jud. VS" },
  { id: "mb9bKl", label: "Barlea - Jud. CJ" },
  { id: "OrooNr", label: "Barlesti - Jud. AB" },
  { id: "l0NOgr", label: "Barlesti - Jud. IS" },
  { id: "wlpvpr", label: "Barlesti Catun - Jud. AB" },
  { id: "rq0Dom", label: "Barlibas - Jud. MS" },
  { id: "ZPaLDl", label: "Barlibasoaia - Jud. MS" },
  { id: "rE6N5Z", label: "Barlogeni - Jud. MH" },
  { id: "arBE5m", label: "Barlogu - Jud. AG" },
  { id: "roYxvl", label: "Barlogu - Jud. VL" },
  { id: "ElLbPr", label: "Barloi - Jud. AG" },
  { id: "rKjPRm", label: "Barna - Jud. BC" },
  { id: "ZPAWAl", label: "Barna - Jud. TM" },
  { id: "lOAjor", label: "Barnadu - Jud. NT" },
  { id: "Z4Y3Ql", label: "Barnesti - Jud. BC" },
  { id: "ZVwj6l", label: "Barnova - Jud. IS" },
  { id: "mgJJkl", label: "Baroiu - Jud. VL" },
  { id: "QmbWqr", label: "Barsa - Jud. AR" },
  { id: "mkzdPl", label: "Barsa - Jud. SJ" },
  { id: "EZzQWr", label: "Barsana - Jud. AB" },
  { id: "lD6abr", label: "Barsana - Jud. MM" },
  { id: "ZRjByZ", label: "Barsanesti - Jud. BC" },
  { id: "rdjYql", label: "Barsanesti - Jud. BT" },
  { id: "lJx03r", label: "Barsau - Jud. HD" },
  { id: "ZQaeDZ", label: "Barsau De Jos - Jud. SM" },
  { id: "rqp9Bm", label: "Barsau De Sus - Jud. SM" },
  { id: "r2qjAm", label: "Barsau Mare - Jud. SJ" },
  { id: "r3y1nl", label: "Barsauta - Jud. SJ" },
  { id: "Zzg6Bl", label: "Barsesti - Jud. GJ" },
  { id: "m18D6l", label: "Barsesti - Jud. VL" },
  { id: "ZQwxom", label: "Barsesti - Jud. VN" },
  { id: "XlxVzr", label: "Barsestii De Jos - Jud. AG" },
  { id: "ZPAAjl", label: "Barsestii De Jos - Jud. TR" },
  { id: "PrExVm", label: "Barsestii De Sus - Jud. AG" },
  { id: "l7yQqr", label: "Barsestii De Sus - Jud. OT" },
  { id: "rE4a5m", label: "Barsoiu - Jud. VL" },
  { id: "r30VGZ", label: "Bartalus Mocani - Jud. VS" },
  { id: "m1bKqr", label: "Bartalus Razesi - Jud. VS" },
  { id: "ZzEVNr", label: "Bartasesti - Jud. BC" },
  { id: "lp53xm", label: "Barticesti - Jud. NT" },
  { id: "ZM9XpZ", label: "Baru - Jud. HD" },
  { id: "r3kYAr", label: "Barz - Jud. CS" },
  { id: "rBW05Z", label: "Barza - Jud. CS" },
  { id: "Z4q8Jl", label: "Barza - Jud. GJ" },
  { id: "Zvo4Vl", label: "Barza - Jud. HD" },
  { id: "rE6kqZ", label: "Barza - Jud. MS" },
  { id: "rBnnJr", label: "Barza - Jud. OT" },
  { id: "rdKWqm", label: "Barza - Jud. VL" },
  { id: "Jr36Am", label: "Barzan - Jud. AB" },
  { id: "ElLXGl", label: "Barzava - Jud. AR" },
  { id: "l0Nkgr", label: "Barzava - Jud. HR" },
  { id: "mkjABl", label: "Barzeiu - Jud. GJ" },
  { id: "rBOQgZ", label: "Barzeiu De Gilort - Jud. GJ" },
  { id: "JlJpYr", label: "Barzesti - Jud. AG" },
  { id: "4lOzGZ", label: "Barzesti - Jud. AR" },
  { id: "lOY0Kr", label: "Barzesti - Jud. VL" },
  { id: "lOY8Gr", label: "Barzesti - Jud. VS" },
  { id: "lJ9eAZ", label: "Barzila - Jud. PH" },
  { id: "olWQBr", label: "Barzogani - Jud. AB" },
  { id: "m1kB8Z", label: "Barzulesti - Jud. BC" },
  { id: "mkaYBZ", label: "Basarabasa - Jud. HD" },
  { id: "rqbvQZ", label: "Basarabi - Jud. DJ" },
  { id: "mnpxxZ", label: "Basarabi - Jud. SV" },
  { id: "lxx7nl", label: "Basasti - Jud. BC" },
  { id: "lDDaKl", label: "Basca Chiojdului - Jud. BZ" },
  { id: "ZQvGvl", label: "Basca Rozilei - Jud. BZ" },
  { id: "ZV13or", label: "Bascenii De Jos - Jud. BZ" },
  { id: "lJW9gm", label: "Bascenii De Sus - Jud. BZ" },
  { id: "JmAy6m", label: "Bascov - Jud. AG" },
  { id: "lJPO9r", label: "Bascov - Jud. DJ" },
  { id: "3ZV4Am", label: "Bascovele - Jud. AG" },
  { id: "ZyWwYm", label: "Bascoveni - Jud. TR" },
  { id: "lWRDEZ", label: "Basesti - Jud. MM" },
  { id: "rKjwQm", label: "Baseu - Jud. BT" },
  { id: "rK2pPm", label: "Basta - Jud. NT" },
  { id: "lj5eNZ", label: "Bastea - Jud. HD" },
  { id: "Wr6gWZ", label: "Bata - Jud. AR" },
  { id: "lLeKAm", label: "Bata - Jud. BN" },
  { id: "mGxMDr", label: "Batalar - Jud. HD" },
  { id: "rBb1Dr", label: "Bataluri - Jud. IL" },
  { id: "ZQqGDZ", label: "Batanii Mari - Jud. CV" },
  { id: "m1DqRl", label: "Batanii Mici - Jud. CV" },
  { id: "lJQDYZ", label: "Batar - Jud. BH" },
  { id: "rBn7Vr", label: "Batarci - Jud. SM" },
  { id: "r6Gd3Z", label: "Batareni - Jud. OT" },
  { id: "m18XRl", label: "Batasani - Jud. VL" },
  { id: "rXx14r", label: "Batcari - Jud. VN" },
  { id: "Z49KQl", label: "Batesti - Jud. PH" },
  { id: "rqOnol", label: "Batesti - Jud. TM" },
  { id: "mAG66Z", label: "Batia - Jud. OT" },
  { id: "mGwA9m", label: "Batin - Jud. CJ" },
  { id: "lDKRKZ", label: "Batinesti - Jud. VN" },
  { id: "lL15ym", label: "Batiz - Jud. HD" },
  { id: "l71pnr", label: "Batogu - Jud. BR" },
  { id: "ZPeODl", label: "Batogu - Jud. BZ" },
  { id: "lxAOnm", label: "Batos - Jud. MS" },
  { id: "maxAjl", label: "Batoti - Jud. MH" },
  { id: "lWOXEl", label: "Batrana - Jud. HD" },
  { id: "ZMYapZ", label: "Batranesti - Jud. BT" },
  { id: "ZQV14l", label: "Batranesti - Jud. NT" },
  { id: "wlp3PZ", label: "Batrani - Jud. AG" },
  { id: "ZR37Al", label: "Batrani - Jud. PH" },
  { id: "yZRzDZ", label: "Batuta - Jud. AR" },
  { id: "l08Gol", label: "Bautar - Jud. CS" },
  { id: "r9273Z", label: "Bazavani - Jud. GJ" },
  { id: "rEdexm", label: "Bazdana - Jud. DJ" },
  { id: "PrEqVr", label: "Bazesti - Jud. AB" },
  { id: "l7vv5Z", label: "Bazga - Jud. BC" },
  { id: "ZvAL3r", label: "Bazias - Jud. CS" },
  { id: "lWDLAm", label: "Bazna - Jud. SB" },
  { id: "ZyWq0m", label: "Bazos - Jud. TM" },
  { id: "r99WXr", label: "Bazosu Nou - Jud. TM" },
  { id: "rdKq5m", label: "Beba Veche - Jud. TM" },
  { id: "lOD1Jm", label: "Becas - Jud. HR" },
  { id: "rXL1Lr", label: "Beceni - Jud. BZ" },
  { id: "lYKywr", label: "Becheni - Jud. GJ" },
  { id: "ZQaXgZ", label: "Becheni - Jud. SM" },
  { id: "r3Gqzr", label: "Bechet - Jud. DJ" },
  { id: "lWR6kZ", label: "Bechet - Jud. OT" },
  { id: "rwyNol", label: "Bechinesti - Jud. DB" },
  { id: "roxAnZ", label: "Becicherecu Mic - Jud. TM" },
  { id: "r6Op2Z", label: "Beciu - Jud. BZ" },
  { id: "lj88br", label: "Beciu - Jud. TR" },
  { id: "lO7zLl", label: "Beciu - Jud. VN" },
  { id: "leaQ6l", label: "Beclean - Jud. BN" },
  { id: "ropyNZ", label: "Beclean - Jud. BV" },
  { id: "l78pXm", label: "Becsani - Jud. VL" },
  { id: "2l5L0Z", label: "Beculesti - Jud. AG" },
  { id: "r8BeEl", label: "Beculesti - Jud. OT" },
  { id: "r8Db6r", label: "Bedeciu - Jud. CJ" },
  { id: "r3BqGm", label: "Bedeni - Jud. MS" },
  { id: "ZQy5oZ", label: "Begheiu Mic - Jud. TM" },
  { id: "r3kv4r", label: "Begu - Jud. BZ" },
  { id: "ZvgBKl", label: "Beharca - Jud. DJ" },
  { id: "lDAVnm", label: "Beia - Jud. BV" },
  { id: "lp57Mm", label: "Beica De Jos - Jud. MS" },
  { id: "rE6YoZ", label: "Beica De Sus - Jud. MS" },
  { id: "mbKJvr", label: "Beidaud - Jud. TL" },
  { id: "m1nR8r", label: "Beilic - Jud. BZ" },
  { id: "ZNJdVZ", label: "Beiu - Jud. TR" },
  { id: "r33vYr", label: "Beius - Jud. BH" },
  { id: "Zv2GxZ", label: "Beiusele - Jud. BH" },
  { id: "rqE8Qr", label: "Bejan - Jud. HD" },
  { id: "ZQGgpm", label: "Bejan Tarnavita - Jud. HD" },
  { id: "ma6ver", label: "Bejenesti - Jud. VS" },
  { id: "l7Q9Lr", label: "Bela - Jud. DB" },
  { id: "mbo5or", label: "Belani - Jud. CV" },
  { id: "ZNyQLr", label: "Belcea - Jud. BT" },
  { id: "ZRwdql", label: "Belcesti - Jud. IS" },
  { id: "lWv0NZ", label: "Belcesti - Jud. VS" },
  { id: "ZM70pm", label: "Belcinu - Jud. DJ" },
  { id: "rBnJbr", label: "Belciug - Jud. PH" },
  { id: "leJp6m", label: "Belciug - Jud. TR" },
  { id: "ZvA39r", label: "Belciugatele - Jud. CL" },
  { id: "rwYwol", label: "Belciugele - Jud. VN" },
  { id: "leaG3l", label: "Belciuneasa - Jud. BC" },
  { id: "BZ4YJl", label: "Beldiu - Jud. AB" },
  { id: "krdORm", label: "Beleghet - Jud. BC" },
  { id: "rwnwvm", label: "Belejeni - Jud. BH" },
  { id: "BmaKDZ", label: "Beleti - Jud. AG" },
  { id: "ma1eNm", label: "Belfir - Jud. BH" },
  { id: "mkEQqZ", label: "Belgun - Jud. OT" },
  { id: "lO6BOZ", label: "Belin - Jud. CV" },
  { id: "lDP2Gl", label: "Belin Vale - Jud. CV" },
  { id: "ma6Gjr", label: "Belint - Jud. TM" },
  { id: "Z4wLqr", label: "Belis - Jud. CJ" },
  { id: "Rr9gXl", label: "Beliu - Jud. AR" },
  { id: "ZRJeNZ", label: "Belobresca - Jud. CS" },
  { id: "lYxaJZ", label: "Belot - Jud. DJ" },
  { id: "0lYR0m", label: "Belotint - Jud. AR" },
  { id: "rqpo1m", label: "Beltiug - Jud. SM" },
  { id: "lLJEyr", label: "Belzeni - Jud. VS" },
  { id: "lj8V1r", label: "Bencecu De Jos - Jud. TM" },
  { id: "ZPAMvl", label: "Bencecu De Sus - Jud. TM" },
  { id: "rBnPgr", label: "Benesat - Jud. SJ" },
  { id: "Rr90am", label: "Benesti - Jud. AR" },
  { id: "lp1A2m", label: "Benesti - Jud. BC" },
  { id: "mnxLBZ", label: "Benesti - Jud. DJ" },
  { id: "leG6Yr", label: "Benesti - Jud. SB" },
  { id: "ZQyRpZ", label: "Benesti - Jud. VL" },
  { id: "l0KoQZ", label: "Benesti - Jud. VS" },
  { id: "rEd5Bm", label: "Bengesti - Jud. GJ" },
  { id: "rwVpVm", label: "Benia - Jud. SV" },
  { id: "9m1bjr", label: "Benic - Jud. AB" },
  { id: "lODdJm", label: "Bentid - Jud. HR" },
  { id: "lW1Myr", label: "Bentu - Jud. BZ" },
  { id: "lxA7nm", label: "Berbesti - Jud. MM" },
  { id: "lpxzxl", label: "Berbesti - Jud. VL" },
  { id: "ZRxzem", label: "Berbesu - Jud. DJ" },
  { id: "r9qDxr", label: "Berbinceni - Jud. BC" },
  { id: "Z4wjqr", label: "Berca - Jud. BZ" },
  { id: "leGAdr", label: "Bercea - Jud. SJ" },
  { id: "rKnAem", label: "Berceni - Jud. IF" },
  { id: "mb5RQl", label: "Berceni - Jud. PH" },
  { id: "ZRJ6LZ", label: "Bercesti - Jud. BZ" },
  { id: "r27X9Z", label: "Bercesti - Jud. GJ" },
  { id: "ZNYY7r", label: "Berchez - Jud. MM" },
  { id: "r8BB0l", label: "Berchezoaia - Jud. MM" },
  { id: "l71EAr", label: "Berchiesu - Jud. CJ" },
  { id: "m1Bp6r", label: "Berchisesti - Jud. SV" },
  { id: "ma6MWr", label: "Bercioiu - Jud. VL" },
  { id: "rwDWxZ", label: "Bercu - Jud. HD" },
  { id: "lL731Z", label: "Bercu - Jud. SM" },
  { id: "l7yOnr", label: "Bercu Nou - Jud. SM" },
  { id: "l7yBwr", label: "Berea - Jud. SM" },
  { id: "r301VZ", label: "Bereasa - Jud. VS" },
  { id: "AZQn5l", label: "Berechiu - Jud. AR" },
  { id: "rwn43m", label: "Berechiu - Jud. BH" },
  { id: "lOYvnr", label: "Berecuta - Jud. TM" },
  { id: "r30NYZ", label: "Beregsau Mare - Jud. TM" },
  { id: "m1bYYr", label: "Beregsau Mic - Jud. TM" },
  { id: "mnEeal", label: "Bereni - Jud. MS" },
  { id: "l7vyvZ", label: "Beresti - Jud. BC" },
  { id: "r9nY8Z", label: "Beresti - Jud. GL" },
  { id: "rBRy5r", label: "Beresti - Jud. SV" },
  { id: "ZQwv4m", label: "Beresti - Jud. VL" },
  { id: "r824Vl", label: "Beresti Bistrita - Jud. BC" },
  { id: "r8YNDZ", label: "Beresti Meria - Jud. GL" },
  { id: "l51Opl", label: "Beresti Tazlau - Jud. BC" },
  { id: "WZPvjm", label: "Berevoesti - Jud. AG" },
  { id: "lWevdr", label: "Berevoesti - Jud. DB" },
  { id: "rqOYol", label: "Berezeni - Jud. VS" },
  { id: "r2QNgm", label: "Berezlogi - Jud. IS" },
  { id: "ZPadBl", label: "Berghia - Jud. MS" },
  { id: "Oro68m", label: "Berghin - Jud. AB" },
  { id: "lYGyVr", label: "Beria De Jos - Jud. OT" },
  { id: "mAGbaZ", label: "Beria De Sus - Jud. OT" },
  { id: "rKDQkZ", label: "Berindan - Jud. SM" },
  { id: "l7waLl", label: "Berindei - Jud. OT" },
  { id: "XlxNpr", label: "Berindesti - Jud. AG" },
  { id: "krw3xr", label: "Berindia - Jud. AR" },
  { id: "lLapnm", label: "Berindu - Jud. CJ" },
  { id: "leJeJm", label: "Berini - Jud. TM" },
  { id: "lD6PYr", label: "Berinta - Jud. MM" },
  { id: "r9bjqZ", label: "Berislavesti - Jud. VL" },
  { id: "mkaWQZ", label: "Beriu - Jud. HD" },
  { id: "ZRjLNZ", label: "Berivoi - Jud. BV" },
  { id: "r82jEl", label: "Berlesti - Jud. BR" },
  { id: "mbOxYr", label: "Berlesti - Jud. GJ" },
  { id: "ZRJV2Z", label: "Berliste - Jud. CS" },
  { id: "rBjeVr", label: "Bernadea - Jud. MS" },
  { id: "r3yxAl", label: "Bertea - Jud. PH" },
  { id: "Zy22ol", label: "Bertestii De Jos - Jud. BR" },
  { id: "mgAjAZ", label: "Bertestii De Sus - Jud. BR" },
  { id: "ZQaAJZ", label: "Berveni - Jud. SM" },
  { id: "ZQQ1nZ", label: "Berza - Jud. BT" },
  { id: "lOWG3l", label: "Berzasca - Jud. CS" },
  { id: "rod5NZ", label: "Berzovia - Jud. CS" },
  { id: "l0pkPl", label: "Berzunti - Jud. BC" },
  { id: "m1nX3r", label: "Beslii - Jud. BZ" },
  { id: "m1bgjr", label: "Bestepe - Jud. TL" },
  { id: "Nmk5nZ", label: "Beta - Jud. AB" },
  { id: "lD2x2r", label: "Beta - Jud. HR" },
  { id: "rBbnQr", label: "Betesti - Jud. HR" },
  { id: "ZMV34l", label: "Betesti - Jud. NT" },
  { id: "r2V51r", label: "Betfia - Jud. BH" },
  { id: "ZvzL5Z", label: "Bethausen - Jud. TM" },
  { id: "mkEPqZ", label: "Beu - Jud. MS" },
  { id: "mgoo7m", label: "Beuca - Jud. TR" },
  { id: "ma132m", label: "Beudiu - Jud. BN" },
  { id: "ZPBJDm", label: "Bezdead - Jud. DB" },
  { id: "ZR3DDl", label: "Bezded - Jud. SJ" },
  { id: "rdx0Gr", label: "Bezid - Jud. MS" },
  { id: "roEPNl", label: "Bezidu Nou - Jud. MS" },
  { id: "ZP28Am", label: "Beznea - Jud. BH" },
  { id: "ZMVM4l", label: "Biban - Jud. MH" },
  { id: "mnQxBl", label: "Bibesti - Jud. GJ" },
  { id: "rXdaRr", label: "Bibiresti - Jud. BC" },
  { id: "lLDDYl", label: "Biborteni - Jud. CV" },
  { id: "r6qd2m", label: "Bibulesti - Jud. GJ" },
  { id: "ZyY1YZ", label: "Bic - Jud. SJ" },
  { id: "l5469Z", label: "Bica - Jud. CJ" },
  { id: "lp16pm", label: "Bicacel - Jud. BH" },
  { id: "rKjWWm", label: "Bicaci - Jud. BH" },
  { id: "r3k1nr", label: "Bicalatu - Jud. CJ" },
  { id: "mkEWzZ", label: "Bicasu - Jud. MS" },
  { id: "lxKnzZ", label: "Bicau - Jud. SM" },
  { id: "mkEzQZ", label: "Bicaz - Jud. MM" },
  { id: "lYnEOm", label: "Bicaz - Jud. NT" },
  { id: "r9yG8r", label: "Bicaz Chei - Jud. NT" },
  { id: "mgaOkZ", label: "Bicazu Ardelean - Jud. NT" },
  { id: "mGg36m", label: "Bicestii De Jos - Jud. VN" },
  { id: "rwYzXl", label: "Bicestii De Sus - Jud. VN" },
  { id: "maoxEZ", label: "Bicfalau - Jud. CV" },
  { id: "ljoybm", label: "Bichesti - Jud. VN" },
  { id: "r30o5Z", label: "Bichigi - Jud. TM" },
  { id: "lYDwAZ", label: "Bichigiu - Jud. BN" },
  { id: "lWRqqZ", label: "Bichis - Jud. MS" },
  { id: "wlpAem", label: "Bidigesti - Jud. AB" },
  { id: "mgPWqr", label: "Bidiu - Jud. BN" },
  { id: "mkzVbl", label: "Biertan - Jud. SB" },
  { id: "Zy2pkl", label: "Bigar - Jud. CS" },
  { id: "l7yKAr", label: "Bighilin - Jud. PH" },
  { id: "krdK2m", label: "Biharia - Jud. AB" },
  { id: "lYDYQZ", label: "Biharia - Jud. BH" },
  { id: "yZR5Kr", label: "Biia - Jud. AB" },
  { id: "ElLNbr", label: "Bijghir - Jud. BC" },
  { id: "lO6OGZ", label: "Bila - Jud. GR" },
  { id: "LZMMQZ", label: "Bilanesti - Jud. AB" },
  { id: "m1qRgm", label: "Bilbor - Jud. HR" },
  { id: "r30BVZ", label: "Bilca - Jud. SV" },
  { id: "LZMpQr", label: "Bilcesti - Jud. AG" },
  { id: "r6qWWm", label: "Bilciuresti - Jud. DB" },
  { id: "lj8K4r", label: "Biled - Jud. TM" },
  { id: "r3yz5l", label: "Bilghez - Jud. SJ" },
  { id: "maN7Nr", label: "Biliesti - Jud. VN" },
  { id: "lJWD9m", label: "Binis - Jud. CS" },
  { id: "mbxBvZ", label: "Bira - Jud. NT" },
  { id: "wmgW9m", label: "Birchis - Jud. AR" },
  { id: "leoyYr", label: "Bircii - Jud. GJ" },
  { id: "r2wD1Z", label: "Bircii - Jud. OT" },
  { id: "m1bJ9r", label: "Birda - Jud. TM" },
  { id: "mk8eor", label: "Birtin - Jud. BH" },
  { id: "mnQ8Xl", label: "Birtin - Jud. HD" },
  { id: "lDPD2l", label: "Biruinta - Jud. CT" },
  { id: "WZPaAl", label: "Bisericani - Jud. AB" },
  { id: "lYKvRr", label: "Bisericani - Jud. HR" },
  { id: "Z4DzJZ", label: "Bisericani - Jud. NT" },
  { id: "lx8e7l", label: "Bisoca - Jud. BZ" },
  { id: "Bma9em", label: "Bistra - Jud. AB" },
  { id: "m1kxgZ", label: "Bistra - Jud. BH" },
  { id: "mnEzql", label: "Bistra - Jud. MM" },
  { id: "roEA8l", label: "Bistra Muresului - Jud. MS" },
  { id: "mG1npZ", label: "Bistret - Jud. DJ" },
  { id: "Zv5d5r", label: "Bistretu - Jud. MH" },
  { id: "Zzg4Nl", label: "Bistretu Nou - Jud. DJ" },
  { id: "lJ2GAl", label: "Bistricioara - Jud. NT" },
  { id: "lLegbm", label: "Bistrita - Jud. BN" },
  { id: "l07RRZ", label: "Bistrita - Jud. MH" },
  { id: "lxAXom", label: "Bistrita - Jud. NT" },
  { id: "r89j0Z", label: "Bistrita - Jud. VL" },
  { id: "mk8MPr", label: "Bistrita Bargaului - Jud. BN" },
  { id: "lD68Gr", label: "Bistrita Noua - Jud. OT" },
  { id: "rXy77Z", label: "Bita - Jud. CV" },
  { id: "mnQMBl", label: "Bitina Pamanteni - Jud. IL" },
  { id: "lD2jWr", label: "Bitina Ungureni - Jud. IL" },
  { id: "leGY6r", label: "Biusa - Jud. SJ" },
  { id: "ZVK7Wl", label: "Bivolari - Jud. BT" },
  { id: "rdoDRm", label: "Bivolari - Jud. IS" },
  { id: "rER5MZ", label: "Bivolaria - Jud. SV" },
  { id: "mG1xdZ", label: "Bixad - Jud. CV" },
  { id: "r3yKVl", label: "Bixad - Jud. SM" },
  { id: "rK65xm", label: "Bizighesti - Jud. VN" },
  { id: "m1BQdr", label: "Bizusa Bai - Jud. SJ" },
  { id: "2l50RZ", label: "Blaga - Jud. BC" },
  { id: "r3Q8ql", label: "Blaga - Jud. IS" },
  { id: "rdjNzl", label: "Blagesti - Jud. BC" },
  { id: "rXq4DZ", label: "Blagesti - Jud. IS" },
  { id: "ZMJLQl", label: "Blagesti - Jud. VS" },
  { id: "l52EEm", label: "Blahnita De Jos - Jud. GJ" },
  { id: "ZM97GZ", label: "Blahnita De Sus - Jud. GJ" },
  { id: "2l5NmQ", label: "Blaj - Jud. AB" },
  { id: "ma5x2m", label: "Blaj - Jud. OT" },
  { id: "r6yD7m", label: "Blaja - Jud. SM" },
  { id: "rE7v6l", label: "Blajani - Jud. BZ" },
  { id: "lDqVWm", label: "Blajel - Jud. SB" },
  { id: "mnQWql", label: "Blajeni - Jud. HD" },
  { id: "lL1ybm", label: "Blajeni Vulcan - Jud. HD" },
  { id: "lxx3Dl", label: "Blajenii De Jos - Jud. BN" },
  { id: "rEQkqr", label: "Blajenii De Sus - Jud. BN" },
  { id: "ZyWODm", label: "Blajova - Jud. TM" },
  { id: "LZM3Qm", label: "Blaju - Jud. AG" },
  { id: "lea4Yl", label: "Blandesti - Jud. BT" },
  { id: "AZv69Z", label: "Blandiana - Jud. AB" },
  { id: "mA8G8l", label: "Blanoiu - Jud. VL" },
  { id: "ronDNm", label: "Blanzi - Jud. GL" },
  { id: "lj3Xdm", label: "Blebea - Jud. NT" },
  { id: "krwR3m", label: "Blejani - Jud. AG" },
  { id: "rK6xPm", label: "Blejani - Jud. VL" },
  { id: "ZyW78m", label: "Blejesti - Jud. TR" },
  { id: "lL7zXZ", label: "Blejoi - Jud. PH" },
  { id: "ZyWAYm", label: "Blesca - Jud. VS" },
  { id: "l08zQl", label: "Blidaresti - Jud. CJ" },
  { id: "VleD6Z", label: "Blidari - Jud. BC" },
  { id: "ZyNwYl", label: "Blidari - Jud. DB" },
  { id: "lJPaWr", label: "Blidari - Jud. GJ" },
  { id: "max1Jl", label: "Blidari - Jud. MM" },
  { id: "ZQwQnm", label: "Blidari - Jud. VL" },
  { id: "rB5GVm", label: "Blidari - Jud. VN" },
  { id: "m1GRjZ", label: "Blidaru - Jud. MH" },
  { id: "AZvdPm", label: "Blidesti - Jud. AB" },
  { id: "m1GgdZ", label: "Blidireasa - Jud. MS" },
  { id: "rwngxm", label: "Boanta - Jud. BC" },
  { id: "ZzoEWl", label: "Boarca - Jud. BR" },
  { id: "m1BPYr", label: "Boarta - Jud. SB" },
  { id: "ZQG6dm", label: "Boatca - Jud. IS" },
  { id: "rwy5Kl", label: "Bobaia - Jud. GJ" },
  { id: "lWO9Nl", label: "Bobaia - Jud. HD" },
  { id: "ZPaNMl", label: "Bobaita - Jud. MH" },
  { id: "rE7n6l", label: "Bobalna - Jud. CJ" },
  { id: "l7J33Z", label: "Bobalna - Jud. HD" },
  { id: "Xl0VNr", label: "Bobaresti - Jud. AB" },
  { id: "rXakgZ", label: "Bobda - Jud. TM" },
  { id: "lDP0Wl", label: "Bobeanu - Jud. DJ" },
  { id: "Zvz23Z", label: "Bobeica - Jud. SV" },
  { id: "rBRXVr", label: "Bobesti - Jud. VS" },
  { id: "rqppgm", label: "Bobicesti - Jud. OT" },
  { id: "rwEjQl", label: "Bobicesti - Jud. PH" },
  { id: "rBWRgZ", label: "Boboc - Jud. BZ" },
  { id: "rXy34Z", label: "Boboci - Jud. DB" },
  { id: "robgwm", label: "Boboci - Jud. PH" },
  { id: "r8BnEl", label: "Bobohalma - Jud. MS" },
  { id: "mG1VwZ", label: "Boboiesti - Jud. GJ" },
  { id: "ZQV0dl", label: "Boboiesti - Jud. NT" },
  { id: "rERv6Z", label: "Bobolia - Jud. PH" },
  { id: "Xl0yQm", label: "Bobos - Jud. BC" },
  { id: "rqp1om", label: "Bobota - Jud. SJ" },
  { id: "m1qDYm", label: "Bobu - Jud. GJ" },
  { id: "robd8m", label: "Bobu - Jud. OT" },
  { id: "ZVK4Kl", label: "Bobulesti - Jud. BT" },
  { id: "mgLAGr", label: "Boca - Jud. GJ" },
  { id: "l07VgZ", label: "Boceni - Jud. MH" },
  { id: "Nmkbwl", label: "Bocesti - Jud. AB" },
  { id: "wmg9qZ", label: "Bochia - Jud. AR" },
  { id: "rKDX0Z", label: "Bocicau - Jud. SM" },
  { id: "lJ2PWl", label: "Bocicoel - Jud. MM" },
  { id: "l7w9vl", label: "Bocicoiu Mare - Jud. MM" },
  { id: "rodJqZ", label: "Bociu - Jud. CJ" },
  { id: "m1qAam", label: "Bocnita - Jud. IS" },
  { id: "rd505Z", label: "Bocsa - Jud. CS" },
  { id: "ZQaOoZ", label: "Bocsa - Jud. SJ" },
  { id: "lL6DWr", label: "Bocsa - Jud. VL" },
  { id: "mA40gl", label: "Bocsa Mare - Jud. HD" },
  { id: "ZNAzVr", label: "Bocsa Mica - Jud. HD" },
  { id: "EZzxnm", label: "Bocsig - Jud. AR" },
  { id: "rX7Q9m", label: "Bocsita - Jud. SJ" },
  { id: "yZRjeZ", label: "Bocsitura - Jud. AB" },
  { id: "ma1MEm", label: "Bod - Jud. BV" },
  { id: "l0XGBl", label: "Bodaiesti - Jud. DJ" },
  { id: "ZRxVjm", label: "Bodaiestii De Sus - Jud. DJ" },
  { id: "yZR4Dm", label: "Bodeasa - Jud. BC" },
  { id: "ZQQLpZ", label: "Bodeasa - Jud. BT" },
  { id: "5l7jLZ", label: "Bodesti - Jud. AB" },
  { id: "yZy38m", label: "Bodesti - Jud. AR" },
  { id: "lD24Gr", label: "Bodesti - Jud. IS" },
  { id: "lLVRYr", label: "Bodesti - Jud. NT" },
  { id: "rqpjvm", label: "Bodesti - Jud. PH" },
  { id: "r30X0Z", label: "Bodesti - Jud. VL" },
  { id: "Z4NApm", label: "Bodesti - Jud. VN" },
  { id: "l57Dpr", label: "Bodestii De Jos - Jud. NT" },
  { id: "r3yOGl", label: "Bodia - Jud. SJ" },
  { id: "m1noYr", label: "Bodinesti - Jud. BZ" },
  { id: "mkzA2l", label: "Bodnareni - Jud. SV" },
  { id: "ZNJ9YZ", label: "Bodo - Jud. TM" },
  { id: "ZyNYol", label: "Bodoc - Jud. CV" },
  { id: "lpbQor", label: "Bodogaia - Jud. HR" },
  { id: "ZzggYl", label: "Bodos - Jud. CV" },
  { id: "olWDom", label: "Bodresti - Jud. AB" },
  { id: "ZPe43l", label: "Bodrog - Jud. CJ" },
  { id: "3ZVXKm", label: "Bodrogu Nou - Jud. AR" },
  { id: "arB4dl", label: "Bodrogu Vechi - Jud. AR" },
  { id: "Zy2O2l", label: "Bogaltin - Jud. CS" },
  { id: "QmbaBl", label: "Bogata - Jud. BC" },
  { id: "mGw8Dm", label: "Bogata - Jud. CJ" },
  { id: "rBW1VZ", label: "Bogata - Jud. CL" },
  { id: "mkEvBZ", label: "Bogata - Jud. MS" },
  { id: "l7yqYr", label: "Bogata - Jud. SV" },
  { id: "r3k4Yr", label: "Bogata De Jos - Jud. CJ" },
  { id: "m1nzYr", label: "Bogata De Sus - Jud. CJ" },
  { id: "rdjDAl", label: "Bogata Olteana - Jud. BV" },
  { id: "Rr9Gdl", label: "Bogati - Jud. AG" },
  { id: "lxKVoZ", label: "Bogatu Roman - Jud. SB" },
  { id: "r6xw5l", label: "Bogda - Jud. TM" },
  { id: "r8260l", label: "Bogdan Voda - Jud. BC" },
  { id: "mGPawr", label: "Bogdan Voda - Jud. MM" },
  { id: "lOwYKl", label: "Bogdana - Jud. BC" },
  { id: "mgA57Z", label: "Bogdana - Jud. CL" },
  { id: "m1B3qr", label: "Bogdana - Jud. SJ" },
  { id: "rKx8EZ", label: "Bogdana - Jud. TR" },
  { id: "lWvqkZ", label: "Bogdana - Jud. VS" },
  { id: "mGayDZ", label: "Bogdana Voloseni - Jud. VS" },
  { id: "m1BOar", label: "Bogdand - Jud. SM" },
  { id: "yZRGKl", label: "Bogdanesti - Jud. AB" },
  { id: "ropYwZ", label: "Bogdanesti - Jud. BC" },
  { id: "r33VYr", label: "Bogdanesti - Jud. BT" },
  { id: "mgLkkr", label: "Bogdanesti - Jud. IS" },
  { id: "rqpg0m", label: "Bogdanesti - Jud. PH" },
  { id: "m1bGar", label: "Bogdanesti - Jud. SV" },
  { id: "lpxwpl", label: "Bogdanesti - Jud. VL" },
  { id: "r3095Z", label: "Bogdanesti - Jud. VS" },
  { id: "m1bP3r", label: "Bogdanita - Jud. VS" },
  { id: "ZyN4Dl", label: "Bogea - Jud. DJ" },
  { id: "ZVK0xl", label: "Bogei - Jud. BH" },
  { id: "Z41Ezl", label: "Bogesti - Jud. VS" },
  { id: "ZvOpDl", label: "Boghesti - Jud. VN" },
  { id: "ZPG8jr", label: "Boghestii De Sus - Jud. VN" },
  { id: "roEjwl", label: "Boghicea - Jud. NT" },
  { id: "rBnadr", label: "Boghis - Jud. SJ" },
  { id: "ZQaggZ", label: "Boghis - Jud. SM" },
  { id: "Nmkz2l", label: "Boglesti - Jud. AB" },
  { id: "lW1gBr", label: "Bogodint - Jud. CS" },
  { id: "rqEqBr", label: "Bogonos - Jud. IS" },
  { id: "lxzRPr", label: "Bogza - Jud. VN" },
  { id: "ZVdpdm", label: "Bogzesti - Jud. NT" },
  { id: "ZMYL5Z", label: "Bohoghina - Jud. BT" },
  { id: "ZP2vMm", label: "Boholt - Jud. BV" },
  { id: "r3QD4l", label: "Boholt - Jud. HD" },
  { id: "lYKDwr", label: "Bohorel - Jud. GJ" },
  { id: "lj5OqZ", label: "Bohotin - Jud. IS" },
  { id: "lez23m", label: "Boia - Jud. GJ" },
  { id: "rwDeyZ", label: "Boia Barzii - Jud. HD" },
  { id: "r978yl", label: "Boian - Jud. CJ" },
  { id: "m1BK9r", label: "Boian - Jud. SB" },
  { id: "mAAN6m", label: "Boianu Mare - Jud. BH" },
  { id: "ZNYy1r", label: "Boiereni - Jud. MM" },
  { id: "m1nYjr", label: "Boina - Jud. CS" },
  { id: "mAGNKZ", label: "Boinesti - Jud. SM" },
  { id: "lOWqwl", label: "Boinita - Jud. CS" },
  { id: "mgJEAl", label: "Boisoara - Jud. VL" },
  { id: "wlpKYZ", label: "Boistea - Jud. BC" },
  { id: "Zy6akl", label: "Boistea - Jud. NT" },
  { id: "4lO9Gr", label: "Boistea De Jos - Jud. BC" },
  { id: "ZQGeom", label: "Boita - Jud. HD" },
  { id: "rKDJ9Z", label: "Boita - Jud. SB" },
  { id: "r33dAr", label: "Boiu - Jud. BH" },
  { id: "mGxo0r", label: "Boiu - Jud. HD" },
  { id: "r6G8pZ", label: "Boiu - Jud. MS" },
  { id: "mGxe0r", label: "Boiu De Jos - Jud. HD" },
  { id: "rwD3aZ", label: "Boiu De Sus - Jud. HD" },
  { id: "rwPVyZ", label: "Boiu Mare - Jud. MM" },
  { id: "rodQ3Z", label: "Boj Catun - Jud. CJ" },
  { id: "rK3ePr", label: "Bojia - Jud. CS" },
  { id: "l7JNnZ", label: "Bojila - Jud. IS" },
  { id: "r8Yj0Z", label: "Bojinu - Jud. GJ" },
  { id: "mnxW8Z", label: "Bojoiu - Jud. DJ" },
  { id: "maLgJl", label: "Boju - Jud. CJ" },
  { id: "r336Yr", label: "Bolatau - Jud. BC" },
  { id: "roxwXZ", label: "Bolati - Jud. VS" },
  { id: "ZQqbpZ", label: "Bolboasa - Jud. GJ" },
  { id: "rqbVoZ", label: "Bolbocesti - Jud. GJ" },
  { id: "ZzwD8l", label: "Bolbosi - Jud. GJ" },
  { id: "AZvKPl", label: "Bolculesti - Jud. AG" },
  { id: "Zyeo2Z", label: "Bold - Jud. BT" },
  { id: "m1B7Rr", label: "Bolda - Jud. SM" },
  { id: "krwyjl", label: "Boldesti - Jud. AB" },
  { id: "mA4EKl", label: "Boldesti - Jud. IS" },
  { id: "lL7qGZ", label: "Boldesti - Jud. PH" },
  { id: "ZMG6jm", label: "Boldesti Scaeni - Jud. PH" },
  { id: "ZMRnGl", label: "Boldu - Jud. BZ" },
  { id: "r99QMr", label: "Boldur - Jud. TM" },
  { id: "mgAbBZ", label: "Boldut - Jud. CJ" },
  { id: "rwyRxl", label: "Bolintin Deal - Jud. GR" },
  { id: "ZNGKxl", label: "Bolintin Vale - Jud. GR" },
  { id: "lLV86r", label: "Bolintineni - Jud. MS" },
  { id: "mgAzDZ", label: "Bologa - Jud. CJ" },
  { id: "rE6KqZ", label: "Bologaia - Jud. MS" },
  { id: "r36Y5m", label: "Bolotesti - Jud. VN" },
  { id: "Xl0wBZ", label: "Bolovanesti - Jud. AB" },
  { id: "AZQOdm", label: "Bolovanesti - Jud. AG" },
  { id: "mnx7AZ", label: "Bolovani - Jud. DB" },
  { id: "l51BRl", label: "Bolovanis - Jud. BC" },
  { id: "maLqEl", label: "Bolvasnita - Jud. CS" },
  { id: "Lmnqln", label: "Boncesti - Jud. AB" },
  { id: "lLJAGr", label: "Bonciu - Jud. VL" },
  { id: "ZVyDWr", label: "Bondoci - Jud. VL" },
  { id: "roEyNl", label: "Bondrea - Jud. OT" },
  { id: "ZQvJol", label: "Bont - Jud. CJ" },
  { id: "Zy6YDl", label: "Bontaieni - Jud. MM" },
  { id: "grK7er", label: "Bontesti - Jud. AR" },
  { id: "rK680m", label: "Bontesti - Jud. VN" },
  { id: "ZMRNGl", label: "Bontida - Jud. CJ" },
  { id: "rqb9vZ", label: "Booveni - Jud. DJ" },
  { id: "mnQ0ql", label: "Bora - Jud. IL" },
  { id: "majVDr", label: "Boranesti - Jud. IL" },
  { id: "rXqODZ", label: "Borascu - Jud. GJ" },
  { id: "ZzD6Ym", label: "Borca - Jud. NT" },
  { id: "l71Vqr", label: "Borcea - Jud. CL" },
  { id: "r8B2Xl", label: "Borcut - Jud. MM" },
  { id: "r2wRbZ", label: "Bord - Jud. MS" },
  { id: "ZPapMl", label: "Bordea - Jud. NT" },
  { id: "mgJ0ql", label: "Bordeasca Noua - Jud. VN" },
  { id: "mbN0Bm", label: "Bordeasca Veche - Jud. VN" },
  { id: "l711qr", label: "Bordei Verde - Jud. BR" },
  { id: "7rqXdm", label: "Bordeieni - Jud. AG" },
  { id: "mnz98m", label: "Bordenii Mari - Jud. PH" },
  { id: "lDqGXm", label: "Bordenii Mici - Jud. PH" },
  { id: "m18V3l", label: "Bordesti - Jud. VN" },
  { id: "lWMxdr", label: "Bordestii De Jos - Jud. VN" },
  { id: "krdd7r", label: "Bordestii Poieni - Jud. AB" },
  { id: "mnEYAl", label: "Bordosiu - Jud. MS" },
  { id: "lOWxKl", label: "Bordusani - Jud. BZ" },
  { id: "lez51m", label: "Bordusani - Jud. IL" },
  { id: "rE2k6Z", label: "Borduselu - Jud. IL" },
  { id: "lDqk1m", label: "Borla - Jud. SJ" },
  { id: "l7vo3Z", label: "Borleasa - Jud. BN" },
  { id: "XlDAbm", label: "Borlesti - Jud. AB" },
  { id: "Jr3gAr", label: "Borlesti - Jud. AG" },
  { id: "rXOzpm", label: "Borlesti - Jud. NT" },
  { id: "rERXVZ", label: "Borlesti - Jud. SM" },
  { id: "ZQv2Dl", label: "Borlova - Jud. CS" },
  { id: "ljNvwl", label: "Borlovenii Noi - Jud. CS" },
  { id: "ZPeoEl", label: "Borlovenii Vechi - Jud. CS" },
  { id: "mkEnbZ", label: "Bornis - Jud. NT" },
  { id: "r2qDvm", label: "Boroaia - Jud. SV" },
  { id: "nljxqr", label: "Borobanesti - Jud. AG" },
  { id: "ZNyeyr", label: "Borod - Jud. BH" },
  { id: "mbKb2r", label: "Borodesti - Jud. VS" },
  { id: "lLVN1r", label: "Boroesti - Jud. OT" },
  { id: "lj3yLm", label: "Borogea - Jud. MH" },
  { id: "m1ky8Z", label: "Borolea - Jud. BT" },
  { id: "l7JdnZ", label: "Borosesti - Jud. IS" },
  { id: "r2bbWr", label: "Borosesti - Jud. VL" },
  { id: "rKPD0Z", label: "Borosneu Mare - Jud. CV" },
  { id: "r3GQqr", label: "Borosneu Mic - Jud. CV" },
  { id: "mkayqZ", label: "Borosoaia - Jud. IS" },
  { id: "lL1aPm", label: "Borosteni - Jud. GJ" },
  { id: "QmbDoZ", label: "Borovinesti - Jud. AG" },
  { id: "ZNyd6r", label: "Borozel - Jud. BH" },
  { id: "r821Dl", label: "Bors - Jud. BH" },
  { id: "ZMYzQZ", label: "Borsa - Jud. BH" },
  { id: "leL7JZ", label: "Borsa - Jud. CJ" },
  { id: "rBbQdr", label: "Borsa - Jud. IS" },
  { id: "rE6QqZ", label: "Borsa - Jud. MM" },
  { id: "ZzoXbl", label: "Borsa Catun - Jud. CJ" },
  { id: "rXLE9r", label: "Borsa Crestaia - Jud. CJ" },
  { id: "yZyP4r", label: "Borsani - Jud. BC" },
  { id: "mGxWpr", label: "Borsec - Jud. HR" },
  { id: "ZzDY3m", label: "Borseni - Jud. NT" },
  { id: "r2YnQm", label: "Borugi - Jud. CS" },
  { id: "lLe01m", label: "Borumlaca - Jud. BH" },
  { id: "l7venZ", label: "Borz - Jud. BH" },
  { id: "r9DYNm", label: "Borza - Jud. SJ" },
  { id: "5l74qm", label: "Borzesti - Jud. BC" },
  { id: "lLeYWm", label: "Borzesti - Jud. BT" },
  { id: "maLa7l", label: "Borzesti - Jud. CJ" },
  { id: "rBj1gr", label: "Borzia - Jud. MS" },
  { id: "lL16Ym", label: "Borzont - Jud. HR" },
  { id: "r8OMDm", label: "Bos - Jud. HD" },
  { id: "ZNw66l", label: "Bosanci - Jud. SV" },
  { id: "ZP2Pkm", label: "Boscoteni - Jud. BT" },
  { id: "rwnYym", label: "Bosia - Jud. BC" },
  { id: "rE2JoZ", label: "Bosia - Jud. IS" },
  { id: "l7ynYr", label: "Bosilcesti - Jud. PH" },
  { id: "maLVNl", label: "Bosneagu - Jud. CL" },
  { id: "lD2wbr", label: "Bosorod - Jud. HD" },
  { id: "AZQdvr", label: "Bosoteni - Jud. BC" },
  { id: "krd64l", label: "Bostanesti - Jud. BC" },
  { id: "Z43Ojr", label: "Bosteni - Jud. IS" },
  { id: "mk8bbr", label: "Bota - Jud. BC" },
  { id: "9m1WgZ", label: "Botani - Jud. AB" },
  { id: "grKN9m", label: "Botarcani - Jud. AG" },
  { id: "lWMayr", label: "Botarlau - Jud. VN" },
  { id: "ZzEjYr", label: "Botean - Jud. BH" },
  { id: "lYn3xm", label: "Botei - Jud. MS" },
  { id: "wmgO7m", label: "Boteni - Jud. AG" },
  { id: "mGwyBm", label: "Boteni - Jud. CJ" },
  { id: "lj9nKZ", label: "Boteni - Jud. DB" },
  { id: "r92JMZ", label: "Boteni - Jud. IL" },
  { id: "Bma7EZ", label: "Botesti - Jud. AB" },
  { id: "QmbBdm", label: "Botesti - Jud. AG" },
  { id: "Z4Y1Jl", label: "Botesti - Jud. BC" },
  { id: "Z4DxxZ", label: "Botesti - Jud. NT" },
  { id: "rKxjWZ", label: "Botesti - Jud. SV" },
  { id: "mgod9m", label: "Botesti - Jud. TM" },
  { id: "lOYpLr", label: "Botesti - Jud. VS" },
  { id: "mnENal", label: "Botez - Jud. MS" },
  { id: "nljvNl", label: "Botfei - Jud. AR" },
  { id: "mbKdqr", label: "Botinesti - Jud. TM" },
  { id: "lOBJOZ", label: "Botiz - Jud. SM" },
  { id: "rBjRbr", label: "Botiza - Jud. MM" },
  { id: "m1bQar", label: "Botoaia - Jud. VS" },
  { id: "l59bEl", label: "Botoi - Jud. VS" },
  { id: "ZzPg8m", label: "Botorani - Jud. VL" },
  { id: "l57aYr", label: "Botorca - Jud. MS" },
  { id: "r2Md9m", label: "Botoroaga - Jud. TR" },
  { id: "lxbvor", label: "Botorogi - Jud. GJ" },
  { id: "lDqOnm", label: "Botos - Jud. SV" },
  { id: "lOYAOr", label: "Botosana - Jud. SV" },
  { id: "lLeLGm", label: "Botosani - Jud. BT" },
  { id: "l7yMYr", label: "Botosanita Mare - Jud. SV" },
  { id: "rBRdQr", label: "Botosanita Mica - Jud. SV" },
  { id: "rBO1QZ", label: "Botosesti Paia - Jud. DJ" },
  { id: "Z41v0l", label: "Botus - Jud. SV" },
  { id: "lxEPMm", label: "Botusel - Jud. SV" },
  { id: "l0KBRZ", label: "Boura - Jud. SV" },
  { id: "r3Gx0r", label: "Boureni - Jud. DJ" },
  { id: "rXqP7Z", label: "Boureni - Jud. IS" },
  { id: "leJBom", label: "Bousori - Jud. VS" },
  { id: "XlDqnm", label: "Boz - Jud. AB" },
  { id: "ZQGBvm", label: "Boz - Jud. HD" },
  { id: "rK2jEm", label: "Bozanta Mare - Jud. MM" },
  { id: "r2wwdZ", label: "Bozanta Mica - Jud. MM" },
  { id: "lOAoAr", label: "Bozed - Jud. MS" },
  { id: "Z4DBzZ", label: "Bozeni - Jud. MS" },
  { id: "rwDwXZ", label: "Bozes - Jud. HD" },
  { id: "ZNJvyZ", label: "Bozia - Jud. VS" },
  { id: "r33P4r", label: "Bozieni - Jud. BT" },
  { id: "lxAj0m", label: "Bozieni - Jud. NT" },
  { id: "rd7Bqm", label: "Bozieni - Jud. PH" },
  { id: "mGP7pr", label: "Bozienii De Sus - Jud. NT" },
  { id: "r6b1gr", label: "Bozies - Jud. BN" },
  { id: "rqpMPm", label: "Bozies - Jud. SJ" },
  { id: "leLdJZ", label: "Bozioru - Jud. BZ" },
  { id: "ZR39Ll", label: "Bozna - Jud. SJ" },
  { id: "ZvAePr", label: "Bozovici - Jud. CS" },
  { id: "JlJkAm", label: "Brabeti - Jud. AG" },
  { id: "ZPBQvm", label: "Brabeti - Jud. DJ" },
  { id: "rqbwdZ", label: "Brabova - Jud. DJ" },
  { id: "ZP2BBm", label: "Brad - Jud. BC" },
  { id: "rKnK0m", label: "Brad - Jud. HD" },
  { id: "r9bANZ", label: "Bradacesti - Jud. VN" },
  { id: "mG130Z", label: "Bradatel - Jud. DB" },
  { id: "lWOzRl", label: "Bradatel - Jud. HD" },
  { id: "r2MVWm", label: "Bradatel - Jud. SV" },
  { id: "wlpgoZ", label: "Bradeana - Jud. AB" },
  { id: "rK3Pxr", label: "Bradeanca - Jud. BR" },
  { id: "rwp0QZ", label: "Bradeanca - Jud. BZ" },
  { id: "lpzLxr", label: "Bradeanu - Jud. BZ" },
  { id: "mGWA9m", label: "Bradeni - Jud. SB" },
  { id: "4lOdJm", label: "Bradesti - Jud. AB" },
  { id: "r3GMAr", label: "Bradesti - Jud. DJ" },
  { id: "rwDb3Z", label: "Bradesti - Jud. HR" },
  { id: "rqOQol", label: "Bradesti - Jud. VS" },
  { id: "lO6PKZ", label: "Bradestii Batrani - Jud. DJ" },
  { id: "Xl08Pl", label: "Bradet - Jud. AB" },
  { id: "r33Rzr", label: "Bradet - Jud. BH" },
  { id: "lpEbpm", label: "Bradet - Jud. CV" },
  { id: "mGx59r", label: "Bradet - Jud. GJ" },
  { id: "rqpLGm", label: "Bradet - Jud. PH" },
  { id: "rwDnjZ", label: "Bradetel - Jud. GJ" },
  { id: "lOAXGr", label: "Bradetelu - Jud. MS" },
  { id: "7rqMvr", label: "Bradetu - Jud. AG" },
  { id: "Z4Njzm", label: "Bradetu - Jud. VN" },
  { id: "ZzwoEl", label: "Bradiceni - Jud. GJ" },
  { id: "lOD3Nm", label: "Bradicesti - Jud. IS" },
  { id: "ZVyPWr", label: "Bradisor - Jud. VL" },
  { id: "r97XMl", label: "Bradisoru De Jos - Jud. CS" },
  { id: "qZNoyl", label: "Bradu - Jud. AG" },
  { id: "Z4DJjZ", label: "Bradu - Jud. NT" },
  { id: "ZQaJpZ", label: "Bradu - Jud. SB" },
  { id: "ZNDwam", label: "Bradu Clocotici - Jud. VL" },
  { id: "ElLRyr", label: "Bradulet - Jud. AG" },
  { id: "ZVL3xm", label: "Bradut - Jud. CV" },
  { id: "rEQxMr", label: "Braesti - Jud. BT" },
  { id: "lW13Ar", label: "Braesti - Jud. BZ" },
  { id: "rozK8m", label: "Braesti - Jud. IS" },
  { id: "rBb3dr", label: "Bragadiru - Jud. IF" },
  { id: "ZVyMJr", label: "Bragadiru - Jud. TR" },
  { id: "leLq3Z", label: "Bragareasa - Jud. BZ" },
  { id: "rXO2Dm", label: "Bragleasa - Jud. MH" },
  { id: "rwEGal", label: "Braglez - Jud. SJ" },
  { id: "ZvgVDl", label: "Brahasesti - Jud. GL" },
  { id: "ZyWa4m", label: "Brahasoaia - Jud. VS" },
  { id: "m1B2jr", label: "Braiesti - Jud. SV" },
  { id: "lYDQEZ", label: "Braila - Jud. BR" },
  { id: "PrEKxl", label: "Braileni - Jud. AG" },
  { id: "l08jJl", label: "Braisoru - Jud. CJ" },
  { id: "m1DNjl", label: "Bralostita - Jud. DJ" },
  { id: "Zv2NPZ", label: "Bran - Jud. BV" },
  { id: "r66Y7r", label: "Bran - Jud. IS" },
  { id: "lJN83l", label: "Branceni - Jud. TR" },
  { id: "lOY1Ar", label: "Brancoveanca - Jud. TR" },
  { id: "lj9ykZ", label: "Brancoveanu - Jud. DB" },
  { id: "lD6LYr", label: "Brancovenesti - Jud. MS" },
  { id: "m1BBdr", label: "Brancoveni - Jud. OT" },
  { id: "rXyeRZ", label: "Brandusa - Jud. DJ" },
  { id: "mao97Z", label: "Branesti - Jud. DB" },
  { id: "Z43Djr", label: "Branesti - Jud. GJ" },
  { id: "ZVL7xm", label: "Branesti - Jud. GL" },
  { id: "r3Bpnm", label: "Branesti - Jud. IF" },
  { id: "m1bo3r", label: "Branesti - Jud. TM" },
  { id: "lexDPZ", label: "Branet - Jud. OT" },
  { id: "l7JYvZ", label: "Branisca - Jud. HD" },
  { id: "r3GVGr", label: "Branistari - Jud. GR" },
  { id: "r9ngMZ", label: "Braniste - Jud. DJ" },
  { id: "arBz2r", label: "Branistea - Jud. AG" },
  { id: "r33qGr", label: "Branistea - Jud. BN" },
  { id: "Zvg6Jl", label: "Branistea - Jud. DB" },
  { id: "maovNZ", label: "Branistea - Jud. GL" },
  { id: "Z4qP8l", label: "Branistea - Jud. GR" },
  { id: "rK2VQm", label: "Branistea - Jud. MH" },
  { id: "rEa9Lr", label: "Branistea - Jud. SV" },
  { id: "JmAj8r", label: "Branzari - Jud. AG" },
  { id: "r3BdAm", label: "Branzeni - Jud. MH" },
  { id: "rwPkQZ", label: "Brasauti - Jud. NT" },
  { id: "l0KpgZ", label: "Brasca - Jud. SV" },
  { id: "rdokqm", label: "Braseu - Jud. HD" },
  { id: "m1kzjZ", label: "Brasov - Jud. BV" },
  { id: "ZQaa5Z", label: "Brastavatu - Jud. OT" },
  { id: "r6yp2m", label: "Bratasanca - Jud. PH" },
  { id: "Z41p8l", label: "Bratasani - Jud. TR" },
  { id: "l51q2l", label: "Bratca - Jud. BH" },
  { id: "r2MxAm", label: "Bratcovu - Jud. TR" },
  { id: "9m1yjm", label: "Brateasca - Jud. AG" },
  { id: "l7yDXr", label: "Brateiu - Jud. SB" },
  { id: "ZQQo5Z", label: "Brateni - Jud. BN" },
  { id: "lJQReZ", label: "Brateni - Jud. BT" },
  { id: "r27qgZ", label: "Brates - Jud. CV" },
  { id: "Z4Dg0Z", label: "Brates - Jud. NT" },
  { id: "qZN07m", label: "Bratesti - Jud. AG" },
  { id: "Lmn5Al", label: "Bratesti - Jud. BC" },
  { id: "mAAM2m", label: "Bratesti - Jud. BH" },
  { id: "rEdyBm", label: "Bratesti - Jud. GJ" },
  { id: "ZNApyr", label: "Bratesti - Jud. IS" },
  { id: "lJ91pZ", label: "Bratesti - Jud. PH" },
  { id: "mGaDDZ", label: "Bratesti - Jud. TR" },
  { id: "lxbeDr", label: "Bratestii De Jos - Jud. DB" },
  { id: "l088Nl", label: "Bratesu Vechi - Jud. BR" },
  { id: "l07kPZ", label: "Bratesul - Jud. MH" },
  { id: "Xl0qBm", label: "Bratia - Jud. AG" },
  { id: "r9b13Z", label: "Bratia Din Deal - Jud. VL" },
  { id: "mgJjGl", label: "Bratia Din Vale - Jud. VL" },
  { id: "ZMYYRZ", label: "Bratila - Jud. BC" },
  { id: "ZzoDbl", label: "Bratilesti - Jud. BZ" },
  { id: "lexjJZ", label: "Bratilovu - Jud. MH" },
  { id: "ZRKByl", label: "Brativoesti - Jud. MH" },
  { id: "lpz0pr", label: "Bratova - Jud. CS" },
  { id: "mbNxQm", label: "Bratovesti - Jud. VL" },
  { id: "lO6kwZ", label: "Bratovoesti - Jud. DJ" },
  { id: "rEdOxm", label: "Bratuia - Jud. GJ" },
  { id: "ZyGVKm", label: "Bratuleni - Jud. IS" },
  { id: "maoYEZ", label: "Bratulesti - Jud. GL" },
  { id: "l0NjNr", label: "Bratulesti - Jud. IS" },
  { id: "PrExmq", label: "Brazesti - Jud. AB" },
  { id: "l7J3AZ", label: "Brazi - Jud. HD" },
  { id: "VrXXgr", label: "Brazii - Jud. AR" },
  { id: "r66PXr", label: "Brazii - Jud. IL" },
  { id: "lxKgnZ", label: "Brazii De Jos - Jud. PH" },
  { id: "rX73gm", label: "Brazii De Sus - Jud. PH" },
  { id: "r8OkXm", label: "Braznic - Jud. HD" },
  { id: "ZyN12l", label: "Breasta - Jud. DJ" },
  { id: "ZVKRol", label: "Breaza - Jud. BN" },
  { id: "ZMYN8Z", label: "Breaza - Jud. BV" },
  { id: "mkwLbm", label: "Breaza - Jud. BZ" },
  { id: "mnE6Xl", label: "Breaza - Jud. MS" },
  { id: "rK2d9m", label: "Breaza - Jud. NT" },
  { id: "l0wbBZ", label: "Breaza - Jud. SV" },
  { id: "lpAx6m", label: "Breaza De Jos - Jud. PH" },
  { id: "lWDMym", label: "Breaza De Sus - Jud. PH" },
  { id: "ZR3ojl", label: "Breaza De Sus - Jud. SV" },
  { id: "rozAqm", label: "Breazova - Jud. HD" },
  { id: "ZPAzkl", label: "Breazova - Jud. TM" },
  { id: "mgLzAr", label: "Breazu - Jud. IS" },
  { id: "rwPPyZ", label: "Breb - Jud. MM" },
  { id: "l0Xbkl", label: "Brebenei - Jud. GJ" },
  { id: "l0XBRl", label: "Brebeni - Jud. CT" },
  { id: "ZPaBDl", label: "Brebeni - Jud. MM" },
  { id: "r3yynl", label: "Brebeni - Jud. OT" },
  { id: "mgBM5l", label: "Brebi - Jud. SJ" },
  { id: "lp5xxm", label: "Brebina - Jud. MH" },
  { id: "ZzV7bZ", label: "Brebina - Jud. TR" },
  { id: "mb9zKl", label: "Brebu - Jud. BZ" },
  { id: "ljNJql", label: "Brebu - Jud. CS" },
  { id: "l0X46l", label: "Brebu - Jud. DB" },
  { id: "lpAvMm", label: "Brebu Manastirei - Jud. PH" },
  { id: "lWDQqm", label: "Brebu Megiesesc - Jud. PH" },
  { id: "ZPeKbl", label: "Brebu Nou - Jud. CS" },
  { id: "lYD6XZ", label: "Brehuiesti - Jud. BT" },
  { id: "mGaMwZ", label: "Brestea - Jud. TM" },
  { id: "mGw0Wm", label: "Brestelnic - Jud. CS" },
  { id: "mgo1Em", label: "Brestovat - Jud. TM" },
  { id: "rBjxar", label: "Breta - Jud. MH" },
  { id: "lJP9Yr", label: "Bretcu - Jud. CV" },
  { id: "ZP2kBm", label: "Bretea - Jud. BN" },
  { id: "l5QNEl", label: "Bretea Muresana - Jud. HD" },
  { id: "mGxQwr", label: "Bretea Romana - Jud. HD" },
  { id: "rBbMJr", label: "Bretea Streiului - Jud. HD" },
  { id: "rBbgbr", label: "Bretelin - Jud. HD" },
  { id: "ZMVj8l", label: "Breznicioara - Jud. MH" },
  { id: "lOAVNr", label: "Breznita Motru - Jud. MH" },
  { id: "Zy6bql", label: "Breznita Ocol - Jud. MH" },
  { id: "mgx09r", label: "Brezoaele - Jud. DB" },
  { id: "mnxdaZ", label: "Brezoaia - Jud. DB" },
  { id: "l0KekZ", label: "Brezoi - Jud. VL" },
  { id: "mgAe1Z", label: "Brezon - Jud. CS" },
  { id: "r33Wer", label: "Briheni - Jud. BH" },
  { id: "lOA2or", label: "Britcani - Jud. NT" },
  { id: "ZyW6om", label: "Brodina - Jud. SV" },
  { id: "ma5Mjm", label: "Brodina De Jos - Jud. SV" },
  { id: "lLJkYr", label: "Brodoc - Jud. VS" },
  { id: "ZMY3zZ", label: "Broscauti - Jud. BT" },
  { id: "ZzVNKZ", label: "Broscosesti - Jud. VS" },
  { id: "lxEEQm", label: "Brosteanca - Jud. TR" },
  { id: "wmg5DZ", label: "Brosteni - Jud. AG" },
  { id: "ropj5Z", label: "Brosteni - Jud. BT" },
  { id: "mgA0EZ", label: "Brosteni - Jud. CS" },
  { id: "rBOvgZ", label: "Brosteni - Jud. DB" },
  { id: "lezLJm", label: "Brosteni - Jud. GJ" },
  { id: "lj501Z", label: "Brosteni - Jud. IL" },
  { id: "rqEVPr", label: "Brosteni - Jud. IS" },
  { id: "rK2OWm", label: "Brosteni - Jud. MH" },
  { id: "mnEXql", label: "Brosteni - Jud. NT" },
  { id: "rBndVr", label: "Brosteni - Jud. OT" },
  { id: "rERxxZ", label: "Brosteni - Jud. SB" },
  { id: "rBnwQr", label: "Brosteni - Jud. SV" },
  { id: "r36kzm", label: "Brosteni - Jud. VL" },
  { id: "lO7PLl", label: "Brosteni - Jud. VN" },
  { id: "rKxwxZ", label: "Brosteni - Jud. VS" },
  { id: "lpbzxr", label: "Brostenii De Sus - Jud. GJ" },
  { id: "lD2dYr", label: "Brotuna - Jud. HD" },
  { id: "rwEvjl", label: "Bruiu - Jud. SB" },
  { id: "grK7Er", label: "Brusturi - Jud. AR" },
  { id: "l0pDNl", label: "Brusturi - Jud. BH" },
  { id: "rE6J5Z", label: "Brusturi - Jud. NT" },
  { id: "mb5p2l", label: "Brusturi - Jud. SJ" },
  { id: "ma1NDm", label: "Brusturoasa - Jud. BC" },
  { id: "WZPKDr", label: "Bruznic - Jud. AR" },
  { id: "WZPevl", label: "Bubesti - Jud. AB" },
  { id: "ZQvKal", label: "Bucea - Jud. CJ" },
  { id: "NmkJzl", label: "Buceava Soimus - Jud. AR" },
  { id: "rEQLBr", label: "Bucecea - Jud. BT" },
  { id: "yZRKjl", label: "Bucerdea Granoasa - Jud. AB" },
  { id: "0lYPJZ", label: "Bucerdea Vanoasa - Jud. AB" },
  { id: "rwDByZ", label: "Buces - Jud. HD" },
  { id: "lYK20r", label: "Buces Vulcan - Jud. HD" },
  { id: "r9nzqZ", label: "Bucesti - Jud. GL" },
  { id: "ZzEgBr", label: "Buchila - Jud. BC" },
  { id: "ZPqJAl", label: "Buchilasi - Jud. PH" },
  { id: "r6Og3Z", label: "Buchin - Jud. CS" },
  { id: "lxbnpr", label: "Bucicani - Jud. DJ" },
  { id: "ZyGDKm", label: "Bucin - Jud. HR" },
  { id: "lOBBGZ", label: "Bucinisu - Jud. OT" },
  { id: "ZVdPBm", label: "Bucinisu Mic - Jud. OT" },
  { id: "nlj6Kl", label: "Bucium - Jud. AB" },
  { id: "ljGgLm", label: "Bucium - Jud. BH" },
  { id: "Z4YGxl", label: "Bucium - Jud. BV" },
  { id: "mA4KBl", label: "Bucium - Jud. HD" },
  { id: "m1GzRZ", label: "Bucium - Jud. NT" },
  { id: "lWOgql", label: "Bucium Orlea - Jud. HD" },
  { id: "Wr6G5Z", label: "Bucium Sat - Jud. AB" },
  { id: "mnJ0jm", label: "Buciumeni - Jud. CL" },
  { id: "r9nWXZ", label: "Buciumeni - Jud. DB" },
  { id: "lj97bZ", label: "Buciumeni - Jud. GL" },
  { id: "mGx2Dr", label: "Buciumeni - Jud. IF" },
  { id: "rB5dbm", label: "Buciumeni - Jud. VL" },
  { id: "Rr9pNm", label: "Buciumi - Jud. BC" },
  { id: "mGP50r", label: "Buciumi - Jud. MM" },
  { id: "r3y25l", label: "Buciumi - Jud. SJ" },
  { id: "r97gql", label: "Bucosnita - Jud. CS" },
  { id: "Wr88qr", label: "Bucov - Jud. AG" },
  { id: "Zzz43Z", label: "Bucov - Jud. PH" },
  { id: "l71nYr", label: "Bucova - Jud. CS" },
  { id: "Z4qe0l", label: "Bucovat - Jud. DJ" },
  { id: "rKxM9Z", label: "Bucovat - Jud. TM" },
  { id: "lj9dLZ", label: "Bucovicior - Jud. DJ" },
  { id: "rEQ6Br", label: "Bucsa - Jud. BC" },
  { id: "mgLREr", label: "Bucsa - Jud. IL" },
  { id: "m1qnYm", label: "Bucsana - Jud. GJ" },
  { id: "mgx69r", label: "Bucsani - Jud. DB" },
  { id: "rBOpJZ", label: "Bucsani - Jud. GR" },
  { id: "mbNPam", label: "Bucsani - Jud. VL" },
  { id: "PrEjMZ", label: "Bucsenesti - Jud. AG" },
  { id: "olWNkZ", label: "Bucsenesti Lotasi - Jud. AG" },
  { id: "ZNyYYr", label: "Bucsesti - Jud. BC" },
  { id: "r2qgvm", label: "Bucsoaia - Jud. SV" },
  { id: "lpb7er", label: "Bucu - Jud. IL" },
  { id: "lJ26Al", label: "Bucura - Jud. MH" },
  { id: "ZM7vpm", label: "Bucureasa - Jud. GJ" },
  { id: "rBb7br", label: "Bucuresci - Jud. HD" },
  { id: "lJQe9Z", label: "Bucuroaia - Jud. BH" },
  { id: "krdjql", label: "Bucuru - Jud. AB" },
  { id: "ZMYV5Z", label: "Buda - Jud. BC" },
  { id: "r82z0l", label: "Buda - Jud. BT" },
  { id: "mnJjBm", label: "Buda - Jud. BZ" },
  { id: "mGPY0r", label: "Buda - Jud. IF" },
  { id: "mnQGxl", label: "Buda - Jud. IS" },
  { id: "r6yW5m", label: "Buda - Jud. PH" },
  { id: "lOYAJr", label: "Buda - Jud. SV" },
  { id: "mnp5XZ", label: "Buda - Jud. VL" },
  { id: "r9bddZ", label: "Buda - Jud. VN" },
  { id: "r89EMZ", label: "Buda - Jud. VS" },
  { id: "lDDaJl", label: "Buda Craciunesti - Jud. BZ" },
  { id: "mnPMNZ", label: "Budacu De Jos - Jud. BN" },
  { id: "m1kgqZ", label: "Budacu De Sus - Jud. BN" },
  { id: "lpbner", label: "Budai - Jud. IS" },
  { id: "arBWDZ", label: "Budaiesti - Jud. AB" },
  { id: "Z4DR0Z", label: "Budanesti - Jud. MH" },
  { id: "EZz6WZ", label: "Budeasa - Jud. AG" },
  { id: "3r21pm", label: "Budeasa Mare - Jud. AG" },
  { id: "3ZVeRr", label: "Budeasa Mica - Jud. AG" },
  { id: "mbNNvm", label: "Budele - Jud. VL" },
  { id: "olWBEZ", label: "Budeni - Jud. AB" },
  { id: "ZNGqxl", label: "Budeni - Jud. GR" },
  { id: "rBnQar", label: "Budeni - Jud. SV" },
  { id: "yZReLr", label: "Budesti - Jud. AR" },
  { id: "mnPQXZ", label: "Budesti - Jud. BC" },
  { id: "lDAjJm", label: "Budesti - Jud. BN" },
  { id: "rE7aQl", label: "Budesti - Jud. BZ" },
  { id: "lW1dkr", label: "Budesti - Jud. CL" },
  { id: "lj5zqZ", label: "Budesti - Jud. IS" },
  { id: "rq0O0m", label: "Budesti - Jud. MM" },
  { id: "r2wEvZ", label: "Budesti - Jud. NT" },
  { id: "mbNnom", label: "Budesti - Jud. VL" },
  { id: "ZM6Mgl", label: "Budesti - Jud. VN" },
  { id: "rwVAXm", label: "Budesti - Jud. VS" },
  { id: "mAA5am", label: "Budesti Fanate - Jud. BN" },
  { id: "lOD6om", label: "Budieni - Jud. GJ" },
  { id: "ljGqqm", label: "Budila - Jud. BV" },
  { id: "yZR9vr", label: "Budisteni - Jud. AG" },
  { id: "ZV1yJr", label: "Budisteni - Jud. BZ" },
  { id: "mkE9OZ", label: "Budiu Mic - Jud. MS" },
  { id: "lOwGJl", label: "Budoi - Jud. BH" },
  { id: "m1nW9r", label: "Budrea - Jud. BZ" },
  { id: "rwVdxm", label: "Budu Cantemir - Jud. VS" },
  { id: "ZM99zZ", label: "Buduhala - Jud. GJ" },
  { id: "l087Ql", label: "Buduile - Jud. BZ" },
  { id: "maN6br", label: "Budurasti - Jud. VL" },
  { id: "ZRjeKZ", label: "Budureasa - Jud. BH" },
  { id: "Zye18Z", label: "Budurleni - Jud. BN" },
  { id: "rKjQxm", label: "Budus - Jud. BN" },
  { id: "rdjv7l", label: "Buduslau - Jud. BH" },
  { id: "ZzwOEl", label: "Buesti - Jud. IL" },
  { id: "l7JM5Z", label: "Buftea - Jud. IF" },
  { id: "ZNGyal", label: "Bugeac - Jud. CT" },
  { id: "VrXzel", label: "Bughea De Jos - Jud. AG" },
  { id: "ZQaNnZ", label: "Bughea De Jos - Jud. PH" },
  { id: "Xl0QPm", label: "Bughea De Sus - Jud. AG" },
  { id: "Zv2n5Z", label: "Buhaceni - Jud. BT" },
  { id: "r3Q1zl", label: "Buhaeni - Jud. IS" },
  { id: "ma6pJr", label: "Buhaiesti - Jud. VS" },
  { id: "lWOWYl", label: "Buhalnita - Jud. IS" },
  { id: "mkEX2Z", label: "Buhalnita - Jud. NT" },
  { id: "QmbqBl", label: "Buhani - Jud. AR" },
  { id: "EZzRKZ", label: "Buhocel - Jud. BC" },
  { id: "Zv2OKZ", label: "Buhoci - Jud. BC" },
  { id: "ZMVyGl", label: "Buhonca - Jud. NT" },
  { id: "krw5ol", label: "Buhusi - Jud. BC" },
  { id: "lOBpoZ", label: "Buia - Jud. SB" },
  { id: "m1GojZ", label: "Buicani - Jud. MH" },
  { id: "Zv5b5r", label: "Buicesti - Jud. MH" },
  { id: "ZQaqvZ", label: "Buicesti - Jud. OT" },
  { id: "l0pqkl", label: "Buimaceni - Jud. BT" },
  { id: "EZzMEr", label: "Bujoi - Jud. AG" },
  { id: "r8YPKZ", label: "Bujor - Jud. DJ" },
  { id: "lD6wJr", label: "Bujor - Jud. MS" },
  { id: "mA1X4m", label: "Bujor Hodaie - Jud. MS" },
  { id: "rwy7ol", label: "Bujoreanca - Jud. DB" },
  { id: "krdMzZ", label: "Bujoreni - Jud. AG" },
  { id: "mGaa6Z", label: "Bujoreni - Jud. TR" },
  { id: "lL6q1r", label: "Bujoreni - Jud. VL" },
  { id: "rE2eQZ", label: "Bujoru - Jud. HD" },
  { id: "mA9aBm", label: "Bujoru - Jud. TR" },
  { id: "ZM6J8l", label: "Bulagei - Jud. VL" },
  { id: "lpVzpr", label: "Bulai - Jud. SV" },
  { id: "ZVy4xr", label: "Bulboaca - Jud. VS" },
  { id: "yZR3jl", label: "Bulbuc - Jud. AB" },
  { id: "rBbE5r", label: "Bulbucani - Jud. IS" },
  { id: "rqbYgZ", label: "Bulbucata - Jud. GR" },
  { id: "ZM785m", label: "Bulbuceni - Jud. GJ" },
  { id: "JlJzgl", label: "Bulci - Jud. AR" },
  { id: "ZQwq4m", label: "Buleta - Jud. VL" },
  { id: "mA4VKl", label: "Bulgareni - Jud. HR" },
  { id: "r9DBym", label: "Bulgari - Jud. SJ" },
  { id: "ZNJz7Z", label: "Bulgarus - Jud. TM" },
  { id: "mGxnwr", label: "Buliga - Jud. IL" },
  { id: "lYGn0r", label: "Bulimanu - Jud. OT" },
  { id: "rop9xZ", label: "Bulz - Jud. BH" },
  { id: "r6x02l", label: "Bulza - Jud. TM" },
  { id: "rKPkPZ", label: "Bulzesti - Jud. DJ" },
  { id: "ZP7w3r", label: "Bulzestii De Jos - Jud. HD" },
  { id: "rqEo0r", label: "Bulzestii De Sus - Jud. HD" },
  { id: "ZQvMgl", label: "Bumbacari - Jud. BR" },
  { id: "rKxqeZ", label: "Bumbata - Jud. VS" },
  { id: "ZQqjoZ", label: "Bumbesti Jiu - Jud. GJ" },
  { id: "lx7ApZ", label: "Bumbesti Pitic - Jud. GJ" },
  { id: "BZ4b0m", label: "Bumbueni - Jud. AG" },
  { id: "l59xNl", label: "Bumbuesti - Jud. VL" },
  { id: "l7Q65r", label: "Bumbuia - Jud. DB" },
  { id: "lOYMLr", label: "Bunea Mare - Jud. TM" },
  { id: "XlDYnm", label: "Bunesti - Jud. AG" },
  { id: "ZP2Rbm", label: "Bunesti - Jud. BV" },
  { id: "lpzYMr", label: "Bunesti - Jud. CJ" },
  { id: "mgLd5r", label: "Bunesti - Jud. HD" },
  { id: "ZzzR8Z", label: "Bunesti - Jud. SV" },
  { id: "ZzP7Dm", label: "Bunesti - Jud. VL" },
  { id: "ZPALbl", label: "Bunesti - Jud. VS" },
  { id: "ZVKXol", label: "Bungard - Jud. BN" },
  { id: "mAGpoZ", label: "Bungard - Jud. SB" },
  { id: "r36p0m", label: "Bungetani - Jud. VL" },
  { id: "rEdvqm", label: "Bungetu - Jud. DB" },
  { id: "lj3xLm", label: "Bunghi - Jud. NT" },
  { id: "ZQGAam", label: "Bunila - Jud. HD" },
  { id: "Lmnz8m", label: "Buninginea - Jud. AB" },
  { id: "lD6xXr", label: "Bunoaica - Jud. MH" },
  { id: "nljDqr", label: "Bunta - Jud. AB" },
  { id: "ma1zNm", label: "Buntesti - Jud. BH" },
  { id: "lYPzAZ", label: "Burca - Jud. VN" },
  { id: "Zy5dKZ", label: "Burcioaia - Jud. VN" },
  { id: "lWBxoZ", label: "Burda - Jud. BH" },
  { id: "ZvO2Kl", label: "Burdalesti - Jud. VL" },
  { id: "EZzKEl", label: "Burdea - Jud. AG" },
  { id: "ZR66KZ", label: "Burdeni - Jud. TR" },
  { id: "3ZVvBZ", label: "Burdesti - Jud. AG" },
  { id: "Z4qKzl", label: "Burduca - Jud. DB" },
  { id: "lxAvzm", label: "Burdulesti - Jud. OT" },
  { id: "leaxYl", label: "Burdusaci - Jud. BC" },
  { id: "arBp2l", label: "Buretesti - Jud. AG" },
  { id: "lL1oAm", label: "Burias - Jud. IF" },
  { id: "lD6GGr", label: "Burila Mare - Jud. MH" },
  { id: "rBjBQr", label: "Burila Mica - Jud. MH" },
  { id: "r3QK0l", label: "Burjuc - Jud. HD" },
  { id: "mk8y2r", label: "Burla - Jud. BT" },
  { id: "ZMG8zm", label: "Burla - Jud. SV" },
  { id: "lODWom", label: "Burlani - Jud. GJ" },
  { id: "mnPV8Z", label: "Burlesti - Jud. BT" },
  { id: "JlJLpr", label: "Burlusi - Jud. AG" },
  { id: "olWjBZ", label: "Burnesti - Jud. AG" },
  { id: "lD2g2r", label: "Bursuc Deal - Jud. IS" },
  { id: "l7JNwZ", label: "Bursuc Vale - Jud. IS" },
  { id: "lxbM0r", label: "Bursucani - Jud. GL" },
  { id: "r6xG3l", label: "Bursuceni - Jud. SV" },
  { id: "ZVyExr", label: "Bursuci - Jud. VS" },
  { id: "ZvAyJr", label: "Buru - Jud. CJ" },
  { id: "r92zOZ", label: "Buruienesti - Jud. IS" },
  { id: "lexkJZ", label: "Buruienesti - Jud. NT" },
  { id: "Oroy8l", label: "Buruienis - Jud. BC" },
  { id: "BmaMem", label: "Buruienisu De Sus - Jud. BC" },
  { id: "WmG96Z", label: "Burzesti - Jud. AB" },
  { id: "olWkkl", label: "Burzonesti - Jud. AB" },
  { id: "ZRjz2Z", label: "Burzuc - Jud. BH" },
  { id: "r2wV9Z", label: "Busag - Jud. MM" },
  { id: "r3y3Vl", label: "Busca - Jud. OT" },
  { id: "r2MKbm", label: "Buscata - Jud. VS" },
  { id: "lD6Wnr", label: "Busesti - Jud. MH" },
  { id: "r3BEzm", label: "Busmei - Jud. NT" },
  { id: "lOWKLl", label: "Bustea - Jud. BZ" },
  { id: "mGWMnm", label: "Bustenari - Jud. PH" },
  { id: "mnx88Z", label: "Busteni - Jud. DJ" },
  { id: "ZQawoZ", label: "Busteni - Jud. PH" },
  { id: "rE26MZ", label: "Bustuchin - Jud. GJ" },
  { id: "lO6goZ", label: "Busu - Jud. DJ" },
  { id: "mgLjDr", label: "Busuioci - Jud. GJ" },
  { id: "ZyNLLl", label: "Busuletu - Jud. DJ" },
  { id: "7rqkBl", label: "Buta - Jud. AG" },
  { id: "mkEkqZ", label: "Buta - Jud. OT" },
  { id: "ZNy2er", label: "Butani - Jud. BH" },
  { id: "ZVk6Bl", label: "Butanu - Jud. VL" },
  { id: "ZPAO3l", label: "Butculesti - Jud. TR" },
  { id: "ZM9E4Z", label: "Butea - Jud. IS" },
  { id: "rwPnaZ", label: "Buteasa - Jud. MM" },
  { id: "BZ4MQl", label: "Buteni - Jud. AR" },
  { id: "maLD7l", label: "Buteni - Jud. CJ" },
  { id: "XlDx2l", label: "Butesti - Jud. AB" },
  { id: "rqO3gl", label: "Butesti - Jud. TR" },
  { id: "mbo0qr", label: "Butimanu - Jud. DB" },
  { id: "rwVwKm", label: "Butin - Jud. TM" },
  { id: "lJ2p9l", label: "Butnaresti - Jud. NT" },
  { id: "mb592l", label: "Butoi - Jud. OT" },
  { id: "l7wWnl", label: "Butoiesti - Jud. MH" },
  { id: "ZVweAl", label: "Butoiu - Jud. IL" },
  { id: "rKP9eZ", label: "Butoiu De Jos - Jud. DB" },
  { id: "r27xAZ", label: "Butoiu De Sus - Jud. DB" },
  { id: "mbKDKr", label: "Butucaria - Jud. VS" },
  { id: "rERwMZ", label: "Butuci - Jud. PH" },
  { id: "ZQqp5Z", label: "Buturugeni - Jud. GR" },
  { id: "lpz2xr", label: "Buza - Jud. CJ" },
  { id: "r9qKNr", label: "Buza Catun - Jud. BN" },
  { id: "lJN1Wl", label: "Buzad - Jud. TM" },
  { id: "r33Xqr", label: "Buzaiel - Jud. BV" },
  { id: "lj4RNr", label: "Buzas - Jud. SJ" },
  { id: "rwpyoZ", label: "Buzau - Jud. BZ" },
  { id: "mAGBWZ", label: "Buzd - Jud. SB" },
  { id: "l7Q3Xr", label: "Buzduc - Jud. DJ" },
  { id: "r3QgAl", label: "Buzdug - Jud. IS" },
  { id: "roYELl", label: "Buzdugan - Jud. VL" },
  { id: "rXd4gr", label: "Buzeni - Jud. BT" },
  { id: "ZNJM1Z", label: "Buzescu - Jud. TR" },
  { id: "rKPR9Z", label: "Buzesti - Jud. GJ" },
  { id: "mgaL9Z", label: "Buzesti - Jud. MM" },
  { id: "rE6OVZ", label: "Buzesti - Jud. OT" },
  { id: "ZMJjjl", label: "Buzias - Jud. TM" },
  { id: "lL1OYm", label: "Buznea - Jud. IS" },
  { id: "rBW72Z", label: "Buzoeni - Jud. CL" },
  { id: "BZ4xAZ", label: "Buzoesti - Jud. AG" },
  { id: "Z49kQl", label: "Buzota - Jud. PH" },
  { id: "m1kq9Z", label: "Cabesti - Jud. BC" },
  { id: "Zv29DZ", label: "Cabesti - Jud. BH" },
  { id: "r3QaGl", label: "Cabesti - Jud. HD" },
  { id: "rKx20Z", label: "Cacica - Jud. SV" },
  { id: "m1GM3Z", label: "Caciulata - Jud. MS" },
  { id: "mgo29m", label: "Caciulata - Jud. VL" },
  { id: "Z4qBql", label: "Caciulatesti - Jud. DJ" },
  { id: "roz3Xm", label: "Caciulati - Jud. IF" },
  { id: "lWedYr", label: "Caciulatu - Jud. DJ" },
  { id: "ZPa3Al", label: "Caciulesti - Jud. NT" },
  { id: "ljNzBl", label: "Cacova Ierii - Jud. CJ" },
  { id: "ZMVwgl", label: "Cacuciu - Jud. MS" },
  { id: "r82Wql", label: "Cacuciu Nou - Jud. BH" },
  { id: "lxx6pl", label: "Cacuciu Vechi - Jud. BH" },
  { id: "ZyGdKm", label: "Cadaciu Mare - Jud. HR" },
  { id: "rKn9km", label: "Cadaciu Mic - Jud. HR" },
  { id: "mGao9Z", label: "Cadar - Jud. TM" },
  { id: "mG51BZ", label: "Cadaresti - Jud. BC" },
  { id: "ZQQD4Z", label: "Cadea - Jud. BH" },
  { id: "lW1PAr", label: "Caianu - Jud. CJ" },
  { id: "l0pG0l", label: "Caianu Mare - Jud. BN" },
  { id: "l7vV3Z", label: "Caianu Mic - Jud. BN" },
  { id: "lW1nRr", label: "Caianu Mic - Jud. CJ" },
  { id: "mkwdzm", label: "Caianu Vama - Jud. CJ" },
  { id: "rE4MQm", label: "Caiata - Jud. VN" },
  { id: "ZMYkjZ", label: "Caila - Jud. BN" },
  { id: "m1q09m", label: "Cainelu De Jos - Jud. HD" },
  { id: "r3QWGl", label: "Cainelu De Sus - Jud. HD" },
  { id: "l711Lr", label: "Caineni Bai - Jud. BR" },
  { id: "mnpOqZ", label: "Cainenii Mari - Jud. VL" },
  { id: "mk1QQZ", label: "Cainenii Mici - Jud. VL" },
  { id: "ZP2Gvm", label: "Caiuti - Jud. BC" },
  { id: "l7y4Yr", label: "Cajvana - Jud. SV" },
  { id: "mbPXvZ", label: "Calacea - Jud. BH" },
  { id: "rd784m", label: "Calacea - Jud. SJ" },
  { id: "rqO7vl", label: "Calacea - Jud. TM" },
  { id: "rBOMqZ", label: "Calafat - Jud. DJ" },
  { id: "r2Mwgm", label: "Calafindesti - Jud. SV" },
  { id: "mbOydr", label: "Calan - Jud. HD" },
  { id: "ZzwQWl", label: "Calanu Mic - Jud. HD" },
  { id: "ZVLPKm", label: "Calaparu - Jud. GJ" },
  { id: "Oro3Xr", label: "Calapodesti - Jud. BC" },
  { id: "leab1l", label: "Calarasi - Jud. BT" },
  { id: "mkw3bm", label: "Calarasi - Jud. CJ" },
  { id: "leLKPZ", label: "Calarasi - Jud. CL" },
  { id: "rBOk5Z", label: "Calarasi - Jud. DJ" },
  { id: "rwpGxZ", label: "Calarasi Gara - Jud. CJ" },
  { id: "r2Yygm", label: "Calarasii Vechi - Jud. CL" },
  { id: "r8DJMr", label: "Calareti - Jud. CL" },
  { id: "rqz6gZ", label: "Calata - Jud. CJ" },
  { id: "l5152l", label: "Calatani - Jud. BH" },
  { id: "lLedWm", label: "Calatea - Jud. BH" },
  { id: "mnJyBm", label: "Calatele - Jud. CJ" },
  { id: "r6bMDr", label: "Calbor - Jud. BV" },
  { id: "mnPxNZ", label: "Calcai - Jud. BC" },
  { id: "majJWr", label: "Calcesti - Jud. GJ" },
  { id: "XlxjQr", label: "Caldararu - Jud. AG" },
  { id: "r8OePm", label: "Caldararu - Jud. IF" },
  { id: "mb9O2l", label: "Caldarasti - Jud. BZ" },
  { id: "r6OOpZ", label: "Caldarusa - Jud. BR" },
  { id: "mb95Bl", label: "Caldarusa - Jud. BZ" },
  { id: "rBW5gZ", label: "Caldaruseanca - Jud. BZ" },
  { id: "mAb4ar", label: "Calea Chiojdului - Jud. BZ" },
  { id: "lWB9BZ", label: "Calea Mare - Jud. BH" },
  { id: "Orop5Z", label: "Calene - Jud. AB" },
  { id: "ZNAG6r", label: "Calesti - Jud. GJ" },
  { id: "mA93Km", label: "Calfa - Jud. TL" },
  { id: "l0kV0Z", label: "Calienii Noi - Jud. VN" },
  { id: "ZRBGqZ", label: "Calienii Vechi - Jud. VN" },
  { id: "mndzGZ", label: "Caligi - Jud. VL" },
  { id: "l0k9QZ", label: "Calimaneasa - Jud. VN" },
  { id: "rXq7nZ", label: "Calimanel - Jud. HR" },
  { id: "lD6N1r", label: "Calimanesti - Jud. MS" },
  { id: "r993Xr", label: "Calimanesti - Jud. VL" },
  { id: "l786Lm", label: "Calimanesti - Jud. VN" },
  { id: "lOYQAr", label: "Calimanesti - Jud. VS" },
  { id: "ZNWBal", label: "Calina - Jud. CS" },
  { id: "r2bqQr", label: "Calina - Jud. VL" },
  { id: "PrEJoZ", label: "Calinesti - Jud. AG" },
  { id: "r6bq6r", label: "Calinesti - Jud. BC" },
  { id: "lOw4nl", label: "Calinesti - Jud. BT" },
  { id: "Zzge8l", label: "Calinesti - Jud. DJ" },
  { id: "ZQVyal", label: "Calinesti - Jud. MM" },
  { id: "lOB6AZ", label: "Calinesti - Jud. OT" },
  { id: "rX7jRm", label: "Calinesti - Jud. PH" },
  { id: "lLJ11r", label: "Calinesti - Jud. SV" },
  { id: "r89RXZ", label: "Calinesti - Jud. TR" },
  { id: "ZR6LAZ", label: "Calinesti - Jud. VL" },
  { id: "ZyYQoZ", label: "Calinesti Oas - Jud. SM" },
  { id: "lj4pLr", label: "Calinesti Vasilache - Jud. SV" },
  { id: "r3BJYm", label: "Calinestii De Jos - Jud. MH" },
  { id: "m1GXYZ", label: "Calinestii De Sus - Jud. MH" },
  { id: "Jr3AGl", label: "Calini - Jud. BC" },
  { id: "lj9wqZ", label: "Calmatui - Jud. GL" },
  { id: "l59jEl", label: "Calmatuiu - Jud. TR" },
  { id: "l0KR6Z", label: "Calmatuiu De Sus - Jud. TR" },
  { id: "lOWaol", label: "Calna - Jud. CJ" },
  { id: "lD2QGr", label: "Calnaci - Jud. HR" },
  { id: "QmbX2Z", label: "Calnic - Jud. AB" },
  { id: "Zy2bkl", label: "Calnic - Jud. CS" },
  { id: "mG1WdZ", label: "Calnic - Jud. CV" },
  { id: "lWORol", label: "Calnic - Jud. GJ" },
  { id: "ZQqdpZ", label: "Calnicu De Sus - Jud. GJ" },
  { id: "m1b1dr", label: "Calomfiresti - Jud. TR" },
  { id: "mGxapr", label: "Calonda - Jud. HR" },
  { id: "lDPk2l", label: "Calopar - Jud. DJ" },
  { id: "JlJbAl", label: "Calotesti - Jud. AG" },
  { id: "r6OVWZ", label: "Caltesti - Jud. BZ" },
  { id: "rqzg0Z", label: "Caltuna - Jud. BZ" },
  { id: "ZyG2Lm", label: "Calugareasa - Jud. GJ" },
  { id: "m1Dw6l", label: "Calugarei - Jud. DJ" },
  { id: "5l775l", label: "Calugareni - Jud. AR" },
  { id: "3ZVDom", label: "Calugareni - Jud. BC" },
  { id: "ZzEv8r", label: "Calugareni - Jud. BT" },
  { id: "rdVj2l", label: "Calugareni - Jud. CT" },
  { id: "ZPB9Km", label: "Calugareni - Jud. DB" },
  { id: "rozpwm", label: "Calugareni - Jud. GJ" },
  { id: "r3G9nr", label: "Calugareni - Jud. GR" },
  { id: "r66v7r", label: "Calugareni - Jud. HR" },
  { id: "ZPaWKl", label: "Calugareni - Jud. MS" },
  { id: "lYn5Jm", label: "Calugareni - Jud. NT" },
  { id: "m1BVjr", label: "Calugareni - Jud. PH" },
  { id: "leGy1r", label: "Calugareni - Jud. SV" },
  { id: "rKxJeZ", label: "Calugareni - Jud. VS" },
  { id: "rXdPDr", label: "Calugarenii Noi - Jud. BT" },
  { id: "arBODZ", label: "Calugaresti - Jud. AB" },
  { id: "lJQy9Z", label: "Calugari - Jud. BH" },
  { id: "lpVVWr", label: "Calugaru - Jud. TR" },
  { id: "lYngEm", label: "Calui - Jud. OT" },
  { id: "lLVgbr", label: "Caluseri - Jud. MS" },
  { id: "lDDEWl", label: "Calvini - Jud. BZ" },
  { id: "m1By3r", label: "Camar - Jud. SJ" },
  { id: "lDD1Wl", label: "Camarasu - Jud. CJ" },
  { id: "rKDy0Z", label: "Camarzana - Jud. SM" },
  { id: "rKxnkZ", label: "Camarzani - Jud. SV" },
  { id: "ZV10dr", label: "Camena - Jud. CS" },
  { id: "lDadnm", label: "Camena - Jud. TL" },
  { id: "AZvN9Z", label: "Camenca - Jud. BC" },
  { id: "rwpLVZ", label: "Camenita - Jud. CS" },
  { id: "r9DO8m", label: "Camin - Jud. SM" },
  { id: "ZyNJ4l", label: "Camineasca - Jud. GR" },
  { id: "WmG0Br", label: "Camna - Jud. AR" },
  { id: "lJQwpZ", label: "Camp - Jud. BH" },
  { id: "r6b26r", label: "Camp - Jud. BN" },
  { id: "lYD1XZ", label: "Camp Moti - Jud. BH" },
  { id: "rXdver", label: "Campani - Jud. BH" },
  { id: "lDAMGm", label: "Campani De Pomezeu - Jud. BH" },
  { id: "r6OK9Z", label: "Campenesti - Jud. CJ" },
  { id: "NmkQZE", label: "Campeni - Jud. AB" },
  { id: "rEQ2Br", label: "Campeni - Jud. BC" },
  { id: "ZP2Vvm", label: "Campeni - Jud. BT" },
  { id: "ZzowKl", label: "Campeni - Jud. BZ" },
  { id: "mAO5qZ", label: "Campeni - Jud. DJ" },
  { id: "Zy69Yl", label: "Campenita - Jud. MS" },
  { id: "ljNKwl", label: "Campia - Jud. CS" },
  { id: "l7yoqr", label: "Campia - Jud. SJ" },
  { id: "m1nQqr", label: "Campia Turzii - Jud. CJ" },
  { id: "mgBJBl", label: "Campina - Jud. PH" },
  { id: "l78e3m", label: "Campineanca - Jud. VN" },
  { id: "m1D99l", label: "Campofeni - Jud. GJ" },
  { id: "r6GwgZ", label: "Campu Cetatii - Jud. MS" },
  { id: "WmGv2l", label: "Campu Goblii - Jud. AB" },
  { id: "mA4val", label: "Campu Lui Neag - Jud. HD" },
  { id: "ZyGNLm", label: "Campu Mare - Jud. GJ" },
  { id: "rdxNzr", label: "Campu Mare - Jud. MH" },
  { id: "ZNwRyl", label: "Campu Mare - Jud. OT" },
  { id: "lOBWAZ", label: "Campu Parului - Jud. OT" },
  { id: "Qmb1El", label: "Campulung - Jud. AG" },
  { id: "lOAYKr", label: "Campulung La Tisa - Jud. MM" },
  { id: "ZMGNGm", label: "Campulung Moldovenesc - Jud. SV" },
  { id: "rqzBoZ", label: "Campulungeanca - Jud. BZ" },
  { id: "lYxAVZ", label: "Campurelu - Jud. GR" },
  { id: "r2bn9r", label: "Campuri - Jud. VN" },
  { id: "rqE7or", label: "Campuri De Sus - Jud. HD" },
  { id: "ZQGxom", label: "Campuri Surduc - Jud. HD" },
  { id: "r8O30m", label: "Camuiesti - Jud. GJ" },
  { id: "Zy2QKl", label: "Candeasca - Jud. CL" },
  { id: "ma5j2m", label: "Candelesti - Jud. OT" },
  { id: "AZQqnZ", label: "Candesti - Jud. AB" },
  { id: "Oro75r", label: "Candesti - Jud. AG" },
  { id: "m1kQ9Z", label: "Candesti - Jud. BT" },
  { id: "Zy2qDl", label: "Candesti - Jud. BZ" },
  { id: "r3B2Ym", label: "Candesti - Jud. NT" },
  { id: "mndgaZ", label: "Candesti - Jud. VN" },
  { id: "l7Q8qr", label: "Candesti Deal - Jud. DB" },
  { id: "rKP60Z", label: "Candesti Vale - Jud. DB" },
  { id: "l7w7Ll", label: "Candu - Jud. MS" },
  { id: "lYxexZ", label: "Canepesti - Jud. GJ" },
  { id: "l71bXr", label: "Canesti - Jud. BZ" },
  { id: "l545vZ", label: "Canicea - Jud. CS" },
  { id: "mboLQr", label: "Canlia - Jud. CT" },
  { id: "r2MvAm", label: "Cantalaresti - Jud. VS" },
  { id: "rqEW0r", label: "Caoi - Jud. HD" },
  { id: "ZRxxNm", label: "Cap Aurora - Jud. CT" },
  { id: "ZzzGNZ", label: "Cap Rosu - Jud. PH" },
  { id: "AZQ8gr", label: "Capalna - Jud. AB" },
  { id: "ljGvbm", label: "Capalna - Jud. BH" },
  { id: "ZV3Bol", label: "Capalna - Jud. SJ" },
  { id: "ElL6Xr", label: "Capalna De Jos - Jud. AB" },
  { id: "ZQVA5l", label: "Capalna De Sus - Jud. MS" },
  { id: "BZ4X8Z", label: "Capalnas - Jud. AR" },
  { id: "lODxJm", label: "Capalnita - Jud. HR" },
  { id: "mbKqar", label: "Capat - Jud. TM" },
  { id: "lLePAm", label: "Capata - Jud. BC" },
  { id: "EZz2NZ", label: "Capatanenii Pamanteni - Jud. AG" },
  { id: "BZ4eJl", label: "Capatanenii Ungureni - Jud. AG" },
  { id: "mGwj0m", label: "Capatanesti - Jud. BZ" },
  { id: "ZVd6Am", label: "Capatanesti - Jud. MH" },
  { id: "rXyypZ", label: "Capeni - Jud. CV" },
  { id: "mGPd6r", label: "Capeti - Jud. MS" },
  { id: "ZyN22l", label: "Capidava - Jud. CT" },
  { id: "mnz0am", label: "Capleni - Jud. SM" },
  { id: "9m15qm", label: "Caporal Alexa - Jud. AR" },
  { id: "lOwwGl", label: "Capotesti - Jud. BC" },
  { id: "rdKzRm", label: "Capotesti - Jud. VS" },
  { id: "ZM9VzZ", label: "Capreni - Jud. GJ" },
  { id: "WZPWBr", label: "Caprioara - Jud. AR" },
  { id: "ZMR3pl", label: "Caprioara - Jud. CJ" },
  { id: "ZyWQ2m", label: "Caprioara - Jud. TL" },
  { id: "mnx1NZ", label: "Caprioru - Jud. DB" },
  { id: "krdR4l", label: "Capruta - Jud. AR" },
  { id: "mA1zWm", label: "Capsa - Jud. NT" },
  { id: "r27LbZ", label: "Capsuna - Jud. DB" },
  { id: "0lY1Rl", label: "Captalan - Jud. AB" },
  { id: "rwE8Ql", label: "Capu Campului - Jud. SV" },
  { id: "Zzgpbl", label: "Capu Coastei - Jud. DB" },
  { id: "roxdvZ", label: "Capu Codrului - Jud. SV" },
  { id: "mnQpGl", label: "Capu Corbului - Jud. HR" },
  { id: "JlJ2pl", label: "Capu Dealului - Jud. AB" },
  { id: "ZRxoAm", label: "Capu Dealului - Jud. GJ" },
  { id: "ZzVdnZ", label: "Capu Dealului - Jud. VL" },
  { id: "4lO3wl", label: "Capu Piscului - Jud. AG" },
  { id: "Z4wKQr", label: "Capu Satului - Jud. BZ" },
  { id: "Xlxxol", label: "Capud - Jud. AB" },
  { id: "r6xYgl", label: "Capusneni - Jud. VS" },
  { id: "r8BgPl", label: "Capusu De Campie - Jud. MS" },
  { id: "l714Xr", label: "Capusu Mare - Jud. CJ" },
  { id: "rwpGaZ", label: "Capusu Mic - Jud. CJ" },
  { id: "ZvAk2r", label: "Cara - Jud. CJ" },
  { id: "l7wqwl", label: "Caracal - Jud. OT" },
  { id: "l0NV0r", label: "Caraci - Jud. HD" },
  { id: "XlD81l", label: "Caraclau - Jud. BC" },
  { id: "mkwpPm", label: "Caragele - Jud. BZ" },
  { id: "rByEDm", label: "Caraiman - Jud. BT" },
  { id: "ZQqodZ", label: "Caraiman - Jud. DJ" },
  { id: "Zy6jql", label: "Caramidaru - Jud. MH" },
  { id: "Xlxnnr", label: "Carand - Jud. AR" },
  { id: "mk86wr", label: "Carandeni - Jud. BH" },
  { id: "ZyW3Lm", label: "Carani - Jud. TM" },
  { id: "mAbWqr", label: "Caransebes - Jud. CS" },
  { id: "mnP9xZ", label: "Caranzel - Jud. BH" },
  { id: "leJWXm", label: "Caraorman - Jud. TL" },
  { id: "mnxwpZ", label: "Carapancea - Jud. GR" },
  { id: "Zvg3Pl", label: "Carapcesti - Jud. GL" },
  { id: "lOwEKl", label: "Carasa - Jud. BT" },
  { id: "Zyeq2Z", label: "Carasau - Jud. BH" },
  { id: "l7yBnr", label: "Caraseu - Jud. SM" },
  { id: "mgAWAZ", label: "Carasova - Jud. CS" },
  { id: "ZRwGql", label: "Carastau - Jud. HD" },
  { id: "ZyYA8Z", label: "Carastelec - Jud. SJ" },
  { id: "Jr3GYr", label: "Carasti - Jud. AB" },
  { id: "l08RJl", label: "Caratnau De Jos - Jud. BZ" },
  { id: "ZRJ8eZ", label: "Caratnau De Sus - Jud. BZ" },
  { id: "l7Qowr", label: "Caraula - Jud. DJ" },
  { id: "l799qr", label: "Caravaneti - Jud. TR" },
  { id: "rqEJ0r", label: "Carbesti - Jud. GJ" },
  { id: "mb9Wol", label: "Carbunari - Jud. CS" },
  { id: "lWO0Bl", label: "Carbunari - Jud. IS" },
  { id: "maxj7l", label: "Carbunari - Jud. MM" },
  { id: "lWD3Am", label: "Carbunari - Jud. PH" },
  { id: "lOBPwZ", label: "Carbunesti - Jud. PH" },
  { id: "l0XL6l", label: "Carbunesti Sat - Jud. GJ" },
  { id: "l0kXBZ", label: "Carcadiesti - Jud. VL" },
  { id: "lLJ8Yr", label: "Carcaliu - Jud. TL" },
  { id: "lO6qKZ", label: "Carcea - Jud. DJ" },
  { id: "ZVdMdm", label: "Carceni - Jud. MH" },
  { id: "Rr9zMZ", label: "Carcesti - Jud. AG" },
  { id: "ZVwwAl", label: "Carciu - Jud. GJ" },
  { id: "krdY2Z", label: "Carciumaresti - Jud. AG" },
  { id: "rKxXPZ", label: "Cardon - Jud. TL" },
  { id: "ZV32Ol", label: "Carei - Jud. SM" },
  { id: "leJYgm", label: "Carja - Jud. VS" },
  { id: "r6y0Dm", label: "Carjari - Jud. PH" },
  { id: "ZRK8vl", label: "Carjei - Jud. MH" },
  { id: "mnpWGZ", label: "Carjelari - Jud. TL" },
  { id: "lL1Xnm", label: "Carjiti - Jud. HD" },
  { id: "mgL81r", label: "Carjoaia - Jud. IS" },
  { id: "lxEkwm", label: "Carjoani - Jud. VS" },
  { id: "lWe3yr", label: "Carlanesti - Jud. DB" },
  { id: "rBn3ar", label: "Carlibaba - Jud. SV" },
  { id: "rqp2Gm", label: "Carlibaba Noua - Jud. SV" },
  { id: "r3Qjql", label: "Carlig - Jud. IS" },
  { id: "Zy6kLl", label: "Carlig - Jud. NT" },
  { id: "ZRx0em", label: "Carligei - Jud. DJ" },
  { id: "ZvgNJl", label: "Carligei - Jud. GJ" },
  { id: "ZVk0Jl", label: "Carligele - Jud. VN" },
  { id: "rBydJm", label: "Carligi - Jud. BC" },
  { id: "r6GLDZ", label: "Carligi - Jud. NT" },
  { id: "lOW7Ll", label: "Carligu Mare - Jud. BZ" },
  { id: "Zy258l", label: "Carligu Mic - Jud. BZ" },
  { id: "ZNY5Qr", label: "Carlogani - Jud. OT" },
  { id: "r30b4Z", label: "Carlogani - Jud. VL" },
  { id: "rK359r", label: "Carlomanesti - Jud. BZ" },
  { id: "ZNGLQl", label: "Carlomanesti - Jud. GL" },
  { id: "lJNwgl", label: "Carlomanu - Jud. TR" },
  { id: "rBbegr", label: "Carmazanesti - Jud. HD" },
  { id: "rKP79Z", label: "Carna - Jud. DJ" },
  { id: "lDDd2l", label: "Carnecea - Jud. CS" },
  { id: "Zzwenl", label: "Carnesti - Jud. HD" },
  { id: "mbOBdr", label: "Carniceni - Jud. IS" },
  { id: "nljBbl", label: "Carpen - Jud. AB" },
  { id: "mG1kWZ", label: "Carpen - Jud. DJ" },
  { id: "WZPxjZ", label: "Carpenii De Sus - Jud. AB" },
  { id: "grKLQZ", label: "Carpenis - Jud. AG" },
  { id: "ZM7Bym", label: "Carpenisu - Jud. GR" },
  { id: "lWeRkr", label: "Carpinenii - Jud. CV" },
  { id: "ZP2ojm", label: "Carpinet - Jud. BH" },
  { id: "WZPjbm", label: "Carpinis - Jud. AB" },
  { id: "rqP2Bm", label: "Carpinis - Jud. BV" },
  { id: "r27JdZ", label: "Carpinis - Jud. GJ" },
  { id: "ZyGjYm", label: "Carpinis - Jud. HD" },
  { id: "l7wQAl", label: "Carpinis - Jud. MM" },
  { id: "mbKwYr", label: "Carpinis - Jud. TM" },
  { id: "l71Jqr", label: "Carpinistea - Jud. BZ" },
  { id: "lD2qnr", label: "Carpitus - Jud. HR" },
  { id: "mgA1kZ", label: "Carsa Rosie - Jud. CS" },
  { id: "rBWY5Z", label: "Carsie - Jud. CS" },
  { id: "lO7GGl", label: "Carsochesti Corabita - Jud. VN" },
  { id: "r2bwQr", label: "Carstanesti - Jud. VL" },
  { id: "r9y6qr", label: "Carstani - Jud. OT" },
  { id: "PrEL6m", label: "Carstieni - Jud. AG" },
  { id: "maoWjZ", label: "Carstovani - Jud. DJ" },
  { id: "roEYwl", label: "Carsu - Jud. MH" },
  { id: "lD2Wjr", label: "Carta - Jud. HR" },
  { id: "rqp5vm", label: "Carta - Jud. SB" },
  { id: "lYJ3Em", label: "Cartibasi - Jud. VS" },
  { id: "robaqm", label: "Cartierul Dambu - Jud. PH" },
  { id: "ZQajnZ", label: "Cartisoara - Jud. SB" },
  { id: "mGxxnr", label: "Cartiu - Jud. GJ" },
  { id: "rwy2xl", label: "Cartojani - Jud. GR" },
  { id: "9m1DYl", label: "Cartulesti - Jud. AB" },
  { id: "mkjNnl", label: "Caruia - Jud. DJ" },
  { id: "lLDPXl", label: "Carvan - Jud. CT" },
  { id: "Wr6OXZ", label: "Casa De Piatra - Jud. AB" },
  { id: "lDK2nZ", label: "Casa Veche - Jud. VL" },
  { id: "lLVOPr", label: "Casaria - Jud. NT" },
  { id: "ljNaKl", label: "Cascioarele - Jud. CL" },
  { id: "lOW03l", label: "Cascioarele - Jud. CT" },
  { id: "lj9xkZ", label: "Cascioarele - Jud. GR" },
  { id: "mGwK9m", label: "Caseiu - Jud. CJ" },
  { id: "lYy9wl", label: "Casele Micesti - Jud. CJ" },
  { id: "m1Djjl", label: "Casian - Jud. CT" },
  { id: "l71MYr", label: "Casicea - Jud. CT" },
  { id: "ZzV2YZ", label: "Casimcea - Jud. TL" },
  { id: "ljGo1m", label: "Casin - Jud. BC" },
  { id: "rEdRVm", label: "Casinu Mic - Jud. CV" },
  { id: "l7JKnZ", label: "Casinu Nou - Jud. HR" },
  { id: "ZzVx3Z", label: "Caslita - Jud. TL" },
  { id: "7rqbvZ", label: "Casoaia - Jud. AB" },
  { id: "mkwKQm", label: "Casoca - Jud. BZ" },
  { id: "mgoAkm", label: "Casoi - Jud. SV" },
  { id: "m1BP8r", label: "Casolt - Jud. SB" },
  { id: "m1n83r", label: "Casota - Jud. BZ" },
  { id: "maj9er", label: "Castau - Jud. HD" },
  { id: "mAObKZ", label: "Castelu - Jud. CT" },
  { id: "rwyQVl", label: "Castranova - Jud. DJ" },
  { id: "ZRxXjm", label: "Castrele Traiane - Jud. DJ" },
  { id: "lLV3Ar", label: "Casva - Jud. MS" },
  { id: "r6bd3r", label: "Cata - Jud. BV" },
  { id: "lOWOKl", label: "Catalina - Jud. CJ" },
  { id: "lYxGQZ", label: "Catalina - Jud. CV" },
  { id: "mkEjBZ", label: "Catalina - Jud. MM" },
  { id: "rwVevm", label: "Cataloi - Jud. TL" },
  { id: "rEQG6r", label: "Catamaresti - Jud. BT" },
  { id: "ZMYyGZ", label: "Catamaresti Deal - Jud. BT" },
  { id: "XlxwMZ", label: "Catane - Jud. AG" },
  { id: "rBO2bZ", label: "Catane - Jud. DJ" },
  { id: "arBLDl", label: "Catanele - Jud. AG" },
  { id: "mG1ODZ", label: "Catanele - Jud. DB" },
  { id: "mgBx5l", label: "Catanele - Jud. OT" },
  { id: "rqbe0Z", label: "Catanele Noi - Jud. DJ" },
  { id: "lJ68Yl", label: "Catauti - Jud. VN" },
  { id: "Zy2RLl", label: "Catcau - Jud. CJ" },
  { id: "LZMdgr", label: "Cateasca - Jud. AG" },
  { id: "ZVwDol", label: "Catelu - Jud. IF" },
  { id: "mGgY9m", label: "Catetu - Jud. VL" },
  { id: "l7195r", label: "Catiasu - Jud. BZ" },
  { id: "mGwv9m", label: "Catina - Jud. BZ" },
  { id: "rwp5jZ", label: "Catina - Jud. CJ" },
  { id: "Z49RJl", label: "Catina - Jud. PH" },
  { id: "ZNJWeZ", label: "Catrinari - Jud. SV" },
  { id: "mA4oql", label: "Catrunesti - Jud. IL" },
  { id: "2l5e3l", label: "Catunasi - Jud. AG" },
  { id: "lezx1m", label: "Catunele - Jud. GJ" },
  { id: "ZvgG2l", label: "Catunu - Jud. DB" },
  { id: "lxK6oZ", label: "Catunu - Jud. PH" },
  { id: "rwVNxm", label: "Catunu - Jud. TR" },
  { id: "ZyedkZ", label: "Cauaceu - Jud. BH" },
  { id: "r2qygm", label: "Cauas - Jud. SM" },
  { id: "lLeGym", label: "Cauasd - Jud. BH" },
  { id: "r66Lpr", label: "Cauesti - Jud. IS" },
  { id: "l79Odr", label: "Caugagia - Jud. TL" },
  { id: "Bmad2l", label: "Cauia - Jud. BC" },
  { id: "lWe4kr", label: "Cauiesti - Jud. GL" },
  { id: "max2Dl", label: "Causeni - Jud. NT" },
  { id: "ZPB1jm", label: "Cavadinesti - Jud. GL" },
  { id: "ZQvn4l", label: "Cavaran - Jud. CS" },
  { id: "lexadZ", label: "Cavnic - Jud. MM" },
  { id: "rdVB4l", label: "Cazaci - Jud. DB" },
  { id: "lxAdMm", label: "Cazaci - Jud. NT" },
  { id: "l7JOAZ", label: "Cazanesti - Jud. HD" },
  { id: "r3QM0l", label: "Cazanesti - Jud. IL" },
  { id: "mGPqdr", label: "Cazanesti - Jud. MH" },
  { id: "rqp0gm", label: "Cazanesti - Jud. OT" },
  { id: "l0kNBZ", label: "Cazanesti - Jud. VL" },
  { id: "mGabWZ", label: "Cazanesti - Jud. VS" },
  { id: "lpzEYr", label: "Cazasu - Jud. BR" },
  { id: "maLpWl", label: "Ceaba - Jud. CJ" },
  { id: "lL7B6Z", label: "Ceaca - Jud. SJ" },
  { id: "rK3y0r", label: "Ceacu - Jud. CL" },
  { id: "ZMVd8l", label: "Ceahlau - Jud. NT" },
  { id: "rodg5Z", label: "Ceairu - Jud. BZ" },
  { id: "lxEy0m", label: "Ceamurlia De Jos - Jud. TL" },
  { id: "mGaGpZ", label: "Ceamurlia De Sus - Jud. TL" },
  { id: "r3yLql", label: "Cean - Jud. SM" },
  { id: "rBWQDZ", label: "Ceanu Mare - Jud. CJ" },
  { id: "rBWKbZ", label: "Ceanu Mic - Jud. CJ" },
  { id: "r9yo8r", label: "Cearangu - Jud. MH" },
  { id: "l5OoAr", label: "Ceardac - Jud. VN" },
  { id: "Z41exl", label: "Ceatalchioi - Jud. TL" },
  { id: "ElLOYZ", label: "Ceauresti - Jud. AG" },
  { id: "l0Xekl", label: "Ceauru - Jud. GJ" },
  { id: "3r2EWm", label: "Ceausesti - Jud. AG" },
  { id: "mk1qPZ", label: "Cebza - Jud. TM" },
  { id: "lD6ejr", label: "Cecalaca - Jud. MS" },
  { id: "mA498l", label: "Cechesti - Jud. HR" },
  { id: "r6bQpr", label: "Cefa - Jud. BH" },
  { id: "ZP7dvr", label: "Cegani - Jud. IL" },
  { id: "ZV3Rxl", label: "Cehal - Jud. SM" },
  { id: "rERo5Z", label: "Cehalut - Jud. SM" },
  { id: "rKDkxZ", label: "Cehei - Jud. SJ" },
  { id: "r2Qx1m", label: "Cehetel - Jud. HR" },
  { id: "rKDN0Z", label: "Cehu Silvaniei - Jud. SJ" },
  { id: "r9qOdr", label: "Ceica - Jud. BH" },
  { id: "Zy6x4l", label: "Ceie - Jud. MS" },
  { id: "ZP2NMm", label: "Ceisoara - Jud. BH" },
  { id: "rqbDBZ", label: "Celaru - Jud. DJ" },
  { id: "mgxwBr", label: "Celei - Jud. GJ" },
  { id: "ZPa8Ml", label: "Celnata - Jud. MH" },
  { id: "lLJgWr", label: "Cenad - Jud. TM" },
  { id: "WZPJKm", label: "Cenade - Jud. AB" },
  { id: "rwnzvm", label: "Cenalos - Jud. BH" },
  { id: "ZzVL8Z", label: "Cenei - Jud. TM" },
  { id: "Vle3Or", label: "Cepari - Jud. AG" },
  { id: "rBygdm", label: "Cepari - Jud. BN" },
  { id: "l57xYr", label: "Cepari - Jud. OT" },
  { id: "yZyyLZ", label: "Ceparii Pamanteni - Jud. AG" },
  { id: "XlDLXl", label: "Ceparii Ungureni - Jud. AG" },
  { id: "lD63Gr", label: "Cepesti - Jud. OT" },
  { id: "mA9X2m", label: "Cepesti - Jud. VS" },
  { id: "lWO1Al", label: "Ceplea - Jud. GJ" },
  { id: "majEer", label: "Ceplenita - Jud. IS" },
  { id: "lOB1nZ", label: "Ceptura De Jos - Jud. PH" },
  { id: "ZyYD0Z", label: "Ceptura De Sus - Jud. PH" },
  { id: "lOAMwr", label: "Ceptureni - Jud. MH" },
  { id: "lexVgZ", label: "Ceranganul - Jud. MH" },
  { id: "ZyYO2Z", label: "Cerasu - Jud. PH" },
  { id: "ZQqkDZ", label: "Cerat - Jud. DJ" },
  { id: "rKnPQm", label: "Ceratu De Copacioasa - Jud. GJ" },
  { id: "m1qO8m", label: "Cerbal - Jud. HD" },
  { id: "l582AZ", label: "Cerbeni - Jud. OT" },
  { id: "mb96El", label: "Cerbesti - Jud. CJ" },
  { id: "rozW5m", label: "Cerbia - Jud. HD" },
  { id: "Rr9yMr", label: "Cerbu - Jud. AB" },
  { id: "AZv1wr", label: "Cerbu - Jud. AG" },
  { id: "rwnXym", label: "Cerbu - Jud. BT" },
  { id: "ZNJ0eZ", label: "Cerbu - Jud. TL" },
  { id: "mk5qOZ", label: "Cerbu - Jud. VN" },
  { id: "ElLp1l", label: "Cerbureni - Jud. AG" },
  { id: "lJWnAm", label: "Cerc - Jud. CJ" },
  { id: "lp1nMm", label: "Cerchejeni - Jud. BT" },
  { id: "ZNGWel", label: "Cerchezu - Jud. CT" },
  { id: "ZNAEar", label: "Cercu - Jud. IS" },
  { id: "JmAYam", label: "Cerdac - Jud. BC" },
  { id: "mbKaEr", label: "Ceretu - Jud. VL" },
  { id: "JmA1qm", label: "Cergau Mare - Jud. AB" },
  { id: "qZNY6r", label: "Cergau Mic - Jud. AB" },
  { id: "ZQVnJl", label: "Cerghid - Jud. MS" },
  { id: "r3BxVm", label: "Cerghizel - Jud. MS" },
  { id: "r9Dxam", label: "Cerisa - Jud. SJ" },
  { id: "lx7QDZ", label: "Cerisor - Jud. HD" },
  { id: "lpx5Pl", label: "Cermegesti - Jud. VL" },
  { id: "PrEXBm", label: "Cermei - Jud. AR" },
  { id: "rEaK5r", label: "Cerna - Jud. TL" },
  { id: "l0KdJZ", label: "Cerna - Jud. TM" },
  { id: "rwY7Vl", label: "Cerna - Jud. VL" },
  { id: "Zvo2Kl", label: "Cerna Sat - Jud. GJ" },
  { id: "l7wRdl", label: "Cerna Varf - Jud. MH" },
  { id: "r27DeZ", label: "Cernadia - Jud. GJ" },
  { id: "rq0NGm", label: "Cernaia - Jud. MH" },
  { id: "mAO96Z", label: "Cernat - Jud. CV" },
  { id: "mAOD8Z", label: "Cernat - Jud. DJ" },
  { id: "rwpjjZ", label: "Cernatesti - Jud. BZ" },
  { id: "r3G5er", label: "Cernatesti - Jud. DJ" },
  { id: "r2YDvm", label: "Cernavoda - Jud. CT" },
  { id: "ZM7D5m", label: "Cernele - Jud. DJ" },
  { id: "maNx9r", label: "Cernelele - Jud. VL" },
  { id: "leo0Yr", label: "Cernelele De Sus - Jud. DJ" },
  { id: "l0poBl", label: "Cernesti - Jud. BT" },
  { id: "r3B00m", label: "Cernesti - Jud. MM" },
  { id: "ZNwxLl", label: "Cernesti - Jud. PH" },
  { id: "leJ03m", label: "Cerneteaz - Jud. TM" },
  { id: "mGPvWr", label: "Cerneti - Jud. MH" },
  { id: "r6xvgl", label: "Cernetu - Jud. TR" },
  { id: "m1GjdZ", label: "Cernica - Jud. IF" },
  { id: "rXx37r", label: "Cernisoara - Jud. VL" },
  { id: "rE20QZ", label: "Cernisoara Florese - Jud. HD" },
  { id: "r82B6l", label: "Cernu - Jud. BC" },
  { id: "robDXm", label: "Cernuc - Jud. SJ" },
  { id: "JlJV4l", label: "Cersani - Jud. AG" },
  { id: "WmG5wZ", label: "Certege - Jud. AB" },
  { id: "ZVwXWl", label: "Certeju De Jos - Jud. HD" },
  { id: "lODJKm", label: "Certeju De Sus - Jud. HD" },
  { id: "r6qYpm", label: "Certesti - Jud. GL" },
  { id: "lJ9OYZ", label: "Certeze - Jud. SM" },
  { id: "lD6YWr", label: "Certieni - Jud. NT" },
  { id: "Wr6WgZ", label: "Ceru Bacainti - Jud. AB" },
  { id: "ZR68LZ", label: "Cervenia - Jud. TR" },
  { id: "ZNYner", label: "Cervenita - Jud. MH" },
  { id: "leakJl", label: "Cervicesti - Jud. BT" },
  { id: "lp1kxm", label: "Cervicesti Deal - Jud. BT" },
  { id: "Zy2nLl", label: "Cetan - Jud. CJ" },
  { id: "mgPv7r", label: "Cetariu - Jud. BH" },
  { id: "m1DNRl", label: "Cetate - Jud. DJ" },
  { id: "r3kAzr", label: "Cetatea - Jud. CT" },
  { id: "leoDYr", label: "Cetatea - Jud. GJ" },
  { id: "Z4qJzl", label: "Cetatea - Jud. GR" },
  { id: "mA9xgm", label: "Cetatea - Jud. TR" },
  { id: "Rr9WNl", label: "Cetatea De Balta - Jud. AB" },
  { id: "rK3bxr", label: "Cetatea Veche - Jud. CL" },
  { id: "ZPG7Ar", label: "Cetateaua - Jud. VL" },
  { id: "rK2D9m", label: "Cetatele - Jud. MM" },
  { id: "wlp8Wl", label: "Cetateni - Jud. AG" },
  { id: "rwnVym", label: "Cetatuia - Jud. BC" },
  { id: "r6q5pm", label: "Cetatuia - Jud. DB" },
  { id: "ZPBdMm", label: "Cetatuia - Jud. DJ" },
  { id: "mkjXOl", label: "Cetatuia - Jud. GR" },
  { id: "lx76zZ", label: "Cetatuia - Jud. HR" },
  { id: "ZyWVYm", label: "Cetatuia - Jud. VS" },
  { id: "4lOYwr", label: "Cetea - Jud. AB" },
  { id: "r82pel", label: "Cetea - Jud. BH" },
  { id: "r3BKnm", label: "Ceuas - Jud. MS" },
  { id: "l7wLAl", label: "Ceuasu De Campie - Jud. MS" },
  { id: "rdxWGr", label: "Cezieni - Jud. OT" },
  { id: "lYJMxm", label: "Charlotenburg - Jud. TM" },
  { id: "lxEpnm", label: "Checea - Jud. TM" },
  { id: "lLJ3Wr", label: "Cheches - Jud. TM" },
  { id: "Zv5oJr", label: "Chechis - Jud. MM" },
  { id: "mgBN5l", label: "Chechis - Jud. SJ" },
  { id: "ZVw8Bl", label: "Chedia Mare - Jud. HR" },
  { id: "lJxd4r", label: "Chedia Mica - Jud. HR" },
  { id: "rBng2r", label: "Chegea - Jud. SM" },
  { id: "m1bx8r", label: "Cheglevici - Jud. TM" },
  { id: "yZydKr", label: "Cheia - Jud. AB" },
  { id: "rByA5m", label: "Cheia - Jud. BV" },
  { id: "rE71Bl", label: "Cheia - Jud. CJ" },
  { id: "lO6ewZ", label: "Cheia - Jud. CT" },
  { id: "lWD5Am", label: "Cheia - Jud. PH" },
  { id: "mk13BZ", label: "Cheia - Jud. VL" },
  { id: "yZRJyZ", label: "Cheile Cibului - Jud. AB" },
  { id: "mgaXAZ", label: "Chelbesti - Jud. OT" },
  { id: "olWOAl", label: "Cheleteni - Jud. AB" },
  { id: "lLVa6r", label: "Chelinta - Jud. MM" },
  { id: "rKjGGm", label: "Chelis - Jud. BT" },
  { id: "JmAegm", label: "Chelmac - Jud. AR" },
  { id: "mb522l", label: "Chendrea - Jud. SJ" },
  { id: "leG9dr", label: "Chendremal - Jud. SJ" },
  { id: "lLVGyr", label: "Chendu - Jud. MS" },
  { id: "ZzPz3m", label: "Cherasti - Jud. VL" },
  { id: "rKD6RZ", label: "Cherba - Jud. PH" },
  { id: "mbPgdZ", label: "Cherechiu - Jud. BH" },
  { id: "grKgEr", label: "Cherelus - Jud. AR" },
  { id: "mgPe7r", label: "Cheresig - Jud. BH" },
  { id: "rEavBr", label: "Cherestur - Jud. TM" },
  { id: "l7yxnr", label: "Chereusa - Jud. SM" },
  { id: "rBb4Jr", label: "Cherghes - Jud. HD" },
  { id: "lxxp0l", label: "Cheriu - Jud. BH" },
  { id: "lJ9xgZ", label: "Cherlestii Din Deal - Jud. OT" },
  { id: "lYGK0r", label: "Cherlestii Mosteni - Jud. OT" },
  { id: "rBRqJr", label: "Chersacosu - Jud. VS" },
  { id: "rKj5Pm", label: "Chesa - Jud. BH" },
  { id: "rwpdKZ", label: "Chesau - Jud. CJ" },
  { id: "rEQqLr", label: "Chesereu - Jud. BH" },
  { id: "4lOynr", label: "Chesint - Jud. AR" },
  { id: "r86o0l", label: "Chesler - Jud. SB" },
  { id: "lYGVOr", label: "Chesnoiu - Jud. PH" },
  { id: "rqPNvm", label: "Chet - Jud. BH" },
  { id: "mGP4Br", label: "Chetani - Jud. MS" },
  { id: "mgPK5r", label: "Chetiu - Jud. BN" },
  { id: "lJQW3Z", label: "Chetreni - Jud. BC" },
  { id: "lLJL1r", label: "Chetresti - Jud. VS" },
  { id: "lYDJOZ", label: "Chetris - Jud. BC" },
  { id: "ZMJagl", label: "Chetrosu - Jud. VS" },
  { id: "mGWE0m", label: "Cheud - Jud. SJ" },
  { id: "rXagDZ", label: "Cheveresu Mare - Jud. TM" },
  { id: "r8B3Xl", label: "Chiajna - Jud. IF" },
  { id: "maxzel", label: "Chibed - Jud. MS" },
  { id: "lWMABr", label: "Chiceni - Jud. VL" },
  { id: "lYDyAZ", label: "Chicerea - Jud. BC" },
  { id: "rqEY1r", label: "Chicerea - Jud. IS" },
  { id: "l07jRZ", label: "Chicerea - Jud. NT" },
  { id: "lW1Kkr", label: "Chichinetu - Jud. BR" },
  { id: "ZNGJyl", label: "Chichis - Jud. CV" },
  { id: "l0wz6Z", label: "Chichisa - Jud. SJ" },
  { id: "r3QQzl", label: "Chiciora - Jud. GJ" },
  { id: "mkzqbl", label: "Chiciureni - Jud. PH" },
  { id: "ZRJPyZ", label: "Chidea - Jud. CJ" },
  { id: "Oro5ql", label: "Chier - Jud. AR" },
  { id: "rKDwEZ", label: "Chiesd - Jud. SJ" },
  { id: "rwP1KZ", label: "Chiheru De Jos - Jud. MS" },
  { id: "lj3vKm", label: "Chiheru De Sus - Jud. MS" },
  { id: "lYD7JZ", label: "Chijic - Jud. BH" },
  { id: "rBbB2r", label: "Chileni - Jud. HR" },
  { id: "l7wGXl", label: "Chilia - Jud. NT" },
  { id: "r9D1dm", label: "Chilia - Jud. OT" },
  { id: "lj4Kqr", label: "Chilia - Jud. SM" },
  { id: "ZRjwAZ", label: "Chilia Benei - Jud. BC" },
  { id: "ZMJ58l", label: "Chilia Veche - Jud. TL" },
  { id: "ZPBBEm", label: "Chilieni - Jud. CV" },
  { id: "mk1BqZ", label: "Chilieni - Jud. VS" },
  { id: "Wr6YDm", label: "Chilii - Jud. AG" },
  { id: "lOAaNr", label: "Chilii - Jud. NT" },
  { id: "l0wBNZ", label: "Chilii - Jud. OT" },
  { id: "rBWvDZ", label: "Chiliile - Jud. BZ" },
  { id: "robM8m", label: "Chilioara - Jud. SJ" },
  { id: "leJzPm", label: "Chiliseni - Jud. SV" },
  { id: "mA4jKl", label: "Chilisoaia - Jud. IS" },
  { id: "ZvoWwl", label: "Chiliu - Jud. GJ" },
  { id: "lYKRAr", label: "Chimindia - Jud. HD" },
  { id: "lD6kYr", label: "Chinari - Jud. MS" },
  { id: "rdx37r", label: "Chincius - Jud. MS" },
  { id: "l51PEl", label: "Chintelnic - Jud. BN" },
  { id: "ZQvRnl", label: "Chinteni - Jud. CJ" },
  { id: "mnEOjl", label: "Chintesti - Jud. OT" },
  { id: "roEX5l", label: "Chintinici - Jud. NT" },
  { id: "r92M8Z", label: "Chinusu - Jud. HR" },
  { id: "rdjRGl", label: "Chioag - Jud. BH" },
  { id: "ljGJKm", label: "Chiochis - Jud. BN" },
  { id: "mkwxqm", label: "Chioibasesti - Jud. BR" },
  { id: "rKDePZ", label: "Chiojdeanca - Jud. PH" },
  { id: "Zy5O8Z", label: "Chiojdeni - Jud. VN" },
  { id: "rqzjvZ", label: "Chiojdu - Jud. BZ" },
  { id: "Z43xAr", label: "Chiperesti - Jud. IS" },
  { id: "mGwDBm", label: "Chiperu - Jud. BZ" },
  { id: "lxbXzr", label: "Chiraftei - Jud. GL" },
  { id: "lJQXgZ", label: "Chirales - Jud. BN" },
  { id: "lp1gPm", label: "Chiraleu - Jud. BH" },
  { id: "rBOJJZ", label: "Chirca - Jud. DB" },
  { id: "mGaL2Z", label: "Chircesti - Jud. VS" },
  { id: "rKPJeZ", label: "Chirculesti - Jud. GR" },
  { id: "m1b99r", label: "Chirculesti - Jud. VL" },
  { id: "l526Rm", label: "Chiriacu - Jud. GR" },
  { id: "lJQ0YZ", label: "Chiribis - Jud. BH" },
  { id: "mgJd5l", label: "Chiricani - Jud. VN" },
  { id: "lO7Wol", label: "Chiricesti - Jud. VL" },
  { id: "ZR34vl", label: "Chiril - Jud. SV" },
  { id: "lD6kJr", label: "Chirileu - Jud. MS" },
  { id: "lJW7Wm", label: "Chiris - Jud. CJ" },
  { id: "mnEG8l", label: "Chiriteni - Jud. NT" },
  { id: "7rqY9r", label: "Chiritesti - Jud. AG" },
  { id: "r86dVl", label: "Chiritesti - Jud. PH" },
  { id: "r3kQGr", label: "Chirlesti - Jud. BZ" },
  { id: "r8YDqZ", label: "Chirnogeni - Jud. CT" },
  { id: "ZPeLKl", label: "Chirnogi - Jud. CL" },
  { id: "r3Q5Yl", label: "Chiroiu Pamanteni - Jud. IL" },
  { id: "m1qNYm", label: "Chiroiu Satu Nou - Jud. IL" },
  { id: "lODkom", label: "Chiroiu Ungureni - Jud. IL" },
  { id: "rBnKDr", label: "Chirpar - Jud. SB" },
  { id: "l57gRr", label: "Chisalita - Jud. MS" },
  { id: "m1Bggr", label: "Chisau - Jud. SM" },
  { id: "lODgnm", label: "Chiscadaga - Jud. HD" },
  { id: "r3kkVr", label: "Chiscani - Jud. BR" },
  { id: "m1kv9Z", label: "Chiscareni - Jud. BT" },
  { id: "ZzwaWl", label: "Chiscareni - Jud. IS" },
  { id: "lDAM2m", label: "Chiscau - Jud. BH" },
  { id: "r6OegZ", label: "Chiselet - Jud. CL" },
  { id: "LZMX5r", label: "Chisindia - Jud. AR" },
  { id: "XlxRwr", label: "Chisineu Cris - Jud. AR" },
  { id: "Zv263Z", label: "Chisirid - Jud. BH" },
  { id: "yZR0Dm", label: "Chislaca - Jud. AR" },
  { id: "lLe9ym", label: "Chislaz - Jud. BH" },
  { id: "lDaRbm", label: "Chisoda - Jud. TM" },
  { id: "ZzEp8r", label: "Chistag - Jud. BH" },
  { id: "AZQpgr", label: "Chitani - Jud. AG" },
  { id: "r65ppm", label: "Chitcani - Jud. VN" },
  { id: "rqOy9l", label: "Chitcani - Jud. VS" },
  { id: "ZVdxBm", label: "Chiteasca - Jud. OT" },
  { id: "VrXbLl", label: "Chitesti - Jud. AG" },
  { id: "mgPBDr", label: "Chiticeni - Jud. BC" },
  { id: "mka6OZ", label: "Chitid - Jud. HD" },
  { id: "rqE2Pr", label: "Chitila - Jud. IF" },
  { id: "r998Nr", label: "Chitoc - Jud. VS" },
  { id: "mGWDBm", label: "Chitorani - Jud. PH" },
  { id: "l7vLAZ", label: "Chitoveni - Jud. BT" },
  { id: "r3kbYr", label: "Chiuiesti - Jud. CJ" },
  { id: "ZM778m", label: "Chiurus - Jud. CV" },
  { id: "rwnMam", label: "Chiuza - Jud. BN" },
  { id: "rXOd3m", label: "Chiuzbaia - Jud. MM" },
  { id: "mk1LBZ", label: "Chizatau - Jud. TM" },
  { id: "lJ9LgZ", label: "Chizeni - Jud. SJ" },
  { id: "lWv5yZ", label: "Ciacova - Jud. TM" },
  { id: "mbOKQr", label: "Ciaracio - Jud. HR" },
  { id: "krd5zZ", label: "Cib - Jud. AB" },
  { id: "lj54LZ", label: "Ciba - Jud. HR" },
  { id: "mnELAl", label: "Ciba - Jud. MS" },
  { id: "l7wBql", label: "Cibu - Jud. MS" },
  { id: "olWLdm", label: "Cicanesti - Jud. AG" },
  { id: "LmndGZ", label: "Cicard - Jud. AB" },
  { id: "m1Gb8Z", label: "Cicarlau - Jud. MM" },
  { id: "ElLxYl", label: "Cicau - Jud. AB" },
  { id: "mgLo1r", label: "Ciceu - Jud. HR" },
  { id: "mk8YOr", label: "Ciceu Corabia - Jud. BN" },
  { id: "mG5b0Z", label: "Ciceu Giurgesti - Jud. BN" },
  { id: "lWB8NZ", label: "Ciceu Mihaiesti - Jud. BN" },
  { id: "ZRjVqZ", label: "Ciceu Poieni - Jud. BN" },
  { id: "7rqRQZ", label: "Cicir - Jud. AR" },
  { id: "r3kqer", label: "Cicleni - Jud. CS" },
  { id: "mb90Yl", label: "Ciclova Montana - Jud. CS" },
  { id: "ZzoxDl", label: "Ciclova Romana - Jud. CS" },
  { id: "lpAWom", label: "Cidreag - Jud. SM" },
  { id: "PrEALr", label: "Ciesti - Jud. AG" },
  { id: "r9Dw8m", label: "Cig - Jud. SM" },
  { id: "lL7bbZ", label: "Ciglean - Jud. SJ" },
  { id: "rBbNVr", label: "Cigmau - Jud. HD" },
  { id: "ZVKzBl", label: "Cihei - Jud. BH" },
  { id: "WmGdDr", label: "Cil - Jud. AR" },
  { id: "ZQv7nl", label: "Cilibia - Jud. BZ" },
  { id: "r9288Z", label: "Cilibiu - Jud. IS" },
  { id: "ZyYY4Z", label: "Cilieni - Jud. OT" },
  { id: "rBbvdr", label: "Cimpa - Jud. HD" },
  { id: "lJxqWr", label: "Cincis Cerna - Jud. HD" },
  { id: "lYDgEZ", label: "Cincsor - Jud. BV" },
  { id: "r9q6qr", label: "Cincu - Jud. BV" },
  { id: "ropVLZ", label: "Cinghiniia - Jud. BT" },
  { id: "lD601r", label: "Cinta - Jud. MS" },
  { id: "0lYWxr", label: "Cintei - Jud. AR" },
  { id: "LZMprb", label: "Cioara De Sus - Jud. AB" },
  { id: "lxA4zm", label: "Cioarga - Jud. MS" },
  { id: "rwVMVm", label: "Cioatele - Jud. VS" },
  { id: "5l7ovl", label: "Ciobanesti - Jud. AG" },
  { id: "yZyB2l", label: "Ciobani - Jud. AG" },
  { id: "lx7EMZ", label: "Ciobanis - Jud. HR" },
  { id: "lWeKYr", label: "Ciobanita - Jud. CT" },
  { id: "lOWKnl", label: "Ciobanoaia - Jud. BZ" },
  { id: "l5243m", label: "Ciobanu - Jud. CT" },
  { id: "EZzbKZ", label: "Ciobanus - Jud. BC" },
  { id: "rq0DQm", label: "Ciobotani - Jud. MS" },
  { id: "l78Jdm", label: "Cioboti - Jud. VL" },
  { id: "r924dZ", label: "Cioca Boca - Jud. IS" },
  { id: "lpERMm", label: "Ciocadia - Jud. GJ" },
  { id: "r33vzr", label: "Ciocaia - Jud. BH" },
  { id: "ZvOExl", label: "Ciocaltei - Jud. VL" },
  { id: "olWYYr", label: "Ciocanai - Jud. AG" },
  { id: "l52jRm", label: "Ciocanari - Jud. DB" },
  { id: "rXxyDr", label: "Ciocanari - Jud. VL" },
  { id: "mnx4qZ", label: "Ciocanele - Jud. DJ" },
  { id: "LZMLGr", label: "Ciocanesti - Jud. AG" },
  { id: "rd5KWZ", label: "Ciocanesti - Jud. BZ" },
  { id: "mGwb2m", label: "Ciocanesti - Jud. CL" },
  { id: "lLDMGl", label: "Ciocanesti - Jud. DB" },
  { id: "lLD9Pl", label: "Ciocanesti - Jud. DJ" },
  { id: "ZzDRDm", label: "Ciocanesti - Jud. OT" },
  { id: "rKDAGZ", label: "Ciocanesti - Jud. SV" },
  { id: "ZzV5DZ", label: "Ciocani - Jud. VS" },
  { id: "XlDzXr", label: "Ciocanu - Jud. AG" },
  { id: "mGx7dr", label: "Ciocarlesti - Jud. IS" },
  { id: "l0X8gl", label: "Ciocarlia - Jud. CT" },
  { id: "lJxLpr", label: "Ciocarlia - Jud. IL" },
  { id: "r8Deer", label: "Ciocarlia De Sus - Jud. CT" },
  { id: "krwjom", label: "Ciocasu - Jud. AB" },
  { id: "mgBp9l", label: "Cioceni - Jud. PH" },
  { id: "0lYwwr", label: "Ciocesti - Jud. AG" },
  { id: "lx737Z", label: "Ciochina - Jud. IL" },
  { id: "rBj5Dr", label: "Ciochiuta - Jud. MH" },
  { id: "rwppoZ", label: "Ciocile - Jud. BR" },
  { id: "mnQ9Al", label: "Cioclovina - Jud. HD" },
  { id: "ZvEq9r", label: "Ciocmani - Jud. SJ" },
  { id: "r6GqWZ", label: "Ciocotis - Jud. MM" },
  { id: "rER3xZ", label: "Ciocrac - Jud. PH" },
  { id: "lLJp6r", label: "Ciofeni - Jud. VS" },
  { id: "mGPV6r", label: "Cioflanu - Jud. OT" },
  { id: "ZMVORl", label: "Cioflinceni - Jud. IF" },
  { id: "NmkVol", label: "Ciofrangeni - Jud. AG" },
  { id: "l7JkdZ", label: "Ciohorani - Jud. IS" },
  { id: "ZQw5vm", label: "Ciolanesti - Jud. VN" },
  { id: "r306GZ", label: "Ciolanestii Din Deal - Jud. TR" },
  { id: "lOY7Ar", label: "Ciolanestii Din Vale - Jud. TR" },
  { id: "OrojLm", label: "Ciolcesti - Jud. AG" },
  { id: "lOAeGr", label: "Ciolpani - Jud. IF" },
  { id: "rBjygr", label: "Ciolt - Jud. MM" },
  { id: "LmnAal", label: "Ciomagesti - Jud. AG" },
  { id: "l79wnr", label: "Ciomartan - Jud. SV" },
  { id: "leGKOr", label: "Cionchesti - Jud. SM" },
  { id: "7rqzvZ", label: "Cionesti - Jud. AB" },
  { id: "r6qEWm", label: "Cionti - Jud. GJ" },
  { id: "mka2BZ", label: "Ciopeia - Jud. HD" },
  { id: "lYPJRZ", label: "Cioponesti - Jud. VL" },
  { id: "ZvEoVr", label: "Cioraca - Jud. OT" },
  { id: "maLX7l", label: "Cioranca - Jud. BZ" },
  { id: "r2qnQm", label: "Ciorani - Jud. PH" },
  { id: "ZVk9ol", label: "Ciorani - Jud. VN" },
  { id: "rKD99Z", label: "Cioranii De Jos - Jud. PH" },
  { id: "r2qxdm", label: "Cioranii De Sus - Jud. PH" },
  { id: "mbOOYr", label: "Ciorari - Jud. GJ" },
  { id: "r27kgZ", label: "Ciorasti - Jud. GL" },
  { id: "ZNDDem", label: "Ciorasti - Jud. VL" },
  { id: "rK6eEm", label: "Ciorasti - Jud. VN" },
  { id: "ZPa5Al", label: "Ciornei - Jud. NT" },
  { id: "lYn8Jm", label: "Cioroboreni - Jud. MH" },
  { id: "Zy6z4l", label: "Ciorogarla - Jud. IF" },
  { id: "lO6kNZ", label: "Cioroiasi - Jud. DJ" },
  { id: "rX7Vem", label: "Cioroiasu - Jud. OT" },
  { id: "Z49vAl", label: "Cioroiu - Jud. OT" },
  { id: "mAOKoZ", label: "Cioroiu Nou - Jud. DJ" },
  { id: "ZQv2gl", label: "Ciortea - Jud. CS" },
  { id: "Zvo89l", label: "Ciortesti - Jud. IS" },
  { id: "lJ6K4l", label: "Ciortesti - Jud. VL" },
  { id: "rBRzJr", label: "Ciortolom - Jud. VS" },
  { id: "l51REl", label: "Ciosa - Jud. BN" },
  { id: "rK2WWm", label: "Ciovarnasani - Jud. MH" },
  { id: "ZMVkjl", label: "Cipaieni - Jud. MS" },
  { id: "lj3Pqm", label: "Cipau - Jud. MS" },
  { id: "mGW2pm", label: "Ciprian Porumbescu - Jud. SV" },
  { id: "mG5k0Z", label: "Cireasi - Jud. BN" },
  { id: "lD682r", label: "Cireasov - Jud. OT" },
  { id: "Z4wajr", label: "Ciresa - Jud. CS" },
  { id: "rwE7Kl", label: "Ciresanu - Jud. PH" },
  { id: "lJW09m", label: "Ciresel - Jud. CS" },
  { id: "rKn6Wm", label: "Cireseni - Jud. HR" },
  { id: "mbOVQr", label: "Cireseni - Jud. IS" },
  { id: "qZN6xZ", label: "Ciresoaia - Jud. BC" },
  { id: "m1kwqZ", label: "Ciresoaia - Jud. BN" },
  { id: "7rqGvl", label: "Ciresu - Jud. AG" },
  { id: "rBWOdZ", label: "Ciresu - Jud. BR" },
  { id: "Zy2w8l", label: "Ciresu - Jud. BZ" },
  { id: "rwP03Z", label: "Ciresu - Jud. MH" },
  { id: "rqOLQl", label: "Ciresu - Jud. TM" },
  { id: "r366em", label: "Ciresu - Jud. VL" },
  { id: "ZMJgzl", label: "Ciresu Mic - Jud. TM" },
  { id: "lLJonr", label: "Ciresul - Jud. VL" },
  { id: "ZNYLYr", label: "Ciretea - Jud. MS" },
  { id: "l7wY3l", label: "Cirhagau - Jud. MS" },
  { id: "ljGO4m", label: "Ciritei - Jud. BT" },
  { id: "rK2E9m", label: "Ciritei - Jud. NT" },
  { id: "r3knYr", label: "Cislau - Jud. BZ" },
  { id: "lYDBOZ", label: "Cismanesti - Jud. BT" },
  { id: "NmkDqr", label: "Cismea - Jud. AG" },
  { id: "mbPbaZ", label: "Cismea - Jud. BT" },
  { id: "ZNJXaZ", label: "Cismeaua Noua - Jud. TL" },
  { id: "ZPBPjm", label: "Cismele - Jud. GL" },
  { id: "ZyYk0Z", label: "Cisnadie - Jud. SB" },
  { id: "rKD1RZ", label: "Cisnadioara - Jud. SB" },
  { id: "Rr9M8r", label: "Cistei - Jud. AB" },
  { id: "Rr9qxr", label: "Cisteiu De Mures - Jud. AB" },
  { id: "Zy2zKl", label: "Cistia - Jud. BR" },
  { id: "leLb3Z", label: "Ciubanca - Jud. CJ" },
  { id: "lpzq2r", label: "Ciubancuta - Jud. CJ" },
  { id: "mgPa9r", label: "Ciucani - Jud. BC" },
  { id: "ZzwpDl", label: "Ciucani - Jud. HR" },
  { id: "m1n9Yr", label: "Ciucea - Jud. CJ" },
  { id: "mGgaWm", label: "Ciucheti - Jud. VL" },
  { id: "rXLX7r", label: "Ciuchici - Jud. CS" },
  { id: "ZyG7Km", label: "Ciucsangeorgiu - Jud. HR" },
  { id: "wmgaEZ", label: "Ciuculesti - Jud. AB" },
  { id: "lpVjpr", label: "Ciucurova - Jud. TL" },
  { id: "Z4wM1r", label: "Ciudanovita - Jud. CS" },
  { id: "rwnyKm", label: "Ciughes - Jud. BC" },
  { id: "2l5WAr", label: "Ciugud - Jud. AB" },
  { id: "VleVOm", label: "Ciugudu De Jos - Jud. AB" },
  { id: "wlpyWm", label: "Ciugudu De Sus - Jud. AB" },
  { id: "XlDKnZ", label: "Ciuguzel - Jud. AB" },
  { id: "mG53pZ", label: "Ciuhoi - Jud. BH" },
  { id: "ZvEYVr", label: "Ciula - Jud. SJ" },
  { id: "r3Qw5l", label: "Ciula Mare - Jud. HD" },
  { id: "m1q73m", label: "Ciula Mica - Jud. HD" },
  { id: "NmkabZ", label: "Ciuldesti - Jud. AB" },
  { id: "ZMV5jl", label: "Ciulea - Jud. MS" },
  { id: "ZvAnJr", label: "Ciuleni - Jud. CJ" },
  { id: "ZP2zbm", label: "Ciulesti - Jud. BH" },
  { id: "Bma29r", label: "Ciulnita - Jud. AG" },
  { id: "lWOqol", label: "Ciulnita - Jud. IL" },
  { id: "Zvo92l", label: "Ciulpaz - Jud. HD" },
  { id: "Z4wz8r", label: "Ciumafaia - Jud. CJ" },
  { id: "Zy52LZ", label: "Ciumagi - Jud. VL" },
  { id: "rKn8km", label: "Ciumani - Jud. HR" },
  { id: "ZR3ELl", label: "Ciumarna - Jud. SJ" },
  { id: "ZvzoPZ", label: "Ciumarna - Jud. SV" },
  { id: "rqPPom", label: "Ciumasi - Jud. BC" },
  { id: "yZyDr3", label: "Ciumbrud - Jud. AB" },
  { id: "ZzEnWr", label: "Ciumeghiu - Jud. BH" },
  { id: "lDqN2m", label: "Ciumesti - Jud. SM" },
  { id: "lpbe6r", label: "Ciumita - Jud. HD" },
  { id: "r2MQ1m", label: "Ciumulesti - Jud. SV" },
  { id: "mGxGBr", label: "Ciungani - Jud. HD" },
  { id: "ljo94m", label: "Ciungetu - Jud. VL" },
  { id: "r8Oq6m", label: "Ciungu Mare - Jud. HD" },
  { id: "krd34m", label: "Ciuntesti - Jud. AR" },
  { id: "4lOQNr", label: "Ciupa Manciulescu - Jud. AG" },
  { id: "ZyY7DZ", label: "Ciupelnita - Jud. PH" },
  { id: "ZzVXWZ", label: "Ciuperca - Jud. VS" },
  { id: "lpb5er", label: "Ciuperceni - Jud. GJ" },
  { id: "ZMGw8m", label: "Ciuperceni - Jud. SM" },
  { id: "mGaJ0Z", label: "Ciuperceni - Jud. TR" },
  { id: "ZyNR8l", label: "Ciupercenii De Oltet - Jud. GJ" },
  { id: "ZyN1ql", label: "Ciupercenii Noi - Jud. DJ" },
  { id: "ZQqBpZ", label: "Ciupercenii Vechi - Jud. DJ" },
  { id: "r6xN9l", label: "Ciurari - Jud. TR" },
  { id: "ZVy66r", label: "Ciurari Deal - Jud. TR" },
  { id: "rKnGkm", label: "Ciurbesti - Jud. IS" },
  { id: "lj5QKZ", label: "Ciurea - Jud. IS" },
  { id: "mnEV8l", label: "Ciurea - Jud. NT" },
  { id: "ZzzNBZ", label: "Ciureni - Jud. SJ" },
  { id: "Jr39ql", label: "Ciuresti - Jud. AG" },
  { id: "lpE7pm", label: "Ciuresti - Jud. GL" },
  { id: "ZMVvQl", label: "Ciuresti - Jud. OT" },
  { id: "lWeqBr", label: "Ciurestii Noi - Jud. GL" },
  { id: "mb9jKl", label: "Ciurgau - Jud. CJ" },
  { id: "rE60VZ", label: "Ciurgau - Jud. MS" },
  { id: "lOW5ol", label: "Ciurila - Jud. CJ" },
  { id: "wmg65l", label: "Ciuruleasa - Jud. AB" },
  { id: "r2bobr", label: "Ciuslea - Jud. VN" },
  { id: "lDD5Jl", label: "Ciuta - Jud. BZ" },
  { id: "rK3oWr", label: "Ciuta - Jud. CS" },
  { id: "rBjWqr", label: "Ciuta - Jud. MM" },
  { id: "lYDRQZ", label: "Ciutelec - Jud. BH" },
  { id: "ZRBwjZ", label: "Ciutesti - Jud. VL" },
  { id: "l52Pvm", label: "Ciutura - Jud. DJ" },
  { id: "lp1E6m", label: "Ciuturesti - Jud. BC" },
  { id: "lDq9Ym", label: "Cizer - Jud. SJ" },
  { id: "Jr3w5r", label: "Cladova - Jud. AR" },
  { id: "l79bAr", label: "Cladova - Jud. TM" },
  { id: "mGag2Z", label: "Clanita - Jud. TR" },
  { id: "mkwOQm", label: "Clapa - Jud. CJ" },
  { id: "leLYOZ", label: "Clatesti - Jud. CL" },
  { id: "mnxNqZ", label: "Cleanov - Jud. DJ" },
  { id: "r6b5Xr", label: "Cleja - Jud. BC" },
  { id: "m1DPdl", label: "Clejani - Jud. GR" },
  { id: "l5QBNl", label: "Clesnesti - Jud. GJ" },
  { id: "mGavBZ", label: "Cliciova - Jud. TM" },
  { id: "ZyW2qm", label: "Climauti - Jud. SV" },
  { id: "krw6om", label: "Climesti - Jud. BC" },
  { id: "ZVd5Am", label: "Climesti - Jud. NT" },
  { id: "rK2aem", label: "Clinceni - Jud. IF" },
  { id: "l7805m", label: "Clipicesti - Jud. VN" },
  { id: "WZPoBr", label: "Clit - Jud. AR" },
  { id: "lj40Kr", label: "Clit - Jud. SJ" },
  { id: "mnz58m", label: "Clit - Jud. SV" },
  { id: "ZPakBl", label: "Cloasterf - Jud. MS" },
  { id: "m1n7jr", label: "Clocotici - Jud. CS" },
  { id: "rXLNRr", label: "Clondiru - Jud. BZ" },
  { id: "r97VXl", label: "Clondiru De Sus - Jud. BZ" },
  { id: "rKxz9Z", label: "Clopodia - Jud. TM" },
  { id: "mGxoBr", label: "Clopotiva - Jud. HD" },
  { id: "lj5G1Z", label: "Closani - Jud. GJ" },
  { id: "r27eQZ", label: "Closca - Jud. CT" },
  { id: "ZVyRdr", label: "Closca - Jud. TL" },
  { id: "Lmn0qZ", label: "Clucereasa - Jud. AG" },
  { id: "r3k1Gr", label: "Cluj Napoca - Jud. CJ" },
  { id: "rXOWLm", label: "Coada Cornetului - Jud. MH" },
  { id: "ZzgGbl", label: "Coada Izvorului - Jud. DB" },
  { id: "ZQa5nZ", label: "Coada Izvorului - Jud. PH" },
  { id: "Z49oql", label: "Coada Malului - Jud. PH" },
  { id: "ZM9dgZ", label: "Coada Stancii - Jud. IS" },
  { id: "lJxXer", label: "Coaja - Jud. HD" },
  { id: "ZP70Kr", label: "Coarnele Caprei - Jud. IS" },
  { id: "ZMV75l", label: "Coas - Jud. MM" },
  { id: "r6bn6r", label: "Coasta - Jud. BN" },
  { id: "r97zal", label: "Coasta - Jud. CJ" },
  { id: "mA818l", label: "Coasta - Jud. VL" },
  { id: "m18bRl", label: "Coasta Cerbului - Jud. VL" },
  { id: "ZNYexr", label: "Coasta Grindului - Jud. MS" },
  { id: "Wr6p3l", label: "Coasta Hentii - Jud. AB" },
  { id: "ZQqEgZ", label: "Coasta Lupei - Jud. GL" },
  { id: "m1qejm", label: "Coasta Magurii - Jud. IS" },
  { id: "lOARLr", label: "Coasta Mare - Jud. MS" },
  { id: "rEayxr", label: "Coasta Mare - Jud. VL" },
  { id: "krwnym", label: "Coasta Vascului - Jud. AB" },
  { id: "ZRxJNm", label: "Cobadin - Jud. CT" },
  { id: "lYKVEr", label: "Cobatesti - Jud. HR" },
  { id: "ZVL66m", label: "Cobiuta - Jud. DB" },
  { id: "Rr973l", label: "Cobles - Jud. AB" },
  { id: "Zye0qZ", label: "Cobor - Jud. BV" },
  { id: "ma5zbm", label: "Coca - Jud. SM" },
  { id: "lOWMol", label: "Coca Antimiresti - Jud. BZ" },
  { id: "Zy2qLl", label: "Coca Niculesti - Jud. BZ" },
  { id: "maoXeZ", label: "Cocani - Jud. DB" },
  { id: "rodx3Z", label: "Cocarceni - Jud. BZ" },
  { id: "lYGMXr", label: "Coceana - Jud. PH" },
  { id: "Rr98Om", label: "Cocenesti - Jud. AG" },
  { id: "4lO6oZ", label: "Cocesti - Jud. AB" },
  { id: "Jr3jqm", label: "Cochinesti - Jud. AG" },
  { id: "m1nJYr", label: "Cochirleanca - Jud. BZ" },
  { id: "lxbxMr", label: "Cochirleni - Jud. CT" },
  { id: "mAAbBm", label: "Cociu - Jud. BC" },
  { id: "leaYdl", label: "Cociu - Jud. BN" },
  { id: "XlxQPm", label: "Cociuba - Jud. AR" },
  { id: "Z4YBAl", label: "Cociuba Mare - Jud. BH" },
  { id: "l7vnwZ", label: "Cociuba Mica - Jud. BH" },
  { id: "lpzQMr", label: "Coconari - Jud. BZ" },
  { id: "lx83Ql", label: "Coconi - Jud. CL" },
  { id: "mkav2Z", label: "Cocora - Jud. IL" },
  { id: "leGL6r", label: "Cocorasti - Jud. OT" },
  { id: "ZMGjGm", label: "Cocorastii Caplii - Jud. PH" },
  { id: "lJ9deZ", label: "Cocorastii Colt - Jud. PH" },
  { id: "mAGwWZ", label: "Cocorastii Grind - Jud. PH" },
  { id: "ZV30dl", label: "Cocorastii Mislii - Jud. PH" },
  { id: "Zzgbnl", label: "Cocoreni - Jud. GJ" },
  { id: "lWOOol", label: "Cocorova - Jud. GJ" },
  { id: "r2w0WZ", label: "Cocorova - Jud. MH" },
  { id: "mbNLam", label: "Cocoru - Jud. VL" },
  { id: "ljoBKm", label: "Cocosari - Jud. VN" },
  { id: "yZyNLl", label: "Cocosesti - Jud. AB" },
  { id: "l58opZ", label: "Cocosesti - Jud. PH" },
  { id: "XlDVjr", label: "Cocu - Jud. AG" },
  { id: "ZyWo8m", label: "Codaesti - Jud. VS" },
  { id: "lOwawl", label: "Codlea - Jud. BV" },
  { id: "l71NAr", label: "Codor - Jud. CJ" },
  { id: "rKj1Qm", label: "Codreni - Jud. BT" },
  { id: "rXLQ4r", label: "Codreni - Jud. CL" },
  { id: "lLJwAr", label: "Codreni - Jud. VS" },
  { id: "rdN97l", label: "Codresti - Jud. VN" },
  { id: "rEQ3Mr", label: "Codrisoru - Jud. BH" },
  { id: "mG5edZ", label: "Codru - Jud. BH" },
  { id: "mGWKnm", label: "Codru - Jud. SV" },
  { id: "rq0Pom", label: "Codru Butesii - Jud. MM" },
  { id: "rondvm", label: "Cogealac - Jud. CT" },
  { id: "ZQG1Dm", label: "Cogeasca - Jud. IS" },
  { id: "l0pvBl", label: "Cohani - Jud. BH" },
  { id: "ZRxPLm", label: "Cojani - Jud. GJ" },
  { id: "Zy26Yl", label: "Cojanu - Jud. BZ" },
  { id: "mboXqr", label: "Cojasca - Jud. DB" },
  { id: "rK3ykr", label: "Cojesti - Jud. CL" },
  { id: "lj45kr", label: "Cojgarei - Jud. OT" },
  { id: "r3QGzl", label: "Cojmanesti - Jud. GJ" },
  { id: "5l7Kwl", label: "Cojocani - Jud. AB" },
  { id: "mGaPdZ", label: "Cojocareni - Jud. SV" },
  { id: "rqbagZ", label: "Cojocaru - Jud. DB" },
  { id: "rd762m", label: "Cojoci - Jud. SV" },
  { id: "rK3YQr", label: "Cojocna - Jud. CJ" },
  { id: "mkjLPl", label: "Cojoiu - Jud. DB" },
  { id: "lO6YOZ", label: "Colacu - Jud. DB" },
  { id: "ZMJO4l", label: "Colacu - Jud. SV" },
  { id: "lO7zGl", label: "Colacu - Jud. VN" },
  { id: "rKP5EZ", label: "Colanu - Jud. DB" },
  { id: "ZRKGNl", label: "Colaret - Jud. MH" },
  { id: "lJ909Z", label: "Colceag - Jud. PH" },
  { id: "lp1NYm", label: "Coldau - Jud. BN" },
  { id: "mGxk9r", label: "Colelia - Jud. IL" },
  { id: "l78pvm", label: "Colelia - Jud. VL" },
  { id: "QmbxYZ", label: "Coleseni - Jud. AB" },
  { id: "mAAxqm", label: "Colesti - Jud. BH" },
  { id: "XlDybm", label: "Colibasi - Jud. AG" },
  { id: "rK39Rr", label: "Colibasi - Jud. BZ" },
  { id: "r8YpPZ", label: "Colibasi - Jud. DB" },
  { id: "lD2PXr", label: "Colibasi - Jud. GJ" },
  { id: "lO6pGZ", label: "Colibasi - Jud. GR" },
  { id: "r6GjDZ", label: "Colibasi - Jud. MH" },
  { id: "r3yQGl", label: "Colibasi - Jud. OT" },
  { id: "AZvb3Z", label: "Colibi - Jud. AB" },
  { id: "ZQQ2vZ", label: "Colibita - Jud. BN" },
  { id: "ZMJ24l", label: "Colina - Jud. TL" },
  { id: "VlewPr", label: "Colnic - Jud. AG" },
  { id: "r9qb3r", label: "Colonesti - Jud. BC" },
  { id: "rKDDeZ", label: "Colonesti - Jud. OT" },
  { id: "mnJ2qm", label: "Colonia - Jud. CJ" },
  { id: "ZRjo2Z", label: "Colonia 1 Mai - Jud. BV" },
  { id: "lLeRXm", label: "Colonia Bod - Jud. BV" },
  { id: "lOYGKr", label: "Colonia Bulgara - Jud. TM" },
  { id: "ZzVnEZ", label: "Colonia Fabricii - Jud. TM" },
  { id: "ZyWq8m", label: "Colonia Mica - Jud. TM" },
  { id: "r33ber", label: "Colonia Reconstructia - Jud. BV" },
  { id: "mAGoaZ", label: "Colonia Sighetu Silvaniei - Jud. SJ" },
  { id: "lL7wGZ", label: "Colonia Talmaciu - Jud. SB" },
  { id: "ma5pDm", label: "Colonia Tarnava - Jud. SB" },
  { id: "l083kl", label: "Coltaneni - Jud. BZ" },
  { id: "lWReqZ", label: "Coltau - Jud. MM" },
  { id: "leLaOZ", label: "Coltea - Jud. BR" },
  { id: "maLNJl", label: "Colteni - Jud. BZ" },
  { id: "AZQPgr", label: "Coltesti - Jud. AB" },
  { id: "r3Q30l", label: "Coltesti - Jud. GJ" },
  { id: "m1bp9r", label: "Coltesti - Jud. VL" },
  { id: "lOWvol", label: "Colti - Jud. BZ" },
  { id: "Zy2W8l", label: "Coltii De Jos - Jud. BZ" },
  { id: "mkEwPZ", label: "Coltirea - Jud. MM" },
  { id: "Wr8oEm", label: "Coltu - Jud. AG" },
  { id: "ZyGkqm", label: "Coltu Cornii - Jud. IS" },
  { id: "ZNwd7l", label: "Coltu De Jos - Jud. PH" },
  { id: "mnJoqm", label: "Coltu Pietrii - Jud. BZ" },
  { id: "mkzDQl", label: "Colun - Jud. SB" },
  { id: "lOwBKl", label: "Coman - Jud. BC" },
  { id: "maoLbZ", label: "Comana - Jud. CT" },
  { id: "ZyNo4l", label: "Comana - Jud. GR" },
  { id: "ZQQjdZ", label: "Comana De Jos - Jud. BV" },
  { id: "r334Ar", label: "Comana De Sus - Jud. BV" },
  { id: "ZQVzJl", label: "Comanca - Jud. OT" },
  { id: "mnpyXZ", label: "Comanca - Jud. VL" },
  { id: "rq0xvm", label: "Comanda - Jud. MH" },
  { id: "rBOnVZ", label: "Comandau - Jud. CV" },
  { id: "lLaaym", label: "Comaneasca - Jud. BR" },
  { id: "Wr6Q6m", label: "Comanesti - Jud. AR" },
  { id: "AZQbvl", label: "Comanesti - Jud. BC" },
  { id: "ZVLBBm", label: "Comanesti - Jud. GL" },
  { id: "mgLpkr", label: "Comanesti - Jud. HR" },
  { id: "maxNDl", label: "Comanesti - Jud. MH" },
  { id: "lD6OGr", label: "Comanesti - Jud. OT" },
  { id: "rBn3Qr", label: "Comanesti - Jud. SV" },
  { id: "Zy6gql", label: "Comani - Jud. OT" },
  { id: "lO6JwZ", label: "Comanicea - Jud. DJ" },
  { id: "mAG4gZ", label: "Comanita - Jud. OT" },
  { id: "r66Xgr", label: "Comarna - Jud. IS" },
  { id: "m1B83r", label: "Comarnic - Jud. PH" },
  { id: "lxbVPr", label: "Comasca - Jud. GR" },
  { id: "mA9W4m", label: "Comeat - Jud. TM" },
  { id: "lODKNm", label: "Comiat - Jud. HR" },
  { id: "lLDyGl", label: "Comisani - Jud. DB" },
  { id: "r3kWzr", label: "Comisoaia - Jud. BZ" },
  { id: "mGWoWm", label: "Comlausa - Jud. SM" },
  { id: "rBy7Jm", label: "Comlod - Jud. BN" },
  { id: "Z417jl", label: "Comlosu Mare - Jud. TM" },
  { id: "lWv9qZ", label: "Comlosu Mic - Jud. TM" },
  { id: "lpVxYr", label: "Comoara - Jud. TR" },
  { id: "mb9kQl", label: "Comoraste - Jud. CS" },
  { id: "Zy2B8l", label: "Comorata - Jud. CJ" },
  { id: "ZzDebm", label: "Comori - Jud. MS" },
  { id: "ZNGeLl", label: "Comosteni - Jud. DJ" },
  { id: "rqz50Z", label: "Comsesti - Jud. CJ" },
  { id: "l08yBl", label: "Conacu - Jud. CT" },
  { id: "lp1qem", label: "Concesti - Jud. BT" },
  { id: "l0NWPr", label: "Condeesti - Jud. IL" },
  { id: "mA4Aol", label: "Condeiesti - Jud. GJ" },
  { id: "ZRBBNZ", label: "Condoiesti - Jud. VL" },
  { id: "lDPYjl", label: "Condrea - Jud. GL" },
  { id: "rXaA4Z", label: "Condrea - Jud. VS" },
  { id: "rBn9qr", label: "Conduratu - Jud. PH" },
  { id: "VleWYr", label: "Conop - Jud. AR" },
  { id: "lDD8Xl", label: "Constanta - Jud. CT" },
  { id: "ZPedjl", label: "Constantin Brancoveanu - Jud. CL" },
  { id: "lLaX1m", label: "Constantin Daicoviciu - Jud. CS" },
  { id: "rXLV7r", label: "Constantin Gabrielescu - Jud. BR" },
  { id: "rXLder", label: "Constantinesti - Jud. BR" },
  { id: "lJ2a4l", label: "Constantinesti - Jud. OT" },
  { id: "mGgw9m", label: "Contea - Jud. VL" },
  { id: "olWWol", label: "Contesti - Jud. AG" },
  { id: "mG5WwZ", label: "Contesti - Jud. BC" },
  { id: "Zzg0nl", label: "Contesti - Jud. DB" },
  { id: "lD2Vjr", label: "Contesti - Jud. IS" },
  { id: "rdKBWm", label: "Contesti - Jud. TR" },
  { id: "r89wDZ", label: "Copaceana - Jud. VS" },
  { id: "m1bbar", label: "Copaceanca - Jud. TR" },
  { id: "lxxWQl", label: "Copacel - Jud. BH" },
  { id: "ZNy8er", label: "Copacel - Jud. BV" },
  { id: "lx8nzl", label: "Copacele - Jud. CS" },
  { id: "mA9q4m", label: "Copacelu - Jud. VL" },
  { id: "ZQQngZ", label: "Copaceni - Jud. BH" },
  { id: "lOWpKl", label: "Copaceni - Jud. CJ" },
  { id: "rXy29Z", label: "Copaceni - Jud. DB" },
  { id: "ZzwRbl", label: "Copaceni - Jud. IF" },
  { id: "Z4NK1m", label: "Copaceni - Jud. VL" },
  { id: "ZzP0bm", label: "Copacesti - Jud. VN" },
  { id: "rXq5gZ", label: "Copaci - Jud. HD" },
  { id: "l7JQYZ", label: "Copacioasa - Jud. GJ" },
  { id: "l7w6Yl", label: "Copacioasa - Jud. MH" },
  { id: "l7QNqr", label: "Copaciu - Jud. GR" },
  { id: "lWBNoZ", label: "Copalau - Jud. BT" },
  { id: "mGP1Br", label: "Copalnic - Jud. MM" },
  { id: "rwPyKZ", label: "Copalnic Deal - Jud. MM" },
  { id: "Zy6WDl", label: "Copalnic Manastur - Jud. MM" },
  { id: "JmAxKl", label: "Copand - Jud. AB" },
  { id: "rK3dEr", label: "Coplean - Jud. CJ" },
  { id: "l08P6l", label: "Copru - Jud. CJ" },
  { id: "rqpA0m", label: "Copsa Mare - Jud. SB" },
  { id: "r2q2em", label: "Copsa Mica - Jud. SB" },
  { id: "mA43ol", label: "Copuzu - Jud. IL" },
  { id: "ZQVbDl", label: "Corabia - Jud. OT" },
  { id: "mgPJGr", label: "Corbasca - Jud. BC" },
  { id: "rEdpQm", label: "Corbeanca - Jud. GR" },
  { id: "l7wp3l", label: "Corbeanca - Jud. IF" },
  { id: "BZ4Ojr", label: "Corbeni - Jud. AG" },
  { id: "mgAP7Z", label: "Corbeni - Jud. BR" },
  { id: "rwP6VZ", label: "Corbeni - Jud. OT" },
  { id: "3r2G9Z", label: "Corbesti - Jud. AR" },
  { id: "r6bjDr", label: "Corbesti - Jud. BH" },
  { id: "ZVdzxm", label: "Corbesti - Jud. MS" },
  { id: "5l7DLm", label: "Corbi - Jud. AG" },
  { id: "lDAOGm", label: "Corbi - Jud. BV" },
  { id: "lpxbel", label: "Corbii Din Vale - Jud. VL" },
  { id: "lLDxbl", label: "Corbii Mari - Jud. DB" },
  { id: "lJ603l", label: "Corbita - Jud. VN" },
  { id: "r2Vxvr", label: "Corboaia - Jud. BH" },
  { id: "XlDvXr", label: "Corbsori - Jud. AG" },
  { id: "rK36Er", label: "Corbu - Jud. BZ" },
  { id: "ZvgA3l", label: "Corbu - Jud. CT" },
  { id: "l7JRLZ", label: "Corbu - Jud. HR" },
  { id: "lDqqJm", label: "Corbu - Jud. OT" },
  { id: "rdKOam", label: "Corbu - Jud. VL" },
  { id: "mgob5m", label: "Corbu - Jud. VS" },
  { id: "l08pNl", label: "Corbu Nou - Jud. BR" },
  { id: "ZRJjKZ", label: "Corbu Vechi - Jud. BR" },
  { id: "rqbG9Z", label: "Corcioveni - Jud. GL" },
  { id: "mkanwZ", label: "Corcodel - Jud. IS" },
  { id: "rBjD2r", label: "Corcova - Jud. MH" },
  { id: "mk8D2r", label: "Cordareni - Jud. BT" },
  { id: "lJQz4Z", label: "Cordau - Jud. BH" },
  { id: "r8B1Ml", label: "Cordos - Jud. MS" },
  { id: "ZQVD4l", label: "Cordun - Jud. MH" },
  { id: "Zv5a3r", label: "Cordun - Jud. NT" },
  { id: "rK21Qm", label: "Corhana - Jud. NT" },
  { id: "l7vAXZ", label: "Corjauti - Jud. BT" },
  { id: "lOBn3Z", label: "Corlata - Jud. SV" },
  { id: "rwyLQl", label: "Corlate - Jud. DJ" },
  { id: "rq0L9m", label: "Corlatel - Jud. MH" },
  { id: "mnPw8Z", label: "Corlateni - Jud. BT" },
  { id: "maxMEl", label: "Corlatesti - Jud. OT" },
  { id: "ZvEbJr", label: "Corlatesti - Jud. PH" },
  { id: "rwnLom", label: "Cormaia - Jud. BN" },
  { id: "rX749m", label: "Cormenis - Jud. SJ" },
  { id: "Rr9RqZ", label: "Corna - Jud. AB" },
  { id: "BmabDr", label: "Cornatel - Jud. AG" },
  { id: "lp1V2m", label: "Cornatel - Jud. BC" },
  { id: "lOBpKZ", label: "Cornatel - Jud. SB" },
  { id: "ZNyW1r", label: "Cornatelu - Jud. BC" },
  { id: "rXykgZ", label: "Cornatelu - Jud. DB" },
  { id: "l7y1qr", label: "Cornatelu - Jud. OT" },
  { id: "rE7XVl", label: "Cornea - Jud. CS" },
  { id: "lx8gnl", label: "Corneanu - Jud. BZ" },
  { id: "rd544Z", label: "Corneni - Jud. CJ" },
  { id: "ZMRXQl", label: "Cornereva - Jud. CS" },
  { id: "rqPJgm", label: "Cornesti - Jud. BC" },
  { id: "r2Ygpm", label: "Cornesti - Jud. CJ" },
  { id: "Z4qWQl", label: "Cornesti - Jud. DB" },
  { id: "ZRxLAm", label: "Cornesti - Jud. GJ" },
  { id: "r2Q21m", label: "Cornesti - Jud. IS" },
  { id: "roEnql", label: "Cornesti - Jud. MM" },
  { id: "l7w3ql", label: "Cornesti - Jud. MS" },
  { id: "ZQyxnZ", label: "Cornesti - Jud. TM" },
  { id: "l5179l", label: "Cornet - Jud. BC" },
  { id: "rB595m", label: "Cornet - Jud. VL" },
  { id: "rdVy2l", label: "Cornetu - Jud. DJ" },
  { id: "lpEKMm", label: "Cornetu - Jud. GJ" },
  { id: "ZM9vRZ", label: "Cornetu - Jud. IF" },
  { id: "lpxabl", label: "Cornetu - Jud. VN" },
  { id: "lDA4Xm", label: "Corni - Jud. BT" },
  { id: "lDP51l", label: "Corni - Jud. DB" },
  { id: "mgxb7r", label: "Corni - Jud. GL" },
  { id: "rq0zQm", label: "Corni - Jud. MM" },
  { id: "l07PPZ", label: "Corni - Jud. NT" },
  { id: "r86xel", label: "Corni - Jud. SV" },
  { id: "lOYbNr", label: "Corni Albesti - Jud. VS" },
  { id: "r8290l", label: "Cornii De Jos - Jud. BC" },
  { id: "l519Nl", label: "Cornii De Sus - Jud. BC" },
  { id: "leaVXl", label: "Cornisesti - Jud. BH" },
  { id: "mGwMnm", label: "Cornisoru - Jud. CS" },
  { id: "m1DYYl", label: "Cornita - Jud. DJ" },
  { id: "l51v0l", label: "Cornitel - Jud. BH" },
  { id: "krdx5r", label: "Cornu - Jud. AB" },
  { id: "lO6y3Z", label: "Cornu - Jud. DJ" },
  { id: "lYGRJr", label: "Cornu - Jud. PH" },
  { id: "rqpa0m", label: "Cornu De Jos - Jud. PH" },
  { id: "rKD89Z", label: "Cornu De Sus - Jud. PH" },
  { id: "lJN2Yl", label: "Cornu Luncii - Jud. SV" },
  { id: "mAbNKr", label: "Cornutel - Jud. CS" },
  { id: "rE7yMl", label: "Coroana - Jud. CT" },
  { id: "mGxYwr", label: "Corobai - Jud. GJ" },
  { id: "lYJ5Am", label: "Corobanesti - Jud. VS" },
  { id: "r99yqr", label: "Corocaiesti - Jud. SV" },
  { id: "r9n8dZ", label: "Corod - Jud. GL" },
  { id: "mGW0dm", label: "Corod - Jud. SM" },
  { id: "leJAOm", label: "Corodesti - Jud. VS" },
  { id: "Oro8XZ", label: "Coroi - Jud. AR" },
  { id: "mgav5Z", label: "Coroi - Jud. MS" },
  { id: "rK2x9m", label: "Coroieni - Jud. MM" },
  { id: "grKPQZ", label: "Coroiesti - Jud. AB" },
  { id: "r66wWr", label: "Coroiesti - Jud. HD" },
  { id: "rKxpEZ", label: "Coroiesti - Jud. VS" },
  { id: "mnp3jZ", label: "Coroiestii De Sus - Jud. VS" },
  { id: "rBjLqr", label: "Coroisanmartin - Jud. MS" },
  { id: "rqOQgl", label: "Coroiu - Jud. VS" },
  { id: "ZNWXQl", label: "Coronini - Jud. CS" },
  { id: "rwDXvZ", label: "Coropceni - Jud. IS" },
  { id: "lWMyRr", label: "Coroteni - Jud. VN" },
  { id: "rwVP3m", label: "Corpaci - Jud. SV" },
  { id: "r97Jal", label: "Corpadea - Jud. CJ" },
  { id: "rKPYEZ", label: "Corsoru - Jud. GJ" },
  { id: "Jr3eel", label: "Cortesti - Jud. AB" },
  { id: "r89gKZ", label: "Corugea - Jud. TL" },
  { id: "lp552m", label: "Coruia - Jud. MM" },
  { id: "rXOv4m", label: "Corunca - Jud. MS" },
  { id: "mGxJ6r", label: "Corund - Jud. HR" },
  { id: "l0wDgZ", label: "Corund - Jud. SM" },
  { id: "lpzXbr", label: "Corusu - Jud. CJ" },
  { id: "mbPWBZ", label: "Corvinesti - Jud. BN" },
  { id: "mnE7Bl", label: "Corzu - Jud. MH" },
  { id: "AZQWnm", label: "Cosaci - Jud. AG" },
  { id: "mnQ68l", label: "Cosambesti - Jud. IL" },
  { id: "ZRBQyZ", label: "Cosani - Jud. VL" },
  { id: "rox6qZ", label: "Cosarii - Jud. TM" },
  { id: "m1bd9r", label: "Cosava - Jud. TM" },
  { id: "rByagm", label: "Cosbuc - Jud. BN" },
  { id: "ZVyJ6r", label: "Cosca - Jud. VS" },
  { id: "l7vnnZ", label: "Cosdeni - Jud. BH" },
  { id: "l7yAAr", label: "Coseiu - Jud. SJ" },
  { id: "r6qq7m", label: "Coseni - Jud. CV" },
  { id: "lOBNKZ", label: "Coserele - Jud. PH" },
  { id: "mG1QpZ", label: "Cosereni - Jud. DJ" },
  { id: "ZRwnjl", label: "Cosereni - Jud. IL" },
  { id: "AZQLnr", label: "Coseri - Jud. AG" },
  { id: "r9qYyr", label: "Coseriu - Jud. BN" },
  { id: "WmGA6Z", label: "Cosesti - Jud. AG" },
  { id: "ZP7QBr", label: "Cosesti - Jud. HD" },
  { id: "lJNGwl", label: "Cosesti - Jud. VS" },
  { id: "r990xr", label: "Cosevita - Jud. TM" },
  { id: "ZQqLgZ", label: "Cositeni - Jud. GL" },
  { id: "lWO4ol", label: "Cositeni - Jud. IS" },
  { id: "Wr8REl", label: "Coslariu - Jud. AB" },
  { id: "PrEQxr", label: "Coslariu Nou - Jud. AB" },
  { id: "r3yaAl", label: "Coslegi - Jud. PH" },
  { id: "lYyaQl", label: "Coslogeni - Jud. CL" },
  { id: "ZzgA3l", label: "Coslugea - Jud. CT" },
  { id: "mbojdr", label: "Cosmesti - Jud. GL" },
  { id: "roxo3Z", label: "Cosmesti - Jud. TR" },
  { id: "r6qK3m", label: "Cosmestii Vale - Jud. GL" },
  { id: "mb5YEl", label: "Cosmina De Jos - Jud. PH" },
  { id: "lL7dnZ", label: "Cosmina De Sus - Jud. PH" },
  { id: "rKxD0Z", label: "Cosna - Jud. SV" },
  { id: "Oro08Z", label: "Cosnea - Jud. BC" },
  { id: "l0wqQZ", label: "Cosniciu De Jos - Jud. SJ" },
  { id: "ZR3nDl", label: "Cosniciu De Sus - Jud. SJ" },
  { id: "r899DZ", label: "Cosoaia - Jud. TR" },
  { id: "rdVPRl", label: "Cosoba - Jud. GR" },
  { id: "lDa8Ym", label: "Cosota - Jud. VL" },
  { id: "rqOagl", label: "Cosoteni - Jud. TR" },
  { id: "mGPjnr", label: "Cosovat - Jud. MH" },
  { id: "rKPkWZ", label: "Cosoveni - Jud. DJ" },
  { id: "lLDByl", label: "Costache Negri - Jud. GL" },
  { id: "rXaq7Z", label: "Costana - Jud. SV" },
  { id: "mgPpGr", label: "Costei - Jud. BC" },
  { id: "lxELpm", label: "Costeiu - Jud. TM" },
  { id: "mgo9Dm", label: "Costeiu De Sus - Jud. TM" },
  { id: "mboMKr", label: "Costeni - Jud. GJ" },
  { id: "ZNYAYr", label: "Costeni - Jud. MM" },
  { id: "mnz1Bm", label: "Costeni - Jud. PH" },
  { id: "VrX3pm", label: "Costesti - Jud. AB" },
  { id: "0lY4XZ", label: "Costesti - Jud. AG" },
  { id: "lLewPm", label: "Costesti - Jud. BT" },
  { id: "Zy2jLl", label: "Costesti - Jud. BZ" },
  { id: "mkj1ol", label: "Costesti - Jud. DB" },
  { id: "rBOQqZ", label: "Costesti - Jud. GJ" },
  { id: "ZNAB1r", label: "Costesti - Jud. HD" },
  { id: "rdoY2m", label: "Costesti - Jud. IS" },
  { id: "Z4DkqZ", label: "Costesti - Jud. MH" },
  { id: "lxzgzr", label: "Costesti - Jud. VL" },
  { id: "r2M69m", label: "Costesti - Jud. VS" },
  { id: "r8OGXm", label: "Costesti Deal - Jud. HD" },
  { id: "Jr32Am", label: "Costesti Vaslan - Jud. AG" },
  { id: "lDPBJl", label: "Costestii Din Deal - Jud. DB" },
  { id: "lxbpnr", label: "Costestii Din Vale - Jud. DB" },
  { id: "m1D6al", label: "Costi - Jud. GL" },
  { id: "ZPexvl", label: "Costieni - Jud. BZ" },
  { id: "ZQyGgZ", label: "Costileva - Jud. SV" },
  { id: "r82NVl", label: "Costinesti - Jud. BT" },
  { id: "Z4w8jr", label: "Costinesti - Jud. CT" },
  { id: "lYyRJl", label: "Costis - Jud. CS" },
  { id: "lex3gZ", label: "Costisa - Jud. NT" },
  { id: "r6x3Dl", label: "Costisa - Jud. SV" },
  { id: "ZRBqDZ", label: "Costisa - Jud. VN" },
  { id: "rdN04l", label: "Costisa De Sus - Jud. VN" },
  { id: "mboNdr", label: "Costisata - Jud. DB" },
  { id: "NmkywZ", label: "Costita - Jud. AG" },
  { id: "ZRjnAZ", label: "Costiugeni - Jud. BT" },
  { id: "ZPaakl", label: "Costiui - Jud. MM" },
  { id: "rwpNKZ", label: "Costomiru - Jud. BZ" },
  { id: "r92ENZ", label: "Costuleni - Jud. IS" },
  { id: "ZNyE7r", label: "Cosula - Jud. BT" },
  { id: "Z4YOQl", label: "Cosuleni - Jud. BT" },
  { id: "lDAgWm", label: "Cotargaci - Jud. BT" },
  { id: "ZyGX2m", label: "Cotargaci - Jud. IS" },
  { id: "rBnQDr", label: "Cotargasi - Jud. SV" },
  { id: "rXL2gr", label: "Cotatcu - Jud. BZ" },
  { id: "l7yy3r", label: "Coteana - Jud. OT" },
  { id: "4lOpOm", label: "Coteasca - Jud. AG" },
  { id: "5l7gnl", label: "Cotenesti - Jud. AG" },
  { id: "VrX94Z", label: "Coteni - Jud. BC" },
  { id: "mnxkAZ", label: "Coteni - Jud. GR" },
  { id: "ZyY2YZ", label: "Coteni - Jud. OT" },
  { id: "Jr3zAm", label: "Cotesti - Jud. AG" },
  { id: "lYPRAZ", label: "Cotesti - Jud. VN" },
  { id: "ZMJ3Rl", label: "Cotic - Jud. VS" },
  { id: "r9qdOr", label: "Cotiglet - Jud. BH" },
  { id: "krwvXm", label: "Cotmeana - Jud. AG" },
  { id: "WmGkwm", label: "Cotmenita - Jud. AG" },
  { id: "mgLw5r", label: "Cotnari - Jud. IS" },
  { id: "m1nMar", label: "Cotofanca - Jud. CL" },
  { id: "mbPNaZ", label: "Cotofanesti - Jud. BC" },
  { id: "rob4Lm", label: "Cotofenesti - Jud. PH" },
  { id: "r278WZ", label: "Cotofenii Din Dos - Jud. DJ" },
  { id: "maoeDZ", label: "Cotofenii Din Fata - Jud. DJ" },
  { id: "grK9kl", label: "Cotorasti - Jud. AB" },
  { id: "r9Dyam", label: "Cotorbesti - Jud. OT" },
  { id: "r2Yb9m", label: "Cotorca - Jud. BZ" },
  { id: "Z430qr", label: "Cotorca - Jud. IL" },
  { id: "Zy6K8l", label: "Cotorinau - Jud. MS" },
  { id: "rE2aLZ", label: "Cotormani - Jud. HR" },
  { id: "r8YLEZ", label: "Cotoroaia - Jud. GL" },
  { id: "ZMVn8l", label: "Cotoroaia - Jud. MH" },
  { id: "AZQKgm", label: "Cotu - Jud. AG" },
  { id: "rByXbm", label: "Cotu - Jud. BT" },
  { id: "lJP0er", label: "Cotu - Jud. DJ" },
  { id: "ZyWz2m", label: "Cotu Baii - Jud. SV" },
  { id: "lOWBGl", label: "Cotu Ciorii - Jud. BZ" },
  { id: "lYJLJm", label: "Cotu Dobei - Jud. SV" },
  { id: "ZQQM5Z", label: "Cotu Grosului - Jud. BC" },
  { id: "mgLbkr", label: "Cotu Lui Ivan - Jud. IS" },
  { id: "m1nkar", label: "Cotu Lung - Jud. BR" },
  { id: "AZv7xl", label: "Cotu Malului - Jud. AG" },
  { id: "ropX5Z", label: "Cotu Miculinti - Jud. BT" },
  { id: "lOWwOl", label: "Cotu Mihalea - Jud. BR" },
  { id: "m1qagm", label: "Cotu Morii - Jud. IS" },
  { id: "ZMR8zl", label: "Cotu Vaii - Jud. CT" },
  { id: "rwPKQZ", label: "Cotu Vames - Jud. NT" },
  { id: "BmaQem", label: "Cotumba - Jud. BC" },
  { id: "rXOG3m", label: "Cotus - Jud. MS" },
  { id: "l7vdYZ", label: "Cotusca - Jud. BT" },
  { id: "rKx7QZ", label: "Covaci - Jud. TM" },
  { id: "r92DOZ", label: "Covacipeter - Jud. HR" },
  { id: "wlpWMZ", label: "Covasint - Jud. AR" },
  { id: "rEdd5m", label: "Covasna - Jud. CV" },
  { id: "Zvo7xl", label: "Covasna - Jud. IS" },
  { id: "m1D58l", label: "Covei - Jud. DJ" },
  { id: "r89DqZ", label: "Coverca - Jud. SV" },
  { id: "rwEkKl", label: "Coves - Jud. SB" },
  { id: "roYPXl", label: "Covrag - Jud. VN" },
  { id: "rqEvgr", label: "Covragiu - Jud. HD" },
  { id: "lJxxpr", label: "Covrigi - Jud. GJ" },
  { id: "lpx4bl", label: "Coza - Jud. VN" },
  { id: "lxKvMZ", label: "Cozanesti - Jud. SV" },
  { id: "mAbe8r", label: "Cozia - Jud. CS" },
  { id: "rqE4gr", label: "Cozia - Jud. HD" },
  { id: "lj5RLZ", label: "Cozia - Jud. IS" },
  { id: "r2woWZ", label: "Cozia - Jud. MH" },
  { id: "rK3WQr", label: "Cozieni - Jud. BZ" },
  { id: "m1q2dm", label: "Cozieni - Jud. IF" },
  { id: "rK3MGr", label: "Cozla - Jud. CS" },
  { id: "lj4wkr", label: "Cozla - Jud. SJ" },
  { id: "rq0GQm", label: "Cozma - Jud. MS" },
  { id: "ZQGydm", label: "Cozmeni - Jud. HR" },
  { id: "mbOM2r", label: "Cozmesti - Jud. IS" },
  { id: "lDavjm", label: "Cozmesti - Jud. VS" },
  { id: "lp52pm", label: "Cracaoani - Jud. NT" },
  { id: "maxvDl", label: "Cracaul Negru - Jud. NT" },
  { id: "ZyYNYZ", label: "Craciunei - Jud. OT" },
  { id: "r3Qvel", label: "Craciunel - Jud. HR" },
  { id: "EZz0Km", label: "Craciunelu De Jos - Jud. AB" },
  { id: "wmgBEl", label: "Craciunelu De Sus - Jud. AB" },
  { id: "lJPKwr", label: "Craciunesti - Jud. DB" },
  { id: "m1qdqm", label: "Craciunesti - Jud. HD" },
  { id: "Zy620l", label: "Craciunesti - Jud. MM" },
  { id: "ZQVLpl", label: "Craciunesti - Jud. MS" },
  { id: "ma6a2r", label: "Craciunesti - Jud. VS" },
  { id: "rqz8BZ", label: "Cracu Almaj - Jud. CS" },
  { id: "lxADMm", label: "Cracu Lung - Jud. MH" },
  { id: "ZNWjal", label: "Cracu Mare - Jud. CS" },
  { id: "Zy6q2l", label: "Cracu Muntelui - Jud. MH" },
  { id: "r8DkKr", label: "Cracu Teiului - Jud. CS" },
  { id: "l54nNZ", label: "Craesti - Jud. CJ" },
  { id: "ZVdQOm", label: "Craguesti - Jud. MH" },
  { id: "rqE7gr", label: "Craguis - Jud. HD" },
  { id: "mGadwZ", label: "Crai Nou - Jud. TM" },
  { id: "lYGaQr", label: "Craidorolt - Jud. SM" },
  { id: "lWBDEZ", label: "Craiesti - Jud. BC" },
  { id: "rKPd0Z", label: "Craiesti - Jud. GL" },
  { id: "r3BP4m", label: "Craiesti - Jud. MS" },
  { id: "lD69Wr", label: "Craiesti - Jud. NT" },
  { id: "Zv5OKr", label: "Crainici - Jud. MH" },
  { id: "l0pW6l", label: "Crainimat - Jud. BN" },
  { id: "rEdMBm", label: "Craiova - Jud. DJ" },
  { id: "ljNxdl", label: "Crairat - Jud. CJ" },
  { id: "AZQa4Z", label: "Craiva - Jud. AB" },
  { id: "olWXqm", label: "Craiva - Jud. AR" },
  { id: "lpV9xr", label: "Cralovat - Jud. TM" },
  { id: "mGWW0m", label: "Crampoia - Jud. OT" },
  { id: "yZyA2Z", label: "Crampotani - Jud. AG" },
  { id: "lp1yPm", label: "Crancesti - Jud. BH" },
  { id: "ZNWAxl", label: "Crang - Jud. BZ" },
  { id: "rXa47Z", label: "Crang - Jud. VS" },
  { id: "r6qvgm", label: "Crangasi - Jud. DB" },
  { id: "mnxXjZ", label: "Crangeni - Jud. GL" },
  { id: "ma68Jr", label: "Crangeni - Jud. TR" },
  { id: "ZRxQvm", label: "Crangu - Jud. CT" },
  { id: "ZvzJ2Z", label: "Crangu - Jud. TR" },
  { id: "lDKa2Z", label: "Crangu - Jud. VL" },
  { id: "Z41O1l", label: "Crangu Nou - Jud. VS" },
  { id: "ZVLAom", label: "Cranguri - Jud. GR" },
  { id: "rwE4vl", label: "Crangurile - Jud. PH" },
  { id: "mAOxaZ", label: "Crangurile De Jos - Jud. DB" },
  { id: "ZNG3xl", label: "Crangurile De Sus - Jud. DB" },
  { id: "ZNA0Lr", label: "Crasanii De Jos - Jud. IL" },
  { id: "r8OPVm", label: "Crasanii De Sus - Jud. IL" },
  { id: "lWeDkr", label: "Crasna - Jud. CV" },
  { id: "mnQE8l", label: "Crasna - Jud. GJ" },
  { id: "mGWLBm", label: "Crasna - Jud. SJ" },
  { id: "ZyWMqm", label: "Crasna - Jud. VS" },
  { id: "ZVLDWm", label: "Crasna Din Deal - Jud. GJ" },
  { id: "r3Bk4m", label: "Crasna Viseului - Jud. MM" },
  { id: "ma1kWm", label: "Crasnaleuca - Jud. BT" },
  { id: "mgoQqm", label: "Crasnaseni - Jud. VS" },
  { id: "rwEJKl", label: "Creaca - Jud. SJ" },
  { id: "lpbObr", label: "Creata - Jud. IF" },
  { id: "ZV1PAr", label: "Credinta - Jud. CT" },
  { id: "lL6ePr", label: "Cremenari - Jud. VL" },
  { id: "ZRJEDZ", label: "Cremenea - Jud. CJ" },
  { id: "rdxdAr", label: "Cremenea - Jud. MH" },
  { id: "r6bV5r", label: "Crestur - Jud. BH" },
  { id: "ljGBLm", label: "Cresuia - Jud. BH" },
  { id: "rE4EVm", label: "Creteni - Jud. VL" },
  { id: "LmnQBl", label: "Cretesti - Jud. AB" },
  { id: "rdVQWl", label: "Cretesti - Jud. GJ" },
  { id: "rwPgxZ", label: "Cretesti - Jud. IF" },
  { id: "ZVypJr", label: "Cretesti - Jud. VS" },
  { id: "rBRoVr", label: "Cretestii De Sus - Jud. VS" },
  { id: "mGWw2m", label: "Creti - Jud. OT" },
  { id: "rqbBPZ", label: "Cretu - Jud. DB" },
  { id: "mnEKpl", label: "Cretuleasca - Jud. IF" },
  { id: "lWexRr", label: "Cretulesti - Jud. DB" },
  { id: "ZzgWnl", label: "Crevedia - Jud. DB" },
  { id: "rKPpeZ", label: "Crevedia Mare - Jud. GR" },
  { id: "maoaeZ", label: "Crevedia Mica - Jud. GR" },
  { id: "ZMRJRl", label: "Crevelesti - Jud. BZ" },
  { id: "r30R5Z", label: "Crevenicu - Jud. TR" },
  { id: "ElLybm", label: "Cricau - Jud. AB" },
  { id: "rEa8Mr", label: "Criciova - Jud. TM" },
  { id: "l52vRm", label: "Cricovu Dulce - Jud. DB" },
  { id: "m1k9jZ", label: "Crihalma - Jud. BV" },
  { id: "lWB1yZ", label: "Crihan - Jud. BC" },
  { id: "ma5a7m", label: "Crint - Jud. SB" },
  { id: "3r2Pdl", label: "Crintesti - Jud. AG" },
  { id: "Zzw0Kl", label: "Cris - Jud. HD" },
  { id: "l57GAr", label: "Cris - Jud. MS" },
  { id: "leLg1Z", label: "Crisan - Jud. CT" },
  { id: "r2QRem", label: "Crisan - Jud. HD" },
  { id: "lWv7BZ", label: "Crisan - Jud. TL" },
  { id: "Z43MJr", label: "Criscior - Jud. HD" },
  { id: "arBDdl", label: "Criseni - Jud. AB" },
  { id: "rBWqqZ", label: "Criseni - Jud. CJ" },
  { id: "ZyGW2m", label: "Criseni - Jud. HR" },
  { id: "rqp1Qm", label: "Criseni - Jud. SJ" },
  { id: "rBnY5r", label: "Criseni - Jud. SM" },
  { id: "rqbNPZ", label: "Cristeasca - Jud. DB" },
  { id: "lDqzKm", label: "Cristelec - Jud. SJ" },
  { id: "WmGDWr", label: "Cristesti - Jud. AB" },
  { id: "3r2A9m", label: "Cristesti - Jud. AR" },
  { id: "mG57nZ", label: "Cristesti - Jud. BT" },
  { id: "rozkvm", label: "Cristesti - Jud. IS" },
  { id: "rdx8ar", label: "Cristesti - Jud. MS" },
  { id: "rKxGxZ", label: "Cristesti - Jud. VS" },
  { id: "ma1bJm", label: "Cristestii Ciceului - Jud. BN" },
  { id: "mgPXAr", label: "Cristian - Jud. BV" },
  { id: "lL7RPZ", label: "Cristian - Jud. SB" },
  { id: "rwn2Qm", label: "Cristinesti - Jud. BT" },
  { id: "rEQoor", label: "Cristioru De Jos - Jud. BH" },
  { id: "ZRj5vZ", label: "Cristioru De Sus - Jud. BH" },
  { id: "rBnPqr", label: "Cristolt - Jud. SJ" },
  { id: "rBnVgr", label: "Cristoltel - Jud. SJ" },
  { id: "lx8XPl", label: "Cristorel - Jud. CJ" },
  { id: "rqEg1r", label: "Cristur - Jud. HD" },
  { id: "mkzMOl", label: "Cristur Criseni - Jud. SJ" },
  { id: "r9qvyr", label: "Cristur Sieu - Jud. BN" },
  { id: "rwDEvZ", label: "Cristuru Secuiesc - Jud. HR" },
  { id: "lea3Xl", label: "Crit - Jud. BV" },
  { id: "l0XWRl", label: "Criva - Jud. DJ" },
  { id: "r920aZ", label: "Criva - Jud. HD" },
  { id: "l7wqnl", label: "Criva De Jos - Jud. OT" },
  { id: "mGPRdr", label: "Criva De Sus - Jud. OT" },
  { id: "ZNA2xr", label: "Crivadia - Jud. HD" },
  { id: "m1nOar", label: "Crivat - Jud. CL" },
  { id: "QmbEYl", label: "Crivatu - Jud. AG" },
  { id: "ZQqDvZ", label: "Crivatu - Jud. DB" },
  { id: "rwDvXZ", label: "Crivesti - Jud. IS" },
  { id: "ZQyK5Z", label: "Crivesti - Jud. VS" },
  { id: "r8YEMZ", label: "Crivina - Jud. GR" },
  { id: "l57M0r", label: "Crivina - Jud. MH" },
  { id: "lDqBbm", label: "Crivina - Jud. PH" },
  { id: "m1bV8r", label: "Crivina - Jud. TM" },
  { id: "rdK3zm", label: "Crivina De Sus - Jud. TM" },
  { id: "r89GVZ", label: "Crivobara - Jud. TM" },
  { id: "lDA12m", label: "Crizbav - Jud. BV" },
  { id: "PrEYQr", label: "Crocna - Jud. AR" },
  { id: "r3Bvzm", label: "Croica - Jud. MH" },
  { id: "rBbyDr", label: "Croici - Jud. GJ" },
  { id: "rwywal", label: "Croitori - Jud. DB" },
  { id: "lYxROZ", label: "Crovna - Jud. DJ" },
  { id: "ZPB83m", label: "Crovu - Jud. DB" },
  { id: "lj9NwZ", label: "Crucea - Jud. CT" },
  { id: "lODQNm", label: "Crucea - Jud. IS" },
  { id: "lYJnQm", label: "Crucea - Jud. SV" },
  { id: "m18Wal", label: "Crucea De Jos - Jud. VN" },
  { id: "m1DKql", label: "Crucea De Piatra - Jud. GR" },
  { id: "lO7dOl", label: "Crucea De Sus - Jud. VN" },
  { id: "ZNGvyl", label: "Cruceanu - Jud. GL" },
  { id: "arBYgm", label: "Cruceni - Jud. AR" },
  { id: "ma60Wr", label: "Cruceni - Jud. TM" },
  { id: "wmgDEr", label: "Crucisoara - Jud. AG" },
  { id: "mAG36Z", label: "Crucisor - Jud. SM" },
  { id: "l7JVYZ", label: "Crunti - Jud. IL" },
  { id: "lD26Xr", label: "Cruset - Jud. GJ" },
  { id: "rqz4dZ", label: "Crusovat - Jud. CS" },
  { id: "ZQvgDl", label: "Crusovita - Jud. CS" },
  { id: "rq029m", label: "Crusovu - Jud. OT" },
  { id: "l7yVqr", label: "Cublesu - Jud. SJ" },
  { id: "Zy2JDl", label: "Cublesu Somesan - Jud. CJ" },
  { id: "m1k16Z", label: "Cubulcut - Jud. BH" },
  { id: "arBKVZ", label: "Cuca - Jud. AG" },
  { id: "ZzgNWl", label: "Cuca - Jud. GL" },
  { id: "m1Gv9Z", label: "Cucerdea - Jud. MS" },
  { id: "ZVkddl", label: "Cucesti - Jud. VL" },
  { id: "rqpDPm", label: "Cuceu - Jud. SJ" },
  { id: "nljqKZ", label: "Cuchinis - Jud. BC" },
  { id: "lOALnr", label: "Cuci - Jud. MS" },
  { id: "l7wAXl", label: "Cuci - Jud. NT" },
  { id: "rwYgjl", label: "Cuci - Jud. VL" },
  { id: "ZPq43l", label: "Cuciulat - Jud. SJ" },
  { id: "ropKvZ", label: "Cuciulata - Jud. BV" },
  { id: "lL6VXr", label: "Cucoiu - Jud. VL" },
  { id: "lWB0AZ", label: "Cucorani - Jud. BT" },
  { id: "l7v8vZ", label: "Cucova - Jud. BC" },
  { id: "rdoG7m", label: "Cucova - Jud. IS" },
  { id: "ZPqdjl", label: "Cucu - Jud. SM" },
  { id: "ljGPqm", label: "Cucuceni - Jud. BH" },
  { id: "ZQaV5Z", label: "Cucueti - Jud. OT" },
  { id: "rXa39Z", label: "Cucueti - Jud. TR" },
  { id: "Z4Y9Jl", label: "Cucuieti - Jud. BC" },
  { id: "r3k5Vr", label: "Cucuieti - Jud. CL" },
  { id: "Zvo69l", label: "Cucuis - Jud. HD" },
  { id: "r6OoXZ", label: "Cuculeasa - Jud. BZ" },
  { id: "mA89Kl", label: "Cuculesti - Jud. VL" },
  { id: "lDP4Kl", label: "Cucuruzu - Jud. GR" },
  { id: "krd75m", label: "Cucuta - Jud. AB" },
  { id: "ma12Wm", label: "Cucuteni - Jud. BT" },
  { id: "ZNGMVl", label: "Cucuteni - Jud. DB" },
  { id: "lL1Abm", label: "Cucuteni - Jud. IS" },
  { id: "rXyAeZ", label: "Cudalbi - Jud. GL" },
  { id: "l57e0r", label: "Cuejdiu - Jud. NT" },
  { id: "ljo4Lm", label: "Cueni - Jud. VL" },
  { id: "l571Er", label: "Cufoaia - Jud. MM" },
  { id: "Xl0pJl", label: "Cugir - Jud. AB" },
  { id: "Wr6w6Z", label: "Cuias - Jud. AR" },
  { id: "ZQaNaZ", label: "Cuib - Jud. PH" },
  { id: "arBeJm", label: "Cuied - Jud. AR" },
  { id: "l0N26r", label: "Cuies - Jud. HD" },
  { id: "ZzEG8r", label: "Cuiesd - Jud. BH" },
  { id: "r6GP6Z", label: "Cuiesd - Jud. MS" },
  { id: "rXyVnZ", label: "Cuiugiuc - Jud. CT" },
  { id: "rdKw4m", label: "Cujba - Jud. VS" },
  { id: "ZQVYgl", label: "Cujmir - Jud. MH" },
  { id: "l07vBZ", label: "Cujmiru Mic - Jud. MH" },
  { id: "lWRREZ", label: "Culcea - Jud. MM" },
  { id: "rKDgWZ", label: "Culciu Mare - Jud. SM" },
  { id: "rwEL3l", label: "Culciu Mic - Jud. SM" },
  { id: "OroLxZ", label: "Culdesti - Jud. AB" },
  { id: "Zvgg3l", label: "Culmea - Jud. CT" },
  { id: "rK2oxm", label: "Culpiu - Jud. MS" },
  { id: "ZPBeEm", label: "Cumpana - Jud. CT" },
  { id: "r86Qel", label: "Cumparatura - Jud. SV" },
  { id: "rq071m", label: "Cund - Jud. MS" },
  { id: "rqzw1Z", label: "Cunesti - Jud. CL" },
  { id: "krd45r", label: "Cungrea - Jud. AG" },
  { id: "mnEqjl", label: "Cungrea - Jud. OT" },
  { id: "Wr6opZ", label: "Cunta - Jud. AB" },
  { id: "ZvEN5r", label: "Cununschi - Jud. SV" },
  { id: "lxbgwr", label: "Cuparu - Jud. DB" },
  { id: "ZM7pRm", label: "Cupele - Jud. GR" },
  { id: "lD6aWr", label: "Cupseni - Jud. MM" },
  { id: "ZQvndl", label: "Cuptoare - Jud. CS" },
  { id: "l5QDvl", label: "Curagau - Jud. IS" },
  { id: "ZMYogZ", label: "Curatele - Jud. BH" },
  { id: "Zzo2Wl", label: "Curatesti - Jud. CL" },
  { id: "wmg3Am", label: "Curaturi - Jud. AB" },
  { id: "m1q6jm", label: "Curaturi - Jud. IS" },
  { id: "leJ7Ym", label: "Curaturile - Jud. VL" },
  { id: "ZNWdYl", label: "Curcanesti - Jud. BZ" },
  { id: "r97xNl", label: "Curcani - Jud. CL" },
  { id: "rdV5Al", label: "Curcani - Jud. CT" },
  { id: "lL7YnZ", label: "Curciu - Jud. SB" },
  { id: "m1BX9r", label: "Curcubeu - Jud. PH" },
  { id: "ZMVBzl", label: "Curechistea - Jud. NT" },
  { id: "rdo04m", label: "Curechiu - Jud. HD" },
  { id: "rK2NEm", label: "Curete - Jud. MS" },
  { id: "PrEygr", label: "Curita - Jud. BC" },
  { id: "9m1Xjl", label: "Curmatura - Jud. AB" },
  { id: "m1nqqr", label: "Curmatura - Jud. BZ" },
  { id: "r3kDer", label: "Curmatura - Jud. CS" },
  { id: "lLDgPl", label: "Curmatura - Jud. DJ" },
  { id: "lYG7wr", label: "Curmatura - Jud. PH" },
  { id: "r8OYem", label: "Curpen - Jud. GJ" },
  { id: "Orobnm", label: "Curpeni - Jud. AB" },
  { id: "Zzw3Nl", label: "Curpenii Silvasului - Jud. HD" },
  { id: "mkawbZ", label: "Cursaru - Jud. GJ" },
  { id: "lOYQGr", label: "Cursesti Deal - Jud. VS" },
  { id: "ZyWV4m", label: "Cursesti Vale - Jud. VS" },
  { id: "leJQ1m", label: "Curtea - Jud. TM" },
  { id: "rB5jQm", label: "Curtea - Jud. VL" },
  { id: "EZz3Nl", label: "Curtea De Arges - Jud. AG" },
  { id: "ronN3m", label: "Curteana - Jud. GJ" },
  { id: "AZvMKr", label: "Curteanca - Jud. AG" },
  { id: "l7woAl", label: "Curteni - Jud. MS" },
  { id: "r2MEbm", label: "Curteni - Jud. VS" },
  { id: "mbP6YZ", label: "Curtesti - Jud. BT" },
  { id: "LZMDyr", label: "Curtici - Jud. AR" },
  { id: "r3G45r", label: "Curtisoara - Jud. GJ" },
  { id: "rwEEal", label: "Curtisoara - Jud. OT" },
  { id: "leavOl", label: "Curtuiseni - Jud. BH" },
  { id: "rK30Qr", label: "Curtuiusu Dejului - Jud. CJ" },
  { id: "l578Nr", label: "Curtuiusu Mare - Jud. MM" },
  { id: "rBjOqr", label: "Curtuiusu Mic - Jud. MM" },
  { id: "rdjk4l", label: "Cusma - Jud. BN" },
  { id: "rKnxPm", label: "Cusmed - Jud. HR" },
  { id: "l079oZ", label: "Custelnic - Jud. MS" },
  { id: "mb9Pdl", label: "Custura - Jud. BR" },
  { id: "r2Y99m", label: "Custura - Jud. CJ" },
  { id: "rEQv5r", label: "Cusuius - Jud. BH" },
  { id: "4lOB3Z", label: "Cut - Jud. AB" },
  { id: "rBjEar", label: "Cut - Jud. NT" },
  { id: "rKDXkZ", label: "Cuta - Jud. SM" },
  { id: "ZvA0wr", label: "Cutca - Jud. CJ" },
  { id: "lj5vNZ", label: "Cutin - Jud. HD" },
  { id: "rwVjKm", label: "Cutina - Jud. TM" },
  { id: "ZNwkxl", label: "Cutis - Jud. SJ" },
  { id: "l7v4wZ", label: "Cutus - Jud. BV" },
  { id: "grKgRr", label: "Cuvesdia - Jud. AR" },
  { id: "AZQ35m", label: "Cuvin - Jud. AR" },
  { id: "lJWQYm", label: "Cuza Voda - Jud. BR" },
  { id: "r2Vvvr", label: "Cuza Voda - Jud. BT" },
  { id: "rwpMoZ", label: "Cuza Voda - Jud. CL" },
  { id: "l71aYr", label: "Cuza Voda - Jud. CT" },
  { id: "lj9BNZ", label: "Cuza Voda - Jud. DB" },
  { id: "lxbNzr", label: "Cuza Voda - Jud. GL" },
  { id: "lOD8Jm", label: "Cuza Voda - Jud. IS" },
  { id: "ZMG7ym", label: "Cuza Voda - Jud. OT" },
  { id: "ZyepKZ", label: "Cuzap - Jud. BH" },
  { id: "ZQaOpZ", label: "Cuzaplac - Jud. SJ" },
  { id: "maLwDl", label: "Cuzdrioara - Jud. CJ" },
  { id: "l0pEPl", label: "Cuzlau - Jud. BT" },
  { id: "ZV1jRr", label: "Dabaca - Jud. CJ" },
  { id: "lj5JBZ", label: "Dabaca - Jud. HD" },
  { id: "lOB4GZ", label: "Dabaceni - Jud. SJ" },
  { id: "lO6GnZ", label: "Dabuleni - Jud. DJ" },
  { id: "ZNyKLr", label: "Dacia - Jud. BT" },
  { id: "ZzEdYr", label: "Dacia - Jud. BV" },
  { id: "r3yDql", label: "Dacia - Jud. SM" },
  { id: "rBy5bm", label: "Dadesti - Jud. BC" },
  { id: "ZzwkYl", label: "Dadesti - Jud. IS" },
  { id: "mk1RwZ", label: "Daeni - Jud. TL" },
  { id: "ZM6QQl", label: "Daesti - Jud. VL" },
  { id: "ZzwdKl", label: "Dagata - Jud. IS" },
  { id: "lDPpJl", label: "Daia - Jud. GR" },
  { id: "mbOGor", label: "Daia - Jud. HR" },
  { id: "ZQVxJl", label: "Daia - Jud. MS" },
  { id: "ZyYoDZ", label: "Daia - Jud. SB" },
  { id: "VrXk4m", label: "Daia Romana - Jud. AB" },
  { id: "rBy32m", label: "Daisoara - Jud. BV" },
  { id: "rE6WLZ", label: "Dalbocita - Jud. MH" },
  { id: "leLWPZ", label: "Dalboset - Jud. CS" },
  { id: "m1nwRr", label: "Dalci - Jud. CS" },
  { id: "r8DPDr", label: "Dalga - Jud. CL" },
  { id: "l54P2Z", label: "Dalga Gara - Jud. CL" },
  { id: "m1kLgZ", label: "Dalghiu - Jud. BV" },
  { id: "r2bdgr", label: "Dalhauti - Jud. VN" },
  { id: "ZvoGDl", label: "Dalja Mare - Jud. HD" },
  { id: "lj5BbZ", label: "Dalja Mica - Jud. HD" },
  { id: "r97Rxl", label: "Dalma - Jud. BZ" },
  { id: "lj3o1m", label: "Dalma - Jud. MH" },
  { id: "rXyO7Z", label: "Dalnic - Jud. CV" },
  { id: "lexYdZ", label: "Dalu - Jud. MS" },
  { id: "l07p6Z", label: "Damacuseni - Jud. MM" },
  { id: "Zv5QDr", label: "Dambau - Jud. MS" },
  { id: "ZQGMam", label: "Dambova - Jud. GJ" },
  { id: "AZQjJl", label: "Dambovicioara - Jud. AG" },
  { id: "ZM76gm", label: "Dambovicioara - Jud. DB" },
  { id: "Zy27Dl", label: "Dambroca - Jud. BZ" },
  { id: "ZQVkol", label: "Dambu - Jud. MS" },
  { id: "ZzzPBZ", label: "Dambu - Jud. PH" },
  { id: "mgAQ9Z", label: "Dambu Mare - Jud. CJ" },
  { id: "XlD2Wr", label: "Dambureni - Jud. AB" },
  { id: "lpzJ2r", label: "Damburile - Jud. CJ" },
  { id: "rqpJ1m", label: "Damburile - Jud. OT" },
  { id: "mG5z9Z", label: "Damideni - Jud. BT" },
  { id: "lLexPm", label: "Damienesti - Jud. BC" },
  { id: "r9yQNr", label: "Damieni - Jud. MS" },
  { id: "lxxXol", label: "Damileni - Jud. BT" },
  { id: "r6bp5r", label: "Damis - Jud. BH" },
  { id: "lYJexm", label: "Damteni - Jud. VL" },
  { id: "lWRPBZ", label: "Damuc - Jud. NT" },
  { id: "lp15Mm", label: "Danaila - Jud. BC" },
  { id: "rB5dDm", label: "Dancai - Jud. VL" },
  { id: "ZVwVBl", label: "Dancas - Jud. IS" },
  { id: "mA1n8m", label: "Danceu - Jud. MH" },
  { id: "l7JwYZ", label: "Danciulesti - Jud. GJ" },
  { id: "r2Y3Am", label: "Dancu - Jud. CJ" },
  { id: "majgbr", label: "Dancu - Jud. IS" },
  { id: "lODqLm", label: "Dancu Mare - Jud. HD" },
  { id: "ZyG38m", label: "Dancu Mic - Jud. HD" },
  { id: "arBybm", label: "Dandut - Jud. AB" },
  { id: "rBnngr", label: "Daneasa - Jud. OT" },
  { id: "Zy6y0l", label: "Danes - Jud. MS" },
  { id: "rXLner", label: "Danesti - Jud. CL" },
  { id: "mGxPnr", label: "Danesti - Jud. GJ" },
  { id: "rwDbXZ", label: "Danesti - Jud. HR" },
  { id: "r2wqdZ", label: "Danesti - Jud. MM" },
  { id: "r6GA5Z", label: "Danesti - Jud. NT" },
  { id: "lJNp3l", label: "Danesti - Jud. VS" },
  { id: "rBjbqr", label: "Danestii Chioarului - Jud. MM" },
  { id: "ZVLnOm", label: "Daneti - Jud. DJ" },
  { id: "rBWVgZ", label: "Dangau Mare - Jud. CJ" },
  { id: "rqz6oZ", label: "Dangau Mic - Jud. CJ" },
  { id: "rqPQGm", label: "Dangeni - Jud. BT" },
  { id: "lJNYel", label: "Dangesti - Jud. VL" },
  { id: "ZPqbMl", label: "Danila - Jud. SV" },
  { id: "mGwWDm", label: "Danulesti - Jud. BZ" },
  { id: "r3QY5l", label: "Danulesti - Jud. HD" },
  { id: "mgAY9Z", label: "Dara - Jud. BZ" },
  { id: "r2qR1m", label: "Dara - Jud. SM" },
  { id: "lp17Mm", label: "Darabani - Jud. BT" },
  { id: "l54w0Z", label: "Darabani - Jud. CT" },
  { id: "rBjdgr", label: "Darasti Ilfov - Jud. IF" },
  { id: "lj9kKZ", label: "Darasti Vlasca - Jud. GR" },
  { id: "rK3q9r", label: "Darja - Jud. CJ" },
  { id: "r3QRVl", label: "Darjiu - Jud. HR" },
  { id: "WmGapZ", label: "Darlesti - Jud. AB" },
  { id: "mGP89r", label: "Darloaia - Jud. NT" },
  { id: "r3y4Yl", label: "Darlos - Jud. SB" },
  { id: "yZygYm", label: "Darmaneasca - Jud. BC" },
  { id: "Jr34VZ", label: "Darmanesti - Jud. AG" },
  { id: "4lOnAZ", label: "Darmanesti - Jud. BC" },
  { id: "rEdwBm", label: "Darmanesti - Jud. DB" },
  { id: "r6ydDm", label: "Darmanesti - Jud. SV" },
  { id: "rqpVvm", label: "Darmoxa - Jud. SV" },
  { id: "QmbGor", label: "Daroaia - Jud. AB" },
  { id: "rwpvjZ", label: "Darot - Jud. CJ" },
  { id: "lpVNer", label: "Darova - Jud. TM" },
  { id: "l54KAZ", label: "Darvari - Jud. CL" },
  { id: "r92paZ", label: "Darvari - Jud. IF" },
  { id: "m1GdgZ", label: "Darvari - Jud. MH" },
  { id: "m1B5jr", label: "Darvari - Jud. PH" },
  { id: "lYJjAm", label: "Darvas - Jud. TR" },
  { id: "Zvgb9l", label: "Darza - Jud. DB" },
  { id: "l716Ar", label: "Dascalesti - Jud. BZ" },
  { id: "rwPgaZ", label: "Dascalu - Jud. IF" },
  { id: "lYnkVm", label: "Dataseni - Jud. MS" },
  { id: "lJ2nzl", label: "David - Jud. NT" },
  { id: "mGPAWr", label: "Davideni - Jud. NT" },
  { id: "7rq51r", label: "Davidesti - Jud. AG" },
  { id: "leGo6r", label: "Davidesti - Jud. OT" },
  { id: "roxk8Z", label: "Davidesti - Jud. VS" },
  { id: "ropBnZ", label: "Davidoaia - Jud. BT" },
  { id: "ZPawbl", label: "Deag - Jud. MS" },
  { id: "Xl0ngr", label: "Deagu De Jos - Jud. AG" },
  { id: "yZRONl", label: "Deagu De Sus - Jud. AG" },
  { id: "m1GOdZ", label: "Deaj - Jud. MS" },
  { id: "3r2wvZ", label: "Deal - Jud. AB" },
  { id: "3ZV7dl", label: "Dealu - Jud. AG" },
  { id: "Zvgy9l", label: "Dealu - Jud. GR" },
  { id: "rBbJVr", label: "Dealu - Jud. HR" },
  { id: "ZyW6Km", label: "Dealu - Jud. SV" },
  { id: "mA9Y4m", label: "Dealu Alunis - Jud. VL" },
  { id: "majNbr", label: "Dealu Armanului - Jud. HR" },
  { id: "lODNOm", label: "Dealu Babii - Jud. HD" },
  { id: "wmgAGZ", label: "Dealu Bajului - Jud. AB" },
  { id: "Jr38qZ", label: "Dealu Bisericii - Jud. AG" },
  { id: "rwYVVl", label: "Dealu Bisericii - Jud. VL" },
  { id: "7rqP0m", label: "Dealu Bistrii - Jud. AB" },
  { id: "ZV1Eor", label: "Dealu Botii - Jud. CJ" },
  { id: "VrXYpZ", label: "Dealu Bradului - Jud. AG" },
  { id: "AZQQaZ", label: "Dealu Capsei - Jud. AB" },
  { id: "wlpooZ", label: "Dealu Caselor - Jud. AB" },
  { id: "lj34dm", label: "Dealu Corbului - Jud. MM" },
  { id: "rK63Qm", label: "Dealu Corni - Jud. VL" },
  { id: "3r27pZ", label: "Dealu Crisului - Jud. AB" },
  { id: "ma1Pjm", label: "Dealu Crucii - Jud. BT" },
  { id: "mGgqDm", label: "Dealu Cucului - Jud. VN" },
  { id: "5l7ydr", label: "Dealu Dostatului - Jud. AB" },
  { id: "7rqLPm", label: "Dealu Ferului - Jud. AB" },
  { id: "rX7Bnm", label: "Dealu Floreni - Jud. SV" },
  { id: "krwwXr", label: "Dealu Frumos - Jud. AB" },
  { id: "7rqABr", label: "Dealu Frumos - Jud. AG" },
  { id: "mG13DZ", label: "Dealu Frumos - Jud. DB" },
  { id: "mAGLWZ", label: "Dealu Frumos - Jud. SB" },
  { id: "Xl0kRZ", label: "Dealu Geoagiului - Jud. AB" },
  { id: "lYPvEZ", label: "Dealu Glameia - Jud. VL" },
  { id: "BmanNr", label: "Dealu Goiesti - Jud. AB" },
  { id: "Jr3kYr", label: "Dealu Lamasoi - Jud. AB" },
  { id: "r996xr", label: "Dealu Launele - Jud. VL" },
  { id: "ZP77Ar", label: "Dealu Leului - Jud. GJ" },
  { id: "ZNJ8YZ", label: "Dealu Malului - Jud. VL" },
  { id: "mk8wPr", label: "Dealu Mare - Jud. BC" },
  { id: "rXdQgr", label: "Dealu Mare - Jud. BT" },
  { id: "mGwpwm", label: "Dealu Mare - Jud. CJ" },
  { id: "l7Q8Lr", label: "Dealu Mare - Jud. DB" },
  { id: "rKnQRm", label: "Dealu Mare - Jud. HD" },
  { id: "lWRMAZ", label: "Dealu Mare - Jud. MH" },
  { id: "ZVdwKm", label: "Dealu Mare - Jud. MM" },
  { id: "lL6aPr", label: "Dealu Mare - Jud. VL" },
  { id: "lpVJ6r", label: "Dealu Mare - Jud. VS" },
  { id: "ZP7KDr", label: "Dealu Mic - Jud. HD" },
  { id: "ZzEBEr", label: "Dealu Morii - Jud. BC" },
  { id: "5l7JXZ", label: "Dealu Muntelui - Jud. AB" },
  { id: "ZQv65l", label: "Dealu Negru - Jud. CJ" },
  { id: "VrXAnZ", label: "Dealu Obejdeanului - Jud. AG" },
  { id: "yZRYNr", label: "Dealu Orasului - Jud. AG" },
  { id: "Bma69r", label: "Dealu Ordancusii - Jud. AB" },
  { id: "JmAdql", label: "Dealu Padurii - Jud. AG" },
  { id: "ZQQqoZ", label: "Dealu Perjului - Jud. BC" },
  { id: "mkjVPl", label: "Dealu Pomilor - Jud. GJ" },
  { id: "Nmk8Qr", label: "Dealu Roatei - Jud. AB" },
  { id: "mnd1AZ", label: "Dealu Sarii - Jud. VN" },
  { id: "ZzV9NZ", label: "Dealu Scheiului - Jud. VL" },
  { id: "lDa71m", label: "Dealu Secarii - Jud. VS" },
  { id: "lWe6qr", label: "Dealu Spirei - Jud. GJ" },
  { id: "l7vP5Z", label: "Dealu Stefanitei - Jud. BN" },
  { id: "9m1Agm", label: "Dealu Tolcesii - Jud. AG" },
  { id: "mA8V2l", label: "Dealu Valeni - Jud. VL" },
  { id: "mA4bol", label: "Dealu Viei - Jud. GJ" },
  { id: "krdzAl", label: "Dealu Viilor - Jud. AG" },
  { id: "lDPOYl", label: "Dealu Viilor - Jud. GJ" },
  { id: "ZPGxKr", label: "Dealul Lung - Jud. VN" },
  { id: "Zy2w0l", label: "Dealul Viei - Jud. BZ" },
  { id: "EZzBYZ", label: "Decea - Jud. AB" },
  { id: "lxKqQZ", label: "Decebal - Jud. SM" },
  { id: "ZQq9vZ", label: "Decindea - Jud. DB" },
  { id: "lWeQNr", label: "Decindeni - Jud. DB" },
  { id: "rK2LRm", label: "Deda - Jud. MS" },
  { id: "rwPjVZ", label: "Dedovita Noua - Jud. MH" },
  { id: "rBjv5r", label: "Dedovita Veche - Jud. MH" },
  { id: "roEexl", label: "Dedrad - Jud. MS" },
  { id: "BZ4V0m", label: "Dedulesti - Jud. AG" },
  { id: "ZvA2Dr", label: "Dedulesti - Jud. BR" },
  { id: "maL8Wl", label: "Dedulesti - Jud. BZ" },
  { id: "lLJPXr", label: "Deia - Jud. SV" },
  { id: "lOW4Al", label: "Dej - Jud. CJ" },
  { id: "mgB8Bl", label: "Deja - Jud. SJ" },
  { id: "mnp4qZ", label: "Dejan - Jud. TM" },
  { id: "rdjOAl", label: "Dejani - Jud. BV" },
  { id: "mAG1gZ", label: "Dejesti - Jud. OT" },
  { id: "rqxJvm", label: "Dejoi - Jud. VL" },
  { id: "l7JKwZ", label: "Dejutiu - Jud. HR" },
  { id: "m1k1YZ", label: "Delani - Jud. BH" },
  { id: "ZzVaBZ", label: "Delea - Jud. VS" },
  { id: "leaaol", label: "Deleni - Jud. BC" },
  { id: "mgA3DZ", label: "Deleni - Jud. BZ" },
  { id: "l08YJl", label: "Deleni - Jud. CJ" },
  { id: "r9n78Z", label: "Deleni - Jud. CT" },
  { id: "ronRLm", label: "Deleni - Jud. DJ" },
  { id: "mnQJBl", label: "Deleni - Jud. GJ" },
  { id: "majWWr", label: "Deleni - Jud. HD" },
  { id: "rXq64Z", label: "Deleni - Jud. IS" },
  { id: "rwP7QZ", label: "Deleni - Jud. MH" },
  { id: "lexNdZ", label: "Deleni - Jud. MS" },
  { id: "r9y4Or", label: "Deleni - Jud. NT" },
  { id: "r86OPl", label: "Deleni - Jud. OT" },
  { id: "lOBLAZ", label: "Deleni - Jud. SJ" },
  { id: "lj8Nwr", label: "Deleni - Jud. SV" },
  { id: "ZvOz3l", label: "Deleni - Jud. VL" },
  { id: "lYJpAm", label: "Deleni - Jud. VS" },
  { id: "Xl0Jr1", label: "Deleni Obarsie - Jud. AB" },
  { id: "ZPaQjl", label: "Delenii - Jud. MS" },
  { id: "mA9pBm", label: "Delesti - Jud. VS" },
  { id: "ZNWeel", label: "Delinesti - Jud. CS" },
  { id: "ZyGDqm", label: "Delnita - Jud. HR" },
  { id: "leJ2Xm", label: "Delnita - Jud. SV" },
  { id: "mgPMBr", label: "Delureni - Jud. BN" },
  { id: "rK25Pm", label: "Delureni - Jud. MH" },
  { id: "lO7KNl", label: "Delureni - Jud. VL" },
  { id: "lpVpPr", label: "Delut - Jud. SV" },
  { id: "ZQyvDZ", label: "Demacusa - Jud. SV" },
  { id: "ZyGQDm", label: "Densus - Jud. HD" },
  { id: "lWvgoZ", label: "Denta - Jud. TM" },
  { id: "Nmkgqm", label: "Deoncesti - Jud. AB" },
  { id: "lxEDPm", label: "Deparati - Jud. TR" },
  { id: "lp19Wm", label: "Derna - Jud. BH" },
  { id: "lLe5Xm", label: "Dernisoara - Jud. BH" },
  { id: "rByzam", label: "Dersca - Jud. BT" },
  { id: "lWD7Nm", label: "Dersida - Jud. SJ" },
  { id: "lJPkzr", label: "Desa - Jud. DJ" },
  { id: "mkaKqZ", label: "Desag - Jud. HR" },
  { id: "l7w9Xl", label: "Desesti - Jud. MM" },
  { id: "rodn8Z", label: "Desirati - Jud. BR" },
  { id: "r6qPDm", label: "Desnatui - Jud. DJ" },
  { id: "mGa90Z", label: "Deta - Jud. TM" },
  { id: "lW1Yyr", label: "Deusu - Jud. CJ" },
  { id: "mGx36r", label: "Deva - Jud. HD" },
  { id: "9m1nYr", label: "Deve - Jud. AB" },
  { id: "r3BWqm", label: "Devesel - Jud. MH" },
  { id: "rqppom", label: "Deveselu - Jud. OT" },
  { id: "maLe9l", label: "Dezesti - Jud. CS" },
  { id: "mgADqZ", label: "Dezmir - Jud. CJ" },
  { id: "NmkWBm", label: "Dezna - Jud. AR" },
  { id: "rdNbzl", label: "Dezrobiti - Jud. VL" },
  { id: "AZvP9m", label: "Diaconesti - Jud. BC" },
  { id: "mG1a6Z", label: "Diaconesti - Jud. DB" },
  { id: "mA8Aol", label: "Diaconesti - Jud. VL" },
  { id: "m188Rl", label: "Dianu - Jud. VL" },
  { id: "lDAE2m", label: "Dicanesti - Jud. BH" },
  { id: "rBWLdZ", label: "Dichiseni - Jud. CL" },
  { id: "4lOOJl", label: "Diconesti - Jud. AG" },
  { id: "mGgYwm", label: "Diculesti - Jud. VL" },
  { id: "lj8gNr", label: "Didesti - Jud. TR" },
  { id: "r3GX4r", label: "Didilesti - Jud. GJ" },
  { id: "LmnWXr", label: "Dieci - Jud. AR" },
  { id: "leGGor", label: "Dienci - Jud. OT" },
  { id: "rdjoal", label: "Dienet - Jud. BC" },
  { id: "ZRjMjZ", label: "Dijir - Jud. BH" },
  { id: "l7wo3l", label: "Dileu Nou - Jud. MS" },
  { id: "ZNYV1r", label: "Dileu Vechi - Jud. MS" },
  { id: "Lmngjr", label: "Dilimani - Jud. AB" },
  { id: "lOwjKl", label: "Dimacheni - Jud. BT" },
  { id: "ZNYR1r", label: "Dimieni - Jud. IF" },
  { id: "ZP24Dm", label: "Dimitrie Cantemir - Jud. BT" },
  { id: "l0XoQl", label: "Dimitrie Cantemir - Jud. GR" },
  { id: "r99Gyr", label: "Dimitrie Cantemir - Jud. VS" },
  { id: "r27o9Z", label: "Dimoiu - Jud. DB" },
  { id: "mndx8Z", label: "Dimulesti - Jud. VL" },
  { id: "9m1Pgl", label: "Dincani - Jud. AG" },
  { id: "Orokvr", label: "Dinculesti - Jud. AG" },
  { id: "lpA9pm", label: "Dindesti - Jud. SM" },
  { id: "ZQy4gZ", label: "Dinga - Jud. VS" },
  { id: "mA9eom", label: "Dinias - Jud. TM" },
  { id: "lWBodZ", label: "Diosig - Jud. BH" },
  { id: "Z4968l", label: "Diosod - Jud. SJ" },
  { id: "lYxwRZ", label: "Diosti - Jud. DJ" },
  { id: "mG5XDZ", label: "Dipsa - Jud. BN" },
  { id: "ma57Wm", label: "Ditesti - Jud. PH" },
  { id: "rqEa1r", label: "Ditrau - Jud. HR" },
  { id: "rd5vAZ", label: "Divici - Jud. CS" },
  { id: "ljN1dl", label: "Diviciorii Mari - Jud. CJ" },
  { id: "ZPepkl", label: "Diviciorii Mici - Jud. CJ" },
  { id: "ZVka6l", label: "Doaga - Jud. VN" },
  { id: "rXaEeZ", label: "Doagele - Jud. VS" },
  { id: "r2wkdZ", label: "Doamna - Jud. NT" },
  { id: "ZR3wDl", label: "Doanca - Jud. OT" },
  { id: "lpAzYm", label: "Doba - Jud. OT" },
  { id: "ZyYyYZ", label: "Doba - Jud. SJ" },
  { id: "ZNw0yl", label: "Doba - Jud. SM" },
  { id: "r8686l", label: "Dobarca - Jud. SB" },
  { id: "ZQQK4Z", label: "Dobarceni - Jud. BT" },
  { id: "r8Y9DZ", label: "Dobarlau - Jud. CV" },
  { id: "mGxDWr", label: "Dobeni - Jud. HR" },
  { id: "Wr8P0r", label: "Doblea - Jud. AG" },
  { id: "m1DGgl", label: "Dobolii De Jos - Jud. CV" },
  { id: "m1Dqgl", label: "Dobolii De Sus - Jud. CV" },
  { id: "r86qEl", label: "Dobolt - Jud. SM" },
  { id: "mAO42Z", label: "Doboseni - Jud. CV" },
  { id: "VrXDer", label: "Dobra - Jud. AB" },
  { id: "ZM7K5m", label: "Dobra - Jud. DB" },
  { id: "rKny9m", label: "Dobra - Jud. HD" },
  { id: "lp5wxm", label: "Dobra - Jud. MH" },
  { id: "rdxyWr", label: "Dobra - Jud. MS" },
  { id: "rBngVr", label: "Dobra - Jud. SM" },
  { id: "l54NvZ", label: "Dobraia - Jud. CS" },
  { id: "rKn3Qm", label: "Dobrana - Jud. GJ" },
  { id: "Z4q58l", label: "Dobreni - Jud. GR" },
  { id: "mkE3wZ", label: "Dobreni - Jud. NT" },
  { id: "mbKYBr", label: "Dobreni - Jud. TR" },
  { id: "AZvzxZ", label: "Dobresti - Jud. AB" },
  { id: "9m1zar", label: "Dobresti - Jud. AG" },
  { id: "mk89or", label: "Dobresti - Jud. BH" },
  { id: "lO6xGZ", label: "Dobresti - Jud. DB" },
  { id: "mAOkKZ", label: "Dobresti - Jud. DJ" },
  { id: "ZvzjJZ", label: "Dobresti - Jud. TM" },
  { id: "rXaBRZ", label: "Dobresti - Jud. VL" },
  { id: "ZQaaoZ", label: "Dobretu - Jud. OT" },
  { id: "rdj1Rl", label: "Dobric - Jud. BN" },
  { id: "ZNDyLm", label: "Dobricea - Jud. VL" },
  { id: "rBy1gm", label: "Dobricel - Jud. BN" },
  { id: "mgBP7l", label: "Dobriceni - Jud. OT" },
  { id: "ljo8wm", label: "Dobriceni - Jud. VL" },
  { id: "l51W3l", label: "Dobricionesti - Jud. BH" },
  { id: "ZRKjLl", label: "Dobricu Lapusului - Jud. MM" },
  { id: "leoO1r", label: "Dobridor - Jud. DJ" },
  { id: "rK3VRr", label: "Dobrilesti - Jud. BZ" },
  { id: "r3y24l", label: "Dobrin - Jud. SJ" },
  { id: "ZQQ04Z", label: "Dobrinauti Hapai - Jud. BT" },
  { id: "r3Gzqr", label: "Dobrinesti - Jud. GL" },
  { id: "majLDr", label: "Dobrita - Jud. GJ" },
  { id: "lYG30r", label: "Dobrocina - Jud. SJ" },
  { id: "l57BEr", label: "Dobroesti - Jud. IF" },
  { id: "grKwPr", label: "Dobrogostea - Jud. AG" },
  { id: "mgxAkr", label: "Dobromir - Jud. CT" },
  { id: "ZRxavm", label: "Dobromira - Jud. DJ" },
  { id: "m1n26r", label: "Dobromiru Din Deal - Jud. CT" },
  { id: "r3yy5l", label: "Dobrosloveni - Jud. OT" },
  { id: "rXap3Z", label: "Dobroslovesti - Jud. VS" },
  { id: "LmnPqZ", label: "Dobrot - Jud. AB" },
  { id: "r8Oy6m", label: "Dobrot - Jud. HD" },
  { id: "ZyYEDZ", label: "Dobrota - Jud. PH" },
  { id: "m1BB3r", label: "Dobroteasa - Jud. OT" },
  { id: "lpE9xm", label: "Dobrotesti - Jud. DJ" },
  { id: "ZPANBl", label: "Dobrotesti - Jud. TR" },
  { id: "r3BAqm", label: "Dobrotinet - Jud. OT" },
  { id: "2l5PNZ", label: "Dobrotu - Jud. AG" },
  { id: "Z43Lzr", label: "Dobrovat - Jud. IS" },
  { id: "lOBBLZ", label: "Dobrun - Jud. OT" },
  { id: "rdNNAl", label: "Dobrusa - Jud. VL" },
  { id: "r3085Z", label: "Docaneasa - Jud. VS" },
  { id: "ZQyKoZ", label: "Docani - Jud. VS" },
  { id: "Z4DEqZ", label: "Dochia - Jud. NT" },
  { id: "lpzWor", label: "Doclin - Jud. CS" },
  { id: "ZNYE7r", label: "Dodeni - Jud. NT" },
  { id: "r6xzpl", label: "Dodesti - Jud. VS" },
  { id: "rwEWQl", label: "Doftana - Jud. PH" },
  { id: "rXdWLr", label: "Dofteana - Jud. BC" },
  { id: "ElLVWr", label: "Dogaresti - Jud. AB" },
  { id: "Oro1nl", label: "Dogari - Jud. AG" },
  { id: "mGwx2m", label: "Dogari - Jud. BZ" },
  { id: "lW1Xkr", label: "Dognecea - Jud. CS" },
  { id: "l7yk5r", label: "Doh - Jud. SJ" },
  { id: "ZNG2Yl", label: "Doicesti - Jud. DB" },
  { id: "ZMYBGZ", label: "Doina - Jud. BT" },
  { id: "r9y5Mr", label: "Doina - Jud. NT" },
  { id: "ma6RWr", label: "Dolat - Jud. TM" },
  { id: "lD2PWr", label: "Dolcesti - Jud. GJ" },
  { id: "ZzEyDr", label: "Dolea - Jud. BH" },
  { id: "3ZVLRm", label: "Dolesti - Jud. AB" },
  { id: "rwE5Ql", label: "Dolhasca - Jud. SV" },
  { id: "ZyY84Z", label: "Dolheni - Jud. SJ" },
  { id: "m1qyRm", label: "Dolhesti - Jud. IS" },
  { id: "r3BjAm", label: "Dolhesti - Jud. NT" },
  { id: "mA9G6m", label: "Dolhesti - Jud. SV" },
  { id: "mgBX1l", label: "Dolhestii Mari - Jud. SV" },
  { id: "mb5aQl", label: "Dolhestii Mici - Jud. SV" },
  { id: "l51Jpl", label: "Dolina - Jud. BT" },
  { id: "l082Rl", label: "Dolina - Jud. CS" },
  { id: "mnEyxl", label: "Doljesti - Jud. NT" },
  { id: "lpA36m", label: "Dolu - Jud. SJ" },
  { id: "r2YWvm", label: "Doman - Jud. CS" },
  { id: "rBn02r", label: "Domanesti - Jud. SM" },
  { id: "mkwWqm", label: "Domasnea - Jud. CS" },
  { id: "4lOaOr", label: "Domnesti - Jud. AG" },
  { id: "leaKol", label: "Domnesti - Jud. BN" },
  { id: "rq0Jom", label: "Domnesti - Jud. IF" },
  { id: "lJ61gl", label: "Domnesti Sat - Jud. VN" },
  { id: "lYPM0Z", label: "Domnesti Targ - Jud. VN" },
  { id: "mnzvNm", label: "Domnin - Jud. SJ" },
  { id: "lJxpYr", label: "Domnita - Jud. IS" },
  { id: "rBOYaZ", label: "Domnu Tudor - Jud. DJ" },
  { id: "ZRJpeZ", label: "Domosu - Jud. CJ" },
  { id: "yZy4Yr", label: "Donceni - Jud. AR" },
  { id: "ZNwYVl", label: "Donesti - Jud. OT" },
  { id: "ma1Ebm", label: "Dopca - Jud. BV" },
  { id: "WZPwKr", label: "Doptau - Jud. AB" },
  { id: "ZQvLvl", label: "Dor Marunt - Jud. CL" },
  { id: "Wr61Wm", label: "Dorgos - Jud. AR" },
  { id: "rE7zQl", label: "Dorna - Jud. CJ" },
  { id: "ZNJwyZ", label: "Dorna Arini - Jud. SV" },
  { id: "Zzz93Z", label: "Dorna Candrenilor - Jud. SV" },
  { id: "rqPx0m", label: "Dorneni - Jud. BC" },
  { id: "l07OPZ", label: "Dornesti - Jud. NT" },
  { id: "r896DZ", label: "Dornesti - Jud. SV" },
  { id: "mbK9vr", label: "Dornisoara - Jud. SV" },
  { id: "mGx6pr", label: "Dorobant - Jud. IS" },
  { id: "olWVRZ", label: "Dorobanti - Jud. AR" },
  { id: "r82EVl", label: "Dorobanti - Jud. BT" },
  { id: "r3kPGr", label: "Dorobantu - Jud. CL" },
  { id: "ZyNe2l", label: "Dorobantu - Jud. CT" },
  { id: "mnpaxZ", label: "Dorobantu - Jud. TL" },
  { id: "ZNJDxZ", label: "Dorobantu - Jud. TR" },
  { id: "AZvRVZ", label: "Dorofei - Jud. BC" },
  { id: "ZzEKnr", label: "Dorohoi - Jud. BT" },
  { id: "ropWXZ", label: "Dorolea - Jud. BN" },
  { id: "r86PDl", label: "Dorolt - Jud. SM" },
  { id: "ZV14or", label: "Doroltu - Jud. CJ" },
  { id: "l7JgnZ", label: "Doroscani - Jud. IS" },
  { id: "ZV3jAl", label: "Doroteia - Jud. SV" },
  { id: "AZvjDZ", label: "Dos - Jud. AB" },
  { id: "ZQqRoZ", label: "Doseni - Jud. GJ" },
  { id: "BZ44zZ", label: "Dospinesti - Jud. BC" },
  { id: "r3Gn5r", label: "Dospinesti - Jud. DB" },
  { id: "BZ4Wzr", label: "Dostat - Jud. AB" },
  { id: "ZQvOol", label: "Dosu Bricii - Jud. CJ" },
  { id: "nljjbl", label: "Dosu Luncii - Jud. AB" },
  { id: "lLaB6m", label: "Dosu Napului - Jud. CJ" },
  { id: "lWMOor", label: "Dosu Raului - Jud. VL" },
  { id: "WZPDjZ", label: "Dosu Vasesti - Jud. AB" },
  { id: "ZQwMnm", label: "Dozesti - Jud. VL" },
  { id: "r99bNr", label: "Dracea - Jud. TR" },
  { id: "ZMJeRl", label: "Dracesti - Jud. TR" },
  { id: "r2V2vr", label: "Dracsani - Jud. BT" },
  { id: "ZzVPKZ", label: "Dracsani - Jud. TR" },
  { id: "r6xj6l", label: "Dracsenei - Jud. TR" },
  { id: "rK2e0m", label: "Draculea Bandului - Jud. MS" },
  { id: "lDAyJm", label: "Draga - Jud. BN" },
  { id: "ZM7WRm", label: "Dragaesti Pamanteni - Jud. DB" },
  { id: "leoqor", label: "Dragaesti Ungureni - Jud. DB" },
  { id: "ZQQOnZ", label: "Dragalina - Jud. BT" },
  { id: "m1nvqr", label: "Dragalina - Jud. CL" },
  { id: "r2q0vm", label: "Draganeasa - Jud. PH" },
  { id: "ZPBy3m", label: "Draganeasca - Jud. GR" },
  { id: "rdVzRl", label: "Draganescu - Jud. GR" },
  { id: "mnPLaZ", label: "Draganesti - Jud. BH" },
  { id: "mAOj6Z", label: "Draganesti - Jud. GL" },
  { id: "m1qQ6m", label: "Draganesti - Jud. IS" },
  { id: "rBjEDr", label: "Draganesti - Jud. NT" },
  { id: "ZNwjal", label: "Draganesti - Jud. PH" },
  { id: "m18kYl", label: "Draganesti - Jud. VL" },
  { id: "r99dyr", label: "Draganesti De Vede - Jud. TR" },
  { id: "lOAnNr", label: "Draganesti Olt - Jud. OT" },
  { id: "mgo4Bm", label: "Draganesti Vlasca - Jud. TR" },
  { id: "Zy5wqZ", label: "Draganu - Jud. VL" },
  { id: "XlDznr", label: "Draganu Olteni - Jud. AG" },
  { id: "ZPA0Dl", label: "Dragasani - Jud. VL" },
  { id: "l0pKJl", label: "Dragesti - Jud. BC" },
  { id: "lDA0jm", label: "Dragesti - Jud. BH" },
  { id: "leJbom", label: "Dragesti - Jud. VS" },
  { id: "yZyoom", label: "Draghescu - Jud. AG" },
  { id: "mGPJpr", label: "Draghesti - Jud. MH" },
  { id: "lJ2xWl", label: "Draghia - Jud. MM" },
  { id: "rqbAPZ", label: "Draghiceanu - Jud. GR" },
  { id: "mnzzpm", label: "Draghiceni - Jud. OT" },
  { id: "BZ4Jxm", label: "Draghicesti - Jud. AG" },
  { id: "ZzzpNZ", label: "Draghicesti - Jud. PH" },
  { id: "Xl0ORZ", label: "Draghici - Jud. AG" },
  { id: "lJNKwl", label: "Draghinesti - Jud. TR" },
  { id: "mGgxpm", label: "Dragioiu - Jud. VL" },
  { id: "rdVA2l", label: "Dragoaia - Jud. DJ" },
  { id: "leonYr", label: "Dragodana - Jud. DB" },
  { id: "mG1g2Z", label: "Dragodanesti - Jud. DB" },
  { id: "rXyz3Z", label: "Dragoeni - Jud. GJ" },
  { id: "rdo45m", label: "Dragoesti - Jud. IL" },
  { id: "lejEPm", label: "Dragoesti - Jud. VL" },
  { id: "l5943l", label: "Dragoiasa - Jud. SV" },
  { id: "lJP5er", label: "Dragoiesti - Jud. GJ" },
  { id: "l5982l", label: "Dragoiesti - Jud. SV" },
  { id: "lLJGPr", label: "Dragoiesti - Jud. TM" },
  { id: "Wr64pl", label: "Dragoiesti Lunca - Jud. AB" },
  { id: "qZN46m", label: "Dragolesti - Jud. AG" },
  { id: "lpVPWr", label: "Dragomanesti - Jud. VS" },
  { id: "JlJYwZ", label: "Dragomir - Jud. BC" },
  { id: "lpE6Mm", label: "Dragomiresti - Jud. DB" },
  { id: "lp516m", label: "Dragomiresti - Jud. MM" },
  { id: "lD612r", label: "Dragomiresti - Jud. NT" },
  { id: "ZvzBKZ", label: "Dragomiresti - Jud. TM" },
  { id: "r89XXZ", label: "Dragomiresti - Jud. VS" },
  { id: "rqEKgr", label: "Dragomiresti Deal - Jud. IF" },
  { id: "ZQVMol", label: "Dragomiresti Vale - Jud. IF" },
  { id: "lxEx0m", label: "Dragomirna - Jud. SV" },
  { id: "lOWLAl", label: "Dragos Voda - Jud. CL" },
  { id: "ZzVA3Z", label: "Dragosa - Jud. SV" },
  { id: "yZynol", label: "Dragoslavele - Jud. AG" },
  { id: "rB5MJm", label: "Dragosloveni - Jud. VN" },
  { id: "ZRjq2Z", label: "Dragoteni - Jud. BH" },
  { id: "ZNGkel", label: "Dragotesti - Jud. DJ" },
  { id: "rwDPQZ", label: "Dragotesti - Jud. GJ" },
  { id: "r8BMql", label: "Dragotesti - Jud. MH" },
  { id: "lOA3or", label: "Dragova - Jud. NT" },
  { id: "ZMJK5l", label: "Dragsina - Jud. TM" },
  { id: "lYJ2Om", label: "Dragsinesti - Jud. TM" },
  { id: "m1By9r", label: "Dragu - Jud. SJ" },
  { id: "rXqk4Z", label: "Dragu Brad - Jud. HD" },
  { id: "lp11bm", label: "Dragugesti - Jud. BC" },
  { id: "Z418Jl", label: "Dragulesti - Jud. VL" },
  { id: "rwn5Vm", label: "Dragus - Jud. BV" },
  { id: "r33G4r", label: "Dragusani - Jud. BC" },
  { id: "m1kA6Z", label: "Draguseni - Jud. BT" },
  { id: "Z4qVAl", label: "Draguseni - Jud. GL" },
  { id: "r2QKWm", label: "Draguseni - Jud. IS" },
  { id: "lj4Abr", label: "Draguseni - Jud. SM" },
  { id: "l0KwNZ", label: "Draguseni - Jud. SV" },
  { id: "rdox5m", label: "Dragutesti - Jud. GJ" },
  { id: "ZMGepm", label: "Drajna De Jos - Jud. PH" },
  { id: "Z49pJl", label: "Drajna De Sus - Jud. PH" },
  { id: "r6OPpZ", label: "Drajna Noua - Jud. CL" },
  { id: "VrX7Dm", label: "Drambar - Jud. AB" },
  { id: "l0Kj6Z", label: "Dranceni - Jud. VS" },
  { id: "r8YaqZ", label: "Dranic - Jud. DJ" },
  { id: "lWDKdm", label: "Dranovatu - Jud. OT" },
  { id: "Rr9odl", label: "Drasov - Jud. AB" },
  { id: "l7vDdZ", label: "Drauseni - Jud. BV" },
  { id: "Bmaq7m", label: "Draut - Jud. AR" },
  { id: "ZvzyVZ", label: "Draxeni - Jud. VS" },
  { id: "lxxNnl", label: "Draxini - Jud. BT" },
  { id: "rd51GZ", label: "Drencova - Jud. CS" },
  { id: "mA1L8m", label: "Dreptu - Jud. NT" },
  { id: "l08okl", label: "Dretea - Jud. CJ" },
  { id: "Jr330r", label: "Dric - Jud. AB" },
  { id: "mAAQ2m", label: "Dridif - Jud. BV" },
  { id: "roz1nm", label: "Dridu - Jud. IL" },
  { id: "r2Q8pm", label: "Dridu Snagov - Jud. IL" },
  { id: "mgBRql", label: "Drighiu - Jud. SJ" },
  { id: "mga7kZ", label: "Drincea - Jud. MH" },
  { id: "lLJvGr", label: "Drinova - Jud. TM" },
  { id: "ZP25Am", label: "Drislea - Jud. BT" },
  { id: "mb9wvl", label: "Dristie - Jud. CS" },
  { id: "ZzDPEm", label: "Drobeta Turnu Severin - Jud. MH" },
  { id: "rwpgXZ", label: "Drogu - Jud. BR" },
  { id: "mGPN6r", label: "Drojdii - Jud. MS" },
  { id: "rBOW5Z", label: "Dropia - Jud. CT" },
  { id: "ZR6b2Z", label: "Drujesti - Jud. VS" },
  { id: "rqPdBm", label: "Drumul Carului - Jud. BV" },
  { id: "l79eXr", label: "Dubesti - Jud. TM" },
  { id: "mnEWpl", label: "Dubistea De Padure - Jud. MS" },
  { id: "lj4q4r", label: "Dubiusca - Jud. SV" },
  { id: "r6Ga3Z", label: "Dubova - Jud. MH" },
  { id: "rKxe9Z", label: "Duboz - Jud. TM" },
  { id: "mbO9ar", label: "Duculesti - Jud. GJ" },
  { id: "AZveJr", label: "Dud - Jud. AR" },
  { id: "l7J6nZ", label: "Duda - Jud. HR" },
  { id: "rqOX1l", label: "Duda - Jud. VS" },
  { id: "rq0jBm", label: "Dudasu - Jud. MH" },
  { id: "rXOxLm", label: "Dudasu Schelei - Jud. MH" },
  { id: "ZQvvJl", label: "Dudescu - Jud. BR" },
  { id: "rqzbPZ", label: "Dudesti - Jud. BR" },
  { id: "rBb2gr", label: "Dudesti - Jud. HD" },
  { id: "ZQyBaZ", label: "Dudestii Noi - Jud. TM" },
  { id: "mk122Z", label: "Dudestii Vechi - Jud. TM" },
  { id: "mao39Z", label: "Dudovicesti - Jud. DJ" },
  { id: "rdoW4m", label: "Dudu - Jud. IF" },
  { id: "ZyWDYm", label: "Dudu - Jud. TR" },
  { id: "BZ4Kxr", label: "Duduieni - Jud. AB" },
  { id: "rXLq4r", label: "Dulbanu - Jud. BZ" },
  { id: "Zy6v4l", label: "Dulcea - Jud. MS" },
  { id: "ZQy85Z", label: "Dulceanca - Jud. TR" },
  { id: "XlDJJr", label: "Dulcele - Jud. AR" },
  { id: "ZMJWRl", label: "Dulceni - Jud. TR" },
  { id: "ZvAN5r", label: "Dulcesti - Jud. CT" },
  { id: "l7w4wl", label: "Dulcesti - Jud. NT" },
  { id: "ZvABxr", label: "Duleu - Jud. CS" },
  { id: "mkj8nl", label: "Dulgheru - Jud. CT" },
  { id: "JlJPAr", label: "Dumacesti - Jud. AB" },
  { id: "roxJXZ", label: "Dumasca - Jud. VS" },
  { id: "olW5dr", label: "Dumbrava - Jud. AB" },
  { id: "BZ4yql", label: "Dumbrava - Jud. AG" },
  { id: "krdvWl", label: "Dumbrava - Jud. AR" },
  { id: "lWBRqZ", label: "Dumbrava - Jud. BC" },
  { id: "l0pVgl", label: "Dumbrava - Jud. BH" },
  { id: "Zv2wVZ", label: "Dumbrava - Jud. BN" },
  { id: "ZQv6ol", label: "Dumbrava - Jud. CJ" },
  { id: "ZVLaJm", label: "Dumbrava - Jud. DB" },
  { id: "ZP7oBr", label: "Dumbrava - Jud. HD" },
  { id: "mGxzWr", label: "Dumbrava - Jud. IS" },
  { id: "rdxjWr", label: "Dumbrava - Jud. MM" },
  { id: "lp5MMm", label: "Dumbrava - Jud. MS" },
  { id: "rwPRvZ", label: "Dumbrava - Jud. NT" },
  { id: "r86kKl", label: "Dumbrava - Jud. PH" },
  { id: "ZNwBel", label: "Dumbrava - Jud. SM" },
  { id: "m1bkjr", label: "Dumbrava - Jud. SV" },
  { id: "mnpY8Z", label: "Dumbrava - Jud. TM" },
  { id: "ZRBxjZ", label: "Dumbrava - Jud. VL" },
  { id: "rwY0xl", label: "Dumbrava - Jud. VN" },
  { id: "ZVwgKl", label: "Dumbrava De Jos - Jud. HD" },
  { id: "lLVdWr", label: "Dumbrava De Jos - Jud. MH" },
  { id: "Z4DpjZ", label: "Dumbrava De Mijloc - Jud. MH" },
  { id: "lJxgWr", label: "Dumbrava De Sus - Jud. HD" },
  { id: "lxA6pm", label: "Dumbrava De Sus - Jud. MH" },
  { id: "lOApwr", label: "Dumbrava Deal - Jud. NT" },
  { id: "roENvl", label: "Dumbrava Rosie - Jud. NT" },
  { id: "m1kR6Z", label: "Dumbravani - Jud. BH" },
  { id: "l7vOqZ", label: "Dumbraveni - Jud. BN" },
  { id: "lYxxRZ", label: "Dumbraveni - Jud. CT" },
  { id: "lYxQOZ", label: "Dumbraveni - Jud. GJ" },
  { id: "lOD0Gm", label: "Dumbraveni - Jud. IF" },
  { id: "ZV3VKl", label: "Dumbraveni - Jud. SB" },
  { id: "lOY6Nr", label: "Dumbraveni - Jud. SV" },
  { id: "mA8eBl", label: "Dumbraveni - Jud. VN" },
  { id: "lLJnyr", label: "Dumbraveni - Jud. VS" },
  { id: "WmGEpZ", label: "Dumbravesti - Jud. AG" },
  { id: "rER0LZ", label: "Dumbravesti - Jud. PH" },
  { id: "mk5pwZ", label: "Dumbravesti - Jud. VL" },
  { id: "ZzDLKm", label: "Dumbravioara - Jud. MS" },
  { id: "Bma5jm", label: "Dumbravita - Jud. AB" },
  { id: "Oro4XZ", label: "Dumbravita - Jud. AR" },
  { id: "ZRjGNZ", label: "Dumbravita - Jud. BH" },
  { id: "rqPwom", label: "Dumbravita - Jud. BN" },
  { id: "lYDNwZ", label: "Dumbravita - Jud. BT" },
  { id: "mbPaoZ", label: "Dumbravita - Jud. BV" },
  { id: "ZRw0Ll", label: "Dumbravita - Jud. HD" },
  { id: "lpbqor", label: "Dumbravita - Jud. IS" },
  { id: "r6GpDZ", label: "Dumbravita - Jud. MH" },
  { id: "mGPa9r", label: "Dumbravita - Jud. MM" },
  { id: "ZVywBr", label: "Dumbravita - Jud. SV" },
  { id: "mA9Nqm", label: "Dumbravita - Jud. TM" },
  { id: "rwn33m", label: "Dumbravita De Codru - Jud. BH" },
  { id: "lLennm", label: "Dumeni - Jud. BT" },
  { id: "olWxkm", label: "Dumesti - Jud. AB" },
  { id: "lYKWOr", label: "Dumesti - Jud. HD" },
  { id: "lx70wZ", label: "Dumesti - Jud. IS" },
  { id: "ZR6pLZ", label: "Dumesti - Jud. VS" },
  { id: "m1bear", label: "Dumestii Vechi - Jud. VS" },
  { id: "Xl0WJZ", label: "Dumiresti - Jud. AG" },
  { id: "2l5jYm", label: "Dumitra - Jud. AB" },
  { id: "rqPMom", label: "Dumitra - Jud. BN" },
  { id: "lOD0Am", label: "Dumitrana - Jud. IF" },
  { id: "ZzD1Wm", label: "Dumitreni - Jud. MS" },
  { id: "mbOAYr", label: "Dumitresti - Jud. IL" },
  { id: "r3yBnl", label: "Dumitresti - Jud. OT" },
  { id: "ZNDj1m", label: "Dumitresti - Jud. VN" },
  { id: "mnE4jl", label: "Dumitrestii - Jud. MS" },
  { id: "rqxg1m", label: "Dumitrestii De Sus - Jud. VN" },
  { id: "ZQwNJm", label: "Dumitrestii Fata - Jud. VN" },
  { id: "m1qAgm", label: "Dumitrestii Galatii - Jud. IS" },
  { id: "r33LGr", label: "Dumitrita - Jud. BN" },
  { id: "lJ9bwZ", label: "Dumuslau - Jud. SJ" },
  { id: "rwynvl", label: "Dunarea - Jud. CT" },
  { id: "lj3D4m", label: "Dunarea Mica - Jud. MH" },
  { id: "lW16or", label: "Dunareni - Jud. CT" },
  { id: "leJKXm", label: "Dunavatu De Jos - Jud. TL" },
  { id: "lpVdPr", label: "Dunavatu De Sus - Jud. TL" },
  { id: "5l76wZ", label: "Dupa Deal - Jud. AB" },
  { id: "rqPogm", label: "Dupa Deal - Jud. BN" },
  { id: "mkjgzl", label: "Dupa Deal - Jud. DB" },
  { id: "lOAEKr", label: "Dupa Deal - Jud. MS" },
  { id: "ZvOQ2l", label: "Dupa Magura - Jud. VN" },
  { id: "4lOWol", label: "Dupa Plese - Jud. AB" },
  { id: "mA4Mgl", label: "Dupapiatra - Jud. HD" },
  { id: "rwEKKl", label: "Dupus - Jud. SB" },
  { id: "WmGx9r", label: "Durasti - Jud. AB" },
  { id: "lOwO3l", label: "Durnesti - Jud. BT" },
  { id: "l07wJZ", label: "Durusa - Jud. MM" },
  { id: "mgP41r", label: "Dusesti - Jud. BH" },
  { id: "mgaq1Z", label: "Dusesti - Jud. NT" },
  { id: "Zvgwxl", label: "Dutulesti - Jud. DJ" },
  { id: "lJWapm", label: "Eforie Nord - Jud. CT" },
  { id: "ZV1xAr", label: "Eforie Sud - Jud. CT" },
  { id: "ZRJX2Z", label: "Eftimie Murgu - Jud. CS" },
  { id: "ZM9J4Z", label: "Eghersec - Jud. HR" },
  { id: "ZPqRAl", label: "Ehreste - Jud. SV" },
  { id: "mbxYYZ", label: "Eibenthal - Jud. MH" },
  { id: "lW1NEr", label: "Elciu - Jud. CJ" },
  { id: "lWO2kl", label: "Eliseni - Jud. HR" },
  { id: "lOY4Or", label: "Emil Racovita - Jud. VS" },
  { id: "Jr3XGZ", label: "Enachesti - Jud. BC" },
  { id: "lLeXAm", label: "Enciu - Jud. BN" },
  { id: "arB1bm", label: "Enculesti - Jud. AG" },
  { id: "l79Pwr", label: "Enisala - Jud. TL" },
  { id: "rwP63Z", label: "Enosesti - Jud. OT" },
  { id: "rdKGWm", label: "Epureni - Jud. VS" },
  { id: "rE2ngZ", label: "Erbiceni - Jud. IS" },
  { id: "lLVqWr", label: "Ercea - Jud. MH" },
  { id: "r9ywdr", label: "Ercea - Jud. MS" },
  { id: "rwPqXZ", label: "Eremieni - Jud. MS" },
  { id: "lD6vbr", label: "Eremitu - Jud. MS" },
  { id: "ZQV7Dl", label: "Erghevita - Jud. MH" },
  { id: "rqp8Bm", label: "Eriu Sancrai - Jud. SM" },
  { id: "lJ97WZ", label: "Ernea - Jud. SB" },
  { id: "l7w2vl", label: "Ernei - Jud. MS" },
  { id: "rwpa3Z", label: "Ersig - Jud. CS" },
  { id: "mk8vBr", label: "Esanca - Jud. BT" },
  { id: "mkwDQm", label: "Escu - Jud. CJ" },
  { id: "r8Y2KZ", label: "Esechioi - Jud. CT" },
  { id: "r8BWEl", label: "Eselnita - Jud. MH" },
  { id: "ZPe2jl", label: "Esna - Jud. BR" },
  { id: "lpE5om", label: "Estelnic - Jud. CV" },
  { id: "r6yPpm", label: "Eteni - Jud. SM" },
  { id: "r9DRxm", label: "Ezeni - Jud. PH" },
  { id: "mnJWjm", label: "Ezeris - Jud. CS" },
  { id: "ma5Y2m", label: "Fabrica - Jud. SJ" },
  { id: "lD2LXr", label: "Facaeni - Jud. IL" },
  { id: "lpEaMm", label: "Facai - Jud. DJ" },
  { id: "l79qAr", label: "Facai - Jud. VL" },
  { id: "lDqoWm", label: "Facaieni - Jud. PH" },
  { id: "Qmb4YZ", label: "Facaletesti - Jud. AG" },
  { id: "lDP71l", label: "Facau - Jud. GR" },
  { id: "leoaXr", label: "Faclia - Jud. CT" },
  { id: "r89v6Z", label: "Fadimac - Jud. TM" },
  { id: "Zyen2Z", label: "Fagaras - Jud. BV" },
  { id: "r89PqZ", label: "Fagarasu Nou - Jud. TL" },
  { id: "lL1nXm", label: "Fagat - Jud. IS" },
  { id: "3ZVaxr", label: "Faget - Jud. AB" },
  { id: "r823Pl", label: "Faget - Jud. BC" },
  { id: "mAOeWZ", label: "Faget - Jud. DJ" },
  { id: "leGP3r", label: "Faget - Jud. PH" },
  { id: "rBRNgr", label: "Faget - Jud. TM" },
  { id: "r3335r", label: "Fagetel - Jud. BC" },
  { id: "ZM90RZ", label: "Fagetel - Jud. HD" },
  { id: "mA4x2l", label: "Fagetel - Jud. HR" },
  { id: "ZyYY8Z", label: "Fagetelu - Jud. OT" },
  { id: "5l7Pvm", label: "Fagetu - Jud. AG" },
  { id: "rwyxxl", label: "Fagetu - Jud. DB" },
  { id: "r3ydYl", label: "Fagetu - Jud. PH" },
  { id: "ZV3EJl", label: "Fagetu - Jud. SJ" },
  { id: "lxzewr", label: "Fagetu - Jud. VN" },
  { id: "Xlxg0Z", label: "Fagetu De Jos - Jud. AB" },
  { id: "PrEE5r", label: "Fagetu De Sus - Jud. AB" },
  { id: "l0pBQl", label: "Fagetu De Sus - Jud. BC" },
  { id: "ZPe3Dl", label: "Fagetu Ierii - Jud. CJ" },
  { id: "rKjjEm", label: "Faghieni - Jud. BC" },
  { id: "r8Y8MZ", label: "Falastoaca - Jud. GR" },
  { id: "rqzAQZ", label: "Falca - Jud. CJ" },
  { id: "r6yE5m", label: "Falcau - Jud. SV" },
  { id: "roxV3Z", label: "Falciu - Jud. VS" },
  { id: "rKDDEZ", label: "Falcoiu - Jud. OT" },
  { id: "mAGzBZ", label: "Falcusa - Jud. SJ" },
  { id: "4lO8Jm", label: "Falfani - Jud. AG" },
  { id: "leG7Jr", label: "Falticeni - Jud. SV" },
  { id: "rKD5QZ", label: "Fanari - Jud. PH" },
  { id: "r82dKl", label: "Fanate - Jud. BH" },
  { id: "mbPpKZ", label: "Fanate - Jud. BN" },
  { id: "ZzoNBl", label: "Fanate - Jud. CJ" },
  { id: "r9ynXr", label: "Fanate - Jud. MM" },
  { id: "roEMql", label: "Fanate - Jud. MS" },
  { id: "rdxk4r", label: "Fanatele Capusului - Jud. MS" },
  { id: "ZVd0xm", label: "Fanatele Madarasului - Jud. MS" },
  { id: "l7vP3Z", label: "Fanatele Silivasului - Jud. BN" },
  { id: "rE6ogZ", label: "Fanatele Socolului - Jud. MS" },
  { id: "lD2K2r", label: "Fancel - Jud. HR" },
  { id: "r9qVMr", label: "Fancica - Jud. BH" },
  { id: "Zv283Z", label: "Fantana - Jud. BV" },
  { id: "lWOVyl", label: "Fantana - Jud. HD" },
  { id: "lp5G6m", label: "Fantana Babii - Jud. MS" },
  { id: "mnp6jZ", label: "Fantana Blanarului - Jud. VS" },
  { id: "rwDVvZ", label: "Fantana Brazilor - Jud. HR" },
  { id: "l71oLr", label: "Fantana Doamnei - Jud. CL" },
  { id: "l57o3r", label: "Fantana Domneasca - Jud. MH" },
  { id: "lYxLJZ", label: "Fantana Mare - Jud. CT" },
  { id: "lOYewr", label: "Fantana Mare - Jud. SV" },
  { id: "rEaXLr", label: "Fantana Mare - Jud. TL" },
  { id: "lDaXnm", label: "Fantana Oilor - Jud. TL" },
  { id: "wlpJor", label: "Fantanea - Jud. AG" },
  { id: "AZvE5r", label: "Fantanele - Jud. AB" },
  { id: "yZRXAm", label: "Fantanele - Jud. AR" },
  { id: "r82DXl", label: "Fantanele - Jud. BC" },
  { id: "ZzExbr", label: "Fantanele - Jud. BN" },
  { id: "mgAE9Z", label: "Fantanele - Jud. BZ" },
  { id: "rwpovZ", label: "Fantanele - Jud. CT" },
  { id: "l0XM0l", label: "Fantanele - Jud. DB" },
  { id: "rwyBvl", label: "Fantanele - Jud. DJ" },
  { id: "ZyGGkm", label: "Fantanele - Jud. GJ" },
  { id: "ZRx9Km", label: "Fantanele - Jud. GL" },
  { id: "ZvoV3l", label: "Fantanele - Jud. IS" },
  { id: "roEp3l", label: "Fantanele - Jud. MM" },
  { id: "rBjobr", label: "Fantanele - Jud. MS" },
  { id: "ZR30vl", label: "Fantanele - Jud. PH" },
  { id: "ZvEyJr", label: "Fantanele - Jud. SB" },
  { id: "rBnLJr", label: "Fantanele - Jud. SJ" },
  { id: "ZR63KZ", label: "Fantanele - Jud. SV" },
  { id: "mnpdAZ", label: "Fantanele - Jud. TR" },
  { id: "lDagKm", label: "Fantanele - Jud. VS" },
  { id: "ZPqPBl", label: "Fantanele Rus - Jud. SJ" },
  { id: "lpENxm", label: "Fantani - Jud. DJ" },
  { id: "m1Degl", label: "Fantani - Jud. GL" },
  { id: "m1GoRZ", label: "Fantanile Negre - Jud. MH" },
  { id: "l7vY5Z", label: "Fantanita - Jud. BN" },
  { id: "mGP6wr", label: "Faragau - Jud. MS" },
  { id: "Zy548Z", label: "Faraoanele - Jud. VN" },
  { id: "Z4Ykql", label: "Faraoani - Jud. BC" },
  { id: "rox8wZ", label: "Farasesti - Jud. TM" },
  { id: "Xlxpwl", label: "Farau - Jud. AB" },
  { id: "ZQGx5m", label: "Farcadin - Jud. HD" },
  { id: "l52R3m", label: "Farcas - Jud. DJ" },
  { id: "maN92r", label: "Farcas - Jud. VN" },
  { id: "mk8EBr", label: "Farcasa - Jud. BC" },
  { id: "rwPVjZ", label: "Farcasa - Jud. MM" },
  { id: "mGPKWr", label: "Farcasa - Jud. NT" },
  { id: "r2qq9m", label: "Farcasele - Jud. OT" },
  { id: "rozVxm", label: "Farcaseni - Jud. IS" },
  { id: "rBbjar", label: "Farcasesti - Jud. GJ" },
  { id: "rKna9m", label: "Farcasesti Mosneni - Jud. GJ" },
  { id: "rER9oZ", label: "Farcasu De Jos - Jud. OT" },
  { id: "lDaNXm", label: "Fardea - Jud. TM" },
  { id: "rodeLZ", label: "Farliug - Jud. CS" },
  { id: "lxbYQr", label: "Fartanesti - Jud. GL" },
  { id: "rK6a9m", label: "Fartatesti - Jud. VL" },
  { id: "mG5N6Z", label: "Fasca - Jud. BH" },
  { id: "l792Lr", label: "Fastaci - Jud. VS" },
  { id: "yZy2Ll", label: "Fata - Jud. AB" },
  { id: "4lOpJm", label: "Fata - Jud. AG" },
  { id: "9m1k8Z", label: "Fata Abrudului - Jud. AB" },
  { id: "roELvl", label: "Fata Cremenii - Jud. MH" },
  { id: "Qmb9al", label: "Fata Cristesei - Jud. AB" },
  { id: "ElLDPl", label: "Fata Lapusului - Jud. AB" },
  { id: "AZvpPm", label: "Fata Lazesti - Jud. AB" },
  { id: "ZvAO2r", label: "Fata Lui Nan - Jud. BZ" },
  { id: "lp5Lpm", label: "Fata Motrului - Jud. MH" },
  { id: "3r24gl", label: "Fata Pietrii - Jud. AB" },
  { id: "l0N00r", label: "Fata Rosie - Jud. HD" },
  { id: "mAA72m", label: "Faurei - Jud. BR" },
  { id: "ZPenKl", label: "Faurei - Jud. CL" },
  { id: "lOWn3l", label: "Faurei - Jud. CT" },
  { id: "rwP5VZ", label: "Faurei - Jud. NT" },
  { id: "lJ6ewl", label: "Faurei - Jud. VN" },
  { id: "ZRJJKZ", label: "Faurei Sat - Jud. BR" },
  { id: "rd5QzZ", label: "Faureni - Jud. CJ" },
  { id: "rq0bQm", label: "Fauresti - Jud. MM" },
  { id: "lpxvol", label: "Fauresti - Jud. VL" },
  { id: "rBj9ar", label: "Fauroaia - Jud. MH" },
  { id: "majpNr", label: "Fedeleseni - Jud. IS" },
  { id: "Bma4jl", label: "Fedelesoiu - Jud. AG" },
  { id: "ZPAbkl", label: "Fedelesoiu - Jud. VL" },
  { id: "rXqv3Z", label: "Federi - Jud. HD" },
  { id: "mA9Bgm", label: "Fedesti - Jud. VS" },
  { id: "mGWgBm", label: "Fefelei - Jud. PH" },
  { id: "rop4NZ", label: "Fegernic - Jud. BH" },
  { id: "ma1REm", label: "Fegernicu Nou - Jud. BH" },
  { id: "EZzP3m", label: "Feisa - Jud. AB" },
  { id: "mkwGPm", label: "Feiurdeni - Jud. CJ" },
  { id: "rEQw5r", label: "Felcheriu - Jud. BH" },
  { id: "lLeo1m", label: "Feldioara - Jud. BV" },
  { id: "ZRJ9LZ", label: "Feldioara - Jud. CJ" },
  { id: "maN02r", label: "Feldioara - Jud. VN" },
  { id: "ZQQWoZ", label: "Feldru - Jud. BN" },
  { id: "ljGAkm", label: "Feleac - Jud. BN" },
  { id: "lJWJAm", label: "Feleacu - Jud. CJ" },
  { id: "lD6jYr", label: "Feleag - Jud. MS" },
  { id: "rBbJ2r", label: "Feliceni - Jud. HR" },
  { id: "l7vMwZ", label: "Felmer - Jud. BV" },
  { id: "5l7xAm", label: "Felnac - Jud. AR" },
  { id: "r33aer", label: "Feneris - Jud. BH" },
  { id: "5l71vr", label: "Fenes - Jud. AB" },
  { id: "ZMRDzl", label: "Fenes - Jud. CS" },
  { id: "5l773l", label: "Fenis - Jud. AR" },
  { id: "mGxLWr", label: "Feredeni - Jud. IS" },
  { id: "lODzGm", label: "Feregi - Jud. HD" },
  { id: "r2M5dm", label: "Ferendia - Jud. TM" },
  { id: "EZzD8m", label: "Feresti - Jud. AB" },
  { id: "rE62BZ", label: "Feresti - Jud. MM" },
  { id: "rdKY7m", label: "Feresti - Jud. VS" },
  { id: "lDAPJm", label: "Ferestrau Oituz - Jud. BC" },
  { id: "ZRxvLm", label: "Ferestre - Jud. DB" },
  { id: "lOwx3l", label: "Ferice - Jud. BH" },
  { id: "ZRK3el", label: "Fericea - Jud. MM" },
  { id: "krwVvm", label: "Fericet - Jud. AB" },
  { id: "rXOORm", label: "Fersig - Jud. MM" },
  { id: "Bma1Wm", label: "Feteni - Jud. AB" },
  { id: "r9nAyZ", label: "Feteni - Jud. DB" },
  { id: "r89x6Z", label: "Feteni - Jud. VL" },
  { id: "l7JPvZ", label: "Fetesti - Jud. IL" },
  { id: "rBbzVr", label: "Fetesti - Jud. IS" },
  { id: "lpARem", label: "Fetesti - Jud. SV" },
  { id: "rqxa1m", label: "Fetesti - Jud. VN" },
  { id: "rwDOyZ", label: "Fetesti Gara - Jud. IL" },
  { id: "mA8a6l", label: "Fetig - Jud. VN" },
  { id: "r3yznl", label: "Fetindia - Jud. SJ" },
  { id: "mAAkBm", label: "Fiad - Jud. BN" },
  { id: "rdK0qm", label: "Fibis - Jud. TM" },
  { id: "Rr9Adr", label: "Ficaresti - Jud. AB" },
  { id: "ZzV1EZ", label: "Ficatar - Jud. TM" },
  { id: "lOwDnl", label: "Fichitesti - Jud. BC" },
  { id: "lpEVWm", label: "Fieni - Jud. DB" },
  { id: "Z4qopl", label: "Fierbinti - Jud. DB" },
  { id: "m1qM8m", label: "Fierbinti Targ - Jud. IL" },
  { id: "lODRKm", label: "Fierbintii De Jos - Jud. IL" },
  { id: "ZyGKDm", label: "Fierbintii De Sus - Jud. IL" },
  { id: "lWBgNZ", label: "Figa - Jud. BN" },
  { id: "ZzgLEl", label: "Filaret - Jud. DJ" },
  { id: "lOB3nZ", label: "Fildu De Jos - Jud. SJ" },
  { id: "m1Bvdr", label: "Fildu De Mijloc - Jud. SJ" },
  { id: "lOBLGZ", label: "Fildu De Sus - Jud. SJ" },
  { id: "maxGel", label: "Filea - Jud. MS" },
  { id: "lYyNAl", label: "Filea De Jos - Jud. CJ" },
  { id: "mAbjBr", label: "Filea De Sus - Jud. CJ" },
  { id: "ZNGAQl", label: "Filia - Jud. CV" },
  { id: "rqEpdr", label: "Filias - Jud. HR" },
  { id: "ZyNp0l", label: "Filiasi - Jud. DJ" },
  { id: "rKnEPm", label: "Filiasi - Jud. IS" },
  { id: "mganDZ", label: "Filioara - Jud. NT" },
  { id: "lxxG7l", label: "Filipeni - Jud. BC" },
  { id: "rEQV6r", label: "Filipesti - Jud. BC" },
  { id: "r8DDDr", label: "Filipesti - Jud. BR" },
  { id: "l58NvZ", label: "Filipestii De Padure - Jud. PH" },
  { id: "l0w2RZ", label: "Filipestii De Targ - Jud. PH" },
  { id: "rXOJem", label: "Filitelnic - Jud. MS" },
  { id: "mGww2m", label: "Filiu - Jud. BR" },
  { id: "mGxOdr", label: "Filpea - Jud. HR" },
  { id: "ZQVevl", label: "Filpisu Mare - Jud. MS" },
  { id: "r3BwGm", label: "Filpisu Mic - Jud. MS" },
  { id: "r3kgnr", label: "Finciu - Jud. CJ" },
  { id: "l7v3LZ", label: "Finis - Jud. BH" },
  { id: "rK3p9r", label: "Finisel - Jud. CJ" },
  { id: "mG1D2Z", label: "Finta Mare - Jud. DB" },
  { id: "rBOBdZ", label: "Finta Veche - Jud. DB" },
  { id: "mb9nql", label: "Fintesti - Jud. BZ" },
  { id: "ZQVQol", label: "Finteusu Mare - Jud. MM" },
  { id: "Z4DDJZ", label: "Finteusu Mic - Jud. MM" },
  { id: "r66D6r", label: "Fintoag - Jud. HD" },
  { id: "ZMJ8pl", label: "Firesti - Jud. VL" },
  { id: "ZQwVdm", label: "Firijba - Jud. VL" },
  { id: "7rq8om", label: "Firiteaz - Jud. AR" },
  { id: "Zv522r", label: "Firiza - Jud. MM" },
  { id: "ZMVW4l", label: "Firizu - Jud. MH" },
  { id: "ZyYX4Z", label: "Firminis - Jud. SJ" },
  { id: "ZNAJar", label: "Firtanus - Jud. HR" },
  { id: "ZNAaer", label: "Firtusu - Jud. HR" },
  { id: "rXxLLr", label: "Fiscalia - Jud. VL" },
  { id: "AZQgom", label: "Fiscut - Jud. AR" },
  { id: "l51Evl", label: "Fiser - Jud. BV" },
  { id: "ZRJKDZ", label: "Fisici - Jud. BZ" },
  { id: "lxA1Qm", label: "Fitcau - Jud. MS" },
  { id: "r84kXr", label: "Fitionesti - Jud. VN" },
  { id: "ZM968Z", label: "Fitod - Jud. HR" },
  { id: "lJWMpm", label: "Fizes - Jud. CS" },
  { id: "lODVAm", label: "Fizes - Jud. HD" },
  { id: "rX7w3m", label: "Fizes - Jud. SJ" },
  { id: "Z43Bpr", label: "Fizesti - Jud. HD" },
  { id: "mAbqor", label: "Fizesu Gherlii - Jud. CJ" },
  { id: "ZQQ7DZ", label: "Fizis - Jud. BH" },
  { id: "lDALYm", label: "Flamanzi - Jud. BT" },
  { id: "yZRerY", label: "Flitesti - Jud. AB" },
  { id: "r6bYXr", label: "Flondora - Jud. BT" },
  { id: "mnx0GZ", label: "Floran - Jud. DJ" },
  { id: "lpVMpr", label: "Floreni - Jud. VS" },
  { id: "maoEJZ", label: "Floresteni - Jud. GJ" },
  { id: "nljyqZ", label: "Floresti - Jud. AB" },
  { id: "ma15Wm", label: "Floresti - Jud. BC" },
  { id: "mAADgm", label: "Floresti - Jud. BN" },
  { id: "ZRjRjZ", label: "Floresti - Jud. BT" },
  { id: "rwpDoZ", label: "Floresti - Jud. BZ" },
  { id: "r8DxVr", label: "Floresti - Jud. CJ" },
  { id: "lj9ALZ", label: "Floresti - Jud. DJ" },
  { id: "m1qq6m", label: "Floresti - Jud. GJ" },
  { id: "lLDwbl", label: "Floresti - Jud. GR" },
  { id: "lOAVJr", label: "Floresti - Jud. MH" },
  { id: "rd732m", label: "Floresti - Jud. PH" },
  { id: "l7ydvr", label: "Floresti - Jud. SB" },
  { id: "lJNO9l", label: "Floresti - Jud. TL" },
  { id: "l79Eqr", label: "Floresti - Jud. VS" },
  { id: "lpz1Wr", label: "Florica - Jud. BR" },
  { id: "leLjdZ", label: "Florica - Jud. BZ" },
  { id: "ZV1Gxr", label: "Florica - Jud. CL" },
  { id: "mgoJ5m", label: "Florica - Jud. TR" },
  { id: "yZRaer", label: "Florieni - Jud. AG" },
  { id: "mkwQ2m", label: "Floriile - Jud. CT" },
  { id: "lj89qr", label: "Florinta - Jud. SV" },
  { id: "lWeOBr", label: "Floroaia - Jud. CV" },
  { id: "leLM6Z", label: "Floroaica - Jud. CL" },
  { id: "ZzzEWZ", label: "Floru - Jud. OT" },
  { id: "Z41Vzl", label: "Focsa - Jud. VS" },
  { id: "mkwgQm", label: "Focsanei - Jud. BZ" },
  { id: "rqx39m", label: "Focsani - Jud. VN" },
  { id: "ma6D2r", label: "Focseasca - Jud. VS" },
  { id: "ZM9NyZ", label: "Focuri - Jud. IS" },
  { id: "ZMRERl", label: "Fodora - Jud. CJ" },
  { id: "mAGXgZ", label: "Fodora - Jud. SJ" },
  { id: "l79BYr", label: "Foeni - Jud. TM" },
  { id: "mgBzDl", label: "Fofeldea - Jud. SB" },
  { id: "rXd07r", label: "Foglas - Jud. BH" },
  { id: "mGPo2r", label: "Foi - Jud. MS" },
  { id: "l58P2Z", label: "Foieni - Jud. SM" },
  { id: "ZQqenZ", label: "Foisor - Jud. DJ" },
  { id: "lYJoXm", label: "Folea - Jud. TM" },
  { id: "mnd7xZ", label: "Folestii De Jos - Jud. VL" },
  { id: "lDK52Z", label: "Folestii De Sus - Jud. VL" },
  { id: "rwD9aZ", label: "Folt - Jud. HD" },
  { id: "rEdgom", label: "Foltesti - Jud. GL" },
  { id: "r6b47r", label: "Fonau - Jud. BH" },
  { id: "rqEkBr", label: "Forasti - Jud. IS" },
  { id: "rdK77m", label: "Forasti - Jud. SV" },
  { id: "ZMYwgZ", label: "Forau - Jud. BH" },
  { id: "ZyGL0m", label: "Fornadia - Jud. HD" },
  { id: "rdjdAl", label: "Forosig - Jud. BH" },
  { id: "lDDXGl", label: "Forotic - Jud. CS" },
  { id: "r2QbWm", label: "Forteni - Jud. HR" },
  { id: "Z4Nwqm", label: "Fotesti - Jud. VL" },
  { id: "lDDWbl", label: "Fotin - Jud. BZ" },
  { id: "rwyP3l", label: "Fotos - Jud. CV" },
  { id: "mA1O4m", label: "Francenii Boiului - Jud. MM" },
  { id: "ZNwLVl", label: "Francenii De Piatra - Jud. SJ" },
  { id: "rXqLLZ", label: "Francesti - Jud. GJ" },
  { id: "lWMQkr", label: "Francesti - Jud. VL" },
  { id: "lL61Wr", label: "Francesti Coasta - Jud. VL" },
  { id: "Zvgv5l", label: "Frasin - Jud. DJ" },
  { id: "r8OOem", label: "Frasin - Jud. GJ" },
  { id: "rKDYGZ", label: "Frasin - Jud. SV" },
  { id: "lYxvVZ", label: "Frasin Deal - Jud. DB" },
  { id: "mAOVaZ", label: "Frasin Vale - Jud. DB" },
  { id: "ZPGqMr", label: "Frasina - Jud. VL" },
  { id: "lYyG0l", label: "Frasinet - Jud. BZ" },
  { id: "rwpXxZ", label: "Frasinet - Jud. CJ" },
  { id: "mgAR5Z", label: "Frasinet - Jud. CL" },
  { id: "mkz5Pl", label: "Frasinet - Jud. PH" },
  { id: "l798qr", label: "Frasinet - Jud. TR" },
  { id: "ZR3KDl", label: "Frasinet Gara - Jud. OT" },
  { id: "rK2R0m", label: "Frasinetu - Jud. OT" },
  { id: "Z4weAr", label: "Frasinetu De Jos - Jud. CL" },
  { id: "m1n0Rr", label: "Frasinis - Jud. CS" },
  { id: "r3GvGr", label: "Frasinu - Jud. DB" },
  { id: "mgxk5r", label: "Frasinu - Jud. GR" },
  { id: "mGaB2Z", label: "Frasinu - Jud. VS" },
  { id: "m1qzam", label: "Frasuleni - Jud. IS" },
  { id: "l54zpZ", label: "Frata - Jud. CJ" },
  { id: "roxbxZ", label: "Fratautii Noi - Jud. SV" },
  { id: "ma65Nr", label: "Fratautii Vechi - Jud. SV" },
  { id: "nljddr", label: "Fratesti - Jud. AG" },
  { id: "lD2Abr", label: "Fratesti - Jud. GJ" },
  { id: "l7Qz3r", label: "Fratesti - Jud. GR" },
  { id: "yZyoKm", label: "Fratici - Jud. AG" },
  { id: "r6q82m", label: "Fratila - Jud. DJ" },
  { id: "lODb3m", label: "Fratilesti - Jud. IL" },
  { id: "mG1dwZ", label: "Fratostita - Jud. DJ" },
  { id: "m1njgr", label: "Frecatei - Jud. BR" },
  { id: "l79owr", label: "Frecatei - Jud. TL" },
  { id: "mA8val", label: "Frecatei - Jud. VN" },
  { id: "ZVwJOl", label: "Frenciugi - Jud. IS" },
  { id: "LZMPyr", label: "Frumoasa - Jud. BC" },
  { id: "m1qRam", label: "Frumoasa - Jud. HR" },
  { id: "lWv1BZ", label: "Frumoasa - Jud. SV" },
  { id: "lLJ26r", label: "Frumoasa - Jud. TR" },
  { id: "m1GE6Z", label: "Frumosu - Jud. NT" },
  { id: "mbKLQr", label: "Frumosu - Jud. SV" },
  { id: "mb9A2l", label: "Frumusani - Jud. CL" },
  { id: "rwDnyZ", label: "Frumusei - Jud. GJ" },
  { id: "ZP263m", label: "Frumuselu - Jud. BC" },
  { id: "krwqxl", label: "Frumuseni - Jud. AR" },
  { id: "ZyeJkZ", label: "Frumusica - Jud. BT" },
  { id: "mGxzdr", label: "Frumusica - Jud. IS" },
  { id: "ZM7qgm", label: "Frumusita - Jud. GL" },
  { id: "lWBKRZ", label: "Fruntesti - Jud. BC" },
  { id: "ma62Nr", label: "Fruntiseni - Jud. VS" },
  { id: "m1qk8m", label: "Frunza - Jud. GJ" },
  { id: "mGW12m", label: "Frunzaru - Jud. OT" },
  { id: "leoXPr", label: "Frunzeasca - Jud. GL" },
  { id: "rE6BQZ", label: "Frunzeni - Jud. MS" },
  { id: "ZRKyyl", label: "Frunzeni - Jud. NT" },
  { id: "mb5ABl", label: "Fufez - Jud. SJ" },
  { id: "ZMYK8Z", label: "Fughiu - Jud. BH" },
  { id: "lLa7Am", label: "Fulga - Jud. BZ" },
  { id: "rERWxZ", label: "Fulga De Jos - Jud. PH" },
  { id: "ZMGWpm", label: "Fulga De Sus - Jud. PH" },
  { id: "ropzqZ", label: "Fulgeris - Jud. BC" },
  { id: "l79N5r", label: "Fulgu - Jud. VS" },
  { id: "rdNV5l", label: "Fumureni - Jud. VL" },
  { id: "ZzE9Dr", label: "Fundata - Jud. BV" },
  { id: "lLax6m", label: "Fundata - Jud. BZ" },
  { id: "rqEMvr", label: "Fundata - Jud. IL" },
  { id: "ZyeRqZ", label: "Fundatica - Jud. BV" },
  { id: "l514El", label: "Fundatura - Jud. BC" },
  { id: "lx82nl", label: "Fundatura - Jud. CJ" },
  { id: "ZMVzQl", label: "Fundatura - Jud. MS" },
  { id: "mGgjdm", label: "Fundatura - Jud. VL" },
  { id: "r89zDZ", label: "Fundatura - Jud. VS" },
  { id: "lYJNVm", label: "Fundatura Mare - Jud. VS" },
  { id: "mA9jam", label: "Fundatura Mica - Jud. VS" },
  { id: "mnPEXZ", label: "Fundatura Rachitoasa - Jud. BC" },
  { id: "r8DOMr", label: "Fundaturile - Jud. BZ" },
  { id: "mkjBql", label: "Fundeanu - Jud. GL" },
  { id: "mbP5EZ", label: "Fundeni - Jud. BC" },
  { id: "rd52zZ", label: "Fundeni - Jud. BZ" },
  { id: "lLaLbm", label: "Fundeni - Jud. CL" },
  { id: "lpE3Wm", label: "Fundeni - Jud. GL" },
  { id: "l7Ja5Z", label: "Fundeni - Jud. IF" },
  { id: "m1B4Yr", label: "Fundeni - Jud. PH" },
  { id: "lO64JZ", label: "Fundenii Noi - Jud. GL" },
  { id: "rKjjem", label: "Fundoaia - Jud. BC" },
  { id: "lj5bqZ", label: "Fundoaia - Jud. HR" },
  { id: "rXO59m", label: "Fundoaia - Jud. MS" },
  { id: "ZMYEpZ", label: "Fundu Hertii - Jud. BT" },
  { id: "rXaVnZ", label: "Fundu Moldovei - Jud. SV" },
  { id: "mbPxqZ", label: "Fundu Racaciuni - Jud. BC" },
  { id: "l7vJAZ", label: "Fundu Tutovei - Jud. BC" },
  { id: "ma1j7m", label: "Fundu Vaii - Jud. BC" },
  { id: "rwVkom", label: "Fundu Vaii - Jud. VS" },
  { id: "l71Ynr", label: "Fundulea - Jud. CL" },
  { id: "r9nJqZ", label: "Furcenii Noi - Jud. GL" },
  { id: "mgxDAr", label: "Furcenii Vechi - Jud. GL" },
  { id: "m1qxqm", label: "Furcsoara - Jud. HD" },
  { id: "mbK8Kr", label: "Furculesti - Jud. TR" },
  { id: "yZyVql", label: "Furduesti - Jud. AG" },
  { id: "XlDBGr", label: "Furduiesti - Jud. AB" },
  { id: "9m1e6m", label: "Furesti - Jud. AG" },
  { id: "l71adr", label: "Furnica - Jud. CT" },
  { id: "mAA9Wm", label: "Furnicari - Jud. BC" },
  { id: "yZRyvr", label: "Furnicosi - Jud. AG" },
  { id: "rd5NWZ", label: "Furtunesti - Jud. BZ" },
  { id: "mAO86Z", label: "Fusea - Jud. DB" },
  { id: "ron7Lm", label: "Gabru - Jud. DJ" },
  { id: "qZN3el", label: "Gabud - Jud. AB" },
  { id: "mbK0Er", label: "Gad - Jud. TM" },
  { id: "ZQv1pl", label: "Gadalin - Jud. CJ" },
  { id: "mA1Eqm", label: "Gadinti - Jud. NT" },
  { id: "mnxpaZ", label: "Gaesti - Jud. DB" },
  { id: "lJW8em", label: "Gageni - Jud. BZ" },
  { id: "l0wxPZ", label: "Gageni - Jud. PH" },
  { id: "lDKDXZ", label: "Gageni - Jud. VL" },
  { id: "Z4NRAm", label: "Gagesti - Jud. VN" },
  { id: "ma6wJr", label: "Gagesti - Jud. VS" },
  { id: "lWOGRl", label: "Gagu - Jud. IF" },
  { id: "lDPdXl", label: "Gaia - Jud. DJ" },
  { id: "ZMYxGZ", label: "Gaiceana - Jud. BC" },
  { id: "lJ2zYl", label: "Gaiesti - Jud. MS" },
  { id: "ZQyqgZ", label: "Gainesti - Jud. SV" },
  { id: "lO7eKl", label: "Gainesti - Jud. VL" },
  { id: "Xlx20r", label: "Gainusa - Jud. AG" },
  { id: "lj9zKZ", label: "Gaiseanca - Jud. GR" },
  { id: "mG1p0Z", label: "Gaiseni - Jud. GR" },
  { id: "lDaebm", label: "Gaiu Mic - Jud. TM" },
  { id: "ZvzEDZ", label: "Galanesti - Jud. SV" },
  { id: "rq0wom", label: "Galaoaia - Jud. MS" },
  { id: "l0p0gl", label: "Galaseni - Jud. BH" },
  { id: "mGWb2m", label: "Galaseni - Jud. SJ" },
  { id: "0lYbwm", label: "Galasesti - Jud. AG" },
  { id: "ZzD2Bm", label: "Galateni - Jud. MS" },
  { id: "ZzV8BZ", label: "Galateni - Jud. TR" },
  { id: "WmGwwm", label: "Galati - Jud. AB" },
  { id: "ZPBnEm", label: "Galati - Jud. GL" },
  { id: "lx7WDZ", label: "Galati - Jud. HD" },
  { id: "r33O5r", label: "Galatii Bistritei - Jud. BN" },
  { id: "m1nOgr", label: "Galatui - Jud. CL" },
  { id: "lODxOm", label: "Galautas - Jud. HR" },
  { id: "ZvoO3l", label: "Galautas Parau - Jud. HR" },
  { id: "EZzgEl", label: "Galbena - Jud. AB" },
  { id: "rXdy3r", label: "Galbeni - Jud. BC" },
  { id: "rwnJym", label: "Galbeni - Jud. BT" },
  { id: "ZvOxVl", label: "Galbeni - Jud. VN" },
  { id: "ZQvqvl", label: "Galbenu - Jud. BR" },
  { id: "mbOd2r", label: "Galbina - Jud. HD" },
  { id: "r2Y0pm", label: "Galbinasi - Jud. BZ" },
  { id: "mGwn6m", label: "Galbinasi - Jud. CL" },
  { id: "lpzOer", label: "Galbiori - Jud. CT" },
  { id: "nljkwm", label: "Galcesti - Jud. AG" },
  { id: "mnx5XZ", label: "Galcesti - Jud. GJ" },
  { id: "PrEwgl", label: "Galda De Jos - Jud. AB" },
  { id: "yZyW2m", label: "Galda De Sus - Jud. AB" },
  { id: "maLKNl", label: "Galdau - Jud. CL" },
  { id: "mG55DZ", label: "Galeri - Jud. BC" },
  { id: "lj4zBr", label: "Gales - Jud. SB" },
  { id: "JmAB2r", label: "Galesesti - Jud. AG" },
  { id: "m1DL9l", label: "Galesoaia - Jud. GJ" },
  { id: "rBj0dr", label: "Galesti - Jud. MS" },
  { id: "Jr3OYr", label: "Galesu - Jud. AG" },
  { id: "BmagbZ", label: "Galeteanu - Jud. AG" },
  { id: "ZyYA0Z", label: "Galgau - Jud. SJ" },
  { id: "ZzzOKZ", label: "Galgau Almasului - Jud. SJ" },
  { id: "mk5bqZ", label: "Galicea - Jud. VL" },
  { id: "l0XAgl", label: "Galicea Mare - Jud. DJ" },
  { id: "lYxowZ", label: "Galiciuica - Jud. DJ" },
  { id: "l521vm", label: "Galita - Jud. CT" },
  { id: "robOwm", label: "Galmeia - Jud. PH" },
  { id: "r36dVm", label: "Galoiesti - Jud. VN" },
  { id: "mG5e6Z", label: "Galospetreu - Jud. BH" },
  { id: "lL7kbZ", label: "Galpaia - Jud. SJ" },
  { id: "9m109l", label: "Galsa - Jud. AR" },
  { id: "mGazDZ", label: "Galtesti - Jud. VS" },
  { id: "Xl0Ror", label: "Galtiu - Jud. AB" },
  { id: "mk5a2Z", label: "Galtofani - Jud. VL" },
  { id: "ZNY1ar", label: "Galu - Jud. NT" },
  { id: "Rr9v3Z", label: "Gamacesti - Jud. AG" },
  { id: "r9nMNZ", label: "Gamanesti - Jud. DB" },
  { id: "lODwom", label: "Gamani - Jud. GJ" },
  { id: "grK9lA", label: "Gambas - Jud. AB" },
  { id: "r3BNVm", label: "Gambut - Jud. MS" },
  { id: "l7yYLr", label: "Ganas - Jud. SM" },
  { id: "r3Bp5m", label: "Ganeasa - Jud. IF" },
  { id: "ZV33Jl", label: "Ganeasa - Jud. OT" },
  { id: "krwDjZ", label: "Ganesti - Jud. AB" },
  { id: "0lYdRZ", label: "Ganesti - Jud. AG" },
  { id: "lJPL4r", label: "Ganesti - Jud. GL" },
  { id: "rXqRpZ", label: "Ganesti - Jud. IS" },
  { id: "rq0y0m", label: "Ganesti - Jud. MS" },
  { id: "rwYpjl", label: "Ganesti - Jud. VL" },
  { id: "rdK8Gm", label: "Ganesti - Jud. VS" },
  { id: "Zvgq3l", label: "Gangiova - Jud. DJ" },
  { id: "ZQGB5m", label: "Gantaga - Jud. HD" },
  { id: "roYnnl", label: "Gantulei - Jud. VL" },
  { id: "ZvEP5r", label: "Gara - Jud. SV" },
  { id: "m1b3gr", label: "Gara Banca - Jud. VS" },
  { id: "ZRxn2m", label: "Gara Berheci - Jud. GL" },
  { id: "rqzOoZ", label: "Gara Bobocu - Jud. BZ" },
  { id: "ZQvy5l", label: "Gara Cilibia - Jud. BZ" },
  { id: "m1bA3r", label: "Gara Docaneasa - Jud. VS" },
  { id: "ZVL4Bm", label: "Gara Ghidigeni - Jud. GL" },
  { id: "l510Yl", label: "Gara Ianca - Jud. BR" },
  { id: "rqpKdm", label: "Gara Leu - Jud. SV" },
  { id: "ZzVJbZ", label: "Gara Rosiesti - Jud. VS" },
  { id: "lOYOLr", label: "Gara Talasman - Jud. VS" },
  { id: "l79R5r", label: "Garagau - Jud. TR" },
  { id: "ljNL4l", label: "Garana - Jud. CS" },
  { id: "rd5DzZ", label: "Garbau - Jud. CJ" },
  { id: "ZV17Jr", label: "Garbau Dejului - Jud. CJ" },
  { id: "rByPbm", label: "Garbeni - Jud. BT" },
  { id: "rdjw5l", label: "Garbesti - Jud. BT" },
  { id: "lYKpQr", label: "Garbesti - Jud. IS" },
  { id: "rKDwRZ", label: "Garbou - Jud. SJ" },
  { id: "LZMKym", label: "Garbova - Jud. AB" },
  { id: "3r2dlO", label: "Garbova De Jos - Jud. AB" },
  { id: "3ZVWlG", label: "Garbova De Sus - Jud. AB" },
  { id: "mnJR8m", label: "Garbovat - Jud. CS" },
  { id: "lJP44r", label: "Garbovat - Jud. GL" },
  { id: "m1G16Z", label: "Garbovatu De Jos - Jud. MH" },
  { id: "ZzD78m", label: "Garbovatu De Sus - Jud. MH" },
  { id: "maj4jr", label: "Garbovi - Jud. IL" },
  { id: "JlJeZ8", label: "Garbovita - Jud. AB" },
  { id: "lWePyr", label: "Garbovu - Jud. GJ" },
  { id: "mnzMAm", label: "Garceiu - Jud. SJ" },
  { id: "lj82Nr", label: "Garceni - Jud. VS" },
  { id: "ZQVRDl", label: "Garcina - Jud. NT" },
  { id: "lDqqKm", label: "Garcov - Jud. OT" },
  { id: "ElLW1Z", label: "Garda Barbulesti - Jud. AB" },
  { id: "Vlen6m", label: "Garda De Sus - Jud. AB" },
  { id: "nlj8Lr", label: "Garda Seaca - Jud. AB" },
  { id: "lD6o2r", label: "Gardaneasa - Jud. MH" },
  { id: "rXOqgm", label: "Gardani - Jud. MM" },
  { id: "arBbDr", label: "Garde - Jud. AB" },
  { id: "lpVwYr", label: "Gardesti - Jud. TR" },
  { id: "mA9LBm", label: "Gardesti - Jud. VS" },
  { id: "AZvk3l", label: "Gardinesti - Jud. AG" },
  { id: "mGPOnr", label: "Gardoaia - Jud. MH" },
  { id: "lxxEol", label: "Garla Anei - Jud. BC" },
  { id: "Zy6bKl", label: "Garla Mare - Jud. MH" },
  { id: "lp1wxm", label: "Garleni - Jud. BC" },
  { id: "mgxdBr", label: "Garleni - Jud. DB" },
  { id: "lJQjgZ", label: "Garlenii De Sus - Jud. BC" },
  { id: "l52qpm", label: "Garlesti - Jud. DJ" },
  { id: "mbo9vr", label: "Garliciu - Jud. CT" },
  { id: "rE7YLl", label: "Garliste - Jud. CS" },
  { id: "l0XpRl", label: "Garlita - Jud. CT" },
  { id: "l71xnr", label: "Garnic - Jud. CS" },
  { id: "r36pYm", label: "Garnicet - Jud. VL" },
  { id: "ZNDJem", label: "Garnicetu - Jud. VL" },
  { id: "l07xgZ", label: "Garnita - Jud. MH" },
  { id: "l5ONEr", label: "Garoafa - Jud. VN" },
  { id: "l59Vvl", label: "Garvan - Jud. TL" },
  { id: "lLeVGm", label: "Gasteni - Jud. BC" },
  { id: "lx7NpZ", label: "Gastesti - Jud. IS" },
  { id: "mGa9BZ", label: "Gataia - Jud. TM" },
  { id: "lpV2Mr", label: "Gatejesti - Jud. VL" },
  { id: "2l5nYm", label: "Gaujani - Jud. AG" },
  { id: "rwyRal", label: "Gaujani - Jud. GR" },
  { id: "l0KbJZ", label: "Gaujani - Jud. VL" },
  { id: "r8BL6l", label: "Gaura Sangerului - Jud. MS" },
  { id: "lJx74r", label: "Gaureni - Jud. IS" },
  { id: "mAGaWZ", label: "Gavanel - Jud. PH" },
  { id: "rd5x4Z", label: "Gavanele - Jud. BZ" },
  { id: "rK389r", label: "Gavanesti - Jud. BZ" },
  { id: "rdVOal", label: "Gavanesti - Jud. GJ" },
  { id: "mGWY6m", label: "Gavanesti - Jud. OT" },
  { id: "ZzPw8m", label: "Gavanesti - Jud. VL" },
  { id: "m1njar", label: "Gavani - Jud. BR" },
  { id: "lDaLGm", label: "Gavanu - Jud. VS" },
  { id: "mGa0nZ", label: "Gavojdia - Jud. TM" },
  { id: "grKY0l", label: "Gazarie - Jud. BC" },
  { id: "l086Pl", label: "Geaca - Jud. CJ" },
  { id: "yZyw2m", label: "Geamana - Jud. AB" },
  { id: "WmGb9l", label: "Geamana - Jud. AG" },
  { id: "lpxxpl", label: "Geamana - Jud. VL" },
  { id: "mkjbOl", label: "Geangoesti - Jud. DB" },
  { id: "lDPJbl", label: "Geblesti - Jud. DJ" },
  { id: "lYx4EZ", label: "Gefu - Jud. GL" },
  { id: "rqEn1r", label: "Gelmar - Jud. HD" },
  { id: "ZNwVyl", label: "Gelu - Jud. SM" },
  { id: "rqO9Gl", label: "Gelu - Jud. TM" },
  { id: "ZVyLBr", label: "Gemenea - Jud. SV" },
  { id: "l7QbAr", label: "Gemenea Bratulesti - Jud. DB" },
  { id: "r3kGGr", label: "Gemenele - Jud. BR" },
  { id: "rdxL5r", label: "Gemeni - Jud. MH" },
  { id: "lx7qoZ", label: "General Berthelot - Jud. HD" },
  { id: "r99gOr", label: "General Praporgescu - Jud. TL" },
  { id: "mGw2nm", label: "General Scarisoreanu - Jud. CT" },
  { id: "roY2wl", label: "Genuneni - Jud. VL" },
  { id: "lD2ojr", label: "Geoagiu - Jud. HD" },
  { id: "ZQG5Jm", label: "Geoagiu Bai - Jud. HD" },
  { id: "3ZVWxl", label: "Geoagiu De Sus - Jud. AB" },
  { id: "WmGOWm", label: "Geogel - Jud. AB" },
  { id: "JlJyYZ", label: "Geomal - Jud. AB" },
  { id: "leaA3l", label: "George Cosbuc - Jud. BT" },
  { id: "rKjqGm", label: "George Enescu - Jud. BT" },
  { id: "lxbW7r", label: "Georocel - Jud. DJ" },
  { id: "ZQqxaZ", label: "Georocu Mare - Jud. DJ" },
  { id: "lDAG2m", label: "Gepis - Jud. BH" },
  { id: "ZyejqZ", label: "Gepiu - Jud. BH" },
  { id: "rd7eAm", label: "Gerausa - Jud. SM" },
  { id: "leaNol", label: "Gersa I - Jud. BN" },
  { id: "lp1Gbm", label: "Gersa Ii - Jud. BN" },
  { id: "l7yjXr", label: "Gheaba - Jud. PH" },
  { id: "l78bqm", label: "Ghebari - Jud. VN" },
  { id: "rqb3PZ", label: "Gheboaia - Jud. DB" },
  { id: "lDPoJl", label: "Gheboieni - Jud. DB" },
  { id: "XlDqXm", label: "Ghedulesti - Jud. AB" },
  { id: "ZMYezZ", label: "Gheghie - Jud. BH" },
  { id: "lexRPZ", label: "Gheja - Jud. MS" },
  { id: "rwPGjZ", label: "Ghelaiesti - Jud. NT" },
  { id: "ZzwxNl", label: "Ghelari - Jud. HD" },
  { id: "l5292m", label: "Ghelinta - Jud. CV" },
  { id: "ZRK5Nl", label: "Ghelmegioaia - Jud. MH" },
  { id: "ZQQA5Z", label: "Ghemes - Jud. BN" },
  { id: "ZzznYZ", label: "Ghenci - Jud. SM" },
  { id: "ZQQDnZ", label: "Ghenetea - Jud. BH" },
  { id: "ZzEDnr", label: "Gheorghe Doja - Jud. BC" },
  { id: "mA4kol", label: "Gheorghe Doja - Jud. IL" },
  { id: "ZQV4al", label: "Gheorghe Doja - Jud. MS" },
  { id: "l7JLYZ", label: "Gheorghe Lazar - Jud. IL" },
  { id: "r66yDr", label: "Gheorgheni - Jud. HR" },
  { id: "l7wjwl", label: "Gheorghesti - Jud. MH" },
  { id: "mGwB0m", label: "Gheorghieni - Jud. CJ" },
  { id: "ZMGv4m", label: "Gheorghiteni - Jud. SV" },
  { id: "rBjQ5r", label: "Gheraesti - Jud. NT" },
  { id: "ZRKOjl", label: "Gheraestii Noi - Jud. NT" },
  { id: "ZV1QRr", label: "Gheraseni - Jud. BZ" },
  { id: "ZRxkNm", label: "Ghercesti - Jud. DJ" },
  { id: "ZRj6eZ", label: "Gherdana - Jud. BC" },
  { id: "ZR3Rel", label: "Gherdeal - Jud. SB" },
  { id: "ZyNWol", label: "Ghergani - Jud. DB" },
  { id: "lJWvAm", label: "Ghergheasa - Jud. BZ" },
  { id: "ZMJ4Ql", label: "Ghergheleu - Jud. VS" },
  { id: "rXyj9Z", label: "Gherghesti - Jud. DB" },
  { id: "Zvza2Z", label: "Gherghesti - Jud. VS" },
  { id: "ZQqMdZ", label: "Gherghina - Jud. CT" },
  { id: "rob8Lm", label: "Gherghita - Jud. PH" },
  { id: "lO6KAZ", label: "Gherghitesti - Jud. DB" },
  { id: "l7125r", label: "Gherla - Jud. CJ" },
  { id: "Zy6BLl", label: "Gherman - Jud. NT" },
  { id: "ZVyzWr", label: "Gherman - Jud. TM" },
  { id: "lex2oZ", label: "Ghermanesti - Jud. IF" },
  { id: "lWvWdZ", label: "Ghermanesti - Jud. VS" },
  { id: "robRxm", label: "Gherta Mare - Jud. SM" },
  { id: "l0wWNZ", label: "Gherta Mica - Jud. SM" },
  { id: "lYy2Xl", label: "Ghertenis - Jud. CS" },
  { id: "WZPAMl", label: "Ghetari - Jud. AB" },
  { id: "mGabdZ", label: "Ghicani - Jud. VS" },
  { id: "rwnxQm", label: "Ghida - Jud. BH" },
  { id: "roEWXl", label: "Ghidasteu - Jud. MS" },
  { id: "l0XKNl", label: "Ghidfalau - Jud. CV" },
  { id: "maozDZ", label: "Ghidici - Jud. DJ" },
  { id: "lWeYdr", label: "Ghidigeni - Jud. GL" },
  { id: "ZRKpvl", label: "Ghidion - Jud. NT" },
  { id: "Z43Nxr", label: "Ghidut - Jud. HR" },
  { id: "ZP2wbm", label: "Ghighiseni - Jud. BH" },
  { id: "mAGx4Z", label: "Ghighiu - Jud. PH" },
  { id: "mbxDQZ", label: "Ghigoiesti - Jud. NT" },
  { id: "mb56El", label: "Ghijasa De Jos - Jud. SB" },
  { id: "lpABMm", label: "Ghijasa De Sus - Jud. SB" },
  { id: "mgo0Dm", label: "Ghilad - Jud. TM" },
  { id: "ZQQwaZ", label: "Ghilavesti - Jud. BC" },
  { id: "rX7vpm", label: "Ghilesti - Jud. SM" },
  { id: "rqpR9m", label: "Ghilvaci - Jud. SM" },
  { id: "r2VXQr", label: "Ghimbav - Jud. BV" },
  { id: "ZRjQDZ", label: "Ghimes - Jud. BC" },
  { id: "leapJl", label: "Ghimes Faget - Jud. BC" },
  { id: "r36oGm", label: "Ghimicesti - Jud. VN" },
  { id: "rKPx0Z", label: "Ghimpati - Jud. DB" },
  { id: "rBOpgZ", label: "Ghimpati - Jud. GR" },
  { id: "ZMGOgm", label: "Ghimpati - Jud. OT" },
  { id: "lOBeOZ", label: "Ghimpeteni - Jud. OT" },
  { id: "ZyYzoZ", label: "Ghimpetenii Noi - Jud. OT" },
  { id: "ZzELKr", label: "Ghinda - Jud. BN" },
  { id: "maxajl", label: "Ghindaoani - Jud. NT" },
  { id: "lLDaYl", label: "Ghindaresti - Jud. CT" },
  { id: "r3B10m", label: "Ghindari - Jud. MS" },
  { id: "lWM1Ar", label: "Ghindari - Jud. VL" },
  { id: "lj9v1Z", label: "Ghindeni - Jud. DJ" },
  { id: "ZPBxBm", label: "Ghinesti - Jud. DB" },
  { id: "r2wy9Z", label: "Ghinesti - Jud. MS" },
  { id: "mnx3jZ", label: "Ghinghesti - Jud. GL" },
  { id: "rwEWvl", label: "Ghinoaica - Jud. PH" },
  { id: "lWMMBr", label: "Ghiobesti - Jud. VL" },
  { id: "leLJoZ", label: "Ghiocari - Jud. BZ" },
  { id: "ZPqxvl", label: "Ghiocel - Jud. PH" },
  { id: "r86p0l", label: "Ghioldum - Jud. PH" },
  { id: "yZRBvZ", label: "Ghioncani - Jud. AB" },
  { id: "r6qR9m", label: "Ghionea - Jud. GR" },
  { id: "nljpkr", label: "Ghionoaia - Jud. BC" },
  { id: "m1kojZ", label: "Ghiorac - Jud. BH" },
  { id: "WmGXBr", label: "Ghioroc - Jud. AR" },
  { id: "mndbjZ", label: "Ghioroiu - Jud. VL" },
  { id: "mGW52m", label: "Ghiosani - Jud. OT" },
  { id: "lOB7LZ", label: "Ghiosesti - Jud. PH" },
  { id: "mbOzvr", label: "Ghipes - Jud. HR" },
  { id: "EZzwEl", label: "Ghirbom - Jud. AB" },
  { id: "lYxV0Z", label: "Ghirdoveni - Jud. DB" },
  { id: "lpV3Yr", label: "Ghireasca - Jud. VS" },
  { id: "Zv2XwZ", label: "Ghireni - Jud. BT" },
  { id: "lOBoNZ", label: "Ghirisa - Jud. SM" },
  { id: "ZQv0pl", label: "Ghirisu Roman - Jud. CJ" },
  { id: "ZNJe6Z", label: "Ghiroda - Jud. TM" },
  { id: "rod1XZ", label: "Ghirolt - Jud. CJ" },
  { id: "ZQa2gZ", label: "Ghirolt - Jud. SM" },
  { id: "lezJXm", label: "Ghiurche - Jud. HR" },
  { id: "r8YoPZ", label: "Ghizdaru - Jud. GR" },
  { id: "l7Q7Xr", label: "Ghizdavesti - Jud. DJ" },
  { id: "rK3VEr", label: "Ghizdita - Jud. BZ" },
  { id: "rwVLQm", label: "Ghizela - Jud. TM" },
  { id: "lxK2nZ", label: "Giacas - Jud. SB" },
  { id: "lODGAm", label: "Gialacuta - Jud. HD" },
  { id: "rBRYar", label: "Giarmata - Jud. TM" },
  { id: "ZzVWNZ", label: "Giarmata Vii - Jud. TM" },
  { id: "lO7wol", label: "Gibesti - Jud. VL" },
  { id: "rqO8Gl", label: "Giera - Jud. TM" },
  { id: "rdVJAl", label: "Gighera - Jud. DJ" },
  { id: "ZRJdyZ", label: "Gilau - Jud. CJ" },
  { id: "rdVWal", label: "Gilortu - Jud. GJ" },
  { id: "rBbkDr", label: "Gimbasani - Jud. IL" },
  { id: "mndQ8Z", label: "Ginerica - Jud. VL" },
  { id: "lOwNwl", label: "Ginta - Jud. BH" },
  { id: "rqpW1m", label: "Giorocuta - Jud. SM" },
  { id: "Zv2WVZ", label: "Gioseni - Jud. BC" },
  { id: "r2V0Wr", label: "Girisu De Cris - Jud. BH" },
  { id: "Zv2BDZ", label: "Girisu Negru - Jud. BH" },
  { id: "r891eZ", label: "Giroc - Jud. TM" },
  { id: "rq0VBm", label: "Girov - Jud. NT" },
  { id: "ronvvm", label: "Giubega - Jud. DJ" },
  { id: "AZvY5r", label: "Giuclani - Jud. AG" },
  { id: "lx8wPl", label: "Giula - Jud. CJ" },
  { id: "mG5MWZ", label: "Giulesti - Jud. BH" },
  { id: "rBjRDr", label: "Giulesti - Jud. MM" },
  { id: "lYnpJm", label: "Giulesti - Jud. NT" },
  { id: "lJ9apZ", label: "Giulesti - Jud. SV" },
  { id: "m18jYl", label: "Giulesti - Jud. VL" },
  { id: "lO7eol", label: "Giulestii De Sus - Jud. VL" },
  { id: "r8BAXl", label: "Giulus - Jud. MS" },
  { id: "ZQyg4Z", label: "Giulvaz - Jud. TM" },
  { id: "ZyY9qZ", label: "Giungi - Jud. SM" },
  { id: "lD65Wr", label: "Giura - Jud. MH" },
  { id: "ZPAPjl", label: "Giurcani - Jud. VS" },
  { id: "lx807l", label: "Giurcuta De Jos - Jud. CJ" },
  { id: "lJWEgm", label: "Giurcuta De Sus - Jud. CJ" },
  { id: "rdjKql", label: "Giurgeni - Jud. BC" },
  { id: "mGx4nr", label: "Giurgeni - Jud. IL" },
  { id: "Zy6nql", label: "Giurgeni - Jud. NT" },
  { id: "r66a9r", label: "Giurgesti - Jud. HD" },
  { id: "rozjLm", label: "Giurgesti - Jud. IS" },
  { id: "lxEAzm", label: "Giurgesti - Jud. SV" },
  { id: "mbK7Br", label: "Giurgesti - Jud. VS" },
  { id: "rwPbvZ", label: "Giurgiani - Jud. MH" },
  { id: "ZyeG0Z", label: "Giurgioana - Jud. BC" },
  { id: "ZMRw4l", label: "Giurgiova - Jud. CS" },
  { id: "l57qAr", label: "Giurgis - Jud. MS" },
  { id: "maoKbZ", label: "Giurgita - Jud. DJ" },
  { id: "mAOEaZ", label: "Giurgiu - Jud. GR" },
  { id: "arBRQr", label: "Giurgiut - Jud. AB" },
  { id: "Zy5eLZ", label: "Giurgiuveni - Jud. VL" },
  { id: "mk55wZ", label: "Giuroiu - Jud. VL" },
  { id: "rd7qGm", label: "Giurtelecu Hododului - Jud. SM" },
  { id: "mGWEDm", label: "Giurtelecu Simleului - Jud. SJ" },
  { id: "lJ993Z", label: "Giuvarasti - Jud. OT" },
  { id: "mA9MWm", label: "Gladna Montana - Jud. TM" },
  { id: "ZNJ77Z", label: "Gladna Romana - Jud. TM" },
  { id: "Z4DQ8Z", label: "Glajarie - Jud. MS" },
  { id: "r3yE4l", label: "Glamboaca - Jud. SB" },
  { id: "nljRLl", label: "Glambocata - Jud. AG" },
  { id: "WZPPMZ", label: "Glambocata Deal - Jud. AG" },
  { id: "XlxM7m", label: "Glambocel - Jud. AG" },
  { id: "PrEP6m", label: "Glambocelu - Jud. AG" },
  { id: "LZM5pZ", label: "Glambocu - Jud. AG" },
  { id: "OroJNm", label: "Glavacioc - Jud. AG" },
  { id: "lWBAAZ", label: "Glavanesti - Jud. BC" },
  { id: "ZyG8km", label: "Glavanesti - Jud. IS" },
  { id: "lDKxGZ", label: "Glavile - Jud. VL" },
  { id: "ZyeQ4Z", label: "Gledin - Jud. BN" },
  { id: "AZvy3r", label: "Gliganu De Jos - Jud. AG" },
  { id: "nljzwl", label: "Gliganu De Sus - Jud. AG" },
  { id: "wmgd7r", label: "Gligoresti - Jud. AB" },
  { id: "rK3GRr", label: "Gligoresti - Jud. CJ" },
  { id: "mGwXdm", label: "Glimboca - Jud. CS" },
  { id: "l07B6Z", label: "Glina - Jud. IF" },
  { id: "mkwenm", label: "Globu Craiovei - Jud. CS" },
  { id: "rBW85Z", label: "Globurau - Jud. CS" },
  { id: "OrodwZ", label: "Glod - Jud. AB" },
  { id: "ZyN74l", label: "Glod - Jud. DB" },
  { id: "mGPWwr", label: "Glod - Jud. MM" },
  { id: "ZPqNvl", label: "Glod - Jud. PH" },
  { id: "r86LPl", label: "Glod - Jud. SJ" },
  { id: "lYyOwl", label: "Glodeanu Sarat - Jud. BZ" },
  { id: "mAbvor", label: "Glodeanu Silistea - Jud. BZ" },
  { id: "lWe9qr", label: "Glodeni - Jud. DB" },
  { id: "mAOY4Z", label: "Glodeni - Jud. GJ" },
  { id: "m1GQ8Z", label: "Glodeni - Jud. MS" },
  { id: "rBRa5r", label: "Glodeni - Jud. VS" },
  { id: "rozNxm", label: "Glodenii Gandului - Jud. IS" },
  { id: "mkaPzZ", label: "Glodghilesti - Jud. HD" },
  { id: "lLe7nm", label: "Glodisoarele - Jud. BC" },
  { id: "Wr67DZ", label: "Glodu - Jud. AG" },
  { id: "l0K8gZ", label: "Glodu - Jud. SV" },
  { id: "lxEvom", label: "Glodu - Jud. VL" },
  { id: "lpzVbr", label: "Glodu Petcari - Jud. BZ" },
  { id: "ljNoNl", label: "Glodurile - Jud. BZ" },
  { id: "rqE0Gr", label: "Glogova - Jud. GJ" },
  { id: "lxbBDr", label: "Glogoveanu - Jud. DB" },
  { id: "JlJeYZ", label: "Glogovet - Jud. AB" },
  { id: "r8O9Km", label: "Goagiu - Jud. HR" },
  { id: "lD65Xr", label: "Goanta - Jud. MH" },
  { id: "AZv2wZ", label: "Goasele - Jud. AB" },
  { id: "rK2Okm", label: "Godeanu - Jud. MH" },
  { id: "grK00Z", label: "Godeni - Jud. AG" },
  { id: "rdV15l", label: "Godeni - Jud. DJ" },
  { id: "ZQGV4m", label: "Godinesti - Jud. GJ" },
  { id: "Zvovwl", label: "Godinesti - Jud. HD" },
  { id: "r3360r", label: "Godinestii De Jos - Jud. BC" },
  { id: "m1k88Z", label: "Godinestii De Sus - Jud. BC" },
  { id: "ZyGVqm", label: "Goesti - Jud. IS" },
  { id: "r9DWMm", label: "Goga - Jud. PH" },
  { id: "r3BYVm", label: "Gogan - Jud. MS" },
  { id: "m1B19r", label: "Gogeasca - Jud. PH" },
  { id: "ZND2Vm", label: "Gogoiu - Jud. VN" },
  { id: "rqbYoZ", label: "Gogosari - Jud. GR" },
  { id: "rXy5DZ", label: "Gogosesti - Jud. DJ" },
  { id: "Z4q7ql", label: "Gogosita - Jud. DJ" },
  { id: "lj90wZ", label: "Gogosu - Jud. DJ" },
  { id: "r2wW1Z", label: "Gogosu - Jud. MH" },
  { id: "mkjGol", label: "Gohor - Jud. GL" },
  { id: "Xl0Yom", label: "Goia - Jud. AG" },
  { id: "ZPBkEm", label: "Goicea - Jud. DJ" },
  { id: "rd5BqZ", label: "Goicelu - Jud. BZ" },
  { id: "rXLO9r", label: "Goidesti - Jud. BZ" },
  { id: "QmbddZ", label: "Goiesti - Jud. AB" },
  { id: "r6qn7m", label: "Goiesti - Jud. DJ" },
  { id: "rByJQm", label: "Goila - Jud. BH" },
  { id: "nljYKZ", label: "Goioasa - Jud. BC" },
  { id: "0lYxwZ", label: "Gojeiesti - Jud. AB" },
  { id: "lez76m", label: "Golaiesti - Jud. IS" },
  { id: "krdPAr", label: "Goleasca - Jud. AG" },
  { id: "rEdGgm", label: "Goleasca - Jud. GR" },
  { id: "r3Ga4r", label: "Golenti - Jud. DJ" },
  { id: "r661Wr", label: "Goles - Jud. HD" },
  { id: "AZQknm", label: "Golesti - Jud. AG" },
  { id: "l78Knm", label: "Golesti - Jud. VL" },
  { id: "mGge0m", label: "Golesti - Jud. VN" },
  { id: "lej8Om", label: "Golestii De Sus - Jud. VN" },
  { id: "Zzo48l", label: "Golet - Jud. CS" },
  { id: "lDPXXl", label: "Golfin - Jud. DJ" },
  { id: "rE63MZ", label: "Golineasa - Jud. MH" },
  { id: "ZRB5qZ", label: "Gologanu - Jud. VN" },
  { id: "ma6Q7r", label: "Golotreni - Jud. VL" },
  { id: "mb9GEl", label: "Golu Grabicina - Jud. BZ" },
  { id: "ZyN9Ll", label: "Golumbelu - Jud. DJ" },
  { id: "rKPoQZ", label: "Golumbu - Jud. DJ" },
  { id: "lJWN3m", label: "Gomoesti - Jud. BZ" },
  { id: "r8D6Pr", label: "Gontesti - Jud. BZ" },
  { id: "3ZVGWl", label: "Goranesti - Jud. AG" },
  { id: "yZyJKl", label: "Gorani - Jud. AG" },
  { id: "rE7EBl", label: "Gorani - Jud. BZ" },
  { id: "l59z9l", label: "Goranu - Jud. VL" },
  { id: "lpb2Yr", label: "Gorban - Jud. IS" },
  { id: "lDA4nm", label: "Gorbanesti - Jud. BT" },
  { id: "maxeNl", label: "Goreni - Jud. MS" },
  { id: "0lYnXm", label: "Gorgan - Jud. AB" },
  { id: "wlp7xZ", label: "Gorganu - Jud. AG" },
  { id: "mk8zQr", label: "Gorghesti - Jud. BC" },
  { id: "lJPy3r", label: "Gorgota - Jud. DB" },
  { id: "ma5B9m", label: "Gorgota - Jud. PH" },
  { id: "ZPAYMl", label: "Gorgova - Jud. TL" },
  { id: "rBbODr", label: "Gornacel - Jud. GJ" },
  { id: "lOWgNl", label: "Gornea - Jud. CS" },
  { id: "r27vAZ", label: "Gorneni - Jud. GR" },
  { id: "mGP3pr", label: "Gornenti - Jud. MH" },
  { id: "lOA4Kr", label: "Gornesti - Jud. MS" },
  { id: "l54QAZ", label: "Gornet - Jud. BZ" },
  { id: "ZvEQxr", label: "Gornet - Jud. PH" },
  { id: "lj4VLr", label: "Gornet Cricov - Jud. PH" },
  { id: "lLDA6l", label: "Gornovita - Jud. GJ" },
  { id: "lxAG7m", label: "Gornovita - Jud. MH" },
  { id: "rwndQm", label: "Gorovei - Jud. BT" },
  { id: "rwpe3Z", label: "Goruia - Jud. CS" },
  { id: "r6G95Z", label: "Gorun - Jud. NT" },
  { id: "robq5m", label: "Goruna - Jud. PH" },
  { id: "lO7YNl", label: "Gorunesti - Jud. VL" },
  { id: "Z4qv0l", label: "Goruni - Jud. CT" },
  { id: "ZQGpJm", label: "Goruni - Jud. IS" },
  { id: "mkEDnZ", label: "Gosmani - Jud. NT" },
  { id: "lYGGAr", label: "Gostavatu - Jud. OT" },
  { id: "ZNwE1l", label: "Gostila - Jud. SJ" },
  { id: "mGwQdm", label: "Gostilele - Jud. CL" },
  { id: "r3G95r", label: "Gostinari - Jud. GR" },
  { id: "ZQqpoZ", label: "Gostinu - Jud. GR" },
  { id: "lODoLm", label: "Gotesti - Jud. HD" },
  { id: "m1qV3m", label: "Gothatea - Jud. HD" },
  { id: "ZQynaZ", label: "Gottlob - Jud. TM" },
  { id: "ZyGO4m", label: "Govajdia - Jud. HD" },
  { id: "rXO3Dm", label: "Govodarva - Jud. MH" },
  { id: "l7wMnl", label: "Govora - Jud. OT" },
  { id: "lO763l", label: "Govora - Jud. VL" },
  { id: "r8900Z", label: "Grabat - Jud. TM" },
  { id: "lLaWnm", label: "Grabicina De Jos - Jud. BZ" },
  { id: "ZzoGNl", label: "Grabicina De Sus - Jud. BZ" },
  { id: "WZPb3m", label: "Gradesti - Jud. BC" },
  { id: "r3kAAr", label: "Gradina - Jud. CT" },
  { id: "rByNQm", label: "Gradinari - Jud. BH" },
  { id: "rBWg2Z", label: "Gradinari - Jud. CS" },
  { id: "m1DP3l", label: "Gradinari - Jud. GR" },
  { id: "mbOjvr", label: "Gradinari - Jud. IS" },
  { id: "mAGGBZ", label: "Gradinari - Jud. OT" },
  { id: "lj3MBm", label: "Gradini - Jud. MS" },
  { id: "l5812Z", label: "Gradinile - Jud. OT" },
  { id: "m1nDqr", label: "Gradistea - Jud. BR" },
  { id: "Zy2yYl", label: "Gradistea - Jud. CL" },
  { id: "rKPgQZ", label: "Gradistea - Jud. DJ" },
  { id: "l52YAm", label: "Gradistea - Jud. GR" },
  { id: "m1Gj3Z", label: "Gradistea - Jud. IF" },
  { id: "mkzxol", label: "Gradistea - Jud. OT" },
  { id: "Zzz7nZ", label: "Gradistea - Jud. PH" },
  { id: "mGgDdm", label: "Gradistea - Jud. VL" },
  { id: "l5Q5El", label: "Gradistea De Munte - Jud. HD" },
  { id: "rKxVxZ", label: "Gradisteanca - Jud. TR" },
  { id: "r2Y4dm", label: "Grajdana - Jud. BZ" },
  { id: "Zvz7DZ", label: "Grajdeni - Jud. VS" },
  { id: "lWOPNl", label: "Grajduri - Jud. IS" },
  { id: "lj84br", label: "Gramesti - Jud. SV" },
  { id: "rXd6pr", label: "Granari - Jud. BV" },
  { id: "krweKl", label: "Graniceri - Jud. AR" },
  { id: "lWvyEZ", label: "Granicerii - Jud. TM" },
  { id: "maoobZ", label: "Graniceru - Jud. CT" },
  { id: "ZPAqjl", label: "Granicesti - Jud. SV" },
  { id: "r2MLbm", label: "Gratia - Jud. TR" },
  { id: "Z4DyQZ", label: "Grausorul - Jud. MS" },
  { id: "grKqkl", label: "Greaban - Jud. AG" },
  { id: "ElL4Wl", label: "Greabanu - Jud. AG" },
  { id: "mG1yDZ", label: "Greaca - Jud. GR" },
  { id: "ZNW9Ll", label: "Grebanu - Jud. BZ" },
  { id: "Zy68Dl", label: "Grebenisu De Campie - Jud. MS" },
  { id: "lDa3bm", label: "Greblesti - Jud. VL" },
  { id: "lW1DRr", label: "Greceanca - Jud. BZ" },
  { id: "r9nv8Z", label: "Grecesti - Jud. DJ" },
  { id: "Z4qR8l", label: "Greci - Jud. DB" },
  { id: "ZVd9Bm", label: "Greci - Jud. MH" },
  { id: "mb5o2l", label: "Greci - Jud. OT" },
  { id: "mGakWZ", label: "Greci - Jud. TL" },
  { id: "r9bnMZ", label: "Greci - Jud. VL" },
  { id: "rKnN9m", label: "Grecii De Jos - Jud. IL" },
  { id: "mkz8ol", label: "Greeresti - Jud. OT" },
  { id: "lpzePr", label: "Greoni - Jud. CS" },
  { id: "rodEXZ", label: "Gresia - Jud. BZ" },
  { id: "ZQaY4Z", label: "Gresia - Jud. PH" },
  { id: "ZVy8or", label: "Gresia - Jud. TR" },
  { id: "lWMzRr", label: "Gresu - Jud. VN" },
  { id: "rKjvWm", label: "Grid - Jud. BV" },
  { id: "rXqDeZ", label: "Grid - Jud. HD" },
  { id: "majwNr", label: "Griesti - Jud. IS" },
  { id: "Zv2EwZ", label: "Grigoreni - Jud. BC" },
  { id: "rBRO2r", label: "Grigoresti - Jud. SV" },
  { id: "r92wyZ", label: "Grind - Jud. HD" },
  { id: "rXqQDZ", label: "Grindasi - Jud. IL" },
  { id: "l07D0Z", label: "Grindeni - Jud. MS" },
  { id: "rwD1QZ", label: "Grindu - Jud. IL" },
  { id: "rwVQVm", label: "Grindu - Jud. TL" },
  { id: "r3Bbem", label: "Grinties - Jud. NT" },
  { id: "ZQQEaZ", label: "Grivita - Jud. BT" },
  { id: "mnxnaZ", label: "Grivita - Jud. GL" },
  { id: "rBbLar", label: "Grivita - Jud. IL" },
  { id: "ZPAvBl", label: "Grivita - Jud. VS" },
  { id: "mGPQ0r", label: "Groapa Radaii - Jud. MS" },
  { id: "r65ogm", label: "Groapa Tufei - Jud. VN" },
  { id: "ZVy3xr", label: "Groapa Vladichii - Jud. SV" },
  { id: "maxLJl", label: "Groape - Jud. MM" },
  { id: "r92XaZ", label: "Grohot - Jud. HD" },
  { id: "ZNA7Vr", label: "Grohotele - Jud. HD" },
  { id: "ZNww1l", label: "Grojdibodu - Jud. OT" },
  { id: "lDPNXl", label: "Gropanele - Jud. DJ" },
  { id: "lOW6Al", label: "Gropeni - Jud. BR" },
  { id: "rqpdGm", label: "Gropeni - Jud. SV" },
  { id: "lDKqnZ", label: "Gropeni - Jud. VL" },
  { id: "mka3OZ", label: "Gropnita - Jud. IS" },
  { id: "mgBaql", label: "Gropsani - Jud. OT" },
  { id: "l5Qo2l", label: "Gros - Jud. HD" },
  { id: "Xlxk0Z", label: "Grosani - Jud. AG" },
  { id: "lYyJAl", label: "Grosani - Jud. BZ" },
  { id: "ZQawpZ", label: "Grosani - Jud. PH" },
  { id: "yZy44r", label: "Groseni - Jud. AR" },
  { id: "rqbVQZ", label: "Groserea - Jud. GJ" },
  { id: "nlj44r", label: "Grosi - Jud. AB" },
  { id: "krwQyl", label: "Grosi - Jud. AG" },
  { id: "VleOYr", label: "Grosi - Jud. AR" },
  { id: "leaP1l", label: "Grosi - Jud. BH" },
  { id: "lLV7nr", label: "Grosi - Jud. MM" },
  { id: "rBjPDr", label: "Grosi - Jud. NT" },
  { id: "mbKREr", label: "Grosi - Jud. TM" },
  { id: "ZQyzaZ", label: "Grosi - Jud. VL" },
  { id: "BmaR2r", label: "Grosii Noi - Jud. AR" },
  { id: "mnEQXl", label: "Grosii Tiblesului - Jud. MM" },
  { id: "Z43Wzr", label: "Grosuri - Jud. HD" },
  { id: "rXyW4Z", label: "Grozavesti - Jud. DB" },
  { id: "lO6EJZ", label: "Grozavesti - Jud. GL" },
  { id: "lD6gXr", label: "Grozavesti - Jud. NT" },
  { id: "ZvEWDr", label: "Grozavesti - Jud. OT" },
  { id: "mnQyAl", label: "Grozesti - Jud. IS" },
  { id: "lJ214l", label: "Grozesti - Jud. MH" },
  { id: "ZyGeLm", label: "Grui - Jud. GJ" },
  { id: "lYnMEm", label: "Gruia - Jud. MH" },
  { id: "ZPGAEr", label: "Gruieri - Jud. VL" },
  { id: "l7vWwZ", label: "Gruilung - Jud. BH" },
  { id: "lYnzQm", label: "Gruisor - Jud. MS" },
  { id: "lWegAr", label: "Gruita - Jud. DJ" },
  { id: "9m1ERm", label: "Gruiu - Jud. AG" },
  { id: "lDDwGl", label: "Gruiu - Jud. CL" },
  { id: "lOAeLr", label: "Gruiu - Jud. IF" },
  { id: "mgBj7l", label: "Gruiu - Jud. OT" },
  { id: "r65x7m", label: "Gruiu - Jud. VL" },
  { id: "l5O8vr", label: "Gruiu Lupului - Jud. VL" },
  { id: "m1G9RZ", label: "Grumazesti - Jud. NT" },
  { id: "roxXxZ", label: "Grumezoaia - Jud. VS" },
  { id: "ZRJ0vZ", label: "Gruni - Jud. CS" },
  { id: "mnpjXZ", label: "Gruni - Jud. TM" },
  { id: "Zv5PPr", label: "Gubandru - Jud. OT" },
  { id: "r9nPOZ", label: "Gubaucea - Jud. DJ" },
  { id: "mbxaoZ", label: "Guesti - Jud. OT" },
  { id: "lJWR3m", label: "Guga - Jud. CJ" },
  { id: "l0k26Z", label: "Gugesti - Jud. VN" },
  { id: "roxDNZ", label: "Gugesti - Jud. VS" },
  { id: "lxz87r", label: "Guguianca - Jud. VL" },
  { id: "lWe5yr", label: "Gulia - Jud. DB" },
  { id: "rqpVGm", label: "Gulia - Jud. SV" },
  { id: "l71vLr", label: "Gulianca - Jud. BR" },
  { id: "rKxbWZ", label: "Gura Albesti - Jud. VS" },
  { id: "arB5Qm", label: "Gura Ariesului - Jud. AB" },
  { id: "lDD5Yl", label: "Gura Badicului - Jud. BZ" },
  { id: "rBbKVr", label: "Gura Badilitei - Jud. IS" },
  { id: "ZPBGjm", label: "Gura Barbuletului - Jud. DB" },
  { id: "l7193r", label: "Gura Bascei - Jud. BZ" },
  { id: "mnzdNm", label: "Gura Beliei - Jud. PH" },
  { id: "lx7Y0Z", label: "Gura Bohotin - Jud. IS" },
  { id: "mkaePZ", label: "Gura Bordului - Jud. HD" },
  { id: "ZRB0LZ", label: "Gura Calitei - Jud. VN" },
  { id: "mb9Lol", label: "Gura Calmatui - Jud. BR" },
  { id: "mnJgqm", label: "Gura Calnaului - Jud. BZ" },
  { id: "mA1Q2m", label: "Gura Caluiu - Jud. OT" },
  { id: "VrXRlL", label: "Gura Cornei - Jud. AB" },
  { id: "l0p86l", label: "Gura Craiesti - Jud. BC" },
  { id: "r3yoYl", label: "Gura Crivatului - Jud. PH" },
  { id: "rEaOxr", label: "Gura Crucilor - Jud. VL" },
  { id: "AZQYvZ", label: "Gura Cutului - Jud. AB" },
  { id: "rBWjdZ", label: "Gura Dimienii - Jud. BZ" },
  { id: "maLdjl", label: "Gura Dobrogei - Jud. CT" },
  { id: "ZvAJwr", label: "Gura Fagetului - Jud. BZ" },
  { id: "rdVXal", label: "Gura Foii - Jud. DB" },
  { id: "mAOd2Z", label: "Gura Garbovatului - Jud. GL" },
  { id: "rK330r", label: "Gura Garlutei - Jud. BR" },
  { id: "l0KXoZ", label: "Gura Haitii - Jud. SV" },
  { id: "lYG9Xr", label: "Gura Humorului - Jud. SV" },
  { id: "rozvwm", label: "Gura Ialomitei - Jud. IL" },
  { id: "rXaY9Z", label: "Gura Idrici - Jud. VS" },
  { id: "BZ4DjZ", label: "Gura Izbitei - Jud. AB" },
  { id: "lJPYWr", label: "Gura Menti - Jud. GJ" },
  { id: "lj3b4m", label: "Gura Motrului - Jud. MH" },
  { id: "mkj6Bl", label: "Gura Ocnitei - Jud. DB" },
  { id: "ZvE2Dr", label: "Gura Padinii - Jud. OT" },
  { id: "olWpkm", label: "Gura Pravat - Jud. AG" },
  { id: "rwVyVm", label: "Gura Putnei - Jud. SV" },
  { id: "r9nexZ", label: "Gura Racului - Jud. DJ" },
  { id: "m1BzYr", label: "Gura Raului - Jud. SB" },
  { id: "EZzGDl", label: "Gura Rosiei - Jud. AB" },
  { id: "r2YOem", label: "Gura Saratii - Jud. BZ" },
  { id: "lDDBbl", label: "Gura Siriului - Jud. BZ" },
  { id: "5l7Xnm", label: "Gura Sohodol - Jud. AB" },
  { id: "lOYwwr", label: "Gura Solcii - Jud. SV" },
  { id: "mk1ABZ", label: "Gura Suhasului - Jud. VL" },
  { id: "Z43vJr", label: "Gura Susitei - Jud. GJ" },
  { id: "m1DWdl", label: "Gura Sutii - Jud. DB" },
  { id: "r8DvVr", label: "Gura Teghii - Jud. BZ" },
  { id: "ZPqMMl", label: "Gura Vadului - Jud. PH" },
  { id: "WZPdkZ", label: "Gura Vaii - Jud. AG" },
  { id: "Oro93l", label: "Gura Vaii - Jud. AR" },
  { id: "r2Vbpr", label: "Gura Vaii - Jud. BC" },
  { id: "rop0vZ", label: "Gura Vaii - Jud. BV" },
  { id: "rXLjRr", label: "Gura Vaii - Jud. BZ" },
  { id: "mgxWEr", label: "Gura Vaii - Jud. DJ" },
  { id: "lj5a4Z", label: "Gura Vaii - Jud. IL" },
  { id: "Zvo0Dl", label: "Gura Vaii - Jud. IS" },
  { id: "Z4DNqZ", label: "Gura Vaii - Jud. MH" },
  { id: "mgagEZ", label: "Gura Vaii - Jud. NT" },
  { id: "lLJNnr", label: "Gura Vaii - Jud. VL" },
  { id: "ZQw8Jm", label: "Gura Vaii - Jud. VN" },
  { id: "rqOAgl", label: "Gura Vaii - Jud. VS" },
  { id: "leGRXr", label: "Gura Vitioarei - Jud. PH" },
  { id: "l58LRZ", label: "Gura Vladesei - Jud. SJ" },
  { id: "ZVL9Km", label: "Gura Vulcanei - Jud. DB" },
  { id: "arBgqZ", label: "Gurahont - Jud. AR" },
  { id: "Zv27wZ", label: "Guranda - Jud. BT" },
  { id: "rwnWVm", label: "Gurani - Jud. BH" },
  { id: "r2Qydm", label: "Gurasada - Jud. HD" },
  { id: "XlDNYr", label: "Gurba - Jud. AR" },
  { id: "ZzoKKl", label: "Gurbanesti - Jud. CL" },
  { id: "ljGebm", label: "Gurbediu - Jud. BH" },
  { id: "r6b83r", label: "Gurbesti - Jud. BH" },
  { id: "Z43wqr", label: "Gureni - Jud. GJ" },
  { id: "rK249m", label: "Gurghiu - Jud. MS" },
  { id: "lDa1Ym", label: "Gurguiata - Jud. VL" },
  { id: "rBWyVZ", label: "Gurguieti - Jud. BR" },
  { id: "Zy5NkZ", label: "Gurisoara - Jud. VL" },
  { id: "ZvzD9Z", label: "Guruieni - Jud. TR" },
  { id: "lxKNPZ", label: "Guruslau - Jud. SJ" },
  { id: "ma6kNr", label: "Gusitei - Jud. VS" },
  { id: "rwYN3l", label: "Gusoeni - Jud. VL" },
  { id: "ZPG2vr", label: "Gusoianca - Jud. VL" },
  { id: "r3GeGr", label: "Gusoiu - Jud. DB" },
  { id: "r2qNdm", label: "Gusu - Jud. SB" },
  { id: "ZyeWDZ", label: "Gutinas - Jud. BC" },
  { id: "rdx2Ar", label: "Gutu - Jud. MH" },
  { id: "lWRAAZ", label: "Gvardinita - Jud. MH" },
  { id: "lj51bZ", label: "Habasesti - Jud. IS" },
  { id: "rBO5VZ", label: "Habeni - Jud. DB" },
  { id: "Z4DepZ", label: "Habic - Jud. MS" },
  { id: "mAGWqZ", label: "Habud - Jud. PH" },
  { id: "ZP73br", label: "Hadambu - Jud. IS" },
  { id: "grKVPm", label: "Hadarau - Jud. AB" },
  { id: "ZRKeql", label: "Hadareni - Jud. MS" },
  { id: "rd5YWZ", label: "Hagau - Jud. CJ" },
  { id: "r2wP9Z", label: "Hagau - Jud. MS" },
  { id: "lDA6Ym", label: "Haghiac - Jud. BC" },
  { id: "ZRx6Km", label: "Haghig - Jud. CV" },
  { id: "r6q3Dm", label: "Hagieni - Jud. CT" },
  { id: "ZzwMEl", label: "Hagieni - Jud. IL" },
  { id: "ZNAb6r", label: "Hagiesti - Jud. IL" },
  { id: "ZNGDyl", label: "Hagioaica - Jud. DB" },
  { id: "majAEr", label: "Hagota - Jud. HR" },
  { id: "l59gvl", label: "Haidar - Jud. TL" },
  { id: "ElLvyr", label: "Haiducesti - Jud. AB" },
  { id: "lezoJm", label: "Haiesti - Jud. GJ" },
  { id: "lYDzEZ", label: "Haieu - Jud. BH" },
  { id: "ZMY95Z", label: "Haineala - Jud. BC" },
  { id: "Rr9Qyr", label: "Halalis - Jud. AR" },
  { id: "lOANwr", label: "Halanga - Jud. MH" },
  { id: "r9n6xZ", label: "Halangesti - Jud. GJ" },
  { id: "l79Gqr", label: "Halaresti - Jud. VS" },
  { id: "lD211r", label: "Halaucesti - Jud. IS" },
  { id: "rXqpeZ", label: "Halceni - Jud. IS" },
  { id: "rXdB7r", label: "Halchiu - Jud. BV" },
  { id: "r3ybYl", label: "Haleasa - Jud. SV" },
  { id: "ZV1WWr", label: "Hales - Jud. BZ" },
  { id: "ZP2aDm", label: "Halmacioaia - Jud. BC" },
  { id: "AZQXpr", label: "Halmagel - Jud. AR" },
  { id: "7rqWQl", label: "Halmagiu - Jud. AR" },
  { id: "ZQQooZ", label: "Halmasau - Jud. BN" },
  { id: "r2qEem", label: "Halmasd - Jud. SJ" },
  { id: "lLejYm", label: "Halmeag - Jud. BV" },
  { id: "ZR3aKl", label: "Halmeu - Jud. SM" },
  { id: "l58GYZ", label: "Halmeu Vii - Jud. SM" },
  { id: "l79d3r", label: "Halta Dodesti - Jud. VS" },
  { id: "l0wjPZ", label: "Hamba - Jud. SB" },
  { id: "rBRk5r", label: "Hamcearca - Jud. TL" },
  { id: "lp5JPm", label: "Hamzoaia - Jud. NT" },
  { id: "LZMQ8Z", label: "Hanasesti - Jud. AB" },
  { id: "mgoaAm", label: "Hancea - Jud. SV" },
  { id: "Z4DqQZ", label: "Handalu Ilbei - Jud. MM" },
  { id: "lpbYor", label: "Handresti - Jud. IS" },
  { id: "mG57pZ", label: "Hanesti - Jud. BT" },
  { id: "WZPRKm", label: "Hanganesti - Jud. BC" },
  { id: "lOA5Nr", label: "Hangu - Jud. NT" },
  { id: "mk5JPZ", label: "Hangulesti - Jud. VN" },
  { id: "rKjnRm", label: "Hanta - Jud. BC" },
  { id: "lO6LJZ", label: "Hantesti - Jud. GL" },
  { id: "mGa5WZ", label: "Hantesti - Jud. SV" },
  { id: "ZyN8Kl", label: "Hanu Conachi - Jud. GL" },
  { id: "rBONgZ", label: "Hanu Lui Pala - Jud. DB" },
  { id: "rK20Pm", label: "Hanul Ancutei - Jud. NT" },
  { id: "BZ49jl", label: "Hapria - Jud. AB" },
  { id: "lDP6Gl", label: "Harale - Jud. CV" },
  { id: "lOAJGr", label: "Haranglab - Jud. MS" },
  { id: "EZzjWZ", label: "Harasti - Jud. AB" },
  { id: "ZVwRWl", label: "Harau - Jud. HD" },
  { id: "mGgO6m", label: "Haret - Jud. VN" },
  { id: "ZP7qMr", label: "Harghita Bai - Jud. HR" },
  { id: "l7vQ3Z", label: "Harja - Jud. BC" },
  { id: "lD2vXr", label: "Harlau - Jud. IS" },
  { id: "m1kjdZ", label: "Harlesti - Jud. BC" },
  { id: "Z4Y81l", label: "Harman - Jud. BV" },
  { id: "l5Qe3l", label: "Harmaneasa - Jud. IS" },
  { id: "lJx3zr", label: "Harmanesti - Jud. IS" },
  { id: "ZNAKer", label: "Harmanestii Noi - Jud. IS" },
  { id: "lYKdRr", label: "Harmanestii Vechi - Jud. IS" },
  { id: "ZRKwAl", label: "Harnicesti - Jud. MM" },
  { id: "mGxydr", label: "Harpasesti - Jud. IS" },
  { id: "ma5ODm", label: "Harsa - Jud. PH" },
  { id: "lxxvzl", label: "Harseni - Jud. BV" },
  { id: "3r2Xgl", label: "Harsesti - Jud. AG" },
  { id: "l51dvl", label: "Harsesti - Jud. BH" },
  { id: "lYyeXl", label: "Harsova - Jud. CT" },
  { id: "l59p2l", label: "Harsova - Jud. VS" },
  { id: "ZNJQxZ", label: "Harsoveni - Jud. VS" },
  { id: "ZyGbYm", label: "Hartagani - Jud. HD" },
  { id: "r9yPyr", label: "Hartau - Jud. MS" },
  { id: "r8B8el", label: "Hartesti - Jud. NT" },
  { id: "3ZVbxZ", label: "Hartiesti - Jud. AG" },
  { id: "rqE51r", label: "Hartoape - Jud. IS" },
  { id: "rBjVDr", label: "Hartop - Jud. NT" },
  { id: "ZQyQDZ", label: "Hartop - Jud. SV" },
  { id: "m1BA8r", label: "Hasag - Jud. SB" },
  { id: "l0NxNr", label: "Hasdat - Jud. HD" },
  { id: "r2Y6dm", label: "Hasdate - Jud. CJ" },
  { id: "r92KXZ", label: "Hasdau - Jud. HD" },
  { id: "Jr3L4Z", label: "Hasmas - Jud. AR" },
  { id: "l58DEZ", label: "Hasmas - Jud. SJ" },
  { id: "Zv2M2Z", label: "Hasmasu Ciceului - Jud. BN" },
  { id: "mgL9qr", label: "Hatagel - Jud. HD" },
  { id: "ZQa8aZ", label: "Hatcarau - Jud. PH" },
  { id: "r97pMl", label: "Hateg - Jud. CT" },
  { id: "rKn50m", label: "Hateg - Jud. HD" },
  { id: "Jr3WGr", label: "Hategana - Jud. AB" },
  { id: "ronzNm", label: "Hatuica - Jud. CV" },
  { id: "lWBkYZ", label: "Haucesti - Jud. BH" },
  { id: "r36WGm", label: "Haulisca - Jud. VN" },
  { id: "r89n0Z", label: "Hauzesti - Jud. TM" },
  { id: "l7vddZ", label: "Havarna - Jud. BT" },
  { id: "rwDKVZ", label: "Heci - Jud. IS" },
  { id: "mk8pbr", label: "Helegiu - Jud. BC" },
  { id: "JmAOoZ", label: "Heleresti - Jud. AB" },
  { id: "l7J4qZ", label: "Helesteni - Jud. IS" },
  { id: "mgxp5r", label: "Helesteu - Jud. DB" },
  { id: "XlxApm", label: "Helesti - Jud. AB" },
  { id: "r97o3l", label: "Heliade Radulescu - Jud. BZ" },
  { id: "NmkQOZ", label: "Heltiu - Jud. BC" },
  { id: "m1k8YZ", label: "Hemeius - Jud. BC" },
  { id: "leazYl", label: "Hemieni - Jud. BC" },
  { id: "VrXqLZ", label: "Henig - Jud. AB" },
  { id: "ZQq05Z", label: "Herasti - Jud. GR" },
  { id: "lejaJm", label: "Herasti - Jud. VL" },
  { id: "lO6DNZ", label: "Herculian - Jud. CV" },
  { id: "ZV35Kl", label: "Hereclean - Jud. SJ" },
  { id: "m1b76r", label: "Herendesti - Jud. TM" },
  { id: "lj3Vbm", label: "Herepea - Jud. MS" },
  { id: "ZQGXam", label: "Herepeia - Jud. HD" },
  { id: "r2wGbZ", label: "Herghelia - Jud. MS" },
  { id: "krwEvl", label: "Heria - Jud. AB" },
  { id: "rwnexm", label: "Herina - Jud. BN" },
  { id: "r30GqZ", label: "Herla - Jud. SV" },
  { id: "lODpOm", label: "Hermeziu - Jud. IS" },
  { id: "rKx5RZ", label: "Herneacova - Jud. TM" },
  { id: "r9q9xr", label: "Hertioana De Jos - Jud. BC" },
  { id: "mgPoDr", label: "Hertioana Razesi - Jud. BC" },
  { id: "r3Gyqr", label: "Hetea - Jud. CV" },
  { id: "r2wa1Z", label: "Hetiur - Jud. MS" },
  { id: "lDaGYm", label: "Hezeris - Jud. TM" },
  { id: "lJ93WZ", label: "Hida - Jud. SJ" },
  { id: "lxAAom", label: "Hideaga - Jud. MM" },
  { id: "mG5MdZ", label: "Hidis - Jud. BH" },
  { id: "lWB5YZ", label: "Hidisel - Jud. BH" },
  { id: "lYDORZ", label: "Hidiselu De Jos - Jud. BH" },
  { id: "mG5o6Z", label: "Hidiselu De Sus - Jud. BH" },
  { id: "ZMVezl", label: "Higiu - Jud. MH" },
  { id: "rdVxGl", label: "Hilib - Jud. CV" },
  { id: "mG5L9Z", label: "Hiliseu Closca - Jud. BT" },
  { id: "rwnJjm", label: "Hiliseu Crisan - Jud. BT" },
  { id: "rwn2vm", label: "Hiliseu Horia - Jud. BT" },
  { id: "ZP7PMr", label: "Hilita - Jud. IS" },
  { id: "ZMYn8Z", label: "Hinchiris - Jud. BH" },
  { id: "mA1W2m", label: "Hinova - Jud. MH" },
  { id: "LmnnGm", label: "Hintesti - Jud. AG" },
  { id: "r33Knr", label: "Hirean - Jud. BN" },
  { id: "ZVLbJm", label: "Hirisesti - Jud. GJ" },
  { id: "Z41aJl", label: "Hisias - Jud. TM" },
  { id: "rXaJLZ", label: "Hitias - Jud. TM" },
  { id: "mnEkBl", label: "Hlapesti - Jud. NT" },
  { id: "ZyGB2m", label: "Hlincea - Jud. IS" },
  { id: "rByzQm", label: "Hlipiceni - Jud. BT" },
  { id: "VrX1eZ", label: "Hoanca - Jud. AB" },
  { id: "Zzgvbl", label: "Hobaia - Jud. GR" },
  { id: "lx787Z", label: "Hobita - Jud. GJ" },
  { id: "rE2oqZ", label: "Hobita - Jud. HD" },
  { id: "majG7r", label: "Hobita Gradiste - Jud. HD" },
  { id: "r6xM6l", label: "Hoceni - Jud. VS" },
  { id: "rdxw5r", label: "Hociungi - Jud. NT" },
  { id: "r2w3dZ", label: "Hodac - Jud. MS" },
  { id: "rXLA3r", label: "Hodai Boian - Jud. CJ" },
  { id: "rwPAyZ", label: "Hodaia - Jud. MS" },
  { id: "rodj3Z", label: "Hodaie - Jud. CJ" },
  { id: "m1D1ql", label: "Hodarasti - Jud. DB" },
  { id: "Wr80Pr", label: "Hodis - Jud. AR" },
  { id: "ropLvZ", label: "Hodis - Jud. BH" },
  { id: "lDqXjm", label: "Hodisa - Jud. SM" },
  { id: "lLeyYm", label: "Hodisel - Jud. BH" },
  { id: "7rqEvr", label: "Hodisesti - Jud. AB" },
  { id: "lLaQnm", label: "Hodisu - Jud. CJ" },
  { id: "lpEqbm", label: "Hodivoaia - Jud. GR" },
  { id: "VrXyLZ", label: "Hodobana - Jud. AB" },
  { id: "rd7A7m", label: "Hodod - Jud. SM" },
  { id: "rqOevl", label: "Hodoni - Jud. TM" },
  { id: "Zzwq3l", label: "Hodora - Jud. IS" },
  { id: "lO60nZ", label: "Hodoreasca - Jud. GJ" },
  { id: "mnP4jZ", label: "Hodos - Jud. BH" },
  { id: "rKxORZ", label: "Hodos - Jud. TM" },
  { id: "ZP7Obr", label: "Hodosa - Jud. HR" },
  { id: "ZVd4Wm", label: "Hodosa - Jud. MS" },
  { id: "ZVwkOl", label: "Hoghia - Jud. HR" },
  { id: "lOBaoZ", label: "Hoghilag - Jud. SB" },
  { id: "rEQOVr", label: "Hoghiz - Jud. BV" },
  { id: "ZNAQer", label: "Hoisesti - Jud. IS" },
  { id: "l57Y0r", label: "Hoisesti - Jud. NT" },
  { id: "m18oql", label: "Holbanesti - Jud. VN" },
  { id: "r6bX7r", label: "Holbav - Jud. BV" },
  { id: "lJxJwr", label: "Holboca - Jud. IS" },
  { id: "m1B9Yr", label: "Holda - Jud. SV" },
  { id: "mgLeBr", label: "Holdea - Jud. HD" },
  { id: "lOB5oZ", label: "Holdita - Jud. SV" },
  { id: "mkaB2Z", label: "Holm - Jud. IS" },
  { id: "lD6nXr", label: "Holm - Jud. NT" },
  { id: "XlD52m", label: "Holobani - Jud. AB" },
  { id: "rwn9Xm", label: "Holod - Jud. BH" },
  { id: "mAAABm", label: "Holt - Jud. BC" },
  { id: "mAb8Br", label: "Homesti - Jud. BZ" },
  { id: "rq06vm", label: "Homiceni - Jud. NT" },
  { id: "mnQvGl", label: "Homita - Jud. IS" },
  { id: "rdN3Wl", label: "Homocea - Jud. VN" },
  { id: "lOYVnr", label: "Homojdia - Jud. TM" },
  { id: "l58dpZ", label: "Homoraciu - Jud. PH" },
  { id: "ZMYvQZ", label: "Homorod - Jud. BV" },
  { id: "r3QoVl", label: "Homorod - Jud. HD" },
  { id: "ZPqWbl", label: "Homorodu De Jos - Jud. SM" },
  { id: "ZvELPr", label: "Homorodu De Mijloc - Jud. SM" },
  { id: "r6y13m", label: "Homorodu De Sus - Jud. SM" },
  { id: "ZVK9Ol", label: "Homorog - Jud. BH" },
  { id: "r8O0Pm", label: "Hondol - Jud. HD" },
  { id: "lOYo3r", label: "Honorici - Jud. TM" },
  { id: "WmGN0l", label: "Hontisor - Jud. AR" },
  { id: "wlp6Yr", label: "Hoparta - Jud. AB" },
  { id: "rd532Z", label: "Hora Mare - Jud. CS" },
  { id: "rod8LZ", label: "Hora Mica - Jud. CS" },
  { id: "mnxANZ", label: "Horasti - Jud. GJ" },
  { id: "rqO5ol", label: "Hordila - Jud. VS" },
  { id: "ZNJp1Z", label: "Hordilesti - Jud. VS" },
  { id: "olW9Nr", label: "Horea - Jud. AB" },
  { id: "mkzWql", label: "Horea - Jud. SM" },
  { id: "rwDDQZ", label: "Horezu - Jud. GJ" },
  { id: "lOA9Or", label: "Horezu - Jud. OT" },
  { id: "Z41GQl", label: "Horezu - Jud. VL" },
  { id: "lYxqJZ", label: "Horezu Poenari - Jud. DJ" },
  { id: "lJNEYl", label: "Horga - Jud. VS" },
  { id: "mnP7BZ", label: "Horgesti - Jud. BC" },
  { id: "AZQ2pl", label: "Horia - Jud. AR" },
  { id: "rd557Z", label: "Horia - Jud. BR" },
  { id: "lYDdwZ", label: "Horia - Jud. BT" },
  { id: "rKPaPZ", label: "Horia - Jud. CT" },
  { id: "lJxAAr", label: "Horia - Jud. IL" },
  { id: "lj32wm", label: "Horia - Jud. NT" },
  { id: "rqODBl", label: "Horia - Jud. TL" },
  { id: "rd5GqZ", label: "Horlacea - Jud. CJ" },
  { id: "lJQ7pZ", label: "Horlaceni - Jud. BT" },
  { id: "mGxK2r", label: "Horlesti - Jud. IS" },
  { id: "lDqy1m", label: "Horoatu Cehului - Jud. SJ" },
  { id: "lYGdxr", label: "Horoatu Crasnei - Jud. SJ" },
  { id: "ZRjYyZ", label: "Horodistea - Jud. BT" },
  { id: "rXqwnZ", label: "Horodistea - Jud. IS" },
  { id: "r6xypl", label: "Horodnic De Jos - Jud. SV" },
  { id: "lOYwNr", label: "Horodnic De Sus - Jud. SV" },
  { id: "ZyWeqm", label: "Horodniceni - Jud. SV" },
  { id: "r30PqZ", label: "Horoiata - Jud. VS" },
  { id: "lYKAEr", label: "Horpaz - Jud. IS" },
  { id: "lezjgm", label: "Hosasau - Jud. HR" },
  { id: "lL7QnZ", label: "Hosman - Jud. SB" },
  { id: "rXdJer", label: "Hotar - Jud. BH" },
  { id: "lLVvYr", label: "Hotarani - Jud. MH" },
  { id: "leG2Or", label: "Hotarani - Jud. OT" },
  { id: "lYJQOm", label: "Hotarasa - Jud. VL" },
  { id: "m1kdRZ", label: "Hotarel - Jud. BH" },
  { id: "lO6pLZ", label: "Hotarele - Jud. GR" },
  { id: "rXxqDr", label: "Hotarele - Jud. VL" },
  { id: "rXx7nr", label: "Hotaroaia - Jud. VL" },
  { id: "rKnnGm", label: "Hotaroasa - Jud. GJ" },
  { id: "robpxm", label: "Hotaru - Jud. OT" },
  { id: "ZNDMym", label: "Hotaru - Jud. VN" },
  { id: "rBjjbr", label: "Hoteni - Jud. MM" },
  { id: "Z49Bxl", label: "Hotoan - Jud. SM" },
  { id: "r3B35m", label: "Hovrila - Jud. MM" },
  { id: "lDavGm", label: "Hreasca - Jud. VS" },
  { id: "rEa6Vr", label: "Hreatca - Jud. SV" },
  { id: "ma5qEm", label: "Hrip - Jud. SM" },
  { id: "mAABqm", label: "Hriscani - Jud. BT" },
  { id: "lpVqbr", label: "Huc - Jud. VS" },
  { id: "ljNkNl", label: "Huci - Jud. CJ" },
  { id: "rqPQdm", label: "Hudesti - Jud. BT" },
  { id: "AZQGnm", label: "Hudricesti - Jud. AB" },
  { id: "mnPXqZ", label: "Hudum - Jud. BT" },
  { id: "ZQv45l", label: "Huedin - Jud. CJ" },
  { id: "r9y83r", label: "Huisurez - Jud. NT" },
  { id: "rByVbm", label: "Hulub - Jud. BT" },
  { id: "0lYpQZ", label: "Huluba - Jud. AG" },
  { id: "ma1gDm", label: "Hulubesti - Jud. BT" },
  { id: "mnx9XZ", label: "Hulubesti - Jud. DB" },
  { id: "lO6QAZ", label: "Hulubesti - Jud. GR" },
  { id: "yZR22Z", label: "Humele - Jud. AG" },
  { id: "rqp2dm", label: "Humoreni - Jud. SV" },
  { id: "ZVwAxl", label: "Humosu - Jud. IS" },
  { id: "ZPaEkl", label: "Humulesti - Jud. NT" },
  { id: "r6Gz2Z", label: "Humulestii Noi - Jud. NT" },
  { id: "ZNAnyr", label: "Hunedoara - Jud. HD" },
  { id: "Jr3D5Z", label: "Hunedoara Timisana - Jud. AR" },
  { id: "r27RvZ", label: "Hunia - Jud. DJ" },
  { id: "m1bvgr", label: "Hupca - Jud. VS" },
  { id: "ZQVwnl", label: "Hurducesti - Jud. MH" },
  { id: "rdKE7m", label: "Hurdugi - Jud. VS" },
  { id: "r9qGOr", label: "Hurez - Jud. BV" },
  { id: "rwEAxl", label: "Hurez - Jud. SJ" },
  { id: "r3QBzl", label: "Hurezani - Jud. GJ" },
  { id: "ZQaXJZ", label: "Hurezu Mare - Jud. SM" },
  { id: "l79pdr", label: "Hurjuieni - Jud. SV" },
  { id: "lDA5Wm", label: "Huruiesti - Jud. BC" },
  { id: "rXd1pr", label: "Husasau De Cris - Jud. BH" },
  { id: "rBy8Vm", label: "Husasau De Tinca - Jud. BH" },
  { id: "ZvEM9r", label: "Huseni - Jud. SJ" },
  { id: "lDaP2m", label: "Husi - Jud. SV" },
  { id: "rEak5r", label: "Husi - Jud. VS" },
  { id: "ZQakvZ", label: "Husia - Jud. SJ" },
  { id: "ZNY2Qr", label: "Husnicioara - Jud. MH" },
  { id: "l7QAqr", label: "Hustiu - Jud. GL" },
  { id: "ZVK8Al", label: "Huta - Jud. BH" },
  { id: "r3k25r", label: "Huta - Jud. CJ" },
  { id: "lOBbAZ", label: "Huta - Jud. SJ" },
  { id: "lWDgBm", label: "Huta Certeze - Jud. SM" },
  { id: "lWBakZ", label: "Huta Voivozi - Jud. BH" },
  { id: "ZNyN6r", label: "Hutani - Jud. BT" },
  { id: "r2VeAr", label: "Hutu - Jud. BC" },
  { id: "Rr99Or", label: "Huzaresti - Jud. AB" },
  { id: "rE70Ml", label: "Iabalcea - Jud. CS" },
  { id: "rqzW9Z", label: "Iablanita - Jud. CS" },
  { id: "lex8XZ", label: "Iablanita - Jud. MH" },
  { id: "rqP60m", label: "Iacobeni - Jud. BT" },
  { id: "Z4wVpr", label: "Iacobeni - Jud. CJ" },
  { id: "mGxDdr", label: "Iacobeni - Jud. HR" },
  { id: "ZQGRvm", label: "Iacobeni - Jud. IS" },
  { id: "ZyYnLZ", label: "Iacobeni - Jud. SB" },
  { id: "lYJDRm", label: "Iacobeni - Jud. SV" },
  { id: "VrXj7l", label: "Iacobesti - Jud. AB" },
  { id: "ZyWe2m", label: "Iacobesti - Jud. SV" },
  { id: "LmnNpm", label: "Iacobini - Jud. AR" },
  { id: "lDKPXZ", label: "Iacovile - Jud. VL" },
  { id: "rq0EQm", label: "Iadara - Jud. MM" },
  { id: "rqzvGZ", label: "Iam - Jud. CS" },
  { id: "mgoOBm", label: "Iana - Jud. VS" },
  { id: "rEQNLr", label: "Ianca - Jud. BH" },
  { id: "ZNyPQr", label: "Ianca - Jud. BR" },
  { id: "r866Xl", label: "Ianca - Jud. OT" },
  { id: "ZQaG5Z", label: "Ianca Noua - Jud. OT" },
  { id: "l588EZ", label: "Iancu Jianu - Jud. OT" },
  { id: "AZvnPZ", label: "Ianculesti - Jud. AG" },
  { id: "ZRx2Dm", label: "Ianculesti - Jud. GR" },
  { id: "lJ9DzZ", label: "Ianculesti - Jud. SM" },
  { id: "lJQ1zZ", label: "Ianosda - Jud. BH" },
  { id: "rEaY6r", label: "Ianova - Jud. TM" },
  { id: "r6Gb6Z", label: "Iapa - Jud. MM" },
  { id: "rodKwZ", label: "Iara - Jud. CJ" },
  { id: "l573Rr", label: "Iara De Mures - Jud. MS" },
  { id: "ZQqVgZ", label: "Iaras - Jud. CV" },
  { id: "ma1Qbm", label: "Iasi - Jud. BV" },
  { id: "lL14Wm", label: "Iasi - Jud. IS" },
  { id: "r3Qp0l", label: "Iasi Gorj - Jud. GJ" },
  { id: "r892qZ", label: "Iaslovat - Jud. SV" },
  { id: "lL1W1m", label: "Iasu - Jud. HR" },
  { id: "rByRbm", label: "Iaz - Jud. BC" },
  { id: "r2YGWm", label: "Iaz - Jud. CS" },
  { id: "lJ9E3Z", label: "Iaz - Jud. SJ" },
  { id: "mnzqGm", label: "Iaz - Jud. SV" },
  { id: "lpVYbr", label: "Iaz - Jud. VS" },
  { id: "ZRxAqm", label: "Iazu - Jud. DB" },
  { id: "rKnbGm", label: "Iazu - Jud. IL" },
  { id: "lxKB7Z", label: "Iazu - Jud. PH" },
  { id: "Z43gAr", label: "Iazu Nou - Jud. IS" },
  { id: "lx7dQZ", label: "Iazu Vechi - Jud. IS" },
  { id: "Zvz13Z", label: "Iazurile - Jud. TL" },
  { id: "ZNyq6r", label: "Ibaneasa - Jud. BT" },
  { id: "ZQQKdZ", label: "Ibanesti - Jud. BT" },
  { id: "lJ24el", label: "Ibanesti - Jud. MS" },
  { id: "l7wanl", label: "Ibanesti - Jud. OT" },
  { id: "ZQyOvZ", label: "Ibanesti - Jud. VS" },
  { id: "rK2Xem", label: "Ibanesti Padure - Jud. MS" },
  { id: "Zy2zol", label: "Ibrianu - Jud. BR" },
  { id: "lO61AZ", label: "Ibrianu - Jud. DB" },
  { id: "qZNA6r", label: "Ibru - Jud. AB" },
  { id: "r9nyqZ", label: "Icafalau - Jud. CV" },
  { id: "r6bKWr", label: "Ichimeni - Jud. BT" },
  { id: "rXOg4m", label: "Icland - Jud. MS" },
  { id: "maxW2l", label: "Iclandu Mare - Jud. MS" },
  { id: "lYn4Om", label: "Iclanzel - Jud. MS" },
  { id: "0lYjEm", label: "Iclod - Jud. AB" },
  { id: "maLEDl", label: "Iclod - Jud. CJ" },
  { id: "lpVexr", label: "Icloda - Jud. TM" },
  { id: "rE7bBl", label: "Iclozel - Jud. CJ" },
  { id: "r9naaZ", label: "Icoana - Jud. GR" },
  { id: "l0ww6Z", label: "Icoana - Jud. OT" },
  { id: "lWvoAZ", label: "Ictar Budinti - Jud. TM" },
  { id: "ljGx4m", label: "Icuseni - Jud. BT" },
  { id: "lODaOm", label: "Icuseni - Jud. IS" },
  { id: "Zy6Rql", label: "Icusesti - Jud. NT" },
  { id: "mA1dWm", label: "Ideciu De Jos - Jud. MS" },
  { id: "mgaWqZ", label: "Ideciu De Sus - Jud. MS" },
  { id: "l7w7ql", label: "Idicel - Jud. MS" },
  { id: "mGPN2r", label: "Idicel Padure - Jud. MS" },
  { id: "m1GVaZ", label: "Idiciu - Jud. MS" },
  { id: "Z41J8l", label: "Idrici - Jud. VS" },
  { id: "m1GN9Z", label: "Idrifaia - Jud. MS" },
  { id: "lOYzKr", label: "Iecea Mare - Jud. TM" },
  { id: "mbKXqr", label: "Iecea Mica - Jud. TM" },
  { id: "ZNGdVl", label: "Iedera De Jos - Jud. DB" },
  { id: "l0XvQl", label: "Iedera De Sus - Jud. DB" },
  { id: "ZQad4Z", label: "Iedu - Jud. SV" },
  { id: "lOBgNZ", label: "Iegheriste - Jud. SM" },
  { id: "lx7ozZ", label: "Iepureni - Jud. IS" },
  { id: "ZyNo8l", label: "Iepuresti - Jud. GR" },
  { id: "krwLKZ", label: "Iercoseni - Jud. AR" },
  { id: "wlpN6r", label: "Iermata - Jud. AR" },
  { id: "qZNXYm", label: "Iermata Neagra - Jud. AR" },
  { id: "Zv5xPr", label: "Iernut - Jud. MS" },
  { id: "m1GxgZ", label: "Iernuteni - Jud. MS" },
  { id: "mGae9Z", label: "Iersnic - Jud. TM" },
  { id: "r3kqqr", label: "Iertof - Jud. CS" },
  { id: "mbK5dr", label: "Iesle - Jud. SV" },
  { id: "rq0Ovm", label: "Ieud - Jud. MM" },
  { id: "rByPDm", label: "Iezer - Jud. BT" },
  { id: "rwVKxm", label: "Iezer - Jud. VS" },
  { id: "r89NMZ", label: "Iezerel - Jud. VS" },
  { id: "ZvAqDr", label: "Iezeru - Jud. CL" },
  { id: "Z4qxpl", label: "Iezureni - Jud. GJ" },
  { id: "lxE9nm", label: "Ifrimesti - Jud. VL" },
  { id: "mGgdDm", label: "Igesti - Jud. VN" },
  { id: "leJ5Pm", label: "Igesti - Jud. VS" },
  { id: "JmA88l", label: "Ighiel - Jud. AB" },
  { id: "l7yEAr", label: "Ighisu Nou - Jud. SB" },
  { id: "lDqnbm", label: "Ighisu Vechi - Jud. SB" },
  { id: "Nmk6Ol", label: "Ighiu - Jud. AB" },
  { id: "ZzwGDl", label: "Ighiu - Jud. HR" },
  { id: "roEOvl", label: "Igiroasa - Jud. MH" },
  { id: "r3G74r", label: "Igirosu - Jud. GJ" },
  { id: "BZ4R1m", label: "Ignatesti - Jud. AB" },
  { id: "9m1g9Z", label: "Ignesti - Jud. AR" },
  { id: "lOYnnr", label: "Igoiu - Jud. VL" },
  { id: "mA9Kom", label: "Igris - Jud. TM" },
  { id: "lLaO6m", label: "Igritia - Jud. CJ" },
  { id: "rBjgJr", label: "Ihod - Jud. MS" },
  { id: "r3G1qr", label: "Ijdileni - Jud. GL" },
  { id: "ZyWg0m", label: "Ilaciu - Jud. VL" },
  { id: "lxAbnm", label: "Ilba - Jud. MM" },
  { id: "rqzxoZ", label: "Ileana - Jud. BZ" },
  { id: "rK3N0r", label: "Ileana - Jud. CL" },
  { id: "mAGE4Z", label: "Ileanda - Jud. SJ" },
  { id: "mnP5xZ", label: "Ileni - Jud. BV" },
  { id: "ronoXm", label: "Ilfoveni - Jud. DB" },
  { id: "rqOodl", label: "Ilganii De Jos - Jud. TL" },
  { id: "r6xJDl", label: "Ilganii De Sus - Jud. TL" },
  { id: "lJxOer", label: "Ilia - Jud. HD" },
  { id: "mkwP2m", label: "Ilidia - Jud. CS" },
  { id: "rdVK7l", label: "Ilieni - Jud. CV" },
  { id: "rBj0Jr", label: "Ilieni - Jud. MS" },
  { id: "r6bGWr", label: "Iliesi - Jud. BC" },
  { id: "rwP4XZ", label: "Iliesi - Jud. MS" },
  { id: "krdN2l", label: "Iliesti - Jud. AB" },
  { id: "lx7PoZ", label: "Iliesti - Jud. GJ" },
  { id: "lJ9BeZ", label: "Ilimbav - Jud. SB" },
  { id: "l07GQZ", label: "Ilioara - Jud. MS" },
  { id: "lOwQol", label: "Iliseni - Jud. BT" },
  { id: "ZVydxr", label: "Ilisesti - Jud. SV" },
  { id: "ljGMNm", label: "Ilisua - Jud. BN" },
  { id: "r2q99m", label: "Ilisua - Jud. SJ" },
  { id: "ZRJgNZ", label: "Ilova - Jud. CS" },
  { id: "rXOjnm", label: "Ilovat - Jud. MH" },
  { id: "l57WYr", label: "Ilovita - Jud. MH" },
  { id: "Z4DKjZ", label: "Ilovu - Jud. MH" },
  { id: "3ZVoJl", label: "Ilteu - Jud. AR" },
  { id: "m1k33Z", label: "Ilva Mare - Jud. BN" },
  { id: "lOwbLl", label: "Ilva Mica - Jud. BN" },
  { id: "maojEZ", label: "Imeni - Jud. CV" },
  { id: "lOA13r", label: "Imoasa - Jud. MH" },
  { id: "rwDN3Z", label: "Imper - Jud. HR" },
  { id: "ma189m", label: "Inand - Jud. BH" },
  { id: "Zv5A2r", label: "Inau - Jud. MM" },
  { id: "lDqYJm", label: "Inau - Jud. SJ" },
  { id: "9m1WRZ", label: "Incesti - Jud. AB" },
  { id: "mbP8QZ", label: "Incesti - Jud. BH" },
  { id: "rK3Lxr", label: "Independenta - Jud. CL" },
  { id: "ZzgoYl", label: "Independenta - Jud. CT" },
  { id: "lDP9jl", label: "Independenta - Jud. GL" },
  { id: "lpAo2m", label: "Independenta - Jud. PH" },
  { id: "maLB9l", label: "Inelet - Jud. CS" },
  { id: "Vle06Z", label: "Ineu - Jud. AR" },
  { id: "rqP91m", label: "Ineu - Jud. BH" },
  { id: "r66xDr", label: "Ineu - Jud. HR" },
  { id: "l08WNl", label: "Infratirea - Jud. CL" },
  { id: "mgxyDr", label: "Infratirea - Jud. DJ" },
  { id: "rq05Bm", label: "Ingaresti - Jud. NT" },
  { id: "r2QMQm", label: "Inlaceni - Jud. HR" },
  { id: "NmkqoZ", label: "Inoc - Jud. AB" },
  { id: "lj4Ddr", label: "Inotesti - Jud. PH" },
  { id: "ma1dEm", label: "Insuratei - Jud. BR" },
  { id: "lDPVJl", label: "Insuratei - Jud. GJ" },
  { id: "ma6Ner", label: "Insuratei - Jud. TR" },
  { id: "ZQqg4Z", label: "Intorsura - Jud. DJ" },
  { id: "leoogr", label: "Intorsura Buzaului - Jud. CV" },
  { id: "2l5Ovr", label: "Intregalde - Jud. AB" },
  { id: "lexoYZ", label: "Intrerauri - Jud. MM" },
  { id: "lJW4gm", label: "Inucu - Jud. CJ" },
  { id: "9m1dql", label: "Inuri - Jud. AB" },
  { id: "EZzkYl", label: "Ioanicesti - Jud. AG" },
  { id: "r33ner", label: "Ioanis - Jud. BH" },
  { id: "ZQVool", label: "Iod - Jud. MS" },
  { id: "ZyWOLm", label: "Iohanisfeld - Jud. TM" },
  { id: "leGOPr", label: "Iojib - Jud. SM" },
  { id: "rXyLpZ", label: "Ion Corvin - Jud. CT" },
  { id: "rK2YWm", label: "Ion Creanga - Jud. NT" },
  { id: "lWO7Al", label: "Ion Ghica - Jud. IL" },
  { id: "ZNA8xr", label: "Ion Neculce - Jud. IS" },
  { id: "ZvoY5l", label: "Ion Roata - Jud. IL" },
  { id: "rqOxPl", label: "Ionascu - Jud. TR" },
  { id: "r33jzr", label: "Ionaseni - Jud. BT" },
  { id: "ZyNXKl", label: "Ionasesti - Jud. GL" },
  { id: "lJNx4l", label: "Ioneasa - Jud. SV" },
  { id: "nljM1Z", label: "Ionesti - Jud. AG" },
  { id: "3ZV2JZ", label: "Ionesti - Jud. AR" },
  { id: "mGwYdm", label: "Ionesti - Jud. BR" },
  { id: "mG5ApZ", label: "Ionesti - Jud. BV" },
  { id: "lxbJPr", label: "Ionesti - Jud. DB" },
  { id: "m1qG6m", label: "Ionesti - Jud. GJ" },
  { id: "rB5x2m", label: "Ionesti - Jud. VL" },
  { id: "l0wX0Z", label: "Ionicesti - Jud. OT" },
  { id: "lWOpdl", label: "Iorcani - Jud. IS" },
  { id: "r6y8Dm", label: "Iordacheanu - Jud. PH" },
  { id: "rdVRql", label: "Iordachesti - Jud. DJ" },
  { id: "r9q83r", label: "Iorga - Jud. BT" },
  { id: "ZNAR7r", label: "Iormanesti - Jud. GJ" },
  { id: "krwqal", label: "Iosas - Jud. AR" },
  { id: "ZR6zeZ", label: "Iosif - Jud. TM" },
  { id: "mk19bZ", label: "Iosifalau - Jud. TM" },
  { id: "Z43b0r", label: "Iosupeni - Jud. IS" },
  { id: "ZNwKYl", label: "Ip - Jud. SJ" },
  { id: "rwD5oZ", label: "Ipatele - Jud. IS" },
  { id: "Z4YEql", label: "Ipotesti - Jud. BT" },
  { id: "lxKxQZ", label: "Ipotesti - Jud. OT" },
  { id: "ZR6jNZ", label: "Ipotesti - Jud. SV" },
  { id: "4lOXnm", label: "Iratosu - Jud. AR" },
  { id: "rdV4Gl", label: "Ireasca - Jud. GL" },
  { id: "mA80Bl", label: "Iresti - Jud. VN" },
  { id: "lDqKJm", label: "Irimesti - Jud. PH" },
  { id: "ZyWR0m", label: "Irimesti - Jud. VL" },
  { id: "lWDoBm", label: "Irina - Jud. SM" },
  { id: "ZQy24Z", label: "Isaccea - Jud. TL" },
  { id: "mb5Ldl", label: "Isaci - Jud. OT" },
  { id: "ZP75br", label: "Isaiia - Jud. IS" },
  { id: "rEdKLm", label: "Isalnita - Jud. DJ" },
  { id: "Wr85qZ", label: "Isca - Jud. AB" },
  { id: "mgL77r", label: "Iscroni - Jud. HD" },
  { id: "rq0Wgm", label: "Isla - Jud. MS" },
  { id: "ZVwPol", label: "Islaz - Jud. IF" },
  { id: "rwVbam", label: "Islaz - Jud. TR" },
  { id: "lJ20Yl", label: "Istan Tau - Jud. MS" },
  { id: "l7weLl", label: "Istihaza - Jud. MS" },
  { id: "r3yqql", label: "Istrau - Jud. SM" },
  { id: "Z4qwxl", label: "Istria - Jud. CT" },
  { id: "lYyjOl", label: "Istrita De Jos - Jud. BZ" },
  { id: "ZzgdBl", label: "Isvarna - Jud. GJ" },
  { id: "l070oZ", label: "Isverna - Jud. MH" },
  { id: "mAOBgZ", label: "Isvoarele - Jud. GR" },
  { id: "lxbyMr", label: "Italieni - Jud. DJ" },
  { id: "mG5xBZ", label: "Itcani - Jud. BC" },
  { id: "rByygm", label: "Itesti - Jud. BC" },
  { id: "rdjL5l", label: "Iteu - Jud. BH" },
  { id: "ropqnZ", label: "Iteu Nou - Jud. BH" },
  { id: "l07oBZ", label: "Itrinesti - Jud. NT" },
  { id: "mGPL9r", label: "Iucsa - Jud. NT" },
  { id: "rBb62r", label: "Iugani - Jud. IS" },
  { id: "r9bRdZ", label: "Iugani - Jud. VN" },
  { id: "ZNJVaZ", label: "Iulia - Jud. TL" },
  { id: "ZPaGvl", label: "Iupca - Jud. MH" },
  { id: "ropJnZ", label: "Iuresti - Jud. BT" },
  { id: "ZPeVBl", label: "Iuriu De Campie - Jud. CJ" },
  { id: "lxzDQr", label: "Ivancesti - Jud. VN" },
  { id: "rwV4ym", label: "Ivanda - Jud. TM" },
  { id: "ZQQX5Z", label: "Ivaneasa - Jud. BN" },
  { id: "rK2dQm", label: "Ivanes - Jud. NT" },
  { id: "mkaRbZ", label: "Ivanesti - Jud. IL" },
  { id: "lLVXAr", label: "Ivanesti - Jud. MS" },
  { id: "mbKBKr", label: "Ivanesti - Jud. VS" },
  { id: "Z4wD8r", label: "Ivanetu - Jud. BZ" },
  { id: "OroYLl", label: "Ivanis - Jud. AB" },
  { id: "l7QALr", label: "Ivesti - Jud. GL" },
  { id: "ZzV6BZ", label: "Ivesti - Jud. VS" },
  { id: "r9nqOZ", label: "Ivrinezu Mare - Jud. CT" },
  { id: "mgxP1r", label: "Ivrinezu Mic - Jud. CT" },
  { id: "yZyaKZ", label: "Izbasesti - Jud. AG" },
  { id: "rdNo5l", label: "Izbasesti - Jud. VL" },
  { id: "ZR33Ll", label: "Izbiceni - Jud. OT" },
  { id: "PrE6MZ", label: "Izbicioara - Jud. AB" },
  { id: "LZMVzl", label: "Izbita - Jud. AB" },
  { id: "lYD8JZ", label: "Izbuc - Jud. BH" },
  { id: "rKDVRZ", label: "Izesti - Jud. PH" },
  { id: "rBW02Z", label: "Izgar - Jud. CS" },
  { id: "lLV2Xr", label: "Izimsa - Jud. MH" },
  { id: "BZ4qql", label: "Izlaz - Jud. AB" },
  { id: "r2M0em", label: "Izvin - Jud. TM" },
  { id: "rqPqGm", label: "Izvoare - Jud. BT" },
  { id: "mgxNkr", label: "Izvoare - Jud. DJ" },
  { id: "mnQojl", label: "Izvoare - Jud. HR" },
  { id: "rq0kGm", label: "Izvoare - Jud. NT" },
  { id: "XlxzMr", label: "Izvoarele - Jud. AB" },
  { id: "ZQQ3JZ", label: "Izvoarele - Jud. BH" },
  { id: "maLx2l", label: "Izvoarele - Jud. BZ" },
  { id: "lxbPMr", label: "Izvoarele - Jud. CT" },
  { id: "mG1vBZ", label: "Izvoarele - Jud. DB" },
  { id: "lD2DWr", label: "Izvoarele - Jud. GJ" },
  { id: "Zvg7Dl", label: "Izvoarele - Jud. GL" },
  { id: "rKPpEZ", label: "Izvoarele - Jud. GR" },
  { id: "lYK0xr", label: "Izvoarele - Jud. HD" },
  { id: "rozJNm", label: "Izvoarele - Jud. IS" },
  { id: "ZNYMar", label: "Izvoarele - Jud. MH" },
  { id: "mgax9Z", label: "Izvoarele - Jud. MM" },
  { id: "rd77Wm", label: "Izvoarele - Jud. OT" },
  { id: "r9DeOm", label: "Izvoarele - Jud. PH" },
  { id: "ZQykDZ", label: "Izvoarele - Jud. TL" },
  { id: "rXaN3Z", label: "Izvoarele - Jud. TR" },
  { id: "mbN8dm", label: "Izvoarele - Jud. VN" },
  { id: "ma61br", label: "Izvoarele Sucevei - Jud. SV" },
  { id: "ZvAQxr", label: "Izvor - Jud. CS" },
  { id: "ZPBYMm", label: "Izvor - Jud. DJ" },
  { id: "maxnbl", label: "Izvoralu - Jud. MH" },
  { id: "l074RZ", label: "Izvoralu De Jos - Jud. MH" },
  { id: "AZQoam", label: "Izvorani - Jud. AG" },
  { id: "ZvoNVl", label: "Izvorani - Jud. IF" },
  { id: "lJWyem", label: "Izvoranu - Jud. BZ" },
  { id: "r6565m", label: "Izvorasu - Jud. VL" },
  { id: "JlJnYl", label: "Izvoru - Jud. AG" },
  { id: "Zy2EDl", label: "Izvoru - Jud. BZ" },
  { id: "rqbjoZ", label: "Izvoru - Jud. DB" },
  { id: "leowor", label: "Izvoru - Jud. GR" },
  { id: "ZVdVAm", label: "Izvoru - Jud. NT" },
  { id: "mnzKam", label: "Izvoru - Jud. OT" },
  { id: "lYGOXr", label: "Izvoru - Jud. PH" },
  { id: "rdK6qm", label: "Izvoru - Jud. VL" },
  { id: "krwpyZ", label: "Izvoru Ampoiului - Jud. AB" },
  { id: "ZRKvvl", label: "Izvoru Anestilor - Jud. MH" },
  { id: "ZRKW2l", label: "Izvoru Barzii - Jud. MH" },
  { id: "l7v5XZ", label: "Izvoru Berheciului - Jud. BC" },
  { id: "ZvA8Kr", label: "Izvoru Crisului - Jud. CJ" },
  { id: "grKpkZ", label: "Izvoru De Jos - Jud. AG" },
  { id: "XlDpjl", label: "Izvoru De Sus - Jud. AG" },
  { id: "ZV1qKr", label: "Izvoru Dulce - Jud. BZ" },
  { id: "l073BZ", label: "Izvoru Frumos - Jud. MH" },
  { id: "mboPQr", label: "Izvoru Mare - Jud. CT" },
  { id: "r8Bz0l", label: "Izvoru Muntelui - Jud. NT" },
  { id: "lpboor", label: "Izvoru Muresului - Jud. HR" },
  { id: "rqxBBm", label: "Izvoru Rece - Jud. VL" },
  { id: "l57pNr", label: "Izvorul Alb - Jud. NT" },
  { id: "lWeyqr", label: "Izvorul Rece - Jud. DJ" },
  { id: "ZyGwqm", label: "Izvorul Trotusului - Jud. HR" },
  { id: "l59W9l", label: "Jabar - Jud. TM" },
  { id: "rwPQKZ", label: "Jabenita - Jud. MS" },
  { id: "ZzzMKZ", label: "Jac - Jud. SJ" },
  { id: "rBjaqr", label: "Jacodu - Jud. MS" },
  { id: "ZMJVQl", label: "Jacota - Jud. SV" },
  { id: "r9yedr", label: "Jacu - Jud. MS" },
  { id: "ZyWNqm", label: "Jahalia - Jud. SV" },
  { id: "r30DzZ", label: "Jamu Mare - Jud. TM" },
  { id: "roY83l", label: "Jaristea - Jud. VN" },
  { id: "l78vXm", label: "Jarostea - Jud. VL" },
  { id: "ZQyYpZ", label: "Jdioara - Jud. TM" },
  { id: "m1b06r", label: "Jebel - Jud. TM" },
  { id: "r86aMl", label: "Jebucu - Jud. SJ" },
  { id: "BZ4jAm", label: "Jeflesti - Jud. AB" },
  { id: "r2Yjbm", label: "Jegalia - Jud. CL" },
  { id: "lp1dbm", label: "Jeica - Jud. BN" },
  { id: "rKn7Em", label: "Jeledinti - Jud. HD" },
  { id: "r2VBbr", label: "Jelna - Jud. BN" },
  { id: "lj8Pdr", label: "Jena - Jud. TM" },
  { id: "r2qLem", label: "Jercalai - Jud. PH" },
  { id: "lpbExr", label: "Jeristea - Jud. GJ" },
  { id: "5l72YZ", label: "Jgheaburi - Jud. AG" },
  { id: "ZvO6Vl", label: "Jgheaburi - Jud. VN" },
  { id: "l715Ar", label: "Jghiab - Jud. BZ" },
  { id: "rdxXGr", label: "Jiana - Jud. MH" },
  { id: "ZNYxar", label: "Jiana Mare - Jud. MH" },
  { id: "r8BdKl", label: "Jiana Veche - Jud. MH" },
  { id: "leagPl", label: "Jibert - Jud. BV" },
  { id: "mbK3qr", label: "Jiblea Noua - Jud. VL" },
  { id: "lLJjGr", label: "Jiblea Veche - Jud. VL" },
  { id: "rBnkdr", label: "Jibou - Jud. SJ" },
  { id: "ZvAaKr", label: "Jichisu De Jos - Jud. CJ" },
  { id: "mGwzBm", label: "Jichisu De Sus - Jud. CJ" },
  { id: "BZ4oAr", label: "Jidostina - Jud. AB" },
  { id: "lOAK3r", label: "Jidostita - Jud. MH" },
  { id: "Lmn9Al", label: "Jidvei - Jud. AB" },
  { id: "r6qJDm", label: "Jieni - Jud. DJ" },
  { id: "ZNwGxl", label: "Jieni - Jud. OT" },
  { id: "rqEjPr", label: "Jiet - Jud. HD" },
  { id: "ZNJNVZ", label: "Jigalia - Jud. VS" },
  { id: "lxAgpm", label: "Jignita - Jud. MH" },
  { id: "l7JXLZ", label: "Jigodin Bai - Jud. HR" },
  { id: "ZvoaDl", label: "Jigoreni - Jud. IS" },
  { id: "rdj4al", label: "Jijia - Jud. BT" },
  { id: "r305eZ", label: "Jijila - Jud. TL" },
  { id: "ZRw4Dl", label: "Jilava - Jud. IF" },
  { id: "lj5w4Z", label: "Jilavele - Jud. IL" },
  { id: "rwYWxl", label: "Jiliste - Jud. VN" },
  { id: "mkj3Pl", label: "Jiltu - Jud. GJ" },
  { id: "m1bo9r", label: "Jimbolia - Jud. TM" },
  { id: "mbPQ2Z", label: "Jimbor - Jud. BN" },
  { id: "mgPwkr", label: "Jimbor - Jud. BV" },
  { id: "rKD0QZ", label: "Jina - Jud. SB" },
  { id: "Zy2NYl", label: "Jirlau - Jud. BR" },
  { id: "Zy6Dkl", label: "Jirov - Jud. MH" },
  { id: "lYneEm", label: "Jitaru - Jud. OT" },
  { id: "maNeJr", label: "Jitia - Jud. VN" },
  { id: "lDKo1Z", label: "Jitia De Jos - Jud. VN" },
  { id: "rqz4GZ", label: "Jitin - Jud. CS" },
  { id: "ZyGEom", label: "Jiu Paroseni - Jud. HD" },
  { id: "ZyNK2l", label: "Jiul - Jud. DJ" },
  { id: "krw4xZ", label: "Joia Mare - Jud. AR" },
  { id: "r2769Z", label: "Joita - Jud. GR" },
  { id: "Wr8YVZ", label: "Jojei - Jud. AB" },
  { id: "ZP2yAm", label: "Joldesti - Jud. BT" },
  { id: "krwz3m", label: "Joldisesti - Jud. AB" },
  { id: "r3Q6el", label: "Jolotca - Jud. HR" },
  { id: "mG1L6Z", label: "Jorasti - Jud. GL" },
  { id: "rwY4al", label: "Jorasti - Jud. VN" },
  { id: "ZRjWNZ", label: "Josani - Jud. BH" },
  { id: "r66Q6r", label: "Josani - Jud. HD" },
  { id: "rK32xr", label: "Joseni - Jud. BZ" },
  { id: "ZyG7om", label: "Joseni - Jud. HR" },
  { id: "ZyeM8Z", label: "Josenii Bargaului - Jud. BN" },
  { id: "r3kV4r", label: "Juc Herghelie - Jud. CJ" },
  { id: "m1nK9r", label: "Jucu De Mijloc - Jud. CJ" },
  { id: "rqzqQZ", label: "Jucu De Sus - Jud. CJ" },
  { id: "ZPaqkl", label: "Jugastreni - Jud. MM" },
  { id: "ZPaOAl", label: "Jugastru - Jud. MH" },
  { id: "PrEG5l", label: "Jugur - Jud. AG" },
  { id: "lx88Ql", label: "Jugureanu - Jud. BR" },
  { id: "rqbnoZ", label: "Jugureni - Jud. DB" },
  { id: "mgBy1l", label: "Jugureni - Jud. PH" },
  { id: "EZzenr", label: "Julita - Jud. AR" },
  { id: "ZNW26l", label: "Jupa - Jud. CS" },
  { id: "AZQ44r", label: "Jupanesti - Jud. AG" },
  { id: "lODA3m", label: "Jupanesti - Jud. GJ" },
  { id: "l7wKYl", label: "Jupanesti - Jud. MH" },
  { id: "rKx5EZ", label: "Jupanesti - Jud. TM" },
  { id: "rqO9vl", label: "Jupani - Jud. TM" },
  { id: "l0XXgl", label: "Jupiter - Jud. CT" },
  { id: "mnJwqm", label: "Jurca - Jud. CJ" },
  { id: "Vlex1Z", label: "Jurcuiesti - Jud. AB" },
  { id: "ZzVjnZ", label: "Juresti - Jud. TM" },
  { id: "m1bNRr", label: "Jurilovca - Jud. TL" },
  { id: "rwDAQZ", label: "Kogalniceni - Jud. IS" },
  { id: "r33Mnr", label: "La Curte - Jud. BN" },
  { id: "3r2Ber", label: "Labasint - Jud. AR" },
  { id: "lexdgZ", label: "Lac - Jud. MH" },
  { id: "PrEBxZ", label: "Laceni - Jud. AG" },
  { id: "rwV7om", label: "Laceni - Jud. TR" },
  { id: "l7QxYr", label: "Lacrita Mare - Jud. DJ" },
  { id: "mG1XnZ", label: "Lacrita Mica - Jud. DJ" },
  { id: "ZMRQ5l", label: "Lacu - Jud. BZ" },
  { id: "lYyAxl", label: "Lacu - Jud. CJ" },
  { id: "l79Lnr", label: "Lacu Babei - Jud. VS" },
  { id: "m1n19r", label: "Lacu Cu Anini - Jud. BZ" },
  { id: "r9boNZ", label: "Lacu Lui Baban - Jud. VN" },
  { id: "Zv2RPZ", label: "Lacu Rezii - Jud. BR" },
  { id: "mgLB1r", label: "Lacu Rosu - Jud. HR" },
  { id: "rwnW3m", label: "Lacu Sarat - Jud. BH" },
  { id: "ZMROQl", label: "Lacu Sarat - Jud. BR" },
  { id: "Z4w3zr", label: "Lacu Sinaia - Jud. BZ" },
  { id: "lOBKnZ", label: "Lacu Turcului - Jud. PH" },
  { id: "m1DWql", label: "Laculete - Jud. DB" },
  { id: "lJP1Wr", label: "Laculete Gara - Jud. DB" },
  { id: "0lY3Xr", label: "Lacurile - Jud. AG" },
  { id: "Zy264l", label: "Lacurile - Jud. BZ" },
  { id: "rKnbQm", label: "Lacusteni - Jud. IL" },
  { id: "rB5WDm", label: "Lacusteni - Jud. VL" },
  { id: "rqxzvm", label: "Lacustenii De Jos - Jud. VL" },
  { id: "ZQwvnm", label: "Lacustenii De Sus - Jud. VL" },
  { id: "ma6A2r", label: "Lada - Jud. TR" },
  { id: "rBOb5Z", label: "Ladauti - Jud. CV" },
  { id: "ZQwDgm", label: "Ladesti - Jud. VL" },
  { id: "Jr3PzZ", label: "Laicai - Jud. AG" },
  { id: "AZv4Vm", label: "Lalasint - Jud. AR" },
  { id: "rBR6Jr", label: "Lalesti - Jud. VS" },
  { id: "rqxN9m", label: "Lalosu - Jud. VL" },
  { id: "rqObBl", label: "Lamaseni - Jud. SV" },
  { id: "lJ6vwl", label: "Lamotesti - Jud. VN" },
  { id: "Rr9xmK", label: "Lancram - Jud. AB" },
  { id: "ZNGO6l", label: "Langa - Jud. DJ" },
  { id: "LZMa4Z", label: "Langesti - Jud. AG" },
  { id: "rBWWVZ", label: "Lanurile - Jud. BR" },
  { id: "mgA7GZ", label: "Lanurile - Jud. BZ" },
  { id: "rqz2GZ", label: "Lanurile - Jud. CT" },
  { id: "grKvxm", label: "Lapos - Jud. BC" },
  { id: "mb5qQl", label: "Lapos - Jud. PH" },
  { id: "r6yjXm", label: "Laposel - Jud. PH" },
  { id: "lYKaOr", label: "Lapugiu De Jos - Jud. HD" },
  { id: "mbOkKr", label: "Lapugiu De Sus - Jud. HD" },
  { id: "ZQVynl", label: "Lapus - Jud. MM" },
  { id: "Jr31zl", label: "Lapusani - Jud. AG" },
  { id: "ZVddWm", label: "Lapusel - Jud. MM" },
  { id: "r2wpAZ", label: "Lapusna - Jud. MS" },
  { id: "lez1om", label: "Lapusnic - Jud. HD" },
  { id: "lj8jBr", label: "Lapusnic - Jud. TM" },
  { id: "ZQvXgl", label: "Lapusnicel - Jud. CS" },
  { id: "r3kLqr", label: "Lapusnicu Mare - Jud. CS" },
  { id: "rwpRyZ", label: "Lapustesti - Jud. CJ" },
  { id: "VrXB9l", label: "Larga - Jud. BC" },
  { id: "lL1DPm", label: "Larga - Jud. GJ" },
  { id: "rq0p0m", label: "Larga - Jud. MM" },
  { id: "lxAnPm", label: "Larga - Jud. MS" },
  { id: "rE21VZ", label: "Larga Jijia - Jud. IS" },
  { id: "lL62yr", label: "Largaseni - Jud. VN" },
  { id: "l54ApZ", label: "Largu - Jud. BZ" },
  { id: "Z4Yqpl", label: "Larguta - Jud. BC" },
  { id: "lL1G6m", label: "Lasau - Jud. HD" },
  { id: "ZQVqpl", label: "Laschia - Jud. MM" },
  { id: "l57VEr", label: "Lascud - Jud. MS" },
  { id: "lOAknr", label: "Laslau Mare - Jud. MS" },
  { id: "Zy610l", label: "Laslau Mic - Jud. MS" },
  { id: "r2qXpm", label: "Laslea - Jud. SB" },
  { id: "Z4120l", label: "Lastuni - Jud. TL" },
  { id: "m184al", label: "Lastuni - Jud. VN" },
  { id: "lWvbBZ", label: "Latesti - Jud. VS" },
  { id: "rd5j7Z", label: "Latinu - Jud. BR" },
  { id: "lJNzel", label: "Latunas - Jud. TM" },
  { id: "ZR3xql", label: "Launele - Jud. OT" },
  { id: "ZMJvpl", label: "Launele De Jos - Jud. VL" },
  { id: "ElLEWm", label: "Launele De Sus - Jud. AG" },
  { id: "lD6RGr", label: "Laureni - Jud. MS" },
  { id: "4lOVAZ", label: "Laz - Jud. AB" },
  { id: "ElL0Al", label: "Laz - Jud. AR" },
  { id: "l5Q9vl", label: "Laz Firtanus - Jud. HR" },
  { id: "l0NKRr", label: "Laz Soimus - Jud. HR" },
  { id: "rXaz3Z", label: "Laza - Jud. VS" },
  { id: "rKn80m", label: "Lazarea - Jud. HR" },
  { id: "r33wVr", label: "Lazareni - Jud. BH" },
  { id: "LmnVxm", label: "Lazaresti - Jud. AG" },
  { id: "m1Dz3l", label: "Lazaresti - Jud. GJ" },
  { id: "r3Q0Al", label: "Lazaresti - Jud. HR" },
  { id: "rXO97m", label: "Lazaresti - Jud. OT" },
  { id: "r2qvdm", label: "Lazaret - Jud. SB" },
  { id: "arBNVr", label: "Lazesti - Jud. AB" },
  { id: "ZzoR8l", label: "Lazu - Jud. CT" },
  { id: "lDPwnl", label: "Lazu - Jud. DJ" },
  { id: "r9ydOr", label: "Lazu - Jud. MH" },
  { id: "mk1doZ", label: "Lazu - Jud. VS" },
  { id: "r9yqyr", label: "Lazu Baciului - Jud. MM" },
  { id: "arBG2Z", label: "Lazuri - Jud. AB" },
  { id: "wlp0Ml", label: "Lazuri - Jud. AR" },
  { id: "lLez1m", label: "Lazuri - Jud. BH" },
  { id: "ronG8m", label: "Lazuri - Jud. DB" },
  { id: "mGx1nr", label: "Lazuri - Jud. GJ" },
  { id: "robj3m", label: "Lazuri - Jud. SJ" },
  { id: "rob7xm", label: "Lazuri - Jud. SM" },
  { id: "ZQQeJZ", label: "Lazuri De Beius - Jud. BH" },
  { id: "rwDJVZ", label: "Leahu Nacu - Jud. IS" },
  { id: "rdV3ql", label: "Leamna De Jos - Jud. DJ" },
  { id: "ron85m", label: "Leamna De Sus - Jud. DJ" },
  { id: "JlJD3r", label: "Leasa - Jud. AR" },
  { id: "l5Q39l", label: "Leaut - Jud. HD" },
  { id: "l57K9r", label: "Lechincioara - Jud. MS" },
  { id: "r2VpAr", label: "Lechinta - Jud. BN" },
  { id: "r6G03Z", label: "Lechinta - Jud. MS" },
  { id: "ZvE93r", label: "Lechinta - Jud. SM" },
  { id: "ZQVgvl", label: "Lefaia - Jud. MS" },
  { id: "lYy40l", label: "Leghia - Jud. CJ" },
  { id: "m1GAjZ", label: "Leghin - Jud. NT" },
  { id: "mAbP4r", label: "Legii - Jud. CJ" },
  { id: "r2Vwer", label: "Lehancea - Jud. BC" },
  { id: "mAAn8m", label: "Leheceni - Jud. BH" },
  { id: "7rqg9l", label: "Lehesti - Jud. AB" },
  { id: "ZV1B6r", label: "Lehliu - Jud. CL" },
  { id: "rwpB3Z", label: "Lehliu Gara - Jud. CL" },
  { id: "ma1p9m", label: "Lehnesti - Jud. BT" },
  { id: "9m1Q6m", label: "Leicesti - Jud. AG" },
  { id: "lYy8Ol", label: "Leiculesti - Jud. BZ" },
  { id: "robb3m", label: "Leleasca - Jud. OT" },
  { id: "robANm", label: "Lelei - Jud. SM" },
  { id: "mA4DWl", label: "Lelese - Jud. HD" },
  { id: "Zye7kZ", label: "Lelesti - Jud. BH" },
  { id: "mnP8AZ", label: "Lelesti - Jud. BN" },
  { id: "ZyG6km", label: "Lelesti - Jud. GJ" },
  { id: "rE245Z", label: "Leliceni - Jud. HR" },
  { id: "ronxxm", label: "Lemnia - Jud. CV" },
  { id: "r6yK9m", label: "Lemniu - Jud. SJ" },
  { id: "lOYg3r", label: "Lenauheim - Jud. TM" },
  { id: "ZVdGJm", label: "Lenis - Jud. MS" },
  { id: "AZvqKr", label: "Lentea - Jud. AG" },
  { id: "wmg25l", label: "Leontinesti - Jud. BC" },
  { id: "r338Ar", label: "Leorda - Jud. BT" },
  { id: "0lYXQZ", label: "Leordeni - Jud. AG" },
  { id: "rq0Rgm", label: "Leordeni - Jud. MS" },
  { id: "r3B0Ym", label: "Leordina - Jud. MM" },
  { id: "ron45m", label: "Leordoasa - Jud. DJ" },
  { id: "WmGOdm", label: "Leorint - Jud. AB" },
  { id: "r6G19Z", label: "Leorinta - Jud. MS" },
  { id: "lYnbxm", label: "Leorinta Saulia - Jud. MS" },
  { id: "lLJYAr", label: "Leosti - Jud. VS" },
  { id: "mGP2dr", label: "Leotesti - Jud. OT" },
  { id: "lOAPOr", label: "Lepindea - Jud. MS" },
  { id: "mk5PzZ", label: "Lepsa - Jud. VN" },
  { id: "mGwaDm", label: "Lera - Jud. BZ" },
  { id: "JmAq6m", label: "Leresti - Jud. AG" },
  { id: "ljG6wm", label: "Les - Jud. BH" },
  { id: "lOWoNl", label: "Lescovita - Jud. CS" },
  { id: "Qmb6om", label: "Lesile - Jud. AG" },
  { id: "r9nNOZ", label: "Lesile - Jud. DJ" },
  { id: "leGXor", label: "Lesmir - Jud. SJ" },
  { id: "r3QL0l", label: "Lesnic - Jud. HD" },
  { id: "Wr6j3r", label: "Lespezea - Jud. AB" },
  { id: "JlJR9l", label: "Lespezi - Jud. AG" },
  { id: "mAA6gm", label: "Lespezi - Jud. BC" },
  { id: "lOW93l", label: "Lespezi - Jud. CT" },
  { id: "rBbAdr", label: "Lespezi - Jud. IS" },
  { id: "l0K6kZ", label: "Lespezi - Jud. VL" },
  { id: "lxzBwr", label: "Lespezi - Jud. VN" },
  { id: "0lYYAl", label: "Lestioara - Jud. AR" },
  { id: "rKjbEm", label: "Lesu - Jud. BN" },
  { id: "lO6DJZ", label: "Let - Jud. CV" },
  { id: "r86E6l", label: "Letca - Jud. SJ" },
  { id: "ZVLpJm", label: "Letca Noua - Jud. GR" },
  { id: "ZPB53m", label: "Letca Veche - Jud. GR" },
  { id: "rqEdPr", label: "Letcani - Jud. IS" },
  { id: "r2MpQm", label: "Letea - Jud. TL" },
  { id: "lOw7ol", label: "Letea Veche - Jud. BC" },
  { id: "mbo2vr", label: "Leu - Jud. DJ" },
  { id: "l79Qwr", label: "Leucusesti - Jud. SV" },
  { id: "rBRvqr", label: "Leucusesti - Jud. TM" },
  { id: "lYyBAl", label: "Leurda - Jud. CJ" },
  { id: "l7QD3r", label: "Leurda - Jud. GJ" },
  { id: "rqE39r", label: "Liban - Jud. HR" },
  { id: "rqPyQm", label: "Libertatea - Jud. BT" },
  { id: "rqzGPZ", label: "Libertatea - Jud. CL" },
  { id: "Zy2Lql", label: "Liborajdea - Jud. CS" },
  { id: "r8BO6l", label: "Libotin - Jud. MM" },
  { id: "mG5gwZ", label: "Lichitiseni - Jud. BC" },
  { id: "rKn2Gm", label: "Licurici - Jud. GJ" },
  { id: "lOYYOr", label: "Licuriciu - Jud. TR" },
  { id: "ZyWLkm", label: "Liebling - Jud. TM" },
  { id: "rwyJXl", label: "Liesti - Jud. GL" },
  { id: "mk50zZ", label: "Liesti - Jud. VN" },
  { id: "lDP8Yl", label: "Lihulesti - Jud. GJ" },
  { id: "lj4Ebr", label: "Liiceni - Jud. OT" },
  { id: "l7wGvl", label: "Liliac - Jud. NT" },
  { id: "mnPPpZ", label: "Lilieci - Jud. BC" },
  { id: "r8OJem", label: "Lilieci - Jud. IL" },
  { id: "rX7x3m", label: "Liliesti - Jud. PH" },
  { id: "rKP3WZ", label: "Limanu - Jud. CT" },
  { id: "XlxKpZ", label: "Limba - Jud. AB" },
  { id: "ZvAbJr", label: "Limpezis - Jud. BZ" },
  { id: "r6O05Z", label: "Lindenfeld - Jud. CS" },
  { id: "r842Vr", label: "Linia - Jud. VL" },
  { id: "l59vRl", label: "Linia Costii - Jud. TR" },
  { id: "r849qr", label: "Linia Dealului - Jud. VL" },
  { id: "m1G2gZ", label: "Linia Din Vale - Jud. OT" },
  { id: "lDK2XZ", label: "Linia Hanului - Jud. VL" },
  { id: "leJg3m", label: "Linia Pe Vale - Jud. VL" },
  { id: "r9yLMr", label: "Linsesti - Jud. NT" },
  { id: "rdxvRr", label: "Lint - Jud. MS" },
  { id: "rwDyQZ", label: "Lintea - Jud. GJ" },
  { id: "Wr8Vel", label: "Lintesti - Jud. AG" },
  { id: "Jr3QYl", label: "Lipaia - Jud. AB" },
  { id: "lpzo2r", label: "Lipanescu - Jud. BZ" },
  { id: "lL70XZ", label: "Lipanesti - Jud. PH" },
  { id: "rBnY2r", label: "Lipau - Jud. SM" },
  { id: "PrEb5Z", label: "Lipia - Jud. AG" },
  { id: "lJWwWm", label: "Lipia - Jud. BZ" },
  { id: "r8OjPm", label: "Lipia - Jud. IF" },
  { id: "lxb80r", label: "Lipnita - Jud. CT" },
  { id: "olWyNr", label: "Lipova - Jud. AR" },
  { id: "mG5j9Z", label: "Lipova - Jud. BC" },
  { id: "Z41xpl", label: "Lipovat - Jud. VS" },
  { id: "rEaQ5r", label: "Lipoveni - Jud. SV" },
  { id: "lLDkYl", label: "Lipovu - Jud. DJ" },
  { id: "ZyNLkl", label: "Lipovu De Sus - Jud. DJ" },
  { id: "lp1Oom", label: "Lisa - Jud. BV" },
  { id: "lL7DbZ", label: "Lisa - Jud. OT" },
  { id: "Z41dpl", label: "Lisa - Jud. TR" },
  { id: "rdKjAm", label: "Lisaura - Jud. SV" },
  { id: "Z4wv1r", label: "Liscoteanca - Jud. BR" },
  { id: "mnP6XZ", label: "Lismanita - Jud. BT" },
  { id: "ZQQ14Z", label: "Lisna - Jud. BT" },
  { id: "Zvg5Pl", label: "Lisnau - Jud. CV" },
  { id: "lj93qZ", label: "Lisnau Vale - Jud. CV" },
  { id: "r27BvZ", label: "Listeava - Jud. DJ" },
  { id: "ZV1pWr", label: "Lita - Jud. CJ" },
  { id: "rBRJgr", label: "Lita - Jud. TR" },
  { id: "lJWpem", label: "Liteni - Jud. CJ" },
  { id: "rozXLm", label: "Liteni - Jud. IS" },
  { id: "mk1wwZ", label: "Liteni - Jud. SV" },
  { id: "r2Yavm", label: "Liubcova - Jud. CS" },
  { id: "krdkam", label: "Livada - Jud. AR" },
  { id: "r338zr", label: "Livada - Jud. BT" },
  { id: "ZNWD1l", label: "Livada - Jud. BZ" },
  { id: "ZRJ2eZ", label: "Livada - Jud. CJ" },
  { id: "l0NGkr", label: "Livada - Jud. HD" },
  { id: "lYGYRr", label: "Livada - Jud. SM" },
  { id: "lO7vAl", label: "Livada - Jud. VN" },
  { id: "rqPndm", label: "Livada Beiusului - Jud. BH" },
  { id: "ZP2JEm", label: "Livada De Bihor - Jud. BH" },
  { id: "r8D4Xr", label: "Livada Mica - Jud. BZ" },
  { id: "r86Gql", label: "Livada Mica - Jud. SM" },
  { id: "ma5R9m", label: "Livadea - Jud. PH" },
  { id: "mkaLOZ", label: "Livadia - Jud. HD" },
  { id: "lWvPqZ", label: "Livadia - Jud. VL" },
  { id: "l5QK0l", label: "Livedea - Jud. IL" },
  { id: "mgPbGr", label: "Liveni - Jud. BT" },
  { id: "AZQ0Dr", label: "Livezeni - Jud. AG" },
  { id: "ZNY47r", label: "Livezeni - Jud. MS" },
  { id: "rwn7jm", label: "Livezi - Jud. BC" },
  { id: "mboWYr", label: "Livezi - Jud. DJ" },
  { id: "r3QYnl", label: "Livezi - Jud. HD" },
  { id: "lezpgm", label: "Livezi - Jud. HR" },
  { id: "rwPxQZ", label: "Livezi - Jud. MH" },
  { id: "r36vqm", label: "Livezi - Jud. VL" },
  { id: "XlDG1Z", label: "Livezile - Jud. AB" },
  { id: "lDAjYm", label: "Livezile - Jud. BN" },
  { id: "mbodKr", label: "Livezile - Jud. DB" },
  { id: "roE6Nl", label: "Livezile - Jud. MH" },
  { id: "rox45Z", label: "Livezile - Jud. TM" },
  { id: "r9beyZ", label: "Livezile - Jud. VN" },
  { id: "lDAN1m", label: "Liviu Rebreanu - Jud. BN" },
  { id: "ZV3bRl", label: "Loamnes - Jud. SB" },
  { id: "ZPeykl", label: "Lobodas - Jud. CJ" },
  { id: "lL1xYm", label: "Locodeni - Jud. HR" },
  { id: "r9nw3Z", label: "Locusteni - Jud. DJ" },
  { id: "0lY7Qr", label: "Lodroman - Jud. AB" },
  { id: "ZMV2Rl", label: "Logig - Jud. MS" },
  { id: "lODwKm", label: "Logresti Mosteni - Jud. GJ" },
  { id: "lYPjQZ", label: "Lojnita - Jud. VN" },
  { id: "ZyYpkZ", label: "Loloiasca - Jud. PH" },
  { id: "4lOxJm", label: "Loman - Jud. AB" },
  { id: "ZV37Jl", label: "Lompirt - Jud. SJ" },
  { id: "5l7Wql", label: "Lopadea Noua - Jud. AB" },
  { id: "VrXWpm", label: "Lopadea Veche - Jud. AB" },
  { id: "rK32er", label: "Lopatareasa - Jud. BZ" },
  { id: "l08VPl", label: "Lopatari - Jud. BZ" },
  { id: "l0w9BZ", label: "Lopatnita - Jud. PH" },
  { id: "rXdjDr", label: "Loranta - Jud. BH" },
  { id: "r9qRMr", label: "Lorau - Jud. BH" },
  { id: "ZV3vWl", label: "Lotrioara - Jud. SB" },
  { id: "max0El", label: "Lotu - Jud. MS" },
  { id: "XlDn2r", label: "Loturi - Jud. AG" },
  { id: "mbPjaZ", label: "Loturi - Jud. BT" },
  { id: "Z4Y6Ql", label: "Loturi Enescu - Jud. BT" },
  { id: "Z4YLxl", label: "Lovnic - Jud. BV" },
  { id: "rKxgGZ", label: "Lovrin - Jud. TM" },
  { id: "rdjnzl", label: "Lozna - Jud. BT" },
  { id: "l58e9Z", label: "Lozna - Jud. SJ" },
  { id: "l7QLnr", label: "Lozova - Jud. GL" },
  { id: "lJ9ozZ", label: "Lucaceni - Jud. SM" },
  { id: "ZQVGpl", label: "Lucacesti - Jud. MM" },
  { id: "mGWVpm", label: "Lucacesti - Jud. SV" },
  { id: "rK3gWr", label: "Lucacevat - Jud. CS" },
  { id: "Zvz6JZ", label: "Lucaret - Jud. TM" },
  { id: "ZyGnom", label: "Luceni - Jud. IS" },
  { id: "lO67OZ", label: "Lucianca - Jud. DB" },
  { id: "0lYBJm", label: "Lucieni - Jud. AG" },
  { id: "lDPGYl", label: "Lucieni - Jud. DB" },
  { id: "ZRJGyZ", label: "Luciu - Jud. BZ" },
  { id: "majKDr", label: "Luciu - Jud. IL" },
  { id: "l59kRl", label: "Ludaneasca - Jud. TR" },
  { id: "Vley6l", label: "Ludasi - Jud. BC" },
  { id: "l7QWAr", label: "Ludesti - Jud. DB" },
  { id: "l0NJ6r", label: "Ludestii De Jos - Jud. HD" },
  { id: "ZRw1Ll", label: "Ludestii De Sus - Jud. HD" },
  { id: "ZNy5Qr", label: "Ludisor - Jud. BV" },
  { id: "lJ9nAZ", label: "Ludos - Jud. SB" },
  { id: "mGP9Wr", label: "Ludu - Jud. MH" },
  { id: "rXOe7m", label: "Ludus - Jud. MS" },
  { id: "r2Qdgm", label: "Lueta - Jud. HR" },
  { id: "m1k7aZ", label: "Lugasu De Jos - Jud. BH" },
  { id: "rByD5m", label: "Lugasu De Sus - Jud. BH" },
  { id: "Z41opl", label: "Lugoj - Jud. TM" },
  { id: "ZPAwkl", label: "Lugojel - Jud. TM" },
  { id: "arBYqm", label: "Luguzau - Jud. AR" },
  { id: "lx8Owl", label: "Luica - Jud. CL" },
  { id: "ZVdeKm", label: "Luieriu - Jud. MS" },
  { id: "rBy9Dm", label: "Luizi Calugara - Jud. BC" },
  { id: "ZzokBl", label: "Lujerdiu - Jud. CJ" },
  { id: "lJPPzr", label: "Lumina - Jud. CT" },
  { id: "AZQNgl", label: "Luminesti - Jud. AB" },
  { id: "XlxYMm", label: "Luminile - Jud. AG" },
  { id: "mGPypr", label: "Luminis - Jud. NT" },
  { id: "rKD4eZ", label: "Luminisu - Jud. SJ" },
  { id: "lLaoWm", label: "Luminita - Jud. CT" },
  { id: "l59P3l", label: "Luminita - Jud. TL" },
  { id: "maxObl", label: "Lumnic - Jud. MH" },
  { id: "ljNQ1l", label: "Luna - Jud. CJ" },
  { id: "ZvEB3r", label: "Luna - Jud. SM" },
  { id: "mnJkNm", label: "Luna De Jos - Jud. CJ" },
  { id: "Zy2k8l", label: "Luna De Sus - Jud. CJ" },
  { id: "XlxeQm", label: "Lunca - Jud. AB" },
  { id: "olWbAZ", label: "Lunca - Jud. AG" },
  { id: "lOwoOl", label: "Lunca - Jud. BH" },
  { id: "lLek6m", label: "Lunca - Jud. BN" },
  { id: "m1kPjZ", label: "Lunca - Jud. BT" },
  { id: "mGwOBm", label: "Lunca - Jud. BZ" },
  { id: "rqzyPZ", label: "Lunca - Jud. CL" },
  { id: "rwyjKl", label: "Lunca - Jud. DB" },
  { id: "mboEor", label: "Lunca - Jud. GL" },
  { id: "rKnOxm", label: "Lunca - Jud. HD" },
  { id: "mnQkxl", label: "Lunca - Jud. IS" },
  { id: "r8BV0l", label: "Lunca - Jud. MS" },
  { id: "r8B7ql", label: "Lunca - Jud. NT" },
  { id: "lOBDGZ", label: "Lunca - Jud. OT" },
  { id: "rBRj2r", label: "Lunca - Jud. SV" },
  { id: "roxWLZ", label: "Lunca - Jud. TL" },
  { id: "lxEJDm", label: "Lunca - Jud. TR" },
  { id: "ZzVRNZ", label: "Lunca - Jud. VL" },
  { id: "mnpvaZ", label: "Lunca - Jud. VS" },
  { id: "2l5M3l", label: "Lunca Ampoitei - Jud. AB" },
  { id: "mnzLxm", label: "Lunca Apei - Jud. SM" },
  { id: "VrXM4Z", label: "Lunca Asau - Jud. BC" },
  { id: "r3B6Ym", label: "Lunca Banului - Jud. MH" },
  { id: "lxEjDm", label: "Lunca Banului - Jud. VS" },
  { id: "PrEvoZ", label: "Lunca Bisericii - Jud. AB" },
  { id: "ZNW8Ll", label: "Lunca Bontului - Jud. CJ" },
  { id: "r33M5r", label: "Lunca Borlesei - Jud. BN" },
  { id: "l57yNr", label: "Lunca Bradului - Jud. MS" },
  { id: "mAAYKm", label: "Lunca Calnicului - Jud. BV" },
  { id: "ZNAV7r", label: "Lunca Cernii De Jos - Jud. HD" },
  { id: "mnQeNl", label: "Lunca Cernii De Sus - Jud. HD" },
  { id: "rKndPm", label: "Lunca Cetatuii - Jud. IS" },
  { id: "qZN8ym", label: "Lunca Corbului - Jud. AG" },
  { id: "LZMngl", label: "Lunca De Jos - Jud. AB" },
  { id: "ZVwMxl", label: "Lunca De Jos - Jud. HR" },
  { id: "lJx8Yr", label: "Lunca De Sus - Jud. HR" },
  { id: "lWBvEZ", label: "Lunca Dochiei - Jud. BC" },
  { id: "ljNVLl", label: "Lunca Florii - Jud. CS" },
  { id: "r8Dp6r", label: "Lunca Frumoasa - Jud. BZ" },
  { id: "WmGydZ", label: "Lunca Gartii - Jud. AG" },
  { id: "VledOZ", label: "Lunca Goiesti - Jud. AB" },
  { id: "l7vVAZ", label: "Lunca Ilvei - Jud. BN" },
  { id: "lW1xEr", label: "Lunca Jaristei - Jud. BZ" },
  { id: "rK23Rm", label: "Lunca La Tisa - Jud. MM" },
  { id: "yZR7Nl", label: "Lunca Larga - Jud. AB" },
  { id: "l0pgQl", label: "Lunca Lesului - Jud. BN" },
  { id: "lxbAzr", label: "Lunca Marcusului - Jud. CV" },
  { id: "robPnm", label: "Lunca Mare - Jud. PH" },
  { id: "4lODom", label: "Lunca Merilor - Jud. AB" },
  { id: "Xl0Mgm", label: "Lunca Metesului - Jud. AB" },
  { id: "ZMVAzl", label: "Lunca Moldovei - Jud. NT" },
  { id: "WmGq2Z", label: "Lunca Muresului - Jud. AB" },
  { id: "rE6eoZ", label: "Lunca Muresului - Jud. MS" },
  { id: "ZPBabm", label: "Lunca Ozunului - Jud. CV" },
  { id: "leGVJr", label: "Lunca Prahovei - Jud. PH" },
  { id: "lOWDAl", label: "Lunca Priporului - Jud. BZ" },
  { id: "rwD23Z", label: "Lunca Rates - Jud. IS" },
  { id: "rwnQam", label: "Lunca Sateasca - Jud. BN" },
  { id: "krd27l", label: "Lunca Tarnavei - Jud. AB" },
  { id: "lxEYwm", label: "Lunca Veche - Jud. VS" },
  { id: "wlpLWl", label: "Lunca Vesesti - Jud. AB" },
  { id: "ZzoYNl", label: "Lunca Visagului - Jud. CJ" },
  { id: "ZPeMMl", label: "Lunca Zaicii - Jud. CS" },
  { id: "ZQQvoZ", label: "Luncani - Jud. BC" },
  { id: "r2Yvem", label: "Luncani - Jud. CJ" },
  { id: "lD2G1r", label: "Luncani - Jud. HD" },
  { id: "Z4390r", label: "Luncani - Jud. HR" },
  { id: "rXavLZ", label: "Luncanii De Jos - Jud. TM" },
  { id: "Z41Bql", label: "Luncanii De Sus - Jud. TM" },
  { id: "ZVw5Ol", label: "Luncasi - Jud. IS" },
  { id: "mk8qnr", label: "Luncasprie - Jud. BH" },
  { id: "m1nggr", label: "Luncavita - Jud. CS" },
  { id: "lOYkNr", label: "Luncavita - Jud. TL" },
  { id: "ZzoBBl", label: "Luncile - Jud. BZ" },
  { id: "mA8n6l", label: "Luncile - Jud. VN" },
  { id: "r8OA0m", label: "Luncoiu De Jos - Jud. HD" },
  { id: "rqEeor", label: "Luncoiu De Sus - Jud. HD" },
  { id: "Xl0J6r", label: "Luncsoara - Jud. AR" },
  { id: "lp1Qem", label: "Luncsoara - Jud. BH" },
  { id: "mA4gWl", label: "Luncsoara - Jud. HD" },
  { id: "lJ2Kpl", label: "Luncsoara - Jud. MH" },
  { id: "l0XDPl", label: "Luncsoru - Jud. DJ" },
  { id: "lpVbor", label: "Luncusoara - Jud. SV" },
  { id: "mgxxkr", label: "Lunga - Jud. CV" },
  { id: "mk16BZ", label: "Lunga - Jud. TM" },
  { id: "5l7zLZ", label: "Lungani - Jud. AG" },
  { id: "ZQGbvm", label: "Lungani - Jud. IS" },
  { id: "ZyYRLZ", label: "Lungeni - Jud. SV" },
  { id: "r6O56Z", label: "Lungesti - Jud. BZ" },
  { id: "r6OAWZ", label: "Lungesti - Jud. CJ" },
  { id: "rEdP5m", label: "Lungesti - Jud. GL" },
  { id: "m181gl", label: "Lungesti - Jud. VL" },
  { id: "rKP4kZ", label: "Lungoci - Jud. GL" },
  { id: "3r2N1r", label: "Lungulesti - Jud. AG" },
  { id: "mG1qBZ", label: "Lunguletu - Jud. DB" },
  { id: "lOWXJl", label: "Lupac - Jud. CS" },
  { id: "VleEgl", label: "Lupaiesti - Jud. AB" },
  { id: "r6bkXr", label: "Luparia - Jud. BT" },
  { id: "lj5qkZ", label: "Luparia - Jud. IF" },
  { id: "lx7wMZ", label: "Luparia - Jud. IS" },
  { id: "r30QqZ", label: "Lupcina - Jud. SV" },
  { id: "rqbX1Z", label: "Lupele - Jud. GL" },
  { id: "r3QdVl", label: "Lupeni - Jud. HD" },
  { id: "lYKjQr", label: "Lupeni - Jud. HR" },
  { id: "VrX5gZ", label: "Lupesti - Jud. AR" },
  { id: "l7v13Z", label: "Lupesti - Jud. BC" },
  { id: "lWvYNZ", label: "Lupesti - Jud. VS" },
  { id: "ma1nbm", label: "Lupoaia - Jud. BH" },
  { id: "l7QMAr", label: "Lupoaia - Jud. GJ" },
  { id: "rX7b4m", label: "Lupoaia - Jud. SJ" },
  { id: "lWMRYr", label: "Lupoaia - Jud. VL" },
  { id: "lO7NOl", label: "Lupoaia - Jud. VN" },
  { id: "mG1A0Z", label: "Lupoita - Jud. GJ" },
  { id: "krw0oZ", label: "Lupsa - Jud. AB" },
  { id: "ljGQwm", label: "Lupsa - Jud. BV" },
  { id: "lYnvXm", label: "Lupsa De Jos - Jud. MH" },
  { id: "mA1Vqm", label: "Lupsa De Sus - Jud. MH" },
  { id: "lJWLwm", label: "Lupsanu - Jud. CL" },
  { id: "grKxPZ", label: "Lupseni - Jud. AB" },
  { id: "lx8yQl", label: "Luptatori - Jud. CL" },
  { id: "Wr8Bel", label: "Lupu - Jud. AB" },
  { id: "arBkbZ", label: "Lupueni - Jud. AG" },
  { id: "Z4N90m", label: "Lupuiesti - Jud. VL" },
  { id: "wlp5em", label: "Lupulesti - Jud. AB" },
  { id: "l7vBqZ", label: "Lusca - Jud. BN" },
  { id: "mgPO1r", label: "Luta - Jud. BV" },
  { id: "rBjzQr", label: "Lutca - Jud. NT" },
  { id: "rwDNVZ", label: "Lutita - Jud. HR" },
  { id: "mAO12Z", label: "Lutoasa - Jud. CV" },
  { id: "r6yNWm", label: "Lutu Rosu - Jud. PH" },
  { id: "EZzX8m", label: "Macai - Jud. AG" },
  { id: "krwxVZ", label: "Macaresti - Jud. AB" },
  { id: "mA4B2l", label: "Macaresti - Jud. IS" },
  { id: "mAbdgr", label: "Macau - Jud. CJ" },
  { id: "yZyv0l", label: "Macea - Jud. AR" },
  { id: "mnp1NZ", label: "Macedonia - Jud. TM" },
  { id: "maL0jl", label: "Macesti - Jud. CS" },
  { id: "Zvg82l", label: "Macesu - Jud. GJ" },
  { id: "ZzgOYl", label: "Macesu De Jos - Jud. DJ" },
  { id: "rXyGpZ", label: "Macesu De Sus - Jud. DJ" },
  { id: "r3Qanl", label: "Maceu - Jud. HD" },
  { id: "mA1o4m", label: "Macicasesti - Jud. MS" },
  { id: "mnJnNm", label: "Macicasu - Jud. CJ" },
  { id: "lOYy3r", label: "Macin - Jud. TL" },
  { id: "r3kxzr", label: "Maciova - Jud. CS" },
  { id: "l0Xaol", label: "Maciseni - Jud. GL" },
  { id: "Z4Nqjm", label: "Maciuceni - Jud. VL" },
  { id: "r3kxAr", label: "Macoviste - Jud. CS" },
  { id: "Z41gpl", label: "Macresti - Jud. VS" },
  { id: "rwpxKZ", label: "Macrina - Jud. BZ" },
  { id: "lL1vbm", label: "Mada - Jud. HD" },
  { id: "Zye9oZ", label: "Madaras - Jud. BH" },
  { id: "majybr", label: "Madaras - Jud. HR" },
  { id: "lD6wKr", label: "Madaras - Jud. MS" },
  { id: "robevm", label: "Madaras - Jud. SM" },
  { id: "Zv5vVr", label: "Madaraseni - Jud. MS" },
  { id: "r3Q7Gl", label: "Madarjac - Jud. IS" },
  { id: "r2QkQm", label: "Madarjesti - Jud. IS" },
  { id: "r9yB3r", label: "Madei - Jud. NT" },
  { id: "WmGM2Z", label: "Maderat - Jud. AR" },
  { id: "XlDeKm", label: "Madrigesti - Jud. AR" },
  { id: "rK6Bkm", label: "Madulari - Jud. VL" },
  { id: "ZR3YAl", label: "Maeriste - Jud. SJ" },
  { id: "ZPq3Dl", label: "Mag - Jud. SB" },
  { id: "l7vwAZ", label: "Magazia - Jud. BC" },
  { id: "Zv5VKr", label: "Magazia - Jud. NT" },
  { id: "rKjo0m", label: "Magesti - Jud. BH" },
  { id: "lOw83l", label: "Maghera - Jud. BT" },
  { id: "l07qJZ", label: "Magherani - Jud. MS" },
  { id: "lWOeAl", label: "Magheresti - Jud. GJ" },
  { id: "Zy6wkl", label: "Magheru - Jud. MH" },
  { id: "r6qy3m", label: "Magherus - Jud. CV" },
  { id: "lx7KMZ", label: "Magherus - Jud. HR" },
  { id: "m1GO3Z", label: "Magherus - Jud. MS" },
  { id: "nljbwl", label: "Maghierat - Jud. AB" },
  { id: "0lYOrQ", label: "Magina - Jud. AB" },
  { id: "rqPBvm", label: "Magiresti - Jud. BC" },
  { id: "r9qnyr", label: "Magla - Jud. BC" },
  { id: "Z4q0xl", label: "Maglavit - Jud. DJ" },
  { id: "m1ny3r", label: "Magoaja - Jud. CJ" },
  { id: "rKDMGZ", label: "Magula - Jud. PH" },
  { id: "olW8qm", label: "Magulicea - Jud. AR" },
  { id: "3r2MQm", label: "Magura - Jud. AB" },
  { id: "Zye5LZ", label: "Magura - Jud. BC" },
  { id: "rByM5m", label: "Magura - Jud. BH" },
  { id: "ZQQbDZ", label: "Magura - Jud. BV" },
  { id: "rd5dzZ", label: "Magura - Jud. BZ" },
  { id: "Zy2xKl", label: "Magura - Jud. CS" },
  { id: "Zy20kl", label: "Magura - Jud. CT" },
  { id: "ZVL8om", label: "Magura - Jud. DB" },
  { id: "r2QA9m", label: "Magura - Jud. HD" },
  { id: "ZyYG4Z", label: "Magura - Jud. OT" },
  { id: "ZR3qjl", label: "Magura - Jud. PH" },
  { id: "r86zXl", label: "Magura - Jud. SJ" },
  { id: "m1bqgr", label: "Magura - Jud. SV" },
  { id: "ZMJMjl", label: "Magura - Jud. TR" },
  { id: "rK6PGm", label: "Magura - Jud. VL" },
  { id: "l78jqm", label: "Magura - Jud. VN" },
  { id: "rEa4gr", label: "Magura Cu Liliac - Jud. TR" },
  { id: "mgAg9Z", label: "Magura Ierii - Jud. CJ" },
  { id: "rByaqm", label: "Magura Ilvei - Jud. BN" },
  { id: "l5QXRl", label: "Magura Toplita - Jud. HD" },
  { id: "lWBdRZ", label: "Magurele - Jud. BN" },
  { id: "r3QXGl", label: "Magurele - Jud. IF" },
  { id: "mbxyvZ", label: "Magurele - Jud. MH" },
  { id: "Zzzy3Z", label: "Magurele - Jud. PH" },
  { id: "l0KWgZ", label: "Magurele - Jud. TL" },
  { id: "mAbA6r", label: "Magureni - Jud. BR" },
  { id: "mGwk2m", label: "Magureni - Jud. CL" },
  { id: "lj56KZ", label: "Magureni - Jud. HD" },
  { id: "mbxoqZ", label: "Magureni - Jud. MM" },
  { id: "rX70nm", label: "Magureni - Jud. PH" },
  { id: "r65bXm", label: "Magureni - Jud. VL" },
  { id: "lJWVWm", label: "Maguri - Jud. CJ" },
  { id: "lxEBDm", label: "Maguri - Jud. TM" },
  { id: "ZPe0vl", label: "Maguri Racatau - Jud. CJ" },
  { id: "lOW1nl", label: "Maguricea - Jud. BZ" },
  { id: "Lmn1al", label: "Mahaceni - Jud. AB" },
  { id: "r6OL2Z", label: "Mahal - Jud. CJ" },
  { id: "ZyW1qm", label: "Mahmudia - Jud. TL" },
  { id: "rB5DJm", label: "Mahriu - Jud. VN" },
  { id: "rd5E4Z", label: "Maia - Jud. CJ" },
  { id: "mgLNGr", label: "Maia - Jud. IL" },
  { id: "rBj2Vr", label: "Maia - Jud. MS" },
  { id: "m1GwqZ", label: "Maiad - Jud. MS" },
  { id: "l0XyJl", label: "Maiag - Jud. GJ" },
  { id: "ZvOB2l", label: "Maicanesti - Jud. VN" },
  { id: "lWD6om", label: "Maidan - Jud. SV" },
  { id: "mG5bBZ", label: "Maieru - Jud. BN" },
  { id: "lWBGkZ", label: "Maierus - Jud. BV" },
  { id: "WmGGBm", label: "Mailat - Jud. AR" },
  { id: "rXqB9Z", label: "Maineasca - Jud. IF" },
  { id: "roE73l", label: "Maioresti - Jud. MS" },
  { id: "lx8Rpl", label: "Mal - Jud. CS" },
  { id: "Z49bpl", label: "Mal - Jud. SJ" },
  { id: "rwEXxl", label: "Maladia - Jud. SJ" },
  { id: "mkj2bl", label: "Malaesti - Jud. DJ" },
  { id: "ZQGJDm", label: "Malaesti - Jud. IS" },
  { id: "ZPanDl", label: "Malaesti - Jud. MS" },
  { id: "ZNwM7l", label: "Malaestii De Jos - Jud. PH" },
  { id: "r86R0l", label: "Malaestii De Sus - Jud. PH" },
  { id: "lO71Jl", label: "Malaia - Jud. VL" },
  { id: "ZQq3nZ", label: "Malaica - Jud. DJ" },
  { id: "r92QXZ", label: "Malaiesti - Jud. HD" },
  { id: "mgB6El", label: "Malaiesti - Jud. PH" },
  { id: "r6xL6l", label: "Malaiesti - Jud. VS" },
  { id: "lWDxEm", label: "Malamuc - Jud. PH" },
  { id: "mGW7wm", label: "Malancrav - Jud. SB" },
  { id: "rwPzvZ", label: "Malarisca - Jud. MH" },
  { id: "ZQyAdZ", label: "Malcoci - Jud. TL" },
  { id: "leJ8dm", label: "Maldaeni - Jud. TR" },
  { id: "ZzDKnm", label: "Maldaoci - Jud. MS" },
  { id: "r2bO1r", label: "Maldaresti - Jud. VL" },
  { id: "rwYyQl", label: "Maldarestii De Jos - Jud. VL" },
  { id: "l57L9r", label: "Malea - Jud. MS" },
  { id: "r2q3Am", label: "Maleni - Jud. SJ" },
  { id: "ZP2Y3m", label: "Malin - Jud. BN" },
  { id: "mgoB7m", label: "Malini - Jud. SV" },
  { id: "r82xql", label: "Malinis - Jud. BV" },
  { id: "rKxkWZ", label: "Maliuc - Jud. TL" },
  { id: "mao6NZ", label: "Malnas - Jud. CV" },
  { id: "r27w1Z", label: "Malnas Bai - Jud. CV" },
  { id: "ZRjjLZ", label: "Malosu - Jud. BC" },
  { id: "max9El", label: "Malovat - Jud. MH" },
  { id: "rozDnm", label: "Maltezi - Jud. IL" },
  { id: "9m16jr", label: "Malu - Jud. AG" },
  { id: "mboBKr", label: "Malu - Jud. GR" },
  { id: "lJxbpr", label: "Malu - Jud. IL" },
  { id: "lDKK2Z", label: "Malu - Jud. VL" },
  { id: "ZM74Qm", label: "Malu Alb - Jud. GL" },
  { id: "rXa9RZ", label: "Malu Alb - Jud. VL" },
  { id: "rwy0Kl", label: "Malu Cu Flori - Jud. DB" },
  { id: "lxb30r", label: "Malu Mare - Jud. DJ" },
  { id: "ZyNdYl", label: "Malu Mierii - Jud. DB" },
  { id: "ZyGKLm", label: "Malu Rosu - Jud. IL" },
  { id: "rqpP1m", label: "Malu Rosu - Jud. OT" },
  { id: "rKDBRZ", label: "Malu Rosu - Jud. PH" },
  { id: "l52eAm", label: "Malu Spart - Jud. GR" },
  { id: "3r2KQZ", label: "Malu Vanat - Jud. AG" },
  { id: "l0w4PZ", label: "Malu Vanat - Jud. PH" },
  { id: "Z414Jl", label: "Malu Vartop - Jud. VL" },
  { id: "maoRWZ", label: "Malumnic - Jud. DJ" },
  { id: "Wr8xDr", label: "Malureni - Jud. AG" },
  { id: "rKPEkZ", label: "Malureni - Jud. GL" },
  { id: "mndNNZ", label: "Maluri - Jud. VN" },
  { id: "rBORVZ", label: "Malurile - Jud. DB" },
  { id: "rEaJqr", label: "Malusteni - Jud. VS" },
  { id: "lOwyAl", label: "Malut - Jud. BN" },
  { id: "m1DnRl", label: "Mamaia - Jud. CT" },
  { id: "krwNVm", label: "Mamaligani - Jud. AB" },
  { id: "l78QYm", label: "Mamu - Jud. VL" },
  { id: "m1Bqqr", label: "Mamura - Jud. OT" },
  { id: "maNJDr", label: "Manailesti - Jud. VL" },
  { id: "Oro5lN", label: "Manarade - Jud. AB" },
  { id: "Zzozbl", label: "Manasia - Jud. BZ" },
  { id: "lYK3Xr", label: "Manasia - Jud. IL" },
  { id: "ZQq5oZ", label: "Manastioara - Jud. DB" },
  { id: "lWvOkZ", label: "Manastioara - Jud. SV" },
  { id: "lO7MAl", label: "Manastioara - Jud. VN" },
  { id: "krw7Vr", label: "Manastire - Jud. AB" },
  { id: "ZyWj0m", label: "Manastire - Jud. TM" },
  { id: "ZRJwqZ", label: "Manastirea - Jud. BZ" },
  { id: "mb97ql", label: "Manastirea - Jud. CJ" },
  { id: "rE7Lgl", label: "Manastirea - Jud. CL" },
  { id: "lj9bKZ", label: "Manastirea - Jud. DB" },
  { id: "m1qyjm", label: "Manastirea - Jud. IS" },
  { id: "ZMV95l", label: "Manastirea - Jud. MM" },
  { id: "mk1GOZ", label: "Manastirea - Jud. VS" },
  { id: "ZQQ9nZ", label: "Manastirea Casin - Jud. BC" },
  { id: "lDAYbm", label: "Manastirea Doamnei - Jud. BT" },
  { id: "lLJ7yr", label: "Manastirea Humorului - Jud. SV" },
  { id: "l57E3r", label: "Manastirea Neamt - Jud. NT" },
  { id: "lDD6Kl", label: "Manastirea Ratesti - Jud. BZ" },
  { id: "rwEwjl", label: "Manastirea Suzana - Jud. PH" },
  { id: "lDAnXm", label: "Manastireni - Jud. BT" },
  { id: "r6OXXZ", label: "Manastireni - Jud. CJ" },
  { id: "lJNgpl", label: "Manastiur - Jud. TM" },
  { id: "krwaKl", label: "Manastur - Jud. AR" },
  { id: "lpzk6r", label: "Manasturel - Jud. CJ" },
  { id: "ZRJRAZ", label: "Manasturu Romanesc - Jud. CJ" },
  { id: "ZzDoBm", label: "Manau - Jud. MM" },
  { id: "7rqOdl", label: "Mancesti - Jud. AB" },
  { id: "PrEgLl", label: "Mancioiu - Jud. AG" },
  { id: "qZNkLl", label: "Mandra - Jud. AG" },
  { id: "mk8kqr", label: "Mandra - Jud. BV" },
  { id: "mkzEzl", label: "Mandra - Jud. OT" },
  { id: "lOBOKZ", label: "Mandra - Jud. SB" },
  { id: "r82bel", label: "Mandresti - Jud. BT" },
  { id: "ZQq6JZ", label: "Mandresti - Jud. GL" },
  { id: "ZQwPgm", label: "Mandresti Moldova - Jud. VN" },
  { id: "r36eqm", label: "Mandresti Munteni - Jud. VN" },
  { id: "Jr3q4l", label: "Mandruloc - Jud. AR" },
  { id: "lL66Yr", label: "Maneasa - Jud. VL" },
  { id: "Z49A0l", label: "Maneciu - Jud. PH" },
  { id: "mGW99m", label: "Maneciu Pamanteni - Jud. PH" },
  { id: "lpAyxm", label: "Maneciu Ungureni - Jud. PH" },
  { id: "Vle1ol", label: "Manerau - Jud. AR" },
  { id: "r92OyZ", label: "Manerau - Jud. HD" },
  { id: "VrXEDZ", label: "Manesti - Jud. AG" },
  { id: "rBODqZ", label: "Manesti - Jud. DB" },
  { id: "rEReLZ", label: "Manesti - Jud. PH" },
  { id: "mgoj1m", label: "Maneuti - Jud. SV" },
  { id: "rBOvqZ", label: "Manga - Jud. DB" },
  { id: "mGwRnm", label: "Mangalia - Jud. CT" },
  { id: "mGgxnm", label: "Mangureni - Jud. VL" },
  { id: "lLe3bm", label: "Manic - Jud. BN" },
  { id: "r845Er", label: "Manicea - Jud. VL" },
  { id: "yZy1DZ", label: "Manicesti - Jud. AG" },
  { id: "r6693r", label: "Manjesti - Jud. IS" },
  { id: "ZQyJvZ", label: "Manjesti - Jud. VS" },
  { id: "rqbjQZ", label: "Manjina - Jud. DB" },
  { id: "rdxnzr", label: "Manoaia - Jud. NT" },
  { id: "lJx5gr", label: "Manolache - Jud. IF" },
  { id: "ZR6QvZ", label: "Manolea - Jud. SV" },
  { id: "lOwpwl", label: "Manoleasa - Jud. BT" },
  { id: "lLeOPm", label: "Manoleasa Prut - Jud. BT" },
  { id: "mk8nbr", label: "Manolesti - Jud. BT" },
  { id: "ZzVvbZ", label: "Mantu - Jud. VS" },
  { id: "Zv5j3r", label: "Manu - Jud. MH" },
  { id: "rd7VRm", label: "Manulesti - Jud. OT" },
  { id: "r3kJ5r", label: "Manzalesti - Jud. BZ" },
  { id: "lez3Om", label: "Manzatesti - Jud. IS" },
  { id: "mnpnAZ", label: "Manzatesti - Jud. VS" },
  { id: "r302GZ", label: "Manzati - Jud. VS" },
  { id: "r3k0nr", label: "Manzu - Jud. BZ" },
  { id: "rdxoar", label: "Mara - Jud. MM" },
  { id: "lJPqpr", label: "Maracinele - Jud. DJ" },
  { id: "Wr87Dm", label: "Maracineni - Jud. AG" },
  { id: "rodLwZ", label: "Maracineni - Jud. BZ" },
  { id: "ZNDxym", label: "Maracini - Jud. VN" },
  { id: "rK3a0r", label: "Maraloiu - Jud. BR" },
  { id: "rwnnxm", label: "Marascu - Jud. BC" },
  { id: "ZVyvor", label: "Maraseni - Jud. VS" },
  { id: "mkE5bZ", label: "Marasesti - Jud. MH" },
  { id: "lYnRQm", label: "Marasesti - Jud. MS" },
  { id: "lDKQjZ", label: "Marasesti - Jud. VN" },
  { id: "ZNJ11Z", label: "Marasesti - Jud. VS" },
  { id: "mk8xzr", label: "Marasti - Jud. BC" },
  { id: "r84WPr", label: "Marasti - Jud. VN" },
  { id: "r2Y7bm", label: "Marasu - Jud. BR" },
  { id: "BmaB2r", label: "Maraus - Jud. AR" },
  { id: "l0wEkZ", label: "Marca - Jud. SJ" },
  { id: "lpAXbm", label: "Marca Huta - Jud. SJ" },
  { id: "rE476m", label: "Marcea - Jud. VL" },
  { id: "LmnOAr", label: "Marcesti - Jud. BC" },
  { id: "rBWpbZ", label: "Marcesti - Jud. CJ" },
  { id: "mbon2r", label: "Marcesti - Jud. DB" },
  { id: "rq0e1m", label: "Marculeni - Jud. MS" },
  { id: "ZM9bGZ", label: "Marculesti - Jud. IL" },
  { id: "rBWkVZ", label: "Marculesti Gara - Jud. CL" },
  { id: "rEd6Vm", label: "Marcus - Jud. CV" },
  { id: "ZvgoPl", label: "Marcusa - Jud. CV" },
  { id: "Zy5zDZ", label: "Marcusu - Jud. VL" },
  { id: "mb5xBl", label: "Mardale - Jud. OT" },
  { id: "Wr6P2l", label: "Mares - Jud. AG" },
  { id: "Zy2vKl", label: "Marga - Jud. CS" },
  { id: "rK29Gm", label: "Marga - Jud. MH" },
  { id: "arB95m", label: "Margaia - Jud. AB" },
  { id: "maLnDl", label: "Margaritesti - Jud. BZ" },
  { id: "ZvE5Vr", label: "Margaritesti - Jud. OT" },
  { id: "ZQvVvl", label: "Margariti - Jud. BZ" },
  { id: "r97E3l", label: "Margau - Jud. CJ" },
  { id: "r3BXqm", label: "Margheni - Jud. OT" },
  { id: "VleAXl", label: "Marghia De Jos - Jud. AG" },
  { id: "wlpPPl", label: "Marghia De Sus - Jud. AG" },
  { id: "rByxDm", label: "Marghita - Jud. BH" },
  { id: "r2MRvm", label: "Margina - Jud. TM" },
  { id: "ma1Ajm", label: "Margine - Jud. BH" },
  { id: "mG510Z", label: "Marginea - Jud. BC" },
  { id: "r99Ddr", label: "Marginea - Jud. SV" },
  { id: "ma58Dm", label: "Marginea Padurii - Jud. PH" },
  { id: "ZRJ7AZ", label: "Margineanu - Jud. BZ" },
  { id: "WmGJdl", label: "Margineni - Jud. AB" },
  { id: "rKj6Qm", label: "Margineni - Jud. BC" },
  { id: "l51z3l", label: "Margineni - Jud. BV" },
  { id: "r2wgWZ", label: "Margineni - Jud. NT" },
  { id: "rK6VWm", label: "Margineni - Jud. VL" },
  { id: "mA1Y2m", label: "Margineni Slobozia - Jud. OT" },
  { id: "mgB3Dl", label: "Marginenii De Jos - Jud. PH" },
  { id: "r9njNZ", label: "Marginenii De Sus - Jud. DB" },
  { id: "Zy5zLZ", label: "Maricesti - Jud. VL" },
  { id: "lLaMWm", label: "Marila - Jud. CS" },
  { id: "lj4MKr", label: "Marin - Jud. SJ" },
  { id: "BmaN9r", label: "Marinesti - Jud. AB" },
  { id: "l520Nm", label: "Marinesti - Jud. GJ" },
  { id: "mgAwGZ", label: "Marisel - Jud. CJ" },
  { id: "rqPMQm", label: "Mariselu - Jud. BN" },
  { id: "ZyWWom", label: "Marita - Jud. TR" },
  { id: "ZQw9Dm", label: "Marita - Jud. VL" },
  { id: "ma5d9m", label: "Maritei - Jud. SV" },
  { id: "r9D6Om", label: "Mariteia Mica - Jud. SV" },
  { id: "mb51dl", label: "Marius - Jud. SM" },
  { id: "l71YLr", label: "Mariuta - Jud. CL" },
  { id: "lxKGnZ", label: "Marlogea - Jud. PH" },
  { id: "r9yROr", label: "Marmanu - Jud. MH" },
  { id: "mbx7YZ", label: "Marmureni - Jud. NT" },
  { id: "mnzWjm", label: "Marna Noua - Jud. SM" },
  { id: "mG1N9Z", label: "Marotinu De Jos - Jud. DJ" },
  { id: "rwyqjl", label: "Marotinu De Sus - Jud. DJ" },
  { id: "lYGXwr", label: "Marpod - Jud. SB" },
  { id: "lYxpAZ", label: "Marsa - Jud. GR" },
  { id: "m1BE9r", label: "Marsa - Jud. SB" },
  { id: "leoYgr", label: "Marsani - Jud. DJ" },
  { id: "rqEwvr", label: "Marsilieni - Jud. IL" },
  { id: "Zy2eol", label: "Martacesti - Jud. BR" },
  { id: "grKdPZ", label: "Martalogi - Jud. AG" },
  { id: "l0XNol", label: "Martanus - Jud. CV" },
  { id: "2l52pm", label: "Martesti - Jud. AB" },
  { id: "ElLwYZ", label: "Martesti - Jud. AG" },
  { id: "lYDMRZ", label: "Martihaz - Jud. BH" },
  { id: "lj95qZ", label: "Martineni - Jud. CV" },
  { id: "ZQvjal", label: "Martinesti - Jud. CJ" },
  { id: "l5QVNl", label: "Martinesti - Jud. HD" },
  { id: "r2qB1m", label: "Martinesti - Jud. SM" },
  { id: "lL6MAr", label: "Martinesti - Jud. VN" },
  { id: "XlxBQr", label: "Martinie - Jud. AB" },
  { id: "mA4n6l", label: "Martinis - Jud. HR" },
  { id: "r2YRWm", label: "Martinovat - Jud. CS" },
  { id: "ZvoJDl", label: "Martonca - Jud. HR" },
  { id: "rK3Qkr", label: "Maru - Jud. CS" },
  { id: "ZyGeDm", label: "Maru - Jud. GJ" },
  { id: "ljGpqm", label: "Maru Rosu - Jud. BR" },
  { id: "rK2BGm", label: "Maru Rosu - Jud. MH" },
  { id: "ma56Jm", label: "Maruntei - Jud. OT" },
  { id: "ZV36Kl", label: "Maruntis - Jud. PH" },
  { id: "rd5oRZ", label: "Maruntisu - Jud. BZ" },
  { id: "ZVLq6m", label: "Maruntisu - Jud. DB" },
  { id: "WmGVDl", label: "Marvila - Jud. BC" },
  { id: "ZyNvkl", label: "Marza - Jud. DJ" },
  { id: "lpVg6r", label: "Marzanesti - Jud. TR" },
  { id: "4lOgnl", label: "Masca - Jud. AR" },
  { id: "r975Xl", label: "Masca - Jud. CJ" },
  { id: "rop1qZ", label: "Mascateni - Jud. BT" },
  { id: "rEaGgr", label: "Mascurei - Jud. VS" },
  { id: "ZVygAr", label: "Masloc - Jud. TM" },
  { id: "lD67Wr", label: "Mastacan - Jud. NT" },
  { id: "rqb11Z", label: "Mastacani - Jud. GL" },
  { id: "yZybYl", label: "Matacina - Jud. AB" },
  { id: "Zy544Z", label: "Matacina - Jud. VN" },
  { id: "lDDwjl", label: "Mataraua - Jud. CL" },
  { id: "lD26nr", label: "Matasari - Jud. GJ" },
  { id: "rqbLQZ", label: "Matasaru - Jud. DB" },
  { id: "nlj7Lm", label: "Matau - Jud. AG" },
  { id: "rBOPVZ", label: "Matca - Jud. GL" },
  { id: "Zy5DKZ", label: "Mateesti - Jud. VL" },
  { id: "Rr9dqm", label: "Matei - Jud. AB" },
  { id: "rwnMKm", label: "Matei - Jud. BN" },
  { id: "l51w3l", label: "Mateias - Jud. BV" },
  { id: "ZyeBDZ", label: "Mateieni - Jud. BT" },
  { id: "ZyeYDZ", label: "Mateiesti - Jud. BC" },
  { id: "r8DR0r", label: "Matesti - Jud. BZ" },
  { id: "rBbx5r", label: "Matiseni - Jud. HR" },
  { id: "AZQydZ", label: "Matisesti - Jud. AB" },
  { id: "mAGRoZ", label: "Matita - Jud. PH" },
  { id: "m1n56r", label: "Matnicu Mare - Jud. CS" },
  { id: "l59aNl", label: "Matnicu Mic - Jud. TM" },
  { id: "lJPe3r", label: "Matraca - Jud. DB" },
  { id: "mga15Z", label: "Matrici - Jud. MS" },
  { id: "rK3Xkr", label: "Maureni - Jud. CS" },
  { id: "r27MgZ", label: "Mavrodin - Jud. DB" },
  { id: "rEaqqr", label: "Mavrodin - Jud. TR" },
  { id: "grKGWr", label: "Mavrodolu - Jud. AG" },
  { id: "r97dxl", label: "Maxenu - Jud. BZ" },
  { id: "lJWPwm", label: "Maxineni - Jud. BR" },
  { id: "rBbP5r", label: "Maxut - Jud. IS" },
  { id: "lDq3nm", label: "Mazanaesti - Jud. SV" },
  { id: "l78JYm", label: "Mazararu - Jud. VL" },
  { id: "l5Q20l", label: "Mazaroi - Jud. GJ" },
  { id: "ZVkkOl", label: "Mazili - Jud. VL" },
  { id: "l5OMYr", label: "Mecea - Jud. VL" },
  { id: "lOw7Kl", label: "Medeleni - Jud. BC" },
  { id: "lL1BYm", label: "Medeleni - Jud. IS" },
  { id: "rwp6QZ", label: "Medgidia - Jud. CT" },
  { id: "lDq7Ym", label: "Medias - Jud. SB" },
  { id: "lpA0om", label: "Medies Raturi - Jud. SM" },
  { id: "lWD8km", label: "Medies Vii - Jud. SM" },
  { id: "ma5VNm", label: "Mediesu Aurit - Jud. SM" },
  { id: "lpAdWm", label: "Medisa - Jud. SM" },
  { id: "mA4w2l", label: "Medisoru Mare - Jud. HR" },
  { id: "ZRw6vl", label: "Medisoru Mic - Jud. HR" },
  { id: "Jr3dqZ", label: "Medresti - Jud. AB" },
  { id: "arBnQr", label: "Medves - Jud. AB" },
  { id: "r2Yp1m", label: "Mehadia - Jud. CS" },
  { id: "ZV1XBr", label: "Mehadica - Jud. CS" },
  { id: "r6yoXm", label: "Mehedinta - Jud. PH" },
  { id: "r36BAm", label: "Meieni - Jud. VL" },
  { id: "rwyzal", label: "Meisoare - Jud. DB" },
  { id: "rBnMar", label: "Melicesti - Jud. PH" },
  { id: "rEdk5m", label: "Melinesti - Jud. DJ" },
  { id: "m1G8YZ", label: "Menti - Jud. MH" },
  { id: "lYxgxZ", label: "Mentii Din Dos - Jud. GJ" },
  { id: "lW1WRr", label: "Mera - Jud. CJ" },
  { id: "ljoeNm", label: "Mera - Jud. VN" },
  { id: "mbPMvZ", label: "Mercheasa - Jud. BV" },
  { id: "l71Onr", label: "Mercina - Jud. CS" },
  { id: "ZQa7nZ", label: "Merdeala - Jud. PH" },
  { id: "ZvAjKr", label: "Merei - Jud. BZ" },
  { id: "rEd75m", label: "Mereni - Jud. CT" },
  { id: "lYxnEZ", label: "Mereni - Jud. CV" },
  { id: "mboz2r", label: "Mereni - Jud. DB" },
  { id: "Z4D41Z", label: "Mereni - Jud. OT" },
  { id: "r6yB5m", label: "Mereni - Jud. SV" },
  { id: "mbKz2r", label: "Merenii De Jos - Jud. TR" },
  { id: "lLJxbr", label: "Merenii De Sus - Jud. TR" },
  { id: "mGa2wZ", label: "Meresesti - Jud. VL" },
  { id: "ZNAxyr", label: "Meresti - Jud. HR" },
  { id: "leJxPm", label: "Meresti - Jud. SV" },
  { id: "grKOxr", label: "Mereteu - Jud. AB" },
  { id: "leog3r", label: "Merfulesti - Jud. GJ" },
  { id: "mAGyoZ", label: "Merghindeal - Jud. SB" },
  { id: "r3yR0l", label: "Meri - Jud. PH" },
  { id: "r30RnZ", label: "Meri - Jud. TR" },
  { id: "lD2JJr", label: "Meria - Jud. HD" },
  { id: "ZQq85Z", label: "Merii - Jud. DB" },
  { id: "ZP7b3r", label: "Merii Petchii - Jud. IF" },
  { id: "ZNYa6r", label: "Meris - Jud. MH" },
  { id: "2l5z2m", label: "Merisani - Jud. AG" },
  { id: "ZPAGKl", label: "Merisani - Jud. TR" },
  { id: "r3GyVr", label: "Merisor - Jud. CV" },
  { id: "rozPXm", label: "Merisor - Jud. HD" },
  { id: "ZVdKJm", label: "Merisor - Jud. MM" },
  { id: "lJ2qgl", label: "Merisor - Jud. MS" },
  { id: "mnxjNZ", label: "Merisoru - Jud. DB" },
  { id: "roER3l", label: "Merisoru - Jud. MS" },
  { id: "ZyG44m", label: "Merisoru De Munte - Jud. HD" },
  { id: "NmkYBZ", label: "Mermesti - Jud. AR" },
  { id: "m1qoam", label: "Mermezeu Valeni - Jud. HD" },
  { id: "krwx3Z", label: "Mescreac - Jud. AB" },
  { id: "lp18Pm", label: "Mesendorf - Jud. BV" },
  { id: "3ZVydr", label: "Mesentea - Jud. AB" },
  { id: "rd7zam", label: "Mesesenii De Jos - Jud. SJ" },
  { id: "m1Bedr", label: "Mesesenii De Sus - Jud. SJ" },
  { id: "lJQEeZ", label: "Mesteacan - Jud. BT" },
  { id: "r6O8DZ", label: "Mesteacan - Jud. CS" },
  { id: "leoddr", label: "Mesteacan - Jud. DB" },
  { id: "ZyG98m", label: "Mesteacan - Jud. HD" },
  { id: "rdx7qr", label: "Mesteacan - Jud. MM" },
  { id: "r3BVzm", label: "Mesteacan - Jud. NT" },
  { id: "l0w5oZ", label: "Mesteacan - Jud. SM" },
  { id: "l58RAZ", label: "Mesteacanu - Jud. SJ" },
  { id: "mgJyBl", label: "Mesteacanu - Jud. VN" },
  { id: "mA9AKm", label: "Mestecanis - Jud. SV" },
  { id: "lYJKEm", label: "Mesteceni - Jud. SV" },
  { id: "ZQVkpl", label: "Mestera - Jud. MS" },
  { id: "l79xdr", label: "Mesteru - Jud. TL" },
  { id: "arBMdm", label: "Metes - Jud. AB" },
  { id: "ZyNODl", label: "Meteu - Jud. DJ" },
  { id: "rd7gqm", label: "Metis - Jud. SB" },
  { id: "WZPVEm", label: "Metofu - Jud. AG" },
  { id: "rdj0Gl", label: "Meziad - Jud. BH" },
  { id: "VleN3m", label: "Mica - Jud. AG" },
  { id: "ljN21l", label: "Mica - Jud. CJ" },
  { id: "rBj7Jr", label: "Mica - Jud. MS" },
  { id: "lj5WdZ", label: "Micanesti - Jud. HD" },
  { id: "ZNwoLl", label: "Micasasa - Jud. SB" },
  { id: "7rq0mP", label: "Micesti - Jud. AB" },
  { id: "Xl06Nr", label: "Micesti - Jud. AG" },
  { id: "r3k40r", label: "Micesti - Jud. CJ" },
  { id: "ZQQWpZ", label: "Micestii De Campie - Jud. BN" },
  { id: "r8YBEZ", label: "Micfalau - Jud. CV" },
  { id: "lezXXm", label: "Miclauseni - Jud. IS" },
  { id: "ZMJdjl", label: "Miclesti - Jud. VS" },
  { id: "Z4qp8l", label: "Miclosanii Mari - Jud. DB" },
  { id: "lxbDPr", label: "Miclosanii Mici - Jud. DB" },
  { id: "Z4qqxl", label: "Miclosoara - Jud. CV" },
  { id: "m1Dp9l", label: "Miclosu - Jud. GJ" },
  { id: "wmgPDr", label: "Micoslaca - Jud. AB" },
  { id: "lJ23pl", label: "Micsunesti - Jud. NT" },
  { id: "r66d9r", label: "Micsunestii Mari - Jud. IF" },
  { id: "r926aZ", label: "Micsunestii Moara - Jud. IF" },
  { id: "ZvE1Dr", label: "Micula - Jud. SM" },
  { id: "mGWGdm", label: "Micula Noua - Jud. SM" },
  { id: "rqbO1Z", label: "Miculesti - Jud. DB" },
  { id: "m1qD6m", label: "Miculesti - Jud. GJ" },
  { id: "lL11Wm", label: "Mielusei - Jud. GJ" },
  { id: "lOwPOl", label: "Mierag - Jud. BH" },
  { id: "3ZVABr", label: "Miercani - Jud. AG" },
  { id: "ZvoExl", label: "Miercurea Ciuc - Jud. HR" },
  { id: "lWRzkZ", label: "Miercurea Nirajului - Jud. MS" },
  { id: "mAGP4Z", label: "Miercurea Sibiului - Jud. SB" },
  { id: "lDDoWl", label: "Mierea - Jud. BZ" },
  { id: "ZRx4em", label: "Mierea - Jud. GJ" },
  { id: "lpx1xl", label: "Mierea - Jud. VL" },
  { id: "rdV6ql", label: "Miericeaua - Jud. GJ" },
  { id: "mAAvKm", label: "Mierlau - Jud. BH" },
  { id: "Z49wzl", label: "Mierlestii De Sus - Jud. OT" },
  { id: "mnzPam", label: "Mierlicesti - Jud. OT" },
  { id: "r9qA8r", label: "Miersig - Jud. BH" },
  { id: "rwEMol", label: "Mierta - Jud. SJ" },
  { id: "mGPVdr", label: "Miesti - Jud. OT" },
  { id: "ZyYoLZ", label: "Mighindoala - Jud. SB" },
  { id: "yZRdKm", label: "Mihaesti - Jud. AG" },
  { id: "ZvEz2r", label: "Mihaesti - Jud. OT" },
  { id: "ZVkqBl", label: "Mihaesti - Jud. VL" },
  { id: "Z4YX1l", label: "Mihai Bravu - Jud. BH" },
  { id: "mnJJam", label: "Mihai Bravu - Jud. BR" },
  { id: "lJPp3r", label: "Mihai Bravu - Jud. GR" },
  { id: "r2M8Wm", label: "Mihai Bravu - Jud. TL" },
  { id: "lp1P2m", label: "Mihai Eminescu - Jud. BT" },
  { id: "rEQ1Mr", label: "Mihai Viteazu - Jud. BT" },
  { id: "ZPevvl", label: "Mihai Viteazu - Jud. CJ" },
  { id: "lW1bNr", label: "Mihai Viteazu - Jud. CL" },
  { id: "leoLgr", label: "Mihai Viteazu - Jud. CT" },
  { id: "r6Gn6Z", label: "Mihai Viteazu - Jud. MS" },
  { id: "rXyR4Z", label: "Mihai Voda - Jud. GR" },
  { id: "rX7Jpm", label: "Mihaieni - Jud. SM" },
  { id: "yZyGLm", label: "Mihaiesti - Jud. AB" },
  { id: "ZzoaNl", label: "Mihaiesti - Jud. CJ" },
  { id: "lpbDbr", label: "Mihaiesti - Jud. HD" },
  { id: "ZVyKOr", label: "Mihaiesti - Jud. SV" },
  { id: "maLLNl", label: "Mihail Kogalniceanu - Jud. BR" },
  { id: "ljGXdm", label: "Mihail Kogalniceanu - Jud. BT" },
  { id: "ZM7R8m", label: "Mihail Kogalniceanu - Jud. CT" },
  { id: "r6q7pm", label: "Mihail Kogalniceanu - Jud. GL" },
  { id: "lL1bPm", label: "Mihail Kogalniceanu - Jud. IL" },
  { id: "lL1Rym", label: "Mihail Kogalniceanu - Jud. IS" },
  { id: "ZVynOr", label: "Mihail Kogalniceanu - Jud. TL" },
  { id: "ZQyWgZ", label: "Mihail Kogalniceanu - Jud. VS" },
  { id: "r2V6Qr", label: "Mihaileni - Jud. BT" },
  { id: "r8OnPm", label: "Mihaileni - Jud. HD" },
  { id: "r8OdDm", label: "Mihaileni - Jud. HR" },
  { id: "r867Vl", label: "Mihaileni - Jud. SB" },
  { id: "ljNj1l", label: "Mihailesti - Jud. BZ" },
  { id: "ZRxYqm", label: "Mihailesti - Jud. GR" },
  { id: "ZNY6Qr", label: "Mihailesti Popesti - Jud. OT" },
  { id: "ZRx1ym", label: "Mihaita - Jud. DJ" },
  { id: "ZVKpdl", label: "Mihalaseni - Jud. BT" },
  { id: "roYgxl", label: "Mihalceni - Jud. VN" },
  { id: "7rqvPZ", label: "Mihalt - Jud. AB" },
  { id: "mG5qWZ", label: "Miheleu - Jud. BH" },
  { id: "ZRKnel", label: "Mihesu De Campie - Jud. MS" },
  { id: "grKj9m", label: "Mihoesti - Jud. AB" },
  { id: "ZPABbl", label: "Mihoveni - Jud. SV" },
  { id: "mAOwgZ", label: "Mija - Jud. DB" },
  { id: "Zv5G3r", label: "Mijarca - Jud. MH" },
  { id: "m18n6l", label: "Mijati - Jud. VL" },
  { id: "lOwXGl", label: "Mijlocenii Bargaului - Jud. BN" },
  { id: "rB5R5m", label: "Mijlocu - Jud. VL" },
  { id: "lpVWPr", label: "Mila 23 - Jud. TL" },
  { id: "r33O4r", label: "Milas - Jud. BN" },
  { id: "r3BDGm", label: "Milasel - Jud. MS" },
  { id: "lJ6w4l", label: "Milcoiu - Jud. VL" },
  { id: "r6q99m", label: "Milcovatu - Jud. GR" },
  { id: "Zy5jYZ", label: "Milcovel - Jud. VN" },
  { id: "ZQvB4l", label: "Milcoveni - Jud. CS" },
  { id: "lexgPZ", label: "Milcoveni - Jud. OT" },
  { id: "lOBwOZ", label: "Milcovu Din Deal - Jud. OT" },
  { id: "ZyYeoZ", label: "Milcovu Din Vale - Jud. OT" },
  { id: "ZPGQBr", label: "Milcovul - Jud. VN" },
  { id: "lJQp9Z", label: "Mileanca - Jud. BT" },
  { id: "mgxV1r", label: "Milesti - Jud. DJ" },
  { id: "ZQwMam", label: "Milesti - Jud. VL" },
  { id: "r2VQer", label: "Milestii De Jos - Jud. BC" },
  { id: "ZVKwKl", label: "Milestii De Sus - Jud. BC" },
  { id: "r9qk3r", label: "Miletin - Jud. BT" },
  { id: "rob0nm", label: "Milisauti - Jud. SV" },
  { id: "maoA2Z", label: "Milosari - Jud. DB" },
  { id: "r3Gjnr", label: "Milosesti - Jud. GR" },
  { id: "rqEGGr", label: "Milosesti - Jud. IL" },
  { id: "Zy5WqZ", label: "Milostea - Jud. VL" },
  { id: "qZNjVZ", label: "Milova - Jud. AR" },
  { id: "lj9W4Z", label: "Milovan - Jud. DJ" },
  { id: "lpAnbm", label: "Miluani - Jud. SJ" },
  { id: "mAOQ4Z", label: "Miluta - Jud. GJ" },
  { id: "ZVyGOr", label: "Mina Altan Tepe - Jud. TL" },
  { id: "QmbgKr", label: "Minead - Jud. AR" },
  { id: "m1qbjm", label: "Minele Lueta - Jud. HR" },
  { id: "ZyWKqm", label: "Mineri - Jud. TL" },
  { id: "mb5VKl", label: "Mineu - Jud. SJ" },
  { id: "ZvEpwr", label: "Minieri - Jud. PH" },
  { id: "Jr3Nnm", label: "Minis - Jud. AR" },
  { id: "qZNOYl", label: "Minisel - Jud. AR" },
  { id: "Wr8y6Z", label: "Minisu De Sus - Jud. AR" },
  { id: "m1qg8m", label: "Mintia - Jud. HD" },
  { id: "ZNyVVr", label: "Mintiu - Jud. BN" },
  { id: "mb9Mal", label: "Mintiu Gherlii - Jud. CJ" },
  { id: "grKLGZ", label: "Mioarele - Jud. AG" },
  { id: "Z4YJql", label: "Miorcani - Jud. BT" },
  { id: "mAbQqr", label: "Miorita - Jud. CT" },
  { id: "NmkNQl", label: "Mioveni - Jud. AG" },
  { id: "Jr3aGl", label: "Miraslau - Jud. AB" },
  { id: "rdVg4l", label: "Mirau - Jud. GR" },
  { id: "mGxbnr", label: "Mircea Cel Batran - Jud. IL" },
  { id: "lYyxVl", label: "Mircea Voda - Jud. BR" },
  { id: "lpEEpm", label: "Mircea Voda - Jud. CT" },
  { id: "r6qo6m", label: "Mircea Voda - Jud. DB" },
  { id: "mgoW1m", label: "Mircea Voda - Jud. TL" },
  { id: "m1qpqm", label: "Mircesti - Jud. IS" },
  { id: "rKDneZ", label: "Mircesti - Jud. OT" },
  { id: "ZvznVZ", label: "Mircesti - Jud. VS" },
  { id: "rB54gm", label: "Mircestii Noi - Jud. VN" },
  { id: "rqx4om", label: "Mircestii Vechi - Jud. VN" },
  { id: "m1Dnjl", label: "Mireasa - Jud. CT" },
  { id: "l792nr", label: "Mireni - Jud. VS" },
  { id: "rEQDgr", label: "Mires - Jud. BN" },
  { id: "l785wm", label: "Miresti - Jud. VL" },
  { id: "m1GbYZ", label: "Miresu Mare - Jud. MM" },
  { id: "ZMGKzm", label: "Miresu Mare - Jud. PH" },
  { id: "leGn1r", label: "Miresu Mic - Jud. PH" },
  { id: "lD6XKr", label: "Mirigioaia - Jud. MS" },
  { id: "rwP83Z", label: "Mirila - Jud. OT" },
  { id: "mkjxnl", label: "Miristea - Jud. CT" },
  { id: "l0pYBl", label: "Miron Costin - Jud. BT" },
  { id: "m1GzjZ", label: "Miron Costin - Jud. NT" },
  { id: "lODnAm", label: "Mironeasa - Jud. IS" },
  { id: "lO68AZ", label: "Mironesti - Jud. GR" },
  { id: "l59QYl", label: "Mironu - Jud. SV" },
  { id: "krdD7r", label: "Mirosi - Jud. AG" },
  { id: "ZyGgYm", label: "Miroslava - Jud. IS" },
  { id: "l58A0Z", label: "Miroslavesti - Jud. PH" },
  { id: "r3Gb5r", label: "Mirosloveni - Jud. GJ" },
  { id: "rKnvxm", label: "Miroslovesti - Jud. IS" },
  { id: "robkqm", label: "Mirsid - Jud. SJ" },
  { id: "grKXRZ", label: "Misca - Jud. AR" },
  { id: "mk8gnr", label: "Misca - Jud. BH" },
  { id: "ZM7k8m", label: "Mischii - Jud. DJ" },
  { id: "lpbwpr", label: "Misentea - Jud. HR" },
  { id: "r33B4r", label: "Misihanesti - Jud. BC" },
  { id: "r8Y5MZ", label: "Mislea - Jud. DB" },
  { id: "l7yWYr", label: "Mislea - Jud. PH" },
  { id: "ZQGWnm", label: "Misleanu - Jud. IL" },
  { id: "rdoPGm", label: "Mitesti - Jud. IS" },
  { id: "r82APl", label: "Mititei - Jud. BN" },
  { id: "lYDpJZ", label: "Mitoc - Jud. BT" },
  { id: "rE2poZ", label: "Mitoc - Jud. IS" },
  { id: "ZVyeBr", label: "Mitoc - Jud. VS" },
  { id: "ZMJY8l", label: "Mitocasi - Jud. SV" },
  { id: "lj371m", label: "Mitocu Balan - Jud. NT" },
  { id: "Z41Yxl", label: "Mitocu Dragomirnei - Jud. SV" },
  { id: "maLYel", label: "Mitreni - Jud. CL" },
  { id: "lxAMnm", label: "Mitresti - Jud. MS" },
  { id: "ljo54m", label: "Mitrofani - Jud. VL" },
  { id: "mgAaqZ", label: "Mitropolia - Jud. BZ" },
  { id: "r6qp9m", label: "Miulesti - Jud. DB" },
  { id: "ZQQ5dZ", label: "Mizies - Jud. BH" },
  { id: "l7y8Ar", label: "Mizil - Jud. PH" },
  { id: "ZzPD3m", label: "Mlaceni - Jud. VL" },
  { id: "Zy2GYl", label: "Mlajet - Jud. BZ" },
  { id: "Z4qQjl", label: "Mlecanesti - Jud. DJ" },
  { id: "r2VKpr", label: "Mlenauti - Jud. BT" },
  { id: "ZvgzDl", label: "Moacsa - Jud. CV" },
  { id: "l0w0BZ", label: "Moara - Jud. PH" },
  { id: "mnpJxZ", label: "Moara Carp - Jud. SV" },
  { id: "mA4y6l", label: "Moara Ciornei - Jud. IS" },
  { id: "maxb7l", label: "Moara De Jos - Jud. MS" },
  { id: "rBWXJZ", label: "Moara De Padure - Jud. CJ" },
  { id: "Z4qkzl", label: "Moara Din Groapa - Jud. DB" },
  { id: "lOD9Gm", label: "Moara Domneasca - Jud. IF" },
  { id: "mb5XYl", label: "Moara Domneasca - Jud. PH" },
  { id: "lOYOGr", label: "Moara Domneasca - Jud. VS" },
  { id: "ZzVOYZ", label: "Moara Grecilor - Jud. VS" },
  { id: "lOw3Kl", label: "Moara Jorii - Jud. BT" },
  { id: "Rr9BOZ", label: "Moara Mocanului - Jud. AG" },
  { id: "leJagm", label: "Moara Nica - Jud. SV" },
  { id: "r9noyZ", label: "Moara Noua - Jud. DB" },
  { id: "lj4bBr", label: "Moara Noua - Jud. PH" },
  { id: "Zy6z8l", label: "Moara Vlasiei - Jud. IF" },
  { id: "robB5m", label: "Moardas - Jud. SB" },
  { id: "rBW2QZ", label: "Moceris - Jud. CS" },
  { id: "rKDKQZ", label: "Mocesti - Jud. PH" },
  { id: "lJ22el", label: "Mocira - Jud. MM" },
  { id: "lLajPm", label: "Mociu - Jud. CJ" },
  { id: "l51VRl", label: "Mocod - Jud. BN" },
  { id: "wlpaYm", label: "Mocrea - Jud. AR" },
  { id: "mkwMOm", label: "Modelu - Jud. CL" },
  { id: "m1b28r", label: "Modoia - Jud. VL" },
  { id: "olW3dl", label: "Modolesti - Jud. AB" },
  { id: "ZQv5al", label: "Modreni - Jud. BZ" },
  { id: "rwYxXl", label: "Modruzeni - Jud. VN" },
  { id: "mkj0Bl", label: "Mofleni - Jud. DJ" },
  { id: "rXyonZ", label: "Moflesti - Jud. DJ" },
  { id: "m1Bwgr", label: "Moftinu Mare - Jud. SM" },
  { id: "rwEa3l", label: "Moftinu Mic - Jud. SM" },
  { id: "r360em", label: "Mogesti - Jud. VL" },
  { id: "rE2RLZ", label: "Moglanesti - Jud. HR" },
  { id: "rwPBaZ", label: "Mogoaia - Jud. MS" },
  { id: "9m1xql", label: "Mogos - Jud. AB" },
  { id: "ZQqYpZ", label: "Mogosani - Jud. DB" },
  { id: "rBbOar", label: "Mogosani - Jud. GJ" },
  { id: "l0p1Ql", label: "Mogoseni - Jud. BN" },
  { id: "WmGp6r", label: "Mogosesti - Jud. AG" },
  { id: "mnxbAZ", label: "Mogosesti - Jud. DB" },
  { id: "mnxYBZ", label: "Mogosesti - Jud. DJ" },
  { id: "ZPBVKm", label: "Mogosesti - Jud. GR" },
  { id: "r2QDbm", label: "Mogosesti - Jud. IS" },
  { id: "rE66xZ", label: "Mogosesti - Jud. MM" },
  { id: "r8BQEl", label: "Mogosesti - Jud. OT" },
  { id: "r9b98Z", label: "Mogosesti - Jud. VL" },
  { id: "ZVwx6l", label: "Mogosesti Siret - Jud. IS" },
  { id: "ZRKQLl", label: "Mogosoaia - Jud. IF" },
  { id: "ZNwpLl", label: "Mohu - Jud. SB" },
  { id: "rXyMgZ", label: "Moi - Jud. GJ" },
  { id: "lJ9R3Z", label: "Moiad - Jud. SJ" },
  { id: "rwn6Vm", label: "Moieciu De Jos - Jud. BV" },
  { id: "r337er", label: "Moieciu De Sus - Jud. BV" },
  { id: "rKDEeZ", label: "Moigrad Porolissum - Jud. SJ" },
  { id: "ZyGaKm", label: "Moimesti - Jud. IS" },
  { id: "yZyRol", label: "Moinesti - Jud. BC" },
  { id: "lYn00m", label: "Moisa - Jud. MS" },
  { id: "lYGeXr", label: "Moisa - Jud. SV" },
  { id: "lOAYor", label: "Moisei - Jud. MM" },
  { id: "mkz2wl", label: "Moiseni - Jud. SM" },
  { id: "mkEKnZ", label: "Moisesti - Jud. MH" },
  { id: "ZQvNal", label: "Moisica - Jud. BZ" },
  { id: "r6G5XZ", label: "Molani - Jud. MH" },
  { id: "rodPnZ", label: "Moldova Noua - Jud. CS" },
  { id: "mGawWZ", label: "Moldova Sulita - Jud. SV" },
  { id: "ZvAx5r", label: "Moldova Veche - Jud. CS" },
  { id: "ZzobEl", label: "Moldovenesti - Jud. CJ" },
  { id: "lpbMxr", label: "Moldoveni - Jud. IL" },
  { id: "ZVdjOm", label: "Moldoveni - Jud. NT" },
  { id: "mA9Vam", label: "Moldoveni - Jud. TR" },
  { id: "ljNP4l", label: "Moldovita - Jud. CS" },
  { id: "ZzVzWZ", label: "Moldovita - Jud. SV" },
  { id: "ZR6w2Z", label: "Molid - Jud. SV" },
  { id: "rBykgm", label: "Moliset - Jud. BN" },
  { id: "mk5wbZ", label: "Mologesti - Jud. VL" },
  { id: "r2qQAm", label: "Momaiu - Jud. OT" },
  { id: "ZVKN6l", label: "Monariu - Jud. BN" },
  { id: "XlDwbZ", label: "Moneasa - Jud. AR" },
  { id: "ZV19Ar", label: "Moniom - Jud. CS" },
  { id: "m1k39Z", label: "Monor - Jud. BN" },
  { id: "nljLkr", label: "Monorostia - Jud. AR" },
  { id: "r8O5qm", label: "Morareni - Jud. HR" },
  { id: "maxKJl", label: "Morareni - Jud. MS" },
  { id: "rwVM3m", label: "Morareni - Jud. VS" },
  { id: "9m14gl", label: "Moraresti - Jud. AB" },
  { id: "OroKxr", label: "Moraresti - Jud. AG" },
  { id: "Zy5p4Z", label: "Moraresti - Jud. VN" },
  { id: "5l7LYm", label: "Morasti - Jud. AG" },
  { id: "rXLR3r", label: "Morau - Jud. CJ" },
  { id: "lYJkXm", label: "Moravita - Jud. TM" },
  { id: "wlpQpm", label: "Morcanesti - Jud. AB" },
  { id: "leoJOr", label: "Moreni - Jud. DB" },
  { id: "ZVL2Rm", label: "Moreni - Jud. DJ" },
  { id: "ZNANQr", label: "Moreni - Jud. IS" },
  { id: "lYnXRm", label: "Moreni - Jud. NT" },
  { id: "lJN4Yl", label: "Moreni - Jud. VS" },
  { id: "m1G5aZ", label: "Moresti - Jud. MS" },
  { id: "r6Ok6Z", label: "Moristi - Jud. CJ" },
  { id: "rXL8Rr", label: "Morlaca - Jud. CJ" },
  { id: "olWgyl", label: "Moroda - Jud. AR" },
  { id: "r3GW4r", label: "Moroeni - Jud. DB" },
  { id: "rXLy4r", label: "Morotesti - Jud. BR" },
  { id: "m1Dd9l", label: "Morteni - Jud. DB" },
  { id: "lx8YDl", label: "Mortesti - Jud. CJ" },
  { id: "rwEnol", label: "Morunesti - Jud. OT" },
  { id: "lj48Nr", label: "Morunglav - Jud. OT" },
  { id: "rXdX9r", label: "Morut - Jud. BN" },
  { id: "m1G5gZ", label: "Morut - Jud. MS" },
  { id: "mbopvr", label: "Moscu - Jud. GL" },
  { id: "rwpbyZ", label: "Mosesti - Jud. BZ" },
  { id: "ZRx5Lm", label: "Mosia Mica - Jud. DB" },
  { id: "ZM7zpm", label: "Mosna - Jud. DJ" },
  { id: "lJxawr", label: "Mosna - Jud. IS" },
  { id: "l58EpZ", label: "Mosna - Jud. SB" },
  { id: "ljNq4l", label: "Mosneni - Jud. CT" },
  { id: "rKPz9Z", label: "Mosneni - Jud. DJ" },
  { id: "rBjBar", label: "Mosneni - Jud. MH" },
  { id: "mA9Jqm", label: "Mosnita Noua - Jud. TM" },
  { id: "rBRebr", label: "Mosnita Veche - Jud. TM" },
  { id: "BmaENm", label: "Mosoaia - Jud. AG" },
  { id: "l794Ar", label: "Mosoroasa - Jud. VL" },
  { id: "mgLK9r", label: "Mosoru - Jud. HD" },
  { id: "mgxzqr", label: "Mosteni - Jud. GR" },
  { id: "ZzzgKZ", label: "Mosteni - Jud. OT" },
  { id: "lWvkyZ", label: "Mosteni - Jud. TR" },
  { id: "ZvOWKl", label: "Mosteni - Jud. VL" },
  { id: "XlDjWr", label: "Mosteni Greci - Jud. AG" },
  { id: "l7w0nl", label: "Mosuni - Jud. MS" },
  { id: "ZRxWAm", label: "Motaieni - Jud. DB" },
  { id: "lpEMpm", label: "Motatei - Jud. DJ" },
  { id: "lpE0em", label: "Motatei Gara - Jud. DJ" },
  { id: "lYKeVr", label: "Motca - Jud. IS" },
  { id: "rqPvBm", label: "Motesti - Jud. BH" },
  { id: "Jr3xnZ", label: "Motiori - Jud. AR" },
  { id: "mgBqGl", label: "Motis - Jud. SB" },
  { id: "l7yPqr", label: "Motis - Jud. SJ" },
  { id: "Zy5EoZ", label: "Motnau - Jud. VN" },
  { id: "Zv2oJZ", label: "Motoc - Jud. BC" },
  { id: "rXdV9r", label: "Motocesti - Jud. BC" },
  { id: "lxbapr", label: "Motoci - Jud. DJ" },
  { id: "lxA5zm", label: "Motoesti - Jud. OT" },
  { id: "3r2Vdr", label: "Motorasti - Jud. AB" },
  { id: "r9npXZ", label: "Motorgi - Jud. GJ" },
  { id: "r33JYr", label: "Motoseni - Jud. BC" },
  { id: "lWeLyr", label: "Motru - Jud. GJ" },
  { id: "ZP72vr", label: "Motru Sec - Jud. GJ" },
  { id: "lOA7or", label: "Motruleni - Jud. MH" },
  { id: "mgx7Br", label: "Movila - Jud. DB" },
  { id: "ZQGL4m", label: "Movila - Jud. IL" },
  { id: "ZPeDvl", label: "Movila Banului - Jud. BZ" },
  { id: "mAbOar", label: "Movila Miresii - Jud. BR" },
  { id: "m1nbdr", label: "Movila Oii - Jud. BZ" },
  { id: "Zv20xZ", label: "Movila Rupta - Jud. BT" },
  { id: "mAO68Z", label: "Movila Verde - Jud. CT" },
  { id: "leGB3r", label: "Movile - Jud. SB" },
  { id: "r92P3Z", label: "Movileanca - Jud. IL" },
  { id: "l7vkvZ", label: "Movileni - Jud. BT" },
  { id: "ZQqOJZ", label: "Movileni - Jud. GL" },
  { id: "mA4Yal", label: "Movileni - Jud. IS" },
  { id: "ZPqABl", label: "Movileni - Jud. OT" },
  { id: "mA942m", label: "Movileni - Jud. SV" },
  { id: "mGa6dZ", label: "Movileni - Jud. VS" },
  { id: "mG5PBZ", label: "Movilita - Jud. BC" },
  { id: "r2Yddm", label: "Movilita - Jud. BZ" },
  { id: "l7Q1wr", label: "Movilita - Jud. CT" },
  { id: "ZP74Ar", label: "Movilita - Jud. IL" },
  { id: "r65D6m", label: "Movilita - Jud. VN" },
  { id: "AZv8Dm", label: "Mozaceni - Jud. AG" },
  { id: "Wr8aVZ", label: "Mozacenii Vale - Jud. AG" },
  { id: "AZQJDl", label: "Mozacu - Jud. AG" },
  { id: "rox35Z", label: "Mrenesti - Jud. VL" },
  { id: "rwpExZ", label: "Mucesti Danulesti - Jud. BZ" },
  { id: "rK3j0r", label: "Muchea - Jud. BR" },
  { id: "lYP1EZ", label: "Muereasca - Jud. VL" },
  { id: "Z4N3jm", label: "Muereasca De Sus - Jud. VL" },
  { id: "lDPNWl", label: "Muereni - Jud. DJ" },
  { id: "l5Qd2l", label: "Mugeni - Jud. HR" },
  { id: "nljGdm", label: "Mugesti - Jud. AB" },
  { id: "ron5nm", label: "Muierusu - Jud. DJ" },
  { id: "ZyG52m", label: "Mujna - Jud. HR" },
  { id: "XlxLDl", label: "Munar - Jud. AR" },
  { id: "lxz1Dr", label: "Muncei - Jud. VN" },
  { id: "ljN7Nl", label: "Muncel - Jud. CJ" },
  { id: "ZMGbym", label: "Muncel - Jud. SJ" },
  { id: "Vle3rK", label: "Muncelu - Jud. AB" },
  { id: "r6b39r", label: "Muncelu - Jud. BC" },
  { id: "lJ27pl", label: "Muncelu - Jud. NT" },
  { id: "lYP20Z", label: "Muncelu - Jud. VN" },
  { id: "rK20Wm", label: "Muncelu De Jos - Jud. NT" },
  { id: "mgLQAr", label: "Muncelu De Sus - Jud. IS" },
  { id: "lODXKm", label: "Muncelu Mare - Jud. HD" },
  { id: "ZyGvDm", label: "Muncelu Mic - Jud. HD" },
  { id: "4lONJZ", label: "Munesti - Jud. AB" },
  { id: "NmkE2Z", label: "Muntari - Jud. AB" },
  { id: "rqzXgZ", label: "Muntele Baisorii - Jud. CJ" },
  { id: "ZQvE5l", label: "Muntele Bocului - Jud. CJ" },
  { id: "r3kznr", label: "Muntele Cacovei - Jud. CJ" },
  { id: "m1nedr", label: "Muntele Filii - Jud. CJ" },
  { id: "lYy6xl", label: "Muntele Rece - Jud. CJ" },
  { id: "lOWEGl", label: "Muntele Sacelului - Jud. CJ" },
  { id: "lJNVgl", label: "Muntenesti - Jud. VS" },
  { id: "rByJam", label: "Munteni - Jud. BH" },
  { id: "ZNGQyl", label: "Munteni - Jud. GL" },
  { id: "majk9r", label: "Munteni - Jud. IS" },
  { id: "mA1yKm", label: "Munteni - Jud. NT" },
  { id: "ZVkLAl", label: "Munteni - Jud. VL" },
  { id: "l7JVXZ", label: "Munteni Buzau - Jud. IL" },
  { id: "leJ3dm", label: "Muntenii De Jos - Jud. VS" },
  { id: "lOY2Ar", label: "Muntenii De Sus - Jud. VS" },
  { id: "QmbKQr", label: "Mununa - Jud. AB" },
  { id: "ZRKVDl", label: "Mura Mare - Jud. MS" },
  { id: "rdx14r", label: "Mura Mica - Jud. MS" },
  { id: "r6x8Xl", label: "Murani - Jud. TM" },
  { id: "l7wVAl", label: "Mureni - Jud. MS" },
  { id: "rBjM2r", label: "Mureseni - Jud. MS" },
  { id: "l0pA6l", label: "Muresenii Bargaului - Jud. BN" },
  { id: "lYy5Ol", label: "Muresenii De Campie - Jud. CJ" },
  { id: "ZQvb4l", label: "Murfatlar - Jud. CT" },
  { id: "lWebBr", label: "Murgasi - Jud. DJ" },
  { id: "lL1LWm", label: "Murgeanca - Jud. IL" },
  { id: "ZMJk8l", label: "Murgeni - Jud. VS" },
  { id: "r6O4XZ", label: "Murgesti - Jud. BZ" },
  { id: "mnxyNZ", label: "Murgesti - Jud. GJ" },
  { id: "mA1e6m", label: "Murgesti - Jud. MS" },
  { id: "lYKKXr", label: "Murgilesti - Jud. GJ" },
  { id: "mAA2Wm", label: "Murguta - Jud. BT" },
  { id: "lJNkzl", label: "Murighiol - Jud. TL" },
  { id: "rEdo6m", label: "Murta - Jud. DJ" },
  { id: "rXaQ7Z", label: "Musata - Jud. VS" },
  { id: "nljQbZ", label: "Musatesti - Jud. AG" },
  { id: "7rqBBZ", label: "Musca - Jud. AB" },
  { id: "NmkMbZ", label: "Muscel - Jud. AG" },
  { id: "ZNWnLl", label: "Muscel - Jud. BZ" },
  { id: "r27dAZ", label: "Muscel - Jud. DB" },
  { id: "rK3xEr", label: "Muscelu Caramanesti - Jud. BZ" },
  { id: "l71Xvr", label: "Muscelusa - Jud. BZ" },
  { id: "r9n3XZ", label: "Musculesti - Jud. GJ" },
  { id: "rKx3WZ", label: "Musenita - Jud. SV" },
  { id: "l7JwdZ", label: "Musetesti - Jud. GJ" },
  { id: "EZzaDr", label: "Mustatesti - Jud. AG" },
  { id: "arB2gr", label: "Mustesti - Jud. AR" },
  { id: "PrEMgr", label: "Nadab - Jud. AR" },
  { id: "ElL96m", label: "Nadalbesti - Jud. AR" },
  { id: "rBjJQr", label: "Nadanova - Jud. MH" },
  { id: "r9qeqr", label: "Nadar - Jud. BH" },
  { id: "2l539r", label: "Nadas - Jud. AR" },
  { id: "lj8J4r", label: "Nadas - Jud. TM" },
  { id: "lexeOZ", label: "Nadasa - Jud. MS" },
  { id: "Zy2k0l", label: "Nadaselu - Jud. CJ" },
  { id: "BmaLDl", label: "Nadastia - Jud. AB" },
  { id: "Z43oAr", label: "Nadastia De Jos - Jud. HD" },
  { id: "lx7BQZ", label: "Nadastia De Sus - Jud. HD" },
  { id: "lW1Jqr", label: "Nadasu - Jud. CJ" },
  { id: "lpbvpr", label: "Nadejdea - Jud. HR" },
  { id: "rdx4qr", label: "Nades - Jud. MS" },
  { id: "mGWn2m", label: "Nadis - Jud. SJ" },
  { id: "rqPO0m", label: "Nadisa - Jud. BC" },
  { id: "ma5GEm", label: "Nadisu Hododului - Jud. SM" },
  { id: "XlDM1m", label: "Nadlac - Jud. AR" },
  { id: "ZNJg6Z", label: "Nadrag - Jud. TM" },
  { id: "r97A3l", label: "Naeni - Jud. BZ" },
  { id: "lJWX4m", label: "Naidas - Jud. CS" },
  { id: "rKDLxZ", label: "Naimon - Jud. SJ" },
  { id: "mG1z2Z", label: "Naipu - Jud. GR" },
  { id: "lD2o1r", label: "Nalatvad - Jud. HD" },
  { id: "lYJwRm", label: "Nalbant - Jud. TL" },
  { id: "NmkOqm", label: "Namaesti - Jud. AG" },
  { id: "grKnQm", label: "Namas - Jud. AB" },
  { id: "mgxG9r", label: "Namete - Jud. GJ" },
  { id: "r3G2Vr", label: "Namoloasa - Jud. GL" },
  { id: "lDPzGl", label: "Namoloasa Sat - Jud. GL" },
  { id: "ZMRLyl", label: "Nana - Jud. CL" },
  { id: "m1GYaZ", label: "Nandra - Jud. MS" },
  { id: "mgLvBr", label: "Nandru - Jud. HD" },
  { id: "lJQxWZ", label: "Nanesti - Jud. BC" },
  { id: "mGPwBr", label: "Nanesti - Jud. MM" },
  { id: "r9bwyZ", label: "Nanesti - Jud. VN" },
  { id: "rqOaol", label: "Nanov - Jud. TR" },
  { id: "Zy2B4l", label: "Naoiu - Jud. CJ" },
  { id: "yZyEKZ", label: "Napaiesti - Jud. AB" },
  { id: "ma5g7m", label: "Napradea - Jud. SJ" },
  { id: "ron1Nm", label: "Nartesti - Jud. GL" },
  { id: "mgJeBl", label: "Naruja - Jud. VN" },
  { id: "lOWaKl", label: "Nasal - Jud. CJ" },
  { id: "mnPYAZ", label: "Nasaud - Jud. BN" },
  { id: "rqPkvm", label: "Nastase - Jud. BT" },
  { id: "lYDKxZ", label: "Nastaseni - Jud. BC" },
  { id: "mk1gPZ", label: "Nasturelu - Jud. TR" },
  { id: "ZzD9Dm", label: "Navargeni - Jud. OT" },
  { id: "ZNW66l", label: "Navodari - Jud. CT" },
  { id: "mnpBpZ", label: "Navodari - Jud. TR" },
  { id: "rXydnZ", label: "Nazarcea - Jud. CT" },
  { id: "Zye5DZ", label: "Nazarioaia - Jud. BC" },
  { id: "lLVk6r", label: "Nazna - Jud. MS" },
  { id: "EZzybr", label: "Neagra - Jud. AR" },
  { id: "lWRdRZ", label: "Neagra - Jud. MS" },
  { id: "lWRpYZ", label: "Neagra - Jud. NT" },
  { id: "rKDYQZ", label: "Neagra - Jud. SV" },
  { id: "l592Yl", label: "Neagra Sarului - Jud. SV" },
  { id: "lYxj0Z", label: "Neajlovu - Jud. DB" },
  { id: "mkwXBm", label: "Nearsova - Jud. CJ" },
  { id: "rqORdl", label: "Neatarnarea - Jud. TL" },
  { id: "roE15l", label: "Neaua - Jud. MS" },
  { id: "lxAY7m", label: "Nechit - Jud. NT" },
  { id: "r9DKqm", label: "Necopoi - Jud. SM" },
  { id: "nljoLm", label: "Necrilesti - Jud. AB" },
  { id: "7rqn1Z", label: "Necsesti - Jud. AB" },
  { id: "mnpgNZ", label: "Necsesti - Jud. TR" },
  { id: "ljoVNm", label: "Neculele - Jud. VN" },
  { id: "r6qQXm", label: "Nedeia - Jud. DJ" },
  { id: "ZV1L6r", label: "Nedeicu - Jud. BR" },
  { id: "lWDAqm", label: "Nedelea - Jud. PH" },
  { id: "wmg4Ar", label: "Negesti - Jud. AB" },
  { id: "2l5y0l", label: "Negesti - Jud. AG" },
  { id: "mgJokl", label: "Neghinesti - Jud. VL" },
  { id: "r97eOl", label: "Negiudin - Jud. CS" },
  { id: "lJWbwm", label: "Negoesti - Jud. CL" },
  { id: "mnEdBl", label: "Negoesti - Jud. MH" },
  { id: "mkjMwl", label: "Negoi - Jud. DJ" },
  { id: "rKjx9m", label: "Negoiesti - Jud. BC" },
  { id: "maoqjZ", label: "Negoiesti - Jud. DJ" },
  { id: "r2QYpm", label: "Negoiesti - Jud. GJ" },
  { id: "rEREBZ", label: "Negoiesti - Jud. PH" },
  { id: "l7J73Z", label: "Negoiu - Jud. HD" },
  { id: "mGxPpr", label: "Negomir - Jud. GJ" },
  { id: "l548RZ", label: "Negosina - Jud. BZ" },
  { id: "ZQab4Z", label: "Negostina - Jud. SV" },
  { id: "mk5EnZ", label: "Negraia - Jud. VL" },
  { id: "WZP0jZ", label: "Negrasi - Jud. AG" },
  { id: "ronjxm", label: "Negrea - Jud. GL" },
  { id: "ZVd3Wm", label: "Negreia - Jud. MM" },
  { id: "krwXQZ", label: "Negreni - Jud. AG" },
  { id: "l0p7kl", label: "Negreni - Jud. BC" },
  { id: "r828el", label: "Negreni - Jud. BT" },
  { id: "rqzQ0Z", label: "Negreni - Jud. CJ" },
  { id: "rBbybr", label: "Negreni - Jud. GJ" },
  { id: "l57wYr", label: "Negreni - Jud. OT" },
  { id: "ZV34ol", label: "Negreni - Jud. SJ" },
  { id: "ZvzdVZ", label: "Negreni - Jud. TR" },
  { id: "lJ6Ppl", label: "Negreni - Jud. VL" },
  { id: "mA1K6m", label: "Negrenii De Campie - Jud. MS" },
  { id: "lj8Dkr", label: "Negrenii De Sus - Jud. TR" },
  { id: "ZPAj3l", label: "Negrenii Osebiti - Jud. TR" },
  { id: "arBx5l", label: "Negresti - Jud. AB" },
  { id: "nlj01r", label: "Negresti - Jud. AG" },
  { id: "ZQQJnZ", label: "Negresti - Jud. BT" },
  { id: "ZRJ4jZ", label: "Negresti - Jud. CT" },
  { id: "mga41Z", label: "Negresti - Jud. MH" },
  { id: "maxDjl", label: "Negresti - Jud. NT" },
  { id: "l79Vwr", label: "Negresti - Jud. VS" },
  { id: "ma5ebm", label: "Negresti Oas - Jud. SM" },
  { id: "m1kXYZ", label: "Negri - Jud. BC" },
  { id: "lJNP4l", label: "Negrileasa - Jud. SV" },
  { id: "r2VyAr", label: "Negrilesti - Jud. BN" },
  { id: "mG1EdZ", label: "Negrilesti - Jud. GL" },
  { id: "leJPom", label: "Negrilesti - Jud. TR" },
  { id: "ZPGDKr", label: "Negrilesti - Jud. VN" },
  { id: "ZVdAdm", label: "Negritesti - Jud. NT" },
  { id: "r8DQer", label: "Negru Voda - Jud. CT" },
  { id: "ZyW0Dm", label: "Negrulesti - Jud. VL" },
  { id: "mGPDnr", label: "Negrusa - Jud. MH" },
  { id: "Wr6d9l", label: "Negulesti - Jud. BC" },
  { id: "rwPdvZ", label: "Negulesti - Jud. NT" },
  { id: "Zy2gkl", label: "Negureni - Jud. CT" },
  { id: "m1kG9Z", label: "Neguseni - Jud. BC" },
  { id: "rK3nxr", label: "Nehoiasu - Jud. BZ" },
  { id: "rqzEPZ", label: "Nehoiu - Jud. BZ" },
  { id: "rK690m", label: "Neicu - Jud. VN" },
  { id: "3r22Wr", label: "Nejlovelu - Jud. AG" },
  { id: "grKKkr", label: "Nelegesti - Jud. AB" },
  { id: "rodY3Z", label: "Nemertea - Jud. BZ" },
  { id: "lLJznr", label: "Nemesesti - Jud. TM" },
  { id: "NmkLor", label: "Nemesi - Jud. AB" },
  { id: "rwV8ym", label: "Nemoiu - Jud. VL" },
  { id: "r6yR2m", label: "Nemsa - Jud. SB" },
  { id: "l07LgZ", label: "Nemtisor - Jud. NT" },
  { id: "l71jXr", label: "Nenciu - Jud. BZ" },
  { id: "lYy1xl", label: "Nenciulesti - Jud. BZ" },
  { id: "mk1pOZ", label: "Nenciulesti - Jud. TR" },
  { id: "ZzPPYm", label: "Nenciulesti - Jud. VL" },
  { id: "rKnNQm", label: "Nenisori - Jud. IL" },
  { id: "rKjXxm", label: "Nepos - Jud. BN" },
  { id: "l5223m", label: "Neptun - Jud. CT" },
  { id: "r99w3r", label: "Nerau - Jud. TM" },
  { id: "mbNkKm", label: "Nereju - Jud. VN" },
  { id: "mbNd2m", label: "Nereju Mic - Jud. VN" },
  { id: "ZMRzzl", label: "Nermed - Jud. CS" },
  { id: "grKzem", label: "Nermis - Jud. AR" },
  { id: "mk8Nzr", label: "Neteni - Jud. BN" },
  { id: "l788wm", label: "Netesti - Jud. VL" },
  { id: "lex61Z", label: "Netezi - Jud. NT" },
  { id: "lpAY2m", label: "Netus - Jud. SB" },
  { id: "yZyx0r", label: "Neudorf - Jud. AR" },
  { id: "r9Do3m", label: "Nevesteasca - Jud. PH" },
  { id: "rqOjQl", label: "Nevrincea - Jud. TM" },
  { id: "rqO09l", label: "Nicani - Jud. SV" },
  { id: "ZP2Ekm", label: "Nichiteni - Jud. BT" },
  { id: "BZ4QQl", label: "Nicolae Balcescu - Jud. AR" },
  { id: "lOwKol", label: "Nicolae Balcescu - Jud. BC" },
  { id: "maLoel", label: "Nicolae Balcescu - Jud. BR" },
  { id: "r6bz2r", label: "Nicolae Balcescu - Jud. BT" },
  { id: "leL56Z", label: "Nicolae Balcescu - Jud. CL" },
  { id: "lWeeBr", label: "Nicolae Balcescu - Jud. CT" },
  { id: "lYnPwm", label: "Nicolae Balcescu - Jud. MH" },
  { id: "l79Ydr", label: "Nicolae Balcescu - Jud. TL" },
  { id: "mGaa2Z", label: "Nicolae Balcescu - Jud. TR" },
  { id: "r6yx6m", label: "Nicolae Titulescu - Jud. OT" },
  { id: "lO6vnZ", label: "Nicolaesti - Jud. DB" },
  { id: "r8OpEm", label: "Nicoleni - Jud. HR" },
  { id: "lDDD1l", label: "Nicolesti - Jud. BR" },
  { id: "rBWBqZ", label: "Nicolesti - Jud. BZ" },
  { id: "rXqj7Z", label: "Nicolesti - Jud. HR" },
  { id: "Z43yqr", label: "Nicolesti - Jud. IL" },
  { id: "rwP9oZ", label: "Nicolesti - Jud. MS" },
  { id: "rwYDvl", label: "Nicolesti - Jud. VL" },
  { id: "mGwdnm", label: "Nicolint - Jud. CS" },
  { id: "lDPvGl", label: "Nicopole - Jud. GL" },
  { id: "XlDBjr", label: "Nicoresti - Jud. AB" },
  { id: "mgPL9r", label: "Nicoresti - Jud. BC" },
  { id: "m1Dyal", label: "Nicoresti - Jud. GL" },
  { id: "mAAp8m", label: "Nicseni - Jud. BT" },
  { id: "r3kE5r", label: "Nicula - Jud. CJ" },
  { id: "rqP10m", label: "Niculcea - Jud. BT" },
  { id: "Jr30AZ", label: "Niculesti - Jud. AB" },
  { id: "rK35Qr", label: "Niculesti - Jud. BZ" },
  { id: "rXyKgZ", label: "Niculesti - Jud. DB" },
  { id: "mA9kKm", label: "Niculitel - Jud. TL" },
  { id: "rKxyPZ", label: "Nifon - Jud. TL" },
  { id: "ZNJAQZ", label: "Nigotesti - Jud. SV" },
  { id: "krwdVZ", label: "Nigrisoara - Jud. AG" },
  { id: "lW1jqr", label: "Nima - Jud. CJ" },
  { id: "m1G0qZ", label: "Nima Milaselului - Jud. MS" },
  { id: "lJ2A3l", label: "Nima Raciului - Jud. MS" },
  { id: "ZP2xMm", label: "Nimaiesti - Jud. BH" },
  { id: "lYDa0Z", label: "Nimigea De Jos - Jud. BN" },
  { id: "ZRjNDZ", label: "Nimigea De Sus - Jud. BN" },
  { id: "lLaYGm", label: "Nires - Jud. CJ" },
  { id: "mGw99m", label: "Niscov - Jud. BZ" },
  { id: "r3kXzr", label: "Nisipari - Jud. CT" },
  { id: "ZzzeDZ", label: "Nisipeni - Jud. SM" },
  { id: "rK6aQm", label: "Nisipi - Jud. VL" },
  { id: "lOYDJr", label: "Nisipitu - Jud. SV" },
  { id: "ZvEGKr", label: "Nisipoasa - Jud. PH" },
  { id: "lWRYAZ", label: "Nisiporesti - Jud. NT" },
  { id: "lWeoAr", label: "Nisipuri - Jud. DJ" },
  { id: "lYx7AZ", label: "Nisipurile - Jud. DB" },
  { id: "Z4q20l", label: "Nistoi - Jud. DJ" },
  { id: "r97byl", label: "Nistoresti - Jud. BZ" },
  { id: "ronpLm", label: "Nistoresti - Jud. CT" },
  { id: "lDP1Yl", label: "Nistoresti - Jud. GJ" },
  { id: "l7y83r", label: "Nistoresti - Jud. PH" },
  { id: "lL6G6r", label: "Nistoresti - Jud. VN" },
  { id: "Zv57Kr", label: "Nistria - Jud. NT" },
  { id: "lJ2Q3l", label: "Nistru - Jud. MM" },
  { id: "r89qeZ", label: "Nitchidorf - Jud. TM" },
  { id: "rqP71m", label: "Niuved - Jud. BH" },
  { id: "VrXoRm", label: "Noaptes - Jud. AG" },
  { id: "lJ2vzl", label: "Noaptesa - Jud. MH" },
  { id: "l0wLPZ", label: "Nocrich - Jud. SB" },
  { id: "lWDjEm", label: "Noistat - Jud. SB" },
  { id: "l0NdQr", label: "Nojag - Jud. HD" },
  { id: "lDA01m", label: "Nojorid - Jud. BH" },
  { id: "r9DpMm", label: "Norocu - Jud. SV" },
  { id: "rX757m", label: "Noroieni - Jud. SM" },
  { id: "4lOGAl", label: "Noslac - Jud. AB" },
  { id: "lL7n6Z", label: "Notig - Jud. SJ" },
  { id: "rKDp9Z", label: "Nou - Jud. SB" },
  { id: "rwE2yl", label: "Nou Sasesc - Jud. SB" },
  { id: "l7yNAr", label: "Noul Roman - Jud. SB" },
  { id: "Zvg4wl", label: "Novac - Jud. DJ" },
  { id: "XlD2Xr", label: "Novacesti - Jud. AB" },
  { id: "lxKDoZ", label: "Novacesti - Jud. PH" },
  { id: "rKP0EZ", label: "Novaci - Jud. GJ" },
  { id: "ronk8m", label: "Novaci - Jud. GR" },
  { id: "mG5DnZ", label: "Nucet - Jud. BH" },
  { id: "lO6VnZ", label: "Nucet - Jud. DB" },
  { id: "r3yd0l", label: "Nucet - Jud. PH" },
  { id: "lDqpWm", label: "Nucet - Jud. SB" },
  { id: "mb92dl", label: "Nucetu - Jud. CL" },
  { id: "ZNAyLr", label: "Nucetu - Jud. GJ" },
  { id: "lx8Mwl", label: "Nuci - Jud. CL" },
  { id: "rK2aEm", label: "Nuci - Jud. IF" },
  { id: "Wr6Xpl", label: "Nucsoara - Jud. AG" },
  { id: "mgL19r", label: "Nucsoara - Jud. HD" },
  { id: "r3ynYl", label: "Nucsoara De Jos - Jud. PH" },
  { id: "m1BJYr", label: "Nucsoara De Sus - Jud. PH" },
  { id: "ZvA5Vr", label: "Nucu - Jud. BZ" },
  { id: "ZNJkeZ", label: "Nufaru - Jud. TL" },
  { id: "ZvgWxl", label: "Nuntasi - Jud. CT" },
  { id: "lOwbnl", label: "Nuseni - Jud. BN" },
  { id: "ZvEkJr", label: "Nusfalau - Jud. SJ" },
  { id: "lj5owZ", label: "Nuteni - Jud. HR" },
  { id: "rE7Qol", label: "Oancea - Jud. BR" },
  { id: "lO63OZ", label: "Oancea - Jud. GL" },
  { id: "rBjqar", label: "Oantu - Jud. NT" },
  { id: "rERBoZ", label: "Oar - Jud. SM" },
  { id: "r9y0qr", label: "Oarba De Mures - Jud. MS" },
  { id: "AZQaZ6", label: "Oarda - Jud. AB" },
  { id: "Rr9Edr", label: "Oarja - Jud. AG" },
  { id: "Zy6WLl", label: "Oarta De Jos - Jud. MM" },
  { id: "lD66br", label: "Oarta De Sus - Jud. MM" },
  { id: "rqPDom", label: "Oarzina - Jud. BN" },
  { id: "mGwBBm", label: "Oas - Jud. CJ" },
  { id: "lDaoJm", label: "Obad - Jud. TM" },
  { id: "ZRwVAl", label: "Obarsa - Jud. HD" },
  { id: "r89oXZ", label: "Obarseni - Jud. VS" },
  { id: "l59nEl", label: "Obarsenii Lingurari - Jud. VS" },
  { id: "JlJo3m", label: "Obarsia - Jud. AR" },
  { id: "r2VV9r", label: "Obarsia - Jud. BC" },
  { id: "mgxXDr", label: "Obarsia - Jud. GJ" },
  { id: "r9D9ym", label: "Obarsia - Jud. OT" },
  { id: "lOY9Kr", label: "Obarsia - Jud. VL" },
  { id: "rBjD5r", label: "Obarsia Closani - Jud. MH" },
  { id: "Zv56Pr", label: "Obarsia De Camp - Jud. MH" },
  { id: "rKD3xZ", label: "Obarsia Noua - Jud. OT" },
  { id: "lYnqAm", label: "Obarsie - Jud. MS" },
  { id: "r6xV9l", label: "Obartu - Jud. TR" },
  { id: "lWvKYZ", label: "Obcina - Jud. SV" },
  { id: "ZM7yym", label: "Obedeni - Jud. GR" },
  { id: "ZNGj7l", label: "Obedin - Jud. DJ" },
  { id: "l5O1pr", label: "Obislavu - Jud. VL" },
  { id: "mgAy1Z", label: "Obita - Jud. CS" },
  { id: "mgBoBl", label: "Oboga - Jud. OT" },
  { id: "mGggWm", label: "Obogeni - Jud. VL" },
  { id: "ZRwYNl", label: "Oboroceni - Jud. IS" },
  { id: "Z43R1r", label: "Obranesti - Jud. HR" },
  { id: "wmgpkr", label: "Obreja - Jud. AB" },
  { id: "lYyWEl", label: "Obreja - Jud. CS" },
  { id: "l0NNBr", label: "Obreja - Jud. GJ" },
  { id: "mndjAZ", label: "Obrejita - Jud. VN" },
  { id: "rwDd3Z", label: "Obrijeni - Jud. IS" },
  { id: "lO77Nl", label: "Obrocesti - Jud. VL" },
  { id: "lDAAJm", label: "Ocheni - Jud. BC" },
  { id: "ZzP8Wm", label: "Ochesesti - Jud. VN" },
  { id: "ZQqP5Z", label: "Ochiuri - Jud. DB" },
  { id: "lj4nBr", label: "Ocina De Jos - Jud. PH" },
  { id: "ZPq9Dl", label: "Ocina De Sus - Jud. PH" },
  { id: "m1GLgZ", label: "Ociogi - Jud. OT" },
  { id: "rwDaKZ", label: "Ocisor - Jud. HD" },
  { id: "rBb0qr", label: "Ociu - Jud. HD" },
  { id: "l0N4Nr", label: "Ocland - Jud. HR" },
  { id: "ZNWOQl", label: "Ocna De Fier - Jud. CS" },
  { id: "rKnBkm", label: "Ocna De Jos - Jud. HR" },
  { id: "r2QO1m", label: "Ocna De Sus - Jud. HR" },
  { id: "Zy28Yl", label: "Ocna Dejului - Jud. CJ" },
  { id: "Wr6b2r", label: "Ocna Mures - Jud. AB" },
  { id: "l58Y9Z", label: "Ocna Sibiului - Jud. SB" },
  { id: "rK2xQm", label: "Ocna Sugatag - Jud. MM" },
  { id: "5l78dm", label: "Ocnisoara - Jud. AB" },
  { id: "rKjkEm", label: "Ocnita - Jud. BN" },
  { id: "ZyNb0l", label: "Ocnita - Jud. DB" },
  { id: "rwV6Km", label: "Ocnita - Jud. VL" },
  { id: "ElLJXr", label: "Ocoale - Jud. AB" },
  { id: "yZypYr", label: "Ocolis - Jud. AB" },
  { id: "lp5bMm", label: "Ocolis - Jud. MM" },
  { id: "mb9vql", label: "Ocolisel - Jud. CJ" },
  { id: "m1qxdm", label: "Ocolisu Mare - Jud. HD" },
  { id: "rdoeWm", label: "Ocolisu Mic - Jud. HD" },
  { id: "lJPzer", label: "Ocolna - Jud. DJ" },
  { id: "rKxvRZ", label: "Ocracu - Jud. VL" },
  { id: "qZNNQZ", label: "Odaeni - Jud. AG" },
  { id: "mgA4DZ", label: "Odaia Banului - Jud. BZ" },
  { id: "l59D2l", label: "Odaia Bogdana - Jud. VS" },
  { id: "rwVGXm", label: "Odaia Bursucani - Jud. VS" },
  { id: "lO6jOZ", label: "Odaia Manolache - Jud. GL" },
  { id: "mnxopZ", label: "Odaia Turcului - Jud. DB" },
  { id: "mnJKjm", label: "Odaieni - Jud. BR" },
  { id: "mgAdGZ", label: "Odaile - Jud. BZ" },
  { id: "rdxbWr", label: "Odaile - Jud. IF" },
  { id: "ZR3Wjl", label: "Odaile - Jud. PH" },
  { id: "Zy628l", label: "Odesti - Jud. MM" },
  { id: "rqxLgm", label: "Odobasca - Jud. VN" },
  { id: "rXax4Z", label: "Odobeasca - Jud. TR" },
  { id: "ZMY7jZ", label: "Odobesti - Jud. BC" },
  { id: "rKPORZ", label: "Odobesti - Jud. DB" },
  { id: "rqx31m", label: "Odobesti - Jud. VN" },
  { id: "Zvge5l", label: "Odoleni - Jud. DJ" },
  { id: "lj4dbr", label: "Odoreu - Jud. SM" },
  { id: "mkaznZ", label: "Odorheiu Secuiesc - Jud. HR" },
  { id: "mbxg2Z", label: "Odrihei - Jud. MS" },
  { id: "WmGgpm", label: "Odverem - Jud. AB" },
  { id: "Wr8kPm", label: "Odvos - Jud. AR" },
  { id: "LZM4zr", label: "Oestii Pamanteni - Jud. AG" },
  { id: "Vle41Z", label: "Oestii Ungureni - Jud. AG" },
  { id: "ZR6GAZ", label: "Ofsenita - Jud. TM" },
  { id: "ZV1gOr", label: "Ogasu Podului - Jud. CS" },
  { id: "r33xqr", label: "Ogesti - Jud. BH" },
  { id: "r9yaOr", label: "Oglinzi - Jud. NT" },
  { id: "ZRJaKZ", label: "Ogoru - Jud. CL" },
  { id: "max4Wl", label: "Ogra - Jud. MS" },
  { id: "rwDMjZ", label: "Ograda - Jud. IL" },
  { id: "mAbx4r", label: "Ograzile - Jud. BZ" },
  { id: "lpAQ2m", label: "Ogretin - Jud. PH" },
  { id: "Jr3jem", label: "Ogrezea - Jud. AG" },
  { id: "mAOpBZ", label: "Ogrezeni - Jud. GR" },
  { id: "grKMxl", label: "Ohaba - Jud. AB" },
  { id: "lxx90l", label: "Ohaba - Jud. BV" },
  { id: "lj9J4Z", label: "Ohaba - Jud. DJ" },
  { id: "ZNG6Yl", label: "Ohaba - Jud. GJ" },
  { id: "Zzw1Bl", label: "Ohaba - Jud. HD" },
  { id: "mA1vKm", label: "Ohaba - Jud. MH" },
  { id: "mbOwqr", label: "Ohaba De Sub Piatra - Jud. HD" },
  { id: "l0K0kZ", label: "Ohaba Forgaci - Jud. TM" },
  { id: "lO6nnZ", label: "Ohaba Jiu - Jud. GJ" },
  { id: "l59G0l", label: "Ohaba Lunga - Jud. TM" },
  { id: "l710dr", label: "Ohaba Matnic - Jud. CS" },
  { id: "ZM9ojZ", label: "Ohaba Ponor - Jud. HD" },
  { id: "rwV3jm", label: "Ohaba Romana - Jud. TM" },
  { id: "rwD9KZ", label: "Ohaba Sibisel - Jud. HD" },
  { id: "rE2NoZ", label: "Ohaba Streiului - Jud. HD" },
  { id: "r8D1qr", label: "Ohabita - Jud. CS" },
  { id: "Lmnjam", label: "Oidesti - Jud. AB" },
  { id: "JlJN9l", label: "Oiejdea - Jud. AB" },
  { id: "ZNGp1l", label: "Oinacu - Jud. GR" },
  { id: "ZyewLZ", label: "Oituz - Jud. BC" },
  { id: "ZM7O4m", label: "Oituz - Jud. CT" },
  { id: "ZRxw2m", label: "Oituz - Jud. CV" },
  { id: "leL83Z", label: "Ojasca - Jud. BZ" },
  { id: "lj98bZ", label: "Ojdula - Jud. CV" },
  { id: "mGw56m", label: "Olaneasca - Jud. BR" },
  { id: "mGaKBZ", label: "Olanesti - Jud. VL" },
  { id: "mA8x2l", label: "Olanu - Jud. VL" },
  { id: "mndRpZ", label: "Olareni - Jud. VN" },
  { id: "5l7Yvr", label: "Olari - Jud. AR" },
  { id: "mAbGgr", label: "Olari - Jud. BZ" },
  { id: "l7J1XZ", label: "Olari - Jud. GJ" },
  { id: "lL7abZ", label: "Olari - Jud. OT" },
  { id: "ZyYqLZ", label: "Olari - Jud. PH" },
  { id: "r2qopm", label: "Olarii Vechi - Jud. PH" },
  { id: "rwpkKZ", label: "Olariu - Jud. CJ" },
  { id: "l7v3qZ", label: "Olcea - Jud. BH" },
  { id: "l54v9Z", label: "Olesesti - Jud. BZ" },
  { id: "rwY4xl", label: "Olesesti - Jud. VN" },
  { id: "ZVLLOm", label: "Olimp - Jud. CT" },
  { id: "mkzLbl", label: "Ologeni - Jud. PH" },
  { id: "lj8e1r", label: "Olosag - Jud. TM" },
  { id: "lOw13l", label: "Olosig - Jud. BH" },
  { id: "lp5gPm", label: "Olteanca - Jud. MH" },
  { id: "l7965r", label: "Olteanca - Jud. TR" },
  { id: "mGgwnm", label: "Olteanca - Jud. VL" },
  { id: "ZRwQel", label: "Olteanu - Jud. GJ" },
  { id: "lpV86r", label: "Oltenesti - Jud. VS" },
  { id: "3ZV8Bm", label: "Olteni - Jud. AB" },
  { id: "ZNGRal", label: "Olteni - Jud. CT" },
  { id: "rBOb2Z", label: "Olteni - Jud. CV" },
  { id: "r3Go5r", label: "Olteni - Jud. DB" },
  { id: "mbOeBr", label: "Olteni - Jud. IF" },
  { id: "lWDyom", label: "Olteni - Jud. PH" },
  { id: "lDaBJm", label: "Olteni - Jud. TR" },
  { id: "r99pxr", label: "Olteni - Jud. VL" },
  { id: "rK6zEm", label: "Olteni - Jud. VN" },
  { id: "lpzdor", label: "Oltenita - Jud. CL" },
  { id: "Zye0KZ", label: "Oltet - Jud. BV" },
  { id: "mndJBZ", label: "Oltetani - Jud. VL" },
  { id: "l0kMoZ", label: "Oltetu - Jud. VL" },
  { id: "mgAM5Z", label: "Oltina - Jud. CL" },
  { id: "mkjjwl", label: "Oltina - Jud. CT" },
  { id: "lDqbjm", label: "Oltisoru - Jud. OT" },
  { id: "7rqNBZ", label: "Oncesti - Jud. AB" },
  { id: "rKjVQm", label: "Oncesti - Jud. BC" },
  { id: "ZQq7pZ", label: "Oncesti - Jud. DB" },
  { id: "l52nRm", label: "Oncesti - Jud. GR" },
  { id: "r3BB0m", label: "Oncesti - Jud. MM" },
  { id: "r33G5r", label: "Oncestii Vechi - Jud. BC" },
  { id: "rqbGBZ", label: "Onciu - Jud. GL" },
  { id: "mbPEEZ", label: "Oneaga - Jud. BT" },
  { id: "XlD11m", label: "Onesti - Jud. BC" },
  { id: "mnQVjl", label: "Onesti - Jud. IS" },
  { id: "lJ2Jzl", label: "Oniceni - Jud. NT" },
  { id: "rdKb2m", label: "Oniceni - Jud. SV" },
  { id: "lOweGl", label: "Oniscani - Jud. BC" },
  { id: "lexQ6Z", label: "Onuca - Jud. MS" },
  { id: "rK6jQm", label: "Opatesti - Jud. VL" },
  { id: "rwVwam", label: "Opatita - Jud. TM" },
  { id: "mb5KKl", label: "Oporelu - Jud. OT" },
  { id: "mga31Z", label: "Opranesti - Jud. MH" },
  { id: "9m1aRm", label: "Opresti - Jud. AG" },
  { id: "l0pyol", label: "Oprisenesti - Jud. BR" },
  { id: "lx7jQZ", label: "Opriseni - Jud. IS" },
  { id: "rwnPKm", label: "Oprisesti - Jud. BC" },
  { id: "ZzwjKl", label: "Oprisesti - Jud. HD" },
  { id: "rBR6dr", label: "Oprisita - Jud. VS" },
  { id: "lj36qm", label: "Oprisor - Jud. MH" },
  { id: "ZNAoyr", label: "Oproaia - Jud. IS" },
  { id: "lpAEYm", label: "Optasani - Jud. OT" },
  { id: "r86DMl", label: "Optasi - Jud. OT" },
  { id: "rwnNjm", label: "Oradea - Jud. BH" },
  { id: "r9q7yr", label: "Orasa - Jud. BC" },
  { id: "ZzwBYl", label: "Oraseni - Jud. HR" },
  { id: "l7vGvZ", label: "Oraseni Deal - Jud. BT" },
  { id: "mG58wZ", label: "Oraseni Vale - Jud. BT" },
  { id: "yZydqr", label: "Orasti - Jud. AB" },
  { id: "leLNOZ", label: "Orasti - Jud. CL" },
  { id: "rozOxm", label: "Orastie - Jud. HD" },
  { id: "ZP7JKr", label: "Orastioara De Jos - Jud. HD" },
  { id: "l0N1Jr", label: "Orastioara De Sus - Jud. HD" },
  { id: "r9DPdm", label: "Orasu Nou - Jud. SM" },
  { id: "lYG0Er", label: "Orasu Nou Vii - Jud. SM" },
  { id: "Z4wpQr", label: "Oratia - Jud. BZ" },
  { id: "ZPewAl", label: "Oravita - Jud. CS" },
  { id: "leGvgr", label: "Orbau - Jud. SM" },
  { id: "mGaj2Z", label: "Orbeasca De Jos - Jud. TR" },
  { id: "rBRxdr", label: "Orbeasca De Sus - Jud. TR" },
  { id: "r2VLpr", label: "Orbeni - Jud. BC" },
  { id: "m1qMYm", label: "Orboesti - Jud. IL" },
  { id: "lD6bJr", label: "Ordoreanu - Jud. IF" },
  { id: "mgxYqr", label: "Oreasca - Jud. DB" },
  { id: "roYO8l", label: "Oreavu - Jud. VN" },
  { id: "rwpwyZ", label: "Oreavul - Jud. BZ" },
  { id: "mA18om", label: "Orevita Mare - Jud. MH" },
  { id: "ZM9kGZ", label: "Orezu - Jud. IL" },
  { id: "Xl0XPl", label: "Orgesti - Jud. AB" },
  { id: "lOYLJr", label: "Orgoiesti - Jud. VS" },
  { id: "ma1qem", label: "Orheiu Bistritei - Jud. BN" },
  { id: "ZR3Pyl", label: "Orlat - Jud. SB" },
  { id: "lL7J6Z", label: "Orlea - Jud. OT" },
  { id: "l0w80Z", label: "Orlea Noua - Jud. OT" },
  { id: "ZND3Qm", label: "Orlesti - Jud. VL" },
  { id: "leL6YZ", label: "Orman - Jud. CJ" },
  { id: "BZ4kxr", label: "Ormenis - Jud. AB" },
  { id: "mnPqjZ", label: "Ormenis - Jud. BV" },
  { id: "r3BO4m", label: "Ormenis - Jud. MS" },
  { id: "r2QWbm", label: "Ormindea - Jud. HD" },
  { id: "Orowvr", label: "Orodel - Jud. AG" },
  { id: "mnxMxZ", label: "Orodel - Jud. DJ" },
  { id: "r33Vzr", label: "Oroftiana - Jud. BT" },
  { id: "ZNYByr", label: "Oroiu - Jud. MS" },
  { id: "m1kOdZ", label: "Orosfaia - Jud. BN" },
  { id: "mA1Jam", label: "Orosia - Jud. MS" },
  { id: "rE646Z", label: "Orsova - Jud. MH" },
  { id: "rE6XQZ", label: "Orsova - Jud. MS" },
  { id: "ZMVXRl", label: "Orsova Padure - Jud. MS" },
  { id: "rq0kvm", label: "Ortasti - Jud. NT" },
  { id: "l0KGBZ", label: "Ortisoara - Jud. TM" },
  { id: "l7wwvl", label: "Ortita - Jud. MM" },
  { id: "rdjXAl", label: "Ortiteag - Jud. BH" },
  { id: "leGgXr", label: "Ortoaia - Jud. SV" },
  { id: "Z4YRjl", label: "Orvisele - Jud. BH" },
  { id: "Zvok3l", label: "Orzeni - Jud. IS" },
  { id: "r66bXr", label: "Orzesti - Jud. GJ" },
  { id: "lJ9KWZ", label: "Orzoaia De Jos - Jud. PH" },
  { id: "lYGvxr", label: "Orzoaia De Sus - Jud. PH" },
  { id: "r8O2Vm", label: "Orzu - Jud. GJ" },
  { id: "mgPdkr", label: "Osand - Jud. BH" },
  { id: "rXdL3r", label: "Osebiti - Jud. BC" },
  { id: "lWvLyZ", label: "Osesti - Jud. VS" },
  { id: "rd75Rm", label: "Osica De Jos - Jud. OT" },
  { id: "ZzzVBZ", label: "Osica De Sus - Jud. OT" },
  { id: "ZRK9yl", label: "Oslobeni - Jud. NT" },
  { id: "mnxKGZ", label: "Osmancea - Jud. CT" },
  { id: "lDDpbl", label: "Osoi - Jud. CJ" },
  { id: "lODOOm", label: "Osoi - Jud. IS" },
  { id: "lpV5or", label: "Osoi - Jud. SV" },
  { id: "mG5o2Z", label: "Osorhei - Jud. BH" },
  { id: "rodXXZ", label: "Osorhel - Jud. CJ" },
  { id: "rXa7eZ", label: "Ostra - Jud. SV" },
  { id: "Z4348r", label: "Ostratu - Jud. IF" },
  { id: "Xlx1Pm", label: "Ostrov - Jud. AR" },
  { id: "mnxxxZ", label: "Ostrov - Jud. CT" },
  { id: "rBb8qr", label: "Ostrov - Jud. HD" },
  { id: "ZPqeKl", label: "Ostrov - Jud. OT" },
  { id: "r89aqZ", label: "Ostrov - Jud. TL" },
  { id: "rqE9Qr", label: "Ostrovel - Jud. HD" },
  { id: "lDPj2l", label: "Ostroveni - Jud. DJ" },
  { id: "rXxdLr", label: "Ostroveni - Jud. VL" },
  { id: "lLabbm", label: "Ostrovu - Jud. CL" },
  { id: "ZzzBnZ", label: "Ostrovu - Jud. PH" },
  { id: "rdxB2r", label: "Ostrovu Corbului - Jud. MH" },
  { id: "rq03dm", label: "Ostrovu Mare - Jud. MH" },
  { id: "ZQGepm", label: "Ostrovu Mic - Jud. HD" },
  { id: "leJNgm", label: "Ostrovu Tataru - Jud. TL" },
  { id: "lOYPor", label: "Otelec - Jud. TM" },
  { id: "ZNA6xr", label: "Oteleni - Jud. IS" },
  { id: "m1b6ar", label: "Oteleni - Jud. VS" },
  { id: "ZVKKJl", label: "Otelesti - Jud. BC" },
  { id: "wmgNGl", label: "Otelu - Jud. AG" },
  { id: "rXLKDr", label: "Otelu Rosu - Jud. CS" },
  { id: "lJx6zr", label: "Oteni - Jud. HR" },
  { id: "r84KEr", label: "Otesani - Jud. VL" },
  { id: "rwPo3Z", label: "Otestii De Jos - Jud. OT" },
  { id: "rBjw2r", label: "Otestii De Sus - Jud. OT" },
  { id: "rKxYRZ", label: "Otetelisu - Jud. VL" },
  { id: "rEaggr", label: "Otetoaia - Jud. VS" },
  { id: "lWBzkZ", label: "Otomani - Jud. BH" },
  { id: "rwD8xZ", label: "Otopeni - Jud. IF" },
  { id: "lWvVAZ", label: "Otvesti - Jud. TM" },
  { id: "mbNoYm", label: "Oveselu - Jud. VL" },
  { id: "ZRJojZ", label: "Ovidiu - Jud. CT" },
  { id: "lOAqOr", label: "Ozd - Jud. MS" },
  { id: "ZPBAjm", label: "Ozun - Jud. CV" },
  { id: "ZyNGql", label: "Ozunca Bai - Jud. CV" },
  { id: "mbPw2Z", label: "Pacalesti - Jud. BH" },
  { id: "ZVLwBm", label: "Pachia - Jud. CV" },
  { id: "4lO43m", label: "Pacioiu - Jud. AG" },
  { id: "l71w5r", label: "Paclele - Jud. BZ" },
  { id: "Jr30Z8", label: "Paclisa - Jud. AB" },
  { id: "Z43QQr", label: "Paclisa - Jud. HD" },
  { id: "rwVA3m", label: "Pacuraresti - Jud. VS" },
  { id: "mA15gm", label: "Pacureni - Jud. MS" },
  { id: "ZMG04m", label: "Pacureti - Jud. PH" },
  { id: "r2qWvm", label: "Pacuri - Jud. PH" },
  { id: "l08wQl", label: "Pacurile - Jud. BZ" },
  { id: "r3GwYr", label: "Padea - Jud. DJ" },
  { id: "rwDPvZ", label: "Pades - Jud. GJ" },
  { id: "mb9dal", label: "Padina - Jud. BZ" },
  { id: "rBR3br", label: "Padina - Jud. VL" },
  { id: "rE6qLZ", label: "Padina Mare - Jud. MH" },
  { id: "rXLJnr", label: "Padina Matei - Jud. CS" },
  { id: "lWRkYZ", label: "Padina Mica - Jud. MH" },
  { id: "leJR3m", label: "Padurani - Jud. TM" },
  { id: "OroEnl", label: "Padure - Jud. AB" },
  { id: "yZRANr", label: "Padurea - Jud. AB" },
  { id: "ZNYbYr", label: "Padurea - Jud. MS" },
  { id: "rBWEqZ", label: "Padurea Iacobeni - Jud. CJ" },
  { id: "rKjBQm", label: "Padurea Neagra - Jud. BH" },
  { id: "Wr8bEl", label: "Padureni - Jud. AG" },
  { id: "r33k5r", label: "Padureni - Jud. BC" },
  { id: "r33NVr", label: "Padureni - Jud. BH" },
  { id: "lYDAXZ", label: "Padureni - Jud. BT" },
  { id: "lDDVbl", label: "Padureni - Jud. CJ" },
  { id: "Zy2Pkl", label: "Padureni - Jud. CT" },
  { id: "l0X7ol", label: "Padureni - Jud. CV" },
  { id: "rEdEgm", label: "Padureni - Jud. DB" },
  { id: "rwykol", label: "Padureni - Jud. GR" },
  { id: "rBbq2r", label: "Padureni - Jud. IS" },
  { id: "roE5Xl", label: "Padureni - Jud. MS" },
  { id: "Zy6ALl", label: "Padureni - Jud. NT" },
  { id: "rBnpDr", label: "Padureni - Jud. SB" },
  { id: "lOB3LZ", label: "Padureni - Jud. SJ" },
  { id: "rX7MDm", label: "Padureni - Jud. SV" },
  { id: "ZyW9km", label: "Padureni - Jud. TM" },
  { id: "Z4Na8m", label: "Padureni - Jud. VN" },
  { id: "mk1VPZ", label: "Padureni - Jud. VS" },
  { id: "mAbnWr", label: "Padurenii - Jud. BZ" },
  { id: "mkwyBm", label: "Padurenii - Jud. CJ" },
  { id: "olWnYZ", label: "Padureti - Jud. AG" },
  { id: "rXa6gZ", label: "Paduretu - Jud. VL" },
  { id: "lWD4Rm", label: "Paduris - Jud. SJ" },
  { id: "lW1wdr", label: "Padurisu - Jud. CL" },
  { id: "Wr6k7Z", label: "Paduroiu Din Deal - Jud. AG" },
  { id: "Rr9k8l", label: "Paduroiu Din Vale - Jud. AG" },
  { id: "lJQdpZ", label: "Pagaia - Jud. BH" },
  { id: "JmAWZX", label: "Pagida - Jud. AB" },
  { id: "lDD7Jl", label: "Paglisa - Jud. CJ" },
  { id: "3r2Dbl", label: "Pagubeni - Jud. BC" },
  { id: "ZVy56r", label: "Pahna - Jud. VS" },
  { id: "r30OqZ", label: "Pahnesti - Jud. VS" },
  { id: "mGwk6m", label: "Paicu - Jud. CL" },
  { id: "ZNYOVr", label: "Paingeni - Jud. MS" },
  { id: "Zzww8l", label: "Paisani - Jud. GJ" },
  { id: "ZyYP2Z", label: "Paiseni - Jud. SV" },
  { id: "3r2nAr", label: "Paiuseni - Jud. AR" },
  { id: "rByOqm", label: "Pajistea - Jud. BC" },
  { id: "rqEbvr", label: "Pajistele - Jud. GJ" },
  { id: "ZVK6Rl", label: "Palanca - Jud. BC" },
  { id: "ZzgJKl", label: "Palanca - Jud. GR" },
  { id: "lL7yWZ", label: "Palanca - Jud. PH" },
  { id: "olWJBZ", label: "Palanga - Jud. AG" },
  { id: "rqO20l", label: "Palanga - Jud. VL" },
  { id: "rXLMLr", label: "Palatca - Jud. CJ" },
  { id: "l71qYr", label: "Palazu Mare - Jud. CT" },
  { id: "rwygvl", label: "Palazu Mic - Jud. CT" },
  { id: "lp1apm", label: "Paleu - Jud. BH" },
  { id: "r8DMVr", label: "Palici - Jud. BZ" },
  { id: "maoBWZ", label: "Palilula - Jud. DJ" },
  { id: "rwnvvm", label: "Palos - Jud. BV" },
  { id: "r820El", label: "Palota - Jud. BH" },
  { id: "nljOqm", label: "Paltenu - Jud. AG" },
  { id: "mk8Qwr", label: "Paltin - Jud. BV" },
  { id: "lJ9VeZ", label: "Paltin - Jud. SB" },
  { id: "mgBGEl", label: "Paltin - Jud. SV" },
  { id: "ZzP1Bm", label: "Paltin - Jud. VN" },
  { id: "Z4Yv8l", label: "Paltinata - Jud. BC" },
  { id: "m1kM3Z", label: "Paltineasa - Jud. BN" },
  { id: "r2YQbm", label: "Paltineni - Jud. BZ" },
  { id: "BZ4Gzr", label: "Paltinis - Jud. BC" },
  { id: "ZNypar", label: "Paltinis - Jud. BT" },
  { id: "maLyJl", label: "Paltinis - Jud. BZ" },
  { id: "mAbg2r", label: "Paltinis - Jud. CS" },
  { id: "l5QM3l", label: "Paltinis - Jud. HR" },
  { id: "mnzkXm", label: "Paltinis - Jud. SB" },
  { id: "ZR6JNZ", label: "Paltinis - Jud. SV" },
  { id: "ZPAnbl", label: "Paltinis - Jud. VS" },
  { id: "rKnVWm", label: "Paltinis Ciuc - Jud. HR" },
  { id: "r3QOYl", label: "Paltinisu - Jud. IL" },
  { id: "rE6EMZ", label: "Paltinisu - Jud. MH" },
  { id: "Z419Al", label: "Paltinoasa - Jud. SV" },
  { id: "l5Q1pl", label: "Paltinu - Jud. GJ" },
  { id: "lj85qr", label: "Paltinu - Jud. SV" },
  { id: "mA9bKm", label: "Panaci - Jud. SV" },
  { id: "JmAa2m", label: "Panade - Jud. AB" },
  { id: "leo13r", label: "Panaghia - Jud. DJ" },
  { id: "r9qJXr", label: "Panaitoaia - Jud. BT" },
  { id: "rXLkLr", label: "Panatau - Jud. BZ" },
  { id: "lWOaRl", label: "Panc - Jud. HD" },
  { id: "mkaJzZ", label: "Panc Saliste - Jud. HD" },
  { id: "Bmaxjl", label: "Panca - Jud. AB" },
  { id: "mAAVom", label: "Pancesti - Jud. BC" },
  { id: "mkEy2Z", label: "Pancesti - Jud. NT" },
  { id: "r36eVm", label: "Panciu - Jud. VN" },
  { id: "5l7nqZ", label: "Pancota - Jud. AR" },
  { id: "rwVavm", label: "Panduru - Jud. TL" },
  { id: "lj3wdm", label: "Panet - Jud. MS" },
  { id: "rq0AGm", label: "Pangaracior - Jud. NT" },
  { id: "l57z3r", label: "Pangarati - Jud. NT" },
  { id: "rERjQZ", label: "Panic - Jud. SJ" },
  { id: "r3kg5r", label: "Paniceni - Jud. CJ" },
  { id: "lxERom", label: "Paniova - Jud. TM" },
  { id: "r33oAr", label: "Pantasesti - Jud. BH" },
  { id: "lOBzwZ", label: "Pantazi - Jud. PH" },
  { id: "rBWdVZ", label: "Pantecani - Jud. BR" },
  { id: "lDPP2l", label: "Pantelimon - Jud. CT" },
  { id: "lD2OKr", label: "Pantelimon - Jud. IF" },
  { id: "mao19Z", label: "Pantelimon De Jos - Jud. CT" },
  { id: "Xlxb7r", label: "Pantesti - Jud. AB" },
  { id: "Z4wGqr", label: "Panticeu - Jud. CJ" },
  { id: "rKPDkZ", label: "Papauti - Jud. CV" },
  { id: "Zv5Ywr", label: "Papiu Ilarian - Jud. MS" },
  { id: "r99q8r", label: "Paraie - Jud. SV" },
  { id: "lJ6Wpl", label: "Paraienii De Jos - Jud. VL" },
  { id: "lYPyXZ", label: "Paraienii De Mijloc - Jud. VL" },
  { id: "mA8bql", label: "Paraienii De Sus - Jud. VL" },
  { id: "WmGzWl", label: "Paraschivesti - Jud. AG" },
  { id: "lDA3Gm", label: "Parau - Jud. BV" },
  { id: "ronyqm", label: "Parau - Jud. GJ" },
  { id: "mbPOqZ", label: "Parau Boghii - Jud. BC" },
  { id: "lpbp2r", label: "Parau Boia - Jud. GJ" },
  { id: "AZQ9DZ", label: "Parau Carbunari - Jud. AB" },
  { id: "mA13Bm", label: "Parau Crucii - Jud. MS" },
  { id: "lj5EdZ", label: "Parau De Pripor - Jud. GJ" },
  { id: "ZP76kr", label: "Parau De Vale - Jud. GJ" },
  { id: "7rqz0Z", label: "Parau Gruiului - Jud. AB" },
  { id: "3r2WbZ", label: "Parau Lui Mihai - Jud. AB" },
  { id: "ZVdXom", label: "Parau Mare - Jud. MS" },
  { id: "rd7W5m", label: "Parau Negrei - Jud. SV" },
  { id: "l7vEXZ", label: "Parau Negru - Jud. BT" },
  { id: "l7QqAr", label: "Parau Viu - Jud. GJ" },
  { id: "mga8GZ", label: "Paraul Carjei - Jud. NT" },
  { id: "r8BoKl", label: "Paraul Fagului - Jud. NT" },
  { id: "lYnNwm", label: "Paraul Mare - Jud. NT" },
  { id: "mbxVaZ", label: "Paraul Pantei - Jud. NT" },
  { id: "lJQn9Z", label: "Paraul Rece - Jud. BV" },
  { id: "ZVk1Al", label: "Parausani - Jud. VL" },
  { id: "lJQKAZ", label: "Parava - Jud. BC" },
  { id: "rKxNWZ", label: "Parches - Jud. TL" },
  { id: "l7J2YZ", label: "Parcovaci - Jud. IS" },
  { id: "l59R3l", label: "Pardina - Jud. TL" },
  { id: "Zzo0El", label: "Pardosi - Jud. BZ" },
  { id: "ZPqjkl", label: "Parepa Rusani - Jud. PH" },
  { id: "ZNyaLr", label: "Pargaresti - Jud. BC" },
  { id: "Z4131l", label: "Parhauti - Jud. SV" },
  { id: "ZQQxJZ", label: "Parhida - Jud. BH" },
  { id: "lYDvwZ", label: "Parincea - Jud. BC" },
  { id: "r825Vl", label: "Parjol - Jud. BC" },
  { id: "l08vkl", label: "Parjolesti - Jud. BZ" },
  { id: "lOAKor", label: "Parlagele - Jud. MH" },
  { id: "ZPeWEl", label: "Parneaura - Jud. CS" },
  { id: "wmg1BZ", label: "Parnesti - Jud. AR" },
  { id: "lL1gGm", label: "Paros - Jud. HD" },
  { id: "rqOMBl", label: "Parpanita - Jud. VS" },
  { id: "lx8p7l", label: "Parscov - Jud. BZ" },
  { id: "lx8KPl", label: "Parscovelu - Jud. BZ" },
  { id: "Z491pl", label: "Parscoveni - Jud. OT" },
  { id: "r2Mnpm", label: "Parta - Jud. TM" },
  { id: "ZvzA3Z", label: "Partestii De Jos - Jud. SV" },
  { id: "leGD1r", label: "Partestii De Sus - Jud. SV" },
  { id: "r99NOr", label: "Partizani - Jud. TL" },
  { id: "rdKdam", label: "Partos - Jud. TM" },
  { id: "l79WAr", label: "Paru - Jud. TM" },
  { id: "mnp7AZ", label: "Paru Rotund - Jud. TR" },
  { id: "ZyeM0Z", label: "Parva - Jud. BN" },
  { id: "r301qZ", label: "Parvesti - Jud. VS" },
  { id: "l717dr", label: "Parvova - Jud. CS" },
  { id: "LZM2pl", label: "Parvu Rosu - Jud. AG" },
  { id: "mG5w0Z", label: "Parvulesti - Jud. BC" },
  { id: "ZRwwjl", label: "Parvulesti - Jud. GJ" },
  { id: "r2wOvZ", label: "Parvulesti - Jud. MH" },
  { id: "lJ289l", label: "Pasarani - Jud. MH" },
  { id: "lpzGWr", label: "Pasarea - Jud. CL" },
  { id: "lJxYgr", label: "Pasarea - Jud. IF" },
  { id: "lxzKMr", label: "Pasarei - Jud. VL" },
  { id: "ZPa4kl", label: "Pasareni - Jud. MS" },
  { id: "r9qLMr", label: "Pasateni - Jud. BT" },
  { id: "Zzw58l", label: "Pascani - Jud. IS" },
  { id: "ZvzPJZ", label: "Pascoaia - Jud. VL" },
  { id: "rwYpQl", label: "Pasculesti - Jud. VL" },
  { id: "olW2BZ", label: "Pastesti - Jud. AB" },
  { id: "lYn9Rm", label: "Pastraveni - Jud. NT" },
  { id: "lj4vwr", label: "Pasunea Mare - Jud. SM" },
  { id: "mb94Bl", label: "Pata - Jud. CJ" },
  { id: "lOAOwr", label: "Pataligeni - Jud. NT" },
  { id: "lYyKVl", label: "Patarlagele - Jud. BZ" },
  { id: "Rr9KXl", label: "Patars - Jud. AR" },
  { id: "r6OQ7Z", label: "Patas - Jud. CS" },
  { id: "ZQwadm", label: "Patesti - Jud. VL" },
  { id: "ZvzvxZ", label: "Patlageanca - Jud. TL" },
  { id: "PrEd6m", label: "Patrahaitesti - Jud. AB" },
  { id: "arBWbZ", label: "Patrangeni - Jud. AB" },
  { id: "rB54Jm", label: "Patrascani - Jud. VN" },
  { id: "ma6Lbr", label: "Patrauti - Jud. SV" },
  { id: "l7wgYl", label: "Patricheni - Jud. NT" },
  { id: "r8YKMZ", label: "Patroaia Deal - Jud. DB" },
  { id: "l52kAm", label: "Patroaia Vale - Jud. DB" },
  { id: "mGxn9r", label: "Patru Frati - Jud. IL" },
  { id: "9m18jl", label: "Patrusesti - Jud. AB" },
  { id: "yZRxym", label: "Patrutesti - Jud. AB" },
  { id: "ZPaJbl", label: "Patulele - Jud. MH" },
  { id: "3ZVVOZ", label: "Patuleni - Jud. AG" },
  { id: "rd7Qzm", label: "Pauca - Jud. SB" },
  { id: "m1Ba8r", label: "Paucea - Jud. SB" },
  { id: "ZvoLJl", label: "Paucinesti - Jud. HD" },
  { id: "lD6dKr", label: "Paucisoara - Jud. MS" },
  { id: "qZNQar", label: "Pauleasca - Jud. AG" },
  { id: "rBR9dr", label: "Pauleasca - Jud. TR" },
  { id: "WZP5bl", label: "Pauleni - Jud. AG" },
  { id: "l0NMgr", label: "Pauleni - Jud. HR" },
  { id: "rqEa9r", label: "Pauleni Ciuc - Jud. HR" },
  { id: "lxxDpl", label: "Paulesti - Jud. BH" },
  { id: "mgL0qr", label: "Paulesti - Jud. HD" },
  { id: "rX7enm", label: "Paulesti - Jud. PH" },
  { id: "mgB57l", label: "Paulesti - Jud. SM" },
  { id: "ZQwYvm", label: "Paulesti - Jud. VN" },
  { id: "ZR35yl", label: "Paulestii Noi - Jud. PH" },
  { id: "7rq7gl", label: "Paulian - Jud. AR" },
  { id: "m1B0gr", label: "Paulian - Jud. SM" },
  { id: "WmGQwZ", label: "Paulis - Jud. AR" },
  { id: "rKngRm", label: "Paulis - Jud. HD" },
  { id: "lexWoZ", label: "Pauloaia - Jud. MS" },
  { id: "r33EYr", label: "Paun - Jud. BT" },
  { id: "r8OzKm", label: "Paun - Jud. IS" },
  { id: "nljw4m", label: "Paunesti - Jud. AG" },
  { id: "lD6Qnr", label: "Paunesti - Jud. MH" },
  { id: "rXxJ3r", label: "Paunesti - Jud. VN" },
  { id: "r6bW7r", label: "Pausa - Jud. BH" },
  { id: "rd7EWm", label: "Pausa - Jud. SJ" },
  { id: "ZzVbnZ", label: "Pausa - Jud. VL" },
  { id: "r8ONqm", label: "Pausesti - Jud. IS" },
  { id: "l5OkYr", label: "Pausesti - Jud. VL" },
  { id: "l0k3oZ", label: "Pausesti Maglasi - Jud. VL" },
  { id: "ZvO5xl", label: "Pausesti Otasau - Jud. VL" },
  { id: "rK6Mem", label: "Pavalari - Jud. VN" },
  { id: "lj3jwm", label: "Pavat - Jud. MH" },
  { id: "lxK3wZ", label: "Peceiu - Jud. SJ" },
  { id: "l0KAgZ", label: "Peceneaga - Jud. TL" },
  { id: "ZyNAol", label: "Pechea - Jud. GL" },
  { id: "krwWor", label: "Pecica - Jud. AR" },
  { id: "l7QQwr", label: "Pecineaga - Jud. CT" },
  { id: "ZRJqjZ", label: "Pecinisca - Jud. CS" },
  { id: "ZR6VjZ", label: "Peciu Nou - Jud. TM" },
  { id: "mbOLEr", label: "Pegeni - Jud. GJ" },
  { id: "r6x7pl", label: "Peicani - Jud. VS" },
  { id: "5l7XLm", label: "Peles - Jud. AB" },
  { id: "Z49Q1l", label: "Peles - Jud. SM" },
  { id: "rd5A7Z", label: "Pelinu - Jud. CL" },
  { id: "r976Ml", label: "Pelinu - Jud. CT" },
  { id: "l7ygvr", label: "Pelisor - Jud. SB" },
  { id: "lxKazZ", label: "Pelisor - Jud. SM" },
  { id: "l7vv3Z", label: "Perchiu - Jud. BC" },
  { id: "rBRNqr", label: "Percosova - Jud. TM" },
  { id: "krwBym", label: "Peregu Mare - Jud. AR" },
  { id: "yZy98Z", label: "Peregu Mic - Jud. AR" },
  { id: "ZNGVal", label: "Pereni - Jud. DJ" },
  { id: "r6yOgm", label: "Peretu - Jud. OT" },
  { id: "l79X3r", label: "Peretu - Jud. TR" },
  { id: "mbxGQZ", label: "Peri - Jud. MH" },
  { id: "rdK15m", label: "Periam - Jud. TM" },
  { id: "lj4kBr", label: "Pericei - Jud. SJ" },
  { id: "ZyYX8Z", label: "Periceiu Mic - Jud. SJ" },
  { id: "l0Noor", label: "Perieni - Jud. IS" },
  { id: "lDaVJm", label: "Perieni - Jud. VS" },
  { id: "r3QPzl", label: "Perieti - Jud. IL" },
  { id: "rX7a3m", label: "Perieti - Jud. OT" },
  { id: "l795qr", label: "Perii Brosteni - Jud. TR" },
  { id: "lJ94gZ", label: "Perii Vadului - Jud. SJ" },
  { id: "ZVyXdr", label: "Periprava - Jud. TL" },
  { id: "r2we9Z", label: "Peris - Jud. IF" },
  { id: "maxq2l", label: "Peris - Jud. MS" },
  { id: "ZRB72Z", label: "Perisani - Jud. VL" },
  { id: "leaMdl", label: "Perisor - Jud. BN" },
  { id: "l7QVwr", label: "Perisor - Jud. DJ" },
  { id: "ZRj42Z", label: "Perisoru - Jud. BR" },
  { id: "mAbXar", label: "Perisoru - Jud. CL" },
  { id: "5l7JYZ", label: "Perjesti - Jud. AB" },
  { id: "rEQ55r", label: "Persani - Jud. BV" },
  { id: "mboGBr", label: "Persinari - Jud. DB" },
  { id: "rwEzjl", label: "Persunari - Jud. PH" },
  { id: "rE4RLm", label: "Pertesti - Jud. VL" },
  { id: "l0K2PZ", label: "Pesac - Jud. TM" },
  { id: "lL7VAZ", label: "Pescaresti - Jud. OT" },
  { id: "7rqeor", label: "Pescari - Jud. AR" },
  { id: "rdNaGl", label: "Pesceana - Jud. VL" },
  { id: "3ZVKWl", label: "Peste Valea Bistrii - Jud. AB" },
  { id: "mbORBr", label: "Pesteana - Jud. HD" },
  { id: "rq03Gm", label: "Pesteana - Jud. MH" },
  { id: "r2Qedm", label: "Pesteana De Jos - Jud. GJ" },
  { id: "Z4qGQl", label: "Pesteana Jiu - Jud. GJ" },
  { id: "rwyoyl", label: "Pesteana Vulcan - Jud. GJ" },
  { id: "lL1zAm", label: "Pestenita - Jud. HD" },
  { id: "ZQVP4l", label: "Pestenuta - Jud. MH" },
  { id: "m1kpRZ", label: "Pestera - Jud. BV" },
  { id: "rK3vQr", label: "Pestera - Jud. CJ" },
  { id: "mG11WZ", label: "Pestera - Jud. CT" },
  { id: "ZzwLnl", label: "Pestera - Jud. HD" },
  { id: "rXd2Dr", label: "Pestere - Jud. BH" },
  { id: "lOWz3l", label: "Pestere - Jud. CS" },
  { id: "lDAxXm", label: "Pestis - Jud. BH" },
  { id: "rBbjQr", label: "Pestisani - Jud. GJ" },
  { id: "ZRw5Kl", label: "Pestisu Mare - Jud. HD" },
  { id: "ZRwNel", label: "Pestisu Mic - Jud. HD" },
  { id: "rwPoXZ", label: "Pestra - Jud. OT" },
  { id: "rXLW3r", label: "Pestritu - Jud. BZ" },
  { id: "lYGLQr", label: "Petculesti - Jud. OT" },
  { id: "mAbLWr", label: "Petea - Jud. CJ" },
  { id: "r8BGDl", label: "Petea - Jud. MS" },
  { id: "ZV3gBl", label: "Petea - Jud. SM" },
  { id: "lx7DzZ", label: "Petecu - Jud. HR" },
  { id: "LZMYpZ", label: "Petelca - Jud. AB" },
  { id: "r6GK2Z", label: "Petelea - Jud. MS" },
  { id: "Nmk7wm", label: "Petelei - Jud. AB" },
  { id: "l7QyLr", label: "Peteni - Jud. CV" },
  { id: "r6Gy2Z", label: "Peteritea - Jud. MM" },
  { id: "rX79Dm", label: "Petia - Jud. SV" },
  { id: "r2VoQr", label: "Petid - Jud. BH" },
  { id: "ZvEePr", label: "Petin - Jud. SM" },
  { id: "rKDpQZ", label: "Petis - Jud. SB" },
  { id: "mnJeGm", label: "Petnic - Jud. CS" },
  { id: "l7w5Xl", label: "Petra - Jud. MH" },
  { id: "mboaEr", label: "Petrachei - Jud. GJ" },
  { id: "r2Yopm", label: "Petrachesti - Jud. BZ" },
  { id: "ZVdYJm", label: "Petrachioaia - Jud. IF" },
  { id: "m1kxRZ", label: "Petrani - Jud. BH" },
  { id: "ZQwY5m", label: "Petreanu - Jud. VN" },
  { id: "4lO7wl", label: "Petreasa - Jud. AB" },
  { id: "ropPNZ", label: "Petreasa - Jud. BH" },
  { id: "LmnE8l", label: "Petreni - Jud. AB" },
  { id: "mGxv2r", label: "Petreni - Jud. HD" },
  { id: "rXqWpZ", label: "Petreni - Jud. HR" },
  { id: "wmgDr0", label: "Petresti - Jud. AB" },
  { id: "yZy8kr", label: "Petresti - Jud. AG" },
  { id: "ljG5Bm", label: "Petresti - Jud. BC" },
  { id: "mnJVXm", label: "Petresti - Jud. CJ" },
  { id: "ron6qm", label: "Petresti - Jud. DB" },
  { id: "mgx29r", label: "Petresti - Jud. GJ" },
  { id: "mnQ4pl", label: "Petresti - Jud. HD" },
  { id: "lx7vPZ", label: "Petresti - Jud. IF" },
  { id: "ZzwNYl", label: "Petresti - Jud. IS" },
  { id: "mb5Jdl", label: "Petresti - Jud. SM" },
  { id: "ZQwnom", label: "Petresti - Jud. VN" },
  { id: "lx897l", label: "Petrestii De Jos - Jud. CJ" },
  { id: "rd5gqZ", label: "Petrestii De Mijloc - Jud. CJ" },
  { id: "rodB5Z", label: "Petrestii De Sus - Jud. CJ" },
  { id: "mgPYEr", label: "Petreu - Jud. BH" },
  { id: "lOwLnl", label: "Petricani - Jud. BT" },
  { id: "mA1qKm", label: "Petricani - Jud. NT" },
  { id: "ZM7GQm", label: "Petriceni - Jud. CV" },
  { id: "ZQQyaZ", label: "Petricica - Jud. BC" },
  { id: "rwDwoZ", label: "Petrila - Jud. HD" },
  { id: "ZNYgxr", label: "Petrilaca - Jud. MS" },
  { id: "Zv5eVr", label: "Petrilaca De Mures - Jud. MS" },
  { id: "r6b03r", label: "Petrileni - Jud. BH" },
  { id: "rwp4QZ", label: "Petrilova - Jud. CS" },
  { id: "rBnLdr", label: "Petrindu - Jud. SJ" },
  { id: "l0wA0Z", label: "Petrinzel - Jud. SJ" },
  { id: "arB7bZ", label: "Petris - Jud. AR" },
  { id: "Zv2e9Z", label: "Petris - Jud. BN" },
  { id: "rdx92r", label: "Petris - Jud. MH" },
  { id: "BmaWZX", label: "Petrisat - Jud. AB" },
  { id: "lOWdnl", label: "Petrisoru - Jud. BZ" },
  { id: "rKxoGZ", label: "Petroasa Mare - Jud. TM" },
  { id: "l79j3r", label: "Petroman - Jud. TM" },
  { id: "mnQjAl", label: "Petros - Jud. HD" },
  { id: "rwpoQZ", label: "Petrosani - Jud. CT" },
  { id: "majONr", label: "Petrosani - Jud. HD" },
  { id: "mA428l", label: "Petrosica - Jud. IS" },
  { id: "rXLeDr", label: "Petrosnita - Jud. CS" },
  { id: "r2wMpZ", label: "Petrova - Jud. MM" },
  { id: "ZVyQKr", label: "Petrovaselo - Jud. TM" },
  { id: "ZRxRDm", label: "Petru Rares - Jud. GR" },
  { id: "l57nvr", label: "Petru Voda - Jud. NT" },
  { id: "Rr9j8Z", label: "Pianu De Jos - Jud. AB" },
  { id: "Wr6N7r", label: "Pianu De Sus - Jud. AB" },
  { id: "krwd3Z", label: "Piatra - Jud. AG" },
  { id: "ZMY1yZ", label: "Piatra - Jud. BN" },
  { id: "rBOdQZ", label: "Piatra - Jud. CT" },
  { id: "rdV9Wl", label: "Piatra - Jud. DB" },
  { id: "ZRwWql", label: "Piatra - Jud. HD" },
  { id: "rdxxqr", label: "Piatra - Jud. MM" },
  { id: "rBjA2r", label: "Piatra - Jud. OT" },
  { id: "ZV3QAl", label: "Piatra - Jud. PH" },
  { id: "lOYRwr", label: "Piatra - Jud. TL" },
  { id: "mGa30Z", label: "Piatra - Jud. TR" },
  { id: "leLEYZ", label: "Piatra Alba - Jud. BZ" },
  { id: "ZRjkLZ", label: "Piatra Fantanele - Jud. BN" },
  { id: "lpA6em", label: "Piatra Mica - Jud. PH" },
  { id: "Zy6XDl", label: "Piatra Neamt - Jud. NT" },
  { id: "rK2vWm", label: "Piatra Olt - Jud. OT" },
  { id: "ZNY8er", label: "Piatra Soimului - Jud. NT" },
  { id: "mG1GnZ", label: "Picaturile - Jud. DJ" },
  { id: "ZM7Qym", label: "Picior De Munte - Jud. DB" },
  { id: "r2Q9Qm", label: "Picioru Lupului - Jud. IS" },
  { id: "rEQWMr", label: "Picleu - Jud. BH" },
  { id: "rE29xZ", label: "Picu - Jud. GJ" },
  { id: "lL7PyZ", label: "Pielcani - Jud. OT" },
  { id: "Xl0zBr", label: "Pielesti - Jud. AG" },
  { id: "mG1bWZ", label: "Pielesti - Jud. DJ" },
  { id: "ZyN00l", label: "Pieptani - Jud. GJ" },
  { id: "lezYJm", label: "Piersica - Jud. IL" },
  { id: "lpEgbm", label: "Pietrari - Jud. DB" },
  { id: "roYaNl", label: "Pietrari - Jud. VL" },
  { id: "l5Qpvl", label: "Pietraria - Jud. IS" },
  { id: "mGgPpm", label: "Pietrarii De Sus - Jud. VL" },
  { id: "mgAJBZ", label: "Pietraru - Jud. BZ" },
  { id: "mbob2r", label: "Pietrele - Jud. GR" },
  { id: "rBWwaZ", label: "Pietreni - Jud. CT" },
  { id: "l590Nl", label: "Pietreni - Jud. VL" },
  { id: "mkzbBl", label: "Pietriceaua - Jud. PH" },
  { id: "r9Dd3m", label: "Pietricica - Jud. PH" },
  { id: "ZyGAqm", label: "Pietris - Jud. IS" },
  { id: "Zv5L9r", label: "Pietris - Jud. MS" },
  { id: "lj3Yqm", label: "Pietris - Jud. OT" },
  { id: "mnxGAZ", label: "Pietrisu - Jud. GR" },
  { id: "lOA9Jr", label: "Pietrisu - Jud. OT" },
  { id: "ZMGnGm", label: "Pietrisu - Jud. PH" },
  { id: "rwV5Km", label: "Pietrisu - Jud. VL" },
  { id: "r8YAKZ", label: "Pietroaia - Jud. DJ" },
  { id: "Lmn2jm", label: "Pietroasa - Jud. AG" },
  { id: "rwn9om", label: "Pietroasa - Jud. BH" },
  { id: "lDD4bl", label: "Pietroasa - Jud. CJ" },
  { id: "lDq1Xm", label: "Pietroasa - Jud. SV" },
  { id: "rox5nZ", label: "Pietroasa - Jud. TM" },
  { id: "lDK5GZ", label: "Pietroasa - Jud. VL" },
  { id: "r2b59r", label: "Pietroasa - Jud. VN" },
  { id: "mb9Yql", label: "Pietroasa Mica - Jud. BZ" },
  { id: "Z4wWqr", label: "Pietroasele - Jud. BZ" },
  { id: "lW11dr", label: "Pietroiu - Jud. BR" },
  { id: "wmgw7m", label: "Pietrosani - Jud. AG" },
  { id: "rd7X5m", label: "Pietrosani - Jud. PH" },
  { id: "rwVzam", label: "Pietrosani - Jud. TR" },
  { id: "r27aeZ", label: "Pietrosita - Jud. DB" },
  { id: "mAb9Br", label: "Pietrosu - Jud. BZ" },
  { id: "mkaOoZ", label: "Pietrosu - Jud. IS" },
  { id: "lLVYWr", label: "Pietrosu - Jud. NT" },
  { id: "ZNYQLr", label: "Pildesti - Jud. NT" },
  { id: "7rqo0l", label: "Pilu - Jud. AR" },
  { id: "lLJaYr", label: "Pilugani - Jud. SV" },
  { id: "r2MGvm", label: "Pini - Jud. TM" },
  { id: "rKPARZ", label: "Pinoasa - Jud. GJ" },
  { id: "rK34xr", label: "Pintic - Jud. CJ" },
  { id: "ZvodPl", label: "Pintic - Jud. HR" },
  { id: "r2V89r", label: "Pinticu - Jud. BN" },
  { id: "rE7RQl", label: "Pinu - Jud. BZ" },
  { id: "l7QBXr", label: "Pioresti - Jud. DJ" },
  { id: "mnzjBm", label: "Pioresti - Jud. PH" },
  { id: "lOAJLr", label: "Pipea - Jud. MS" },
  { id: "r8Bxql", label: "Pipirig - Jud. NT" },
  { id: "r6yg3m", label: "Pir - Jud. SM" },
  { id: "lj9LdZ", label: "Piria - Jud. DJ" },
  { id: "AZQval", label: "Pirita - Jud. AB" },
  { id: "ZPqkKl", label: "Pirosa - Jud. SJ" },
  { id: "r9Dgqm", label: "Piru Nou - Jud. SM" },
  { id: "arBXal", label: "Piscani - Jud. AG" },
  { id: "rKPe9Z", label: "Piscani - Jud. DJ" },
  { id: "l07eoZ", label: "Piscani - Jud. OT" },
  { id: "r86ADl", label: "Piscari - Jud. SM" },
  { id: "ma6qjr", label: "Pischia - Jud. TM" },
  { id: "l5QG9l", label: "Pischinti - Jud. HD" },
  { id: "r6665r", label: "Piscoiu - Jud. GJ" },
  { id: "lL78yZ", label: "Piscolt - Jud. SM" },
  { id: "rKPw0Z", label: "Piscu - Jud. GL" },
  { id: "ZP7R3r", label: "Piscu - Jud. IF" },
  { id: "lDPzjl", label: "Piscu Corbului - Jud. GL" },
  { id: "mboqEr", label: "Piscu Lung - Jud. DJ" },
  { id: "mbNKvm", label: "Piscu Mare - Jud. VL" },
  { id: "rBOgQZ", label: "Piscu Nou - Jud. DJ" },
  { id: "lj8qdr", label: "Piscu Pietrei - Jud. VL" },
  { id: "mbNqKm", label: "Piscu Radului - Jud. VN" },
  { id: "ljo6km", label: "Piscu Reghiului - Jud. VN" },
  { id: "lOD3wm", label: "Piscu Rusului - Jud. IS" },
  { id: "m1Dg6l", label: "Piscu Sadovei - Jud. DJ" },
  { id: "r65yDm", label: "Piscu Scoartei - Jud. VL" },
  { id: "rwyMVl", label: "Piscu Vechi - Jud. DJ" },
  { id: "r3yazl", label: "Pisculesti - Jud. PH" },
  { id: "l5230m", label: "Pisculet - Jud. DJ" },
  { id: "mGxw9r", label: "Piscuri - Jud. GJ" },
  { id: "rqEbGr", label: "Pistestii Din Deal - Jud. GJ" },
  { id: "lD6KWr", label: "Pistrita - Jud. MH" },
  { id: "XlDEjm", label: "Pitarcesti - Jud. AB" },
  { id: "ZQqN5Z", label: "Pitaru - Jud. DB" },
  { id: "ZyGP4m", label: "Piteasca - Jud. IF" },
  { id: "wmgVDl", label: "Pitesti - Jud. AG" },
  { id: "Jr3Jem", label: "Pitiga - Jud. AB" },
  { id: "4lO8Nm", label: "Pitigaia - Jud. AG" },
  { id: "mkwoom", label: "Pitigaia - Jud. CL" },
  { id: "mkz7Ql", label: "Pitigoi - Jud. PH" },
  { id: "krwKVr", label: "Pitoi - Jud. AG" },
  { id: "rqzP1Z", label: "Pitulati - Jud. BR" },
  { id: "ZQvwol", label: "Pitulicea - Jud. BZ" },
  { id: "lDKWjZ", label: "Pitulusa - Jud. VN" },
  { id: "mgJ37l", label: "Placinteni - Jud. VN" },
  { id: "EZzV3Z", label: "Plai - Jud. AB" },
  { id: "lx7pwZ", label: "Plai - Jud. HD" },
  { id: "rq0BGm", label: "Plai - Jud. MH" },
  { id: "mnzBqm", label: "Plai - Jud. PH" },
  { id: "mk1xnZ", label: "Plai - Jud. SV" },
  { id: "ZRwvKl", label: "Plaiesii De Jos - Jud. HR" },
  { id: "rBbx2r", label: "Plaiesii De Sus - Jud. HR" },
  { id: "l71dvr", label: "Plaiesti - Jud. CJ" },
  { id: "rBjpQr", label: "Plaiesu - Jud. NT" },
  { id: "lxK1MZ", label: "Plaietu - Jud. PH" },
  { id: "Zy2D0l", label: "Plaisor - Jud. BZ" },
  { id: "lL7MWZ", label: "Plaiu - Jud. PH" },
  { id: "ma50jm", label: "Plaiu Campinei - Jud. PH" },
  { id: "ZvEx5r", label: "Plaiu Cornului - Jud. PH" },
  { id: "Z4wkpr", label: "Plaiu Nucului - Jud. BZ" },
  { id: "ZR6x2Z", label: "Plaiu Sarului - Jud. SV" },
  { id: "wmgEkZ", label: "Plaiuri - Jud. AB" },
  { id: "maLPWl", label: "Plaiuri - Jud. CJ" },
  { id: "mGww6m", label: "Plasoiu - Jud. BR" },
  { id: "lpz7Yr", label: "Plataresti - Jud. CL" },
  { id: "r66V3r", label: "Platonesti - Jud. HR" },
  { id: "ZyGMLm", label: "Platonesti - Jud. IL" },
  { id: "lj8WLr", label: "Plauru - Jud. TL" },
  { id: "mk1aqZ", label: "Plavalari - Jud. SV" },
  { id: "mGwjBm", label: "Plavatu - Jud. BZ" },
  { id: "r2q4pm", label: "Plavia - Jud. PH" },
  { id: "ZR3jKl", label: "Plaviceanca - Jud. OT" },
  { id: "r6yqgm", label: "Plaviceni - Jud. OT" },
  { id: "rwENKl", label: "Pleasa - Jud. PH" },
  { id: "rwY73l", label: "Pleasa - Jud. VL" },
  { id: "rdKa4m", label: "Pleasov - Jud. TR" },
  { id: "rBOa5Z", label: "Plenita - Jud. DJ" },
  { id: "lO6aLZ", label: "Plesa - Jud. GJ" },
  { id: "ZQqLDZ", label: "Plesa - Jud. GL" },
  { id: "ZzVEYZ", label: "Plesa - Jud. SV" },
  { id: "ZQQ4pZ", label: "Plesani - Jud. BT" },
  { id: "l58KAZ", label: "Plesca - Jud. SJ" },
  { id: "rwpVxZ", label: "Plescioara - Jud. BZ" },
  { id: "mGwPDm", label: "Plescoi - Jud. BZ" },
  { id: "5l7bLZ", label: "Plescuta - Jud. AB" },
  { id: "AZQAaZ", label: "Plescuta - Jud. AR" },
  { id: "grK3Qr", label: "Plesesti - Jud. AB" },
  { id: "lx86nl", label: "Plesesti - Jud. BZ" },
  { id: "Z41D1l", label: "Plesesti - Jud. SV" },
  { id: "ZM6G4l", label: "Plesesti - Jud. VL" },
  { id: "mbNGdm", label: "Plesesti - Jud. VN" },
  { id: "yZy7Km", label: "Plesi - Jud. AB" },
  { id: "r2YwAm", label: "Plesi - Jud. BZ" },
  { id: "ronWnm", label: "Plesoi - Jud. DJ" },
  { id: "lxKEDZ", label: "Plesoiu - Jud. OT" },
  { id: "rwYDQl", label: "Plesoiu - Jud. VL" },
  { id: "m1kK6Z", label: "Plevna - Jud. BT" },
  { id: "l54OEZ", label: "Plevna - Jud. BZ" },
  { id: "lLakym", label: "Plevna - Jud. CL" },
  { id: "l52D2m", label: "Plevna - Jud. GL" },
  { id: "VrXanZ", label: "Plisti - Jud. AB" },
  { id: "r9Dbym", label: "Ploiesti - Jud. PH" },
  { id: "mgBE9l", label: "Ploiestiori - Jud. PH" },
  { id: "rKneem", label: "Plop - Jud. HD" },
  { id: "l51Mpl", label: "Plopana - Jud. BC" },
  { id: "Z4wRJr", label: "Plopeasa - Jud. BZ" },
  { id: "lJWYpm", label: "Plopeni - Jud. CT" },
  { id: "l58jNZ", label: "Plopeni - Jud. PH" },
  { id: "r9D3Mm", label: "Plopeni - Jud. SV" },
  { id: "ZQypoZ", label: "Plopeni - Jud. VS" },
  { id: "ZMYAzZ", label: "Plopenii Mari - Jud. BT" },
  { id: "leaB1l", label: "Plopenii Mici - Jud. BT" },
  { id: "ZNWyyl", label: "Plopi - Jud. BR" },
  { id: "rqz3QZ", label: "Plopi - Jud. BZ" },
  { id: "lYyXwl", label: "Plopi - Jud. CJ" },
  { id: "lODGGm", label: "Plopi - Jud. HD" },
  { id: "ZPaDEl", label: "Plopi - Jud. MH" },
  { id: "mbKKdr", label: "Plopi - Jud. TR" },
  { id: "r6xK3l", label: "Plopi - Jud. VS" },
  { id: "rqOgol", label: "Plopii Slavitesti - Jud. TR" },
  { id: "ZP7GEr", label: "Plopis - Jud. HR" },
  { id: "lJ29el", label: "Plopis - Jud. MM" },
  { id: "ZPqVDl", label: "Plopis - Jud. SJ" },
  { id: "lWvNRZ", label: "Plopoasa - Jud. VS" },
  { id: "rKPXGZ", label: "Plopsor - Jud. DJ" },
  { id: "ZzzDbZ", label: "Plopsorelu - Jud. OT" },
  { id: "rqE0dr", label: "Plopsoru - Jud. GJ" },
  { id: "mgxg5r", label: "Plopsoru - Jud. GR" },
  { id: "ZVKdKl", label: "Plopu - Jud. BC" },
  { id: "rdj6Gl", label: "Plopu - Jud. BR" },
  { id: "leL01Z", label: "Plopu - Jud. CS" },
  { id: "l52O2m", label: "Plopu - Jud. DB" },
  { id: "lL1Pnm", label: "Plopu - Jud. GJ" },
  { id: "leG1Xr", label: "Plopu - Jud. PH" },
  { id: "mgJ75l", label: "Plopu - Jud. VN" },
  { id: "r27GpZ", label: "Plopu Amarasti - Jud. DJ" },
  { id: "lWvdYZ", label: "Plopul - Jud. TL" },
  { id: "Z4qXJl", label: "Plosca - Jud. DJ" },
  { id: "rBRGgr", label: "Plosca - Jud. TR" },
  { id: "rE756l", label: "Ploscos - Jud. CJ" },
  { id: "rK6Oxm", label: "Ploscuteni - Jud. VN" },
  { id: "lx8GDl", label: "Plostina - Jud. BZ" },
  { id: "ZPBKAm", label: "Plostina - Jud. DJ" },
  { id: "rwyval", label: "Plostina - Jud. GJ" },
  { id: "rE4eqm", label: "Plostina - Jud. VN" },
  { id: "ZvzXDZ", label: "Plotonesti - Jud. VS" },
  { id: "r8OQMm", label: "Plugari - Jud. IS" },
  { id: "ZQVjDl", label: "Plugari - Jud. NT" },
  { id: "rqz9BZ", label: "Plugova - Jud. CS" },
  { id: "l08Q0l", label: "Plumbuita - Jud. CL" },
  { id: "r6GN5Z", label: "Pluta - Jud. MH" },
  { id: "Zy6J2l", label: "Pluton - Jud. NT" },
  { id: "lJ9JpZ", label: "Plutonita - Jud. SV" },
  { id: "lp5NYm", label: "Poarta - Jud. MS" },
  { id: "rwyyVl", label: "Poarta Alba - Jud. CT" },
  { id: "robX3m", label: "Poarta Salajului - Jud. SJ" },
  { id: "rERaqZ", label: "Poboru - Jud. OT" },
  { id: "mgok5m", label: "Pochidia - Jud. VS" },
  { id: "lJPn3r", label: "Pociovalistea - Jud. GJ" },
  { id: "r6boDr", label: "Pocioveliste - Jud. BH" },
  { id: "mnPgGZ", label: "Poclusa De Barcau - Jud. BH" },
  { id: "rBye2m", label: "Poclusa De Beius - Jud. BH" },
  { id: "rByYdm", label: "Pocola - Jud. BH" },
  { id: "ZQyqDZ", label: "Pocoleni - Jud. SV" },
  { id: "lODOJm", label: "Pocreaca - Jud. IS" },
  { id: "rXy63Z", label: "Pocruia - Jud. GJ" },
  { id: "lJWAYm", label: "Podari - Jud. CL" },
  { id: "ZM754m", label: "Podari - Jud. DJ" },
  { id: "Jr37Gr", label: "Podei - Jud. BC" },
  { id: "ZQG3om", label: "Podele - Jud. HD" },
  { id: "WZPnvm", label: "Podeni - Jud. AG" },
  { id: "ZyeXDZ", label: "Podeni - Jud. BT" },
  { id: "mGw7wm", label: "Podeni - Jud. CJ" },
  { id: "r9yXqr", label: "Podeni - Jud. MH" },
  { id: "Z494jl", label: "Podeni - Jud. SV" },
  { id: "rXxOnr", label: "Podeni - Jud. VL" },
  { id: "Zvz02Z", label: "Podeni - Jud. VS" },
  { id: "lLeb6m", label: "Podenii - Jud. BN" },
  { id: "lpADPm", label: "Podenii Noi - Jud. PH" },
  { id: "r2qOem", label: "Podenii Vechi - Jud. PH" },
  { id: "lWBwRZ", label: "Poderei - Jud. BN" },
  { id: "rE7w6l", label: "Podgoria - Jud. BZ" },
  { id: "rX7KDm", label: "Podgoria - Jud. PH" },
  { id: "ZRjaLZ", label: "Podirei - Jud. BN" },
  { id: "m1kn3Z", label: "Podis - Jud. BC" },
  { id: "leok6r", label: "Podisor - Jud. GR" },
  { id: "2l5Rpm", label: "Podisoru - Jud. AG" },
  { id: "ZVwEdl", label: "Podisu - Jud. IS" },
  { id: "lYG40r", label: "Podisu - Jud. SJ" },
  { id: "rXybpZ", label: "Podoleni - Jud. GL" },
  { id: "l076gZ", label: "Podoleni - Jud. NT" },
  { id: "lpbPPr", label: "Podolenii De Jos - Jud. IS" },
  { id: "lWOnYl", label: "Podolenii De Sus - Jud. IS" },
  { id: "r82w0l", label: "Podriga - Jud. BT" },
  { id: "VleK3r", label: "Podu Brosteni - Jud. AG" },
  { id: "mnzbXm", label: "Podu Cheii - Jud. PH" },
  { id: "rEdVgm", label: "Podu Corbencii - Jud. DB" },
  { id: "mGWg0m", label: "Podu Corbului - Jud. PH" },
  { id: "mAG78Z", label: "Podu Cosnei - Jud. SV" },
  { id: "r3Gdnr", label: "Podu Cristinii - Jud. DB" },
  { id: "5l7kYl", label: "Podu Dambovitei - Jud. AG" },
  { id: "r272bZ", label: "Podu Doamnei - Jud. GR" },
  { id: "mGPj9r", label: "Podu Grosului - Jud. MH" },
  { id: "rE2g5Z", label: "Podu Hagiului - Jud. IS" },
  { id: "rBO6dZ", label: "Podu Ilfovatului - Jud. GR" },
  { id: "lez41m", label: "Podu Iloaiei - Jud. IS" },
  { id: "rXqApZ", label: "Podu Jijiei - Jud. IS" },
  { id: "r36Wnm", label: "Podu Lacului - Jud. VN" },
  { id: "mgB7Gl", label: "Podu Lui Galben - Jud. PH" },
  { id: "Jr3k0r", label: "Podu Lui Paul - Jud. AB" },
  { id: "ZyY58Z", label: "Podu Lung - Jud. PH" },
  { id: "ZV1aRr", label: "Podu Muncii - Jud. BZ" },
  { id: "roYL8l", label: "Podu Narujei - Jud. VN" },
  { id: "lYD9RZ", label: "Podu Oltului - Jud. BV" },
  { id: "r99Jqr", label: "Podu Oprii - Jud. VS" },
  { id: "lxENzm", label: "Podu Petris - Jud. VS" },
  { id: "lOWkOl", label: "Podu Pitarului - Jud. CL" },
  { id: "lpEBYm", label: "Podu Popa Nae - Jud. GR" },
  { id: "mboyKr", label: "Podu Rizii - Jud. DB" },
  { id: "rE4vgm", label: "Podu Schiopului - Jud. VN" },
  { id: "maNner", label: "Podu Stoica - Jud. VN" },
  { id: "ZRjAyZ", label: "Podu Turcului - Jud. BC" },
  { id: "ZvE4xr", label: "Podu Ursului - Jud. PH" },
  { id: "rwEYal", label: "Podu Vadului - Jud. PH" },
  { id: "lDqEWm", label: "Podu Valeni - Jud. PH" },
  { id: "JlJQeZ", label: "Poduri - Jud. AB" },
  { id: "WmG6nl", label: "Poduri - Jud. AG" },
  { id: "rdjpzl", label: "Poduri - Jud. BC" },
  { id: "r6G2WZ", label: "Poduri - Jud. MS" },
  { id: "rK6zem", label: "Poduri - Jud. VN" },
  { id: "AZQ5JZ", label: "Poduri Bricesti - Jud. AB" },
  { id: "lDqWbm", label: "Podurile - Jud. PH" },
  { id: "r84dDr", label: "Podurile - Jud. VN" },
  { id: "mbo6Br", label: "Poenari - Jud. GR" },
  { id: "ZR6dAZ", label: "Poenari - Jud. VL" },
  { id: "ZQyNoZ", label: "Poeni - Jud. TR" },
  { id: "mbNy2m", label: "Poenile - Jud. VN" },
  { id: "r2bVpr", label: "Poenita - Jud. VL" },
  { id: "rBWRJZ", label: "Poenitele - Jud. BZ" },
  { id: "r9yJxr", label: "Pogaceaua - Jud. MS" },
  { id: "mGaA0Z", label: "Pogana - Jud. VS" },
  { id: "ZP7Xkr", label: "Poganesti - Jud. HD" },
  { id: "rXa1gZ", label: "Poganesti - Jud. TM" },
  { id: "ZQy05Z", label: "Poganesti - Jud. VS" },
  { id: "m1BGdr", label: "Poganu - Jud. OT" },
  { id: "mb9qQl", label: "Pogara - Jud. CS" },
  { id: "lLa0Xm", label: "Pogara De Sus - Jud. CS" },
  { id: "krwoxr", label: "Poglet - Jud. BC" },
  { id: "mgAL5Z", label: "Pogoanele - Jud. BZ" },
  { id: "mb98El", label: "Pogonele - Jud. BZ" },
  { id: "lpVkYr", label: "Pogonesti - Jud. VS" },
  { id: "lea6Jl", label: "Pogorasti - Jud. BT" },
  { id: "r6qxpm", label: "Poian - Jud. CV" },
  { id: "WmG36m", label: "Poiana - Jud. AB" },
  { id: "Lmn8XZ", label: "Poiana - Jud. AR" },
  { id: "mgPxBr", label: "Poiana - Jud. BC" },
  { id: "mAAK6m", label: "Poiana - Jud. BH" },
  { id: "r6bR5r", label: "Poiana - Jud. BT" },
  { id: "r970Ml", label: "Poiana - Jud. CS" },
  { id: "rd5W5Z", label: "Poiana - Jud. CT" },
  { id: "ZVLOKm", label: "Poiana - Jud. DB" },
  { id: "mkaa2Z", label: "Poiana - Jud. GJ" },
  { id: "lO6EOZ", label: "Poiana - Jud. GL" },
  { id: "rXq14Z", label: "Poiana - Jud. HD" },
  { id: "mnQaBl", label: "Poiana - Jud. IL" },
  { id: "ZyGJKm", label: "Poiana - Jud. IS" },
  { id: "ZMVQzl", label: "Poiana - Jud. MH" },
  { id: "ZQV04l", label: "Poiana - Jud. NT" },
  { id: "rKDPxZ", label: "Poiana - Jud. OT" },
  { id: "rKD6EZ", label: "Poiana - Jud. PH" },
  { id: "rKx2kZ", label: "Poiana - Jud. SV" },
  { id: "rKx6xZ", label: "Poiana - Jud. TR" },
  { id: "Z4ND0m", label: "Poiana - Jud. VL" },
  { id: "ZM60jl", label: "Poiana - Jud. VN" },
  { id: "ZQyWDZ", label: "Poiana - Jud. VS" },
  { id: "PrE4Lm", label: "Poiana Aiudului - Jud. AB" },
  { id: "krdpAr", label: "Poiana Ampoiului - Jud. AB" },
  { id: "r6ykWm", label: "Poiana Blenchii - Jud. SJ" },
  { id: "r3Bk5m", label: "Poiana Botizii - Jud. MM" },
  { id: "lJQ54Z", label: "Poiana Brasov - Jud. BV" },
  { id: "lxK4MZ", label: "Poiana Campina - Jud. PH" },
  { id: "ZyYLqZ", label: "Poiana Codrului - Jud. SM" },
  { id: "lOBNoZ", label: "Poiana Copaceni - Jud. PH" },
  { id: "ZPa1vl", label: "Poiana Cracaoani - Jud. NT" },
  { id: "Z4Nnpm", label: "Poiana Cristei - Jud. VN" },
  { id: "l7JEwZ", label: "Poiana Cu Cetate - Jud. IS" },
  { id: "r8O7Dm", label: "Poiana De Sus - Jud. IS" },
  { id: "l7J5wZ", label: "Poiana Fagului - Jud. HR" },
  { id: "ZPBzkm", label: "Poiana Fantanii - Jud. DJ" },
  { id: "rqzkQZ", label: "Poiana Fratii - Jud. CJ" },
  { id: "0lYJJm", label: "Poiana Galdei - Jud. AB" },
  { id: "r8BRKl", label: "Poiana Gruii - Jud. MH" },
  { id: "lYyE0l", label: "Poiana Horea - Jud. CJ" },
  { id: "ZzDv8m", label: "Poiana Humei - Jud. NT" },
  { id: "rXdo9r", label: "Poiana Ilvei - Jud. BN" },
  { id: "QmbMdl", label: "Poiana Lacului - Jud. AG" },
  { id: "l07YRZ", label: "Poiana Largului - Jud. NT" },
  { id: "ZNJqVZ", label: "Poiana Lui Alexa - Jud. VS" },
  { id: "lpEJbm", label: "Poiana Lui Stanga - Jud. GR" },
  { id: "Zzoy3l", label: "Poiana Lunga - Jud. CS" },
  { id: "lYGBAr", label: "Poiana Magura - Jud. SJ" },
  { id: "l5QE2l", label: "Poiana Manastirii - Jud. IS" },
  { id: "rqbMBZ", label: "Poiana Mare - Jud. DJ" },
  { id: "rBnWdr", label: "Poiana Mare - Jud. OT" },
  { id: "r3yWzl", label: "Poiana Mare - Jud. PH" },
  { id: "l7vanZ", label: "Poiana Marului - Jud. BV" },
  { id: "r2YB1m", label: "Poiana Marului - Jud. CS" },
  { id: "mka4nZ", label: "Poiana Marului - Jud. IS" },
  { id: "mgoPkm", label: "Poiana Marului - Jud. SV" },
  { id: "rXadpZ", label: "Poiana Micului - Jud. SV" },
  { id: "l7yRvr", label: "Poiana Mierlei - Jud. PH" },
  { id: "Z4980l", label: "Poiana Negrii - Jud. SV" },
  { id: "qZN5xr", label: "Poiana Negustorului - Jud. BC" },
  { id: "leGM6r", label: "Poiana Ontii - Jud. SJ" },
  { id: "Z41zAl", label: "Poiana Pietrei - Jud. VS" },
  { id: "lW1vRr", label: "Poiana Pletari - Jud. BZ" },
  { id: "rKnzem", label: "Poiana Rachitelii - Jud. HD" },
  { id: "rwnyam", label: "Poiana Sarata - Jud. BC" },
  { id: "mgLq7r", label: "Poiana Scheii - Jud. IS" },
  { id: "mboeqr", label: "Poiana Seciuri - Jud. GJ" },
  { id: "robNwm", label: "Poiana Sibiului - Jud. SB" },
  { id: "r9978r", label: "Poiana Stampei - Jud. SV" },
  { id: "ZPGMBr", label: "Poiana Stoichii - Jud. VN" },
  { id: "r3y65l", label: "Poiana Tapului - Jud. PH" },
  { id: "lD2WGr", label: "Poiana Tarnavei - Jud. HR" },
  { id: "mAAR8m", label: "Poiana Tasad - Jud. BH" },
  { id: "rdxDAr", label: "Poiana Teiului - Jud. NT" },
  { id: "rX72Rm", label: "Poiana Trestiei - Jud. PH" },
  { id: "OroGvr", label: "Poiana Ursului - Jud. AB" },
  { id: "3r2abm", label: "Poiana Vadului - Jud. AB" },
  { id: "rwp7KZ", label: "Poiana Valcului - Jud. BZ" },
  { id: "lj4LLr", label: "Poiana Varbilau - Jud. PH" },
  { id: "krwAQl", label: "Poienarei - Jud. AG" },
  { id: "BZ4Exl", label: "Poienari - Jud. AG" },
  { id: "JmAKBZ", label: "Poienari - Jud. AR" },
  { id: "lj9qBZ", label: "Poienari - Jud. GJ" },
  { id: "ZRKdNl", label: "Poienari - Jud. NT" },
  { id: "r3ybzl", label: "Poienari - Jud. SV" },
  { id: "lWMBAr", label: "Poienari - Jud. VL" },
  { id: "mGW3wm", label: "Poienarii Apostoli - Jud. PH" },
  { id: "lWDaYm", label: "Poienarii Burchii - Jud. PH" },
  { id: "l7ybXr", label: "Poienarii Rali - Jud. PH" },
  { id: "mGWv9m", label: "Poienarii Vechi - Jud. PH" },
  { id: "l79D3r", label: "Poienesti - Jud. VS" },
  { id: "rqOqPl", label: "Poienesti Deal - Jud. VS" },
  { id: "WmGv6l", label: "Poieni - Jud. AB" },
  { id: "lOwAnl", label: "Poieni - Jud. BC" },
  { id: "ZMRPGl", label: "Poieni - Jud. CJ" },
  { id: "Zzw4bl", label: "Poieni - Jud. HD" },
  { id: "rKnqkm", label: "Poieni - Jud. IS" },
  { id: "rBjqQr", label: "Poieni - Jud. NT" },
  { id: "ma6BDr", label: "Poieni - Jud. TM" },
  { id: "rEa75r", label: "Poieni Solca - Jud. SV" },
  { id: "mnpQjZ", label: "Poieni Suceava - Jud. SV" },
  { id: "rKj8Gm", label: "Poienii De Jos - Jud. BH" },
  { id: "lDAWnm", label: "Poienii De Sus - Jud. BH" },
  { id: "maLjel", label: "Poienile - Jud. BZ" },
  { id: "lLD0nl", label: "Poienile - Jud. DJ" },
  { id: "ZyGA2m", label: "Poienile - Jud. IS" },
  { id: "rBnvar", label: "Poienile - Jud. PH" },
  { id: "lLagYm", label: "Poienile Boinei - Jud. CS" },
  { id: "ZVdyRm", label: "Poienile De Sub Munte - Jud. MM" },
  { id: "lLV6Pr", label: "Poienile Izei - Jud. MM" },
  { id: "AZQDDr", label: "Poienile Mogos - Jud. AB" },
  { id: "rdxG2r", label: "Poienile Oancei - Jud. NT" },
  { id: "lp1M6m", label: "Poienile Zagrei - Jud. BN" },
  { id: "3ZV96r", label: "Poienita - Jud. AB" },
  { id: "Jr35Yl", label: "Poienita - Jud. AG" },
  { id: "rBOGJZ", label: "Poienita - Jud. DB" },
  { id: "lLDNGl", label: "Poienita - Jud. GJ" },
  { id: "Z43jzr", label: "Poienita - Jud. HD" },
  { id: "ZzDxbm", label: "Poienita - Jud. MS" },
  { id: "ma5DWm", label: "Poienita - Jud. SB" },
  { id: "r6yngm", label: "Poienita - Jud. SJ" },
  { id: "rK6K0m", label: "Poienita - Jud. VN" },
  { id: "r2Q5Am", label: "Poienita Tomii - Jud. HD" },
  { id: "ZM9zRZ", label: "Poienita Voinii - Jud. HD" },
  { id: "lOwGNl", label: "Poietari - Jud. BH" },
  { id: "krwDQZ", label: "Poiu - Jud. AB" },
  { id: "ZzgRnl", label: "Pojaru - Jud. GJ" },
  { id: "l08Dgl", label: "Pojejena - Jud. CS" },
  { id: "r66g2r", label: "Pojoga - Jud. HD" },
  { id: "lj9QNZ", label: "Pojogeni - Jud. GJ" },
  { id: "Zy55qZ", label: "Pojogi Cerna - Jud. VL" },
  { id: "ElLnXr", label: "Pojorata - Jud. AG" },
  { id: "ZMJR8l", label: "Pojorata - Jud. SV" },
  { id: "lea7gl", label: "Pojorta - Jud. BV" },
  { id: "lxbjDr", label: "Polata - Jud. GJ" },
  { id: "rwpQoZ", label: "Polcesti - Jud. CL" },
  { id: "lx8Dol", label: "Policiori - Jud. BZ" },
  { id: "lYyDQl", label: "Polizesti - Jud. BR" },
  { id: "lexbXZ", label: "Poloboc - Jud. NT" },
  { id: "mk1nOZ", label: "Polocin - Jud. VS" },
  { id: "lYKPRr", label: "Polonita - Jud. HR" },
  { id: "ZQGVdm", label: "Polovragi - Jud. GJ" },
  { id: "r82XKl", label: "Pomarla - Jud. BT" },
  { id: "mG109Z", label: "Pometesti - Jud. DJ" },
  { id: "rqP8Pm", label: "Pomezeu - Jud. BH" },
  { id: "Zzz2WZ", label: "Pomi - Jud. SM" },
  { id: "l54a0Z", label: "Poneasca - Jud. CS" },
  { id: "mgP3Er", label: "Ponoara - Jud. BH" },
  { id: "mga0AZ", label: "Ponoarele - Jud. MH" },
  { id: "3ZVO6l", label: "Ponor - Jud. AB" },
  { id: "lezvdm", label: "Ponor - Jud. HD" },
  { id: "krwjXm", label: "Ponorel - Jud. AB" },
  { id: "mG1o9Z", label: "Popanzalesti - Jud. DJ" },
  { id: "rwyLjl", label: "Popeasa - Jud. DJ" },
  { id: "XlDO1Z", label: "Popeni - Jud. BC" },
  { id: "Z4YbJl", label: "Popeni - Jud. BT" },
  { id: "lDqzJm", label: "Popeni - Jud. SJ" },
  { id: "lWvpyZ", label: "Popeni - Jud. VS" },
  { id: "WZPGMr", label: "Popesti - Jud. AB" },
  { id: "ElLAym", label: "Popesti - Jud. AG" },
  { id: "ZVKYol", label: "Popesti - Jud. BC" },
  { id: "ZQQgvZ", label: "Popesti - Jud. BH" },
  { id: "mkw4zm", label: "Popesti - Jud. CJ" },
  { id: "rE7Pgl", label: "Popesti - Jud. CL" },
  { id: "r6q15m", label: "Popesti - Jud. DJ" },
  { id: "rKnj9m", label: "Popesti - Jud. GJ" },
  { id: "maogeZ", label: "Popesti - Jud. GR" },
  { id: "ZQGn5m", label: "Popesti - Jud. HD" },
  { id: "l5QwAl", label: "Popesti - Jud. IS" },
  { id: "mbxvYZ", label: "Popesti - Jud. NT" },
  { id: "mnzEpm", label: "Popesti - Jud. OT" },
  { id: "mb5yal", label: "Popesti - Jud. PH" },
  { id: "maNXEr", label: "Popesti - Jud. VL" },
  { id: "Zy5b4Z", label: "Popesti - Jud. VN" },
  { id: "rwVJom", label: "Popesti - Jud. VS" },
  { id: "rBb3Jr", label: "Popesti Leordeni - Jud. IF" },
  { id: "r922MZ", label: "Popesti Stejari - Jud. GJ" },
  { id: "Jr3oVr", label: "Popestii De Jos - Jud. AB" },
  { id: "9m1oaZ", label: "Popestii De Sus - Jud. AB" },
  { id: "Zzz6EZ", label: "Poplaca - Jud. SB" },
  { id: "ljG1Lm", label: "Popoaia - Jud. BT" },
  { id: "rqPbQm", label: "Popoiu - Jud. BC" },
  { id: "mnxRXZ", label: "Popoveni - Jud. DJ" },
  { id: "l0Ne0r", label: "Popricani - Jud. IS" },
  { id: "ZvE3Vr", label: "Popteleac - Jud. SJ" },
  { id: "ZMJn5l", label: "Pordeanu - Jud. TM" },
  { id: "r3Bnem", label: "Poroina - Jud. MH" },
  { id: "mbx0oZ", label: "Poroina Mare - Jud. MH" },
  { id: "lDPWKl", label: "Poroinica - Jud. DB" },
  { id: "rXODpm", label: "Poroinita - Jud. MH" },
  { id: "ZQy8oZ", label: "Poroschia - Jud. TR" },
  { id: "lWDWRm", label: "Port - Jud. SJ" },
  { id: "lDKDWZ", label: "Portaresti - Jud. VL" },
  { id: "lxEdDm", label: "Portari - Jud. VS" },
  { id: "l0w1NZ", label: "Portita - Jud. SM" },
  { id: "mga9AZ", label: "Porumbac - Jud. MS" },
  { id: "ma5wDm", label: "Porumbacu De Jos - Jud. SB" },
  { id: "mnzwqm", label: "Porumbacu De Sus - Jud. SB" },
  { id: "rqEN9r", label: "Porumbeni - Jud. HR" },
  { id: "ZVdo6m", label: "Porumbeni - Jud. MS" },
  { id: "mG5n0Z", label: "Porumbenii - Jud. BN" },
  { id: "ZQGDgm", label: "Porumbenii Mari - Jud. HR" },
  { id: "r3Qvql", label: "Porumbenii Mici - Jud. HR" },
  { id: "leGWPr", label: "Porumbesti - Jud. SM" },
  { id: "lDqKYm", label: "Posada - Jud. PH" },
  { id: "AZQPDr", label: "Posaga De Jos - Jud. AB" },
  { id: "grK9Wl", label: "Posaga De Sus - Jud. AB" },
  { id: "rBnvDr", label: "Posestii Pamanteni - Jud. PH" },
  { id: "lOBvoZ", label: "Posestii Ungureni - Jud. PH" },
  { id: "lYDqAZ", label: "Posmus - Jud. BN" },
  { id: "lpzvMr", label: "Posobesti - Jud. BZ" },
  { id: "9m1XRl", label: "Posogani - Jud. AB" },
  { id: "l0pJNl", label: "Posoloaca - Jud. BH" },
  { id: "ljNgdl", label: "Posta - Jud. BZ" },
  { id: "mao4EZ", label: "Posta - Jud. GL" },
  { id: "rqbqPZ", label: "Posta - Jud. GR" },
  { id: "l5QxRl", label: "Posta - Jud. IF" },
  { id: "l577Nr", label: "Posta - Jud. MM" },
  { id: "rBRgQr", label: "Posta - Jud. TL" },
  { id: "ZMRKGl", label: "Posta Calnau - Jud. BZ" },
  { id: "r994yr", label: "Posta Elan - Jud. VS" },
  { id: "lWR3BZ", label: "Posta Veche - Jud. MH" },
  { id: "ZyNDYl", label: "Postarnacu - Jud. DB" },
  { id: "mnJDam", label: "Postavari - Jud. CL" },
  { id: "ZM9AQZ", label: "Potangeni - Jud. IS" },
  { id: "mG1wWZ", label: "Potarnichea - Jud. CT" },
  { id: "lW12qr", label: "Potarnichesti - Jud. BZ" },
  { id: "mkzYql", label: "Potau - Jud. SM" },
  { id: "ZRJkKZ", label: "Potcoava - Jud. CL" },
  { id: "rq0d9m", label: "Potcoava - Jud. OT" },
  { id: "ZQVbgl", label: "Potcoava Falcoeni - Jud. OT" },
  { id: "rE7Vql", label: "Potecu - Jud. BZ" },
  { id: "r6ybpm", label: "Potelu - Jud. OT" },
  { id: "rwEzyl", label: "Potigrafu - Jud. PH" },
  { id: "ZVwWxl", label: "Potingani - Jud. HD" },
  { id: "3r2Ypm", label: "Potionci - Jud. AB" },
  { id: "lpbVPr", label: "Potiond - Jud. HR" },
  { id: "rd7o4m", label: "Potlogeni - Jud. OT" },
  { id: "rEdqQm", label: "Potlogeni Deal - Jud. DB" },
  { id: "l0X30l", label: "Potlogeni Vale - Jud. DB" },
  { id: "lJPMWr", label: "Potlogi - Jud. DB" },
  { id: "rdVezl", label: "Potmeltu - Jud. DJ" },
  { id: "mkw2wm", label: "Potoc - Jud. CS" },
  { id: "ZvgdVl", label: "Potocelu - Jud. DB" },
  { id: "rwp7aZ", label: "Potoceni - Jud. BZ" },
  { id: "l07zJZ", label: "Potoci - Jud. NT" },
  { id: "r2wJgZ", label: "Potopinu - Jud. OT" },
  { id: "lDaoYm", label: "Povargina - Jud. TM" },
  { id: "mG550Z", label: "Pradais - Jud. BC" },
  { id: "mGgv2m", label: "Prahuda - Jud. VN" },
  { id: "rdo97m", label: "Praid - Jud. HR" },
  { id: "rdj5Wl", label: "Praja - Jud. BC" },
  { id: "r3y64l", label: "Prajani - Jud. PH" },
  { id: "l51bvl", label: "Prajeni - Jud. BT" },
  { id: "ma1x7m", label: "Prajesti - Jud. BC" },
  { id: "mA1p8m", label: "Prajesti - Jud. NT" },
  { id: "ZMJN5l", label: "Prajila - Jud. VL" },
  { id: "mbP9KZ", label: "Prajoaia - Jud. BC" },
  { id: "5l7Mqr", label: "Pralea - Jud. BC" },
  { id: "ZzVoYZ", label: "Praleni - Jud. SV" },
  { id: "lYxzOZ", label: "Prapor - Jud. DJ" },
  { id: "rqERQr", label: "Pravaleni - Jud. HD" },
  { id: "rKxaPZ", label: "Praxia - Jud. SV" },
  { id: "lJPgpr", label: "Preajba - Jud. DJ" },
  { id: "rBRxJr", label: "Preajba - Jud. TR" },
  { id: "rBO7QZ", label: "Preajba De Jos - Jud. DJ" },
  { id: "rqbodZ", label: "Preajba De Padure - Jud. DJ" },
  { id: "rEdJqm", label: "Preajba Mare - Jud. GJ" },
  { id: "lOWROl", label: "Preasna - Jud. CL" },
  { id: "Z4w6zr", label: "Preasna Veche - Jud. CL" },
  { id: "lYP7VZ", label: "Precistanu - Jud. VN" },
  { id: "ZVKbdl", label: "Predeal - Jud. BV" },
  { id: "mGWvnm", label: "Predeal - Jud. PH" },
  { id: "rXdznr", label: "Predelut - Jud. BV" },
  { id: "ZQqWDZ", label: "Predesti - Jud. DJ" },
  { id: "m1Bx6r", label: "Predesti - Jud. PH" },
  { id: "rqxEGm", label: "Predesti - Jud. VL" },
  { id: "rEdXMm", label: "Predestii Mici - Jud. DJ" },
  { id: "mG5VdZ", label: "Prejmer - Jud. BV" },
  { id: "rE6V6Z", label: "Prejna - Jud. MH" },
  { id: "lj9VdZ", label: "Prejoi - Jud. DJ" },
  { id: "mgB2El", label: "Prelipca - Jud. SV" },
  { id: "Qmb8oZ", label: "Preluca - Jud. AB" },
  { id: "r6657r", label: "Preluca - Jud. HR" },
  { id: "r3Bjzm", label: "Preluca - Jud. NT" },
  { id: "r3BG4m", label: "Preluca Noua - Jud. MM" },
  { id: "m1GD9Z", label: "Preluca Veche - Jud. MM" },
  { id: "r3k80r", label: "Prelucele - Jud. CJ" },
  { id: "WZPgKr", label: "Preluci - Jud. BC" },
  { id: "Z49O8l", label: "Preluci - Jud. SJ" },
  { id: "ma5vJm", label: "Preoteasa - Jud. SJ" },
  { id: "mb5Pdl", label: "Preotesti - Jud. OT" },
  { id: "r2MDem", label: "Preotesti - Jud. VL" },
  { id: "ZMG3pm", label: "Presaca - Jud. SB" },
  { id: "Bmaybm", label: "Presaca Ampoiului - Jud. AB" },
  { id: "rq0Ydm", label: "Preutesti - Jud. NT" },
  { id: "lxEKQm", label: "Preutesti - Jud. SV" },
  { id: "rwpWQZ", label: "Preveciori - Jud. CS" },
  { id: "l0wQ0Z", label: "Pria - Jud. SJ" },
  { id: "rdKDam", label: "Priba - Jud. VL" },
  { id: "lW1Bdr", label: "Pribeagu - Jud. BR" },
  { id: "leJ4Pm", label: "Pribesti - Jud. VS" },
  { id: "ZMVVpl", label: "Pribilesti - Jud. MM" },
  { id: "9m1NYr", label: "Priboaia - Jud. AG" },
  { id: "XlDg2Z", label: "Priboieni - Jud. AG" },
  { id: "lLVWXr", label: "Priboiesti - Jud. MH" },
  { id: "l7Qj3r", label: "Priboiu - Jud. DB" },
  { id: "ZPB3Km", label: "Priboiu - Jud. GR" },
  { id: "ZM915Z", label: "Pricaz - Jud. HD" },
  { id: "r8DgEr", label: "Prigor - Jud. CS" },
  { id: "lx7k0Z", label: "Prigoreni - Jud. IS" },
  { id: "r3QBAl", label: "Prigoria - Jud. GJ" },
  { id: "ZQG2pm", label: "Prihodiste - Jud. HD" },
  { id: "l08dBl", label: "Prilipet - Jud. CS" },
  { id: "l79avr", label: "Priloage - Jud. VL" },
  { id: "mAG52Z", label: "Prilog - Jud. SM" },
  { id: "ZNwOQl", label: "Prilog Vii - Jud. SM" },
  { id: "lxz7pr", label: "Pripoara - Jud. VL" },
  { id: "lp5j6m", label: "Pripoare - Jud. MS" },
  { id: "lxzAMr", label: "Pripoare - Jud. VL" },
  { id: "lDP91l", label: "Priponesti - Jud. GL" },
  { id: "lJPEYr", label: "Priponestii De Jos - Jud. GL" },
  { id: "rBOwbZ", label: "Priporu - Jud. GJ" },
  { id: "rB592m", label: "Priporu - Jud. VL" },
  { id: "9m1Lql", label: "Prisaca - Jud. BC" },
  { id: "leaeOl", label: "Prisaca - Jud. BH" },
  { id: "Zy24kl", label: "Prisaca - Jud. CS" },
  { id: "rX7O9m", label: "Prisaca - Jud. OT" },
  { id: "lDKRJZ", label: "Prisaca - Jud. VN" },
  { id: "rdKoGm", label: "Prisaca Dornei - Jud. SV" },
  { id: "rByLqm", label: "Prisacani - Jud. BT" },
  { id: "ZRwLql", label: "Prisacani - Jud. IS" },
  { id: "lxAJMm", label: "Prisaceaua - Jud. MH" },
  { id: "rXL0nr", label: "Prisacina - Jud. CS" },
  { id: "grK4GZ", label: "Priseaca - Jud. AG" },
  { id: "ZM7Jgm", label: "Priseaca - Jud. DB" },
  { id: "ZMGJjm", label: "Priseaca - Jud. OT" },
  { id: "rKDK9Z", label: "Priseaca - Jud. PH" },
  { id: "lL6Wyr", label: "Prisecani - Jud. VN" },
  { id: "mgA9EZ", label: "Prisian - Jud. CS" },
  { id: "Zy232l", label: "Prislop - Jud. CS" },
  { id: "ZNYGYr", label: "Prislop - Jud. MM" },
  { id: "rqpY0m", label: "Prislop - Jud. SB" },
  { id: "krwXvZ", label: "Prislopu Mare - Jud. AG" },
  { id: "wlpG2Z", label: "Prislopu Mic - Jud. AG" },
  { id: "lLVM1r", label: "Pristol - Jud. MH" },
  { id: "Zy6PKl", label: "Proaspeti - Jud. OT" },
  { id: "rdoORm", label: "Probota - Jud. IS" },
  { id: "m1B96r", label: "Probota - Jud. SV" },
  { id: "r36QAm", label: "Procopoaia - Jud. VL" },
  { id: "lxKooZ", label: "Prod - Jud. SB" },
  { id: "r3GPer", label: "Prodanesti - Jud. GL" },
  { id: "Z49yzl", label: "Prodanesti - Jud. SJ" },
  { id: "ZM6RGl", label: "Prodanesti - Jud. VL" },
  { id: "krwRXm", label: "Prodani - Jud. AG" },
  { id: "lYx2xZ", label: "Produlesti - Jud. DB" },
  { id: "lWDeNm", label: "Profa - Jud. OT" },
  { id: "lOWbAl", label: "Progresu - Jud. CL" },
  { id: "lJxkAr", label: "Progresu - Jud. IL" },
  { id: "lxxOnl", label: "Progresul - Jud. BT" },
  { id: "ZRjKAZ", label: "Prohozesti - Jud. BC" },
  { id: "lj8YBr", label: "Proieni - Jud. VL" },
  { id: "rwPwVZ", label: "Proitesti - Jud. MH" },
  { id: "lLaqGm", label: "Prosca - Jud. BZ" },
  { id: "mA4P2l", label: "Proselnici - Jud. IS" },
  { id: "4lO2wZ", label: "Prosia - Jud. AG" },
  { id: "lj8Okr", label: "Protopopesti - Jud. VS" },
  { id: "mkzJnl", label: "Provita De Jos - Jud. PH" },
  { id: "mnzNGm", label: "Provita De Sus - Jud. PH" },
  { id: "lj3Bwm", label: "Prunaru - Jud. MH" },
  { id: "rwVVXm", label: "Prunaru - Jud. TR" },
  { id: "ZvObPl", label: "Prundeni - Jud. VL" },
  { id: "r8YXXZ", label: "Prundu - Jud. GR" },
  { id: "rdKpRm", label: "Prundu - Jud. TR" },
  { id: "rKjbRm", label: "Prundu Bargaului - Jud. BN" },
  { id: "rodOwZ", label: "Pruneni - Jud. BZ" },
  { id: "maL42l", label: "Pruneni - Jud. CJ" },
  { id: "m1D93l", label: "Prunesti - Jud. GJ" },
  { id: "r3GY0r", label: "Prunet - Jud. DJ" },
  { id: "maLk2l", label: "Pruni - Jud. CJ" },
  { id: "ZyG0Ym", label: "Pruni - Jud. IF" },
  { id: "r8DNXr", label: "Prunis - Jud. CJ" },
  { id: "grKzxm", label: "Prunisor - Jud. AR" },
  { id: "ZzDWDm", label: "Prunisor - Jud. MH" },
  { id: "mAOM4Z", label: "Pucheni - Jud. DB" },
  { id: "lDqenm", label: "Puchenii Mari - Jud. PH" },
  { id: "rob6nm", label: "Puchenii Mici - Jud. PH" },
  { id: "ma59jm", label: "Puchenii Mosneni - Jud. PH" },
  { id: "lDPajl", label: "Pucioasa - Jud. DB" },
  { id: "ZQqyJZ", label: "Pucioasa Sat - Jud. DB" },
  { id: "lxzQDr", label: "Pufesti - Jud. VN" },
  { id: "rdoyqm", label: "Pui - Jud. HD" },
  { id: "ZM73Rm", label: "Puieni - Jud. GR" },
  { id: "rwVvam", label: "Puiesti - Jud. VS" },
  { id: "lDDQYl", label: "Puiestii De Jos - Jud. BZ" },
  { id: "ZQvPpl", label: "Puiestii De Sus - Jud. BZ" },
  { id: "ZNWqYl", label: "Puini - Jud. CJ" },
  { id: "arBvVr", label: "Puiuletesti - Jud. AB" },
  { id: "mb9NKl", label: "Punga - Jud. BZ" },
  { id: "rBRKgr", label: "Pungesti - Jud. VS" },
  { id: "rXOK7m", label: "Punghina - Jud. MH" },
  { id: "ZM7MRm", label: "Puntea De Greci - Jud. DB" },
  { id: "mGxjWr", label: "Puntea Lupului - Jud. HR" },
  { id: "m1bQgr", label: "Puntiseni - Jud. VS" },
  { id: "mkjvwl", label: "Pupezeni - Jud. GL" },
  { id: "ZyW74m", label: "Purani - Jud. TR" },
  { id: "ZyWD4m", label: "Puranii De Sus - Jud. TR" },
  { id: "NmkXwr", label: "Purcareni - Jud. AG" },
  { id: "ZQQdDZ", label: "Purcareni - Jud. BV" },
  { id: "r9DJam", label: "Purcaret - Jud. SJ" },
  { id: "lJQOgZ", label: "Purcarete - Jud. BN" },
  { id: "Qmbnvm", label: "Purcareti - Jud. AB" },
  { id: "l7JQXZ", label: "Purcaru - Jud. GJ" },
  { id: "m1DvRl", label: "Puricani - Jud. GL" },
  { id: "ZNw3Yl", label: "Puscasi - Jud. PH" },
  { id: "mA9Pgm", label: "Puscasi - Jud. VS" },
  { id: "ZVdqAm", label: "Puscasu - Jud. MH" },
  { id: "Oronwm", label: "Puselesti - Jud. AB" },
  { id: "l07QkZ", label: "Pusta - Jud. MS" },
  { id: "r2q8bm", label: "Pusta - Jud. SJ" },
  { id: "lp1bMm", label: "Pustiana - Jud. BC" },
  { id: "rXOPDm", label: "Pustieta - Jud. NT" },
  { id: "rKxoQZ", label: "Pustinis - Jud. TM" },
  { id: "ZyeakZ", label: "Pustoaia - Jud. BT" },
  { id: "l71zvr", label: "Pustuta - Jud. CJ" },
  { id: "grKp0Z", label: "Putina - Jud. AG" },
  { id: "lxbqMr", label: "Putinei - Jud. DJ" },
  { id: "Zy6E2l", label: "Putinei - Jud. MH" },
  { id: "l52bEm", label: "Putineiu - Jud. GR" },
  { id: "m1b43r", label: "Putineiu - Jud. TR" },
  { id: "rByjqm", label: "Putini - Jud. BC" },
  { id: "r97O8l", label: "Putna - Jud. CS" },
  { id: "rEaRor", label: "Putna - Jud. SV" },
  { id: "ZM6Wgl", label: "Putna - Jud. VN" },
  { id: "r9q1ar", label: "Putredeni - Jud. BC" },
  { id: "l7QR5r", label: "Putu Cu Salcie - Jud. DB" },
  { id: "rwydxl", label: "Putu Greci - Jud. GR" },
  { id: "m1byqr", label: "Putu Olarului - Jud. VS" },
  { id: "r9qzxr", label: "Putureni - Jud. BT" },
  { id: "mG1NwZ", label: "Puturi - Jud. DJ" },
  { id: "r33DGr", label: "Rabagani - Jud. BH" },
  { id: "JmAPKl", label: "Raca - Jud. AG" },
  { id: "ma1yDm", label: "Racaciuni - Jud. BC" },
  { id: "mbxRoZ", label: "Racamet - Jud. MS" },
  { id: "r3G0Vr", label: "Racari - Jud. DB" },
  { id: "rwy4yl", label: "Racarii De Sus - Jud. DJ" },
  { id: "mnP1GZ", label: "Racas - Jud. BH" },
  { id: "mkzBzl", label: "Racas - Jud. SJ" },
  { id: "l08gol", label: "Racasdia - Jud. CS" },
  { id: "rdo27m", label: "Racastia - Jud. HD" },
  { id: "2l5Q0l", label: "Racatau - Jud. AB" },
  { id: "rByyJm", label: "Racatau De Jos - Jud. BC" },
  { id: "rqPPgm", label: "Racatau Razesi - Jud. BC" },
  { id: "ZQQkoZ", label: "Racatesu - Jud. BN" },
  { id: "wmgG5m", label: "Racauti - Jud. BC" },
  { id: "ma639r", label: "Rachelu - Jud. TL" },
  { id: "ZyY42Z", label: "Rachieri - Jud. PH" },
  { id: "XlxG0Z", label: "Rachis - Jud. AB" },
  { id: "grK8kZ", label: "Rachita - Jud. AB" },
  { id: "rXLgpr", label: "Rachita - Jud. CS" },
  { id: "r2Madm", label: "Rachita - Jud. TM" },
  { id: "leoR3r", label: "Rachita De Jos - Jud. DJ" },
  { id: "ZQqXdZ", label: "Rachita De Sus - Jud. DJ" },
  { id: "r84RDr", label: "Rachitasu - Jud. VN" },
  { id: "rdoXRm", label: "Rachitaua - Jud. HD" },
  { id: "ljNOBl", label: "Rachitele - Jud. CJ" },
  { id: "WZP4AZ", label: "Rachitele De Jos - Jud. AG" },
  { id: "VrX4Dr", label: "Rachitele De Sus - Jud. AG" },
  { id: "rdowGm", label: "Rachiteni - Jud. IS" },
  { id: "lLeQWm", label: "Rachiti - Jud. BT" },
  { id: "ZvoAKl", label: "Rachiti - Jud. GJ" },
  { id: "rdjb4l", label: "Rachitis - Jud. BC" },
  { id: "rozxLm", label: "Rachitis - Jud. HR" },
  { id: "r3300r", label: "Rachitisu - Jud. BC" },
  { id: "Zv2DKZ", label: "Rachitoasa - Jud. BC" },
  { id: "mA8Ral", label: "Rachitosu - Jud. VN" },
  { id: "ZzoW8l", label: "Rachitova - Jud. CS" },
  { id: "maj3Wr", label: "Rachitova - Jud. HD" },
  { id: "l0NpPr", label: "Raci - Jud. GJ" },
  { id: "r3Gd5r", label: "Raciu - Jud. DB" },
  { id: "mbx4EZ", label: "Raciu - Jud. MS" },
  { id: "rE4Yqm", label: "Racoasa - Jud. VN" },
  { id: "rwno3m", label: "Racos - Jud. BV" },
  { id: "lxbb0r", label: "Racosul De Sus - Jud. CV" },
  { id: "Z4qLpl", label: "Racoti - Jud. GJ" },
  { id: "ropGwZ", label: "Racova - Jud. BC" },
  { id: "lexqXZ", label: "Racova - Jud. MH" },
  { id: "r3yLVl", label: "Racova - Jud. SM" },
  { id: "lDa2Gm", label: "Racova - Jud. SV" },
  { id: "ZzVqWZ", label: "Racova - Jud. VS" },
  { id: "Zv2kKZ", label: "Racovat - Jud. BT" },
  { id: "WmGnwl", label: "Racovita - Jud. AG" },
  { id: "ZNWGxl", label: "Racovita - Jud. BR" },
  { id: "rqbx1Z", label: "Racovita - Jud. DB" },
  { id: "lDPebl", label: "Racovita - Jud. DJ" },
  { id: "ZQGvnm", label: "Racovita - Jud. GJ" },
  { id: "lj43kr", label: "Racovita - Jud. OT" },
  { id: "ZvEaKr", label: "Racovita - Jud. SB" },
  { id: "Zvze5Z", label: "Racovita - Jud. TM" },
  { id: "ljobqm", label: "Racovita - Jud. VL" },
  { id: "rXaweZ", label: "Racovita - Jud. VS" },
  { id: "leLnJZ", label: "Racoviteni - Jud. BZ" },
  { id: "r3yMVl", label: "Racsa - Jud. SM" },
  { id: "l583YZ", label: "Racsa Vii - Jud. SM" },
  { id: "lJxw4r", label: "Racu - Jud. HR" },
  { id: "r9b2MZ", label: "Racu - Jud. VL" },
  { id: "lJQ2WZ", label: "Racusana - Jud. BC" },
  { id: "lYJgOm", label: "Radacinesti - Jud. VL" },
  { id: "rXxNer", label: "Radacinesti - Jud. VN" },
  { id: "r89LEZ", label: "Radaesti - Jud. VS" },
  { id: "mnJXpm", label: "Radaia - Jud. CJ" },
  { id: "ZMJGgl", label: "Radaseni - Jud. SV" },
  { id: "mkz3bl", label: "Radauti - Jud. SV" },
  { id: "l0pjRl", label: "Radauti Prut - Jud. BT" },
  { id: "r2VMdr", label: "Radeana - Jud. BC" },
  { id: "r6b72r", label: "Radeni - Jud. BT" },
  { id: "rE2xVZ", label: "Radeni - Jud. IS" },
  { id: "lexB1Z", label: "Radeni - Jud. NT" },
  { id: "rEazor", label: "Radeni - Jud. VS" },
  { id: "JlJMwZ", label: "Radesti - Jud. AB" },
  { id: "yZyaqZ", label: "Radesti - Jud. AG" },
  { id: "arB4Jl", label: "Radesti - Jud. AR" },
  { id: "mAO26Z", label: "Radesti - Jud. GL" },
  { id: "ZNwWxl", label: "Radesti - Jud. OT" },
  { id: "lOY4Jr", label: "Radesti - Jud. VS" },
  { id: "rKDzPZ", label: "Radila - Jud. PH" },
  { id: "rod9vZ", label: "Radimna - Jud. CS" },
  { id: "lLDonl", label: "Radinesti - Jud. GJ" },
  { id: "ZPADDl", label: "Radmanesti - Jud. TM" },
  { id: "Nmk0Or", label: "Radna - Jud. AR" },
  { id: "m1kD9Z", label: "Radoaia - Jud. BC" },
  { id: "ZNJ3VZ", label: "Radoiesti Deal - Jud. TR" },
  { id: "lYJ10m", label: "Radoiesti Vale - Jud. TR" },
  { id: "ZzgnEl", label: "Radomir - Jud. DJ" },
  { id: "ZNyy1r", label: "Radomiresti - Jud. BC" },
  { id: "leGJdr", label: "Radomiresti - Jud. OT" },
  { id: "mAO7WZ", label: "Radosi - Jud. GJ" },
  { id: "r3GOer", label: "Radovan - Jud. DJ" },
  { id: "lW1qNr", label: "Radovanu - Jud. CL" },
  { id: "olWqAr", label: "Radu Negru - Jud. AG" },
  { id: "mnJMAm", label: "Radu Negru - Jud. CL" },
  { id: "ZzoOWl", label: "Radu Voda - Jud. CL" },
  { id: "rdVw4l", label: "Radu Voda - Jud. GR" },
  { id: "roz08m", label: "Raducaneni - Jud. IS" },
  { id: "ZMJqyl", label: "Raducani - Jud. VS" },
  { id: "ZPeNkl", label: "Raducesti - Jud. BZ" },
  { id: "ZR6EKZ", label: "Raduiesti - Jud. VS" },
  { id: "mnQNpl", label: "Radulesti - Jud. HD" },
  { id: "lxKW0Z", label: "Radulesti - Jud. SM" },
  { id: "rdKNRm", label: "Radulesti - Jud. TR" },
  { id: "r36x5m", label: "Radulesti - Jud. VN" },
  { id: "Wr8Ler", label: "Radutesti - Jud. AG" },
  { id: "r9yjMr", label: "Radutesti - Jud. MH" },
  { id: "ZR6ODZ", label: "Rafaila - Jud. VS" },
  { id: "lOWowl", label: "Rafnic - Jud. CS" },
  { id: "l7yedr", label: "Rafov - Jud. PH" },
  { id: "lOwXAl", label: "Ragla - Jud. BN" },
  { id: "leGdJr", label: "Ragman - Jud. PH" },
  { id: "m1Do3l", label: "Ragu - Jud. DB" },
  { id: "QmbElO", label: "Rahau - Jud. AB" },
  { id: "l0KgRZ", label: "Rahman - Jud. TL" },
  { id: "lL7vPZ", label: "Rahova - Jud. PH" },
  { id: "JmA98m", label: "Raicani - Jud. AB" },
  { id: "rBjN5r", label: "Raiculesti - Jud. MH" },
  { id: "rK2Rkm", label: "Raitiu - Jud. OT" },
  { id: "mk1MwZ", label: "Raiu - Jud. VS" },
  { id: "ZPGJ3r", label: "Raiuti - Jud. VN" },
  { id: "JlJB4l", label: "Rajletu Govora - Jud. AG" },
  { id: "r3GjGr", label: "Ralesti - Jud. GR" },
  { id: "ZvOO3l", label: "Ramesti - Jud. VL" },
  { id: "0lY2Vm", label: "Ramet - Jud. AB" },
  { id: "7rq39l", label: "Rametea - Jud. AB" },
  { id: "l54gYZ", label: "Ramna - Jud. CS" },
  { id: "ZVkzJl", label: "Ramniceanca - Jud. VN" },
  { id: "r8DYMr", label: "Ramnicelu - Jud. BR" },
  { id: "lpz6xr", label: "Ramnicelu - Jud. BZ" },
  { id: "rB5vdm", label: "Ramniceni - Jud. VN" },
  { id: "ZvAR5r", label: "Ramnicu De Jos - Jud. CT" },
  { id: "ljNp4l", label: "Ramnicu De Sus - Jud. CT" },
  { id: "rBWbdZ", label: "Ramnicu Sarat - Jud. BZ" },
  { id: "ZVyjKr", label: "Ramnicu Valcea - Jud. VL" },
  { id: "Nmkvbl", label: "Rancaciov - Jud. AG" },
  { id: "lDPx1l", label: "Rancaciov - Jud. DB" },
  { id: "Z4161l", label: "Ranceni - Jud. VS" },
  { id: "lxEqMm", label: "Randunica - Jud. TL" },
  { id: "ZyeVLZ", label: "Ranghilesti - Jud. BT" },
  { id: "rKjGQm", label: "Ranghilesti Deal - Jud. BT" },
  { id: "rK270m", label: "Ranta - Jud. MS" },
  { id: "WmGo0m", label: "Ranusa - Jud. AR" },
  { id: "l0KPNZ", label: "Ranzesti - Jud. VS" },
  { id: "ZP2Qjm", label: "Rapa - Jud. BH" },
  { id: "rBOKgZ", label: "Rapa - Jud. GJ" },
  { id: "r9bDOZ", label: "Rapa Caramizii - Jud. VL" },
  { id: "lWRbqZ", label: "Rapa De Jos - Jud. MS" },
  { id: "leJD3m", label: "Rapanesti - Jud. VL" },
  { id: "lezOYm", label: "Rapas - Jud. HD" },
  { id: "rK3BRr", label: "Rapile - Jud. BZ" },
  { id: "rBb8gr", label: "Rapoltel - Jud. HD" },
  { id: "rozR5m", label: "Rapoltu Mare - Jud. HD" },
  { id: "rKxGeZ", label: "Rapsa - Jud. VS" },
  { id: "wlpDbl", label: "Rapsig - Jud. AR" },
  { id: "Z43kxr", label: "Rares - Jud. HR" },
  { id: "rdVb2l", label: "Raristea - Jud. CT" },
  { id: "ZQvoJl", label: "Rasa - Jud. CL" },
  { id: "lj52bZ", label: "Rasboieni - Jud. IS" },
  { id: "r6bLDr", label: "Rasca - Jud. BT" },
  { id: "ZQVJnl", label: "Rasca - Jud. NT" },
  { id: "leJGOm", label: "Rasca - Jud. SV" },
  { id: "lL65br", label: "Rasca - Jud. VN" },
  { id: "rKPKEZ", label: "Rascaeti - Jud. DB" },
  { id: "r89bPZ", label: "Rascani - Jud. VS" },
  { id: "ZQG7vm", label: "Rascoala - Jud. HD" },
  { id: "rK2KPm", label: "Rascolesti - Jud. MH" },
  { id: "mgAnqZ", label: "Rascruci - Jud. CJ" },
  { id: "l79kLr", label: "Rasesti - Jud. VS" },
  { id: "r3QOzl", label: "Rasi - Jud. IL" },
  { id: "mgL5Gr", label: "Rasimnicea - Jud. IL" },
  { id: "rX7zLm", label: "Rasinari - Jud. SB" },
  { id: "lOYNLr", label: "Rasmiresti - Jud. TR" },
  { id: "lO6qoZ", label: "Rasnicu Batran - Jud. DJ" },
  { id: "ZyN3Ll", label: "Rasnicu Oghian - Jud. DJ" },
  { id: "ZvzV9Z", label: "Rasnita - Jud. VS" },
  { id: "ZNyoar", label: "Rasnov - Jud. BV" },
  { id: "rBOO5Z", label: "Rasova - Jud. CT" },
  { id: "ron0qm", label: "Rasova - Jud. GJ" },
  { id: "l7JvvZ", label: "Rasovita - Jud. GJ" },
  { id: "m1D3Rl", label: "Rast - Jud. DJ" },
  { id: "rXdOgr", label: "Rastoaca - Jud. BC" },
  { id: "rdNX4l", label: "Rastoaca - Jud. VN" },
  { id: "mAGdgZ", label: "Rastoci - Jud. SJ" },
  { id: "mgaDDZ", label: "Rastolita - Jud. MS" },
  { id: "ZyYMYZ", label: "Rastolt - Jud. SJ" },
  { id: "lJ9kwZ", label: "Rastoltu Desert - Jud. SJ" },
  { id: "mkjDzl", label: "Rasuceni - Jud. GR" },
  { id: "lYyqQl", label: "Rasurile - Jud. CL" },
  { id: "lejGXm", label: "Ratalesti - Jud. VL" },
  { id: "9m1KRZ", label: "Ratesti - Jud. AG" },
  { id: "rBWjJZ", label: "Ratesti - Jud. BZ" },
  { id: "ZRx7qm", label: "Ratesti - Jud. DB" },
  { id: "rKDoWZ", label: "Ratesti - Jud. SM" },
  { id: "ZPA33l", label: "Ratesu Cuzei - Jud. VS" },
  { id: "r6632r", label: "Ratez - Jud. GJ" },
  { id: "ZPqnKl", label: "Ratin - Jud. SJ" },
  { id: "arBbar", label: "Ratitis - Jud. AB" },
  { id: "mgBekl", label: "Ratiu - Jud. SM" },
  { id: "ZQqwJZ", label: "Ratoaia - Jud. DB" },
  { id: "arBpVl", label: "Ratoi - Jud. AG" },
  { id: "ZvEV2r", label: "Ratovei - Jud. SJ" },
  { id: "lYx8AZ", label: "Rau Alb - Jud. DB" },
  { id: "rXqggZ", label: "Rau Alb - Jud. HD" },
  { id: "mAOnBZ", label: "Rau Alb De Jos - Jud. DB" },
  { id: "ZNGx1l", label: "Rau Alb De Sus - Jud. DB" },
  { id: "lpb96r", label: "Rau Barbat - Jud. HD" },
  { id: "lj5AdZ", label: "Rau De Mori - Jud. HD" },
  { id: "Z437Qr", label: "Rau Mic - Jud. HD" },
  { id: "lj421r", label: "Rau Sadului - Jud. SB" },
  { id: "mGaVwZ", label: "Rau Vadului - Jud. VL" },
  { id: "roEKvl", label: "Raucesti - Jud. NT" },
  { id: "roxKqZ", label: "Raureni - Jud. VL" },
  { id: "ZRjpvZ", label: "Rauseni - Jud. BT" },
  { id: "lDA82m", label: "Rausor - Jud. BV" },
  { id: "ZQyVgZ", label: "Rauteni - Jud. SV" },
  { id: "lDa0Xm", label: "Rauti - Jud. TM" },
  { id: "ma5PWm", label: "Ravasel - Jud. SB" },
  { id: "ZzoLYl", label: "Ravensca - Jud. CS" },
  { id: "VleoJr", label: "Ravicesti - Jud. AB" },
  { id: "rE2G5Z", label: "Razboieni - Jud. IS" },
  { id: "maxEbl", label: "Razboieni - Jud. NT" },
  { id: "ZR6XvZ", label: "Razboieni - Jud. TL" },
  { id: "QmbPEZ", label: "Razboieni Cetate - Jud. AB" },
  { id: "lLVQXr", label: "Razboienii De Jos - Jud. NT" },
  { id: "ZvAXVr", label: "Razbuneni - Jud. CJ" },
  { id: "mgPjqr", label: "Razesu - Jud. BC" },
  { id: "ZQvJpl", label: "Razoare - Jud. CJ" },
  { id: "lj3NNm", label: "Razoare - Jud. MM" },
  { id: "rBj7gr", label: "Razoare - Jud. MS" },
  { id: "mAbk6r", label: "Razoarele - Jud. CL" },
  { id: "r27VQZ", label: "Razoarele - Jud. CT" },
  { id: "r8YW6Z", label: "Razvad - Jud. DB" },
  { id: "rqzo9Z", label: "Razvani - Jud. CL" },
  { id: "ZvOj9l", label: "Rebegari - Jud. VN" },
  { id: "r2Vjer", label: "Rebra - Jud. BN" },
  { id: "ZQyjoZ", label: "Rebricea - Jud. VS" },
  { id: "ZVKBKl", label: "Rebrisoara - Jud. BN" },
  { id: "lOYMnr", label: "Recas - Jud. TM" },
  { id: "EZzdWm", label: "Recea - Jud. AG" },
  { id: "ZQQQ5Z", label: "Recea - Jud. BC" },
  { id: "rByw2m", label: "Recea - Jud. BV" },
  { id: "ZV1dor", label: "Recea - Jud. BZ" },
  { id: "lj5DqZ", label: "Recea - Jud. HR" },
  { id: "ZP7vjr", label: "Recea - Jud. IS" },
  { id: "lLV5Yr", label: "Recea - Jud. MH" },
  { id: "ZzDzNm", label: "Recea - Jud. MM" },
  { id: "lOAzOr", label: "Recea - Jud. MS" },
  { id: "lYnAXm", label: "Recea - Jud. NT" },
  { id: "lxKAPZ", label: "Recea - Jud. OT" },
  { id: "r9D8ym", label: "Recea - Jud. SJ" },
  { id: "mGa82Z", label: "Recea - Jud. VS" },
  { id: "leLyJZ", label: "Recea Cristur - Jud. CJ" },
  { id: "mgBbBl", label: "Recea Mica - Jud. SJ" },
  { id: "r9n9dZ", label: "Reci - Jud. CV" },
  { id: "rKjd9m", label: "Recia Verbia - Jud. BT" },
  { id: "Xl0KRZ", label: "Reciu - Jud. AB" },
  { id: "Wr62Xr", label: "Redea - Jud. AG" },
  { id: "lpAV6m", label: "Redea - Jud. OT" },
  { id: "ZV3L6l", label: "Redisoara - Jud. OT" },
  { id: "lp1Bxm", label: "Rediu - Jud. BT" },
  { id: "l08qQl", label: "Rediu - Jud. CJ" },
  { id: "mG1L2Z", label: "Rediu - Jud. GL" },
  { id: "lYK9Vr", label: "Rediu - Jud. IS" },
  { id: "Zv583r", label: "Rediu - Jud. NT" },
  { id: "lxEoPm", label: "Rediu - Jud. VS" },
  { id: "rwDAvZ", label: "Rediu Aldei - Jud. IS" },
  { id: "lpVnor", label: "Rediu Galian - Jud. VS" },
  { id: "rKnJkm", label: "Rediu Mitropoliei - Jud. IS" },
  { id: "lx7anZ", label: "Reea - Jud. HD" },
  { id: "m1kYaZ", label: "Reghea - Jud. BH" },
  { id: "ZQVBgl", label: "Reghin - Jud. MS" },
  { id: "ZM6wjl", label: "Reghiu - Jud. VN" },
  { id: "l077JZ", label: "Remecioara - Jud. MM" },
  { id: "AZvD3m", label: "Remetea - Jud. AB" },
  { id: "m1k0qZ", label: "Remetea - Jud. BH" },
  { id: "maj8Nr", label: "Remetea - Jud. HR" },
  { id: "rq0v9m", label: "Remetea - Jud. MS" },
  { id: "lJ2NAl", label: "Remetea Chioarului - Jud. MM" },
  { id: "lpV42r", label: "Remetea Lunca - Jud. TM" },
  { id: "ZPAKAl", label: "Remetea Mare - Jud. TM" },
  { id: "rEa0xr", label: "Remetea Mica - Jud. TM" },
  { id: "l0wGoZ", label: "Remetea Oasului - Jud. SM" },
  { id: "ljNeLl", label: "Remetea Poganici - Jud. CS" },
  { id: "rqPaGm", label: "Remeti - Jud. BH" },
  { id: "lYnJwm", label: "Remeti - Jud. MM" },
  { id: "r3BQ4m", label: "Remeti Pe Somes - Jud. MM" },
  { id: "lxb2wr", label: "Remus - Jud. GR" },
  { id: "rBWAaZ", label: "Remus Opreanu - Jud. CT" },
  { id: "lODMOm", label: "Renghet - Jud. HD" },
  { id: "mA19om", label: "Repedea - Jud. MM" },
  { id: "mA8Mgl", label: "Repedea - Jud. VN" },
  { id: "rKj69m", label: "Reprivat - Jud. BC" },
  { id: "ZVdDxm", label: "Resca - Jud. OT" },
  { id: "lJ25Yl", label: "Rescuta - Jud. OT" },
  { id: "lL7X1Z", label: "Resighea - Jud. SM" },
  { id: "lOWV3l", label: "Resita - Jud. CS" },
  { id: "rK37Pr", label: "Resita Mica - Jud. CS" },
  { id: "rE2wgZ", label: "Ret - Jud. HD" },
  { id: "mbP1BZ", label: "Reteag - Jud. BN" },
  { id: "JmAEKm", label: "Retevoiesti - Jud. AG" },
  { id: "majYjr", label: "Retezatu - Jud. IL" },
  { id: "r86b0l", label: "Retis - Jud. SB" },
  { id: "l79Jnr", label: "Reuseni - Jud. SV" },
  { id: "r30qzZ", label: "Revarsarea - Jud. TL" },
  { id: "LZMwRm", label: "Revetis - Jud. AR" },
  { id: "lD2jXr", label: "Reviga - Jud. IL" },
  { id: "lYKkxr", label: "Ribicioara - Jud. HD" },
  { id: "Zvowwl", label: "Ribita - Jud. HD" },
  { id: "ZQa0aZ", label: "Richis - Jud. SB" },
  { id: "lOwgAl", label: "Rieni - Jud. BH" },
  { id: "ZVdRJm", label: "Rigmani - Jud. MS" },
  { id: "rdjG2l", label: "Ripiceni - Jud. BT" },
  { id: "ZP2pMm", label: "Ripicenii Vechi - Jud. BT" },
  { id: "rozL8m", label: "Risca - Jud. HD" },
  { id: "majner", label: "Risculita - Jud. HD" },
  { id: "lWBLYZ", label: "Roades - Jud. BV" },
  { id: "rBnzbr", label: "Roandola - Jud. SB" },
  { id: "l0Xj6l", label: "Roata De Jos - Jud. GR" },
  { id: "rBOzJZ", label: "Roata Mica - Jud. GR" },
  { id: "yZyk2m", label: "Robaia - Jud. AG" },
  { id: "mA9QWm", label: "Robaia - Jud. VL" },
  { id: "mkjW2l", label: "Robanestii De Jos - Jud. DJ" },
  { id: "rwyeQl", label: "Robanestii De Sus - Jud. DJ" },
  { id: "lW19Ar", label: "Robeasca - Jud. BZ" },
  { id: "krwzXm", label: "Robesti - Jud. AB" },
  { id: "ZRJMAZ", label: "Robesti - Jud. BZ" },
  { id: "rwVoym", label: "Robesti - Jud. VL" },
  { id: "VrX6el", label: "Rociu - Jud. AG" },
  { id: "m1GK6Z", label: "Rocna - Jud. NT" },
  { id: "lexP1Z", label: "Rocsoreni - Jud. MH" },
  { id: "lj411r", label: "Rod - Jud. SB" },
  { id: "mG52WZ", label: "Rodbav - Jud. BV" },
  { id: "lD6DJr", label: "Rodina - Jud. MM" },
  { id: "lJQLWZ", label: "Rodna - Jud. BN" },
  { id: "ZPGObr", label: "Roesti - Jud. VL" },
  { id: "ZNw4Vl", label: "Rogna - Jud. SJ" },
  { id: "arBwJZ", label: "Rogoaza - Jud. BC" },
  { id: "ZQvpal", label: "Rogojel - Jud. CJ" },
  { id: "ZVwYWl", label: "Rogojel - Jud. GJ" },
  { id: "ZPB0Bm", label: "Rogojeni - Jud. GJ" },
  { id: "mboVdr", label: "Rogojeni - Jud. GL" },
  { id: "mG5B9Z", label: "Rogojesti - Jud. BT" },
  { id: "JmAooZ", label: "Rogojina - Jud. AG" },
  { id: "lxARzm", label: "Rogova - Jud. MH" },
  { id: "3ZV1Rr", label: "Rogoz - Jud. AB" },
  { id: "m1k5gZ", label: "Rogoz - Jud. BH" },
  { id: "ZPaeBl", label: "Rogoz - Jud. MM" },
  { id: "AZvQVr", label: "Rogoz De Beliu - Jud. AR" },
  { id: "ZyeE2Z", label: "Rohani - Jud. BH" },
  { id: "r6GO6Z", label: "Rohia - Jud. MM" },
  { id: "rBy42m", label: "Roit - Jud. BH" },
  { id: "rqbWGZ", label: "Rojiste - Jud. DJ" },
  { id: "r9q4Or", label: "Roma - Jud. BT" },
  { id: "lJ2Eel", label: "Roman - Jud. NT" },
  { id: "0lY5El", label: "Romana - Jud. AG" },
  { id: "rBjA5r", label: "Romana - Jud. OT" },
  { id: "r9DkXm", label: "Romanasi - Jud. SJ" },
  { id: "4lO0Am", label: "Romanesti - Jud. BC" },
  { id: "mgPq1r", label: "Romanesti - Jud. BT" },
  { id: "m1D4dl", label: "Romanesti - Jud. DB" },
  { id: "mbo1Qr", label: "Romanesti - Jud. DJ" },
  { id: "ZM7djm", label: "Romanesti - Jud. GJ" },
  { id: "majQer", label: "Romanesti - Jud. IS" },
  { id: "r8BY6l", label: "Romanesti - Jud. MM" },
  { id: "r86K6l", label: "Romanesti - Jud. PH" },
  { id: "lOB8KZ", label: "Romanesti - Jud. SB" },
  { id: "mnz8jm", label: "Romanesti - Jud. SM" },
  { id: "rKxjPZ", label: "Romanesti - Jud. SV" },
  { id: "lxEW7m", label: "Romanesti - Jud. TM" },
  { id: "lpxAPl", label: "Romanesti - Jud. VL" },
  { id: "ZM6nyl", label: "Romanesti - Jud. VN" },
  { id: "mbPDQZ", label: "Romanesti Vale - Jud. BT" },
  { id: "lj3Qwm", label: "Romani - Jud. NT" },
  { id: "Z41LQl", label: "Romani - Jud. VL" },
  { id: "ZMJP5l", label: "Romanii De Jos - Jud. VL" },
  { id: "leJyYm", label: "Romanii De Sus - Jud. VL" },
  { id: "l542AZ", label: "Romanu - Jud. BR" },
  { id: "ma52Jm", label: "Romita - Jud. SJ" },
  { id: "ZP7Ykr", label: "Romos - Jud. HD" },
  { id: "l0N5kr", label: "Romosel - Jud. HD" },
  { id: "lYD3xZ", label: "Romuli - Jud. BN" },
  { id: "r3y5Gl", label: "Rona - Jud. SJ" },
  { id: "ZNYJLr", label: "Rona De Jos - Jud. MM" },
  { id: "r8B9Vl", label: "Rona De Sus - Jud. MM" },
  { id: "mAA02m", label: "Rontau - Jud. BH" },
  { id: "ZVdOBm", label: "Rora - Jud. MS" },
  { id: "l7QLwr", label: "Roscani - Jud. GL" },
  { id: "lD2eKr", label: "Roscani - Jud. HD" },
  { id: "ZM93QZ", label: "Roscani - Jud. IS" },
  { id: "l58z0Z", label: "Roscani - Jud. SV" },
  { id: "lDKB1Z", label: "Roscari - Jud. VN" },
  { id: "ZRK2vl", label: "Roseni - Jud. NT" },
  { id: "JlJWAm", label: "Rosesti - Jud. AB" },
  { id: "ZNWLxl", label: "Roseti - Jud. CL" },
  { id: "Vleeol", label: "Rosia - Jud. AR" },
  { id: "ZyeLYZ", label: "Rosia - Jud. BH" },
  { id: "lx7ewZ", label: "Rosia - Jud. HD" },
  { id: "lexE1Z", label: "Rosia - Jud. MH" },
  { id: "ZPqvvl", label: "Rosia - Jud. SB" },
  { id: "lDaObm", label: "Rosia - Jud. VL" },
  { id: "m1qBjm", label: "Rosia De Amaradia - Jud. GJ" },
  { id: "qZN7xl", label: "Rosia De Secas - Jud. AB" },
  { id: "lJxjer", label: "Rosia Jiu - Jud. GJ" },
  { id: "JmAMam", label: "Rosia Montana - Jud. AB" },
  { id: "0lYoAr", label: "Rosia Noua - Jud. AR" },
  { id: "r3kj4r", label: "Rosieni - Jud. CJ" },
  { id: "r8Yk0Z", label: "Rosieni - Jud. DJ" },
  { id: "ZR3QKl", label: "Rosienii Mari - Jud. OT" },
  { id: "rd7b7m", label: "Rosienii Mici - Jud. OT" },
  { id: "r3045Z", label: "Rosiesti - Jud. VS" },
  { id: "r65V3m", label: "Rosiile - Jud. VL" },
  { id: "ZNJ6YZ", label: "Rosioara - Jud. VL" },
  { id: "rK6Wxm", label: "Rosioara - Jud. VN" },
  { id: "ljGn1m", label: "Rosiori - Jud. BC" },
  { id: "rXde7r", label: "Rosiori - Jud. BH" },
  { id: "l08X0l", label: "Rosiori - Jud. BR" },
  { id: "ZzEJEr", label: "Rosiori - Jud. BT" },
  { id: "rqEMGr", label: "Rosiori - Jud. IL" },
  { id: "mgadkZ", label: "Rosiori - Jud. MH" },
  { id: "lp54om", label: "Rosiori - Jud. MS" },
  { id: "l7wEYl", label: "Rosiori - Jud. NT" },
  { id: "lL7KyZ", label: "Rosiori - Jud. SM" },
  { id: "rox2LZ", label: "Rosiori - Jud. SV" },
  { id: "mgoDAm", label: "Rosiori - Jud. VS" },
  { id: "lYJGQm", label: "Rosiori De Vede - Jud. TR" },
  { id: "ZQvyol", label: "Rosioru - Jud. BZ" },
  { id: "rqb5oZ", label: "Rosiuta - Jud. GJ" },
  { id: "rB5Oam", label: "Rosoveni - Jud. VL" },
  { id: "BmaGJZ", label: "Rostoci - Jud. AR" },
  { id: "rozyXm", label: "Rosu - Jud. IF" },
  { id: "r66R3r", label: "Rosu - Jud. IS" },
  { id: "l7y4Xr", label: "Rosu - Jud. SV" },
  { id: "ZM69zl", label: "Rotarasti - Jud. VL" },
  { id: "m1Bd6r", label: "Rotarea - Jud. PH" },
  { id: "lOwzJl", label: "Rotaresti - Jud. BH" },
  { id: "l0KvQZ", label: "Rotaresti - Jud. TR" },
  { id: "lDqQbm", label: "Rotari - Jud. PH" },
  { id: "rqOqgl", label: "Rotari - Jud. VS" },
  { id: "ropd3Z", label: "Rotaria - Jud. BC" },
  { id: "rqEXdr", label: "Rotaria - Jud. IS" },
  { id: "m1k9RZ", label: "Rotbav - Jud. BV" },
  { id: "ZNYjyr", label: "Roteni - Jud. MS" },
  { id: "r36RVm", label: "Rotilestii Mari - Jud. VN" },
  { id: "m18Ral", label: "Rotilestii Mici - Jud. VN" },
  { id: "lJNQzl", label: "Rotopanesti - Jud. SV" },
  { id: "olW4om", label: "Rotunda - Jud. AG" },
  { id: "ZMRaRl", label: "Rotunda - Jud. CJ" },
  { id: "lp5kxm", label: "Rotunda - Jud. NT" },
  { id: "lWDvym", label: "Rotunda - Jud. OT" },
  { id: "l0weBZ", label: "Rotunda - Jud. SV" },
  { id: "mGP02r", label: "Roua - Jud. MS" },
  { id: "maj02r", label: "Rovina - Jud. HD" },
  { id: "mAOyBZ", label: "Rovinari - Jud. GJ" },
  { id: "lDPMYl", label: "Rovine - Jud. DJ" },
  { id: "rwDMQZ", label: "Rovine - Jud. IL" },
  { id: "rwVWym", label: "Rovinita Mare - Jud. TM" },
  { id: "rBRMbr", label: "Rovinita Mica - Jud. TM" },
  { id: "l579pr", label: "Rozavlea - Jud. MM" },
  { id: "rdxEqr", label: "Roznov - Jud. NT" },
  { id: "rBWNbZ", label: "Rubla - Jud. BZ" },
  { id: "BZ4LAZ", label: "Rucar - Jud. AG" },
  { id: "rKjAkm", label: "Rucar - Jud. BV" },
  { id: "rqxvgm", label: "Rucareni - Jud. VN" },
  { id: "lD2eJr", label: "Ruda - Jud. HD" },
  { id: "r6xE2l", label: "Ruda - Jud. VL" },
  { id: "lJxyYr", label: "Ruda Brad - Jud. HD" },
  { id: "lDqaJm", label: "Rudari - Jud. OT" },
  { id: "Wr6R3r", label: "Rudeni - Jud. AG" },
  { id: "ZQGdvm", label: "Rudeni - Jud. IF" },
  { id: "rqOJdl", label: "Rudesti - Jud. SV" },
  { id: "rqO70l", label: "Rudicica - Jud. TM" },
  { id: "r9yb3r", label: "Rudina - Jud. MH" },
  { id: "rBR4br", label: "Rudna - Jud. TM" },
  { id: "l5QvYl", label: "Ruganesti - Jud. HR" },
  { id: "l0KY6Z", label: "Rugaria - Jud. VS" },
  { id: "mAb2Br", label: "Rugasesti - Jud. CJ" },
  { id: "lYDVXZ", label: "Rugea - Jud. BH" },
  { id: "ZNAWLr", label: "Ruget - Jud. GJ" },
  { id: "ZNDz1m", label: "Ruget - Jud. VN" },
  { id: "rK6xWm", label: "Rugetu - Jud. VL" },
  { id: "l54q3Z", label: "Rugi - Jud. CS" },
  { id: "rBbbar", label: "Rugi - Jud. GJ" },
  { id: "l7wNYl", label: "Ruginesti - Jud. NT" },
  { id: "lejedm", label: "Ruginesti - Jud. VN" },
  { id: "VrXp7r", label: "Ruginoasa - Jud. AG" },
  { id: "ZMRGRl", label: "Ruginoasa - Jud. BZ" },
  { id: "ZvoP9l", label: "Ruginoasa - Jud. IS" },
  { id: "l7wddl", label: "Ruginoasa - Jud. NT" },
  { id: "rqpGPm", label: "Ruginoasa - Jud. SJ" },
  { id: "mGwdpm", label: "Ruginosu - Jud. CS" },
  { id: "rBnEqr", label: "Ruja - Jud. SB" },
  { id: "7rqj1Z", label: "Runc - Jud. AB" },
  { id: "r92VqZ", label: "Runc - Jud. HR" },
  { id: "lODPLm", label: "Runcsor - Jud. HD" },
  { id: "7rqdPm", label: "Runcu - Jud. BC" },
  { id: "rd5LaZ", label: "Runcu - Jud. BZ" },
  { id: "Zvg2xl", label: "Runcu - Jud. CT" },
  { id: "ZNG7Yl", label: "Runcu - Jud. DB" },
  { id: "lODBwm", label: "Runcu - Jud. GJ" },
  { id: "mkakzZ", label: "Runcu - Jud. IF" },
  { id: "l0NLNr", label: "Runcu - Jud. IS" },
  { id: "mkzQ2l", label: "Runcu - Jud. SV" },
  { id: "r9bVqZ", label: "Runcu - Jud. VL" },
  { id: "rE2YqZ", label: "Runcu Mare - Jud. HD" },
  { id: "mAGA6Z", label: "Runcu Mare - Jud. OT" },
  { id: "rKnX9m", label: "Runcu Mic - Jud. HD" },
  { id: "rwnOxm", label: "Runcu Salvei - Jud. BN" },
  { id: "rqEPvr", label: "Runcurel - Jud. GJ" },
  { id: "7rqEGr", label: "Runcuri - Jud. AB" },
  { id: "mgaJGZ", label: "Runcusoru - Jud. MH" },
  { id: "r827Kl", label: "Rupea - Jud. BV" },
  { id: "lp56pm", label: "Ruptura - Jud. MH" },
  { id: "rwyaQl", label: "Rupturile - Jud. DJ" },
  { id: "lj35Bm", label: "Rus - Jud. MM" },
  { id: "mgBk9l", label: "Rus - Jud. SJ" },
  { id: "mkz1Pl", label: "Rusanesti - Jud. OT" },
  { id: "ZVkYRl", label: "Rusanesti - Jud. VL" },
  { id: "ZPqa3l", label: "Rusanestii De Sus - Jud. OT" },
  { id: "l54opZ", label: "Rusavat - Jud. BZ" },
  { id: "mkwYwm", label: "Rusca - Jud. CS" },
  { id: "lpAOPm", label: "Rusca - Jud. SV" },
  { id: "lj8kKr", label: "Rusca - Jud. VS" },
  { id: "rd5kGZ", label: "Rusca Montana - Jud. CS" },
  { id: "lx8W0l", label: "Ruschita - Jud. CS" },
  { id: "ZRKL2l", label: "Rusciori - Jud. OT" },
  { id: "rd7Gzm", label: "Rusciori - Jud. SB" },
  { id: "l07KPZ", label: "Ruscova - Jud. MM" },
  { id: "rdxg2r", label: "Ruseni - Jud. NT" },
  { id: "lj4Jqr", label: "Ruseni - Jud. SM" },
  { id: "rwnDKm", label: "Rusenii De Sus - Jud. BC" },
  { id: "lj5kwZ", label: "Rusenii Noi - Jud. IS" },
  { id: "rBybqm", label: "Rusenii Razesi - Jud. BC" },
  { id: "ZP7VEr", label: "Rusenii Vechi - Jud. IS" },
  { id: "0lYywl", label: "Rusesti - Jud. AB" },
  { id: "mbO0Br", label: "Rusesti - Jud. HD" },
  { id: "mkw6bm", label: "Rusetu - Jud. BZ" },
  { id: "4lOWKl", label: "Rusi - Jud. AB" },
  { id: "ZyGp4m", label: "Rusi - Jud. HD" },
  { id: "rqpYvm", label: "Rusi - Jud. SB" },
  { id: "ma6J9r", label: "Rusi - Jud. SV" },
  { id: "ZQy15Z", label: "Rusi - Jud. VS" },
  { id: "r822Xl", label: "Rusi Ciutea - Jud. BC" },
  { id: "mGaxdZ", label: "Rusii Manastioara - Jud. SV" },
  { id: "lLV4nr", label: "Rusii Munti - Jud. MS" },
  { id: "lWOoyl", label: "Rusor - Jud. HD" },
  { id: "lOA6nr", label: "Rusor - Jud. MM" },
  { id: "r3kazr", label: "Rusova Noua - Jud. CS" },
  { id: "m1nx6r", label: "Rusova Veche - Jud. CS" },
  { id: "lx81Ml", label: "Rustin - Jud. CS" },
  { id: "ZzEOBr", label: "Rustior - Jud. BN" },
  { id: "Zyev4Z", label: "Rusu Bargaului - Jud. BN" },
  { id: "mk82Or", label: "Rusu De Jos - Jud. BN" },
  { id: "r6bJ9r", label: "Rusu De Sus - Jud. BN" },
  { id: "mGanWZ", label: "Sabangia - Jud. TL" },
  { id: "r6GX7Z", label: "Sabaoani - Jud. NT" },
  { id: "r3G8nr", label: "Sabareni - Jud. GR" },
  { id: "lLVBPr", label: "Sabasa - Jud. NT" },
  { id: "lJ2owl", label: "Sabed - Jud. MS" },
  { id: "ZVLyxm", label: "Sabiesti - Jud. DB" },
  { id: "mnEPNl", label: "Sabisa - Jud. MM" },
  { id: "leaRPl", label: "Sabolciu - Jud. BH" },
  { id: "mk8K2r", label: "Saca - Jud. BH" },
  { id: "rKjgxm", label: "Sacadat - Jud. BH" },
  { id: "rBj4Vr", label: "Sacadat - Jud. MS" },
  { id: "lOB2nZ", label: "Sacadate - Jud. SB" },
  { id: "m1nE3r", label: "Sacalaia - Jud. CJ" },
  { id: "ZzEQ3r", label: "Sacalasau - Jud. BH" },
  { id: "rXdDnr", label: "Sacalasau Nou - Jud. BH" },
  { id: "rdxKzr", label: "Sacalaseni - Jud. MM" },
  { id: "mgoKEm", label: "Sacalaz - Jud. TM" },
  { id: "ZPayMl", label: "Sacaleni - Jud. NT" },
  { id: "rwPqoZ", label: "Sacalu De Padure - Jud. MS" },
  { id: "mbxEEZ", label: "Sacalusesti - Jud. NT" },
  { id: "rdo3Wm", label: "Sacamas - Jud. HD" },
  { id: "ZRwzDl", label: "Sacaramb - Jud. HD" },
  { id: "Z4D7zZ", label: "Sacareni - Jud. MS" },
  { id: "rwDGvZ", label: "Sacaresti - Jud. IS" },
  { id: "lxKyQZ", label: "Sacaseni - Jud. SM" },
  { id: "Zy2X4l", label: "Sacel - Jud. CJ" },
  { id: "mnQYXl", label: "Sacel - Jud. HD" },
  { id: "lj5gbZ", label: "Sacel - Jud. HR" },
  { id: "roExwl", label: "Sacel - Jud. MM" },
  { id: "r6yAWm", label: "Sacel - Jud. SB" },
  { id: "rKj0Pm", label: "Sacele - Jud. BV" },
  { id: "ZRJbqZ", label: "Sacele - Jud. CL" },
  { id: "ZQqqDZ", label: "Sacele - Jud. CT" },
  { id: "rKnDPm", label: "Sacelu - Jud. GJ" },
  { id: "ZQyenZ", label: "Saceni - Jud. TM" },
  { id: "rKxKEZ", label: "Saceni - Jud. TR" },
  { id: "Z4q91l", label: "Saciova - Jud. CV" },
  { id: "lDaMbm", label: "Sacosu Mare - Jud. TM" },
  { id: "r6x15l", label: "Sacosu Turcesc - Jud. TM" },
  { id: "rodWNZ", label: "Sacu - Jud. CS" },
  { id: "rqPNGm", label: "Sacueni - Jud. BH" },
  { id: "r3Genr", label: "Sacueni - Jud. DB" },
  { id: "lpzRxr", label: "Sacuieu - Jud. CJ" },
  { id: "mAGYqZ", label: "Sacuta - Jud. SV" },
  { id: "mb5eYl", label: "Sadau - Jud. SV" },
  { id: "rqbQgZ", label: "Sadina - Jud. GR" },
  { id: "ZyYJDZ", label: "Sadinca - Jud. SB" },
  { id: "lO6bNZ", label: "Sadova - Jud. DJ" },
  { id: "lDaPGm", label: "Sadova - Jud. SV" },
  { id: "rd5qAZ", label: "Sadova Noua - Jud. CS" },
  { id: "rodAvZ", label: "Sadova Veche - Jud. CS" },
  { id: "ZzEkEr", label: "Sadoveni - Jud. BT" },
  { id: "r6yMXm", label: "Sadu - Jud. SB" },
  { id: "ZR67DZ", label: "Saelele - Jud. TR" },
  { id: "lp5DWm", label: "Saes - Jud. MS" },
  { id: "ZyG04m", label: "Saftica - Jud. IF" },
  { id: "lL7OGZ", label: "Sag - Jud. SJ" },
  { id: "lxEapm", label: "Sag - Jud. TM" },
  { id: "XlDQGm", label: "Sagagea - Jud. AB" },
  { id: "mnJ9Bm", label: "Sageata - Jud. BZ" },
  { id: "ZPa0El", label: "Sagna - Jud. NT" },
  { id: "3ZVRWZ", label: "Sagu - Jud. AR" },
  { id: "lL6vbr", label: "Sahastru - Jud. VN" },
  { id: "lDDGWl", label: "Sahateni - Jud. BZ" },
  { id: "Zzz3WZ", label: "Sai - Jud. SM" },
  { id: "mgJB1l", label: "Saioci - Jud. VL" },
  { id: "r2VRbr", label: "Salacea - Jud. BH" },
  { id: "rKn1Wm", label: "Salageni - Jud. IS" },
  { id: "r3yAAl", label: "Salageni - Jud. SV" },
  { id: "AZQG4m", label: "Salagesti - Jud. AB" },
  { id: "XlDRKr", label: "Salajeni - Jud. AR" },
  { id: "mGWk2m", label: "Salajeni - Jud. SJ" },
  { id: "ZVKg6l", label: "Salard - Jud. BH" },
  { id: "mkENzZ", label: "Salard - Jud. MS" },
  { id: "lx7LnZ", label: "Salasu De Jos - Jud. HD" },
  { id: "r92NxZ", label: "Salasu De Sus - Jud. HD" },
  { id: "rq0MQm", label: "Salasuri - Jud. MS" },
  { id: "mb5bql", label: "Salatig - Jud. SJ" },
  { id: "lDDnYl", label: "Salatiu - Jud. CJ" },
  { id: "JlJawZ", label: "Salatruc - Jud. BC" },
  { id: "ZNWv1l", label: "Salatruc - Jud. CJ" },
  { id: "ZM9KyZ", label: "Salatruc - Jud. HD" },
  { id: "lOAxwr", label: "Salatruc - Jud. MH" },
  { id: "mgJYAl", label: "Salatrucel - Jud. VL" },
  { id: "Xlx0Ql", label: "Salatrucu - Jud. AG" },
  { id: "r6xa2l", label: "Salbagel - Jud. TM" },
  { id: "ZMRo8l", label: "Salbagelu Nou - Jud. CS" },
  { id: "ZvEKwr", label: "Salcau - Jud. SB" },
  { id: "ZPqgAl", label: "Salcea - Jud. SV" },
  { id: "ZPAXMl", label: "Salceni - Jud. TL" },
  { id: "ZzVkKZ", label: "Salceni - Jud. VS" },
  { id: "rK3akr", label: "Salcia - Jud. BR" },
  { id: "ZNWx7l", label: "Salcia - Jud. BZ" },
  { id: "r6q02m", label: "Salcia - Jud. DJ" },
  { id: "l7QGLr", label: "Salcia - Jud. GL" },
  { id: "rE6MVZ", label: "Salcia - Jud. MH" },
  { id: "lxKbwZ", label: "Salcia - Jud. OT" },
  { id: "mGWepm", label: "Salcia - Jud. PH" },
  { id: "ZyWE8m", label: "Salcia - Jud. TR" },
  { id: "maN8Nr", label: "Salcia Noua - Jud. VN" },
  { id: "ZRJxqZ", label: "Salcia Tudor - Jud. BR" },
  { id: "ZvOJDl", label: "Salcia Veche - Jud. VN" },
  { id: "rwE3vl", label: "Salciile - Jud. PH" },
  { id: "mGwg0m", label: "Salcioara - Jud. BZ" },
  { id: "Zy2Qol", label: "Salcioara - Jud. CL" },
  { id: "r8Yn6Z", label: "Salcioara - Jud. DB" },
  { id: "m1qv6m", label: "Salcioara - Jud. IL" },
  { id: "ZzzjEZ", label: "Salcioara - Jud. PH" },
  { id: "ZR6NvZ", label: "Salcioara - Jud. TL" },
  { id: "lJNb4l", label: "Salcioara - Jud. VS" },
  { id: "VleqPl", label: "Salciua De Jos - Jud. AB" },
  { id: "NmkKqm", label: "Salciua De Sus - Jud. AB" },
  { id: "r99e3r", label: "Salciua Noua - Jud. TM" },
  { id: "r92gxZ", label: "Salciva - Jud. HD" },
  { id: "lLVz1r", label: "Salcud - Jud. MS" },
  { id: "m1kMdZ", label: "Salcuta - Jud. BN" },
  { id: "l0XkNl", label: "Salcuta - Jud. DB" },
  { id: "ZyNMql", label: "Salcuta - Jud. DJ" },
  { id: "rByBam", label: "Saldabagiu De Barcau - Jud. BH" },
  { id: "lWByBZ", label: "Saldabagiu De Munte - Jud. BH" },
  { id: "rKjKPm", label: "Saldabagiu Mic - Jud. BH" },
  { id: "l54JEZ", label: "Salicea - Jud. CJ" },
  { id: "ZM7Y4m", label: "Saligny - Jud. CT" },
  { id: "ZPe1Bl", label: "Salisca - Jud. CJ" },
  { id: "mgPyAr", label: "Saliste - Jud. BH" },
  { id: "l08O6l", label: "Saliste - Jud. CJ" },
  { id: "ZzgyNl", label: "Saliste - Jud. DJ" },
  { id: "lJx1wr", label: "Saliste - Jud. HD" },
  { id: "rK23Em", label: "Saliste - Jud. MM" },
  { id: "ZR3OAl", label: "Saliste - Jud. SB" },
  { id: "mnPo8Z", label: "Saliste De Beius - Jud. BH" },
  { id: "ZNy7Qr", label: "Saliste De Pomezeu - Jud. BH" },
  { id: "ropOLZ", label: "Saliste De Vascau - Jud. BH" },
  { id: "Wr8nMl", label: "Salistea - Jud. AB" },
  { id: "JmAL2l", label: "Salistea - Jud. AG" },
  { id: "ZPGBAr", label: "Salistea - Jud. VL" },
  { id: "lWRByZ", label: "Salistea De Sus - Jud. MM" },
  { id: "krwb3m", label: "Salistea Deal - Jud. AB" },
  { id: "lDDzKl", label: "Salistea Noua - Jud. CJ" },
  { id: "mGwL0m", label: "Salistea Veche - Jud. CJ" },
  { id: "lD2Xbr", label: "Salistioara - Jud. HD" },
  { id: "r9yDxr", label: "Salnita - Jud. MM" },
  { id: "lOw1ol", label: "Salonta - Jud. BH" },
  { id: "max6Dl", label: "Salsig - Jud. MM" },
  { id: "r9yyxr", label: "Salta - Jud. MM" },
  { id: "r3yGGl", label: "Saltanesti - Jud. OT" },
  { id: "mAAX4m", label: "Salva - Jud. BN" },
  { id: "arB65r", label: "Samaila - Jud. AG" },
  { id: "wmgkkm", label: "Samara - Jud. AG" },
  { id: "ZyGY2m", label: "Samarinesti - Jud. GJ" },
  { id: "rqP49m", label: "Sambata - Jud. BH" },
  { id: "r82eEl", label: "Sambata De Jos - Jud. BV" },
  { id: "r6bB7r", label: "Sambata De Sus - Jud. BV" },
  { id: "ZR6aNZ", label: "Sambata Noua - Jud. TL" },
  { id: "9m173Z", label: "Sambateni - Jud. AR" },
  { id: "r974xl", label: "Samboieni - Jud. CJ" },
  { id: "rK3der", label: "Samboleni - Jud. CJ" },
  { id: "mGx19r", label: "Sambotin - Jud. GJ" },
  { id: "ZQVX5l", label: "Sambrias - Jud. MS" },
  { id: "rwEVal", label: "Samburesti - Jud. OT" },
  { id: "lxKknZ", label: "Samsud - Jud. SJ" },
  { id: "ZPBOKm", label: "Samurcasi - Jud. DB" },
  { id: "ZzVe8Z", label: "Sanandrei - Jud. TM" },
  { id: "lO65nZ", label: "Sanatesti - Jud. GJ" },
  { id: "rXL9Dr", label: "Sanatoriul Agigea - Jud. CT" },
  { id: "7rqpdm", label: "Sanbenedic - Jud. AB" },
  { id: "r6xd2l", label: "Sanbotin - Jud. VL" },
  { id: "yZRqqr", label: "Sancel - Jud. AB" },
  { id: "rdopAm", label: "Sancel - Jud. HR" },
  { id: "qZN7ld", label: "Sancrai - Jud. AB" },
  { id: "ZM9jgZ", label: "Sancrai - Jud. HD" },
  { id: "l7J8wZ", label: "Sancrai - Jud. HR" },
  { id: "mgL47r", label: "Sancraieni - Jud. HR" },
  { id: "mnJ5Bm", label: "Sancraiu - Jud. CJ" },
  { id: "lO6AJZ", label: "Sancraiu - Jud. CV" },
  { id: "lWDYym", label: "Sancraiu Almasului - Jud. SJ" },
  { id: "roEDql", label: "Sancraiu De Mures - Jud. MS" },
  { id: "lDqLKm", label: "Sancraiu Silvaniei - Jud. SJ" },
  { id: "lL12ym", label: "Sandominic - Jud. HR" },
  { id: "r2qGWm", label: "Sandra - Jud. SM" },
  { id: "rdKezm", label: "Sandra - Jud. TM" },
  { id: "maxVJl", label: "Sandru - Jud. MS" },
  { id: "r6bvXr", label: "Sanduleni - Jud. BC" },
  { id: "yZREjr", label: "Sandulesti - Jud. AG" },
  { id: "r6OMXZ", label: "Sandulesti - Jud. CJ" },
  { id: "rBWadZ", label: "Sandulita - Jud. CL" },
  { id: "mkzXBl", label: "Sangatin - Jud. SB" },
  { id: "rKxWRZ", label: "Sangeorge - Jud. TM" },
  { id: "m1GN3Z", label: "Sangeorgiu De Campie - Jud. MS" },
  { id: "rKDbxZ", label: "Sangeorgiu De Meses - Jud. SJ" },
  { id: "maxY7l", label: "Sangeorgiu De Mures - Jud. MS" },
  { id: "ZRKq2l", label: "Sangeorgiu De Padure - Jud. MS" },
  { id: "mG502Z", label: "Sangeorz Bai - Jud. BN" },
  { id: "lYDW0Z", label: "Sangeorzu Nou - Jud. BN" },
  { id: "rXO4Rm", label: "Sanger - Jud. MS" },
  { id: "m1qERm", label: "Sangeri - Jud. IS" },
  { id: "ZQaxdZ", label: "Sangeru - Jud. PH" },
  { id: "lxALwm", label: "Sangeru De Padure - Jud. MS" },
  { id: "mAAggm", label: "Saniacob - Jud. BN" },
  { id: "mGPe6r", label: "Saniacob - Jud. MS" },
  { id: "rByGQm", label: "Saniob - Jud. BH" },
  { id: "rX7nem", label: "Sanislau - Jud. SM" },
  { id: "rXOX9m", label: "Sanisor - Jud. MS" },
  { id: "l7vXdZ", label: "Sanlazar - Jud. BH" },
  { id: "LZMojm", label: "Sanleani - Jud. AR" },
  { id: "Zzovnl", label: "Sanmarghita - Jud. CJ" },
  { id: "mGPk0r", label: "Sanmarghita - Jud. MS" },
  { id: "wlp96m", label: "Sanmartin - Jud. AR" },
  { id: "rKjzkm", label: "Sanmartin - Jud. BH" },
  { id: "lDD8Wl", label: "Sanmartin - Jud. CJ" },
  { id: "Zzw8Wl", label: "Sanmartin - Jud. HR" },
  { id: "ZyepqZ", label: "Sanmartin De Beius - Jud. BH" },
  { id: "ZNY01r", label: "Sanmartinu De Campie - Jud. MS" },
  { id: "l793Yr", label: "Sanmartinu Maghiar - Jud. TM" },
  { id: "ZNJjLZ", label: "Sanmartinu Sarbesc - Jud. TM" },
  { id: "OroOxr", label: "Sanmiclaus - Jud. AB" },
  { id: "lOByJZ", label: "Sanmiclaus - Jud. SM" },
  { id: "lp5eWm", label: "Sanmihai De Padure - Jud. MS" },
  { id: "ZzzknZ", label: "Sanmihaiu Almasului - Jud. SJ" },
  { id: "ZNyLYr", label: "Sanmihaiu De Campie - Jud. BN" },
  { id: "ZVy2Rr", label: "Sanmihaiu German - Jud. TM" },
  { id: "rXa5DZ", label: "Sanmihaiu Roman - Jud. TM" },
  { id: "ZzEeKr", label: "Sannicoara - Jud. BN" },
  { id: "lLa4Am", label: "Sannicoara - Jud. CJ" },
  { id: "rqP79m", label: "Sannicolau De Beius - Jud. BH" },
  { id: "ZyeDkZ", label: "Sannicolau De Munte - Jud. BH" },
  { id: "lYJOxm", label: "Sannicolau Mare - Jud. TM" },
  { id: "mG5ddZ", label: "Sannicolau Roman - Jud. BH" },
  { id: "rEaMxr", label: "Sanovita - Jud. TM" },
  { id: "0lY0xZ", label: "Sanpaul - Jud. AR" },
  { id: "l71qXr", label: "Sanpaul - Jud. CJ" },
  { id: "lx7G0Z", label: "Sanpaul - Jud. HR" },
  { id: "Z4DOJZ", label: "Sanpaul - Jud. MS" },
  { id: "ZQQzgZ", label: "Sanpetru - Jud. BV" },
  { id: "lD2NYr", label: "Sanpetru - Jud. HD" },
  { id: "mnz3pm", label: "Sanpetru Almasului - Jud. SJ" },
  { id: "lxANom", label: "Sanpetru De Campie - Jud. MS" },
  { id: "LZMgjm", label: "Sanpetru German - Jud. AR" },
  { id: "Z41Qjl", label: "Sanpetru Mare - Jud. TM" },
  { id: "ZQye4Z", label: "Sanpetru Mic - Jud. TM" },
  { id: "rXqNeZ", label: "Sansimion - Jud. HR" },
  { id: "lJ2O3l", label: "Sansimion - Jud. MS" },
  { id: "l51L9l", label: "Sant - Jud. BN" },
  { id: "lLepXm", label: "Santa Mare - Jud. BT" },
  { id: "lpAP6m", label: "Santa Maria - Jud. SJ" },
  { id: "lezVOm", label: "Santamaria De Piatra - Jud. HD" },
  { id: "ZM9g5Z", label: "Santamaria Orlea - Jud. HD" },
  { id: "Qmb5Yl", label: "Santamarie - Jud. AB" },
  { id: "Jr3xGZ", label: "Santana - Jud. AR" },
  { id: "Zv53Jr", label: "Santana De Mures - Jud. MS" },
  { id: "ZNyzQr", label: "Santandrei - Jud. BH" },
  { id: "rKnWxm", label: "Santandrei - Jud. HD" },
  { id: "Z49eAl", label: "Santau - Jud. SM" },
  { id: "roponZ", label: "Santaul Mare - Jud. BH" },
  { id: "ma17jm", label: "Santaul Mic - Jud. BH" },
  { id: "Zy2nDl", label: "Santejude - Jud. CJ" },
  { id: "rK309r", label: "Santejude Vale - Jud. CJ" },
  { id: "ZNy9er", label: "Santelec - Jud. BH" },
  { id: "krd0Rr", label: "Santimbru - Jud. AB" },
  { id: "ZM9eQZ", label: "Santimbru - Jud. HR" },
  { id: "mbO8dr", label: "Santimbru Bai - Jud. HR" },
  { id: "lDARGm", label: "Santimreu - Jud. BH" },
  { id: "mnP0pZ", label: "Santioana - Jud. BN" },
  { id: "lDDVWl", label: "Santioana - Jud. CJ" },
  { id: "m1G39Z", label: "Santioana - Jud. MS" },
  { id: "mga5BZ", label: "Santioana De Mures - Jud. MS" },
  { id: "Zv2p5Z", label: "Santion - Jud. BH" },
  { id: "r9nDqZ", label: "Santionlunca - Jud. CV" },
  { id: "lexKoZ", label: "Santu - Jud. MS" },
  { id: "l0NyQr", label: "Santu Floresti - Jud. IF" },
  { id: "ZQGNJm", label: "Santuhalm - Jud. HD" },
  { id: "lOAyAr", label: "Sanvasii - Jud. MS" },
  { id: "mgxo7r", label: "Sanzieni - Jud. CV" },
  { id: "Zv5zKr", label: "Sapanta - Jud. MM" },
  { id: "mgay7Z", label: "Sapartoc - Jud. MS" },
  { id: "mAOJqZ", label: "Sapata - Jud. DJ" },
  { id: "l71WXr", label: "Sapoca - Jud. BZ" },
  { id: "LZMq4Z", label: "Sapunari - Jud. AG" },
  { id: "ZPekjl", label: "Sapunari - Jud. CL" },
  { id: "Wr8MDZ", label: "Saracsau - Jud. AB" },
  { id: "rwpkaZ", label: "Saradis - Jud. CJ" },
  { id: "lYDEOZ", label: "Sarafinesti - Jud. BT" },
  { id: "rqbbBZ", label: "Saraiu - Jud. CT" },
  { id: "rqbEBZ", label: "Saramas - Jud. CV" },
  { id: "ZNynar", label: "Sarand - Jud. BH" },
  { id: "lLadGm", label: "Saranga - Jud. BZ" },
  { id: "rqpjGm", label: "Sarari - Jud. PH" },
  { id: "rXO7Rm", label: "Sarasau - Jud. MM" },
  { id: "lxxKol", label: "Sarata - Jud. BC" },
  { id: "rXdg4r", label: "Sarata - Jud. BN" },
  { id: "rwnKjm", label: "Sarata - Jud. BT" },
  { id: "Z4wdJr", label: "Sarata - Jud. BZ" },
  { id: "r2YNdm", label: "Sarata - Jud. CJ" },
  { id: "r3GN0r", label: "Sarata - Jud. DJ" },
  { id: "ZzDkEm", label: "Sarata - Jud. NT" },
  { id: "lDqpbm", label: "Sarata - Jud. SB" },
  { id: "ZyeADZ", label: "Sarata Basarab - Jud. BT" },
  { id: "l51DNl", label: "Sarata Draguseni - Jud. BT" },
  { id: "ZNW3Yl", label: "Sarata Monteoru - Jud. BZ" },
  { id: "rdjAWl", label: "Saratel - Jud. BN" },
  { id: "ZQa74Z", label: "Saratel - Jud. PH" },
  { id: "ZRwDjl", label: "Sarateni - Jud. IL" },
  { id: "mgaNBZ", label: "Sarateni - Jud. MS" },
  { id: "mnpMxZ", label: "Sarateni - Jud. VS" },
  { id: "r30jnZ", label: "Saratu - Jud. VS" },
  { id: "m1BMar", label: "Saratura - Jud. SM" },
  { id: "mb5kvl", label: "Sarauad - Jud. SM" },
  { id: "mGaN9Z", label: "Saravale - Jud. TM" },
  { id: "Z41jQl", label: "Sarazani - Jud. TM" },
  { id: "ZvgQwl", label: "Sarbatoarea - Jud. DJ" },
  { id: "l59dEl", label: "Sarbeni - Jud. TR" },
  { id: "r99jar", label: "Sarbenii De Jos - Jud. TR" },
  { id: "lOwVNl", label: "Sarbesti - Jud. BH" },
  { id: "lJWeAm", label: "Sarbesti - Jud. BZ" },
  { id: "l7Q4Ar", label: "Sarbesti - Jud. GJ" },
  { id: "yZR1Lr", label: "Sarbi - Jud. AR" },
  { id: "lYDnxZ", label: "Sarbi - Jud. BC" },
  { id: "l51XYl", label: "Sarbi - Jud. BH" },
  { id: "ZRj2jZ", label: "Sarbi - Jud. BT" },
  { id: "l7QkLr", label: "Sarbi - Jud. GL" },
  { id: "roz83m", label: "Sarbi - Jud. HD" },
  { id: "mbxOqZ", label: "Sarbi - Jud. MM" },
  { id: "lxKwDZ", label: "Sarbi - Jud. SJ" },
  { id: "ljoowm", label: "Sarbi - Jud. VL" },
  { id: "rqx4gm", label: "Sarbi - Jud. VN" },
  { id: "lYJbEm", label: "Sarbi - Jud. VS" },
  { id: "ZvEg9r", label: "Sarbii Magura - Jud. OT" },
  { id: "Z41nql", label: "Sarbova - Jud. TM" },
  { id: "lJxE9r", label: "Sarca - Jud. IS" },
  { id: "mGWqnm", label: "Sarca - Jud. PH" },
  { id: "Zv24PZ", label: "Sarcau - Jud. BH" },
  { id: "qZNDam", label: "Sard - Jud. AB" },
  { id: "rwDpjZ", label: "Sardanesti - Jud. GJ" },
  { id: "mbxNaZ", label: "Sardanesti - Jud. MH" },
  { id: "Z4wgJr", label: "Sardu - Jud. CJ" },
  { id: "mGPddr", label: "Sardu Nirajului - Jud. MS" },
  { id: "ZMRxjl", label: "Sareni - Jud. BZ" },
  { id: "lxE7zm", label: "Sarghiesti - Jud. SV" },
  { id: "ZR6kNZ", label: "Sarichioi - Jud. TL" },
  { id: "ZQy2dZ", label: "Sarighiol De Deal - Jud. TL" },
  { id: "lJW2gm", label: "Sarile - Jud. BZ" },
  { id: "rodo5Z", label: "Sarile Catun - Jud. BZ" },
  { id: "mk1NnZ", label: "Sarinasuf - Jud. TL" },
  { id: "rdKVGm", label: "Sarisor - Jud. SV" },
  { id: "roxnNZ", label: "Sarisoru Mare - Jud. SV" },
  { id: "ZP7Njr", label: "Sarmas - Jud. HR" },
  { id: "rERGBZ", label: "Sarmasag - Jud. SJ" },
  { id: "lOAzJr", label: "Sarmasel - Jud. MS" },
  { id: "Zy64Kl", label: "Sarmasel Gara - Jud. MS" },
  { id: "rq049m", label: "Sarmasu - Jud. MS" },
  { id: "r66J2r", label: "Sarmizegetusa - Jud. HD" },
  { id: "lYGAxr", label: "Saros Pe Tarnave - Jud. SB" },
  { id: "ZyWPDm", label: "Sarsanesti - Jud. VL" },
  { id: "l52Vvm", label: "Sarsca - Jud. DJ" },
  { id: "lDABnm", label: "Sarsig - Jud. BH" },
  { id: "wlp2rz", label: "Sartas - Jud. AB" },
  { id: "lLDv6l", label: "Saru - Jud. DB" },
  { id: "ma6oEr", label: "Saru Bucovinei - Jud. SV" },
  { id: "mGwq9m", label: "Sarulesti - Jud. BZ" },
  { id: "mkwvOm", label: "Sarulesti - Jud. CL" },
  { id: "rqxzGm", label: "Sarulesti - Jud. VL" },
  { id: "rqzMPZ", label: "Sarulesti Gara - Jud. CL" },
  { id: "mgBWAl", label: "Sarvazel - Jud. SM" },
  { id: "4lOKNl", label: "Sasa - Jud. AB" },
  { id: "lpEO2m", label: "Sasa - Jud. GJ" },
  { id: "r86VPl", label: "Sasa - Jud. SJ" },
  { id: "lYnnOm", label: "Sasar - Jud. MM" },
  { id: "leaO6l", label: "Sasarm - Jud. BN" },
  { id: "ZPq5kl", label: "Sasaus - Jud. SB" },
  { id: "rKDRPZ", label: "Sasca Mare - Jud. SV" },
  { id: "r2qJQm", label: "Sasca Mica - Jud. SV" },
  { id: "maLWEl", label: "Sasca Montana - Jud. CS" },
  { id: "ZV3Ddl", label: "Sasca Noua - Jud. SV" },
  { id: "mnJYxm", label: "Sasca Romana - Jud. CS" },
  { id: "ZzD5Nm", label: "Saschiz - Jud. MS" },
  { id: "ZRBA2Z", label: "Sascioara - Jud. VL" },
  { id: "2l5aAl", label: "Sasciori - Jud. AB" },
  { id: "Zv2P3Z", label: "Sasciori - Jud. BV" },
  { id: "ZP29vm", label: "Sascut - Jud. BC" },
  { id: "rBynbm", label: "Sascut Sat - Jud. BC" },
  { id: "lO6LNZ", label: "Saseni - Jud. GL" },
  { id: "rwpwjZ", label: "Sasenii Noi - Jud. BZ" },
  { id: "rBWNDZ", label: "Sasenii Pe Vale - Jud. BZ" },
  { id: "rqznvZ", label: "Sasenii Vechi - Jud. BZ" },
  { id: "r6xA9l", label: "Sasova - Jud. VS" },
  { id: "rozgxm", label: "Sasveres - Jud. HR" },
  { id: "lpzaer", label: "Sat Batran - Jud. CS" },
  { id: "Zyey0Z", label: "Sat Nou - Jud. BT" },
  { id: "rq000m", label: "Sat Sugatag - Jud. MM" },
  { id: "r99KMr", label: "Satchinez - Jud. TM" },
  { id: "rdVN7l", label: "Sateni - Jud. DB" },
  { id: "Rr958r", label: "Satic - Jud. AG" },
  { id: "r2qAWm", label: "Satmarel - Jud. SM" },
  { id: "mAb36r", label: "Satnoeni - Jud. CL" },
  { id: "Zv2d5Z", label: "Satu Barba - Jud. BH" },
  { id: "ZR3Mel", label: "Satu De Sus - Jud. PH" },
  { id: "l71A3r", label: "Satu Lung - Jud. CJ" },
  { id: "PrE8qm", label: "Satu Mare - Jud. AR" },
  { id: "l5QkYl", label: "Satu Mare - Jud. HR" },
  { id: "mkELwZ", label: "Satu Mare - Jud. MH" },
  { id: "rKD7WZ", label: "Satu Mare - Jud. SM" },
  { id: "lpVAWr", label: "Satu Mare - Jud. SV" },
  { id: "7rq8Qm", label: "Satu Mic - Jud. AR" },
  { id: "ZRwANl", label: "Satu Mic - Jud. HR" },
  { id: "ZQagDZ", label: "Satu Mic - Jud. SM" },
  { id: "krdgGm", label: "Satu Nou - Jud. AG" },
  { id: "Nmk9PZ", label: "Satu Nou - Jud. AR" },
  { id: "mk81Qr", label: "Satu Nou - Jud. BC" },
  { id: "r33YVr", label: "Satu Nou - Jud. BH" },
  { id: "rop58Z", label: "Satu Nou - Jud. BN" },
  { id: "ZVKjOl", label: "Satu Nou - Jud. BV" },
  { id: "rd5aaZ", label: "Satu Nou - Jud. BZ" },
  { id: "ZNWkyl", label: "Satu Nou - Jud. CL" },
  { id: "ZVLKdm", label: "Satu Nou - Jud. CT" },
  { id: "ZM7xym", label: "Satu Nou - Jud. DB" },
  { id: "mkjQBl", label: "Satu Nou - Jud. GJ" },
  { id: "mbo4or", label: "Satu Nou - Jud. GL" },
  { id: "l7Qd5r", label: "Satu Nou - Jud. GR" },
  { id: "m1q1Rm", label: "Satu Nou - Jud. HR" },
  { id: "mbODdr", label: "Satu Nou - Jud. IS" },
  { id: "Z4Da1Z", label: "Satu Nou - Jud. MH" },
  { id: "r3B55m", label: "Satu Nou - Jud. MS" },
  { id: "lj4Gbr", label: "Satu Nou - Jud. OT" },
  { id: "mb58al", label: "Satu Nou - Jud. PH" },
  { id: "lLJKXr", label: "Satu Nou - Jud. TL" },
  { id: "ZvzO9Z", label: "Satu Nou - Jud. TR" },
  { id: "ljogbm", label: "Satu Nou - Jud. VN" },
  { id: "lWvjRZ", label: "Satu Nou - Jud. VS" },
  { id: "lWROqZ", label: "Satu Nou De Jos - Jud. MM" },
  { id: "Z4DYpZ", label: "Satu Nou De Sus - Jud. MM" },
  { id: "ZVyxKr", label: "Satu Poieni - Jud. VL" },
  { id: "rBW9qZ", label: "Satu Vechi - Jud. BZ" },
  { id: "rqzJ1Z", label: "Satuc - Jud. BR" },
  { id: "rqz0gZ", label: "Satuc - Jud. BZ" },
  { id: "ZQvWvl", label: "Satucu - Jud. CL" },
  { id: "lDqMnm", label: "Satucu - Jud. PH" },
  { id: "Z41Nzl", label: "Satul Vechi - Jud. TR" },
  { id: "ZRK6yl", label: "Satulung - Jud. MM" },
  { id: "ronnvm", label: "Saturn - Jud. CT" },
  { id: "r9qW8r", label: "Sauaieu - Jud. BH" },
  { id: "rERKoZ", label: "Sauca - Jud. SM" },
  { id: "lj87Kr", label: "Sauca - Jud. VS" },
  { id: "r82nEl", label: "Saucani - Jud. BH" },
  { id: "rByqam", label: "Saucenita - Jud. BT" },
  { id: "r9qM3r", label: "Saucesti - Jud. BC" },
  { id: "l7vRdZ", label: "Saud - Jud. BH" },
  { id: "mnJGXm", label: "Saula - Jud. CJ" },
  { id: "r2QqQm", label: "Saulesti - Jud. GJ" },
  { id: "ZVwQ6l", label: "Saulesti - Jud. HD" },
  { id: "lWR4EZ", label: "Saulia - Jud. MS" },
  { id: "rq0oom", label: "Saulita - Jud. MS" },
  { id: "Zy64ol", label: "Sausa - Jud. MS" },
  { id: "ZNW17l", label: "Sava - Jud. CJ" },
  { id: "lW1EAr", label: "Savadisla - Jud. CJ" },
  { id: "Jr3K0r", label: "Savarsin - Jud. AR" },
  { id: "ljGYwm", label: "Savastreni - Jud. BV" },
  { id: "rqPGQm", label: "Saveni - Jud. BT" },
  { id: "m1q36m", label: "Saveni - Jud. IL" },
  { id: "r3QEel", label: "Saveni - Jud. IS" },
  { id: "leojOr", label: "Savesti - Jud. DB" },
  { id: "mgaz1Z", label: "Savesti - Jud. NT" },
  { id: "maxwbl", label: "Savinesti - Jud. NT" },
  { id: "4lO2NZ", label: "Sboghitesti - Jud. AG" },
  { id: "ljN3kl", label: "Scaeni - Jud. BZ" },
  { id: "rKPbWZ", label: "Scaesti - Jud. DJ" },
  { id: "r840Xr", label: "Scafari - Jud. VN" },
  { id: "m18Gjl", label: "Scaiosi - Jud. VL" },
  { id: "ZPeQMl", label: "Scaius - Jud. CS" },
  { id: "ZyGMkm", label: "Scanteia - Jud. IL" },
  { id: "ZP7gKr", label: "Scanteia - Jud. IS" },
  { id: "lejV6m", label: "Scanteia - Jud. VN" },
  { id: "rwyJol", label: "Scanteiesti - Jud. GL" },
  { id: "ZPajAl", label: "Scapau - Jud. MH" },
  { id: "rE6zxZ", label: "Scaricica - Jud. NT" },
  { id: "lLea6m", label: "Scariga - Jud. BC" },
  { id: "Xl090m", label: "Scarisoara - Jud. AB" },
  { id: "7rqKgl", label: "Scarisoara - Jud. BC" },
  { id: "mGwa0m", label: "Scarisoara - Jud. BZ" },
  { id: "rE7eLl", label: "Scarisoara - Jud. CS" },
  { id: "mnzpNm", label: "Scarisoara - Jud. OT" },
  { id: "mA8Oql", label: "Scarisoara - Jud. VL" },
  { id: "ZzzxDZ", label: "Scarisoara Noua - Jud. SM" },
  { id: "rwpg3Z", label: "Scarlatesti - Jud. BR" },
  { id: "r97Myl", label: "Scarlatesti - Jud. BZ" },
  { id: "ZNJ57Z", label: "Scaueni - Jud. VL" },
  { id: "ZzwbKl", label: "Scheia - Jud. IS" },
  { id: "ZvzgPZ", label: "Scheia - Jud. SV" },
  { id: "lj9DkZ", label: "Scheiu De Jos - Jud. DB" },
  { id: "ZPBj3m", label: "Scheiu De Sus - Jud. DB" },
  { id: "rKP9xZ", label: "Schela - Jud. DB" },
  { id: "lJx99r", label: "Schela - Jud. GJ" },
  { id: "rBOEdZ", label: "Schela - Jud. GL" },
  { id: "Z49Npl", label: "Schela - Jud. PH" },
  { id: "lxAz7m", label: "Schela Cladovei - Jud. MH" },
  { id: "r2q5Qm", label: "Schiau - Jud. PH" },
  { id: "l518Nl", label: "Schineni - Jud. BC" },
  { id: "lDaj2m", label: "Schineni - Jud. VS" },
  { id: "lOYEOr", label: "Schinetea - Jud. VS" },
  { id: "r2w4QZ", label: "Schinteiesti - Jud. MH" },
  { id: "lLeEnm", label: "Schit Oraseni - Jud. BT" },
  { id: "lx8vpl", label: "Schitu - Jud. CT" },
  { id: "l7Qevr", label: "Schitu - Jud. DJ" },
  { id: "ZRxpLm", label: "Schitu - Jud. GR" },
  { id: "l7y93r", label: "Schitu - Jud. OT" },
  { id: "ZQwG4m", label: "Schitu - Jud. VL" },
  { id: "l59LYl", label: "Schitu - Jud. VS" },
  { id: "l58QRZ", label: "Schitu Deleni - Jud. OT" },
  { id: "lWD1Nm", label: "Schitu Din Deal - Jud. OT" },
  { id: "mkzwOl", label: "Schitu Din Vale - Jud. OT" },
  { id: "lj5YKZ", label: "Schitu Duca - Jud. IS" },
  { id: "wlpRYr", label: "Schitu Frumoasa - Jud. BC" },
  { id: "PrEnoZ", label: "Schitu Golesti - Jud. AG" },
  { id: "ZQG1gm", label: "Schitu Hadambului - Jud. IS" },
  { id: "qZNL6m", label: "Schitu Matei - Jud. AG" },
  { id: "rKx8eZ", label: "Schitu Poienari - Jud. TR" },
  { id: "wmg81r", label: "Schitu Scoicesti - Jud. AG" },
  { id: "ZyGRYm", label: "Schitu Stavnic - Jud. IS" },
  { id: "rE6pLZ", label: "Schitu Tarcau - Jud. NT" },
  { id: "ZVdWdm", label: "Schitu Topolnitei - Jud. MH" },
  { id: "ZR3vyl", label: "Schiulesti - Jud. PH" },
  { id: "ZzEMBr", label: "Scoabe - Jud. BN" },
  { id: "BZ410l", label: "Scoarta - Jud. AB" },
  { id: "ZVw3dl", label: "Scoarta - Jud. GJ" },
  { id: "mnQ38l", label: "Scobalteni - Jud. IS" },
  { id: "r66Bgr", label: "Scobinti - Jud. IS" },
  { id: "mbObvr", label: "Scoposeni - Jud. IS" },
  { id: "l07boZ", label: "Scorbura - Jud. OT" },
  { id: "l7yzvr", label: "Scoreiu - Jud. SB" },
  { id: "Z4DjxZ", label: "Scorila - Jud. MH" },
  { id: "Zy6gKl", label: "Scornicesti - Jud. OT" },
  { id: "lW1Qqr", label: "Scorosesti - Jud. BZ" },
  { id: "rd5VRZ", label: "Scortaru Nou - Jud. BR" },
  { id: "ZzooWl", label: "Scortaru Vechi - Jud. BR" },
  { id: "rE72gl", label: "Scorteanca - Jud. BZ" },
  { id: "mbPzaZ", label: "Scorteni - Jud. BC" },
  { id: "rBneQr", label: "Scorteni - Jud. PH" },
  { id: "rwp0jZ", label: "Scortoasa - Jud. BZ" },
  { id: "ZNG5Yl", label: "Scorusu - Jud. GJ" },
  { id: "lO7W3l", label: "Scorusu - Jud. VL" },
  { id: "mG1RBZ", label: "Scrada - Jud. GJ" },
  { id: "mkjawl", label: "Scradoasa - Jud. CV" },
  { id: "ZPe5Dl", label: "Scrind Frasinet - Jud. CJ" },
  { id: "r2M49m", label: "Scrioastea - Jud. TR" },
  { id: "rKn00m", label: "Sculeni - Jud. IS" },
  { id: "rqOnQl", label: "Sculia - Jud. TM" },
  { id: "mbNYom", label: "Scundu - Jud. VL" },
  { id: "lDAPYm", label: "Scurta - Jud. BC" },
  { id: "mkEoPZ", label: "Scurta - Jud. MS" },
  { id: "lDDobl", label: "Scurtesti - Jud. BZ" },
  { id: "r9DXMm", label: "Scurtesti - Jud. PH" },
  { id: "rwy6Kl", label: "Scurtu - Jud. GJ" },
  { id: "ZVyWJr", label: "Scurtu Mare - Jud. TR" },
  { id: "lpVQbr", label: "Scurtu Slavesti - Jud. TR" },
  { id: "r2VEpr", label: "Scutari - Jud. BT" },
  { id: "rwnpam", label: "Scutaru - Jud. BC" },
  { id: "rBWDDZ", label: "Scutelnici - Jud. BZ" },
  { id: "BZ488Z", label: "Seaca - Jud. BC" },
  { id: "lD2AWr", label: "Seaca - Jud. GJ" },
  { id: "mGWa0m", label: "Seaca - Jud. OT" },
  { id: "lJNy3l", label: "Seaca - Jud. TR" },
  { id: "r36yAm", label: "Seaca - Jud. VL" },
  { id: "r271WZ", label: "Seaca De Camp - Jud. DJ" },
  { id: "ZVLeOm", label: "Seaca De Padure - Jud. DJ" },
  { id: "Wr62rb", label: "Sebes - Jud. AB" },
  { id: "l0p6gl", label: "Sebes - Jud. BV" },
  { id: "Zv5q2r", label: "Sebes - Jud. MS" },
  { id: "3r2d1l", label: "Sebesel - Jud. AB" },
  { id: "r6yLXm", label: "Sebesu De Jos - Jud. SB" },
  { id: "rwERyl", label: "Sebesu De Sus - Jud. SB" },
  { id: "4lOzAZ", label: "Sebis - Jud. AR" },
  { id: "m1kJjZ", label: "Sebis - Jud. BH" },
  { id: "rXdG3r", label: "Sebis - Jud. BN" },
  { id: "arBGVZ", label: "Sebisesti - Jud. AB" },
  { id: "QmbRBm", label: "Secaci - Jud. AR" },
  { id: "l0Kk0Z", label: "Secara - Jud. TR" },
  { id: "rqp7dm", label: "Secaria - Jud. PH" },
  { id: "5l7e5r", label: "Secas - Jud. AR" },
  { id: "mbKQYr", label: "Secas - Jud. TM" },
  { id: "WZPOEr", label: "Secasel - Jud. AB" },
  { id: "l71Owr", label: "Secaseni - Jud. CS" },
  { id: "r30YYZ", label: "Seceani - Jud. TM" },
  { id: "m1Bgar", label: "Secheresa - Jud. SM" },
  { id: "leGjdr", label: "Seciu - Jud. PH" },
  { id: "lJ6jAl", label: "Seciu - Jud. VL" },
  { id: "l5Od2r", label: "Seciu - Jud. VN" },
  { id: "lj4P4r", label: "Seciuri - Jud. PH" },
  { id: "r8ODVm", label: "Seciurile - Jud. GJ" },
  { id: "lW1eNr", label: "Sectorul 1 - Jud. B" },
  { id: "mkwjOm", label: "Sectorul 2 - Jud. B" },
  { id: "mnJxAm", label: "Sectorul 3 - Jud. B" },
  { id: "lDDP1l", label: "Sectorul 4 - Jud. B" },
  { id: "l71Qqr", label: "Sectorul 5 - Jud. B" },
  { id: "mGw12m", label: "Sectorul 6 - Jud. B" },
  { id: "lJW1pm", label: "Secu - Jud. CS" },
  { id: "lJPbzr", label: "Secu - Jud. DJ" },
  { id: "ZM9G4Z", label: "Secu - Jud. HR" },
  { id: "ZRKEel", label: "Secu - Jud. NT" },
  { id: "leoKXr", label: "Secui - Jud. DJ" },
  { id: "r30EGZ", label: "Secuia - Jud. VS" },
  { id: "lLeqPm", label: "Secuieni - Jud. BC" },
  { id: "r66jpr", label: "Secuieni - Jud. HR" },
  { id: "r9yE8r", label: "Secuieni - Jud. NT" },
  { id: "ZNYpar", label: "Secuienii Noi - Jud. NT" },
  { id: "ZvAD2r", label: "Secuiu - Jud. BZ" },
  { id: "rXy0RZ", label: "Seculesti - Jud. DJ" },
  { id: "rwVD3m", label: "Securiceni - Jud. SV" },
  { id: "9m1O8Z", label: "Secusigiu - Jud. AR" },
  { id: "7rq4Pm", label: "Sederhat - Jud. AR" },
  { id: "AZQ7Jm", label: "Segaj - Jud. AB" },
  { id: "rqb40Z", label: "Segarcea - Jud. DJ" },
  { id: "mGaODZ", label: "Segarcea Deal - Jud. TR" },
  { id: "mA9nBm", label: "Segarcea Vale - Jud. TR" },
  { id: "ZyebqZ", label: "Seghiste - Jud. BH" },
  { id: "mgx51r", label: "Seglet - Jud. DJ" },
  { id: "mgBOGl", label: "Seica Mare - Jud. SB" },
  { id: "ZV3pRl", label: "Seica Mica - Jud. SB" },
  { id: "r3GGer", label: "Seimeni - Jud. CT" },
  { id: "rBOyQZ", label: "Seimenii Mici - Jud. CT" },
  { id: "mkE8PZ", label: "Seini - Jud. MM" },
  { id: "rd5MRZ", label: "Seinoiu - Jud. CL" },
  { id: "JlJOem", label: "Seitin - Jud. AR" },
  { id: "Xl0APm", label: "Selareasca - Jud. AG" },
  { id: "XlDpGl", label: "Selari - Jud. AG" },
  { id: "l52a9m", label: "Selaru - Jud. DB" },
  { id: "4lOJKZ", label: "Seleus - Jud. AR" },
  { id: "r2wjeZ", label: "Seleus - Jud. MS" },
  { id: "Z49xql", label: "Selimbar - Jud. SB" },
  { id: "rwn8Vm", label: "Selistat - Jud. BV" },
  { id: "JmANBr", label: "Seliste - Jud. AR" },
  { id: "Jr3Ynl", label: "Selistea - Jud. AR" },
  { id: "ZVK5Rl", label: "Selistea - Jud. BT" },
  { id: "rq0adm", label: "Selistea - Jud. MH" },
  { id: "ZzDG3m", label: "Selisteni - Jud. MH" },
  { id: "rwP7jZ", label: "Selistiuta - Jud. MH" },
  { id: "ZMJEgl", label: "Semenea - Jud. VS" },
  { id: "yZyQDZ", label: "Semlac - Jud. AR" },
  { id: "ZQy5pZ", label: "Semlacu Mare - Jud. TM" },
  { id: "r30o4Z", label: "Semlacu Mic - Jud. TM" },
  { id: "mgP8Dr", label: "Sendreni - Jud. BT" },
  { id: "lJPRYr", label: "Sendreni - Jud. GL" },
  { id: "l7JDqZ", label: "Sendreni - Jud. IS" },
  { id: "ma1oJm", label: "Sendresti - Jud. BC" },
  { id: "Z4Yg0l", label: "Sendriceni - Jud. BT" },
  { id: "r3355r", label: "Sendroaia - Jud. BN" },
  { id: "WmG4nr", label: "Sendrulesti - Jud. AG" },
  { id: "Z4DnAZ", label: "Senereus - Jud. MS" },
  { id: "ZQGPgm", label: "Senetea - Jud. HR" },
  { id: "0lYaOl", label: "Sepreus - Jud. AR" },
  { id: "ZR3eNl", label: "Ser - Jud. SM" },
  { id: "l0pRBl", label: "Serani - Jud. BH" },
  { id: "rdV2Wl", label: "Serbaneasa - Jud. DB" },
  { id: "r36Qzm", label: "Serbaneasa - Jud. VL" },
  { id: "WZP3Er", label: "Serbanesti - Jud. AG" },
  { id: "ZyYW8Z", label: "Serbanesti - Jud. OT" },
  { id: "rwVPXm", label: "Serbanesti - Jud. SV" },
  { id: "roYYvl", label: "Serbanesti - Jud. VL" },
  { id: "r65jpm", label: "Serbanesti - Jud. VN" },
  { id: "mGWxDm", label: "Serbanestii De Sus - Jud. OT" },
  { id: "mbKOor", label: "Serbauti - Jud. SV" },
  { id: "lWRVdZ", label: "Serbeni - Jud. MS" },
  { id: "ZRj3eZ", label: "Serbesti - Jud. BC" },
  { id: "ZQGEdm", label: "Serbesti - Jud. IS" },
  { id: "l5OXEr", label: "Serbesti - Jud. VN" },
  { id: "lxbwQr", label: "Serbestii Vechi - Jud. GL" },
  { id: "Rr9Y3r", label: "Serboeni - Jud. AG" },
  { id: "mk1yzZ", label: "Serbotesti - Jud. VS" },
  { id: "rqPK9m", label: "Sercaia - Jud. BV" },
  { id: "ZMYP8Z", label: "Sercaita - Jud. BV" },
  { id: "mboYBr", label: "Serdanu - Jud. DB" },
  { id: "r92WNZ", label: "Sereca - Jud. HD" },
  { id: "rqpygm", label: "Seredeiu - Jud. SJ" },
  { id: "mka9PZ", label: "Serel - Jud. HD" },
  { id: "rqza0Z", label: "Sergent Ionel Stefan - Jud. BZ" },
  { id: "rBy2Vm", label: "Serghis - Jud. BH" },
  { id: "rXaaeZ", label: "Sericu - Jud. TR" },
  { id: "ZP2qkm", label: "Serpeni - Jud. BC" },
  { id: "rKjpPm", label: "Serpenita - Jud. BT" },
  { id: "r2Mqgm", label: "Ses - Jud. SV" },
  { id: "ZMYRjZ", label: "Sesuri - Jud. BC" },
  { id: "ZvoxVl", label: "Sesuri - Jud. HD" },
  { id: "r3yXzl", label: "Sesuri - Jud. SV" },
  { id: "ZyeK8Z", label: "Sesuri Spermezeu Vale - Jud. BN" },
  { id: "rE276Z", label: "Seuca - Jud. GJ" },
  { id: "l7wO5l", label: "Seuca - Jud. MS" },
  { id: "ZVdg6m", label: "Seulia De Mures - Jud. MS" },
  { id: "PrERMZ", label: "Seusa - Jud. AB" },
  { id: "lp5vem", label: "Severinesti - Jud. MH" },
  { id: "rX71Lm", label: "Sfacaru - Jud. PH" },
  { id: "rqz7dZ", label: "Sfanta Elena - Jud. CS" },
  { id: "lj99wZ", label: "Sfantu Gheorghe - Jud. CV" },
  { id: "r6qAgm", label: "Sfantu Gheorghe - Jud. GR" },
  { id: "lODL3m", label: "Sfantu Gheorghe - Jud. IL" },
  { id: "ZzD4Dm", label: "Sfantu Gheorghe - Jud. MS" },
  { id: "rdKJAm", label: "Sfantu Gheorghe - Jud. TL" },
  { id: "r6x63l", label: "Sfantu Ilie - Jud. SV" },
  { id: "ZR3kql", label: "Sfaras - Jud. SJ" },
  { id: "Wr65Dm", label: "Sfarcea - Jud. AB" },
  { id: "mG1ewZ", label: "Sfarcea - Jud. DJ" },
  { id: "l0wRJZ", label: "Sfarleanca - Jud. PH" },
  { id: "rqPgdm", label: "Sfarnas - Jud. BH" },
  { id: "lYJ8Am", label: "Sfintesti - Jud. TR" },
  { id: "lJNX9l", label: "Sfistofca - Jud. TL" },
  { id: "EZz8Dm", label: "Sfoartea - Jud. AB" },
  { id: "ZMVxGl", label: "Sfodea - Jud. MH" },
  { id: "rKnPGm", label: "Siacu - Jud. GJ" },
  { id: "Wr689l", label: "Siad - Jud. AR" },
  { id: "r2Yxem", label: "Sibiciu De Jos - Jud. BZ" },
  { id: "ZvAo9r", label: "Sibiciu De Sus - Jud. BZ" },
  { id: "r9D5Xm", label: "Sibiel - Jud. SB" },
  { id: "leo2Xr", label: "Sibioara - Jud. CT" },
  { id: "r3Qw4l", label: "Sibisel - Jud. HD" },
  { id: "mkznBl", label: "Sibiu - Jud. SB" },
  { id: "AZvx9m", label: "Sibot - Jud. AB" },
  { id: "mkwAbm", label: "Sic - Jud. CJ" },
  { id: "mGxJdr", label: "Sicasau - Jud. HR" },
  { id: "mnEDNl", label: "Sicele - Jud. MS" },
  { id: "rBWKDZ", label: "Sicfa - Jud. CJ" },
  { id: "ZvAvPr", label: "Sichevita - Jud. CS" },
  { id: "rKDEEZ", label: "Sici - Jud. SJ" },
  { id: "4lOqGr", label: "Siclau - Jud. AR" },
  { id: "ZVwydl", label: "Siclod - Jud. HR" },
  { id: "7rqg1l", label: "Sicoiesti - Jud. AB" },
  { id: "Zzz08Z", label: "Sicrita - Jud. PH" },
  { id: "JmADWl", label: "Sicula - Jud. AR" },
  { id: "r92ddZ", label: "Siculeni - Jud. HR" },
  { id: "l0pakl", label: "Sieu - Jud. BN" },
  { id: "m1GB8Z", label: "Sieu - Jud. MM" },
  { id: "ZRjDAZ", label: "Sieu Magherus - Jud. BN" },
  { id: "rdj8al", label: "Sieu Odorhei - Jud. BN" },
  { id: "lp1j6m", label: "Sieu Sfantu - Jud. BN" },
  { id: "ropDqZ", label: "Sieut - Jud. BN" },
  { id: "rwpKKZ", label: "Sigau - Jud. CJ" },
  { id: "ZPa2Bl", label: "Sighetu Marmatiei - Jud. MM" },
  { id: "ZNwbxl", label: "Sighetu Silvaniei - Jud. SJ" },
  { id: "lOAGJr", label: "Sighisoara - Jud. MS" },
  { id: "l0p4Rl", label: "Sighistel - Jud. BH" },
  { id: "Z4Y7zl", label: "Sigmir - Jud. BN" },
  { id: "Z4NW8m", label: "Sihlea - Jud. VN" },
  { id: "ZQvQJl", label: "Sihleanu - Jud. BR" },
  { id: "lpVy6r", label: "Silagiu - Jud. TM" },
  { id: "AZQadZ", label: "Silea - Jud. AB" },
  { id: "lO7Awl", label: "Silea - Jud. VL" },
  { id: "mGPQDr", label: "Silea Nirajului - Jud. MS" },
  { id: "qZNV7Z", label: "Silindia - Jud. AR" },
  { id: "ZMY0QZ", label: "Silindru - Jud. BH" },
  { id: "lWBnEZ", label: "Siliscani - Jud. BT" },
  { id: "4lOLom", label: "Silistea - Jud. AG" },
  { id: "ZvAg9r", label: "Silistea - Jud. BR" },
  { id: "l7vNYZ", label: "Silistea - Jud. BT" },
  { id: "ZzoMKl", label: "Silistea - Jud. CL" },
  { id: "m1DDRl", label: "Silistea - Jud. CT" },
  { id: "rong3m", label: "Silistea - Jud. DB" },
  { id: "mG186Z", label: "Silistea - Jud. GL" },
  { id: "mnEwGl", label: "Silistea - Jud. NT" },
  { id: "Z49ajl", label: "Silistea - Jud. PH" },
  { id: "ZR3Ljl", label: "Silistea - Jud. SV" },
  { id: "ZNJx1Z", label: "Silistea - Jud. TR" },
  { id: "mk1DzZ", label: "Silistea - Jud. VS" },
  { id: "lYxbRZ", label: "Silistea Crucii - Jud. DJ" },
  { id: "lj4ydr", label: "Silistea Dealului - Jud. PH" },
  { id: "r89dXZ", label: "Silistea Gumesti - Jud. TR" },
  { id: "ZQyP5Z", label: "Silistea Mica - Jud. TR" },
  { id: "lOB53Z", label: "Silistea Noua - Jud. SV" },
  { id: "l5Q0Rl", label: "Silistea Snagovului - Jud. IF" },
  { id: "Nmkdnr", label: "Silisteni - Jud. AG" },
  { id: "r977dl", label: "Silistraru - Jud. BR" },
  { id: "VleJXm", label: "Silivas - Jud. AB" },
  { id: "rBWoJZ", label: "Silivas - Jud. CJ" },
  { id: "rKjNem", label: "Silivasu De Campie - Jud. BN" },
  { id: "lOBXJZ", label: "Silvas - Jud. SM" },
  { id: "l7JjqZ", label: "Silvasu De Jos - Jud. HD" },
  { id: "mGx92r", label: "Silvasu De Sus - Jud. HD" },
  { id: "Wr8A0Z", label: "Simand - Jud. AR" },
  { id: "m1qJqm", label: "Simeria - Jud. HD" },
  { id: "r2Q0bm", label: "Simeria Veche - Jud. HD" },
  { id: "mAAJam", label: "Simian - Jud. BH" },
  { id: "rwP0VZ", label: "Simian - Jud. MH" },
  { id: "mk1OPZ", label: "Simila - Jud. VS" },
  { id: "mGa4dZ", label: "Similisoara - Jud. VS" },
  { id: "rwVy3m", label: "Siminicea - Jud. SV" },
  { id: "r3k7zr", label: "Siminoc - Jud. CT" },
  { id: "l78Xqm", label: "Siminoc - Jud. VN" },
  { id: "lJQqwZ", label: "Simionesti - Jud. BN" },
  { id: "r8BNVl", label: "Simionesti - Jud. NT" },
  { id: "r86wXl", label: "Simisna - Jud. SJ" },
  { id: "lOBkAZ", label: "Simleu Silvaniei - Jud. SJ" },
  { id: "Z49D8l", label: "Simniceni - Jud. OT" },
  { id: "l7QnAr", label: "Simnicu De Jos - Jud. DJ" },
  { id: "leoNXr", label: "Simnicu De Sus - Jud. DJ" },
  { id: "Jr3dVZ", label: "Simocesti - Jud. AB" },
  { id: "lxxjMl", label: "Simon - Jud. BV" },
  { id: "rE2qoZ", label: "Simonesti - Jud. HR" },
  { id: "olWErN", label: "Simulesti - Jud. AB" },
  { id: "rBn5qr", label: "Sinaia - Jud. PH" },
  { id: "lJ959Z", label: "Sinca - Jud. SV" },
  { id: "lWB6BZ", label: "Sinca Noua - Jud. BV" },
  { id: "rXdMpr", label: "Sinca Veche - Jud. BV" },
  { id: "mkEBQZ", label: "Sincai - Jud. MS" },
  { id: "ZRKbAl", label: "Sincai Fanate - Jud. MS" },
  { id: "ZPa7Dl", label: "Sindresti - Jud. MM" },
  { id: "lYyn0l", label: "Sindrila - Jud. BZ" },
  { id: "r65W9m", label: "Sindrilari - Jud. VN" },
  { id: "rKnRem", label: "Sindrilita - Jud. IF" },
  { id: "ZR6WAZ", label: "Sinersig - Jud. TM" },
  { id: "BZ4zjm", label: "Sinesti - Jud. AG" },
  { id: "r66K5r", label: "Sinesti - Jud. IL" },
  { id: "r923NZ", label: "Sinesti - Jud. IS" },
  { id: "r3B7qm", label: "Sinesti - Jud. OT" },
  { id: "lL6d1r", label: "Sinesti - Jud. VL" },
  { id: "ZNA3Qr", label: "Sineu - Jud. HR" },
  { id: "rdVGWl", label: "Singureni - Jud. GR" },
  { id: "l7Qpdr", label: "Sinoie - Jud. CT" },
  { id: "ZNJ2YZ", label: "Sintar - Jud. TM" },
  { id: "grKy9l", label: "Sintea Mare - Jud. AR" },
  { id: "arB8gl", label: "Sintea Mica - Jud. AR" },
  { id: "ma1Y7m", label: "Sintereag - Jud. BN" },
  { id: "lWB7yZ", label: "Sintereag Gara - Jud. BN" },
  { id: "rBjdJr", label: "Sintesti - Jud. IF" },
  { id: "Zvo1Kl", label: "Sintesti - Jud. IL" },
  { id: "ZzV4NZ", label: "Sintesti - Jud. TM" },
  { id: "ZNygxr", label: "Sinteu - Jud. BH" },
  { id: "lpV7or", label: "Sipeni - Jud. VS" },
  { id: "rwV9jm", label: "Sipet - Jud. TM" },
  { id: "mnxgpZ", label: "Sipot - Jud. DB" },
  { id: "rKPLWZ", label: "Sipote - Jud. GL" },
  { id: "rXqM4Z", label: "Sipote - Jud. IS" },
  { id: "rqzKGZ", label: "Sipotele - Jud. CT" },
  { id: "lD22Xr", label: "Sipotu - Jud. GJ" },
  { id: "Zy6qql", label: "Sipotu - Jud. MH" },
  { id: "lL75PZ", label: "Sipotu - Jud. PH" },
  { id: "lL7jWZ", label: "Siret - Jud. SV" },
  { id: "mgL25r", label: "Siretel - Jud. IS" },
  { id: "l0pwJl", label: "Siretu - Jud. BC" },
  { id: "rwY3al", label: "Siretu - Jud. VN" },
  { id: "2l5VNm", label: "Siria - Jud. AR" },
  { id: "lejPPm", label: "Sirineasa - Jud. VL" },
  { id: "mgPNBr", label: "Sirioara - Jud. BN" },
  { id: "mkwk2m", label: "Siriu - Jud. CT" },
  { id: "rwE9Vl", label: "Sirlau - Jud. SM" },
  { id: "lOBqwZ", label: "Sirna - Jud. PH" },
  { id: "rKjYWm", label: "Sirnea - Jud. BV" },
  { id: "l7w6dl", label: "Siroca - Jud. MH" },
  { id: "rK69km", label: "Siscani - Jud. VN" },
  { id: "ZyWBom", label: "Siscani - Jud. VS" },
  { id: "lp5aom", label: "Sisesti - Jud. MH" },
  { id: "r6GxXZ", label: "Sisesti - Jud. MM" },
  { id: "Xl01Jm", label: "Sistarovat - Jud. AR" },
  { id: "ZzE83r", label: "Sisterea - Jud. BH" },
  { id: "lOwRLl", label: "Sita - Jud. BN" },
  { id: "ZQqaJZ", label: "Sita Buzaului - Jud. CV" },
  { id: "rByM2m", label: "Sitani - Jud. BH" },
  { id: "mA47gl", label: "Sitaru - Jud. IF" },
  { id: "lYxXAZ", label: "Sitesti - Jud. GJ" },
  { id: "mbPdvZ", label: "Sititelec - Jud. BH" },
  { id: "r275dZ", label: "Sitoaia - Jud. DJ" },
  { id: "l0N8Pr", label: "Sitoaia - Jud. GJ" },
  { id: "leoAOr", label: "Sivita - Jud. GL" },
  { id: "4lOboZ", label: "Slamnesti - Jud. AG" },
  { id: "ron35m", label: "Slamnesti - Jud. GJ" },
  { id: "QmbJEm", label: "Slanic - Jud. AG" },
  { id: "rqpxQm", label: "Slanic - Jud. PH" },
  { id: "0lYeVl", label: "Slanic Moldova - Jud. BC" },
  { id: "mkEgnZ", label: "Slasoma - Jud. MH" },
  { id: "yZykqm", label: "Slatina - Jud. AG" },
  { id: "mnE5xl", label: "Slatina - Jud. OT" },
  { id: "lWvDdZ", label: "Slatina - Jud. SV" },
  { id: "VrX09r", label: "Slatina De Cris - Jud. AR" },
  { id: "WZPz3m", label: "Slatina De Mures - Jud. AR" },
  { id: "lDDN2l", label: "Slatina Nera - Jud. CS" },
  { id: "ljNWql", label: "Slatina Timis - Jud. CS" },
  { id: "Zy65Ll", label: "Slatinicu Mare - Jud. MH" },
  { id: "rK26Qm", label: "Slatinicu Mic - Jud. MH" },
  { id: "r66opr", label: "Slatinioara - Jud. HD" },
  { id: "lxxLwl", label: "Slatinita - Jud. BN" },
  { id: "rwPEyZ", label: "Slatioara - Jud. MM" },
  { id: "mkzzzl", label: "Slatioara - Jud. OT" },
  { id: "lYJxEm", label: "Slatioara - Jud. SV" },
  { id: "ZzPpDm", label: "Slatioara - Jud. VL" },
  { id: "7rqD0m", label: "Slatioarele - Jud. AG" },
  { id: "r66nXr", label: "Slatioarele - Jud. IL" },
  { id: "rBRAqr", label: "Slatioarele - Jud. VL" },
  { id: "roxvvZ", label: "Slava Cercheza - Jud. TL" },
  { id: "r30MeZ", label: "Slava Rusa - Jud. TL" },
  { id: "ZV3Yxl", label: "Slaveni - Jud. OT" },
  { id: "r99Var", label: "Slavesti - Jud. TR" },
  { id: "r844qr", label: "Slavitesti - Jud. VL" },
  { id: "ZNwnLl", label: "Slavu - Jud. PH" },
  { id: "maodWZ", label: "Slavuta - Jud. GJ" },
  { id: "r9DG3m", label: "Slimnic - Jud. SB" },
  { id: "rE40Qm", label: "Slimnic - Jud. VN" },
  { id: "lYKGJr", label: "Slivilesti - Jud. GJ" },
  { id: "ZyNyql", label: "Slivna - Jud. GL" },
  { id: "arBq2m", label: "Slobozia - Jud. AG" },
  { id: "mnPpqZ", label: "Slobozia - Jud. BC" },
  { id: "rdjzzl", label: "Slobozia - Jud. BT" },
  { id: "ZvAEVr", label: "Slobozia - Jud. BZ" },
  { id: "l7Q5qr", label: "Slobozia - Jud. DB" },
  { id: "leo3dr", label: "Slobozia - Jud. GJ" },
  { id: "lDPpKl", label: "Slobozia - Jud. GR" },
  { id: "mkaNQZ", label: "Slobozia - Jud. IL" },
  { id: "rKnYxm", label: "Slobozia - Jud. IS" },
  { id: "lj3R1m", label: "Slobozia - Jud. NT" },
  { id: "lDa6jm", label: "Slobozia - Jud. SV" },
  { id: "Z41bAl", label: "Slobozia - Jud. VS" },
  { id: "rwyX3l", label: "Slobozia Blaneasa - Jud. GL" },
  { id: "rqxjPm", label: "Slobozia Botesti - Jud. VN" },
  { id: "lpxe6l", label: "Slobozia Bradului - Jud. VN" },
  { id: "lWMVyr", label: "Slobozia Ciorasti - Jud. VN" },
  { id: "rqbkPZ", label: "Slobozia Conachi - Jud. GL" },
  { id: "ZNG4Ql", label: "Slobozia Corni - Jud. GL" },
  { id: "rKjw9m", label: "Slobozia Hanesti - Jud. BT" },
  { id: "l0K46Z", label: "Slobozia Mandra - Jud. TR" },
  { id: "Z4qaQl", label: "Slobozia Moara - Jud. DB" },
  { id: "lDAabm", label: "Slobozia Noua - Jud. BC" },
  { id: "lD2ybr", label: "Slobozia Noua - Jud. IL" },
  { id: "rwyXXl", label: "Slobozia Oancea - Jud. GL" },
  { id: "lOw2ol", label: "Slobozia Siliscani - Jud. BT" },
  { id: "lDaA2m", label: "Slobozia Sucevei - Jud. SV" },
  { id: "rwExyl", label: "Slon - Jud. PH" },
  { id: "ZyNQ2l", label: "Smadovicioara De Secu - Jud. DJ" },
  { id: "rBj9Dr", label: "Smadovita - Jud. MH" },
  { id: "lOwQ3l", label: "Smardan - Jud. BT" },
  { id: "mb9xBl", label: "Smardan - Jud. BZ" },
  { id: "r8YGVZ", label: "Smardan - Jud. DJ" },
  { id: "ZQqJvZ", label: "Smardan - Jud. GL" },
  { id: "ma6Kbr", label: "Smardan - Jud. TL" },
  { id: "r6xxpl", label: "Smardan - Jud. TR" },
  { id: "ZR6vLZ", label: "Smardioasa - Jud. TR" },
  { id: "rqzLvZ", label: "Smeeni - Jud. BZ" },
  { id: "lYy7wl", label: "Smeesti - Jud. BZ" },
  { id: "wlpd2l", label: "Smei - Jud. AG" },
  { id: "5l7Adr", label: "Smeura - Jud. AG" },
  { id: "mAbzgr", label: "Smida - Jud. CJ" },
  { id: "mnpKGZ", label: "Smida Ungurenilor - Jud. SV" },
  { id: "rERbBZ", label: "Smig - Jud. SB" },
  { id: "ZRwkyl", label: "Smirna - Jud. IL" },
  { id: "r3GEGr", label: "Smulti - Jud. GL" },
  { id: "lJ2j3l", label: "Snagov - Jud. IF" },
  { id: "l78ydm", label: "Snamana - Jud. VL" },
  { id: "XlxEMm", label: "Snide - Jud. AB" },
  { id: "XlxDzl", label: "Soal - Jud. AB" },
  { id: "ZQa1pZ", label: "Soala - Jud. SB" },
  { id: "mGPbBr", label: "Soard - Jud. MS" },
  { id: "r33Aqr", label: "Soars - Jud. BV" },
  { id: "roxLqZ", label: "Soca - Jud. TM" },
  { id: "lp5qPm", label: "Socea - Jud. NT" },
  { id: "rd5e2Z", label: "Soceni - Jud. CS" },
  { id: "rEdBLm", label: "Soceni - Jud. DJ" },
  { id: "mk8Jqr", label: "Socet - Jud. BH" },
  { id: "ZVwzol", label: "Socet - Jud. HD" },
  { id: "lJNdgl", label: "Socetu - Jud. TR" },
  { id: "ZP27Dm", label: "Soci - Jud. BC" },
  { id: "rozwNm", label: "Soci - Jud. IS" },
  { id: "lLVpXr", label: "Soci - Jud. NT" },
  { id: "lDaYjm", label: "Soci - Jud. VS" },
  { id: "mb9Jdl", label: "Socoalele - Jud. CL" },
  { id: "3r2ydm", label: "Socodor - Jud. AR" },
  { id: "ZPeXbl", label: "Socol - Jud. CS" },
  { id: "mnJ48m", label: "Socolari - Jud. CS" },
  { id: "ZMVoyl", label: "Socolu De Campie - Jud. MS" },
  { id: "ZMG5gm", label: "Socond - Jud. SM" },
  { id: "l7yxLr", label: "Soconzel - Jud. SM" },
  { id: "mk8dQr", label: "Socrujeni - Jud. BT" },
  { id: "mbo3qr", label: "Socu - Jud. GJ" },
  { id: "ZM94zZ", label: "Sodomeni - Jud. IS" },
  { id: "ZPA53l", label: "Sofieni - Jud. VS" },
  { id: "OroWqr", label: "Sofronea - Jud. AR" },
  { id: "mnpwpZ", label: "Sofronesti - Jud. VS" },
  { id: "BZ4JmV", label: "Soharu - Jud. AB" },
  { id: "mnJ6Am", label: "Sohatu - Jud. CL" },
  { id: "OroP8Z", label: "Sohodol - Jud. AB" },
  { id: "mnPJNZ", label: "Sohodol - Jud. BC" },
  { id: "r33RAr", label: "Sohodol - Jud. BH" },
  { id: "Z4Yx0l", label: "Sohodol - Jud. BV" },
  { id: "lxb0Dr", label: "Sohodol - Jud. GJ" },
  { id: "ZM9wjZ", label: "Sohodol - Jud. HD" },
  { id: "r333nr", label: "Sohodor - Jud. BC" },
  { id: "BmaoDZ", label: "Soicesti - Jud. AB" },
  { id: "r3BEYm", label: "Soimaresti - Jud. NT" },
  { id: "ZyY32Z", label: "Soimari - Jud. PH" },
  { id: "rodNwZ", label: "Soimeni - Jud. CJ" },
  { id: "rKnBWm", label: "Soimeni - Jud. HR" },
  { id: "l7y6vr", label: "Soimesti - Jud. PH" },
  { id: "r82qMl", label: "Soimi - Jud. BH" },
  { id: "LmnRAm", label: "Soimos - Jud. AR" },
  { id: "ZyWd4m", label: "Soimu - Jud. TR" },
  { id: "arBB2r", label: "Soimus - Jud. AB" },
  { id: "ma10Em", label: "Soimus - Jud. BH" },
  { id: "mAA3Bm", label: "Soimus - Jud. BN" },
  { id: "mgLVDr", label: "Soimus - Jud. HD" },
  { id: "lLV9br", label: "Soimus - Jud. MS" },
  { id: "l7yG3r", label: "Soimus - Jud. SJ" },
  { id: "mgBDql", label: "Soimuseni - Jud. SJ" },
  { id: "ZRw72l", label: "Soimusu Mare - Jud. HR" },
  { id: "rdoaGm", label: "Soimusu Mic - Jud. HR" },
  { id: "r3kOGr", label: "Solacolu - Jud. CL" },
  { id: "Z4DPjZ", label: "Solca - Jud. NT" },
  { id: "Z49Gjl", label: "Solca - Jud. SV" },
  { id: "lWB4qZ", label: "Soldanesti - Jud. BT" },
  { id: "lpA2xm", label: "Soldanesti - Jud. SV" },
  { id: "l71Lqr", label: "Soldanu - Jud. CL" },
  { id: "mgBKAl", label: "Solduba - Jud. SM" },
  { id: "m1bz3r", label: "Solesti - Jud. VS" },
  { id: "ZPGaMr", label: "Solicesti - Jud. VL" },
  { id: "rK2Qem", label: "Solocma - Jud. MS" },
  { id: "lj4akr", label: "Solomon - Jud. SJ" },
  { id: "rqp6om", label: "Solona - Jud. SJ" },
  { id: "mgLn1r", label: "Solonet - Jud. IS" },
  { id: "rEa2Vr", label: "Solonet - Jud. SV" },
  { id: "mnzO8m", label: "Solonetu Nou - Jud. SV" },
  { id: "rXd3Lr", label: "Solont - Jud. BC" },
  { id: "rE6jxZ", label: "Solovastru - Jud. MS" },
  { id: "lezz1m", label: "Somanesti - Jud. GJ" },
  { id: "rd7wqm", label: "Somartin - Jud. SB" },
  { id: "l7wv3l", label: "Somcuta Mare - Jud. MM" },
  { id: "lDDvKl", label: "Somcutu Mic - Jud. CJ" },
  { id: "rwEXal", label: "Somes Guruslau - Jud. SJ" },
  { id: "rX7Rgm", label: "Somes Odorhei - Jud. SJ" },
  { id: "rXOL3m", label: "Somes Uileac - Jud. MM" },
  { id: "lDq02m", label: "Someseni - Jud. SM" },
  { id: "l08nkl", label: "Somesu Cald - Jud. CJ" },
  { id: "ZRJOAZ", label: "Somesu Rece - Jud. CJ" },
  { id: "yZyO4m", label: "Somosches - Jud. AR" },
  { id: "Zy6Q4l", label: "Somostelnic - Jud. MS" },
  { id: "Zvzq3Z", label: "Somova - Jud. TL" },
  { id: "arBwdZ", label: "Somusca - Jud. BC" },
  { id: "nljPKr", label: "Sona - Jud. AB" },
  { id: "l7vqwZ", label: "Sona - Jud. BV" },
  { id: "mGaE6Z", label: "Soparleni - Jud. VS" },
  { id: "rBnbJr", label: "Soparlita - Jud. OT" },
  { id: "r3kE4r", label: "Soporu De Campie - Jud. CJ" },
  { id: "mAOoKZ", label: "Sopot - Jud. DJ" },
  { id: "r6OJ3Z", label: "Sopotu Nou - Jud. CS" },
  { id: "r2YAQm", label: "Sopotu Vechi - Jud. CS" },
  { id: "rXdb3r", label: "Sopteriu - Jud. BN" },
  { id: "rBO2DZ", label: "Soreni - Jud. DJ" },
  { id: "ZNWYVl", label: "Soresti - Jud. BZ" },
  { id: "0lYDOZ", label: "Sorlita - Jud. AB" },
  { id: "l7vgYZ", label: "Soroceni - Jud. BT" },
  { id: "rBboQr", label: "Sorogani - Jud. IS" },
  { id: "lJ2M4l", label: "Soromiclea - Jud. MS" },
  { id: "lJ9pAZ", label: "Sorostin - Jud. SB" },
  { id: "mGwoWm", label: "Sosdea - Jud. CS" },
  { id: "l52W9m", label: "Sotanga - Jud. DB" },
  { id: "mA86ol", label: "Sotani - Jud. VL" },
  { id: "ZzPQKm", label: "Sotarcari - Jud. VN" },
  { id: "rKD7PZ", label: "Sotrile - Jud. PH" },
  { id: "lWRykZ", label: "Sovarna - Jud. MH" },
  { id: "l7w0Ll", label: "Sovata - Jud. MS" },
  { id: "mk5ePZ", label: "Soveja - Jud. VN" },
  { id: "ZMVqGl", label: "Sovoaia - Jud. NT" },
  { id: "mnQQ8l", label: "Spahii - Jud. GJ" },
  { id: "wlpVPr", label: "Spalnaca - Jud. AB" },
  { id: "lDDL1l", label: "Spantov - Jud. CL" },
  { id: "r9bq3Z", label: "Sparleni - Jud. VL" },
  { id: "r6x4Wl", label: "Spata - Jud. TM" },
  { id: "AZvwl3", label: "Spatac - Jud. AB" },
  { id: "ZPGNjr", label: "Spatareasa - Jud. VN" },
  { id: "r30JGZ", label: "Spatarei - Jud. TR" },
  { id: "r2MeQm", label: "Spataresti - Jud. SV" },
  { id: "ZNWJ1l", label: "Spataru - Jud. BZ" },
  { id: "rq0K9m", label: "Spataru - Jud. OT" },
  { id: "lO6dGZ", label: "Sperieteni - Jud. DB" },
  { id: "lWR9BZ", label: "Sperlesti - Jud. MH" },
  { id: "r82L6l", label: "Spermezeu - Jud. BN" },
  { id: "rBWnJZ", label: "Spidele - Jud. BZ" },
  { id: "lp5Yem", label: "Spiesti - Jud. NT" },
  { id: "r9nKMZ", label: "Spineni - Jud. DJ" },
  { id: "lD2vnr", label: "Spineni - Jud. IS" },
  { id: "rBnRgr", label: "Spineni - Jud. OT" },
  { id: "lej1dm", label: "Spinesti - Jud. VN" },
  { id: "lpb0Mr", label: "Spini - Jud. HD" },
  { id: "ZRwyNl", label: "Spinoasa - Jud. IS" },
  { id: "rE46Lm", label: "Spinu - Jud. VL" },
  { id: "lJQgwZ", label: "Spinus - Jud. BH" },
  { id: "rqPv9m", label: "Spinus De Pomezeu - Jud. BH" },
  { id: "lOAQ3r", label: "Spiridonesti - Jud. NT" },
  { id: "krdE5Z", label: "Spiridoni - Jud. AG" },
  { id: "lLaP1m", label: "Spiru Haret - Jud. BR" },
  { id: "rdonAm", label: "Spranceana - Jud. IS" },
  { id: "rqpOom", label: "Sprancenata - Jud. OT" },
  { id: "4lO9Ar", label: "Spria - Jud. BC" },
  { id: "Wr6agm", label: "Spring - Jud. AB" },
  { id: "r36anm", label: "Spulber - Jud. VN" },
  { id: "WmGPnr", label: "Stalnisoara - Jud. AB" },
  { id: "LZMNgl", label: "Stalpeni - Jud. AG" },
  { id: "ZQvYnl", label: "Stalpu - Jud. BZ" },
  { id: "ZVLvom", label: "Stalpu - Jud. GR" },
  { id: "ZNJRaZ", label: "Stamate - Jud. SV" },
  { id: "l79evr", label: "Stamora Germana - Jud. TM" },
  { id: "mk1ebZ", label: "Stamora Romana - Jud. TM" },
  { id: "rd7JRm", label: "Stana - Jud. SJ" },
  { id: "mGWX6m", label: "Stana - Jud. SM" },
  { id: "Wr8Kqm", label: "Stana De Mures - Jud. AB" },
  { id: "mAbb6r", label: "Stanca - Jud. BR" },
  { id: "lxxwol", label: "Stanca - Jud. BT" },
  { id: "lD2V1r", label: "Stanca - Jud. IS" },
  { id: "rK2qPm", label: "Stanca - Jud. NT" },
  { id: "l79wLr", label: "Stanca - Jud. SV" },
  { id: "rXanpZ", label: "Stanca - Jud. TL" },
  { id: "ZR62LZ", label: "Stancaseni - Jud. VS" },
  { id: "r2Y1bm", label: "Stancea - Jud. CL" },
  { id: "ZMV4pl", label: "Stanceni - Jud. MS" },
  { id: "mG5JpZ", label: "Stancesti - Jud. BH" },
  { id: "mnPkBZ", label: "Stancesti - Jud. BT" },
  { id: "l71jvr", label: "Stancesti - Jud. BZ" },
  { id: "rKnjQm", label: "Stancesti - Jud. GJ" },
  { id: "l7Je5Z", label: "Stancesti - Jud. HD" },
  { id: "r2wbpZ", label: "Stancesti - Jud. MH" },
  { id: "rERMMZ", label: "Stancesti - Jud. PH" },
  { id: "r2QVpm", label: "Stancesti Larga - Jud. GJ" },
  { id: "mGxeDr", label: "Stancesti Ohaba - Jud. HD" },
  { id: "Z4w7xr", label: "Stancilova - Jud. CS" },
  { id: "lJNvWl", label: "Stanciova - Jud. TM" },
  { id: "lL1MAm", label: "Stanculesti - Jud. HD" },
  { id: "lYPLwZ", label: "Stanculesti - Jud. VL" },
  { id: "ljN9Kl", label: "Stancuta - Jud. BR" },
  { id: "mGaP6Z", label: "Stancuta - Jud. SV" },
  { id: "LmnBxm", label: "Stanesti - Jud. AB" },
  { id: "arBoaZ", label: "Stanesti - Jud. AG" },
  { id: "rEQ7qr", label: "Stanesti - Jud. BC" },
  { id: "lJPNYr", label: "Stanesti - Jud. DB" },
  { id: "mA4G8l", label: "Stanesti - Jud. GJ" },
  { id: "ronV3m", label: "Stanesti - Jud. GR" },
  { id: "l7w8Xl", label: "Stanesti - Jud. MH" },
  { id: "rXx27r", label: "Stanesti - Jud. VL" },
  { id: "maNojr", label: "Stanesti Lunca - Jud. VL" },
  { id: "ZMVDQl", label: "Stangaceaua - Jud. MH" },
  { id: "XlxXpl", label: "Stanicei - Jud. AG" },
  { id: "l5QaRl", label: "Stanija - Jud. HD" },
  { id: "ZV1w6r", label: "Stanila - Jud. BZ" },
  { id: "lOYaLr", label: "Stanilesti - Jud. VS" },
  { id: "Z4YKql", label: "Stanisesti - Jud. BC" },
  { id: "mgawkZ", label: "Stanita - Jud. NT" },
  { id: "rdNpGl", label: "Stanomiru - Jud. VL" },
  { id: "r86BPl", label: "Stanuleasa - Jud. OT" },
  { id: "r3yYAl", label: "Starchiojd - Jud. PH" },
  { id: "olWdEm", label: "Starci - Jud. AG" },
  { id: "rBnoJr", label: "Starciu - Jud. SJ" },
  { id: "rE7gql", label: "Starcu - Jud. CJ" },
  { id: "lDPEJl", label: "Statesti - Jud. DB" },
  { id: "r9q38r", label: "Statiunea Climaterica Sambata - Jud. BV" },
  { id: "ZzEY8r", label: "Stauceni - Jud. BT" },
  { id: "JlJ1wZ", label: "Stauini - Jud. AB" },
  { id: "rE2vgZ", label: "Stauini - Jud. HD" },
  { id: "lW1ONr", label: "Stavarasti - Jud. BZ" },
  { id: "ZzzwbZ", label: "Stavaru - Jud. OT" },
  { id: "lO6MLZ", label: "Stavropolia - Jud. DB" },
  { id: "Nmk3om", label: "Stefan Cel Mare - Jud. AG" },
  { id: "ZVKkRl", label: "Stefan Cel Mare - Jud. BC" },
  { id: "r8DLMr", label: "Stefan Cel Mare - Jud. CL" },
  { id: "lpE1Pm", label: "Stefan Cel Mare - Jud. CT" },
  { id: "mbxMvZ", label: "Stefan Cel Mare - Jud. NT" },
  { id: "rKDxEZ", label: "Stefan Cel Mare - Jud. OT" },
  { id: "ZyWn8m", label: "Stefan Cel Mare - Jud. VS" },
  { id: "roEgLl", label: "Stefan Odobleja - Jud. MH" },
  { id: "lxxPPl", label: "Stefan Voda - Jud. BC" },
  { id: "l54LAZ", label: "Stefan Voda - Jud. CL" },
  { id: "Jr3Qzl", label: "Stefanca - Jud. AB" },
  { id: "ZQVAol", label: "Stefanca - Jud. MS" },
  { id: "l07akZ", label: "Stefaneaca - Jud. MS" },
  { id: "lxbL7r", label: "Stefanel - Jud. DJ" },
  { id: "2l56Ym", label: "Stefanesti - Jud. AG" },
  { id: "rKjLRm", label: "Stefanesti - Jud. BT" },
  { id: "r8DaDr", label: "Stefanesti - Jud. CL" },
  { id: "r6qX6m", label: "Stefanesti - Jud. GJ" },
  { id: "lpxQol", label: "Stefanesti - Jud. VL" },
  { id: "lYD4xZ", label: "Stefanesti Sat - Jud. BT" },
  { id: "lYnLAm", label: "Stefanestii De Jos - Jud. IF" },
  { id: "lD6bKr", label: "Stefanestii De Sus - Jud. IF" },
  { id: "Jr3M0l", label: "Stefanestii Noi - Jud. AG" },
  { id: "ZzVBKZ", label: "Stefeni - Jud. TR" },
  { id: "lDqJ2m", label: "Stefesti - Jud. PH" },
  { id: "rKjBGm", label: "Stei - Jud. BH" },
  { id: "rXqe9Z", label: "Stei - Jud. HD" },
  { id: "olWeAr", label: "Stei Arieseni - Jud. AB" },
  { id: "rdo1am", label: "Steia - Jud. HD" },
  { id: "mG12BZ", label: "Steic - Jud. GJ" },
  { id: "l54W0Z", label: "Steierdorf - Jud. CS" },
  { id: "Xlxanm", label: "Stejar - Jud. AR" },
  { id: "r3QN5l", label: "Stejarel - Jud. HD" },
  { id: "ZRKgql", label: "Stejarenii - Jud. MS" },
  { id: "grKk9r", label: "Stejari - Jud. AG" },
  { id: "ZNAwar", label: "Stejari - Jud. GJ" },
  { id: "Zzw6Wl", label: "Stejarii - Jud. IS" },
  { id: "mkzyQl", label: "Stejarisu - Jud. SB" },
  { id: "ljG4dm", label: "Stejaru - Jud. BC" },
  { id: "mnxPGZ", label: "Stejaru - Jud. CT" },
  { id: "l5Q4pl", label: "Stejaru - Jud. GJ" },
  { id: "lJPBgr", label: "Stejaru - Jud. GR" },
  { id: "m1q3Ym", label: "Stejaru - Jud. IL" },
  { id: "lJ2wpl", label: "Stejaru - Jud. MH" },
  { id: "m1Ga6Z", label: "Stejaru - Jud. NT" },
  { id: "rKDj0Z", label: "Stejaru - Jud. OT" },
  { id: "leGEYr", label: "Stejaru - Jud. PH" },
  { id: "lj80wr", label: "Stejaru - Jud. TL" },
  { id: "rdK9Wm", label: "Stejaru - Jud. TR" },
  { id: "ZVyVor", label: "Stejaru - Jud. VS" },
  { id: "m1Gq9Z", label: "Stejera - Jud. MM" },
  { id: "mkaE2Z", label: "Stejerei - Jud. GJ" },
  { id: "rwp2yZ", label: "Stejeris - Jud. CJ" },
  { id: "r8BkDl", label: "Stejeris - Jud. MS" },
  { id: "ZyGykm", label: "Stelnica - Jud. IL" },
  { id: "r2q6pm", label: "Stenea - Jud. SB" },
  { id: "ZVLV6m", label: "Sterea - Jud. GR" },
  { id: "ZQqRpZ", label: "Sterpoaia - Jud. GJ" },
  { id: "AZvgKl", label: "Stertesti - Jud. AB" },
  { id: "rqEQ1r", label: "Sticlaria - Jud. IS" },
  { id: "l7Q2nr", label: "Stietesti - Jud. GL" },
  { id: "lOAMNr", label: "Stignita - Jud. MH" },
  { id: "lpz4er", label: "Stinapari - Jud. CS" },
  { id: "mnpVpZ", label: "Stioborani - Jud. VS" },
  { id: "rBRb2r", label: "Stirbat - Jud. SV" },
  { id: "mk58bZ", label: "Stirbesti - Jud. VL" },
  { id: "lxAe0m", label: "Stircovita - Jud. MH" },
  { id: "l71Rvr", label: "Stiubei - Jud. BZ" },
  { id: "lLD8Xl", label: "Stiubei - Jud. DJ" },
  { id: "lxxdMl", label: "Stiubieni - Jud. BT" },
  { id: "rEaDMr", label: "Stiuca - Jud. TM" },
  { id: "r2Q7vm", label: "Stiucani - Jud. GJ" },
  { id: "VrXN7l", label: "Stiuleti - Jud. AB" },
  { id: "mb5OBl", label: "Stoborasti - Jud. OT" },
  { id: "ZQaLvZ", label: "Stoboru - Jud. SJ" },
  { id: "Vle7Or", label: "Stoenesti - Jud. AG" },
  { id: "lDDj1l", label: "Stoenesti - Jud. CL" },
  { id: "maowJZ", label: "Stoenesti - Jud. GR" },
  { id: "ZQayoZ", label: "Stoenesti - Jud. OT" },
  { id: "mkzpBl", label: "Stoenesti - Jud. PH" },
  { id: "Z4Np1m", label: "Stoenesti - Jud. VL" },
  { id: "Z4wEpr", label: "Stoiana - Jud. CJ" },
  { id: "r3y05l", label: "Stoicanesti - Jud. OT" },
  { id: "rB5bQm", label: "Stoicanesti - Jud. VL" },
  { id: "rqby9Z", label: "Stoicani - Jud. GL" },
  { id: "r9y7yr", label: "Stoiceni - Jud. MM" },
  { id: "Z4qAJl", label: "Stoicesti - Jud. DJ" },
  { id: "ZPGGEr", label: "Stoiculesti - Jud. VL" },
  { id: "l7JxvZ", label: "Stoieneasa - Jud. HD" },
  { id: "lDDbjl", label: "Stoienesti - Jud. BR" },
  { id: "lxz6zr", label: "Stoilesti - Jud. VL" },
  { id: "r8O6Km", label: "Stoina - Jud. GJ" },
  { id: "nljVkl", label: "Stoinesti - Jud. AR" },
  { id: "mA9o2m", label: "Stoisesti - Jud. VS" },
  { id: "lYypOl", label: "Stolna - Jud. CJ" },
  { id: "lWBJAZ", label: "Stolniceni - Jud. BT" },
  { id: "Zvz8JZ", label: "Stolniceni - Jud. VL" },
  { id: "mbO32r", label: "Stolniceni Prajescu - Jud. IS" },
  { id: "wlp2Wr", label: "Stolnici - Jud. AG" },
  { id: "maoQ7Z", label: "Stolojani - Jud. GJ" },
  { id: "r9qBxr", label: "Storesti - Jud. BT" },
  { id: "ZyGJom", label: "Stornesti - Jud. IS" },
  { id: "ma6OJr", label: "Storobaneasa - Jud. TR" },
  { id: "l0kpPZ", label: "Strachinesti - Jud. VL" },
  { id: "l7vbwZ", label: "Stracos - Jud. BH" },
  { id: "ZQQ6aZ", label: "Strahotin - Jud. BT" },
  { id: "BZ43qr", label: "Straja - Jud. AB" },
  { id: "Xlx9wm", label: "Straja - Jud. BC" },
  { id: "r97kyl", label: "Straja - Jud. CJ" },
  { id: "r6qO7m", label: "Straja - Jud. CT" },
  { id: "ZMVp4l", label: "Straja - Jud. NT" },
  { id: "mk1zoZ", label: "Straja - Jud. SV" },
  { id: "ZNDnxm", label: "Strajescu - Jud. VN" },
  { id: "rKjXem", label: "Stramba - Jud. BN" },
  { id: "lDP1Jl", label: "Stramba Jiu - Jud. GJ" },
  { id: "rqbK0Z", label: "Stramba Vulcan - Jud. GJ" },
  { id: "Xl0ooZ", label: "Strambeni - Jud. AG" },
  { id: "lOW3Ll", label: "Strambu - Jud. CJ" },
  { id: "m1Gn3Z", label: "Strambu Baiut - Jud. MM" },
  { id: "rqPEQm", label: "Straminoasa - Jud. BC" },
  { id: "lOD63m", label: "Stramtu - Jud. GJ" },
  { id: "lj381m", label: "Stramtura - Jud. MM" },
  { id: "roxzNZ", label: "Stramtura - Jud. SV" },
  { id: "ZNJbQZ", label: "Stramtura Mitoc - Jud. VS" },
  { id: "mndeNZ", label: "Straoane - Jud. VN" },
  { id: "leoE6r", label: "Straosti - Jud. DB" },
  { id: "ZV3WRl", label: "Straosti - Jud. PH" },
  { id: "ropQwZ", label: "Strateni - Jud. BT" },
  { id: "ZzgjBl", label: "Stratonesti - Jud. DB" },
  { id: "rwPYjZ", label: "Strehaia - Jud. MH" },
  { id: "lpbyWr", label: "Strei - Jud. HD" },
  { id: "lWO5dl", label: "Strei Sacel - Jud. HD" },
  { id: "mkaqoZ", label: "Streisangeorgiu - Jud. HD" },
  { id: "m1Bb3r", label: "Strejesti - Jud. OT" },
  { id: "lOBDAZ", label: "Strejestii De Sus - Jud. OT" },
  { id: "lxKRpZ", label: "Strejnicu - Jud. PH" },
  { id: "ma6dWr", label: "Streminoasa - Jud. VL" },
  { id: "Bma0el", label: "Stremt - Jud. AB" },
  { id: "lD6DYr", label: "Stremt - Jud. MM" },
  { id: "lJWgzm", label: "Streneac - Jud. CS" },
  { id: "rwD3xZ", label: "Stretea - Jud. HD" },
  { id: "r8Dd0r", label: "Strezeni - Jud. BZ" },
  { id: "grK1Pl", label: "Stroesti - Jud. AG" },
  { id: "ljN5Kl", label: "Stroesti - Jud. BZ" },
  { id: "rwDRXZ", label: "Stroesti - Jud. IS" },
  { id: "r3Bezm", label: "Stroesti - Jud. MH" },
  { id: "rE43Vm", label: "Stroesti - Jud. VL" },
  { id: "Zv2VKZ", label: "Stroiesti - Jud. BT" },
  { id: "ZyNR0l", label: "Stroiesti - Jud. GJ" },
  { id: "lOY6Jr", label: "Stroiesti - Jud. SV" },
  { id: "rXaP9Z", label: "Stroiesti - Jud. VS" },
  { id: "ZMRqjl", label: "Strucut - Jud. CJ" },
  { id: "lxxg7l", label: "Strugari - Jud. BC" },
  { id: "ZMR04l", label: "Strugasca - Jud. CS" },
  { id: "l7yJ5r", label: "Strugurelu - Jud. OT" },
  { id: "rXd54r", label: "Strugureni - Jud. BN" },
  { id: "lJPQ9r", label: "Strunga - Jud. CT" },
  { id: "lL1jbm", label: "Strunga - Jud. IS" },
  { id: "ElLqYm", label: "Strungari - Jud. AB" },
  { id: "ronO3m", label: "Stubeie Tisa - Jud. DB" },
  { id: "mkz02l", label: "Stubeiu - Jud. PH" },
  { id: "leL1XZ", label: "Studena - Jud. CS" },
  { id: "ZNY9er", label: "Studina - Jud. MH" },
  { id: "lOBYLZ", label: "Studina - Jud. OT" },
  { id: "rKDnxZ", label: "Studinita - Jud. OT" },
  { id: "rKjD9m", label: "Stufu - Jud. BC" },
  { id: "rEaLVr", label: "Stuhulet - Jud. VS" },
  { id: "mnpzaZ", label: "Stulpicani - Jud. SV" },
  { id: "ZNDG6m", label: "Stuparei - Jud. VL" },
  { id: "lW1Gor", label: "Stupina - Jud. CT" },
  { id: "ZRKo2l", label: "Stupina - Jud. OT" },
  { id: "ZQw7vm", label: "Stupina - Jud. VN" },
  { id: "lOwRGl", label: "Stupini - Jud. BN" },
  { id: "lDqvKm", label: "Stupini - Jud. SJ" },
  { id: "ZNy6er", label: "Stupinii Prejmerului - Jud. BV" },
  { id: "wlpExm", label: "Sturu - Jud. AB" },
  { id: "ljNXkl", label: "Suaras - Jud. CJ" },
  { id: "mGwR9m", label: "Suatu - Jud. CJ" },
  { id: "l7JRnZ", label: "Sub Cetate - Jud. HR" },
  { id: "lj47Nr", label: "Sub Cetate - Jud. SJ" },
  { id: "Zzo5bl", label: "Sub Coasta - Jud. CJ" },
  { id: "lpzDPr", label: "Sub Crang - Jud. CS" },
  { id: "lJ629l", label: "Sub Deal - Jud. VL" },
  { id: "lW1yor", label: "Sub Margine - Jud. CS" },
  { id: "mGPGDr", label: "Sub Padure - Jud. MS" },
  { id: "Lmnojr", label: "Sub Piatra - Jud. AB" },
  { id: "lW1aYr", label: "Sub Plai - Jud. CS" },
  { id: "l7JBAZ", label: "Subcetate - Jud. HD" },
  { id: "Z43dAr", label: "Subcetate - Jud. HR" },
  { id: "Z4YnAl", label: "Subpiatra - Jud. BH" },
  { id: "l71k5r", label: "Suceagu - Jud. CJ" },
  { id: "rERn6Z", label: "Suceava - Jud. SV" },
  { id: "PrEaLr", label: "Sucesti - Jud. AB" },
  { id: "m1DEql", label: "Suceveni - Jud. GL" },
  { id: "rwV13m", label: "Suceveni - Jud. VS" },
  { id: "lDaqjm", label: "Sucevita - Jud. SV" },
  { id: "ZRJ3DZ", label: "Suchea - Jud. BZ" },
  { id: "ZQVaal", label: "Suciu De Jos - Jud. MM" },
  { id: "ZPaAvl", label: "Suciu De Sus - Jud. MM" },
  { id: "r8D86r", label: "Sucutard - Jud. CJ" },
  { id: "mkw7Bm", label: "Suditi - Jud. BZ" },
  { id: "rKnLGm", label: "Suditi - Jud. IL" },
  { id: "rBR8Dr", label: "Sudrias - Jud. TM" },
  { id: "r9q0qr", label: "Sudrigiu - Jud. BH" },
  { id: "r3Gn4r", label: "Suduleni - Jud. DB" },
  { id: "mGWXdm", label: "Sudurau - Jud. SM" },
  { id: "Rr9XNm", label: "Sugag - Jud. AB" },
  { id: "mgaPBZ", label: "Sugau - Jud. MM" },
  { id: "roxg3Z", label: "Suhaia - Jud. TR" },
  { id: "ZzEa3r", label: "Suharau - Jud. BT" },
  { id: "mboJQr", label: "Suharu - Jud. DJ" },
  { id: "lWRQoZ", label: "Suharu - Jud. MH" },
  { id: "lezwOm", label: "Suhulet - Jud. IS" },
  { id: "ZzgqWl", label: "Suhurlui - Jud. GL" },
  { id: "rdxOGr", label: "Suica - Jud. OT" },
  { id: "Lmnyal", label: "Suici - Jud. AG" },
  { id: "l5O93r", label: "Suiesti - Jud. VL" },
  { id: "ljGD4m", label: "Suiug - Jud. BH" },
  { id: "rKx0EZ", label: "Suletea - Jud. VS" },
  { id: "r2QBem", label: "Sulighete - Jud. HD" },
  { id: "ZyWxkm", label: "Sulina - Jud. TL" },
  { id: "rXdpnr", label: "Sulita - Jud. BT" },
  { id: "Wr6Bgr", label: "Sulta - Jud. BC" },
  { id: "rE7kol", label: "Sultana - Jud. CL" },
  { id: "m1DJ3l", label: "Sultanu - Jud. DB" },
  { id: "mkz4zl", label: "Sumal - Jud. SJ" },
  { id: "rKPyPZ", label: "Sumandra - Jud. DJ" },
  { id: "mGwNpm", label: "Sumita - Jud. CS" },
  { id: "r82vql", label: "Sumugiu - Jud. BH" },
  { id: "rXLpRr", label: "Sumurducu - Jud. CJ" },
  { id: "lWDGYm", label: "Sunatori - Jud. SV" },
  { id: "m1kJRZ", label: "Suncuis - Jud. BH" },
  { id: "l51GAl", label: "Suncuius - Jud. BH" },
  { id: "l0pzJl", label: "Supitca - Jud. BT" },
  { id: "lex43Z", label: "Suplac - Jud. MS" },
  { id: "lYDkVZ", label: "Suplacu De Barcau - Jud. BH" },
  { id: "r2V4Qr", label: "Suplacu De Tinca - Jud. BH" },
  { id: "lWBbyZ", label: "Suplai - Jud. BN" },
  { id: "rwEeXl", label: "Supuru De Jos - Jud. SM" },
  { id: "lOBXOZ", label: "Supuru De Sus - Jud. SM" },
  { id: "ZyGNkm", label: "Sura - Jud. GJ" },
  { id: "mb5Bal", label: "Sura Mare - Jud. SB" },
  { id: "lL7APZ", label: "Sura Mica - Jud. SB" },
  { id: "r84nPr", label: "Suraia - Jud. VN" },
  { id: "m1BYjr", label: "Surani - Jud. PH" },
  { id: "mG1W6Z", label: "Surcea - Jud. CV" },
  { id: "AZQNJl", label: "Surdesti - Jud. AB" },
  { id: "lYnGOm", label: "Surdesti - Jud. MM" },
  { id: "rBn5gr", label: "Surdesti - Jud. PH" },
  { id: "ZPeBKl", label: "Surdila Gaiseanca - Jud. BR" },
  { id: "m1nnar", label: "Surdila Greci - Jud. BR" },
  { id: "ZM6V4l", label: "Surdoiu - Jud. VL" },
  { id: "r82MKl", label: "Surduc - Jud. BH" },
  { id: "lLawGm", label: "Surduc - Jud. CJ" },
  { id: "Z49EQl", label: "Surduc - Jud. SJ" },
  { id: "rwnqXm", label: "Surducel - Jud. BH" },
  { id: "lLaGXm", label: "Surducu Mare - Jud. CS" },
  { id: "r30wYZ", label: "Surducu Mic - Jud. TM" },
  { id: "ElLBXr", label: "Surdulesti - Jud. AG" },
  { id: "roYznl", label: "Suricaru - Jud. VL" },
  { id: "ZNyRVr", label: "Surina - Jud. BC" },
  { id: "Z4388r", label: "Surlari - Jud. IF" },
  { id: "ljoE1m", label: "Surpatele - Jud. VL" },
  { id: "mGgWpm", label: "Surpati - Jud. VL" },
  { id: "rBnOdr", label: "Surpeni - Jud. OT" },
  { id: "WZPM3l", label: "Susag - Jud. AR" },
  { id: "EZznBZ", label: "Susani - Jud. AR" },
  { id: "m1b7Yr", label: "Susani - Jud. TM" },
  { id: "lWM2kr", label: "Susani - Jud. VL" },
  { id: "maLzbl", label: "Susca - Jud. CS" },
  { id: "yZy2Dl", label: "Suseni - Jud. AB" },
  { id: "olWPdm", label: "Suseni - Jud. AG" },
  { id: "lj5N1Z", label: "Suseni - Jud. GJ" },
  { id: "l0XE0l", label: "Suseni - Jud. GR" },
  { id: "m1q79m", label: "Suseni - Jud. HD" },
  { id: "lx7JQZ", label: "Suseni - Jud. HR" },
  { id: "lp5n2m", label: "Suseni - Jud. MS" },
  { id: "ZzPVYm", label: "Suseni - Jud. VL" },
  { id: "rdKMGm", label: "Suseni - Jud. VS" },
  { id: "lxbzQr", label: "Suseni Socetu - Jud. DB" },
  { id: "lxxyPl", label: "Susenii Bargaului - Jud. BN" },
  { id: "lYnjJm", label: "Susita - Jud. MH" },
  { id: "wmgb1l", label: "Suslanesti - Jud. AG" },
  { id: "rKjOWm", label: "Sustiu - Jud. BH" },
  { id: "mnpLBZ", label: "Sustra - Jud. TM" },
  { id: "rXdNnr", label: "Susturogi - Jud. BH" },
  { id: "rE42Mm", label: "Suta - Jud. VL" },
  { id: "lO6NLZ", label: "Suta Seaca - Jud. DB" },
  { id: "roddxZ", label: "Sutesti - Jud. BR" },
  { id: "ZM6eQl", label: "Sutesti - Jud. VL" },
  { id: "mkzGPl", label: "Sutoru - Jud. SJ" },
  { id: "ZRJyLZ", label: "Sutu - Jud. CJ" },
  { id: "l57N2r", label: "Suveica - Jud. MS" },
  { id: "l7Qb3r", label: "Suvita - Jud. DB" },
  { id: "lex0PZ", label: "Svinita - Jud. MH" },
  { id: "rE73Bl", label: "Tabacari - Jud. BZ" },
  { id: "lxKKPZ", label: "Tabaci - Jud. OT" },
  { id: "mbK4or", label: "Tabalaesti - Jud. VS" },
  { id: "mbOEQr", label: "Tabara - Jud. IS" },
  { id: "Zy6Vkl", label: "Tabara - Jud. NT" },
  { id: "mkw5Pm", label: "Tabarasti - Jud. BZ" },
  { id: "lj3Wkm", label: "Tablaseni - Jud. MS" },
  { id: "r2qYbm", label: "Tabonu - Jud. OT" },
  { id: "ZzPGWm", label: "Tabucesti - Jud. VN" },
  { id: "r8D2Dr", label: "Tacau - Jud. BR" },
  { id: "l79DAr", label: "Tacuta - Jud. VS" },
  { id: "rqzdvZ", label: "Taga - Jud. CJ" },
  { id: "ElLzAr", label: "Tagadau - Jud. AR" },
  { id: "l0pMPl", label: "Tagara - Jud. BC" },
  { id: "r82yMl", label: "Tagsoru - Jud. BN" },
  { id: "ZNyOxr", label: "Tagu - Jud. BN" },
  { id: "rqENBr", label: "Taietura - Jud. HR" },
  { id: "AZvL2m", label: "Talagiu - Jud. AR" },
  { id: "ZQV94l", label: "Talapanu - Jud. MH" },
  { id: "l7y7wr", label: "Talea - Jud. PH" },
  { id: "r8YOEZ", label: "Talisoara - Jud. CV" },
  { id: "ZzzJnZ", label: "Talmacel - Jud. SB" },
  { id: "Rr9ear", label: "Talmaci - Jud. AR" },
  { id: "mb5vql", label: "Talmaciu - Jud. SB" },
  { id: "Zye80Z", label: "Talpa - Jud. BT" },
  { id: "ZQV6nl", label: "Talpa - Jud. NT" },
  { id: "rdKL4m", label: "Talpa Bascoveni - Jud. TR" },
  { id: "r89pPZ", label: "Talpa Ograzile - Jud. TR" },
  { id: "ZR6MDZ", label: "Talpa Postei - Jud. TR" },
  { id: "mGPynr", label: "Talpalai - Jud. NT" },
  { id: "Zzg33l", label: "Talpas - Jud. DJ" },
  { id: "ZvgPJl", label: "Talpasesti - Jud. GJ" },
  { id: "lOwvwl", label: "Talpe - Jud. BH" },
  { id: "ma6YEr", label: "Talpigeni - Jud. VS" },
  { id: "r8YVEZ", label: "Talpigi - Jud. GL" },
  { id: "r33ezr", label: "Talpos - Jud. BH" },
  { id: "m1qj8m", label: "Talvesti - Jud. GJ" },
  { id: "mGw42m", label: "Tamadau Mare - Jud. CL" },
  { id: "rodM8Z", label: "Tamadau Mic - Jud. CL" },
  { id: "lLV1Gr", label: "Tamaia - Jud. MM" },
  { id: "m1DQgl", label: "Tamaoani - Jud. GL" },
  { id: "r3yPGl", label: "Tamasa - Jud. SJ" },
  { id: "ZVw2Jl", label: "Tamasasa - Jud. HD" },
  { id: "mnPB8Z", label: "Tamasda - Jud. BH" },
  { id: "Z4DLxZ", label: "Tamaseni - Jud. NT" },
  { id: "rBn85r", label: "Tamaseni - Jud. SM" },
  { id: "lj9YBZ", label: "Tamasesti - Jud. GJ" },
  { id: "mgLWDr", label: "Tamasesti - Jud. HD" },
  { id: "l7w13l", label: "Tamasesti - Jud. MM" },
  { id: "rByeVm", label: "Tamaseu - Jud. BH" },
  { id: "rwyEXl", label: "Tamasfalau - Jud. CV" },
  { id: "rEQE6r", label: "Tamasi - Jud. BC" },
  { id: "rE2OQZ", label: "Tamasi - Jud. IF" },
  { id: "grKRem", label: "Tamasoaia - Jud. BC" },
  { id: "mGxgWr", label: "Tamasu - Jud. HR" },
  { id: "mGgN0m", label: "Tamboesti - Jud. VN" },
  { id: "qZNWLl", label: "Tamboresti - Jud. AB" },
  { id: "ZQqX4Z", label: "Tamburesti - Jud. DJ" },
  { id: "mkE0qZ", label: "Tamna - Jud. MH" },
  { id: "rwDjoZ", label: "Tampa - Jud. HD" },
  { id: "rwP43Z", label: "Tampa - Jud. MS" },
  { id: "lDaVYm", label: "Tanacu - Jud. VS" },
  { id: "r6586m", label: "Tanasari - Jud. VN" },
  { id: "lpVRMr", label: "Tanasesti - Jud. VL" },
  { id: "lDKJJZ", label: "Tanasoaia - Jud. VN" },
  { id: "lp5pbm", label: "Tancabesti - Jud. IF" },
  { id: "rBOYDZ", label: "Tandara - Jud. DJ" },
  { id: "lD2yWr", label: "Tandarei - Jud. IL" },
  { id: "l0NbQr", label: "Tanganu - Jud. IF" },
  { id: "ZNDRLm", label: "Tanislavi - Jud. VL" },
  { id: "Z43Gzr", label: "Tansa - Jud. IS" },
  { id: "ZRw3vl", label: "Tantareni - Jud. GJ" },
  { id: "mb5nql", label: "Tantareni - Jud. PH" },
  { id: "mgaEEZ", label: "Tantaru - Jud. MH" },
  { id: "lDPnKl", label: "Tantava - Jud. GR" },
  { id: "rEaNqr", label: "Tapia - Jud. TM" },
  { id: "l52yYm", label: "Taplau - Jud. GL" },
  { id: "l58nNZ", label: "Tapu - Jud. SB" },
  { id: "grKnGm", label: "Taranesti - Jud. AB" },
  { id: "lWBOqZ", label: "Tarata - Jud. BC" },
  { id: "lYK8Qr", label: "Taratel - Jud. HD" },
  { id: "m18ddl", label: "Taratu - Jud. VN" },
  { id: "ZRjgqZ", label: "Tarcaia - Jud. BH" },
  { id: "ZyeOoZ", label: "Tarcaita - Jud. BH" },
  { id: "lLVAYr", label: "Tarcau - Jud. NT" },
  { id: "l0p50l", label: "Tarcea - Jud. BH" },
  { id: "l0Nvor", label: "Tarcesti - Jud. HR" },
  { id: "rodqqZ", label: "Tarcov - Jud. BZ" },
  { id: "mkjkQl", label: "Tarculesti - Jud. GJ" },
  { id: "lDqBWm", label: "Tarculesti - Jud. PH" },
  { id: "Wr8eMZ", label: "Tardenii Mari - Jud. BC" },
  { id: "rK2wQm", label: "Tardenii Mici - Jud. NT" },
  { id: "rEdaom", label: "Targoviste - Jud. DB" },
  { id: "l59A9l", label: "Targoviste - Jud. TM" },
  { id: "mnz7Xm", label: "Targsoru Nou - Jud. PH" },
  { id: "Z49X0l", label: "Targsoru Vechi - Jud. PH" },
  { id: "mgxMkr", label: "Targu Bujor - Jud. GL" },
  { id: "r8Y7XZ", label: "Targu Carbunesti - Jud. GJ" },
  { id: "mGx6nr", label: "Targu Frumos - Jud. IS" },
  { id: "r89Q6Z", label: "Targu Gangulesti - Jud. VL" },
  { id: "lLDR6l", label: "Targu Jiu - Jud. GJ" },
  { id: "mA1ABm", label: "Targu Lapus - Jud. MM" },
  { id: "ZQGQam", label: "Targu Logresti - Jud. GJ" },
  { id: "rwPW3Z", label: "Targu Mures - Jud. MS" },
  { id: "Zv5Xwr", label: "Targu Neamt - Jud. NT" },
  { id: "Wr8QMZ", label: "Targu Ocna - Jud. BC" },
  { id: "r9nn8Z", label: "Targu Secuiesc - Jud. CV" },
  { id: "leaEJl", label: "Targu Trotus - Jud. BC" },
  { id: "ZMYM4Z", label: "Targusor - Jud. BH" },
  { id: "mgAqDZ", label: "Targusor - Jud. CJ" },
  { id: "lO66NZ", label: "Targusor - Jud. CT" },
  { id: "rop2XZ", label: "Tarhausi - Jud. BC" },
  { id: "ZVKQOl", label: "Tarian - Jud. BH" },
  { id: "rE7Kol", label: "Tariceni - Jud. CL" },
  { id: "ZNw26l", label: "Tariceni - Jud. PH" },
  { id: "PrEWVl", label: "Tarina - Jud. AB" },
  { id: "ZPebAl", label: "Tariverde - Jud. CT" },
  { id: "r3k05r", label: "Tarlele - Jud. BZ" },
  { id: "rop3NZ", label: "Tarlele Filiu - Jud. BR" },
  { id: "ZyYjLZ", label: "Tarlesti - Jud. PH" },
  { id: "r6beWr", label: "Tarlisua - Jud. BN" },
  { id: "m1k2gZ", label: "Tarlungeni - Jud. BV" },
  { id: "Wr8GXm", label: "Tarmure - Jud. AR" },
  { id: "mAG2BZ", label: "Tarmure - Jud. SJ" },
  { id: "leGNOr", label: "Tarna Mare - Jud. SM" },
  { id: "lWDPAm", label: "Tarna Mare - Jud. SV" },
  { id: "lpEWem", label: "Tarnava - Jud. DJ" },
  { id: "rKnMxm", label: "Tarnava - Jud. HD" },
  { id: "lxKj7Z", label: "Tarnava - Jud. SB" },
  { id: "lWvvdZ", label: "Tarnava - Jud. TR" },
  { id: "m1qw9m", label: "Tarnava De Cris - Jud. HD" },
  { id: "ZNY7Qr", label: "Tarnaveni - Jud. MS" },
  { id: "krdeWZ", label: "Tarnavita - Jud. AR" },
  { id: "lD2MKr", label: "Tarnavita - Jud. HD" },
  { id: "lYJyRm", label: "Tarnicioara - Jud. SV" },
  { id: "lOw6Ll", label: "Tarnita - Jud. BC" },
  { id: "l0N9Qr", label: "Tarnita - Jud. HD" },
  { id: "rKnwPm", label: "Tarnita - Jud. IS" },
  { id: "krdyqZ", label: "Tarnova - Jud. AR" },
  { id: "mb91ol", label: "Tarnova - Jud. CS" },
  { id: "Zvozxl", label: "Tarnovita - Jud. HR" },
  { id: "rK2JGm", label: "Tarpesti - Jud. NT" },
  { id: "rqPWPm", label: "Tarpiu - Jud. BN" },
  { id: "rBW6qZ", label: "Tarpiu - Jud. CJ" },
  { id: "nlj91Z", label: "Tarsa - Jud. AB" },
  { id: "mGxq2r", label: "Tarsa - Jud. HD" },
  { id: "mnEjxl", label: "Tarsa - Jud. MH" },
  { id: "WZPBvm", label: "Tarsa Plai - Jud. AB" },
  { id: "mnzDam", label: "Tarsolt - Jud. SM" },
  { id: "mgB0El", label: "Tarsoreni - Jud. PH" },
  { id: "ZQqAdZ", label: "Tartal - Jud. DJ" },
  { id: "arBJ2r", label: "Tartaria - Jud. AB" },
  { id: "lxbRnr", label: "Tartasesti - Jud. DB" },
  { id: "ZQVOnl", label: "Tarzia - Jud. NT" },
  { id: "lJN3wl", label: "Tarzii - Jud. VS" },
  { id: "mG5vWZ", label: "Tasad - Jud. BH" },
  { id: "ZzDdYm", label: "Tasca - Jud. NT" },
  { id: "ZPqQEl", label: "Tasnad - Jud. SM" },
  { id: "rXaXnZ", label: "Tatanir - Jud. TL" },
  { id: "rwEjjl", label: "Tatarai - Jud. PH" },
  { id: "l0X9kl", label: "Tatarani - Jud. DB" },
  { id: "l58k9Z", label: "Tatarani - Jud. PH" },
  { id: "lxE0nm", label: "Tatarani - Jud. VL" },
  { id: "mGaABZ", label: "Tatarani - Jud. VS" },
  { id: "l7873m", label: "Tataranu - Jud. VN" },
  { id: "ZQQOaZ", label: "Tataraseni - Jud. BT" },
  { id: "ZMYQGZ", label: "Tatarasti - Jud. BC" },
  { id: "lD2RKr", label: "Tatarasti - Jud. HD" },
  { id: "r3Qq4l", label: "Tatarastii De Cris - Jud. HD" },
  { id: "ZvzG2Z", label: "Tatarastii De Jos - Jud. TR" },
  { id: "lj8BNr", label: "Tatarastii De Sus - Jud. TR" },
  { id: "lpEPWm", label: "Tatarca - Jud. GL" },
  { id: "lWDddm", label: "Tataresti - Jud. SM" },
  { id: "ElL7WZ", label: "Tatarlaua - Jud. AB" },
  { id: "ZQvV5l", label: "Tatarligu - Jud. BZ" },
  { id: "r3kpqr", label: "Tataru - Jud. BR" },
  { id: "mgAXEZ", label: "Tataru - Jud. CT" },
  { id: "mGWNWm", label: "Tataru - Jud. PH" },
  { id: "rXaLpZ", label: "Tataru - Jud. SV" },
  { id: "r36nGm", label: "Tataru - Jud. VN" },
  { id: "lx79wZ", label: "Tatarusi - Jud. IS" },
  { id: "r27ndZ", label: "Tatomiresti - Jud. DJ" },
  { id: "r995ar", label: "Tatomiresti - Jud. VS" },
  { id: "mnJNGm", label: "Tatu - Jud. CS" },
  { id: "lLayPm", label: "Tatulesti - Jud. BZ" },
  { id: "r2qM9m", label: "Tatulesti - Jud. OT" },
  { id: "yZRM2r", label: "Tau - Jud. AB" },
  { id: "ZRKDAl", label: "Tau - Jud. MS" },
  { id: "PrENoZ", label: "Tau Bistra - Jud. AB" },
  { id: "robv8m", label: "Taudu - Jud. SJ" },
  { id: "ZyeN8Z", label: "Taula - Jud. BC" },
  { id: "qZN9yr", label: "Tauni - Jud. AB" },
  { id: "rdjy4l", label: "Taure - Jud. BN" },
  { id: "mA48Kl", label: "Taureni - Jud. HR" },
  { id: "mnE3ql", label: "Taureni - Jud. MS" },
  { id: "mb9EBl", label: "Tauseni - Jud. CJ" },
  { id: "yZRNeZ", label: "Taut - Jud. AR" },
  { id: "m1kW6Z", label: "Taut - Jud. BH" },
  { id: "Z4Yd0l", label: "Tautelec - Jud. BH" },
  { id: "lWBpYZ", label: "Tautesti - Jud. BT" },
  { id: "lL1Q1m", label: "Tautesti - Jud. IS" },
  { id: "r30BqZ", label: "Tautesti - Jud. SV" },
  { id: "rdjqRl", label: "Tauteu - Jud. BH" },
  { id: "nljnwl", label: "Tauti - Jud. AB" },
  { id: "rK31Er", label: "Tauti - Jud. CJ" },
  { id: "lxAxDm", label: "Tautii De Sus - Jud. MM" },
  { id: "lOAwLr", label: "Tautii Magheraus - Jud. MM" },
  { id: "Rr96aZ", label: "Tavadaresti - Jud. BC" },
  { id: "rqONgl", label: "Tavarlau - Jud. TR" },
  { id: "rXO6pm", label: "Tazlau - Jud. NT" },
  { id: "Zv23JZ", label: "Teaca - Jud. BN" },
  { id: "ZNGbel", label: "Teasc - Jud. DJ" },
  { id: "r9n0xZ", label: "Teascu Din Deal - Jud. DJ" },
  { id: "Zvoj9l", label: "Tebea - Jud. HD" },
  { id: "roEE5l", label: "Teceu Mic - Jud. MM" },
  { id: "ZM9nyZ", label: "Techereu - Jud. HD" },
  { id: "rodynZ", label: "Techirghiol - Jud. CT" },
  { id: "Rr9bOZ", label: "Tecsesti - Jud. AB" },
  { id: "ZyNyKl", label: "Tecucelu Sec - Jud. GL" },
  { id: "r6q27m", label: "Tecuci - Jud. GL" },
  { id: "rdKK7m", label: "Tecuci - Jud. TR" },
  { id: "ZV18Kr", label: "Tega - Jud. BZ" },
  { id: "r3yDel", label: "Teghea - Jud. SM" },
  { id: "rwDoxZ", label: "Teghes - Jud. IF" },
  { id: "ZVwLAl", label: "Tehomir - Jud. GJ" },
  { id: "rqOdQl", label: "Teica - Jud. VL" },
  { id: "ZM7jjm", label: "Teis - Jud. DB" },
  { id: "rq0dBm", label: "Teis - Jud. OT" },
  { id: "rwEqVl", label: "Teisani - Jud. PH" },
  { id: "l7QEqr", label: "Teisori - Jud. GR" },
  { id: "r898PZ", label: "Teisoru - Jud. VS" },
  { id: "lLa66m", label: "Teisu - Jud. BZ" },
  { id: "grK6Pm", label: "Teiu - Jud. AB" },
  { id: "XlD1jm", label: "Teiu - Jud. AG" },
  { id: "ZyNxkl", label: "Teiu - Jud. DJ" },
  { id: "rXqJ3Z", label: "Teiu - Jud. HD" },
  { id: "Z4NYqm", label: "Teiu - Jud. VL" },
  { id: "ZQydaZ", label: "Teiul - Jud. VL" },
  { id: "VrXdRr", label: "Teius - Jud. AB" },
  { id: "lOw6nl", label: "Teius - Jud. BC" },
  { id: "roE0Nl", label: "Teius - Jud. OT" },
  { id: "ZNGNVl", label: "Teiusu - Jud. GR" },
  { id: "rK2Akm", label: "Teiusu - Jud. OT" },
  { id: "lpVK2r", label: "Teiusu - Jud. VL" },
  { id: "0lYz0m", label: "Tela - Jud. AR" },
  { id: "ZNyk1r", label: "Telcisor - Jud. BN" },
  { id: "ljGaBm", label: "Telciu - Jud. BN" },
  { id: "LZMGzm", label: "Teleac - Jud. AB" },
  { id: "lDAWXm", label: "Teleac - Jud. BH" },
  { id: "ZNADer", label: "Teleac - Jud. HR" },
  { id: "lj3Jkm", label: "Teleac - Jud. MS" },
  { id: "ZVd7Rm", label: "Telec - Jud. NT" },
  { id: "rqxbGm", label: "Telechesti - Jud. VL" },
  { id: "lJPx4r", label: "Telechia - Jud. CV" },
  { id: "lxxQQl", label: "Telechiu - Jud. BH" },
  { id: "rBn25r", label: "Telega - Jud. PH" },
  { id: "rEapqr", label: "Telejna - Jud. VS" },
  { id: "r99MNr", label: "Teleormanu - Jud. TR" },
  { id: "r6qV9m", label: "Telesti - Jud. DB" },
  { id: "l5Q8vl", label: "Telesti - Jud. GJ" },
  { id: "l586NZ", label: "Teline - Jud. SB" },
  { id: "rqOWdl", label: "Telita - Jud. TL" },
  { id: "lOw9Jl", label: "Teliu - Jud. BV" },
  { id: "ZVwNKl", label: "Teliucu Inferior - Jud. HD" },
  { id: "mA454l", label: "Teliucu Superior - Jud. HD" },
  { id: "Wr84Kr", label: "Telna - Jud. AB" },
  { id: "lxxxPl", label: "Temelia - Jud. BC" },
  { id: "l79jAr", label: "Temeresti - Jud. TM" },
  { id: "QmbwKr", label: "Temesesti - Jud. AR" },
  { id: "lDPXnl", label: "Tencanau - Jud. DJ" },
  { id: "ZQG8Jm", label: "Tengheler - Jud. HR" },
  { id: "Z4YQzl", label: "Tentea - Jud. BN" },
  { id: "PrEzMZ", label: "Teodoresti - Jud. AG" },
  { id: "rwYjol", label: "Tepa - Jud. VN" },
  { id: "r6ObpZ", label: "Tepes Voda - Jud. BR" },
  { id: "ZQqQdZ", label: "Tepes Voda - Jud. CT" },
  { id: "Z4NNxm", label: "Tepesti - Jud. VL" },
  { id: "Zv2g2Z", label: "Tepoaia - Jud. BC" },
  { id: "maNjjr", label: "Tepsenari - Jud. VL" },
  { id: "rKP1xZ", label: "Tepu - Jud. GL" },
  { id: "mkjdol", label: "Tepu De Sus - Jud. GL" },
  { id: "leLpdZ", label: "Terca - Jud. BZ" },
  { id: "rK6Oem", label: "Terchesti - Jud. VN" },
  { id: "lWDwdm", label: "Terebesti - Jud. SM" },
  { id: "r97Nql", label: "Teregova - Jud. CS" },
  { id: "ZMJ1zl", label: "Teremia Mare - Jud. TM" },
  { id: "mgoeGm", label: "Teremia Mica - Jud. TM" },
  { id: "lYyMXl", label: "Terova - Jud. CS" },
  { id: "lpEdPm", label: "Terpezita - Jud. DJ" },
  { id: "lj86Br", label: "Tes - Jud. TM" },
  { id: "yZy0Yr", label: "Tescani - Jud. BC" },
  { id: "l0wdRZ", label: "Tesila - Jud. PH" },
  { id: "r8YJqZ", label: "Teslui - Jud. DJ" },
  { id: "ZV3yJl", label: "Teslui - Jud. OT" },
  { id: "Z41wxl", label: "Tesna - Jud. SV" },
  { id: "ZQa6oZ", label: "Testioara - Jud. SJ" },
  { id: "rdxP5r", label: "Tetcani - Jud. NT" },
  { id: "ljGKKm", label: "Tetchea - Jud. BH" },
  { id: "lpEobm", label: "Tetcoiu - Jud. DB" },
  { id: "ZyNn8l", label: "Tetila - Jud. GJ" },
  { id: "ZyYy4Z", label: "Tetisu - Jud. SJ" },
  { id: "mk57qZ", label: "Tetoiu - Jud. VL" },
  { id: "lJ9N3Z", label: "Tia Mare - Jud. OT" },
  { id: "lezy6m", label: "Tibana - Jud. IS" },
  { id: "lpbRYr", label: "Tibanesti - Jud. IS" },
  { id: "r6xe3l", label: "Tibanestii Buhlii - Jud. VS" },
  { id: "m1BL6r", label: "Tibau - Jud. SV" },
  { id: "mGa1dZ", label: "Tibeni - Jud. SV" },
  { id: "rwDYVZ", label: "Tibod - Jud. HR" },
  { id: "m1Dkjl", label: "Tibrinu - Jud. CT" },
  { id: "Jr3yzl", label: "Tibru - Jud. AB" },
  { id: "ZMVN8l", label: "Tibucani - Jud. NT" },
  { id: "rwPvVZ", label: "Tibucanii De Jos - Jud. NT" },
  { id: "lxA8Dm", label: "Ticau - Jud. MM" },
  { id: "ZzwWbl", label: "Ticera - Jud. HD" },
  { id: "r6OqgZ", label: "Tichilesti - Jud. BR" },
  { id: "ZVLYdm", label: "Tichilesti - Jud. CT" },
  { id: "m1bw6r", label: "Tichilesti - Jud. TL" },
  { id: "ZzzYNZ", label: "Tichindeal - Jud. SB" },
  { id: "l0kd6Z", label: "Tichiris - Jud. VN" },
  { id: "mG1K0Z", label: "Ticleni - Jud. GJ" },
  { id: "lJ2RAl", label: "Ticos - Jud. NT" },
  { id: "mkEOnZ", label: "Ticos Floarea - Jud. NT" },
  { id: "ZNW4Vl", label: "Ticu - Jud. CJ" },
  { id: "r8DVPr", label: "Ticu Colonie - Jud. CJ" },
  { id: "lOw5wl", label: "Ticusu Nou - Jud. BV" },
  { id: "lOw0Nl", label: "Ticusu Vechi - Jud. BV" },
  { id: "mgAVAZ", label: "Ticvaniu Mare - Jud. CS" },
  { id: "mGwGWm", label: "Ticvaniu Mic - Jud. CS" },
  { id: "rB52gm", label: "Tifesti - Jud. VN" },
  { id: "Wr80r4", label: "Tifra - Jud. AB" },
  { id: "r89JEZ", label: "Tifu - Jud. VS" },
  { id: "lWOENl", label: "Tiganasi - Jud. IS" },
  { id: "ZRK7jl", label: "Tiganasi - Jud. MH" },
  { id: "JlJAeZ", label: "Tiganesti - Jud. AG" },
  { id: "l7v8XZ", label: "Tiganesti - Jud. BC" },
  { id: "lpEXom", label: "Tiganesti - Jud. GL" },
  { id: "mgo7Bm", label: "Tiganesti - Jud. TR" },
  { id: "Zyej2Z", label: "Tiganestii De Beius - Jud. BH" },
  { id: "ZMYWzZ", label: "Tiganestii De Cris - Jud. BH" },
  { id: "ZNyXVr", label: "Tigau - Jud. BN" },
  { id: "r36Jqm", label: "Tighina - Jud. VL" },
  { id: "Zy6Q8l", label: "Tigmandru - Jud. MS" },
  { id: "5l74Lm", label: "Tigveni - Jud. AG" },
  { id: "ZP2LDm", label: "Tiha Bargaului - Jud. BN" },
  { id: "r3yg5l", label: "Tihau - Jud. SJ" },
  { id: "ropA8Z", label: "Tileagd - Jud. BH" },
  { id: "ZRj1KZ", label: "Tilecus - Jud. BH" },
  { id: "ZzzdEZ", label: "Tilisca - Jud. SB" },
  { id: "lYKLOr", label: "Timiseni - Jud. GJ" },
  { id: "mGPppr", label: "Timisesti - Jud. NT" },
  { id: "rXaD3Z", label: "Timisoara - Jud. TM" },
  { id: "lYDXJZ", label: "Timisu De Jos - Jud. BV" },
  { id: "mAAy8m", label: "Timisu De Sus - Jud. BV" },
  { id: "mgPD9r", label: "Timus - Jud. BT" },
  { id: "r84Der", label: "Tina - Jud. VL" },
  { id: "l7vKYZ", label: "Tinaud - Jud. BH" },
  { id: "ma1Gem", label: "Tinca - Jud. BH" },
  { id: "leLvgZ", label: "Tincova - Jud. CS" },
  { id: "mGg32m", label: "Tinoasa - Jud. VN" },
  { id: "rqpeBm", label: "Tinosu - Jud. PH" },
  { id: "rER4qZ", label: "Tintea - Jud. PH" },
  { id: "lOWVol", label: "Tintesti - Jud. BZ" },
  { id: "lx8kDl", label: "Tiocu De Jos - Jud. CJ" },
  { id: "rE7Gql", label: "Tiocu De Sus - Jud. CJ" },
  { id: "ZMRyjl", label: "Tioltiur - Jud. CJ" },
  { id: "5l7B3Z", label: "Tipar - Jud. AR" },
  { id: "ma5AWm", label: "Tiparesti - Jud. PH" },
  { id: "mGaqBZ", label: "Tipari - Jud. TM" },
  { id: "m18xdl", label: "Tipau - Jud. VN" },
  { id: "r2Qv1m", label: "Tipilesti - Jud. IS" },
  { id: "l5752r", label: "Tiptelnic - Jud. MS" },
  { id: "mkzNol", label: "Tireac - Jud. SM" },
  { id: "mkzool", label: "Tiream - Jud. SM" },
  { id: "lJ2Xgl", label: "Tireu - Jud. MS" },
  { id: "r3QnGl", label: "Tirici - Jud. HD" },
  { id: "r3Bqnm", label: "Tirimia - Jud. MS" },
  { id: "rBjYdr", label: "Tirimioara - Jud. MS" },
  { id: "ZzwgEl", label: "Tirioi - Jud. GJ" },
  { id: "lDq6Km", label: "Tirisneag - Jud. OT" },
  { id: "lYyYJl", label: "Tirol - Jud. CS" },
  { id: "qZNB1Z", label: "Tisa - Jud. AR" },
  { id: "r2Vqdr", label: "Tisa - Jud. BC" },
  { id: "l7J05Z", label: "Tisa - Jud. HD" },
  { id: "r2w7eZ", label: "Tisa - Jud. MM" },
  { id: "lWD9om", label: "Tisa - Jud. PH" },
  { id: "rBRQqr", label: "Tisa - Jud. VL" },
  { id: "Lmnepl", label: "Tisa Noua - Jud. AR" },
  { id: "lWBeyZ", label: "Tisa Silvestri - Jud. BC" },
  { id: "r3kWYr", label: "Tisau - Jud. BZ" },
  { id: "roxpvZ", label: "Tisauti - Jud. SV" },
  { id: "lYnW0m", label: "Tisieu - Jud. MS" },
  { id: "l787Am", label: "Tisita - Jud. VN" },
  { id: "r9nEyZ", label: "Tismana - Jud. GJ" },
  { id: "r6GV5Z", label: "Tismana - Jud. MH" },
  { id: "l71pLr", label: "Titcov - Jud. BR" },
  { id: "mGPg9r", label: "Titerlesti - Jud. MH" },
  { id: "WmGK6m", label: "Titesti - Jud. AG" },
  { id: "rE445m", label: "Titesti - Jud. VL" },
  { id: "rK2zkm", label: "Titiana - Jud. MS" },
  { id: "l5Oj2r", label: "Titila - Jud. VN" },
  { id: "lWv6EZ", label: "Titireci - Jud. VL" },
  { id: "mkE6wZ", label: "Titirigi - Jud. MH" },
  { id: "lYxJQZ", label: "Titu - Jud. DB" },
  { id: "rKP7QZ", label: "Tiu - Jud. DJ" },
  { id: "roz5qm", label: "Tiulesti - Jud. HD" },
  { id: "nljdrx", label: "Tiur - Jud. AB" },
  { id: "l7wx5l", label: "Toaca - Jud. MS" },
  { id: "mk10QZ", label: "Toager - Jud. TM" },
  { id: "rqP5dm", label: "Toarcla - Jud. BV" },
  { id: "r9qwdr", label: "Toboliu - Jud. BH" },
  { id: "ElLg6Z", label: "Toc - Jud. AR" },
  { id: "ZM7oGm", label: "Toceni - Jud. DJ" },
  { id: "rqP0Qm", label: "Tochilea - Jud. BC" },
  { id: "9m14al", label: "Toci - Jud. AB" },
  { id: "mG5znZ", label: "Tocileni - Jud. BT" },
  { id: "maLA7l", label: "Tocileni - Jud. BZ" },
  { id: "lYxMxZ", label: "Toculesti - Jud. DB" },
  { id: "mG5RWZ", label: "Toderita - Jud. BV" },
  { id: "l0NzRr", label: "Todirel - Jud. IS" },
  { id: "rEQpLr", label: "Todireni - Jud. BT" },
  { id: "roEVLl", label: "Todireni - Jud. NT" },
  { id: "mGWK9m", label: "Todireni - Jud. SV" },
  { id: "mnpANZ", label: "Todireni - Jud. VS" },
  { id: "rE25gZ", label: "Todiresti - Jud. IS" },
  { id: "l79yLr", label: "Todiresti - Jud. SV" },
  { id: "rwVvKm", label: "Todiresti - Jud. VS" },
  { id: "Z4D0pZ", label: "Tofalau - Jud. MS" },
  { id: "r3GPqr", label: "Toflea - Jud. GL" },
  { id: "rBnGDr", label: "Tohani - Jud. PH" },
  { id: "rdjQ2l", label: "Tohanu Nou - Jud. BV" },
  { id: "Z4DwpZ", label: "Tohat - Jud. MM" },
  { id: "Oroe3l", label: "Tohesti - Jud. AR" },
  { id: "rXqqDZ", label: "Toiaga - Jud. GJ" },
  { id: "r2baAr", label: "Tojanii De Jos - Jud. VN" },
  { id: "ZVkOol", label: "Tojanii De Sus - Jud. VN" },
  { id: "9m1q6m", label: "Tolacesti - Jud. AB" },
  { id: "Zy6M0l", label: "Toldal - Jud. MS" },
  { id: "r92b8Z", label: "Toleseni - Jud. HR" },
  { id: "ZvzWxZ", label: "Tolesti - Jud. SV" },
  { id: "lD6nnr", label: "Tolici - Jud. NT" },
  { id: "r9D7Nm", label: "Tomeni - Jud. OT" },
  { id: "Jr3vem", label: "Tomesti - Jud. AB" },
  { id: "mbO1Er", label: "Tomesti - Jud. HD" },
  { id: "lD2Bjr", label: "Tomesti - Jud. HR" },
  { id: "mA4qal", label: "Tomesti - Jud. IS" },
  { id: "leJO1m", label: "Tomesti - Jud. TM" },
  { id: "ZMJyyl", label: "Tomesti - Jud. VS" },
  { id: "4lOD3m", label: "Tomnatec - Jud. AB" },
  { id: "rXqK9Z", label: "Tomnatec - Jud. HD" },
  { id: "mnPeaZ", label: "Tomnatic - Jud. BH" },
  { id: "ZMJoGl", label: "Tomnatic - Jud. TM" },
  { id: "lDAKWm", label: "Tomozia - Jud. BC" },
  { id: "rKxd0Z", label: "Tomsa - Jud. VS" },
  { id: "wmgMGZ", label: "Tomsanca - Jud. AG" },
  { id: "lJPwwr", label: "Tomsani - Jud. DB" },
  { id: "ZQa3DZ", label: "Tomsani - Jud. PH" },
  { id: "mndBjZ", label: "Tomsani - Jud. VL" },
  { id: "VrXRpl", label: "Tomulesti - Jud. AG" },
  { id: "maoP2Z", label: "Tomulesti - Jud. GR" },
  { id: "JmAAWm", label: "Tomusesti - Jud. AB" },
  { id: "4lOMOr", label: "Tomutesti - Jud. AB" },
  { id: "rBygJm", label: "Tonciu - Jud. BN" },
  { id: "lWRgNZ", label: "Tonciu - Jud. MS" },
  { id: "3ZVMBr", label: "Tonea - Jud. AB" },
  { id: "mkwRom", label: "Tonea - Jud. CL" },
  { id: "ma5oem", label: "Tonesti - Jud. OT" },
  { id: "rqpvGm", label: "Tontesti - Jud. PH" },
  { id: "mbxqdZ", label: "Topa - Jud. MS" },
  { id: "lDAJjm", label: "Topa De Cris - Jud. BH" },
  { id: "lDAonm", label: "Topa De Jos - Jud. BH" },
  { id: "l7vjdZ", label: "Topa De Sus - Jud. BH" },
  { id: "lx8dol", label: "Topa Mica - Jud. CJ" },
  { id: "ZyNNql", label: "Topalu - Jud. CT" },
  { id: "lYGJAr", label: "Topana - Jud. OT" },
  { id: "l0wnkZ", label: "Toparcea - Jud. SB" },
  { id: "rwnjVm", label: "Topesti - Jud. BH" },
  { id: "rEdnqm", label: "Topesti - Jud. GJ" },
  { id: "rdNB7l", label: "Topesti - Jud. VN" },
  { id: "l7JDLZ", label: "Topile - Jud. IS" },
  { id: "mkwJnm", label: "Topla - Jud. CS" },
  { id: "lWvzEZ", label: "Topla - Jud. TM" },
  { id: "rwpaVZ", label: "Toplet - Jud. CS" },
  { id: "m1ndYr", label: "Topliceni - Jud. BZ" },
  { id: "WmG8pr", label: "Toplita - Jud. AG" },
  { id: "lL1Knm", label: "Toplita - Jud. HD" },
  { id: "Zzwz3l", label: "Toplita - Jud. HR" },
  { id: "mb54Bl", label: "Toplita - Jud. SJ" },
  { id: "rdoR4m", label: "Toplita Muresului - Jud. HD" },
  { id: "maxP9l", label: "Topoliceni - Jud. NT" },
  { id: "lp5Bem", label: "Topolita - Jud. NT" },
  { id: "ZPAkEl", label: "Topolog - Jud. TL" },
  { id: "lpV0er", label: "Topolovatu Mare - Jud. TM" },
  { id: "lDa0Wm", label: "Topolovatu Mic - Jud. TM" },
  { id: "yZyKDm", label: "Topoloveni - Jud. AG" },
  { id: "lJN7gl", label: "Toporasti - Jud. VS" },
  { id: "mgxOBr", label: "Toporu - Jud. GR" },
  { id: "rKPPWZ", label: "Topraisar - Jud. CT" },
  { id: "rwPBxZ", label: "Torba - Jud. MS" },
  { id: "rwyGXl", label: "Torcesti - Jud. GL" },
  { id: "lWv8oZ", label: "Tormac - Jud. TM" },
  { id: "rqzpgZ", label: "Toropalesti - Jud. BZ" },
  { id: "ZNGGel", label: "Tortoman - Jud. CT" },
  { id: "rqEP0r", label: "Totea - Jud. GJ" },
  { id: "ZzwANl", label: "Totea De Hurezani - Jud. GJ" },
  { id: "yZyqoZ", label: "Totesti - Jud. AB" },
  { id: "rXqoRZ", label: "Totesti - Jud. HD" },
  { id: "rBbDdr", label: "Totia - Jud. HD" },
  { id: "rozQvm", label: "Totoesti - Jud. IS" },
  { id: "yZR82m", label: "Totoi - Jud. AB" },
  { id: "lD6V2r", label: "Totoiesti - Jud. NT" },
  { id: "rKje0m", label: "Totoreni - Jud. BH" },
  { id: "lx7M7Z", label: "Tovarasia - Jud. IL" },
  { id: "lp1vxm", label: "Traian - Jud. BC" },
  { id: "r97nNl", label: "Traian - Jud. BR" },
  { id: "l7Qvdr", label: "Traian - Jud. CT" },
  { id: "mG14dZ", label: "Traian - Jud. GL" },
  { id: "r66e5r", label: "Traian - Jud. IL" },
  { id: "lL1EXm", label: "Traian - Jud. IS" },
  { id: "ZNYDLr", label: "Traian - Jud. MH" },
  { id: "rdxQAr", label: "Traian - Jud. NT" },
  { id: "mAG9BZ", label: "Traian - Jud. OT" },
  { id: "lOBgJZ", label: "Traian - Jud. SM" },
  { id: "mbKWQr", label: "Traian - Jud. TL" },
  { id: "ZPAxBl", label: "Traian - Jud. TR" },
  { id: "mk1Y2Z", label: "Traian Vuia - Jud. TM" },
  { id: "ZR3zvl", label: "Traisteni - Jud. PH" },
  { id: "grK39r", label: "Trampoiele - Jud. AB" },
  { id: "BZ4d1Z", label: "Trancesti - Jud. AB" },
  { id: "rBnXgr", label: "Tranis - Jud. SJ" },
  { id: "Z4w5Jr", label: "Tranisu - Jud. CJ" },
  { id: "Zye28Z", label: "Trebes - Jud. BC" },
  { id: "mgabGZ", label: "Trei Fantani - Jud. NT" },
  { id: "r992qr", label: "Trei Movile - Jud. SV" },
  { id: "r2wBAZ", label: "Trei Sate - Jud. MS" },
  { id: "ZyYdDZ", label: "Trenu - Jud. PH" },
  { id: "l587RZ", label: "Trepteni - Jud. OT" },
  { id: "mGaQpZ", label: "Trestenic - Jud. TL" },
  { id: "ZzoPBl", label: "Trestia - Jud. BZ" },
  { id: "lYKMVr", label: "Trestia - Jud. HD" },
  { id: "lLVDGr", label: "Trestia - Jud. MM" },
  { id: "l7y25r", label: "Trestia - Jud. SJ" },
  { id: "rwYzol", label: "Trestia - Jud. VN" },
  { id: "rBRVVr", label: "Trestiana - Jud. VS" },
  { id: "ZvAdJr", label: "Trestieni - Jud. BZ" },
  { id: "lLDQAl", label: "Trestieni - Jud. GR" },
  { id: "ZM6zRl", label: "Trestieni - Jud. VN" },
  { id: "r2qddm", label: "Trestienii De Jos - Jud. PH" },
  { id: "ZV3MWl", label: "Trestienii De Sus - Jud. PH" },
  { id: "rqzBQZ", label: "Trestioara - Jud. BZ" },
  { id: "rwDgyZ", label: "Trestioara - Jud. GJ" },
  { id: "r9D0Om", label: "Trestioara - Jud. PH" },
  { id: "lWD0qm", label: "Treznea - Jud. SJ" },
  { id: "Z4Yo0l", label: "Tria - Jud. BH" },
  { id: "yZywqm", label: "Trifesti - Jud. AB" },
  { id: "ZM9PyZ", label: "Trifesti - Jud. IS" },
  { id: "lxA00m", label: "Trifesti - Jud. NT" },
  { id: "ZNweel", label: "Trip - Jud. SM" },
  { id: "yZyGkm", label: "Trisoresti - Jud. AB" },
  { id: "rwp6jZ", label: "Tritenii De Jos - Jud. CJ" },
  { id: "l71Dvr", label: "Tritenii De Sus - Jud. CJ" },
  { id: "mGwAwm", label: "Tritenii Hotar - Jud. CJ" },
  { id: "r6xo6l", label: "Trivalea Mosteni - Jud. TR" },
  { id: "EZzLBm", label: "Troas - Jud. AR" },
  { id: "lWeGEr", label: "Trocani - Jud. GJ" },
  { id: "lxEwQm", label: "Trohan - Jud. VS" },
  { id: "lj8QBr", label: "Troian - Jud. VL" },
  { id: "r99oyr", label: "Troianul - Jud. TR" },
  { id: "Zy6xYl", label: "Troita - Jud. MS" },
  { id: "l08xPl", label: "Tronari - Jud. BZ" },
  { id: "ZND9xm", label: "Trotusanu - Jud. VN" },
  { id: "m1GpgZ", label: "Trufinesti - Jud. OT" },
  { id: "rqxB9m", label: "Trundin - Jud. VL" },
  { id: "ZMYp4Z", label: "Trusesti - Jud. BT" },
  { id: "mgAx5Z", label: "Tudor Vladimirescu - Jud. BR" },
  { id: "mbP4qZ", label: "Tudor Vladimirescu - Jud. BT" },
  { id: "rqzD1Z", label: "Tudor Vladimirescu - Jud. CL" },
  { id: "rK3vGr", label: "Tudor Vladimirescu - Jud. CT" },
  { id: "lO62AZ", label: "Tudor Vladimirescu - Jud. GL" },
  { id: "mbO7or", label: "Tudor Vladimirescu - Jud. IS" },
  { id: "r3B7em", label: "Tudor Vladimirescu - Jud. OT" },
  { id: "rBR0ar", label: "Tudor Vladimirescu - Jud. TL" },
  { id: "ZvzbVZ", label: "Tudor Vladimirescu - Jud. TR" },
  { id: "leawXl", label: "Tudora - Jud. BT" },
  { id: "ZyNGKl", label: "Tufalau - Jud. CV" },
  { id: "r8Y3KZ", label: "Tufani - Jud. CT" },
  { id: "m1BR8r", label: "Tufani - Jud. PH" },
  { id: "m18q6l", label: "Tufanii - Jud. VL" },
  { id: "l7yvLr", label: "Tufaru - Jud. OT" },
  { id: "ZNwJ1l", label: "Tufeni - Jud. OT" },
  { id: "lxKzDZ", label: "Tufeni - Jud. PH" },
  { id: "mb9o2l", label: "Tufesti - Jud. BR" },
  { id: "mgogqm", label: "Tufestii De Jos - Jud. VS" },
  { id: "rqEQ9r", label: "Tufestii De Sus - Jud. IS" },
  { id: "l52K3m", label: "Tuglui - Jud. DJ" },
  { id: "rXLx3r", label: "Tulburea - Jud. BZ" },
  { id: "r3ynzl", label: "Tulburea - Jud. PH" },
  { id: "l0k4NZ", label: "Tulburea - Jud. VN" },
  { id: "m1BJ6r", label: "Tulburea Valeni - Jud. PH" },
  { id: "Zv2L9Z", label: "Tulca - Jud. BH" },
  { id: "rwVaQm", label: "Tulcea - Jud. TL" },
  { id: "ZVkKRl", label: "Tulei Campeni - Jud. VL" },
  { id: "leJXOm", label: "Tulesti - Jud. VS" },
  { id: "ZM9MgZ", label: "Tulghes - Jud. HR" },
  { id: "lOADnr", label: "Tulghies - Jud. MM" },
  { id: "rwYqal", label: "Tulnici - Jud. VN" },
  { id: "ZyNkYl", label: "Tulucesti - Jud. GL" },
  { id: "ZzgPWl", label: "Tunari - Jud. DB" },
  { id: "mA16Bm", label: "Tunari - Jud. IF" },
  { id: "mk11oZ", label: "Tunari - Jud. TR" },
  { id: "Zzgx8l", label: "Tunarii Noi - Jud. DJ" },
  { id: "rXyXDZ", label: "Tunarii Vechi - Jud. DJ" },
  { id: "r66Mpr", label: "Tungujei - Jud. IS" },
  { id: "l0KaoZ", label: "Tunsesti - Jud. VS" },
  { id: "rE6n5Z", label: "Tupilati - Jud. NT" },
  { id: "mgo87m", label: "Tupilati - Jud. VS" },
  { id: "lj4ewr", label: "Tur - Jud. SM" },
  { id: "NmkB2l", label: "Turburea - Jud. AG" },
  { id: "l0NwRr", label: "Turburea - Jud. GJ" },
  { id: "ZRBjyZ", label: "Turburea - Jud. VL" },
  { id: "m1B63r", label: "Turbuta - Jud. SJ" },
  { id: "lpE26m", label: "Turceni - Jud. GJ" },
  { id: "Vle6gm", label: "Turcesti - Jud. AG" },
  { id: "mgJxEl", label: "Turcesti - Jud. VL" },
  { id: "rozEnm", label: "Turcinesti - Jud. GJ" },
  { id: "r6xn7l", label: "Turcoaia - Jud. TL" },
  { id: "rqzygZ", label: "Turda - Jud. CJ" },
  { id: "ZzV33Z", label: "Turda - Jud. TL" },
  { id: "olWvYZ", label: "Turdas - Jud. AB" },
  { id: "Z432Jr", label: "Turdas - Jud. HD" },
  { id: "ZRwM2l", label: "Turdeni - Jud. HR" },
  { id: "rK31Rr", label: "Turea - Jud. CJ" },
  { id: "rdjJWl", label: "Tureac - Jud. BN" },
  { id: "rBWADZ", label: "Tureni - Jud. CJ" },
  { id: "mboKdr", label: "Turia - Jud. CV" },
  { id: "rER6QZ", label: "Turia - Jud. OT" },
  { id: "grKAxl", label: "Turluianu - Jud. BC" },
  { id: "lJxD3r", label: "Turmas - Jud. HD" },
  { id: "m1na9r", label: "Turmasi - Jud. CJ" },
  { id: "AZQnvl", label: "Turnu - Jud. AR" },
  { id: "mA996m", label: "Turnu Magurele - Jud. TR" },
  { id: "rX76Lm", label: "Turnu Rosu - Jud. SB" },
  { id: "lLaK1m", label: "Turnu Ruieni - Jud. CS" },
  { id: "lDqyjm", label: "Turt - Jud. SM" },
  { id: "ma53Nm", label: "Turt Bai - Jud. SM" },
  { id: "ZQV8dl", label: "Turtaba - Jud. MH" },
  { id: "lLVwWr", label: "Turturesti - Jud. NT" },
  { id: "l7yPLr", label: "Turulung - Jud. SM" },
  { id: "ZPqYjl", label: "Turulung Vii - Jud. SM" },
  { id: "rERAqZ", label: "Tusa - Jud. SJ" },
  { id: "lOAkLr", label: "Tusinu - Jud. MS" },
  { id: "lez8Om", label: "Tusnad - Jud. HR" },
  { id: "r66p3r", label: "Tusnadu Nou - Jud. HR" },
  { id: "m1qVdm", label: "Tustea - Jud. HD" },
  { id: "Zv2zwZ", label: "Tuta - Jud. BC" },
  { id: "XlDkWZ", label: "Tutana - Jud. AG" },
  { id: "lDa91m", label: "Tutcani - Jud. VS" },
  { id: "mGP8wr", label: "Tutcanii Din Deal - Jud. NT" },
  { id: "rwPGyZ", label: "Tutcanii Din Vale - Jud. NT" },
  { id: "mkaAOZ", label: "Tutora - Jud. IS" },
  { id: "rBRKqr", label: "Tutova - Jud. VS" },
  { id: "Z4NdAm", label: "Tutu - Jud. VN" },
  { id: "yZRR2Z", label: "Tutulesti - Jud. AG" },
  { id: "l0kwRZ", label: "Tutulesti - Jud. VL" },
  { id: "rdNjzl", label: "Tuturu - Jud. VL" },
  { id: "r27YWZ", label: "Tuzla - Jud. CT" },
  { id: "rKjAWm", label: "Ucea De Jos - Jud. BV" },
  { id: "mG52dZ", label: "Ucea De Sus - Jud. BV" },
  { id: "ZzE0Yr", label: "Ucuris - Jud. BH" },
  { id: "krw5Xl", label: "Uda - Jud. AG" },
  { id: "mnQ2al", label: "Uda - Jud. IS" },
  { id: "mk1KzZ", label: "Uda Clocociov - Jud. TR" },
  { id: "mnpopZ", label: "Uda Paciurea - Jud. TR" },
  { id: "r3kd0r", label: "Udati Lucieni - Jud. BZ" },
  { id: "m1n48r", label: "Udati Manzu - Jud. BZ" },
  { id: "mbKnBr", label: "Udeni - Jud. TR" },
  { id: "Lmn6Bm", label: "Udeni Zavoi - Jud. AG" },
  { id: "ZMJ9Ql", label: "Udesti - Jud. SV" },
  { id: "mAORBZ", label: "Udresti - Jud. DB" },
  { id: "rERVBZ", label: "Udresti - Jud. PH" },
  { id: "lpVO2r", label: "Udresti - Jud. VL" },
  { id: "lLJdAr", label: "Udupu - Jud. TR" },
  { id: "rqpGgm", label: "Ugrutiu - Jud. SJ" },
  { id: "olWwEl", label: "Uiasca - Jud. AG" },
  { id: "mA4J4l", label: "Uibaresti - Jud. HD" },
  { id: "lj8ELr", label: "Uidesti - Jud. SV" },
  { id: "lpEkYm", label: "Uiesti - Jud. GR" },
  { id: "roxewZ", label: "Uihei - Jud. TM" },
  { id: "Zv5BDr", label: "Uila - Jud. MS" },
  { id: "rozaNm", label: "Uilac - Jud. HR" },
  { id: "ZP2WKm", label: "Uileacu De Beius - Jud. BH" },
  { id: "rdje7l", label: "Uileacu De Cris - Jud. BH" },
  { id: "mk80wr", label: "Uileacu De Munte - Jud. BH" },
  { id: "rBnXJr", label: "Uileacu Simleului - Jud. SJ" },
  { id: "ElLenm", label: "Uioara De Jos - Jud. AB" },
  { id: "EZzENr", label: "Uioara De Sus - Jud. AB" },
  { id: "mnp88Z", label: "Uivar - Jud. TM" },
  { id: "rBb55r", label: "Ulcani - Jud. HR" },
  { id: "rwEOol", label: "Ulciug - Jud. SJ" },
  { id: "ZyWyKm", label: "Ulea - Jud. VS" },
  { id: "yZyMLZ", label: "Uleni - Jud. AG" },
  { id: "ZyGO8m", label: "Ulies - Jud. HD" },
  { id: "lpbgWr", label: "Ulies - Jud. HR" },
  { id: "r8BPXl", label: "Ulies - Jud. MS" },
  { id: "ZRxqAm", label: "Uliesti - Jud. DB" },
  { id: "4lOkol", label: "Ulita - Jud. AG" },
  { id: "mnpeBZ", label: "Uliuc - Jud. TM" },
  { id: "lJxzgr", label: "Ulm - Jud. HD" },
  { id: "mGaW6Z", label: "Ulma - Jud. SV" },
  { id: "Zy2bLl", label: "Ulmeni - Jud. BZ" },
  { id: "rwp1oZ", label: "Ulmeni - Jud. CL" },
  { id: "mgaABZ", label: "Ulmeni - Jud. MM" },
  { id: "rEaaor", label: "Ulmeni - Jud. TR" },
  { id: "ZPea3l", label: "Ulmet - Jud. BZ" },
  { id: "Z433jr", label: "Ulmet - Jud. GJ" },
  { id: "rob2xm", label: "Ulmet - Jud. OT" },
  { id: "r84BKr", label: "Ulmetel - Jud. VL" },
  { id: "mG1v0Z", label: "Ulmetu - Jud. DB" },
  { id: "rKxR9Z", label: "Ulmetu - Jud. VL" },
  { id: "l0X0kl", label: "Ulmi - Jud. DB" },
  { id: "Zvga2l", label: "Ulmi - Jud. GR" },
  { id: "ZP7EMr", label: "Ulmi - Jud. IS" },
  { id: "m1Bkar", label: "Ulmi - Jud. OT" },
  { id: "ZMGz4m", label: "Ulmi - Jud. PH" },
  { id: "lYnDAm", label: "Ulmoasa - Jud. MM" },
  { id: "lLaDbm", label: "Ulmu - Jud. BR" },
  { id: "l08a0l", label: "Ulmu - Jud. CL" },
  { id: "r27EbZ", label: "Umbraresti - Jud. GL" },
  { id: "rBOVVZ", label: "Umbraresti Deal - Jud. GL" },
  { id: "r8BXKl", label: "Uncesti - Jud. NT" },
  { id: "lxK5pZ", label: "Uncesti - Jud. SV" },
  { id: "ZMJpjl", label: "Uncesti - Jud. VS" },
  { id: "lODonm", label: "Unciuc - Jud. HD" },
  { id: "arBQVm", label: "Ungheni - Jud. AG" },
  { id: "lpb8Wr", label: "Ungheni - Jud. IS" },
  { id: "rq041m", label: "Ungheni - Jud. MS" },
  { id: "mbx6QZ", label: "Ungheni - Jud. NT" },
  { id: "l7wEXl", label: "Unghi - Jud. NT" },
  { id: "ZyePKZ", label: "Ungra - Jud. BV" },
  { id: "ZQvbnl", label: "Unguras - Jud. CJ" },
  { id: "r6G6WZ", label: "Unguras - Jud. MM" },
  { id: "krdLGr", label: "Ungurei - Jud. AB" },
  { id: "BZ4g1r", label: "Ungureni - Jud. AG" },
  { id: "lWBQAZ", label: "Ungureni - Jud. BC" },
  { id: "lp1JPm", label: "Ungureni - Jud. BT" },
  { id: "l7QKqr", label: "Ungureni - Jud. DB" },
  { id: "ZRxeym", label: "Ungureni - Jud. DJ" },
  { id: "mnxqqZ", label: "Ungureni - Jud. GJ" },
  { id: "lWeWkr", label: "Ungureni - Jud. GL" },
  { id: "l57Q9r", label: "Ungureni - Jud. MM" },
  { id: "ZPq73l", label: "Ungureni - Jud. OT" },
  { id: "rBnMQr", label: "Ungureni - Jud. PH" },
  { id: "lejd6m", label: "Ungureni - Jud. VN" },
  { id: "rdVvzl", label: "Ungurenii Mici - Jud. DJ" },
  { id: "ZMRMpl", label: "Unguriu - Jud. BZ" },
  { id: "ZzEXNr", label: "Unguroaia - Jud. BT" },
  { id: "Z49nxl", label: "Unimat - Jud. SM" },
  { id: "lDaJWm", label: "Unip - Jud. TM" },
  { id: "wmg05r", label: "Unirea - Jud. AB" },
  { id: "rEQ8gr", label: "Unirea - Jud. BN" },
  { id: "ZzogKl", label: "Unirea - Jud. BR" },
  { id: "ZRJDqZ", label: "Unirea - Jud. CL" },
  { id: "l0XQgl", label: "Unirea - Jud. DJ" },
  { id: "ZQwPJm", label: "Unirea - Jud. VN" },
  { id: "mk8Onr", label: "Unteni - Jud. BT" },
  { id: "rKxLkZ", label: "Untesti - Jud. VS" },
  { id: "ZNWoLl", label: "Urca - Jud. CJ" },
  { id: "lx8L0l", label: "Urcu - Jud. CS" },
  { id: "ZvgRwl", label: "Urda De Jos - Jud. GJ" },
  { id: "lx77pZ", label: "Urda De Sus - Jud. GJ" },
  { id: "rdo72m", label: "Urdari - Jud. GJ" },
  { id: "Jr3nVm", label: "Urdes - Jud. AB" },
  { id: "r99zdr", label: "Urdesti - Jud. VS" },
  { id: "lpE42m", label: "Urdinita - Jud. DJ" },
  { id: "lex7gZ", label: "Urecheni - Jud. NT" },
  { id: "3r2jvr", label: "Urechesti - Jud. AG" },
  { id: "mnPbBZ", label: "Urechesti - Jud. BC" },
  { id: "rEdDMm", label: "Urechesti - Jud. DJ" },
  { id: "lODeKm", label: "Urechesti - Jud. GJ" },
  { id: "rqxeom", label: "Urechesti - Jud. VN" },
  { id: "rwEyol", label: "Uria - Jud. OT" },
  { id: "mnQLNl", label: "Uric - Jud. HD" },
  { id: "lYKOVr", label: "Uricani - Jud. HD" },
  { id: "ZNAqQr", label: "Uricani - Jud. IS" },
  { id: "rdKgWm", label: "Uricari - Jud. VS" },
  { id: "mGPXDr", label: "Uricea - Jud. MS" },
  { id: "r3GKAr", label: "Urieni - Jud. DJ" },
  { id: "ZPaoKl", label: "Urisiu De Jos - Jud. MS" },
  { id: "r6GQgZ", label: "Urisiu De Sus - Jud. MS" },
  { id: "r8DwXr", label: "Urisor - Jud. CJ" },
  { id: "r9qxXr", label: "Uriu - Jud. BN" },
  { id: "m1B89r", label: "Urlati - Jud. PH" },
  { id: "lj8Xbr", label: "Urlati - Jud. VS" },
  { id: "mgAA7Z", label: "Urleasca - Jud. BR" },
  { id: "ZRxD2m", label: "Urlesti - Jud. GL" },
  { id: "lJ9wWZ", label: "Urleta - Jud. PH" },
  { id: "XlDLWl", label: "Urlucea - Jud. AG" },
  { id: "yZRkyl", label: "Urlueni - Jud. AG" },
  { id: "mgAGEZ", label: "Urluia - Jud. CT" },
  { id: "krw1Ql", label: "Urluiesti - Jud. AG" },
  { id: "ZMJJgl", label: "Urluiu - Jud. TR" },
  { id: "mgPR9r", label: "Urmenis - Jud. BN" },
  { id: "rq0zom", label: "Urmenis - Jud. MM" },
  { id: "lJxvwr", label: "Uroi - Jud. HD" },
  { id: "r3ypVl", label: "Ursa - Jud. OT" },
  { id: "ZQQxgZ", label: "Ursad - Jud. BH" },
  { id: "lWvEqZ", label: "Ursani - Jud. VL" },
  { id: "majDEr", label: "Ursaresti - Jud. IS" },
  { id: "lpE86m", label: "Ursati - Jud. GJ" },
  { id: "lO6vLZ", label: "Urseiu - Jud. DB" },
  { id: "ZQyxaZ", label: "Urseni - Jud. TM" },
  { id: "mGW56m", label: "Ursi - Jud. OT" },
  { id: "rB555m", label: "Ursi - Jud. VL" },
  { id: "rwD0oZ", label: "Ursici - Jud. HD" },
  { id: "r3QVql", label: "Ursita - Jud. IS" },
  { id: "OroXnZ", label: "Ursoaia - Jud. AG" },
  { id: "mbPoKZ", label: "Ursoaia - Jud. BC" },
  { id: "ZRJ5yZ", label: "Ursoaia - Jud. BZ" },
  { id: "mgx9Dr", label: "Ursoaia - Jud. DJ" },
  { id: "ZRwjyl", label: "Ursoaia - Jud. GJ" },
  { id: "Z4351r", label: "Ursoaia - Jud. IS" },
  { id: "Zv512r", label: "Ursoaia - Jud. MS" },
  { id: "rX7dem", label: "Ursoaia - Jud. OT" },
  { id: "lDK6nZ", label: "Ursoaia - Jud. VL" },
  { id: "r9bWaZ", label: "Ursoaia - Jud. VN" },
  { id: "l59JAl", label: "Ursoaia - Jud. VS" },
  { id: "rqPLBm", label: "Urvind - Jud. BH" },
  { id: "r33Yqr", label: "Urvis De Beius - Jud. BH" },
  { id: "Rr9Oyl", label: "Urvisu De Beliu - Jud. AR" },
  { id: "r869Xl", label: "Urzica - Jud. OT" },
  { id: "ZQwyDm", label: "Urzica - Jud. VL" },
  { id: "ZVLGdm", label: "Urzica Mare - Jud. DJ" },
  { id: "r3GJGr", label: "Urziceanca - Jud. DB" },
  { id: "rqEw0r", label: "Urziceni - Jud. IL" },
  { id: "mGWn6m", label: "Urziceni - Jud. SM" },
  { id: "r9DNdm", label: "Urziceni Padure - Jud. SM" },
  { id: "ZRxbNm", label: "Urzicuta - Jud. DJ" },
  { id: "rBO4bZ", label: "Uscaci - Jud. DJ" },
  { id: "r6557m", label: "Usurei - Jud. VL" },
  { id: "XlDXYl", label: "Ususau - Jud. AR" },
  { id: "lJNDAl", label: "Utvin - Jud. TM" },
  { id: "mnp0GZ", label: "Uzlina - Jud. TL" },
  { id: "ZyNVYl", label: "Uzunu - Jud. GR" },
  { id: "AZvVxZ", label: "Vacarea - Jud. AG" },
  { id: "lD2bbr", label: "Vacarea - Jud. GJ" },
  { id: "lJW63m", label: "Vacareasca - Jud. BZ" },
  { id: "r99P8r", label: "Vacareni - Jud. TL" },
  { id: "ronPqm", label: "Vacaresti - Jud. DB" },
  { id: "lWOQBl", label: "Vacaresti - Jud. HR" },
  { id: "ZMJ6yl", label: "Vacaresti - Jud. TR" },
  { id: "lWvMNZ", label: "Vaceni - Jud. TR" },
  { id: "l7vzdZ", label: "Vaculesti - Jud. BT" },
  { id: "ZzEbYr", label: "Vad - Jud. BV" },
  { id: "r3k7Yr", label: "Vad - Jud. CJ" },
  { id: "Zy6N0l", label: "Vad - Jud. MM" },
  { id: "mGPB9r", label: "Vad - Jud. NT" },
  { id: "lYnaAm", label: "Vadas - Jud. MS" },
  { id: "l0wK6Z", label: "Vadastra - Jud. OT" },
  { id: "ZR36Ll", label: "Vadastrita - Jud. OT" },
  { id: "Z4wqzr", label: "Vadeni - Jud. BR" },
  { id: "lYx3EZ", label: "Vadeni - Jud. GL" },
  { id: "Zzo98l", label: "Vadu - Jud. CT" },
  { id: "mGx0Br", label: "Vadu - Jud. HD" },
  { id: "rE6PBZ", label: "Vadu - Jud. MS" },
  { id: "lYKg0r", label: "Vadu Anei - Jud. IF" },
  { id: "r6bwgr", label: "Vadu Crisului - Jud. BH" },
  { id: "lezRom", label: "Vadu Dobrii - Jud. HD" },
  { id: "Z4D9JZ", label: "Vadu Izei - Jud. MM" },
  { id: "lWe0Nr", label: "Vadu Lat - Jud. GR" },
  { id: "rwVEXm", label: "Vadu Moldovei - Jud. SV" },
  { id: "Qmb02Z", label: "Vadu Motilor - Jud. AB" },
  { id: "mA9O2m", label: "Vadu Negrilesei - Jud. SV" },
  { id: "ljNnNl", label: "Vadu Oii - Jud. BZ" },
  { id: "mAbYqr", label: "Vadu Oii - Jud. CT" },
  { id: "mb5zql", label: "Vadu Parului - Jud. PH" },
  { id: "rK3OQr", label: "Vadu Pasii - Jud. BZ" },
  { id: "lDKeJZ", label: "Vadu Rosca - Jud. VN" },
  { id: "mGWMpm", label: "Vadu Sapat - Jud. PH" },
  { id: "maLODl", label: "Vadu Soresti - Jud. BZ" },
  { id: "lpEwYm", label: "Vadu Stanchii - Jud. DB" },
  { id: "ZRwPKl", label: "Vadu Vejei - Jud. IS" },
  { id: "r2wKpZ", label: "Vadurele - Jud. NT" },
  { id: "rqpXom", label: "Vadurele - Jud. SJ" },
  { id: "ZMVEpl", label: "Vaduri - Jud. NT" },
  { id: "rBRPdr", label: "Vadurile - Jud. VS" },
  { id: "lpbAPr", label: "Vagani - Jud. HR" },
  { id: "lpAGWm", label: "Vagas - Jud. SM" },
  { id: "rozbLm", label: "Vagiulesti - Jud. GJ" },
  { id: "JlJKzm", label: "Vai - Jud. AB" },
  { id: "lxx4zl", label: "Vaida - Jud. BH" },
  { id: "mnJvpm", label: "Vaida Camaras - Jud. CJ" },
  { id: "rK2kRm", label: "Vaidacuta - Jud. MS" },
  { id: "lDKQGZ", label: "Vaideeni - Jud. VL" },
  { id: "rdoo5m", label: "Vaidei - Jud. GJ" },
  { id: "ZRwgAl", label: "Vaidei - Jud. HD" },
  { id: "l0716Z", label: "Vaideiu - Jud. MS" },
  { id: "mgLPGr", label: "Vaieni - Jud. GJ" },
  { id: "ZzPWbm", label: "Vajaitoarea - Jud. VN" },
  { id: "ZQQBgZ", label: "Valani De Pomezeu - Jud. BH" },
  { id: "lp1eWm", label: "Valanii De Beius - Jud. BH" },
  { id: "rozznm", label: "Valari - Jud. GJ" },
  { id: "mbOQqr", label: "Valari - Jud. HD" },
  { id: "yZyjol", label: "Valcaneasa - Jud. AB" },
  { id: "ZyY3qZ", label: "Valcanesti - Jud. PH" },
  { id: "rwV9Qm", label: "Valcani - Jud. TM" },
  { id: "rB5Ddm", label: "Valcani - Jud. VN" },
  { id: "ZMGy5m", label: "Valcau De Jos - Jud. SJ" },
  { id: "ZPq1Bl", label: "Valcau De Sus - Jud. SJ" },
  { id: "yZy6kl", label: "Valcea - Jud. AB" },
  { id: "leaygl", label: "Valcea - Jud. BV" },
  { id: "lDP3bl", label: "Valceaua - Jud. GJ" },
  { id: "AZQz5l", label: "Valcele - Jud. BC" },
  { id: "lx8Jol", label: "Valcele - Jud. BZ" },
  { id: "rBWEgZ", label: "Valcele - Jud. CJ" },
  { id: "ZzgVWl", label: "Valcele - Jud. CV" },
  { id: "ZM7Njm", label: "Valcele - Jud. GJ" },
  { id: "rKnMem", label: "Valcele - Jud. HD" },
  { id: "ZvEO2r", label: "Valcele - Jud. OT" },
  { id: "rwYbXl", label: "Valcele - Jud. VN" },
  { id: "l0KE0Z", label: "Valcele - Jud. VS" },
  { id: "JlJG9m", label: "Valcelele - Jud. AG" },
  { id: "lxx1zl", label: "Valcelele - Jud. BH" },
  { id: "l7v2AZ", label: "Valcelele - Jud. BT" },
  { id: "m1no8r", label: "Valcelele - Jud. BZ" },
  { id: "ZPeE3l", label: "Valcelele - Jud. CJ" },
  { id: "rd58RZ", label: "Valcelele - Jud. CL" },
  { id: "l08bBl", label: "Valcelele - Jud. CT" },
  { id: "r3QbGl", label: "Valcelele - Jud. IS" },
  { id: "r6yV2m", label: "Valcelele - Jud. PH" },
  { id: "ZyWNKm", label: "Valcelele - Jud. SV" },
  { id: "lxzJQr", label: "Valcelele - Jud. VN" },
  { id: "r2QaAm", label: "Valcelele Bune - Jud. HD" },
  { id: "rwEPxl", label: "Valcelele De Sus - Jud. OT" },
  { id: "ZVwOol", label: "Valceluta - Jud. HD" },
  { id: "grKW0r", label: "Valcesti - Jud. AB" },
  { id: "rER1xZ", label: "Valchid - Jud. SB" },
  { id: "lD2pjr", label: "Valcica - Jud. IS" },
  { id: "ZvAYVr", label: "Vale - Jud. CJ" },
  { id: "lezGXm", label: "Vale - Jud. HR" },
  { id: "mgBg9l", label: "Vale - Jud. SB" },
  { id: "arBB5r", label: "Vale In Jos - Jud. AB" },
  { id: "Z4Yypl", label: "Valea - Jud. BN" },
  { id: "lDPBKl", label: "Valea - Jud. DB" },
  { id: "ZyNg0l", label: "Valea - Jud. GJ" },
  { id: "mbOWEr", label: "Valea - Jud. HD" },
  { id: "ZMVb5l", label: "Valea - Jud. MS" },
  { id: "krwPQZ", label: "Valea Abruzel - Jud. AB" },
  { id: "r8O8Em", label: "Valea Adanca - Jud. IS" },
  { id: "ZzoJnl", label: "Valea Agrisului - Jud. CJ" },
  { id: "arBjar", label: "Valea Alba - Jud. AB" },
  { id: "Z4D50Z", label: "Valea Alba - Jud. NT" },
  { id: "lLV0yr", label: "Valea Albestiului - Jud. MS" },
  { id: "l5OO3r", label: "Valea Alunisului - Jud. VL" },
  { id: "lOBV3Z", label: "Valea Anei - Jud. PH" },
  { id: "ZNY36r", label: "Valea Anilor - Jud. MH" },
  { id: "rBWodZ", label: "Valea Argovei - Jud. CL" },
  { id: "rq060m", label: "Valea Arini - Jud. NT" },
  { id: "leaLdl", label: "Valea Arinilor - Jud. BC" },
  { id: "lj5LkZ", label: "Valea Arsului - Jud. HD" },
  { id: "rwYEvl", label: "Valea Babei - Jud. VL" },
  { id: "mGxN0r", label: "Valea Babii - Jud. HD" },
  { id: "7rqA9r", label: "Valea Badenilor - Jud. AG" },
  { id: "lO7D3l", label: "Valea Balceasca - Jud. VL" },
  { id: "l580vZ", label: "Valea Bancului - Jud. SV" },
  { id: "Z4wNpr", label: "Valea Banului - Jud. BZ" },
  { id: "4lO1Nr", label: "Valea Barlutesti - Jud. AB" },
  { id: "9m11Rm", label: "Valea Barnii - Jud. AB" },
  { id: "lDKMKZ", label: "Valea Beciului - Jud. VN" },
  { id: "rXqyLZ", label: "Valea Bisericii - Jud. GJ" },
  { id: "ZyG1Lm", label: "Valea Bisericii - Jud. IL" },
  { id: "ZV1NBr", label: "Valea Bistrei - Jud. CS" },
  { id: "qZNy7r", label: "Valea Bistrii - Jud. AB" },
  { id: "ZNwaYl", label: "Valea Bobului - Jud. PH" },
  { id: "ZQveDl", label: "Valea Bolvasnita - Jud. CS" },
  { id: "rBy0dm", label: "Valea Borcutului - Jud. BN" },
  { id: "rwD7VZ", label: "Valea Boros - Jud. HR" },
  { id: "rBnBbr", label: "Valea Borului - Jud. PH" },
  { id: "l7vp5Z", label: "Valea Botului - Jud. BC" },
  { id: "lL7oXZ", label: "Valea Bourei - Jud. SV" },
  { id: "rqp30m", label: "Valea Bradetului - Jud. PH" },
  { id: "OroQLl", label: "Valea Bradului - Jud. AG" },
  { id: "mA4R6l", label: "Valea Bradului - Jud. HD" },
  { id: "ZNw96l", label: "Valea Bradului - Jud. PH" },
  { id: "5l7oXl", label: "Valea Brazilor - Jud. AG" },
  { id: "7rq3Bl", label: "Valea Bucurului - Jud. AB" },
  { id: "rKj3Em", label: "Valea Budului - Jud. BC" },
  { id: "ronJXm", label: "Valea Bujorului - Jud. GR" },
  { id: "mnE9xl", label: "Valea Buna - Jud. MH" },
  { id: "maLvJl", label: "Valea Calda - Jud. CJ" },
  { id: "ZP2bbm", label: "Valea Calmatuiului - Jud. BR" },
  { id: "r3yNel", label: "Valea Calugareasca - Jud. PH" },
  { id: "Rr9aqr", label: "Valea Calului - Jud. AG" },
  { id: "ZMRRgl", label: "Valea Canepii - Jud. BR" },
  { id: "rBb95r", label: "Valea Capelei - Jud. HR" },
  { id: "Zy2A8l", label: "Valea Caseielului - Jud. CJ" },
  { id: "Wr820l", label: "Valea Caselor - Jud. AB" },
  { id: "ropp3Z", label: "Valea Caselor - Jud. BC" },
  { id: "rXy13Z", label: "Valea Caselor - Jud. DB" },
  { id: "lO7Bwl", label: "Valea Caselor - Jud. VL" },
  { id: "ljN4kl", label: "Valea Catinei - Jud. BZ" },
  { id: "7rq0Gm", label: "Valea Cerbului - Jud. AB" },
  { id: "Z4YA1l", label: "Valea Cerului - Jud. BH" },
  { id: "9m1v6r", label: "Valea Cetatuia - Jud. AG" },
  { id: "l5O7vr", label: "Valea Cheii - Jud. VL" },
  { id: "r9y93r", label: "Valea Chioarului - Jud. MM" },
  { id: "r2Qjvm", label: "Valea Ciorii - Jud. IL" },
  { id: "rXLPgr", label: "Valea Ciresoii - Jud. CJ" },
  { id: "mnppaZ", label: "Valea Ciresului - Jud. TR" },
  { id: "2l5k3r", label: "Valea Ciuciului - Jud. AB" },
  { id: "rX7A3m", label: "Valea Ciurenilor - Jud. SJ" },
  { id: "yZyDqr", label: "Valea Cocesti - Jud. AB" },
  { id: "m1GJRZ", label: "Valea Copcii - Jud. MH" },
  { id: "5l7LXm", label: "Valea Corbului - Jud. AG" },
  { id: "mkEb2Z", label: "Valea Cosustei - Jud. MH" },
  { id: "lpxgWl", label: "Valea Cotesti - Jud. VN" },
  { id: "ZQv9pl", label: "Valea Cotoarei - Jud. BZ" },
  { id: "r8656l", label: "Valea Crangului - Jud. PH" },
  { id: "ZMGx5m", label: "Valea Cricovului - Jud. PH" },
  { id: "mbPGYZ", label: "Valea Crisului - Jud. BH" },
  { id: "lLDJyl", label: "Valea Crisului - Jud. CV" },
  { id: "mA46Wl", label: "Valea Cu Apa - Jud. GJ" },
  { id: "LZMEzZ", label: "Valea Cucii - Jud. AG" },
  { id: "lJ9yAZ", label: "Valea Cucului - Jud. PH" },
  { id: "mbxPKZ", label: "Valea Cufundoasa - Jud. MM" },
  { id: "rqzdGZ", label: "Valea Dacilor - Jud. CT" },
  { id: "lJPdgr", label: "Valea Dadei - Jud. DB" },
  { id: "ZzDyWm", label: "Valea Daii - Jud. MS" },
  { id: "ZyG90m", label: "Valea Daljii - Jud. HD" },
  { id: "7rqV1Z", label: "Valea Danului - Jud. AG" },
  { id: "ZNA9xr", label: "Valea De Brazi - Jud. HD" },
  { id: "mgP9Ar", label: "Valea De Jos - Jud. BH" },
  { id: "ZRjvvZ", label: "Valea De Sus - Jud. BH" },
  { id: "l5QQ0l", label: "Valea Desului - Jud. GJ" },
  { id: "ZM7VQm", label: "Valea Dobarlaului - Jud. CV" },
  { id: "m1BYRr", label: "Valea Doftanei - Jud. PH" },
  { id: "lx8Vol", label: "Valea Draganului - Jud. CJ" },
  { id: "lj92NZ", label: "Valea Dragului - Jud. GR" },
  { id: "Z49jql", label: "Valea Dulce - Jud. PH" },
  { id: "lxAopm", label: "Valea Enei - Jud. NT" },
  { id: "JlJd4r", label: "Valea Fagetului - Jud. AB" },
  { id: "ZzE7Er", label: "Valea Fanatului - Jud. BC" },
  { id: "mkwbBm", label: "Valea Fantanei - Jud. BZ" },
  { id: "rwy3yl", label: "Valea Fantanilor - Jud. DJ" },
  { id: "XlD72Z", label: "Valea Faurului - Jud. AG" },
  { id: "lOBAGZ", label: "Valea Fetei - Jud. OT" },
  { id: "r6OR2Z", label: "Valea Florilor - Jud. CJ" },
  { id: "r9yYXr", label: "Valea Fratiei - Jud. MS" },
  { id: "r2QLWm", label: "Valea Garbea - Jud. HR" },
  { id: "lW10yr", label: "Valea Garboului - Jud. CJ" },
  { id: "grKBWm", label: "Valea Giogesti - Jud. AB" },
  { id: "mgaM9Z", label: "Valea Glodului - Jud. MS" },
  { id: "lWvRkZ", label: "Valea Glodului - Jud. SV" },
  { id: "0lYMVl", label: "Valea Goblii - Jud. AB" },
  { id: "roYpwl", label: "Valea Gradistei - Jud. VL" },
  { id: "mnP2GZ", label: "Valea Grajdului - Jud. BT" },
  { id: "ZQyEJZ", label: "Valea Grecului - Jud. VS" },
  { id: "r2YXpm", label: "Valea Grosilor - Jud. CJ" },
  { id: "ma1LJm", label: "Valea Hogei - Jud. BC" },
  { id: "grKVWm", label: "Valea Holhorii - Jud. AB" },
  { id: "grKEGl", label: "Valea Hotarului - Jud. AG" },
  { id: "lLVe6r", label: "Valea Hotarului - Jud. MM" },
  { id: "Z49Vpl", label: "Valea Hranei - Jud. SJ" },
  { id: "AZQRJl", label: "Valea Iasului - Jud. AG" },
  { id: "ZPaX3l", label: "Valea Iclandului - Jud. MS" },
  { id: "m1npYr", label: "Valea Ierii - Jud. CJ" },
  { id: "Vlekgr", label: "Valea Indarat - Jud. AG" },
  { id: "rqEBBr", label: "Valea Intunecoasa - Jud. HR" },
  { id: "0lYVEm", label: "Valea Inzelului - Jud. AB" },
  { id: "rwPQaZ", label: "Valea Izvoarelor - Jud. MS" },
  { id: "XlDWGZ", label: "Valea Larga - Jud. AB" },
  { id: "ZQva5l", label: "Valea Larga - Jud. BZ" },
  { id: "rBOGgZ", label: "Valea Larga - Jud. DB" },
  { id: "lD6zbr", label: "Valea Larga - Jud. MS" },
  { id: "ZV3zdl", label: "Valea Larga - Jud. PH" },
  { id: "maL7Wl", label: "Valea Larga Sarulesti - Jud. BZ" },
  { id: "ZQaPaZ", label: "Valea Lespezii - Jud. PH" },
  { id: "lxKXPZ", label: "Valea Lesului - Jud. SJ" },
  { id: "mG1g6Z", label: "Valea Leurzii - Jud. DB" },
  { id: "rERzQZ", label: "Valea Loznei - Jud. SJ" },
  { id: "lpAAbm", label: "Valea Lui Alb - Jud. OT" },
  { id: "ZQG9Dm", label: "Valea Lui Antaloc - Jud. HR" },
  { id: "Z41P8l", label: "Valea Lui Bosie - Jud. VS" },
  { id: "leL9dZ", label: "Valea Lui Cati - Jud. CJ" },
  { id: "maonJZ", label: "Valea Lui Dan - Jud. DB" },
  { id: "rEa1Qr", label: "Valea Lui Darie - Jud. VS" },
  { id: "WmGk9m", label: "Valea Lui Enache - Jud. AG" },
  { id: "2l5xAZ", label: "Valea Lui Ion - Jud. BC" },
  { id: "rBWxqZ", label: "Valea Lui Lalu - Jud. BZ" },
  { id: "5l7Ewr", label: "Valea Lui Mas - Jud. AG" },
  { id: "JmAWaZ", label: "Valea Lui Mihai - Jud. AB" },
  { id: "r2VOvr", label: "Valea Lui Mihai - Jud. BH" },
  { id: "rK3wEr", label: "Valea Lui Opris - Jud. CJ" },
  { id: "mG1XpZ", label: "Valea Lui Patru - Jud. DJ" },
  { id: "rBbRQr", label: "Valea Lui Pavel - Jud. HR" },
  { id: "ZPAgDl", label: "Valea Lui Stan - Jud. VL" },
  { id: "Z4wPQr", label: "Valea Luncii - Jud. CJ" },
  { id: "ElLMbm", label: "Valea Lunga - Jud. AB" },
  { id: "majeJr", label: "Valea Lunga - Jud. HD" },
  { id: "r66k7r", label: "Valea Lunga - Jud. IS" },
  { id: "ZzzvNZ", label: "Valea Lunga - Jud. SB" },
  { id: "lxKYDZ", label: "Valea Lunga - Jud. SJ" },
  { id: "l0kKgZ", label: "Valea Lunga - Jud. VL" },
  { id: "rKxqEZ", label: "Valea Lunga - Jud. VS" },
  { id: "r8YMXZ", label: "Valea Lunga Cricov - Jud. DB" },
  { id: "Zvgj2l", label: "Valea Lunga Gorgota - Jud. DB" },
  { id: "lj9jNZ", label: "Valea Lunga Ogrea - Jud. DB" },
  { id: "rwV0Km", label: "Valea Lunga Romana - Jud. TM" },
  { id: "l52NNm", label: "Valea Lungului - Jud. DJ" },
  { id: "3r2LWl", label: "Valea Lupsii - Jud. AB" },
  { id: "mG5g9Z", label: "Valea Lupului - Jud. BC" },
  { id: "ZPe7Kl", label: "Valea Lupului - Jud. BZ" },
  { id: "lD2E1r", label: "Valea Lupului - Jud. HD" },
  { id: "mkaVoZ", label: "Valea Lupului - Jud. IS" },
  { id: "l79GLr", label: "Valea Lupului - Jud. VS" },
  { id: "Zy5wKZ", label: "Valea Macesului - Jud. VL" },
  { id: "Wr6qXm", label: "Valea Maciului - Jud. AB" },
  { id: "mgLDEr", label: "Valea Macrisului - Jud. IL" },
  { id: "rop73Z", label: "Valea Magherusului - Jud. BN" },
  { id: "arBLal", label: "Valea Magurei - Jud. AG" },
  { id: "JmAw2Z", label: "Valea Manastirii - Jud. AB" },
  { id: "Lmnwjr", label: "Valea Manastirii - Jud. AG" },
  { id: "rwy8Kl", label: "Valea Manastirii - Jud. GJ" },
  { id: "lJ9z9Z", label: "Valea Mantei - Jud. PH" },
  { id: "lp5Qem", label: "Valea Marcului - Jud. MH" },
  { id: "WZPqAl", label: "Valea Mare - Jud. AB" },
  { id: "7rqqBr", label: "Valea Mare - Jud. AG" },
  { id: "VrXg3m", label: "Valea Mare - Jud. AR" },
  { id: "Zye60Z", label: "Valea Mare - Jud. BC" },
  { id: "lxxMDl", label: "Valea Mare - Jud. BN" },
  { id: "r6ODDZ", label: "Valea Mare - Jud. CS" },
  { id: "rBOn2Z", label: "Valea Mare - Jud. CV" },
  { id: "rdV0al", label: "Valea Mare - Jud. DB" },
  { id: "mgxKEr", label: "Valea Mare - Jud. DJ" },
  { id: "ZP7evr", label: "Valea Mare - Jud. GJ" },
  { id: "l07JNZ", label: "Valea Mare - Jud. MS" },
  { id: "lxAVMm", label: "Valea Mare - Jud. NT" },
  { id: "l589EZ", label: "Valea Mare - Jud. OT" },
  { id: "l786nm", label: "Valea Mare - Jud. VL" },
  { id: "l0KO0Z", label: "Valea Mare - Jud. VS" },
  { id: "yZy1LZ", label: "Valea Mare Bratia - Jud. AG" },
  { id: "Zv2j3Z", label: "Valea Mare De Codru - Jud. BH" },
  { id: "ZRj8jZ", label: "Valea Mare De Cris - Jud. BH" },
  { id: "majq7r", label: "Valea Mare De Cris - Jud. HD" },
  { id: "9m1M8m", label: "Valea Mare Podgoria - Jud. AG" },
  { id: "Xl0LNl", label: "Valea Mare Pravat - Jud. AG" },
  { id: "qZNbLZ", label: "Valea Marului - Jud. AG" },
  { id: "Zv2A2Z", label: "Valea Marului - Jud. BC" },
  { id: "ZVL56m", label: "Valea Marului - Jud. GL" },
  { id: "rwnYjm", label: "Valea Merilor - Jud. BC" },
  { id: "lOAnJr", label: "Valea Merilor - Jud. OT" },
  { id: "XlDDWl", label: "Valea Mica - Jud. AB" },
  { id: "rKj2Rm", label: "Valea Mica - Jud. BC" },
  { id: "rKPnkZ", label: "Valea Mica - Jud. CV" },
  { id: "Z43qqr", label: "Valea Mica - Jud. GJ" },
  { id: "rB5Ndm", label: "Valea Mica - Jud. VN" },
  { id: "l58M9Z", label: "Valea Mieilor - Jud. PH" },
  { id: "mgJ6ql", label: "Valea Milcovului - Jud. VN" },
  { id: "ZRJzjZ", label: "Valea Minisului - Jud. CS" },
  { id: "3r2OWZ", label: "Valea Mlacii - Jud. AB" },
  { id: "rBRnVr", label: "Valea Moldovei - Jud. SV" },
  { id: "9m1Jar", label: "Valea Morii - Jud. AB" },
  { id: "rXyxeZ", label: "Valea Morii - Jud. DB" },
  { id: "lL7GYZ", label: "Valea Morii - Jud. SM" },
  { id: "ljGNNm", label: "Valea Mosneagului - Jud. BC" },
  { id: "mA44ql", label: "Valea Motrului - Jud. GJ" },
  { id: "mboQYr", label: "Valea Muierii De Jos - Jud. DJ" },
  { id: "WmGBWl", label: "Valea Muscelului - Jud. AG" },
  { id: "ZQQaaZ", label: "Valea Nacului - Jud. BC" },
  { id: "7rqXGm", label: "Valea Nandrii - Jud. AG" },
  { id: "mbOgKr", label: "Valea Nandrului - Jud. HD" },
  { id: "lj3GNm", label: "Valea Neagra - Jud. MM" },
  { id: "lpxLYl", label: "Valea Neagra - Jud. VN" },
  { id: "AZQV4l", label: "Valea Negrilesii - Jud. AB" },
  { id: "AZQ1DZ", label: "Valea Nenii - Jud. AG" },
  { id: "lYGzJr", label: "Valea Nicovani - Jud. PH" },
  { id: "r99v8r", label: "Valea Nucarilor - Jud. TL" },
  { id: "l0wMkZ", label: "Valea Nucetului - Jud. PH" },
  { id: "r3kBnr", label: "Valea Nucului - Jud. BZ" },
  { id: "ZR6yqZ", label: "Valea Oanei - Jud. VS" },
  { id: "lYKEJr", label: "Valea Oilor - Jud. IS" },
  { id: "mAGe8Z", label: "Valea Oprii - Jud. PH" },
  { id: "lYykRl", label: "Valea Orevita - Jud. CS" },
  { id: "rBnxqr", label: "Valea Orlei - Jud. PH" },
  { id: "mbxpqZ", label: "Valea Padurii - Jud. MS" },
  { id: "r3kBGr", label: "Valea Parului - Jud. BZ" },
  { id: "mgop5m", label: "Valea Parului - Jud. TR" },
  { id: "5l7gwl", label: "Valea Pechii - Jud. AG" },
  { id: "rBO3qZ", label: "Valea Perilor - Jud. GJ" },
  { id: "Zy672l", label: "Valea Petrii - Jud. MH" },
  { id: "ZR3AAl", label: "Valea Pietrei - Jud. PH" },
  { id: "rwyKol", label: "Valea Plopilor - Jud. GR" },
  { id: "rKDWQZ", label: "Valea Plopului - Jud. PH" },
  { id: "Zv2vVZ", label: "Valea Poenii - Jud. BN" },
  { id: "ZyYRkZ", label: "Valea Poienei - Jud. SV" },
  { id: "qZNdQm", label: "Valea Poienii - Jud. AB" },
  { id: "lx7b7Z", label: "Valea Poienii - Jud. GJ" },
  { id: "ZNAX7r", label: "Valea Poienii - Jud. HD" },
  { id: "mAG08Z", label: "Valea Poienii - Jud. PH" },
  { id: "rXy9gZ", label: "Valea Pojarului - Jud. GJ" },
  { id: "ZyYB8Z", label: "Valea Pomilor - Jud. SJ" },
  { id: "Jr3Vem", label: "Valea Popii - Jud. AG" },
  { id: "rK3k0r", label: "Valea Popii - Jud. CL" },
  { id: "ZNwzal", label: "Valea Popii - Jud. PH" },
  { id: "lDa4Km", label: "Valea Popii - Jud. VS" },
  { id: "lWv2RZ", label: "Valea Postei - Jud. TR" },
  { id: "Zy2Kol", label: "Valea Presnei - Jud. CL" },
  { id: "ljNDBl", label: "Valea Purcarului - Jud. BZ" },
  { id: "leJLgm", label: "Valea Putnei - Jud. SV" },
  { id: "r8DK6r", label: "Valea Putului Merei - Jud. BZ" },
  { id: "rE785l", label: "Valea Rachitei - Jud. CS" },
  { id: "rdojzm", label: "Valea Racilor - Jud. GJ" },
  { id: "rE2ALZ", label: "Valea Racului - Jud. IS" },
  { id: "lDDGXl", label: "Valea Ramnicului - Jud. BZ" },
  { id: "r6ONWZ", label: "Valea Ratei - Jud. BZ" },
  { id: "lxzx7r", label: "Valea Raului - Jud. VL" },
  { id: "mAbJKr", label: "Valea Ravensca - Jud. CS" },
  { id: "r3QJel", label: "Valea Rece - Jud. HR" },
  { id: "ZRK1Kl", label: "Valea Rece - Jud. MS" },
  { id: "AZQE4Z", label: "Valea Rizii - Jud. AG" },
  { id: "lx8zDl", label: "Valea Roatei - Jud. BZ" },
  { id: "lpzjWr", label: "Valea Rosie - Jud. CL" },
  { id: "ZMRg8l", label: "Valea Rosie - Jud. CS" },
  { id: "rqExBr", label: "Valea Rotunda - Jud. HR" },
  { id: "ElLKnZ", label: "Valea Rumanestilor - Jud. AG" },
  { id: "rXLGer", label: "Valea Rusului - Jud. CL" },
  { id: "rBy5Dm", label: "Valea Salciei - Jud. BC" },
  { id: "l71WYr", label: "Valea Salciei - Jud. BZ" },
  { id: "r3ko0r", label: "Valea Salciei Catun - Jud. BZ" },
  { id: "l084Jl", label: "Valea Salciilor - Jud. BZ" },
  { id: "mnQ1al", label: "Valea Sangeorgiului - Jud. HD" },
  { id: "l57PEr", label: "Valea Sanmartinului - Jud. MS" },
  { id: "lD6yJr", label: "Valea Sanpetrului - Jud. MS" },
  { id: "rXO0em", label: "Valea Sapartocului - Jud. MS" },
  { id: "ZQw3om", label: "Valea Sarii - Jud. VN" },
  { id: "BmaONZ", label: "Valea Sasului - Jud. AB" },
  { id: "lexv6Z", label: "Valea Sasului - Jud. MS" },
  { id: "mGxBWr", label: "Valea Satului - Jud. IS" },
  { id: "rERRQZ", label: "Valea Satului - Jud. OT" },
  { id: "ZQaDpZ", label: "Valea Scheilor - Jud. PH" },
  { id: "lWvGEZ", label: "Valea Scheiului - Jud. VL" },
  { id: "rqzNQZ", label: "Valea Schiopului - Jud. BZ" },
  { id: "lDqEXm", label: "Valea Screzii - Jud. PH" },
  { id: "mkjEql", label: "Valea Scurta - Jud. CV" },
  { id: "lDAxWm", label: "Valea Seaca - Jud. BC" },
  { id: "leoGPr", label: "Valea Seaca - Jud. CV" },
  { id: "mnQ5Al", label: "Valea Seaca - Jud. IS" },
  { id: "l07W6Z", label: "Valea Seaca - Jud. MS" },
  { id: "ZQV6al", label: "Valea Seaca - Jud. NT" },
  { id: "l7yXXr", label: "Valea Seaca - Jud. PH" },
  { id: "r2qpgm", label: "Valea Seaca - Jud. SM" },
  { id: "lxEVPm", label: "Valea Seaca - Jud. VS" },
  { id: "rd7pam", label: "Valea Seman - Jud. PH" },
  { id: "3ZV6OZ", label: "Valea Sesii - Jud. AB" },
  { id: "r6O6gZ", label: "Valea Sibiciului - Jud. BZ" },
  { id: "ZNWgel", label: "Valea Sichevitei - Jud. CS" },
  { id: "lDanKm", label: "Valea Silistei - Jud. VS" },
  { id: "ElL8nl", label: "Valea Silistii - Jud. AG" },
  { id: "lJ9PwZ", label: "Valea Soarelui - Jud. OT" },
  { id: "rdjxal", label: "Valea Sosii - Jud. BC" },
  { id: "mnzR8m", label: "Valea Stalpului - Jud. PH" },
  { id: "rdVMAl", label: "Valea Stanciului - Jud. DJ" },
  { id: "ZvApwr", label: "Valea Stanei - Jud. BZ" },
  { id: "lOB03Z", label: "Valea Stanei - Jud. SV" },
  { id: "XlD4Gl", label: "Valea Stanii - Jud. AG" },
  { id: "r97vdl", label: "Valea Stanii - Jud. CL" },
  { id: "mnJbXm", label: "Valea Stefanului - Jud. BZ" },
  { id: "ZNYw7r", label: "Valea Stejarului - Jud. MM" },
  { id: "r3Qeql", label: "Valea Stramba - Jud. HR" },
  { id: "l7ybYr", label: "Valea Stupinii - Jud. PH" },
  { id: "lLVbGr", label: "Valea Surii - Jud. MS" },
  { id: "l59YRl", label: "Valea Targului - Jud. VS" },
  { id: "mnPNjZ", label: "Valea Tarnei - Jud. BH" },
  { id: "rqpxom", label: "Valea Tarsei - Jud. PH" },
  { id: "ZPAdEl", label: "Valea Teilor - Jud. TL" },
  { id: "r3BJzm", label: "Valea Teiului - Jud. MH" },
  { id: "mb9RYl", label: "Valea Timisului - Jud. CS" },
  { id: "r3ye0l", label: "Valea Tocii - Jud. PH" },
  { id: "3ZVqOr", label: "Valea Tupilor - Jud. AB" },
  { id: "ZVw6Ol", label: "Valea Ugra - Jud. HR" },
  { id: "Xlxdzl", label: "Valea Uleiului - Jud. AG" },
  { id: "ZRKaLl", label: "Valea Uliesului - Jud. MS" },
  { id: "ZvEJKr", label: "Valea Unghiului - Jud. PH" },
  { id: "rqz5vZ", label: "Valea Ungurasului - Jud. CJ" },
  { id: "lp59Ym", label: "Valea Ungurului - Jud. MS" },
  { id: "ZzDMnm", label: "Valea Uriesului - Jud. MS" },
  { id: "robGqm", label: "Valea Urloii - Jud. PH" },
  { id: "r860Kl", label: "Valea Ursoii - Jud. PH" },
  { id: "NmkoQm", label: "Valea Ursului - Jud. AG" },
  { id: "r3kJ4r", label: "Valea Ursului - Jud. BZ" },
  { id: "l5QYYl", label: "Valea Ursului - Jud. IS" },
  { id: "r6G47Z", label: "Valea Ursului - Jud. MH" },
  { id: "lp5Rpm", label: "Valea Ursului - Jud. NT" },
  { id: "r843Vr", label: "Valea Ursului - Jud. VL" },
  { id: "Rr9n3Z", label: "Valea Utului - Jud. AB" },
  { id: "Wr8pEr", label: "Valea Uzei - Jud. AB" },
  { id: "rXq27Z", label: "Valea Uzului - Jud. HR" },
  { id: "rXLYgr", label: "Valea Vadului - Jud. CJ" },
  { id: "roYGNl", label: "Valea Valeni - Jud. VL" },
  { id: "4lONOZ", label: "Valea Verde - Jud. AB" },
  { id: "rd574Z", label: "Valea Verzei - Jud. BZ" },
  { id: "r972Nl", label: "Valea Viei - Jud. BZ" },
  { id: "l7Q43r", label: "Valea Viei - Jud. GJ" },
  { id: "Zy5GkZ", label: "Valea Viei - Jud. VL" },
  { id: "Z49Lql", label: "Valea Viilor - Jud. SB" },
  { id: "qZN2xZ", label: "Valea Vintului - Jud. AB" },
  { id: "mnPDpZ", label: "Valea Vinului - Jud. BN" },
  { id: "rwEOXl", label: "Valea Vinului - Jud. SM" },
  { id: "m1Gn9Z", label: "Valea Viseului - Jud. MM" },
  { id: "ZVLdBm", label: "Valea Zalanului - Jud. CV" },
  { id: "maLRjl", label: "Valeadeni - Jud. CS" },
  { id: "mkwzzm", label: "Valeanca Vilanesti - Jud. BZ" },
  { id: "lLa9Ym", label: "Valeapai - Jud. CS" },
  { id: "WZP9El", label: "Valeni - Jud. AB" },
  { id: "Qmbvvm", label: "Valeni - Jud. AG" },
  { id: "l7v9vZ", label: "Valeni - Jud. BC" },
  { id: "lxx00l", label: "Valeni - Jud. BV" },
  { id: "m1n6dr", label: "Valeni - Jud. CJ" },
  { id: "rK3RGr", label: "Valeni - Jud. CT" },
  { id: "rBbWDr", label: "Valeni - Jud. GJ" },
  { id: "lj5jKZ", label: "Valeni - Jud. HD" },
  { id: "r8O4qm", label: "Valeni - Jud. HR" },
  { id: "maxo7l", label: "Valeni - Jud. MM" },
  { id: "l7wP3l", label: "Valeni - Jud. MS" },
  { id: "ZVdbOm", label: "Valeni - Jud. NT" },
  { id: "rd7KWm", label: "Valeni - Jud. OT" },
  { id: "l0wYJZ", label: "Valeni - Jud. SB" },
  { id: "lpAMYm", label: "Valeni - Jud. SJ" },
  { id: "maNyEr", label: "Valeni - Jud. VL" },
  { id: "ZND7Vm", label: "Valeni - Jud. VN" },
  { id: "rqO5Ql", label: "Valeni - Jud. VS" },
  { id: "mao07Z", label: "Valeni Dambovita - Jud. DB" },
  { id: "WmG49r", label: "Valeni Podgoria - Jud. AG" },
  { id: "mbKPvr", label: "Valeni Stanisoara - Jud. SV" },
  { id: "r92GdZ", label: "Valenii - Jud. IS" },
  { id: "l072NZ", label: "Valenii - Jud. MS" },
  { id: "ma5y7m", label: "Valenii De Munte - Jud. PH" },
  { id: "rBj8dr", label: "Valenii De Mures - Jud. MS" },
  { id: "lYnKxm", label: "Valenii Lapusului - Jud. MM" },
  { id: "m1Gk3Z", label: "Valenii Somcutei - Jud. MM" },
  { id: "rKnoEm", label: "Valioara - Jud. HD" },
  { id: "LZM64l", label: "Valisoara - Jud. AB" },
  { id: "mAbpWr", label: "Valisoara - Jud. CJ" },
  { id: "Z4wXjr", label: "Valisoara - Jud. CS" },
  { id: "ZM92pZ", label: "Valisoara - Jud. HD" },
  { id: "lWR7yZ", label: "Valisoara - Jud. MS" },
  { id: "lL74AZ", label: "Valisoara - Jud. SJ" },
  { id: "Zzo3Dl", label: "Valiug - Jud. CS" },
  { id: "7rq1dr", label: "Valsanesti - Jud. AG" },
  { id: "lJPVgr", label: "Valter Maracineanu - Jud. GR" },
  { id: "grK50l", label: "Valtori - Jud. AB" },
  { id: "r277WZ", label: "Valu Lui Traian - Jud. CT" },
  { id: "Zy6LYl", label: "Valureni - Jud. MS" },
  { id: "lj9pdZ", label: "Valuta - Jud. GJ" },
  { id: "rqEA9r", label: "Vama - Jud. IS" },
  { id: "rBn1Vr", label: "Vama - Jud. SM" },
  { id: "l0KNoZ", label: "Vama - Jud. SV" },
  { id: "rKjRkm", label: "Vama Buzaului - Jud. BV" },
  { id: "lDD02l", label: "Vama Marga - Jud. CS" },
  { id: "Nmk1nZ", label: "Vama Seaca - Jud. AB" },
  { id: "r9n1OZ", label: "Vama Veche - Jud. CT" },
  { id: "r3GzVr", label: "Vames - Jud. GL" },
  { id: "r2YVgm", label: "Vamesu - Jud. BR" },
  { id: "leo7dr", label: "Vanata - Jud. GJ" },
  { id: "ZV3NBl", label: "Vanatoresti - Jud. SM" },
  { id: "LmnLNZ", label: "Vanatori - Jud. AR" },
  { id: "mnPvqZ", label: "Vanatori - Jud. BT" },
  { id: "ZV1JJr", label: "Vanatori - Jud. CJ" },
  { id: "ZPB2Mm", label: "Vanatori - Jud. CT" },
  { id: "lYxBQZ", label: "Vanatori - Jud. GL" },
  { id: "lxAPPm", label: "Vanatori - Jud. IF" },
  { id: "l7JqqZ", label: "Vanatori - Jud. IS" },
  { id: "mnERjl", label: "Vanatori - Jud. MH" },
  { id: "rq0X0m", label: "Vanatori - Jud. MS" },
  { id: "l59MAl", label: "Vanatori - Jud. TR" },
  { id: "lO7qLl", label: "Vanatori - Jud. VN" },
  { id: "lWREBZ", label: "Vanatori Neamt - Jud. NT" },
  { id: "lWepRr", label: "Vanatorii Mari - Jud. GR" },
  { id: "r9nGyZ", label: "Vanatorii Mici - Jud. GR" },
  { id: "ZyY64Z", label: "Vanesti - Jud. OT" },
  { id: "ZVdkRm", label: "Vanju Mare - Jud. MH" },
  { id: "lD6MGr", label: "Vanjulet - Jud. MH" },
  { id: "Nmkjbl", label: "Vanvucesti - Jud. AB" },
  { id: "ZV1oOr", label: "Var - Jud. CS" },
  { id: "m1BNqr", label: "Var - Jud. SJ" },
  { id: "rXLo7r", label: "Varadia - Jud. CS" },
  { id: "OroR5m", label: "Varadia De Mures - Jud. AR" },
  { id: "roEb5l", label: "Varai - Jud. MM" },
  { id: "leaq1l", label: "Varasau - Jud. BH" },
  { id: "l51aYl", label: "Varaseni - Jud. BH" },
  { id: "ZvA1Dr", label: "Varasti - Jud. CL" },
  { id: "ZPBvBm", label: "Varasti - Jud. GR" },
  { id: "lLVEnr", label: "Varatec - Jud. NT" },
  { id: "mb53Yl", label: "Varatec - Jud. SV" },
  { id: "rB5nQm", label: "Varateci - Jud. VL" },
  { id: "r6xBWl", label: "Varatica - Jud. VL" },
  { id: "l0KyJZ", label: "Varatici - Jud. VL" },
  { id: "lYG8wr", label: "Varbila - Jud. PH" },
  { id: "lOBqNZ", label: "Varbilau - Jud. PH" },
  { id: "l7v7LZ", label: "Varciorog - Jud. BH" },
  { id: "ZNW76l", label: "Varciorova - Jud. CS" },
  { id: "r6y92m", label: "Vard - Jud. SB" },
  { id: "Zzo7nl", label: "Varf - Jud. BZ" },
  { id: "rwnRvm", label: "Varfu Campului - Jud. BT" },
  { id: "r6xO7l", label: "Varfu Dealului - Jud. SV" },
  { id: "rqbgoZ", label: "Varfureni - Jud. DB" },
  { id: "ZPBwDm", label: "Varfuri - Jud. DB" },
  { id: "AZvwwl", label: "Varfurile - Jud. AR" },
  { id: "l58XvZ", label: "Varfurile - Jud. PH" },
  { id: "l7wkvl", label: "Vargata - Jud. MS" },
  { id: "mbO5Qr", label: "Vargatac - Jud. HR" },
  { id: "rXyaeZ", label: "Varghis - Jud. CV" },
  { id: "lDadXm", label: "Varias - Jud. TM" },
  { id: "BZ4Bpm", label: "Variasu Mare - Jud. AR" },
  { id: "XlxWDZ", label: "Variasu Mic - Jud. AR" },
  { id: "ZPe9Bl", label: "Varlaam - Jud. BZ" },
  { id: "r6qkgm", label: "Varlaam - Jud. GR" },
  { id: "ZRB6NZ", label: "Varleni - Jud. VL" },
  { id: "lJP3wr", label: "Varlezi - Jud. GL" },
  { id: "BmakjZ", label: "Varloveni - Jud. AG" },
  { id: "roz4Xm", label: "Varmaga - Jud. HD" },
  { id: "3ZVNKZ", label: "Varnita - Jud. AR" },
  { id: "r86Wel", label: "Varnita - Jud. PH" },
  { id: "l5OWRr", label: "Varnita - Jud. VN" },
  { id: "lWR2oZ", label: "Varodia - Jud. MH" },
  { id: "lWOkdl", label: "Varsag - Jud. HR" },
  { id: "Wr81XZ", label: "Varsand - Jud. AR" },
  { id: "leL2PZ", label: "Varsatura - Jud. BR" },
  { id: "lpxyYl", label: "Varsatura - Jud. VN" },
  { id: "AZQpJr", label: "Varsesti - Jud. AG" },
  { id: "2l51Nl", label: "Varsi - Jud. AB" },
  { id: "3r2Qvm", label: "Varsi Rontu - Jud. AB" },
  { id: "3ZVwAl", label: "Varsii Mari - Jud. AB" },
  { id: "JlJxpr", label: "Varsii Mici - Jud. AB" },
  { id: "leGkYr", label: "Varsolt - Jud. SJ" },
  { id: "ZNGo1l", label: "Vart - Jud. GJ" },
  { id: "XlDE1m", label: "Vartanesti - Jud. AB" },
  { id: "lpzw6r", label: "Varteju - Jud. BZ" },
  { id: "rKnAxm", label: "Varteju - Jud. IF" },
  { id: "rERgqZ", label: "Vartesca - Jud. SJ" },
  { id: "Zy538Z", label: "Vartescoiu - Jud. VN" },
  { id: "mGaJDZ", label: "Vartoapele De Jos - Jud. TR" },
  { id: "lDaWKm", label: "Vartoapele De Sus - Jud. TR" },
  { id: "LZMWQm", label: "Vartop - Jud. AB" },
  { id: "leLD1Z", label: "Vartop - Jud. CT" },
  { id: "ZvgM3l", label: "Vartop - Jud. DJ" },
  { id: "ZQqzaZ", label: "Vartopu - Jud. GJ" },
  { id: "m1GpRZ", label: "Vartopu - Jud. OT" },
  { id: "ZPAeEl", label: "Varvata - Jud. SV" },
  { id: "rKjMkm", label: "Varviz - Jud. BH" },
  { id: "maoV9Z", label: "Varvor - Jud. DJ" },
  { id: "lj9MwZ", label: "Varvoru De Jos - Jud. DJ" },
  { id: "r2Va1r", label: "Varzari - Jud. BH" },
  { id: "ZNy36r", label: "Varzarii De Jos - Jud. BH" },
  { id: "r82Kel", label: "Varzarii De Sus - Jud. BH" },
  { id: "qZNKel", label: "Varzaroaia - Jud. AG" },
  { id: "3ZVJdZ", label: "Varzaru - Jud. AG" },
  { id: "mgP71r", label: "Vasad - Jud. BH" },
  { id: "mkaDqZ", label: "Vascani - Jud. IS" },
  { id: "ZVKqAl", label: "Vascau - Jud. BH" },
  { id: "r2MYWm", label: "Vascauti - Jud. SV" },
  { id: "4lOvOZ", label: "Vasesti - Jud. AB" },
  { id: "ZQv4vl", label: "Vasilati - Jud. CL" },
  { id: "rwy13l", label: "Vasile Alecsandri - Jud. GL" },
  { id: "lJNAzl", label: "Vasile Alecsandri - Jud. TL" },
  { id: "EZz4bm", label: "Vasile Goldis - Jud. AR" },
  { id: "rE2WVZ", label: "Vasileni - Jud. HR" },
  { id: "mgoNkm", label: "Vaslui - Jud. VS" },
  { id: "3ZV0om", label: "Vasoaia - Jud. AR" },
  { id: "7rqY1r", label: "Vata - Jud. AG" },
  { id: "rE2BxZ", label: "Vata De Jos - Jud. HD" },
  { id: "lODynm", label: "Vata De Sus - Jud. HD" },
  { id: "lJ6QAl", label: "Vatasesti - Jud. VL" },
  { id: "ZQyD5Z", label: "Vatasi - Jud. TR" },
  { id: "mGPEwr", label: "Vatava - Jud. MS" },
  { id: "ZVKJRl", label: "Vatra - Jud. BT" },
  { id: "leJqom", label: "Vatra - Jud. TR" },
  { id: "mnzyBm", label: "Vatra Dornei - Jud. SV" },
  { id: "rqOp1l", label: "Vatra Moldovitei - Jud. SV" },
  { id: "r6OG9Z", label: "Vavalucile - Jud. BZ" },
  { id: "mGWywm", label: "Vecerd - Jud. SB" },
  { id: "rBWPgZ", label: "Vechea - Jud. CJ" },
  { id: "Jr3bVm", label: "Vedea - Jud. AG" },
  { id: "r6qM6m", label: "Vedea - Jud. GR" },
  { id: "mbKyKr", label: "Vedea - Jud. TR" },
  { id: "maxp9l", label: "Veja - Jud. NT" },
  { id: "ronMvm", label: "Vela - Jud. DJ" },
  { id: "r3GLAr", label: "Veleni - Jud. DJ" },
  { id: "rBO0aZ", label: "Velesti - Jud. DJ" },
  { id: "lOBQnZ", label: "Velt - Jud. SB" },
  { id: "lYn2Em", label: "Venchi - Jud. MS" },
  { id: "r2VDWr", label: "Venetia De Jos - Jud. BV" },
  { id: "ZVKxOl", label: "Venetia De Sus - Jud. BV" },
  { id: "rdVVAl", label: "Venus - Jud. CT" },
  { id: "ZQyMdZ", label: "Verbia - Jud. SV" },
  { id: "rXynnZ", label: "Verbicioara - Jud. DJ" },
  { id: "maobbZ", label: "Verbita - Jud. DJ" },
  { id: "rd7O5m", label: "Vercicani - Jud. SV" },
  { id: "lJ66zl", label: "Verdea - Jud. VL" },
  { id: "l0k0QZ", label: "Verdea - Jud. VN" },
  { id: "rBRL2r", label: "Verdes - Jud. VS" },
  { id: "wmgxGr", label: "Verdesti - Jud. AB" },
  { id: "ZQv3dl", label: "Verendin - Jud. CS" },
  { id: "ZPA7bl", label: "Veresti - Jud. SV" },
  { id: "robx3m", label: "Verguleasa - Jud. OT" },
  { id: "r82gPl", label: "Vermes - Jud. BN" },
  { id: "Z4w21r", label: "Vermes - Jud. CS" },
  { id: "9m1pql", label: "Vermesti - Jud. BC" },
  { id: "WZPybl", label: "Vernesti - Jud. AG" },
  { id: "mGwqnm", label: "Vernesti - Jud. BZ" },
  { id: "majaEr", label: "Verseni - Jud. IS" },
  { id: "ZVK3Wl", label: "Versesti - Jud. BC" },
  { id: "ZzDJ8m", label: "Versesti - Jud. NT" },
  { id: "l7yL5r", label: "Verveghiu - Jud. SJ" },
  { id: "ZQapnZ", label: "Veseud - Jud. SB" },
  { id: "VrXxnr", label: "Veseus - Jud. AB" },
  { id: "r86XVl", label: "Vestem - Jud. SB" },
  { id: "rBj42r", label: "Veta - Jud. MS" },
  { id: "rwPXyZ", label: "Vetca - Jud. MS" },
  { id: "lezK3m", label: "Vetel - Jud. HD" },
  { id: "mA97Wm", label: "Vetelu - Jud. VL" },
  { id: "lLDeXl", label: "Veteranu - Jud. CT" },
  { id: "rqpw1m", label: "Vetis - Jud. SM" },
  { id: "Jr39Vl", label: "Vetisoara - Jud. AG" },
  { id: "lWM3Nr", label: "Vetresti Herastrau - Jud. VN" },
  { id: "ZQyjpZ", label: "Vetrisoaia - Jud. VS" },
  { id: "WZPkr1", label: "Veza - Jud. AB" },
  { id: "ZR3NKl", label: "Vezendiu - Jud. SM" },
  { id: "lD6Bnr", label: "Viasu - Jud. MH" },
  { id: "rKneEm", label: "Vica - Jud. HD" },
  { id: "rE67qZ", label: "Vicea - Jud. MM" },
  { id: "lp1Yem", label: "Vicoleni - Jud. BT" },
  { id: "ZQyaJZ", label: "Vicovu De Jos - Jud. SV" },
  { id: "lxK9pZ", label: "Vicovu De Sus - Jud. SV" },
  { id: "ZVy1Or", label: "Vicsani - Jud. SV" },
  { id: "l79OYr", label: "Victor Vlad Delamarina - Jud. TM" },
  { id: "lx8bwl", label: "Victoria - Jud. BR" },
  { id: "rwnKQm", label: "Victoria - Jud. BT" },
  { id: "l0pLRl", label: "Victoria - Jud. BV" },
  { id: "lD281r", label: "Victoria - Jud. IS" },
  { id: "r30KAZ", label: "Victoria - Jud. TL" },
  { id: "majXEr", label: "Vidacut - Jud. HR" },
  { id: "ZNJJyZ", label: "Videle - Jud. TR" },
  { id: "lLVxPr", label: "Vidimiresti - Jud. MH" },
  { id: "lWOKEl", label: "Vidin - Jud. GJ" },
  { id: "Oroavl", label: "Vidolm - Jud. AB" },
  { id: "EZzWKl", label: "Vidra - Jud. AB" },
  { id: "XlDdYl", label: "Vidra - Jud. AR" },
  { id: "mGPYDr", label: "Vidra - Jud. IF" },
  { id: "r36N5m", label: "Vidra - Jud. VN" },
  { id: "rK2z0m", label: "Vidrasau - Jud. MS" },
  { id: "Qmboar", label: "Vidrisoara - Jud. AB" },
  { id: "mkaxQZ", label: "Viersani - Jud. GJ" },
  { id: "lWeNRr", label: "Vieru - Jud. GR" },
  { id: "Wr6y5m", label: "Viezuri - Jud. AB" },
  { id: "ronYxm", label: "Viforata - Jud. DB" },
  { id: "rEQaxr", label: "Viforeni - Jud. BC" },
  { id: "lDA9bm", label: "Viforeni - Jud. BT" },
  { id: "rwPLoZ", label: "Viforoasa - Jud. MS" },
  { id: "ron2Lm", label: "Viile - Jud. CT" },
  { id: "rwyA3l", label: "Viile - Jud. GL" },
  { id: "Z41K8l", label: "Viile - Jud. TR" },
  { id: "lD6AJr", label: "Viile Apei - Jud. MM" },
  { id: "lxKMwZ", label: "Viile Jacului - Jud. SJ" },
  { id: "ZQaoJZ", label: "Viile Satu Mare - Jud. SM" },
  { id: "ZVKnJl", label: "Viile Tecii - Jud. BN" },
  { id: "mA1M2m", label: "Viilor - Jud. MS" },
  { id: "4lORKZ", label: "Viisoara - Jud. AG" },
  { id: "ljG8dm", label: "Viisoara - Jud. BC" },
  { id: "r9qQNr", label: "Viisoara - Jud. BH" },
  { id: "ZMYgyZ", label: "Viisoara - Jud. BN" },
  { id: "mG5ppZ", label: "Viisoara - Jud. BT" },
  { id: "lOWnol", label: "Viisoara - Jud. CJ" },
  { id: "rd565Z", label: "Viisoara - Jud. CT" },
  { id: "ZNGn1l", label: "Viisoara - Jud. DB" },
  { id: "rwy9jl", label: "Viisoara - Jud. DJ" },
  { id: "rBjXbr", label: "Viisoara - Jud. MS" },
  { id: "ZzDXNm", label: "Viisoara - Jud. NT" },
  { id: "mGW0Wm", label: "Viisoara - Jud. SM" },
  { id: "lLJ56r", label: "Viisoara - Jud. TR" },
  { id: "ZPG6vr", label: "Viisoara - Jud. VL" },
  { id: "ZRBzLZ", label: "Viisoara - Jud. VN" },
  { id: "r3044Z", label: "Viisoara - Jud. VS" },
  { id: "ZVKvAl", label: "Viisoara Mica - Jud. BT" },
  { id: "m1DMjl", label: "Viisoara Mosneni - Jud. DJ" },
  { id: "rwV2am", label: "Viltotesti - Jud. VS" },
  { id: "mgaBDZ", label: "Vima Mare - Jud. MM" },
  { id: "mgaoGZ", label: "Vima Mica - Jud. MM" },
  { id: "rBRzgr", label: "Vinderei - Jud. VS" },
  { id: "WZP2km", label: "Vinerea - Jud. AB" },
  { id: "lYJdVm", label: "Vinetesti - Jud. VS" },
  { id: "mnzxAm", label: "Vineti - Jud. OT" },
  { id: "lJWxwm", label: "Vinetisu - Jud. BZ" },
  { id: "Bma3WZ", label: "Vinga - Jud. AR" },
  { id: "wmg77m", label: "Vingard - Jud. AB" },
  { id: "0lYvRm", label: "Vinta - Jud. AB" },
  { id: "ljGjwm", label: "Vintere - Jud. BH" },
  { id: "rBWDaZ", label: "Vintila Voda - Jud. BZ" },
  { id: "mAbaWr", label: "Vintileanca - Jud. BZ" },
  { id: "m18Y3l", label: "Vintileasca - Jud. VN" },
  { id: "VrXK4m", label: "Vintu De Jos - Jud. AB" },
  { id: "rqzLGZ", label: "Viperesti - Jud. BZ" },
  { id: "PrEeQr", label: "Virismort - Jud. AR" },
  { id: "rK3AGr", label: "Viroaga - Jud. CT" },
  { id: "lL17Xm", label: "Visafolio - Jud. HR" },
  { id: "ZVyoAr", label: "Visag - Jud. TM" },
  { id: "r3k90r", label: "Visagu - Jud. CJ" },
  { id: "ZRwEvl", label: "Visan - Jud. IS" },
  { id: "rE7dgl", label: "Visani - Jud. BR" },
  { id: "r8Og0m", label: "Visca - Jud. HD" },
  { id: "mk8Vnr", label: "Viscri - Jud. BV" },
  { id: "lOWQnl", label: "Visea - Jud. CJ" },
  { id: "mbxKaZ", label: "Viseu De Jos - Jud. MM" },
  { id: "lexLdZ", label: "Viseu De Mijloc - Jud. MM" },
  { id: "ZMVRjl", label: "Viseu De Sus - Jud. MM" },
  { id: "ZvgxJl", label: "Visina - Jud. DB" },
  { id: "ZyNXol", label: "Visina - Jud. GL" },
  { id: "rK28Pm", label: "Visina - Jud. MH" },
  { id: "ma5NJm", label: "Visina - Jud. OT" },
  { id: "rdKy2m", label: "Visina - Jud. TL" },
  { id: "ZV3dol", label: "Visina Noua - Jud. OT" },
  { id: "r2Mj1m", label: "Visinari - Jud. VS" },
  { id: "lD6Rjr", label: "Visinelu - Jud. MS" },
  { id: "lj9PBZ", label: "Visinesti - Jud. DB" },
  { id: "rd5J7Z", label: "Visinii - Jud. CL" },
  { id: "mnJzpm", label: "Vispesti - Jud. BZ" },
  { id: "r2Y2em", label: "Vistea - Jud. CJ" },
  { id: "lOw0Jl", label: "Vistea De Jos - Jud. BV" },
  { id: "r2Vz1r", label: "Vistea De Sus - Jud. BV" },
  { id: "rwVOVm", label: "Visterna - Jud. TL" },
  { id: "ZPqwAl", label: "Vistieru - Jud. PH" },
  { id: "ZVKPBl", label: "Vistisoara - Jud. BV" },
  { id: "mG5QDZ", label: "Visuia - Jud. BN" },
  { id: "r9qNar", label: "Vita - Jud. BN" },
  { id: "lj49Kr", label: "Vitanesti - Jud. OT" },
  { id: "r30d5Z", label: "Vitanesti - Jud. TR" },
  { id: "ZQwn5m", label: "Vitanesti - Jud. VN" },
  { id: "lejqOm", label: "Vitanestii De Sub Magura - Jud. VN" },
  { id: "rKj4Rm", label: "Vitcani - Jud. BT" },
  { id: "lOBv3Z", label: "Vitioara De Sus - Jud. PH" },
  { id: "lJ92gZ", label: "Vitomiresti - Jud. OT" },
  { id: "lL606r", label: "Vizantea Manastireasca - Jud. VN" },
  { id: "ZzPyBm", label: "Vizantea Razaseasca - Jud. VN" },
  { id: "r30x0Z", label: "Vizejdia - Jud. TM" },
  { id: "rK3Der", label: "Vizireni - Jud. BZ" },
  { id: "ZMR7yl", label: "Viziru - Jud. BR" },
  { id: "l595pl", label: "Vizma - Jud. TM" },
  { id: "r308nZ", label: "Vizureni - Jud. VS" },
  { id: "m1DXql", label: "Vizuresti - Jud. DB" },
  { id: "rKPLkZ", label: "Vizuresti - Jud. GL" },
  { id: "rodD8Z", label: "Vlad Tepes - Jud. CL" },
  { id: "l0Xn0l", label: "Vlad Tepes - Jud. GR" },
  { id: "l7wnnl", label: "Vladaia - Jud. MH" },
  { id: "lYn1Xm", label: "Vladasesti - Jud. MH" },
  { id: "rBypQm", label: "Vladeni - Jud. BT" },
  { id: "rqPVBm", label: "Vladeni - Jud. BV" },
  { id: "rXL79r", label: "Vladeni - Jud. BZ" },
  { id: "rKPWEZ", label: "Vladeni - Jud. DB" },
  { id: "ZVwBAl", label: "Vladeni - Jud. IL" },
  { id: "mGxR2r", label: "Vladeni - Jud. IS" },
  { id: "mbPVEZ", label: "Vladeni Deal - Jud. BT" },
  { id: "2l5vYm", label: "Vladesti - Jud. AB" },
  { id: "9m19am", label: "Vladesti - Jud. AG" },
  { id: "lYxdVZ", label: "Vladesti - Jud. GL" },
  { id: "mGgOdm", label: "Vladesti - Jud. VL" },
  { id: "ZVyBBr", label: "Vladesti - Jud. VS" },
  { id: "lpVXWr", label: "Vladia - Jud. VS" },
  { id: "mkE72Z", label: "Vladica - Jud. MH" },
  { id: "rXLb4r", label: "Vladiceasca - Jud. CL" },
  { id: "lWRKRZ", label: "Vladiceasca - Jud. IF" },
  { id: "r3Q9Vl", label: "Vladiceni - Jud. IS" },
  { id: "r3BgYm", label: "Vladiceni - Jud. NT" },
  { id: "lj4oNr", label: "Vladila - Jud. OT" },
  { id: "rd7x4m", label: "Vladila Noua - Jud. OT" },
  { id: "rqb8vZ", label: "Vladimir - Jud. DJ" },
  { id: "maj59r", label: "Vladimir - Jud. GJ" },
  { id: "BmaW7Z", label: "Vladimirescu - Jud. AR" },
  { id: "l51Q9l", label: "Vladnic - Jud. BC" },
  { id: "Zv50xr", label: "Vladnicele - Jud. NT" },
  { id: "ZPGw3r", label: "Vladnicu De Jos - Jud. VN" },
  { id: "r65a9m", label: "Vladnicu De Sus - Jud. VN" },
  { id: "ZQGjJm", label: "Vladnicut - Jud. IS" },
  { id: "ZyGoom", label: "Vladomira - Jud. IS" },
  { id: "yZyEoZ", label: "Vladosesti - Jud. AB" },
  { id: "l0k7RZ", label: "Vladuceni - Jud. VL" },
  { id: "lxb9nr", label: "Vladuleni - Jud. GJ" },
  { id: "ma5Lem", label: "Vladuleni - Jud. OT" },
  { id: "rqxxBm", label: "Vladulesti - Jud. VL" },
  { id: "QmbpaZ", label: "Vladuta - Jud. AG" },
  { id: "ZNWp7l", label: "Vlaha - Jud. CJ" },
  { id: "mnJO8m", label: "Vlahii - Jud. CT" },
  { id: "ZQGadm", label: "Vlahita - Jud. HR" },
  { id: "rXOB7m", label: "Vlaici - Jud. OT" },
  { id: "l54R2Z", label: "Vlaiculesti - Jud. CL" },
  { id: "lWDDRm", label: "Vlangaresti - Jud. OT" },
  { id: "rBb1br", label: "Vlasca - Jud. IL" },
  { id: "lO6NGZ", label: "Vlasceni - Jud. DB" },
  { id: "grKJkm", label: "Vlascuta - Jud. AG" },
  { id: "rKPqeZ", label: "Vlasin - Jud. GR" },
  { id: "rqPYdm", label: "Vlasinesti - Jud. BT" },
  { id: "r2Qgbm", label: "Vocotesti - Jud. IS" },
  { id: "Zy292l", label: "Vodnic - Jud. CS" },
  { id: "ZM6DRl", label: "Voetin - Jud. VN" },
  { id: "rdVaRl", label: "Voia - Jud. DB" },
  { id: "lpbLYr", label: "Voia - Jud. HD" },
  { id: "ZQw9gm", label: "Voicesti - Jud. VL" },
  { id: "m18Xgl", label: "Voicestii Din Vale - Jud. VL" },
  { id: "rwYnjl", label: "Voiculeasa - Jud. VL" },
  { id: "m1bXqr", label: "Voievoda - Jud. TR" },
  { id: "r89YEZ", label: "Voievodeasa - Jud. SV" },
  { id: "r2VJ1r", label: "Voila - Jud. BV" },
  { id: "robEXm", label: "Voineasa - Jud. OT" },
  { id: "rwYx3l", label: "Voineasa - Jud. VL" },
  { id: "rK6Vkm", label: "Voinesita - Jud. VL" },
  { id: "EZzq3r", label: "Voinesti - Jud. AG" },
  { id: "rodpxZ", label: "Voinesti - Jud. BR" },
  { id: "r6qaWm", label: "Voinesti - Jud. DB" },
  { id: "rwD6oZ", label: "Voinesti - Jud. IS" },
  { id: "m1bz9r", label: "Voinesti - Jud. VS" },
  { id: "lYnoVm", label: "Voiniceni - Jud. MS" },
  { id: "ZPBgDm", label: "Voinigesti - Jud. GJ" },
  { id: "lJWq4m", label: "Voislova - Jud. CS" },
  { id: "lWezEr", label: "Voita - Jud. DJ" },
  { id: "mGaGnZ", label: "Voiteg - Jud. TM" },
  { id: "r8YQ6Z", label: "Voitestii Din Deal - Jud. GJ" },
  { id: "ZzVDDZ", label: "Voitinel - Jud. SV" },
  { id: "2l5XRZ", label: "Voivodeni - Jud. AR" },
  { id: "l51xYl", label: "Voivodeni - Jud. BV" },
  { id: "ZQVEal", label: "Voivodeni - Jud. MS" },
  { id: "ZQaL5Z", label: "Voivodeni - Jud. SJ" },
  { id: "lea1Pl", label: "Voivozi - Jud. BH" },
  { id: "rqEyGr", label: "Volintiresti - Jud. IS" },
  { id: "mGPMdr", label: "Voloiac - Jud. MH" },
  { id: "lD6G2r", label: "Voloicel - Jud. MH" },
  { id: "rdNRWl", label: "Voloscani - Jud. VN" },
  { id: "r30yVZ", label: "Volovat - Jud. SV" },
  { id: "rqE2gr", label: "Voluntari - Jud. IF" },
  { id: "VleX1Z", label: "Vonigeasa - Jud. AG" },
  { id: "ZQQpdZ", label: "Vorniceni - Jud. BT" },
  { id: "lDaD2m", label: "Vornicenii Mari - Jud. SV" },
  { id: "l791wr", label: "Vornicenii Mici - Jud. SV" },
  { id: "r339Ar", label: "Vorona - Jud. BT" },
  { id: "r9qaMr", label: "Vorona Mare - Jud. BT" },
  { id: "mgPzEr", label: "Vorona Teodoru - Jud. BT" },
  { id: "mAGqqZ", label: "Voronet - Jud. SV" },
  { id: "Nmk42r", label: "Voroveni - Jud. AG" },
  { id: "l0Nnor", label: "Vorovesti - Jud. IS" },
  { id: "lpbd2r", label: "Vorta - Jud. HD" },
  { id: "mkagoZ", label: "Voslabeni - Jud. HR" },
  { id: "r99xqr", label: "Vovriesti - Jud. VS" },
  { id: "r92RqZ", label: "Vrabia - Jud. HR" },
  { id: "rdxa5r", label: "Vrancea - Jud. MH" },
  { id: "WmG22l", label: "Vranceni - Jud. BC" },
  { id: "rK67Em", label: "Vrancioaia - Jud. VN" },
  { id: "BZ46qm", label: "Vranesti - Jud. AG" },
  { id: "lx8qzl", label: "Vrani - Jud. CS" },
  { id: "rXLvpr", label: "Vraniut - Jud. CS" },
  { id: "mGPqWr", label: "Vrata - Jud. MH" },
  { id: "leJnYm", label: "Vucova - Jud. TM" },
  { id: "krwEQl", label: "Vulcan - Jud. AB" },
  { id: "ZVKDBl", label: "Vulcan - Jud. BV" },
  { id: "m1q4am", label: "Vulcan - Jud. HD" },
  { id: "lWRadZ", label: "Vulcan - Jud. MS" },
  { id: "r9nXXZ", label: "Vulcana Bai - Jud. DB" },
  { id: "ZyNj0l", label: "Vulcana De Sus - Jud. DB" },
  { id: "r27WeZ", label: "Vulcana Pandele - Jud. DB" },
  { id: "r2b0br", label: "Vulcaneasa - Jud. VN" },
  { id: "roxMNZ", label: "Vulpaseni - Jud. VS" },
  { id: "rq0Qdm", label: "Vulpasesti - Jud. NT" },
  { id: "ZPqGBl", label: "Vulpeni - Jud. OT" },
  { id: "yZRbyZ", label: "Vulpesti - Jud. AG" },
  { id: "r863Dl", label: "Vulpesti - Jud. OT" },
  { id: "r84Yer", label: "Vulpuesti - Jud. VL" },
  { id: "r2749Z", label: "Vultureanca - Jud. DB" },
  { id: "l7vKXZ", label: "Vultureni - Jud. BC" },
  { id: "rBWd2Z", label: "Vultureni - Jud. BR" },
  { id: "lDApnm", label: "Vultureni - Jud. BT" },
  { id: "Zy2gLl", label: "Vultureni - Jud. CJ" },
  { id: "3ZVpxm", label: "Vulturesti - Jud. AG" },
  { id: "r6y56m", label: "Vulturesti - Jud. OT" },
  { id: "m1bBar", label: "Vulturesti - Jud. SV" },
  { id: "lOYanr", label: "Vulturesti - Jud. VS" },
  { id: "lJxV4r", label: "Vulturi - Jud. IS" },
  { id: "mAOOKZ", label: "Vulturu - Jud. CT" },
  { id: "mgoV1m", label: "Vulturu - Jud. TL" },
  { id: "lDKJYZ", label: "Vulturu - Jud. VN" },
  { id: "Wr8WMm", label: "Vurpar - Jud. AB" },
  { id: "lxK07Z", label: "Vurpar - Jud. SB" },
  { id: "ZyWn0m", label: "Vutcani - Jud. VS" },
  { id: "lxbEQr", label: "Zabala - Jud. CV" },
  { id: "wmgK9m", label: "Zabalt - Jud. AR" },
  { id: "rKnp0m", label: "Zaboloteni - Jud. IS" },
  { id: "nljAdZ", label: "Zabrani - Jud. AR" },
  { id: "mkjzql", label: "Zabratau - Jud. CV" },
  { id: "3r2pel", label: "Zadareni - Jud. AR" },
  { id: "mkjOzl", label: "Zadariciu - Jud. GR" },
  { id: "r3Bz0m", label: "Zagar - Jud. MS" },
  { id: "ZQGKJm", label: "Zagavia - Jud. IS" },
  { id: "Z4q1Al", label: "Zagon - Jud. CV" },
  { id: "mbPAqZ", label: "Zagra - Jud. BN" },
  { id: "qZNJaZ", label: "Zagris - Jud. AB" },
  { id: "rK3zGr", label: "Zagujeni - Jud. CS" },
  { id: "leG01r", label: "Zahanaua - Jud. PH" },
  { id: "lJWdWm", label: "Zaharesti - Jud. BZ" },
  { id: "rKxPkZ", label: "Zaharesti - Jud. SV" },
  { id: "rXdRLr", label: "Zahoreni - Jud. BT" },
  { id: "rEQjBr", label: "Zaicesti - Jud. BT" },
  { id: "Zzg9Nl", label: "Zaicoiu - Jud. GJ" },
  { id: "rqbE9Z", label: "Zalan - Jud. CV" },
  { id: "lOBROZ", label: "Zalau - Jud. SJ" },
  { id: "mb5jKl", label: "Zalha - Jud. SJ" },
  { id: "m1BoYr", label: "Zalhanaua - Jud. PH" },
  { id: "mkzROl", label: "Zalnoc - Jud. SJ" },
  { id: "lL7NWZ", label: "Zalomestra - Jud. SV" },
  { id: "lWOdEl", label: "Zam - Jud. HD" },
  { id: "ZzVQBZ", label: "Zambreasca - Jud. TR" },
  { id: "ZyYjkZ", label: "Zambroaia - Jud. PH" },
  { id: "ZzzQEZ", label: "Zamfira - Jud. PH" },
  { id: "AZvX5m", label: "Zamfiresti - Jud. AG" },
  { id: "ZQvMJl", label: "Zamfiresti - Jud. BR" },
  { id: "lOYBOr", label: "Zamostea - Jud. SV" },
  { id: "mkEAwZ", label: "Zanesti - Jud. NT" },
  { id: "m1D06l", label: "Zanoaga - Jud. DJ" },
  { id: "rBjwVr", label: "Zanoaga - Jud. OT" },
  { id: "lJ98eZ", label: "Zanoaga - Jud. PH" },
  { id: "lDDenl", label: "Zanogi - Jud. CS" },
  { id: "r8Dqqr", label: "Zanou - Jud. CS" },
  { id: "3ZVkdl", label: "Zanzesti - Jud. AB" },
  { id: "l08k6l", label: "Zaplazi - Jud. BZ" },
  { id: "mgLJkr", label: "Zapodea - Jud. HR" },
  { id: "mkERPZ", label: "Zapodea - Jud. MS" },
  { id: "rKx0RZ", label: "Zapodeni - Jud. VS" },
  { id: "mbPKEZ", label: "Zapodia - Jud. BC" },
  { id: "rE74ql", label: "Zapodia - Jud. BZ" },
  { id: "WZPYkl", label: "Zarand - Jud. AR" },
  { id: "AZQBvl", label: "Zaries - Jud. AB" },
  { id: "r99EXr", label: "Zarneni - Jud. VL" },
  { id: "krwGvr", label: "Zarnesti - Jud. AG" },
  { id: "ZRjPvZ", label: "Zarnesti - Jud. BV" },
  { id: "ZQvY4l", label: "Zarnesti - Jud. BZ" },
  { id: "lLDE1l", label: "Zarnesti - Jud. GL" },
  { id: "rK63Gm", label: "Zarnesti - Jud. VL" },
  { id: "Z4w98r", label: "Zarnestii De Slanic - Jud. BZ" },
  { id: "l54G3Z", label: "Zasloane - Jud. CS" },
  { id: "rB5B2m", label: "Zatreni - Jud. VL" },
  { id: "ZvODPl", label: "Zatrenii De Sus - Jud. VL" },
  { id: "m1Ge8Z", label: "Zau De Campie - Jud. MS" },
  { id: "rd744m", label: "Zauan - Jud. SJ" },
  { id: "rob1Xm", label: "Zauan Bai - Jud. SJ" },
  { id: "r9nO3Z", label: "Zaval - Jud. DJ" },
  { id: "ZVk3dl", label: "Zavideni - Jud. VL" },
  { id: "leLo6Z", label: "Zavoaia - Jud. BR" },
  { id: "2l5E2l", label: "Zavoi - Jud. AG" },
  { id: "rE7BVl", label: "Zavoi - Jud. CS" },
  { id: "rE28BZ", label: "Zavoi - Jud. HD" },
  { id: "lejo1m", label: "Zavoieni - Jud. VL" },
  { id: "Zye4KZ", label: "Zavoiu - Jud. BH" },
  { id: "r3GRnr", label: "Zavoiu - Jud. DB" },
  { id: "l71edr", label: "Zbegu - Jud. CS" },
  { id: "ZM9a4Z", label: "Zbereni - Jud. IS" },
  { id: "lez9gm", label: "Zberoaia - Jud. IS" },
  { id: "rBOqJZ", label: "Zboiu - Jud. GR" },
  { id: "ZP7z3r", label: "Zdrapti - Jud. HD" },
  { id: "rBR15r", label: "Zebil - Jud. TL" },
  { id: "rKjekm", label: "Zece Hotare - Jud. BH" },
  { id: "lD292r", label: "Zece Prajini - Jud. IS" },
  { id: "ZPaxEl", label: "Zegaia - Jud. MH" },
  { id: "m1GW6Z", label: "Zegujani - Jud. MH" },
  { id: "lj5KBZ", label: "Zeicani - Jud. HD" },
  { id: "ZPeq3l", label: "Zeletin - Jud. BZ" },
  { id: "mG5D9Z", label: "Zemes - Jud. BC" },
  { id: "lWODYl", label: "Zencani - Jud. HR" },
  { id: "Wr6J2r", label: "Zerind - Jud. AR" },
  { id: "XlD0Jl", label: "Zerindu Mic - Jud. AR" },
  { id: "lOWyNl", label: "Zervesti - Jud. CS" },
  { id: "mnQgal", label: "Zetea - Jud. HR" },
  { id: "ZPAQvl", label: "Zgribesti - Jud. TM" },
  { id: "Orovwm", label: "Zgripcesti - Jud. AG" },
  { id: "lWMDYr", label: "Zgubea - Jud. VL" },
  { id: "mA9Eam", label: "Zgura - Jud. VS" },
  { id: "m1nd6r", label: "Ziduri - Jud. BZ" },
  { id: "arBPQl", label: "Zidurile - Jud. AG" },
  { id: "r9nRaZ", label: "Zidurile - Jud. DB" },
  { id: "krwQjl", label: "Zigoneni - Jud. AG" },
  { id: "mnJBXm", label: "Zilisteanca - Jud. BZ" },
  { id: "Xl0gkZ", label: "Zimandcuz - Jud. AR" },
  { id: "Rr9NxZ", label: "Zimandu Nou - Jud. AR" },
  { id: "lpAkMm", label: "Zimbor - Jud. SJ" },
  { id: "Jr3N5m", label: "Zimbru - Jud. AR" },
  { id: "r6O2gZ", label: "Zimbru - Jud. CL" },
  { id: "ZM7b8m", label: "Zimbru - Jud. GL" },
  { id: "l5992l", label: "Zimnicea - Jud. TR" },
  { id: "rEaVgr", label: "Zimnicele - Jud. TR" },
  { id: "mA1ggm", label: "Zimti - Jud. MS" },
  { id: "r33Xer", label: "Zizin - Jud. BV" },
  { id: "lYJ4Qm", label: "Zizinca - Jud. VS" },
  { id: "Zy2xql", label: "Zlagna - Jud. CS" },
  { id: "rwEdyl", label: "Zlagna - Jud. SB" },
  { id: "mbKN2r", label: "Zlata - Jud. TR" },
  { id: "mgow9m", label: "Zlatarei - Jud. VL" },
  { id: "ZMYJpZ", label: "Zlatari - Jud. BC" },
  { id: "ZQqgnZ", label: "Zlatari - Jud. DJ" },
  { id: "r6Ow7Z", label: "Zlatita - Jud. CS" },
  { id: "Vlea3l", label: "Zlatna - Jud. AB" },
  { id: "ljG71m", label: "Zlatunoaia - Jud. BT" },
  { id: "mnQXGl", label: "Zlodica - Jud. IS" },
  { id: "rKnGWm", label: "Zmeu - Jud. IS" },
  { id: "rXxapr", label: "Zmeuratu - Jud. VL" },
  { id: "ZyYbkZ", label: "Zmeuret - Jud. PH" },
  { id: "mGwepm", label: "Zmogotin - Jud. CS" },
  { id: "rwp3vZ", label: "Zoina - Jud. CS" },
  { id: "mb9yal", label: "Zoita - Jud. BZ" },
  { id: "r82V6l", label: "Zoitani - Jud. BT" },
  { id: "l0K9JZ", label: "Zolt - Jud. TM" },
  { id: "rBOj2Z", label: "Zoltan - Jud. CV" },
  { id: "rXaeRZ", label: "Zorani - Jud. TM" },
  { id: "ZyeK4Z", label: "Zoreni - Jud. BN" },
  { id: "lOW8nl", label: "Zorenii De Vale - Jud. CJ" },
  { id: "ZQv5nl", label: "Zoresti - Jud. BZ" },
  { id: "rwp4vZ", label: "Zorile - Jud. CS" },
  { id: "mb9eYl", label: "Zorile - Jud. CT" },
  { id: "l7Qg5r", label: "Zorile - Jud. GR" },
  { id: "ZMGVRm", label: "Zorleasca - Jud. OT" },
  { id: "mAb52r", label: "Zorlencior - Jud. CS" },
  { id: "r2Mgem", label: "Zorleni - Jud. VS" },
  { id: "ZMR2Ql", label: "Zorlentu Mare - Jud. CS" },
  { id: "ZVw1Rl", label: "Zorlesti - Jud. GJ" },
  { id: "r3GA0r", label: "Zorzila - Jud. GJ" },
  { id: "ZQGz5m", label: "Zurbaua - Jud. IF" },
  { id: "krdJzl", label: "Zuvelcati - Jud. AG" },
  { id: "ZQVpdl", label: "Zvoranesti - Jud. NT" },
  { id: "ZyWYom", label: "Zvoristea - Jud. SV" },
  { id: "ZNGz7l", label: "Zvorsca - Jud. DJ" }
]