import {apiAction} from "store/middleware/api";
import {setApiResponse} from "./auth";
import {dynamoAction} from "store/middleware/api";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import {date} from "yup/lib/locale";
import {mapMenuItems} from "../../layouts/magic/utils/menuUtil";
import store from "../store";
import {API} from "../middleware/api";


export const SET_VALIDATION_RULES = "backData/SET_VALIDATION_RULES";
export const SET_DICTIONARY = "backData/SET_DICTIONARY";
export const SET_PRODUCT_CATEGORY = "backData/SET_PRODUCT_CATEGORY";
export const SET_ENUM = "backData/SET_ENUM";
export const SET_LOGIN_CALLS = "backData/SET_LOGIN_CALLS";
export const UPDATE_LOGIN_CALLS = "backData/UPDATE_LOGIN_CALLS";
export const SET_LIST = "backData/SET_LIST";
export const LOAD_ALL_OPTIONS = "backData/LOAD_ALL_OPTIONS";
export const SET_PRODUCTS_TO_EXCEL = "backData/SET_PRODUCTS_TO_EXCEL"
export const SET_MENU_ITEMS = "backData/SET_MENU_ITEMS"
export const SET_LEAD_ACTIVITY = "backData/SET_LEAD_ACTIVITY"
export const SET_DEAL_ACTIVITY = "backData/SET_DEAL_ACTIVITY"

//new action: loadd all options


const initialState = {
    validationRules: [],
    ddOptions: {},
    dictionary: {},
    enums: {},
    productCategory: [],
    lists: {}, //simmilar with dictionary/ENUMs but arbitrary data structure
    loginCalls: 0, // used to redirect after all the login calls are done
    productsToExcel: [],
    menuItems: [],
    leadActivity: [],
    dealActivity: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VALIDATION_RULES:
            return {
                ...state,
                validationRules: action.payload,
            };
        case SET_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategory: action.payload,
            };
        case SET_LOGIN_CALLS:
            return {
                ...state,
                loginCalls: action.payload,
            };
        case UPDATE_LOGIN_CALLS:
            return {
                ...state,
                loginCalls: state.loginCalls + action.payload,
            };
        case SET_DICTIONARY:
            return {
                ...state,
                dictionary: {...state.dictionary, ...action.payload},
            };
        case SET_ENUM:
            return {
                ...state,
                enums: {...state.enums, ...action.payload},
            };
        case SET_LIST:
            return {
                ...state,
                lists: {...state.lists, ...action.payload},
            };
        case LOAD_ALL_OPTIONS:
            return {
                ...state,
                ddOptions: {...state.ddOptions, ...action.payload},
            };
        case SET_PRODUCTS_TO_EXCEL:
            return {
                ...state,
                productsToExcel: action.payload,
            };
        case SET_MENU_ITEMS:
            return {
                ...state,
                menuItems: action?.payload
            };
        case SET_LEAD_ACTIVITY:
            return {
                ...state,
                leadActivity: action?.payload
            };
        case SET_DEAL_ACTIVITY:
            return {
                ...state,
                dealActivity: action?.payload
            };
        default:
            return state;
    }
}

export const setDictionary = (data) => ({
    type: SET_DICTIONARY,
    payload: {...data},
})

export const loadValidationRules = () =>
    apiAction({
        endPoint: "/public/validation-rules",
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            dispatch(setApiResponse(data));
            return {
                type: SET_VALIDATION_RULES,
                payload: data,
            };
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });

export const setBackList = (id, data) => ({
    type: SET_LIST,
    payload: {[id]: data}
})
export const setLoginCalls = (data) => ({
    type: SET_LOGIN_CALLS,
    payload: data
})
export const incrementLoginCall = () => ({
    type: UPDATE_LOGIN_CALLS,
    payload: 1
})
export const decrementLoginCall = () => ({
    type: UPDATE_LOGIN_CALLS,
    payload: -1
})

export const loadDictionary = (id) => {
    let state = store.getState();
    let dictionaries = state?.backData?.dictionary;

    if (dictionaries && dictionaries[id] && dictionaries[id].length > 0) {
        return { type: "a"}
    }


    return apiAction({
        endPoint: "/dictionary/type/" + id,
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            dispatch(setApiResponse(data));
            incrementLoginCall();//dispatch
            let payload = {};
            payload[id] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
}

export const getAllClients = () =>
    apiAction({
        endPoint: "/organization/webshop-client/my-options", // alloptions",
        onSuccess: (data, dispatch) => {
            //console.log("CLIENTS API data", data);
            let payload = {};
            payload["CLIENTS"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
export const getAllClientsOptions = () =>
    apiAction({
        endPoint: "/organization/webshop-client/alloptions", //
        onSuccess: (data, dispatch) => {
            //console.log("CLIENTS API data", data);
            let payload = {};
            payload["ALL_CLIENTS"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
export const getAllAgents = () =>
    apiAction({
        endPoint: "/organization-user-relation/user-list-by-relation/sales_manager",
        onSuccess: (data, dispatch) => {
            //console.log("CLIENTS API data", data);
            let payload = {};
            payload["AGENTS"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
export const getAllRoles = (toList) =>
    apiAction({
        endPoint: "/group/alloptions/",
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            let payload = {};
            payload["ALL_ROLES"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//did not recognize dispatch on error branch on server down
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });

export const getMenuItems = () => {
    return apiAction({
        endPoint: "/menu/all",
        method: "GET",
        onSuccess: (data) => {
            return {
                type: SET_MENU_ITEMS,
                payload: mapMenuItems(data)
            };
        },
        onFailure: () => {
            incrementLoginCall();
            console.log("error occured custom - menu items");
            return {
                type: "a",
            };
        },
    })
};

export const getLeadActivity = (id) => {
    return apiAction({
        endPoint: `/organization/lead/activities/search/${id}`,
        data: {"firstRow": 0, "pageSize": 13},
        method: "POST",
        onSuccess: (data) => {
            return {
                type: SET_LEAD_ACTIVITY,
                payload: data
            };
        },
        onFailure: () => {
            incrementLoginCall();
            console.log("error occured custom - lead activity");
            return {
                type: "a",
            };
        },
    })
};

export const getDealActivity = (id) => {
    return apiAction({
        endPoint: `/sales-deal/activities/search/${id}`,
        data: {"firstRow": 0, "pageSize": 400},
        method: "POST",
        onSuccess: (data) => {
            return {
                type: SET_DEAL_ACTIVITY,
                payload: data
            };
        },
        onFailure: () => {
            // incrementLoginCall();
            console.log("error occured custom - lead activity");
            return {
                type: "a",
            };
        },
    })
};

export const getExternalRoles = () =>
    apiAction({
        endPoint: "/group/alloptions/EXTERNAL",
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            let payload = {};
            payload["EXTERNAL_ROLES"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
export const getInternalRoles = () =>
    apiAction({
        endPoint: "/group/alloptions/INTERNAL",
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            let payload = {};
            payload["INTERNAL_ROLES"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
export const getAllUniLocations = () =>
    apiAction({
        endPoint: "/organization/workplace/alloptions",
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            let payload = {};
            payload["ALL_UNI_LOCATIONS"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });
export const loadProductCategories = (isAdmin) =>
    apiAction({
        endPoint: `/product-category/${isAdmin ? "admin/" : ""}all`,
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            dispatch(setApiResponse(data));
            incrementLoginCall();//dispatch

            return {
                type: SET_PRODUCT_CATEGORY,
                payload: data,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });

export const getRankingOptions = () =>
    apiAction({
        endPoint: "/product/goods/shop/product-ranking-list",
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            let payload = {};
            payload["RANKINGS"] = data;
            return {
                type: SET_DICTIONARY,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });

export const loadEnums = (shortType) =>
    // enums?type=eu.mgs.synergic.model.product.ProductStockStatus
    apiAction({
        endPoint: "/enums?type=eu.mgs.synergic.model." + shortType,
        onSuccess: (data, dispatch) => {
            //console.log("API data", data);
            dispatch(setApiResponse(data));
            incrementLoginCall();//dispatch
            let payload = {};
            payload[shortType] = data;
            return {
                type: SET_ENUM,
                payload,
            };
        },
        onFailure: (data, dispatch) => {
            incrementLoginCall();//dispatch
            console.log("error occured custom");
            return {
                type: "a",
            };
        },
    });

export const loadAllDdOptions = () =>
    dynamoAction({
        method: "QUERY",
        parameters: {
            TableName: "TempDevStack-MainTable-74SE74RNUI3D",
            KeyConditionExpression: "#pk = :pkvalue",
            IndexName: "SK-PK",
            ExpressionAttributeNames: {
                "#pk": "SK",
            },
            ExpressionAttributeValues: {
                ":pkvalue": {S: "DDOPTIONS"},
            },
        },
        onSuccess: (data, dispatch) => {
            return {
                payload: data?.items?.map(item => ({[item?.name]: item?.options})),
                type: LOAD_ALL_OPTIONS,
                // lastEvalKey: data.lastEvalKey,
            };
        },
        onFailure: (err) => {
            // showMagicToaster({title: err})
            console.log(err);
            return {
                type: "a",
            };
        },
    });
export const loadTestPdf = () =>
    apiAction({
        endPoint: '/product/relation/export',
        method: "POST",
        responseType: 'blob',
        data: {
            format: "PDF",
            language: "RO",
            entityCode: "productRelation",
            queryCriteria: {"firstRow": 0, "pageSize": 10, "filters": [], "searchTerm": null, "jsonLogic": null},
            columns: [
                "mainProductCode",
                "mainProductName",
                "mainProductBrand",
                "mainProductUnit",
                "mainProductStatus",
                "mainProductPriceNetMin",
                "mainProductPriceNetMax",
                "mainProductCurrency",
                "relationType",
                "relatedProductCode",
                "relatedProductName",
                "relatedProductBrand",
                "relatedProductUnit",
                "relatedProductStatus",
                "relatedProductPriceNetMin",
                "relatedProductPriceNetMax",
                "relatedProductCurrency"
            ]
        },
        onSuccess: (data, dispatch) => {//console.log("DATA BACK", data.headers)
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
            downloadLink.download = "testfile.pdf";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return {
                type: "a",
            };
        },
        onFailure: (data, dispatch) => {
            console.log("error occured custom");
            //toastr.error(".......")
            return {
                type: "a",
            };
        },
    });

export const loadInvoice = (nr, id) =>
    apiAction({
        endPoint: `/client-invoice/report-export/${id}`,
        method: "GET",
        responseType: 'blob',
        // data: {
        //   "name" : "Factura",
        //   "path" : "Invoice.jrxml",
        //   "parameters": [
        //         {
        //             "name":"Invoice_id",
        //             "type": null,
        //             "subType": null,
        //             "value": id,
        //             "querySearchMethod": null,
        //             "queryValueField": null,
        //             "queryCriteria": null
        //         }
        //     ],
        //   "property" : {
        //         "idx": 1,
        //         "displayName": "Raport_RZB",
        //         "permission": null
        //     }
        // },
        onSuccess: (data, dispatch) => {//console.log("DATA BACK")
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
            downloadLink.download = `Factura_${nr}_${moment(Date.now()).format("DD.MM.YYYY")}.pdf`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return {
                type: "a",
            };
        },
        onFailure: (data, dispatch) => {
            console.log("error occured custom");
            toastr.error("Momentan factura nu a putut fi descarcata, incearca mai tarziu")
            return {
                type: "a",
            };
        },
    });


