import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import MagicSidebarItem from "./MagicSidebarItem";
import { getMenuItems } from "store/modules/backData";

import { MenuItem } from "./menu";
import { SvgIcon } from "@synergic/core";
import { getRefreshToken } from "store/middleware/api";
import { logout } from "store/justActions/javaAuth";

interface MagicSidebarProps {
    left?: boolean,
    isOpen?: boolean
}

interface IState {
    backData?: {
        menuItems?: Array<MenuItem>
    }
}

export default function MagicSidebar(props: MagicSidebarProps) {
    const { left = true, isOpen } = props

    const dispatch = useDispatch()
    const menuItems = useSelector((state: IState) => state?.backData?.menuItems)

    useEffect(() => {
        dispatch(getMenuItems())
    }, [])

    return (
        <nav className={`magic-sidebar ${isOpen ? 'open' : 'closed'}`}>
            <div className="dual-bar">
                <div>
                    {menuItems?.map((item: MenuItem, index: any) => <MagicSidebarItem key={index} item={item} />)}
                </div>
                <div className="logout-item" onClick={() => {
                    dispatch(logout())
                }}>
                    <SvgIcon mr={0} h={6} w={6} name="logout" stroke="white" />
                </div>
            </div>
        </nav>
    )
}