
import moment from 'moment';

// TODO: change lang from session
//import 'moment/locale/hu';
import 'moment/locale/ro';

//TODO: review> should we move these to staticData?
export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const DATE_FORMAT_STANDARD = 'YYYY.MM.DD.';
export const DATE_FORMAT_STANDARD_WMONTH_STR = 'DD MMM YYYY';
export const DATE_FORMAT_STANDARD_WDAY = 'YYYY.MM.DD, dddd';
export const DATE_FORMAT_STANDARD_WTIME = 'DD MMM YYYY HH:mm';
export const DATE_FORMAT_STANDARD_WDAYTIME = 'ddd, D MMM  YYYY, h:mm';
export const DATE_FORMAT_DAY_ONLY = 'dddd';
export const DATE_FORMAT_TIME_ONLY = 'HH:mm';


export const formatDate = (value, format) => {
    return value ? (moment.isMoment(value) ? value : moment(value)).format(format ?? DATE_FORMAT_DEFAULT) : null;
}

export const getToday = () => moment();
export const getDayOfWeekFromDate = value => formatDate(value, DATE_FORMAT_DAY_ONLY);
export const getTimeFromDate = value => formatDate(value, DATE_FORMAT_TIME_ONLY)
export const formatDateStandard = value => formatDate(value, DATE_FORMAT_STANDARD);
export const formatDateStandardWDay = value => formatDate(value, DATE_FORMAT_STANDARD_WDAY);
export const formatDateStandardWMonthStr = value => formatDate(value, DATE_FORMAT_STANDARD_WMONTH_STR);
export const formatDateWTime = value => formatDate(value, DATE_FORMAT_STANDARD_WTIME);
export const formatDateWDayTime = value => formatDate(value, DATE_FORMAT_STANDARD_WDAYTIME);

export const formatISOMoment = date => moment.isMoment(date) ? date.format() : moment(date).format();

export const calcDateDifference = (date1, date2) => {
    return moment.duration(moment(date2).diff(moment(date1)));
}

// dateType: ex. 'd' or 'day', 'H' or 'hours'...
export const addAdditionalTimeToDate = (date, addValue, dateType) => {
    return (moment.isMoment(date) ? date : moment(date)).add(addValue, dateType);
}

export const addHoursAndMinutesToDate = (date, hours, minutes) => {
    return addAdditionalTimeToDate(addAdditionalTimeToDate(date, hours, 'H'), minutes, 'm');
}


export const getMonthsDayIntervallum = (date, tillToday, manualEndDay = null) => {
    date = date ? moment.isMoment(date) ? date : moment(date) : moment();
    let lastDay = manualEndDay === null ?
        (tillToday ? moment().format("DD") : date.endOf('month').format("DD")) : manualEndDay;
    return moment(date.startOf('month')).format("DD") + " - " + lastDay + ".";
}

export const dateIsAfter = (date) => {
    return date ? moment(date).isAfter() : date;
}

export function getMonthDays(yearin, inMonth, startSunday=false) {
    const daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const year = Number.parseInt(yearin)
    const inputMonth = Number.parseInt(inMonth)
    // If the year is leap then February has 29 days
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        daysInMonth[2] = 29;
    } else {
        daysInMonth[2] = 28;
    }
    //0 sunday - 6 saturday
    const firstDay = new Date(yearin, inMonth - 1, 1).getDay();
    //const lastDay = new Date(yearin, inMonth - 1, daysInMonth[inMonth]).getDay();
    let
        prev = [25, 26, 27, 28, 29, 30, 31],
        cur = [],
        next = [1, 2, 3, 4, 5, 6, 7],
        missFront = 0,
        missBack = 0;
    
    for (let day = 1; day <= daysInMonth[inMonth]; day++) {
        cur.push(day);
    }
    
    missFront = firstDay === 0 ? 6 : (firstDay - 1)
    //calculate the inputMounth last row missings
    missBack = 35 - (cur.length + missFront);
    //console.log("INMONTH ", inMonth, "MISS FRONT ", missFront,"MISS BACK ", missBack)
    cur = [
        ...prev.slice(prev.length - missFront),
        ...cur,
        ...next.slice(0, missBack)
    ];
    return cur;
}
