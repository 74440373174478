import { lazy } from "react";

export const userRoutes = [
    {
        exact: true,
        path: "/admin/user/client/list",
        component: lazy(() => import("../views/userList")),
    },
    {
        exact: false,
        path: "/admin/user/client/form",
        component: lazy(() => import("../views/userForm")),
    },
    {
        exact: false,
        path: "/admin/user/role/list",
        component: lazy(() => import("../views/rolesList")),
    },

    {
        exact: false,
        path: "/admin/user/role/form",
        component: lazy(() => import("../views/roleForm")),
    },
];