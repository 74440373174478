/* eslint-disable import/no-anonymous-default-export */
// import store from "store";

// import store from "store";
import { adminLeftMenu } from "mockData/adminMenu";
import { apiAction } from "store/middleware/api";

export const SET_COLLAPSED_MENU = "page/SET_COLLAPSED_MENU";
export const SET_CRT_LIST_ITEMS = "page/SET_CRT_LIST_ITEMS";
export const ADD_CRT_LIST_ITEMS = "page/ADD_CRT_LIST_ITEMS";
export const DELETE_CRT_LIST_ITEM = "page/DELETE_CRT_LIST_ITEM";
export const SET_LEFT_MENU_ITEMS = "page/SET_LEFT_MENU_ITEMS";
export const SET_PAGE_MODAL_DATA = "page/SET_PAGE_MODAL_DATA";
export const SET_PAGE_MODAL = "page/SET_PAGE_MODAL";
export const SET_PAGE_EDIT = "page/SET_PAGE_EDIT";
export const SET_WIZZARD_STEP = "page/SET_WIZZARD_STEP";
export const RESET_CRT_LIST = "page/RESET_CRT_LIST";
export const SET_CRT_LIST_SEARCH_STRING = "page/SET_CRT_LIST_SEARCH_STRING";
export const SET_CRT_FORM_ITEM = "page/SET_CRT_FORM_ITEM";
export const SET_SCROLL_POSITION = "page/SET_SCROLL_POSITION";
export const SET_ACTION_TO_CONFIRM = "page/SET_ACTION_TO_CONFIRM";
export const SET_TEMP_PRODUCT_LIST_DETAILS = "page/SET_TEMP_PRODUCT_LIST_DETAILS";
export const SET_ALL_FILED_ERRORS = "page/SET_ALL_FILED_ERRORS";
export const SET_TEMP_PRODUCT_IMPORT_DETAILS = "page/SET_TEMP_PRODUCT_IMPORT_DETAILS";
export const SET_IMAGE_POSITION = "page/SET_IMAGE_POSITION";
export const SET_IMAGE_WIDTH = "page/SET_IMAGE_WIDTH";
export const SET_LOADING_INDICATOR = "page/SET_LOADING_INDICATOR";
export const SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS = "page/SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS"
export const SET_LOCATION_NAME = "page/SET_LOCATION_NAME"
export const SET_PAGE_Y_SCROLL_POSITION = "page/SET_PAGE_Y_SCROLL_POSITION"
export const DELETE_ITEM_FROM_IMPORT = "page/DELETE_ITEM_FROM_IMPORT"
export const SET_MODAL_PRICE_AND_QUANTITY = "page/SET_MODAL_PRICE_AND_QUANTITY"
export const SET_ACCESSORY_PRICE_AND_QUANTITY = "page/ SET_ACCESSORY_PRICE_AND_QUANTITY"

const initialcrtList = {
  items: [],
  lastItemHint: null,
  searchString: "",
}
const initialState = {
  collapsedMenu: false,
  isEdit: false,
  crtFormItem: undefined,
  actionToConfirm: undefined,
  modal: undefined,
  modalData: undefined,
  crtList: initialcrtList,
  wizzardStep: 1,
  leftMenuItems: adminLeftMenu,
  fieldErrors: {},
  loadingIndicators: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TEMP_PRODUCT_LIST_DETAILS:
      const products = state.crtFormItem?.products?.map(oldProd =>
        oldProd?.id === action.payload?.id ? action.payload : oldProd
        )
      return {
        ...state,
        crtFormItem: {
          ...state.crtFormItem,
          products
        },
      };
    case SET_TEMP_PRODUCT_IMPORT_DETAILS:
      const products2 = state.crtFormItem?.products ? state.crtFormItem?.products : [];
      products2.push(action.payload)
      return {
        ...state,
        crtFormItem: {
          ...state.crtFormItem,
          products: products2
        },
      };
    case SET_LOADING_INDICATOR:
        return {
          ...state,
          loadingIndicators: { ...state.loadingIndicators, ...action.payload },
        };
    case SET_WIZZARD_STEP:
      return {
        ...state,
        wizzardStep: action.payload,
      };
    case SET_ACTION_TO_CONFIRM:
      return {
        ...state,
        actionToConfirm: action.payload,
        modal: action.payload ? "SYS-confirm-action" : undefined
      };
    case SET_CRT_FORM_ITEM:
      return {
        ...state,
        crtFormItem: action.payload.combine
          ? {...state.crtFormItem, ...action.payload.data}
          : action.payload.data,
      };
      case SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS:
        //console.log("PAYLOAD ", action.payload)
        //{...p, localStockQuantity: action.payload.localStockQuantity, centralStockQuantity: action.payload.centralStockQuantity}
        return {
          ...state,
          crtFormItem: {...state.crtFormItem,
            content:
            state?.crtFormItem?.content.map( i => i.type !== "products" ? i : {
              ...i, products: i?.products?.map( p => p.id === action.payload.id ? {...action.payload, quantity: p.quantity ?? 0} : p)
            }
        ),
          }
        };
      case SET_LOCATION_NAME:
        return {
          ...state,
          crtFormItem: {...state.crtFormItem,
            lof:
            state?.crtFormItem?.lof.map( i => i.locationId !== action?.payload?.id ? i :
            {...i, locationName: action.payload.name})
            },
        };
    case SET_PAGE_MODAL:
      return {
        ...state,
        modal: action.payload?.name,
        modalData : action.payload?.data ?? state.modalData,
      };
    case SET_PAGE_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload,
      };
    case SET_MODAL_PRICE_AND_QUANTITY:
      return {
        ...state,
        modalData: {...state.modalData, quantity: action.payload}
      };
    case  SET_ACCESSORY_PRICE_AND_QUANTITY:
      return {
        ...state,
        crtList: {...state.crtList,
          items: state?.crtList?.items.map(i => i?.id === action.payload.id ? {...i, quantity: action.payload.changedQuantity} : i),// priceNet: i.priceNet * action.payload.changedQuantity, priceGross: i.priceGross * action.payload.changedQuantity,
          lastItemHint: action.payload?.lastItemHint,
        }
      };
    case SET_ALL_FILED_ERRORS:
      return {
        ...state,
        fieldErrors: action.payload,
      };
    case SET_PAGE_EDIT:
      return {
        ...state,
        isEdit: action.payload ?? false,
      };
    case SET_LEFT_MENU_ITEMS:
      return {
        ...state,
        leftMenuItems: action.payload,
      };
    case SET_CRT_LIST_SEARCH_STRING:
      return {
        ...state,
        crtList: {...state.crtList,
          searchString: action.payload,
        }
        };
    case SET_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {...state.crtList,
          items: action.payload.items,
          lastItemHint: action.payload.lastItemHint,
        }
      };
    case ADD_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {...state.crtList,
          items: [...state.crtList.items, ...action.payload.items],
          lastItemHint: action.payload.lastItemHint,
        }
      };
    case DELETE_CRT_LIST_ITEM:
      return {
        ...state,
        crtList: {
          items: state?.crtList?.items?.filter(i => i.id !== action.payload),
          lastItemHint: state?.crtList?.lastItemHint,
        }
      }
    case RESET_CRT_LIST:
      return {
        ...state,
        crtList: initialcrtList,
      };
    case SET_COLLAPSED_MENU:
      return {
        ...state,
        collapsedMenu: action.payload,
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        setScrollPosition: action.payload,
      };
    case SET_PAGE_Y_SCROLL_POSITION:
      return {
        ...state,
        yPageScrollPos: action.payload,
      }
    default:
      return state;
  }
}

export const setLoadingIndicator = (id, state) => ({
  type: SET_LOADING_INDICATOR,
  payload: {[id] : state},
})
export const clearLoadingIndicator = (id) => ({
  type: SET_LOADING_INDICATOR,
  payload: {[id] : undefined},
})

export const setActionToConfirm = (data) => ({
  type: SET_ACTION_TO_CONFIRM,
  payload: data,
})
export const setAllFieldErrors = (data) => ({
  type: SET_ALL_FILED_ERRORS,
  payload: data,
})

export const setPageModal = (name, data) => ({
  type: SET_PAGE_MODAL,
  payload: {name, data},
})
export const setPageModalData = (data) => ({
  type: SET_PAGE_MODAL_DATA,
  payload: data,
})
export const setMainProductPriceAndQuantity = (data) => ({
  type: SET_MODAL_PRICE_AND_QUANTITY,
  payload: data,
})

export const setAccessoryProductPriceAndQuantity = (data) => ({
  type: SET_ACCESSORY_PRICE_AND_QUANTITY,
  payload: data,
})
export const setLeftMenuItems = (data) => ({
  type: SET_LEFT_MENU_ITEMS,
  payload: data,
})
export const setWizzardStep = (data) => ({
  type: SET_WIZZARD_STEP,
  payload: data,
})
export const resetCrtList = () => {
  return ({
    type: RESET_CRT_LIST,
    //payload: data,
  })
}

export const setCrtList = (data) => ({
  type: SET_CRT_LIST_ITEMS,
  payload: data,
})

export const addCrtListItems = (data) => ({
  type: ADD_CRT_LIST_ITEMS,
  payload: data,
})

export const deleteCrtListItem = (data) => ({
  type: DELETE_CRT_LIST_ITEM,
  payload: data,
})

export const setCrtListSearchString = (data) => ({
  type: SET_CRT_LIST_SEARCH_STRING,
  payload: data,
})

export const setImportedDataFromExcel = (data) => ({
  type: SET_TEMP_PRODUCT_IMPORT_DETAILS,
  payload: data,
})

export const setCrtFormItem = (data, combine) => ({
  type: SET_CRT_FORM_ITEM,
  payload: {data, combine},
})

export const enrichCrtFrmLocations = (data, dispatch) => {
  //console.log("DATA ",  data)
  data?.addressList?.forEach(i => dispatch(fetchLocationNames(i.locationId, dispatch)))
  return { type: "a"}
}

const fetchLocationNames = (locationId, dispatch) =>
  //console.log("SECOND STEP ", locationId)
  apiAction({
    endPoint: `/location/` + locationId,
    onSuccess: (data, dispatch) => {
      //console.log("SECOND STEP ", data);
      return { type: SET_LOCATION_NAME,
          payload: data,
      }
    },
    onFailure: () => {
      return {
        type: "a"
      };
    }
  });

export const setPageEdit = (data) => ({
  type: SET_PAGE_EDIT,
  payload: data,
})

export const setCollapsedMenu = (flag) => ({
  type: SET_COLLAPSED_MENU,
  payload: flag,
})

export const setScrollPostion = (data) => ({
  type: SET_SCROLL_POSITION,
  payload: data,
})

export const setPageYScrollPosition = (data) => ({
  type: SET_PAGE_Y_SCROLL_POSITION,
  payload: data,
})
