import moment from "moment";

import { getPipelineStageDealsPagination } from 'store/justActions/miscApiInteractions';
import { ColumnsInterface } from '../views/form/form';

export const SALES_DEAL_STATUS_ACTIVE = "SalesDealStatus.ACTIVE"
export const SALES_DEAL_STATUS_WON = "SalesDealStatus.WON"
export const SALES_DEAL_STATUS_LOST = "SalesDealStatus.LOST"

export const getAvatarLabel = (name?: string) => {
    const nameParts = name?.split(' ')
    return `${nameParts?.[0]?.substring(0, 1)}${nameParts?.[1]?.substring(0, 1)}`
}

export const getStatusBg = (type?: string) => {
    switch (type) {
        case 'SalesDealStatus.ACTIVE':
            return 'blue-50'
        case 'SalesDealStatus.WON':
            return 'green-50'
        case 'SalesDealStatus.LOST':
            return 'red-60'
        default:
            return 'blue-50'
    }
}

export const getSecondStatusBg = (type?: string) => {
    switch (type) {
        case SALES_DEAL_STATUS_ACTIVE:
            return 'blue-40'
        case SALES_DEAL_STATUS_WON:
            return 'all-good-50'
        case SALES_DEAL_STATUS_LOST:
            return 'critical-50'
        default:
            return 'blue-40'
    }
}

export const getDealSvgProps = (type?: string) => {
    switch (type) {
        case 'NOTIFY':
            return { bgColor: 'cyan-20', stroke: '#00539A', name: 'notifications-1' }
        case 'NOTE':
            return { bgColor: 'magenta-20', stroke: '#D12771', name: 'document-10' }
        case 'MEETING':
            return { bgColor: 'blue-20', stroke: '#0043CE', name: 'upload-1' }
        case 'INTERFACE':
            return { bgColor: 'gray-20', stroke: '#6F6F6F', name: 'elements-5' }
        case 'EMAIL':
            return { bgColor: 'teal-20', stroke: '#007D79', name: 'message-2' }
        case 'TASK':
            return { bgColor: 'purple-20', stroke: '#8A3FFC', name: 'task-1' }
        case 'BRANCH':
            return { bgColor: 'purple-20', stroke: '#8A3FFC', name: 'money-6' }
        case 'PRE-SCORING':
            return { bgColor: 'gray-50', stroke: '#FFF', name: 'ocre-prescoring' }
        case 'SCORING':
            return { bgColor: 'gray-80', stroke: '#FFF', name: 'ocre-scoring' }
        default:
            return { bgColor: 'blue-20', stroke: '#161616', name: 'elements-5' }
    }
}

export const getLeadStatusSvg = (type: string) => {
    switch (type) {
        case 'ACTIVE':
            return 'check1'
        case 'INACTIVE':
            return 'check2'
        case 'SUSPENDED':
            return 'check3'
        default:
            return 'check4'
    }
}

export const getModalTitle = (type?: string) => {
    switch (type) {
        case 'TASK':
            return 'Add a note'
        case 'BRANCH':
            return 'Book a Branch Visit for the Client'
        case 'PRE-SCORING':
            return 'Retry Prescoring'
        case 'SCORING':
            return 'Retry Scoring'
        default:
            return 'Select Activity'
    }
}

export const parseApiDealData = (data: any) => {
    return {
        ...data
    }
}

export const parseColumns = (columns: ColumnsInterface[], dispatch: any, setColumns: Function) => columns.map(x => ({
    id: x.id,
    title: x.name,
    cards: x.items.map(card => ({
        title: card.name,
        subtitle: card.registrationNr,
        badges: parseCardBadges(card),
        extraData: {
            id: card.id
        },
        name: card.companyName,
        id: card.id
    })),
    badges: parseColumnBadges(x),
    bottomButtons: x.meta.pageNumber === x.meta.pageCount ? [] : [
        {
            importance: 'standard',
            type: 'secondary',
            label: 'Load more',
            isLoading: false,
            onClick: () => {
                dispatch(getPipelineStageDealsPagination({ id: x.id, page: x.meta.pageNumber + 1 })).unwrap().then((response: any) => {
                    setColumns((state: any) => [...state].map(column => (column === x) ? {
                        ...x,
                        ...response.data,
                        items: [...x.items, ...response.data.items]
                    } : column))
                })
            }
        }
    ]
}))

export const parseCardBadges = (item: any) => {
    const badges = [];
    if (item.currencyCode && item.value) {
        badges.push({ label: `${item.currencyCode} ${item.value}`, color: 'blue', size: 'big' })
    }

    if (item.period) {
        badges.push({ label: `${item.period} months`, color: 'gray', size: 'big' })
    }

    return badges;
}

export const parseColumnBadges = (column: any) => {
    const badges = [];

    if (column.totalValue) {
        badges.push({ label: `${column.currencyCode} ${column.totalValue}`, color: 'blue', size: 'big' })
    }

    badges.push({ label: `${column.dealCount} Deals`, color: 'gray', size: 'big' })
    badges.push({ label: `${column.percentage}%`, color: 'red', size: 'big' })

    return badges;
}

export const sortColums = (columns: any) => {
    return columns.sort((a: any, b: any) => a.idx - b.idx)
}

export const joinDateTime = (dueDate: Date, dueTime: string) => {
    const date = moment(dueDate)
    if (dueDate !== undefined) {
        const time = dueTime.split(':')
        return date.set({hours: parseInt(time[0]), minute: parseInt(time[1]), second: 0}).toISOString()
    }
}