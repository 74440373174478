import { apiAction } from "store/middleware/api";
import { setApiResponse } from "./auth";

const TOGGLE_LANGUAGE = "user/TOGGLE_LANGUAGE"
const SET_USER_INFO = "user/SET_USER_INFO"
const SET_USER_PERMISSIONS = "user/SET_USER_PERMISSIONS"
const SET_USER_GROUP_ID = "user/SET_USER_GROUP_ID"
export const RESET_USER_INFO = "user/RESET_USER_INFO"


const initialState = {
  language: 'EN',
  id: null,
  groupId: '',
  userId: '',
  name: '',
  organizationId: '',
  permissionsList: [],
  groupsId: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
      case TOGGLE_LANGUAGE:
        return {
          ...state,
        language: state.language === "EN" ? "RO" : "EN"
      }
      case SET_USER_INFO:
        return {
          ...state,
          ...action.payload,
      }
      case SET_USER_PERMISSIONS:
        return {
          ...state,
          permissionsList: action.payload,
      }
      case RESET_USER_INFO:
        return initialState
      case SET_USER_GROUP_ID:
        return {
          ...state,
          groupsId: action?.payload
        }
      default:
          return state;
  }
}

export const toggleLanguage = () => ({
  type: TOGGLE_LANGUAGE
})
export const setUserInfo = (data) => ({
  type: SET_USER_INFO,
  payload: data,
})
export const setUserPermissions = (data) => ({
  type: SET_USER_PERMISSIONS,
  payload: data,
})

export const setUserGroupsId = (data) => ({
  type: SET_USER_GROUP_ID,
  payload: data,
})

const saveUserData = {
  "accessKeyList": [],
  "id": null,
  "language": "RO",
  "loginName": null,
  "name": "Uniprest user",
  "newLoginName": "uniprest@test.com",
  "attachmentList": [],
  "avatar": null,
  "contactInfo": null,
  "department": "TRANSPORT",
  "groupIds": ["XlDbrD"],
  "organizationId": "0lYvRm",
  "phone": null,
  "position": "08",
  "reminderList": [],
  "status": "UserStatus.ACTIVE",
  "userDirectory": "INTERN",
  "workplace": null
}

export const saveUser = (data = saveUserData) => apiAction({
  endPoint: `/user`,
  method: "POST",
  data,
  onSuccess: (data, dispatch) => {
    dispatch( setApiResponse(data))
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});