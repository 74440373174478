import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
// import { reducer2 as formReducer } from "react-redux-toastr";
// import { reducer as formReducer } from 'redux-form';
// import { loadingBarReducer } from "react-redux-loading-bar";
import authReducer from "./modules/auth";
import jobReducer from "./modules/job";
import tableReducer from "./modules/table";
import dataViewReducer from "./modules/dataView";
import companyReducer from "./modules/company";
import { addColegiiReducer } from "./modules/colegii";
import pageReducer from "./modules/page";
import companyDetailReducer from "./modules/company_details";

import translateReducer from "./modules/translate";
import userReducer from "./modules/user";
import backDataReducer from "./modules/backData";
import dashboardReducer from "./modules/dashboard";

// import menuReducer from "./modules/menu";
// import authReducer from "./modules/auth";
import pageTabsReducer from "./modules/pageTabs";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import { reduxStorageKey } from "store/store";
import { persistReducer } from "redux-persist";


// export const LOGOUT_USER = "LOGOUT_USER";

export const rootReducer = (state, action) => {
  if (action.type === "auth/LOGOUT_USER") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem(`persist:${reduxStorageKey}`);
    // storage.removeItem('user')
    state = undefined;
  }


  return appReducer(state, action);
};
// this should be used in case of nested blacklist needed
// const filtersTrackingConfig = {
//   key: 'filtersTracking',
//   storage,
//   blacklist: ['selectedFilters']
//   };

const appReducer = combineReducers({
  auth: authReducer,
  page: pageReducer,
  company: companyReducer,
  job: jobReducer,
  colegii: addColegiiReducer,
  // form: formReducer,
  toastr: toastrReducer,
  translate: translateReducer,
  user: userReducer,
  table: tableReducer,
  dataView: dataViewReducer,
  // this should be used in case of nested blacklist needed
  //persistReducer(filtersTrackingConfig, dataViewReducer),
  company_details: companyDetailReducer,
  backData: backDataReducer,
  pageTabs: pageTabsReducer,
  dashboard: dashboardReducer
  // loadingBar: loadingBarReducer,
});

export default rootReducer;
