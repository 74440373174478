import {lazy} from "react";

export const dealRoutes = [
    {
        exact: false,
        path: "/admin/deal/overview/:action",
        component: lazy(() => import("../views/dealOverview")),

    },
    {
        exact: false,
        path: "/admin/deal/overview",
        component: lazy(() => import("../views/dealOverview")),

    },
    {
        exact: false,
        path: "/admin/deal/pipeline/list",
        component: lazy(() => import("../views/pipeline/pipelineList")),
    },
    {
        exact: false,
        path: "/admin/deal/:id",
        component: lazy(() => import("../views/form/form"))
    }

];