import store from "store/store"
import {apiAction} from "store/middleware/api"

export type METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

interface IActionParams {
    method?: METHODS
    isToastOnError?: boolean
    data?: any
    responseType?: any
    extraHeaders?: any
    label?: any
    baseUrl?: any
    qs?: any
}

export const getDataAsync = <T>(endPoint: string, params?: IActionParams) => new Promise<T>((resolve, reject) => {
    const f = (success: boolean) => (data: T | any) => {
        if (success) resolve(data)
        else reject(data)
        return {type: 'a'}
    }
    store.dispatch(apiAction(<any>{
        isToastOnError: false,
        endPoint,
        onSuccess: f(true),
        onFailure: f(false),
        ...params
    }))
})
