import {Redirect} from 'react-router'
import {useUserInfo} from 'utils/useUserInfo'
import Config from "config/config";
import store from "store/store";

const NotAuthGuard = (props: any) => {
    const {adminAccess} = useUserInfo()
    const {redirectUrl} = store.getState().auth;

    if (adminAccess) {
        if (redirectUrl) {
            return <Redirect to={redirectUrl}/>
        } else {
            return <Redirect to={Config.authenticatedMainUrl}/>
        }
    }

    return props.children
}

export default NotAuthGuard
