import {apiAction, reDispatch} from "store/middleware/api";
import {getDealActivity, setBackList, SET_DICTIONARY} from "store/modules/backData";
import {setDataItems, SET_VOLUME_DISCOUNT_QUNTITIES} from "store/modules/dataView";
import {loadViewData} from "store/modules/dataView/dataView-dataLoader";
import {showMagicToaster} from "utils/MagicToasterCall";
import store from "../store"
import {loadItem} from "./loadApiIems";
import {setCrtFormItem, setPageEdit} from "store/modules/page";
import axios from "axios";
import {setUserPermissions} from "../modules/user";
import {CallmeStages, LoansStages} from "../../modules/reports/constants/reports.constants";
import {organizeReportStages} from "../../modules/reports/utils/reports.utils";

export const changeStatusAPI = (props) => {
    const {itemType, id, status, successMessage} = props;

    if (!itemType || !id || !status) {
        console.error("Missing some data to call API action (itemType, id or status)");
        return {type: "error"};
    }
    return apiAction({
        endPoint: `/${itemType}/status-change?id=${id}&status=${status}`,
        method: "POST",
        onSuccess: () => {
            //TODO: toastr issues
            // if (successMessage)
            //   showMagicToaster({ title: successMessage})
            return reDispatch(dispatch => dispatch(loadViewData({reload: true})))
        },
        onFailure: () => {
            console.log("error occured custom");
            return {type: 'a'}
        }
    });
}

export const changePatchStatus = (props) => {
    const {itemType, id, status, successAction} = props;

    if (!itemType || !id || !status) {
        console.error("Missing some data to call API action (itemType, id or status)");
        return {type: "error"};
    }
    return apiAction({
        endPoint: `/${itemType}/status-change?id=${id}&status=${status}`,
        method: "PATCH",
        onSuccess: () => {
            //TODO: toastr issues
            // if (successMessage)
            //   showMagicToaster({ title: successMessage})
            return reDispatch(dispatch => dispatch(successAction()))
        },
        onFailure: () => {
            console.log("error occured custom");
            return {type: 'a'}
        }
    });
}

export const saveProductsRank = ({modalData, data}) => {
    return apiAction({
        endPoint: "/product/set-rank",
        method: "POST",
        data: {
            rank: data?.rank,
            idList: modalData,
        },
        onSuccess: (data, dispatch) => {
            console.log("API data", data);
            showMagicToaster({title: 'Datele au fost salvate'})
            return {type: 'a'}
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }
    });
}


export const loadSearchInList = (type = "uniprest/user") => {
    // let state = store.getState();

    return apiAction({
        endPoint: `/${type}/search`,
        method: "POST",
        data: {
            // ...productSearchData,
            // filterText: "",
            sortAsc: true,
            sortField: "id",
            firstRow: 0,
            pageSize: 1000,
            // searchTerm: "",
            // filters: createClassicFilters(),
            // jsonLogic: {
            //   "operation": "AND",
            //   "children": null,
            //   "field": null,
            //   "value": null
            // }
        },
        onSuccess: (data, dispatch) => {
            dispatch(setBackList(type, data?.items))
            return {type: "a",};
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }
    });
}

export const getVolumeDiscountQuantities = (id, productId) => apiAction({
    endPoint: `/product/goods/discount/volume/quantities/${id}`,
    // method: "POST",
    // data : {
    //   goodsId: id,
    //   quantity: quantity,
    // },
    onSuccess: (data, dispatch) => {
        // dispatch(setCartData(data?.basket));
        return {
            type: SET_VOLUME_DISCOUNT_QUNTITIES,
            payload: {
                data,
                productId,
            }
        };
    },
    onFailure: () => {
        console.log("error in modifyBasket()");
        return {
            type: "a"
        };
    }
});
export const getCartDeliveryAddress = () => {
    let state = store.getState();
    const orgId = state?.user?.organizationId
    if (!orgId) return {type: 'a'}
    return apiAction({
        endPoint: '/address/my-organization',
        // method: "POST",
        // data :
        //   {"firstRow":0,"pageSize":13,"sortField":"id","sortAsc":false,"filters":[],"filterText":"","searchTerm":null,"jsonLogicText":"{\"and\": []}"}
        // ,
        onSuccess: (data, dispatch) => {
            console.log(3333, data);

            // const final = data?.items?.filter((address) => address.status === "ACTIVE")

            let payload = {}
            payload['ORG_ADDRESS_LIST'] = data ?? []
            return {
                type: SET_DICTIONARY,
                payload
            }
        },
        onFailure: () => {
            console.log("error in loading address list()");
            return {
                type: "a"
            };
        }
    });
}

export const setUserStatus = (id, status, isAdmin) => {
    return apiAction({
        endPoint: `/user/status-change?id=${id}&status=${status}&suspendReason=EXIT`,
        method: "PATCH",
        onSuccess: (data, dispatch) => {
            isAdmin && dispatch(loadItem("user", id))
            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log(id, status, isAdmin)
            console.log("user status update error");
            return {
                type: "a"
            };
        }
    });
}

export const setProductState = (id, status) => {
    const itemType = "productDetails";

    return apiAction({
        endPoint: `/product/status-change/?id=${id}&status=${status}`,
        method: "POST",
        // data : {
        //   goodsId: id,
        //   quantity: quantity,
        // },
        onSuccess: (data, dispatch) => {
            dispatch(loadItem(itemType, id))
            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("error in loading address list()");
            return {
                type: "a"
            };
        }
    });
}

export const getForms = (data) => {
    return apiAction({
        endPoint: `/form-model/search`,
        method: "POST",
        data,
        onSuccess: (data, dispatch) => {
            const {items} = data
            dispatch(setDataItems(items))
            return {
                type: "a"
            };
        },
        onFailure: (err) => {
            console.log("err", err);
            return {
                type: "a"
            };
        }
    });
}

export const getForm = (id) => {
    return apiAction({
        endPoint: `/public/form-model/${id}`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            const parsedFormElements = JSON.parse(data?.content)

            const title = parsedFormElements?.filter(e => e.id === "title")?.[0]?.value
            const formDescription = parsedFormElements?.filter(e => e.id === "formDescription")?.[0]?.value

            let formStructure = {}
            parsedFormElements?.map(item => {
                if (item?.id === "title" || item?.id === "formDescription") formStructure[item?.id] = item?.value
                else {
                    formStructure[item?.checkedId] = item?.show ? [1] : null
                    formStructure[item?.mandatoryId] = item?.mandatory ? [1] : null
                }
            })

            dispatch(setCrtFormItem({
                ...data,
                language: data?.language === "EN" ? 1 : data?.language === "RO" ? 2 : 3,
                title,
                formDescription, ...formStructure
            }))

            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("form get error");
            return {
                type: "a"
            };
        }
    });
}

export const deleteForm = (id, history) => {
    return apiAction({
        endPoint: `/form-model/${id}`,
        method: "DELETE",
        onSuccess: (data, dispatch) => {

            if (history !== undefined) history.push("/admin/forms/list")
            else dispatch(getForms({firstRow: 0, pageSize: 13}))

            return {
                type: "a"
            };
        },
        onFailure: (err) => {
            console.log("err", err)
            console.log("form delete error");
            return {
                type: "a"
            };
        }
    });
}

export const getPipelines = (data, setPipelines) => {
    return apiAction({
        endPoint: "/pipeline/search",
        data,
        method: "POST",
        onSuccess: (data, dispatch) => {

            const pipelines = data?.items?.map(item => ({
                value: item?.id,
                label: item?.name,
            }))

            setPipelines(pipelines)

            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("pipelines get error");
            return {
                type: "a"
            };
        }
    });
}

export const getGroupById = (itemId) => {
    return apiAction({
        endPoint: `/group/${itemId}`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            dispatch(setUserPermissions(data?.permissionList))
            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("permission list get error");
            return {
                type: "a"
            };
        }
    });
}

export const deleteGroup = (id) => {
    return apiAction({
        endPoint: `/group/${id}`,
        method: "DELETE",
        onSuccess: (data, dispatch) => {
            showMagicToaster({title: 'Group deleted successfully!', type: "success"})
            dispatch(loadViewData({reload:true}))
            return {
                type: "a"
            };
        },
        onFailure: (err) => {
            console.log("err", err)
            console.log("group delete error");
            return {
                type: "a"
            };
        }
    });
}

export const addDealNote = (data, id, closeModal) => {
    return apiAction({
        endPoint: "/sales-activity/note",
        data,
        method: "POST",
        onSuccess: (data, dispatch) => {
            closeModal();
            dispatch(setPageEdit(false))
            dispatch(getDealActivity(id))

            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("addDealNote error");
            return {
                type: "a"
            };
        }
    });
}

export const addDealMeeting = (data, id, closeModal) => {
    return apiAction({
        endPoint: "/sales-activity/meeting",
        data,
        method: "POST",
        onSuccess: (data, dispatch) => {
            closeModal()
            dispatch(setPageEdit(false))
            dispatch(getDealActivity(id))

            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("addDealMeeting error");
            return {
                type: "a"
            };
        }
    });
}

export const addDealPreScoring = (data, id, closeModal) => {
    return apiAction({
        endPoint: `/sales-deal/retry-prescoring/${id}`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            closeModal()
            dispatch(setPageEdit(false))
            dispatch(getDealActivity(id))

            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("addDealMeeting error");
            return {
                type: "a"
            };
        }
    });
}
export const addDealScoring = (data, id, closeModal) => {
    return apiAction({
        endPoint: `/sales-deal/retry-scoring/${id}`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            closeModal();
            dispatch(setPageEdit(false))
            dispatch(getDealActivity(id))

            return {
                type: "a"
            };
        },
        onFailure: () => {
            console.log("addDealScoring error");
            return {
                type: "a"
            };
        }
    });
}

export const markDealLost = (id, statusReason, note = '', history, pipelineId) => {
    return apiAction({
        endPoint: `/sales-deal/set-lost?id=${id}&statusReason=${statusReason}&info=${note}`,
        method: "PATCH",
        onSuccess: (data) => {
            const {status} = data
            if (status) showMagicToaster({title: 'Deal marked as lost!', type: "success"})

            return {type: "a"};
        },
        onFailure: (err) => {
            showMagicToaster({title: 'Deal could not be marked as lost!', type: "error"})
            return {type: "a"};
        }
    });
}

export const markDealWon = (id, note='', history, pipelineId) => {
    return apiAction({
        endPoint: `/sales-deal/set-won?id=${id}&info=${note}`,
        method: "PATCH",
        onSuccess: (data) => {
            const {status} = data
            if (status) showMagicToaster({title: 'Deal marked as won!', type: "success"})

            return {type: "a"};
        },
        onFailure: (err) => {
            showMagicToaster({title: 'Deal could not be marked as won!', type: "error"})

            return {type: "a"};
        }
    });
}

export const saveActivityDetails = (data, crtFormItem, activityType) => {
    return apiAction({
        endPoint: `/sales-activity/${activityType}`,
        data,
        method: "POST",
        onSuccess: (responseData, dispatch) => {
            dispatch(setCrtFormItem({...responseData}))
            dispatch(setPageEdit(false))
            showMagicToaster({title: 'Activity saved successfully!', type: "success"})

            return {
                type: "a"
            };
        },
        onFailure: (err) => {
            console.log("saveActivityDetails error", err);
            showMagicToaster({title: 'Activity could not be saved successfully!', type: "error"})
            return {
                type: "a"
            };
        }
    });
}

export const saveEditDealActivity = (data, activityType) => {
    return apiAction({
        endPoint: `/sales-activity/${activityType}`,
        data,
        method: "POST",
        onSuccess: (responseData, dispatch) => {
            dispatch(setCrtFormItem({...responseData}))
            showMagicToaster({title: 'Activity saved successfully!', type: "success"})
            return {
                type: "a"
            };
        },
        onFailure: (err) => {
            console.log("saveActivityDetails error", err);
            showMagicToaster({title: 'Activity could not be saved successfully!', type: "error"})
            return {
                type: "a"
            };
        }
    });
}

export const reportDownload = (reportType, xlsFilter, itemId, columns = [], pageSize) => {
    axios({
        url: `${process.env.REACT_APP_API_ENDPOINT}/${reportType}/export/${itemId}`,
        method: 'POST',
        responseType: 'blob',
        data: {
            "format": "XLSX",
            "entityCode": `${reportType === "otpm/report/loans" ? "salesDealLoans" : "salesDealCallme"}`,
            "language": "RO",
            "queryCriteria": {
                "firstRow": 0,
                "pageSize": pageSize || 25,
                "filters": xlsFilter,
                sortAsc: false,
                sortField: "lastModifiedDate",
                "searchTerm": null,
                "jsonLogic": null,
                "countResult": true
            },
            "columns": organizeReportStages(columns)
        }
    }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}

export const getDataAsync = async (endPoint, method, bodyData) => {
    return await new Promise(resolve => {
        const func = (data) => {
            resolve(data);
            return {type: 'a'}
        }
        store.dispatch(apiAction({
            endPoint,
            method,
            data: bodyData,
            onSuccess: func,
            onFailure: func
        }))
    })
}

export const getPipelineStageDeals = (pipelineId, searchString) => getDataAsync(`/pipeline/sales-deal/search/${pipelineId}`, 'POST', {
    ...(!!searchString && {searchTerm: searchString}),
    countResult: true,
    sortField: "modifyDate",
    sortAsc: false,
    pageSize: 25
}).then(response => response)

export const getPipelineStageDealsPagination = (pipelineId, page) => getDataAsync(`/pipeline/stage/sales-deal/search/${pipelineId}`, 'POST', {
    countResult: true,
    sortField: "modifyDate",
    sortAsc: false,
    pageSize: 25,
    pageNumber: page
}).then(response => response)

export const getPipeline = (pipelineId) => getDataAsync(`/pipeline/${pipelineId}`).then(response => response)

export const getPipelineStage = (pipelineId) => getDataAsync(`/pipeline-stage/${pipelineId}`).then(response => response)

export const getAllBranches = () => getDataAsync(`/organization/sales-point/all-option-code-list`).then(response => response)

export const getLead = (pipelineId) => getDataAsync(`/organization/lead/${pipelineId}`).then(response => response)

export const getLeadData = (itemId) => getDataAsync(`/organization/lead/${itemId}`).then(response => response)

export const getDealData = (itemId) => getDataAsync(`/sales-deal/${itemId}`).then(response => response)

export const getBranches = () => getDataAsync(`/organization/sales-point/all-option-code-list`).then(response => response)

export const getFormList = () => getDataAsync(`/otpm/form-model/search`, "POST", {
    "firstRow":0,
    "pageSize":13,
    "sortField":"name",
    "sortAsc":false,
    "filters":[{
        "beginParenthesis": "(",
        "field": "methodType",
        "relation": "=",
        "value": "PRESCORING",
        "operator": "AND",
        "endParenthesis": ")"
    }]}).then(response => response)

export const getDealActivityData = (type, itemId) => getDataAsync(`/sales-activity/${type}/${itemId}`).then(response => response)
export const getGroups = () => getDataAsync("/otpm/group/search", "POST", {
    "firstRow": 0,
    "pageSize": 100,
    "sortField": "id",
    "sortAsc": true,
    "filters": [],
    "filterText": "",
    "searchTerm": "",
    "countResult": true,
    "jsonLogic": null
}).then(response => response)
