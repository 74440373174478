import { setDataItems } from "../dataView"
import adreseTableData from "mockData/adreseTableData"
import priceNormalTable from "mockData/priceNormalTable"
import stockRequestTable from "mockData/stockRequestTable"
import { SET_ITEMS } from "../dataView"

const { default: store } = require("store/store")
const { reDispatch, dynamoAction } = require("store/middleware/api")
const { searchStuff, loadDynamoItemsBySK } = require("../dataView.search")

const directMatchApi = [
  "sales-activity/history",
  "uniprest/group", // Lista de roluri - 2.2
  "otpm/group",
  "uniprest/employee", // Angajati Uniprest - 2.3
  "product/goods/discount/fix", // Preturi nete nete - 4.4
  "product/goods/discount/volume", // Discounturi de volum - 4.5
  "product/goods/discount/package", // Pachete - 4.6
  "product-question", // Intrebari si raspunsuri - 4.9
  "product-rating", // Recenzii - 4.10
  "product/goods/discount/grid", // Grila de discounturi - 4.3
  "product-document", // Documente technice - 4.11
  "goods-badge", // Badges - 4.8
  "organization/webshop-client", // Lista de companii - 3.1
  "uniprest/user", // Lista de utilizatori - 2.1
  "organization/warehouse", // Depozite Uniprest - 4.12
  "stock", // Detalii depozite - 4.12.1
  "product/goods", // Lista de produse Compano 4.2
  "basket", // Lista de comenzi 5.1
  "basket/my-basket", //guest - my orders
  "invoice/my-invoice", //client: my invoices
  "client-invoice/my", //client: my invoices
  "client-invoice", // Lista de facturi - 5.3
  "location-delivery", // Localitati si detalii de livrare - 4.13.1
  "organization/webshop-client/limit", // Detalii factura - /admin/order/invoice/form
  "sales/user", // Lista de utilizatori table's form
  "group", // Lista de roluri table's form
  "organization/lead", // Lista de leads table's form
  "organization/lead/activities",
  "pipeline", // Lista de pipelines table's form
  "microsite", // Lista de microsites table's form
  "form-model", // Lista de formulare
  "product/goods/info", // /admin/product/b2b/form DETALII PRODUS page
  "uniprest/user/my-user", // /shop/client/profile Profilul tau form
  "order",
  "organization/workplace",
  "product/relation",
  "organization/address", // Adresa de livrare clienti - admin/company/delivery/list
  "basket/my-statistics",
  "organization/workplace/alloptions",
  "pipeline/sales-deal",
  "otpm/report/loans",
  "otpm/report/callme",
  "sales-deal",
]

const directMatchDynamo = [
  "GUEST_ADD_LIST", // Liste Predefinite - 6.1
  "GUEST_ARTICLE_V2", // Stiri - 7.1
  "LOCATION_RESPONSE", // Locatii Uniprest - 7.5
  "JOB_RESPONSE", // Joburi disponibile - 7.2
  "CONTACT_RESPONSE", // Formular de contact - 7.4
  "SIGNUP_RESPONSE", // Cont Uniprest - Solicitari - 3.2
  "APLICANT_RESPONSE", // Aplicanti - 7.3
  "REQUEST_STOCK",
  "REQUEST_PRICE",
  "TUTORIALS"
]

export const loadViewData = (props) => reDispatch((dispatch) => {
  const viewId = store.getState()?.dataView?.viewId
  
  if (directMatchApi.includes(viewId) && viewId !== 'sales-activity/history' ) dispatch(searchStuff({ ...props, type: viewId }))
  
  if (viewId === "java-product-client") dispatch(searchStuff({...props,type: "product/goods/shop", sortField: "ranking", sortAsc: false }))
  if (viewId === "global-products-table") dispatch(searchStuff({ ...props, type: "product/goods", pageSize: 25 })) //  Lista de produse: Shop B2B - 4.1

  // DYNAMO - TOTAL table data from Dynamo: 7
  if (directMatchDynamo.includes(viewId)) dispatch(loadDynamoItemsBySK({ SK: viewId }))

  // MOCK - TOTAL table mock data: 2
  if (viewId === "mock-adrese") dispatch(setDataItems(adreseTableData.contents)) // Adresa de livrare clienti - 3.3
  if (viewId === "mock-priceNormal") dispatch(setDataItems(priceNormalTable.contents)) // Matricea de Shopping - 4.7
  if (viewId === "mock-stockRequest") dispatch(setDataItems(stockRequestTable.contents)) // 4.13 Interes produse fara stoc

  return { type: "a" }
})

export const loadNewsData = () => dynamoAction({
  method: "QUERY",

  parameters: {
    TableName: "TempDevStack-MainTable-74SE74RNUI3D",
    KeyConditionExpression: "#pk = :pkvalue",
    ExpressionAttributeNames: {
      "#pk": "PK"
    },
    ExpressionAttributeValues: {
      ":pkvalue": { S: "ARTICLE" }
    }
  },

  onSuccess: (data, dispatch) => {
    return {
      type: SET_ITEMS,
      payload: data
    }
  },
    onFailure: (err) => {
    console.log(err)
    return {
      type: "a"
    }
}})
