export const adminLeftMenu = [
  {
    name: 'Dashboard',
    //svg: 'homepage',
    svg: 'dashboard1',
    url: '/admin/dashboard'
  },

  {
    name: 'Utilizatori',
    svg: 'manageusers',
    url: '/admin/user/client/list',
    children: [
      {
        name:  'Lista de utilizatori',//'Conturi Clienti',
        svg: 'menulist',
        url: '/admin/user/client/list'
      },
      {
        name: 'Lista de roluri',//'Roles',
        svg: 'resetpass',
        url: '/admin/user/role/list'
      }
    ]
  },

  {
    name: 'Organizatii',
    svg: 'Business', //taken from design
    url: '/admin/company/dashboard', //???business
    children: [
      {
        name: 'Dashboard',
        svg: 'homepage',
        url: '/admin/company/dashboard'
      },
      {
        name: 'Lista de companii',//'Toate Companiile',
        //svg: 'invoice',
        svg: 'list1',//
        url: '/admin/company/client/list'
      },
      {
        name: 'Solicitari - cont uniprest',//'Adrese Livrare',
        svg: 'uniprestplus', //taken from design
        url: '/admin/company/accountrequests/list'
        //url: '/admin/company/delivery/list' //???
      },
      {
        name: 'Adrese Livrare',
        svg: 'delivery',
        //url: '/admin/company/accountrequests/list'
        url: '/admin/company/delivery/list'
      },
      {
        name: 'Localitati',
        svg: 'maps',
        //url: '/admin/company/accountrequests/list'
        url: '/admin/company/towns/list'
      }
    ]
  },
  {
    name: 'Produse',
    svg: 'product',
    // icon: ManageUsers,
    url: '/admin/product/dashboard',
    children: [
      {
        name: 'Dashboard',
        svg: 'homepage',
        url: '/admin/product/dashboard'
      },
      {
        name: 'Lista de produse - b2b',//'B2B Items',
        //svg: 'invoice',
        svg: 'uniprestglobalproducts',
        url: '/admin/product/b2b/list'
      },
      {
        name: 'Relatii intre Produse - B2B',//'B2B Items',
        //svg: 'invoice',
        svg: 'adminlink',
        url: '/admin/product/b2brelations/list'
      },
      {
        name: 'Lista de produse - compano',//'Compano',
        svg: 'compano-products',
        url: '/admin/product/compano/list'
      },
      {
        name: 'Grile de discount',//'Grila de discounturi',
        svg: 'grila-discount',
        url: '/admin/product/discount/list',
        roForm: true,
      },
      {
        name: 'Preturi net net',//'Net nete',
        svg: 'nete-nete',
        url: '/admin/product/net/list',
        roForm: true,
      },
      {
        name: 'Discounturi Volum',//'Discount Volum',
        svg: 'volume-discount',
        url: '/admin/product/volume/list'
      },
      {
        name: 'Pachete',
        svg: 'discount-pachete',
        url: '/admin/product/pack/list'
      },
      {
        name: 'Matrice de Produs',//'Matrice Produse',
        svg: 'kanban',
        url: '/admin/product/matrix/list'
      },
      {
        name: 'Badge-uri',//'Badges',
        svg: 'badge',
        url: '/admin/product/badge/list'
      },
      {
        name: 'Intrebari si raspunsuri',
        svg: 'airecommendations',
        url: '/admin/product/qa/list'
      },

      {
        name: 'Recenzii',
        svg: 'reviewstar',
        url: '/admin/product/review/list'
      },
      // {
      //   name: 'Star',
      //   svg: 'review-star',
      //   url: '/admin/product/review/list'
      // },
      // {
      //   name: 'WareHouse',
      //   svg: 'warehouse',
      //   url: '/admin/product/review/list'
      // },
      {
        name: 'Documente tehnice',//'Documente',
        svg: 'product-document',
        url: '/admin/product/document/list'
      },
      {
        name: 'Lista Depozite',
        svg: 'Warehouse', //taken from design
        url: '/admin/product/warehouse/list' //???
      },
      {
        name: 'Interes produse fara stoc',
        svg: 'Warning',
        url: '/admin/product/stockrequest/list'
      },
      {
        name: 'Interes produse fara pret',
        svg: 'Warning',
        url: '/admin/product/pricerequest/list'
      }
    ]
  },

  {
    name: 'Comenzi si facturi',
    svg: 'invoicedollar', //taken from design
    url: '/admin/order/dashboard', //???
    children: [
      {
        name: 'Dashboard',
        svg: 'homepage', //taken from design
        url: '/admin/order/dashboard' //???
      },
      {
        name: 'Lista Comenzi',
        svg: 'list1', //taken from design
        url: '/admin/order/list' //???
      },
      {
        name: 'Lista Facturi',
        svg: 'invoice', //taken from design
        url: '/admin/order/invoice/list' //???
      }
    ]
  },

  {
    name: 'Liste predefinite',
    svg: 'listmodels', //taken from design
    url: '/admin/admin-lists/dashboard', //???
    children: [
      {
        name: 'Dashboard',
        svg: 'homepage', //taken from design
        url: '/admin/admin-lists/dashboard' //???
      },
      {
        name: 'Lista - Liste Predefinite',
        svg: 'list1', //taken from design
        url: '/admin/admin-lists/list' //???
      }
    ]
  },

  {
    name: 'Guest',
    svg: 'webpage', //taken from design
    url: '/admin/guest/dashboard',
    children: [
      {
        name: 'Dashboard',
        svg: 'homepage', //taken from design
        url: '/admin/guest/dashboard' //???
      },
      {
        name: 'Stiri',
        svg: 'Documents3', //taken from design
        url: '/admin/guest/news/list' //???
      },
      {
        name: 'Tutoriale',
        svg: 'openbook', //taken from design
        url: '/admin/guest/tutorials/list' //???
      },
      {
        name: 'Anunturi de job',//'Anunturi angajari',
        svg: 'Jobs2', //taken from design
        url: '/admin/guest/job/list' //???
      },
      {
        name: 'Aplicanti joburi',
        svg: 'jobs1', //taken from design
        url: '/admin/guest/candidate/list' //???
      },
      {
        name: 'Cereri de Contact',//'Cereri Contact',
        //svg: 'email', //taken from design
        svg: 'sendemail',
        url: '/admin/guest/contact/list' //???
      },
      {
        name: 'Locatii Uniprest',
        svg: 'maps', //taken from design
        url: '/admin/guest/location/list' //???
      }
    ]
  },

  // {
  //   name: 'Depozite',
  //   svg: 'Warehouse', //taken from design
  //   url: '/admin/warehouse/list', //???
  //   children: [
  //     {
  //       name: 'Lista Depozite',
  //       svg: 'Warehouse', //taken from design
  //       url: '/admin/warehouse/list' //???
  //     }
  //   ]
  // }

  // {
  //   name: "Cereri de oferta",
  //   subMenu: true,
  //   svg: "RFPList", //taken from design
  //   url: "/admin/offer/list/", //??? RFPList
  // },
  // {
  //   name: "Proiecte",    subMenu: true,
  //   svg: "Projects", //taken from design
  //   url: "/admin/project/list/", //??? RFPList
  // },
  // {
  //   name: "Stiri",
  //   svg: "Documents3", //taken from design
  //   url: "/admin/news/list/",
  //   subMenu: true,
  //   rightTopButtons: [
  //     {
  //       name: "Dashboard",
  //       svg: "homepage",
  //       url: "/admin/news/",
  //     },
  //     {
  //       name: "All Items",
  //       svg: "invoice",
  //       url: "/admin/news/list",
  //     },
  //   ]
  // },
  // {
  //   name: "Joburi",
  //   subMenu: true,

  //   svg: "Jobs2", //taken from design
  //   url: "list", //???
  // },

  // {
  //   name: "Pagini de prezentare",
  //   svg: "WebPage", //taken from design
  //   url: "/admin/pages/", //???
  // },
  // {
  //   name: "Promotii",
  //   svg: "Promo", //taken from design
  //   url: "/admin/promo/", //???
  // },
]

export const adminBottomMenu = [
  // {
  //   name: "The other thing",
  //   svg: "stats",
  //   url: "/admin/settings",
  // },
  // {
  //   name: 'Settings',
  //   svg: 'settings',
  //   url: '/admin/settings'
  // }
]
