import { lazy } from "react";

export const formsRoutes = [
    {
        exact: true,
        path: "/admin/forms/list",
        component: lazy(() => import("../views/formList")),
    },
    {
        exact: false,
        path: "/admin/forms/form",
        component: lazy(() => import("../views/formForm")),
    },
];