// const DELETE_COLEG = "shop/DELETE_COLEG";
// const ADD_COLEG = "shop/ADD_COLEG";
// const SET_SEARCH_STRING = "shop/SET_SEARCH_STRING";
//import Delete from "assets/mockPictures/Delete.svg";
import AddUser from "assets/MenuIcon/Atom/Icons/Profile.svg";
import Delete from "assets/mockPictures/Delete.svg";
import AP from "assets/mockPictures/AP.svg";
import { reDispatch } from "store/middleware/api";
import { now } from "moment";
import { object } from "yup";
import store from "../store";
import moment from "moment";

const ADD_TO_CART = "shop/ADD_TO_CART";
const SEARCH_STRING = "colegii/SEARCH_STRING";
const SET_SEARCH_RESULTS = "colegii/SET_SEARCH_RESULTS";
const SET_MENU_SEARCH_STRING = "colegii/SET_MENU_SEARCH_STRING";
const SHOW_COLEG = "colegii/SHOW_COLEG";
const ADD_NEW_COLEG = "colegii/SHOW_COLEG";
const DELETE_COLEG = "colegii/DELETE_COLEG";
const SUBMIT_COLEG_FORM = "colegii/SUBMIT_COLEG_FORM";
const USER_FORM_ACTIVE = "colegii/USER_FORM_ACTIVE";
const REGISTER_DATE = "colegii/REGISTER_DATE";

const MODIFICA_ADRESA = "colegii/MODIFICA_ADRESA";
const MODIFICA_ROL = "colegii/MODIFICA_ROL";

const newColeg = {
  id: "new06",
  name: "Kedves Norbert",
  profile_image: AddUser,
  email: "kedvesnorbi@yahoo.com",
  rol: "editor",
  //icon: Delete,
  icon: Delete,
  pending: false,
  date: "invitat acum 2 zil"
};

const mockColegiiList = [
  {
    id: "new01",
    name: "Andrei Popescu",
    adress: "Timisoara",
    profile_image: AddUser,
    email: "andrei.popescu@numefirma.com",
    rol: "editor",
    checked: true,
    //icon: Delete,
    pending: false,
    date: "2021-04-20T11:30:58+03:00"
  },
  {
    id: "new02",
    name: "Andrei Doi",
    adress: "Timisoara",
    profile_image: AddUser,
    email: "andrei.popescu@numefirma.com",
    rol: "editor",
    checked: false,
    //icon: Delete,
    pending: false,
    date: "2021-04-20T10:30:58+03:00"
  },
  {
    id: "new03",
    name: "Andrei Trei",
    adress: "Timisoara",
    profile_image: AddUser,
    email: "andrei.popescu@numefirma.com",
    rol: "editor",
    checked: false,
    //icon: Delete,
    pending: false,
    date: "2021-04-20T09:30:58+03:00"
  },
  {
    id: "new04",
    name: "Andrei Patru",
    adress: "Timisoara",
    profile_image: AddUser,
    email: "andrei.popescu@numefirma.com",
    rol: "",
    checked: true,
    icon: "",
    pending: false,
    date: ""
  }
];

const initialState = {
  colleagues: [...mockColegiiList],
  adresa: []
};

//REDUCER
export const addColegiiReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_COLEG:
      return {
        ...state,
        colleagues: [...state.colleagues, action.payload]
      };
    case REGISTER_DATE:
      return {
        ...state,
        registeredDate: action.payload
      };
    case DELETE_COLEG:
      return {
        ...state,
        colleagues: state.colleagues.filter(item => item.id !== action.payload.id)
      };
    case USER_FORM_ACTIVE:
      return {
        ...state,
        inputValues: [...state.inputValues, action.payload]
      };
    case MODIFICA_ADRESA:
      return {
        ...state,
        adresa: [...state.adresa, action.payload]
      };
    default:
      return state;
  }
};

export const addNewColeg = (userInput, userSelect) => {
  const length = 9;
  const prefix = "id-";
  const id =
    prefix +
    Math.random()
      .toString(36)
      .substr(2, length);

  const date = new Date().toISOString();
  return {
    payload: { id: id, name: "New Coleg", email: userInput, profile_image: AddUser, rol: userSelect, date: date },
    registeredDate: date,
    type: ADD_NEW_COLEG
  };
};

export const deleteColeg = id => {
  return {
    payload: { id },
    type: DELETE_COLEG
  };
};

export const addAdresa = data => {
  return {
    payload: { data },
    type: MODIFICA_ADRESA
  };
};
