import { history } from "utils/history";
export const LOGIN_USER = "LOGIN_USER";
export const SET_USER = "SET_USER";
export const LOGOUT_USER = "auth/LOGOUT_USER";

export const SET_API_RESPONSE = "auth/SET_API_RESPONSE";
export const SET_AUTHENTICATED = "auth/SET_AUTHENTICATED";
export const SET_AUTH_TIME_STAMP = "auth/SET_AUTH_TIME_STAMP";
export const SET_AUTH_ERROR = "auth/SET_AUTH_ERROR";
export const SET_AUTH_IS_LOADING = "auth/SET_AUTH_IS_LOADING";
export const SET_AUTH_REDIRECT_URL = "auth/SET_AUTH_REDIRECT_URL";

const initialState = {
    authenticated: false,
    token: "",
    idToken: "",
    email: "",
    userData: {},
    apiResponse: null,
    authTimeStamp: null,
    authError: null,
    authIsLoading: false,
    redirectUrl: "",
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                authenticated: true,
                token: action.payload.AccessToken,
                idToken: action.payload.IdToken,
                email: action.payload.email,
            };
        // case SET_USER:
        //     return {
        //         ...state,
        //         userData: action.payload
        //     };
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: action.payload
            };
        case SET_USER:
            return {
                ...state,
                userData: action.payload
            };
        case SET_API_RESPONSE:
            return {
                ...state,
                apiResponse: action.payload
            };
        case SET_AUTH_TIME_STAMP:
            return {
                ...state,
                authTimeStamp: action.payload
            };
        case SET_AUTH_ERROR: {
            return {
                ...state,
                authError: action.payload
            }
        }
        case SET_AUTH_IS_LOADING: {
            return {
                ...state,
                authIsLoading: action.payload
            }
        }
        case SET_AUTH_REDIRECT_URL: {
            return {
                ...state,
                redirectUrl: action.payload
            }
        }

    // case LOGOUT_USER:
    //     return { ...initialState };

    default:
      return state;
  }
}

export const loginUser = (username, password) => {
    return dispatch => {
        // console.log("login action dispatched", username, password);
        // signIn (cognito)

    };
};

export const resetPassword = email => {
  return async dispatch => {
    // await Auth.forgotPassword(email);
  };
};
export const resetPasswordConfirm = (username, code, password) => {
  return async dispatch => {
    // await Auth.forgotPasswordSubmit(username, code, password);
    // dispatch(loginUser(username, password));
    // history.push('/')
  };
};
/// DEPRE - CA - TE - D MOFO
// export const loginUser = (username, password) =>
//     apiAction({
//         endPoint: "/user/getToken",
//         data: {
//             id: username,
//             password: password
//         },
//         onSuccess: (data, dispatch) => {
//             localStorage.setItem("user", JSON.stringify(data));
//             // history.push('/dashboard');
//             dispatch(initPage());
//             dispatch(getLabels());
//             dispatch(getManagers());
//             dispatch(getChildrenCompanies());
//             dispatch(getStores());
//             dispatch(getPosList());
//             return {
//                 type: LOGIN_USER,
//                 payload: data
//             };
//         },
//         onFailure: () => {
// console.log("error occured");
//             return {
//                 type: "a"
//             };
//         }
//     });

// export const resetPassword = email =>
//     apiAction({
//         endPoint: "/user/reset",
//         method: "POST",
//         data: {email: email},
//         onSuccess: data => {
//             // console.log("user data", data);
//             // localStorage.setItem("userData", JSON.stringify(data));
//             // // data['custom:onboarding'] ? history.push('/onboarding') : history.push('/dashboard')
//             localStorage.clear('user');
//             return {
//                 type: LOGOUT_USER,
//                 payload: data
//             };
//         },
//         onFailure: () => {
//             console.log("error occured");
//             return {
//                 type: "a"
//             };
//         }
//     });



export const logoutUser = () => {
    setTimeout(() => {
        history.push('/')
    }, 500)
    return {
        type: LOGOUT_USER
    };
};


export const clearApiResponse = () => {
    return {
        type: SET_API_RESPONSE,
        payload: null,
    };
};

export const setApiResponse = (data) => {
    return {
        type: SET_API_RESPONSE,
        payload: data,
    };
};


