import { lazy } from "react";

export const leadRoutes = [
    {
        exact: true,
        path: "/admin/lead/list",
        component: lazy(() => import("../views/leadList")),
    },
    {
        exact: false,
        path: "/admin/lead/form",
        component: lazy(() => import("../views/leadForm")),
    },
];