import {createSelector} from 'reselect';
import { filtersCategoriesSelector } from './filterCategories.selector';
import templateFilterList from './filters.template';
import { guest_article, uni_location, applicability, associated_users, status, permission_name } from 'staticData/dynamoTypes'
import { permissionGroups } from 'staticData/user';

const selectedFilters = state => state.dataView?.selectedFilters;
const sort = state => state.dataView?.sort;
// const stateColumns = state => state.dataView?.columns;
const dictonary = state => state.backData?.dictionary
const locationOptions = state => state.backData?.dictionary?.LOCATION_RESPONSE
const clientType = state => state.company?.type

export const compare = ( a, b ) => {
  if ( a?.label < b?.label ){
    return -1;
  }
  if ( a?.label > b?.label ){
    return 1;
  }
  return 0;
}
export const selectComputedFilters = createSelector(
  selectedFilters, dictonary, sort, filtersCategoriesSelector, clientType, locationOptions,
  (selectedFilters, dictonary, sort, filtersCategoriesSelector, clientType, locationOptions) => {
    //handling double unilocation points for dropdowns
    const locationDropdowns = []
    const controlDictionary = []
    const doublePoints = ["BIROU VÂNZĂRI BUCUREȘTI", "SEDIU SOCIAL TÂRGU MUREȘ"]

    for(let i=0; i < locationOptions?.length; i++) {
      if(!controlDictionary?.includes(locationOptions[i]?.county)) {
        controlDictionary?.push(locationOptions[i]?.county)
        locationDropdowns?.push({
          value: locationOptions[i]?.id,
          label: locationOptions[i]?.locationName
        })
    } else {
      const checkElement = (e) => locationOptions[i]?.county === e
      const index = controlDictionary?.findIndex(checkElement);
      locationDropdowns[index] = {
        ...locationDropdowns[index],
        value: doublePoints?.includes(locationOptions[i]?.name) ? locationOptions[i]?.id : locationDropdowns[index]?.id,
      }
    }
  }
    locationDropdowns?.sort(compare)
    const filters = clientTypeFilter(templateFilterList, clientType)?.map(item => {
      function computeDataField() {
        //exception for column sort filter
        // if (item.id === "FLT-C0SORTER") return templateHeader.map((col, index) =>
        //   ({
        //     id: index,
        //     disabled: col.noHide,
        //     label: col.label,
        //     visible: (!stateColumns || stateColumns?.length === 0) ? true : stateColumns?.includes(index)
        //   })
        // )
        //dynamic filters coming from API (dictionaries)
        const listFromDictionary =
        item?.dictionary === "CLIENTS" && item?.id === "FLT-CLIENT"
          ? dictonary?.[item?.dictionary]?.map(val => ({ id: val?.value, label: val?.label }))
          :
        item?.dictionary === "CLIENTS" && item?.id === "FLT-CLIENT-ID"
          ? dictonary?.[item?.dictionary]?.map(val => ({ id: val?.value, label: val?.label }))
          :
        item?.dictionary === "AGENTS" && item?.id === "FLT-Agent"
        ? dictonary?.[item?.dictionary]?.map(val => ({ id: val?.label, label: val?.label }))
        :
        item?.dictionary === "RANKINGS" && item?.id === "FLT-Ranking"
        ? dictonary?.[item?.dictionary]?.map(val => ({ id: val?.value, label: val?.label }))
        :
          dictonary?.[item?.dictionary]?.map(val => ({ id: val?.code, label: val?.value }))
        let exceptionOptions
        if (!listFromDictionary){
          //filtrul cu orasele locatiilor uniprest
          if (item?.id === "FLT-M0ctyjob") exceptionOptions = locationDropdowns
          // dictonary?.[uni_location]?.map((item) => ({
          //   id: item?.id,
          //   label: item?.locationName //name
          // })).sort(compare)

          // filtru aplicabilitate
          if (item?.id === "FLT-Applicability") exceptionOptions = dictonary?.[applicability]?.map((item) => ({
            id: item?.PK
          }))

          // filtru status
          if (item?.id === "FLT-RoleStatus") exceptionOptions = dictonary?.[status]?.map((item) => ({
            id: item?.PK
          }))

          //filtru permission names
          if (item?.id === "FLT-PermissionName") exceptionOptions = permissionGroups?.map((item) => ({
            id: item?.code,
            label: item?.label
          }))

          //filter pt client in grila discounts list
          // if (item?.id === "FLT-CLIENT") exceptionOptions = dictonary?.["CLIENTS"]?.map((item) => {
          //   console.log("DICT ", item)
          //   return {
          //     id: item?.label,
          //     label: item?.label
          //   }})


          //labels in articles
          if (item?.id === "FLT-M0lblart") {

            exceptionOptions = []
            dictonary?.[guest_article]?.forEach((item) => {
              if (item?.values?.labels) exceptionOptions?.push(...item?.values?.labels?.map(i => ({
                label:i,
                id:i,
              })))
            })
          }
        }
        return listFromDictionary
          ?? filtersCategoriesSelector?.[item.id]
          ?? exceptionOptions
          ?? item?.data //static options included in filter definition
          ?? []
      }
      return {
        ...item,
        selection: selectedFilters?.[item?.id],
        expanded: Boolean(item?.startExpanded || selectedFilters?.[item.id]),
        data: computeDataField(),
      }
    })


    let appliedFilters = []
    filters?.forEach(item => {
      if (selectedFilters?.[item.id]?.length > 0) appliedFilters?.push({
        name: item?.label,
        id: item?.id,
      })
      if(item?.label === "Brand")
      {
        item?.data.push({id:'Fara Brand',label:'Fara Brand'});
      }
    })

    appliedFilters = appliedFilters?.filter(e => !['FLT-sort00p1']?.includes(e.id))
    return { filters, appliedFilters, sort }
  })


const guestBlockedFilters = ["FLT-RGaaaa00", "FLT-sort0prc", "FLT-tipClient", "FLT-sort00p1", "FLT-5Saaaa00" ]
  const clientTypeFilter = (filters, clientType) => filters?.filter(item => {
    if (clientType === "guest") return !guestBlockedFilters?.includes(item?.id)
    return true
  })